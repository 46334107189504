/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';

const OtherDataAdditionForm = ({ oldValues, setState, heading, BtnText, Datakeys, DataLabels }) => {
	let [savedData, setSavedData] = useState([]);
	let [required, setRequired] = useState();
	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------
	console.log('oldValues', oldValues);
	useEffect(() => {
		let entries = [];
		Datakeys.forEach((data) => {
			if (data?.type === 'CheckBox') {
				entries.push({ name: data.key, value: null, type: data?.type });
			} else {
				entries.push({ name: data, value: null, type: 'Required' });
			}
		});
		setSavedData(entries);
	}, [Datakeys]);

	function resetFormStates() {
		setSavedData([]);
	}

	const handleSubmitForm = () => {
		// Check Validity
		setRequired();
		let check_Validity = false;

		savedData.forEach((element) => {
			if (!element.value && element?.type !== 'CheckBox') check_Validity = true;
		});

		if (check_Validity) {
			setRequired(true);
			return;
		}

		let FinalObj = {};
		savedData.forEach((data) => {
			FinalObj[data.name] = data.value;
		});

		if (oldValues) {
			setState([...oldValues, FinalObj]);
		} else {
			setState([FinalObj]);
		}
		resetFormStates([]);
	};

	const handleChange = (id, index, savedData, type) => {
		const myInputAmount = document.getElementById(id);
		if (type === 'checkBox') {
			savedData[index].value = myInputAmount.checked;
		} else {
			savedData[index].value = myInputAmount.value;
		}
		setSavedData(JSON.parse(JSON.stringify(savedData)));
	};

	console.log('savedData', savedData);
	return (
		<div className="my-2">
			<form className="col-md-11 bg-light mt-3 shadow-sm mx-4 p-3">
				<small className="ml-4 fw-bold">{heading}</small>
				<hr className="m-0" />
				<div className="mt-3 d-flex flex-column align-items-center justify-content-center px-3  custom-left-form">
					{Datakeys?.map((data, index) => {
						if (data?.type === 'CheckBox' && data?.mode === 'single') {
							return (
								<div className="clearfix col-md-6">
									<div className="clearfix mt-3 mb-4">
										<small className="text-center">{DataLabels[index]?.Heading}</small>
									</div>
									<div className="row mt-3">
										<div className="col-md-12 mb-3 d-flex flex-row">
											<input
												className="form-check-input custom-checkbox"
												type="checkbox"
												onChange={() => {
													handleChange(`option-${index}`, index, savedData, 'checkBox');
												}}
												checked={
													savedData.length === 0
														? false
														: savedData[Object.keys(data)[index]]?.value
												}
												id={`option-${index}`}
												required={false}
											/>
											<label className="px-2 small" htmlFor={`option-${index}`} role="button">
												{DataLabels[index].label}
											</label>
										</div>
									</div>
								</div>
							);
						}
						return (
							<div className="col-md-6 mb-3">
								<Input
									type="text"
									name={data}
									value={savedData.length === 0 ? '' : savedData[Object.keys(data)[index]]?.value}
									placeholder={DataLabels[index]}
									formid={`${data}${index}`}
									fn={() => {
										handleChange(`${data}${index}`, index, savedData);
									}}
									mask={
										index === 0
											? ''
											: DataLabels[index].toLowerCase().includes('date')
											? '99-99-9999'
											: '99999999'
									}
								/>
							</div>
						);
					})}
					{required && (
						<small className="text-primary text-align-left text-red">Fill Complete Form*</small>
					)}
				</div>
				<div className="container-fluid content-row pb-4">
					<div className="d-flex align-items-center justify-content-center row">
						<Button
							mainClass="btn btn-primary custon-button-text   w-100"
							buttonText={BtnText}
							wrapperClass={'col-md-3 custon-button  mt-4'}
							type={'button'}
							callback={() => handleSubmitForm()}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default OtherDataAdditionForm;
