import { BASEURL } from "../../utilites";
import axios from "axios";
import { PAYOUT_ACTION_TYPE } from "./Type";

//  Function To CREATE REQUEST FOR PAYOUT
const requestPayout = (payoutObj) => {
	return async (dispatch) => {
		let apiUrl = !payoutObj.id
			? `${BASEURL}/api/stripe/payout-by-taxpro`
			: `${BASEURL}/api/stripe/payout-by-taxpro?id=${payoutObj.id}`;

		if (payoutObj?.id) {
			delete payoutObj.id;
		}
		await axios
			.post(apiUrl, payoutObj)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: PAYOUT_ACTION_TYPE.requestPayout,
					payload: {
						message: "success",
						status: true,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: PAYOUT_ACTION_TYPE.requestPayout,
					payload: {
						message: handlePayoutErrors("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};

const getPayout = (name, dateCreated, page = 1, per_page = 10) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/stripe/payout-by-taxpro`,{
				params: { name, dateCreated, page, per_page },
			})
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: PAYOUT_ACTION_TYPE.getPayout,
					payload: {
						message: "success",
						status: true,
						data: response?.data?.data?.data?.data,
						count:response?.data?.data?.count
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: PAYOUT_ACTION_TYPE.getPayout,
					payload: {
						message: handlePayoutErrors("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};

const getBalance = (id) => {
	return async (dispatch) => {
		let apiUrl = !id ? `${BASEURL}/api/stripe/balance` : `${BASEURL}/api/stripe/balance?id=${id}`;
		axios
			.get(apiUrl)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: PAYOUT_ACTION_TYPE.balance,
					payload: {
						message: "success",
						status: true,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: PAYOUT_ACTION_TYPE.balance,
					payload: {
						message: handlePayoutErrors("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};
// FUNCTION TO HANDLE API ERRORS
const handlePayoutErrors = (type, error) => {
	if (type === "simple") {
		return error.code === "ERR_NETWORK"
			? error?.message
			: error?.response?.data?.error?.raw?.message
			? error?.response?.data?.error?.raw?.message
			: error?.response?.data?.error;
	}
};

export const payout = {
	requestPayout,
	getPayout,
	getBalance,
};
