import React, { useState, useEffect } from "react";
import GoogleCalendarImg from "../../../../assets/img/google-calendar-icon.webp";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASEURL } from "../../../../utilites";

const GoogleCalendar = ({ user,googleCalendarVerification }) => {
  const [googlecalendarAuthUrl, setGooglecalendarAuthUrl] = useState("");


  useEffect(() => {
    axios
      .get(`${BASEURL}/api/googlecalendar/auth-url`)
      .then((res) => setGooglecalendarAuthUrl(res.data.data))
      .catch((error) =>
        console.error("Error fetching outlookCalendar auth URL:", error)
      );
  }, []);
  return (
    <>
      {user.isTaxPro && (
        <div className="clearfix py-3 border-1 border-light border-bottom">
          <h4 className="text-dark">Google-Calendar Integration</h4>
          <p>
            <img
              src={GoogleCalendarImg}
              alt="googleCalendar"
              className="w-25"
            />
          </p>
          <p>
            <strong>Google-Calendar</strong> is the scheduling automation
            platform with team-based scheduling, solutions and integrations for
            every department, and advanced security features.
          </p>
          <p className="text-dark">
            <strong>Status: </strong>
            {googleCalendarVerification || user?.isGoogleAuth ? "Integrated" : "No Integration"}
          </p>
          <p>
            <Link
              to={googlecalendarAuthUrl}
              className="btn btn-outline-primary"
              target="_blank"
            >
              {googleCalendarVerification || user?.isGoogleAuth 
                ? "Re-Authenticate Application"
                : "Authenticate Application"}
            </Link>
          </p>
        </div>
      )}
    </>
  );
};

export default GoogleCalendar;
