/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Input from './Input';

const MultiLineOneFieldInputs = ({ Options, handleChange, placeholder }) => {
	return (
		<div className="w-100 d-flex flex-row flex-wrap justify-content-between align-items-end mb-3 px-4 mt-3">
			{Options?.map((data, i) => {
				return (
					<div key={i} className="w-100 d-flex align-items-end justify-content-between px-2 my-2">
						<small className="form-check-label text-start fw-bold">{data.question}</small>
						<div className="w-50 d-flex flex-row justify-content-between">
							<div className="col-md-8">
								<Input
									type="text"
									value={data[placeholder.toLowerCase()]}
									name={placeholder}
									placeholder={placeholder}
									formid={`${placeholder}${i}`}
									fn={() => {
										handleChange(`${placeholder}${i}`, i);
									}}
									mask={'999999999'}
									label={placeholder}
								/>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default MultiLineOneFieldInputs;
