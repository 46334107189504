import Header from "../../Organizar/Form/Header";
import React, { useEffect, useState } from "react";

const Forms = ({ taxPayerFormData }) => {
	const [OpenForm, SetOpenForm] = useState(<></>);
	const [myFormObj, setmyFormObj] = useState();

	useEffect(() => {
		const SubHeader = taxPayerFormData?.flatMap((option) => option.SubOption);
		const activeComponent = SubHeader?.find((data) => data?.open);
		setmyFormObj(activeComponent);
		SetOpenForm(activeComponent?.component);
	}, [taxPayerFormData]);

	return (
		<div className="w-100">
			{OpenForm && (
				<>
					<Header myFormObj={myFormObj} />
					<hr className="m-0" />
					<div className="py-2 px-4">{OpenForm}</div>
				</>
			)}
		</div>
	);
};

export default Forms;
