import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import ReactSelect from 'react-select';
import { BASEURL } from '../../utilites';
import Input from './fields/Input';
import Button from './fields/Button';
import allActions from '../../Store/action';

const customStyles = {
	option: (provided) => ({
		...provided,
		textAlign: 'left',
	}),
	singleValue: (provided) => ({
		...provided,
		textAlign: 'left',
	}),
	placeholder: (provided) => ({
		...provided,
		textAlign: 'left',
		fontSize: 14,
	}),
};

export default function EditDocumentForm({ data, setAlert, reloadDocuments }) {
	const [{ customFolders }] = useSelector((state) => [state.dashboard]);
	const [uuid, setUuid] = useState('');
	const [documentName, setDocumentName] = useState('');
	const [selectedFolder, setSelectedFolder] = useState();
	const [createNewFolder, setCreateNewFolder] = useState(false);
	const [newFolderName, setNewFolderName] = useState('');

	const [errors, setErrors] = useState({
		documentName: false,
		general: '',
	});
	const button = useRef();
	const dispatch = useDispatch();

	useEffect(() => {
		if (data) {
			setUuid(data.uuid);
			setDocumentName(data.name);
			setSelectedFolder(
				data?.folders?.map((f) => ({
					value: f.id,
					label: f.name,
				}))
			);
		}
	}, [data]);

	useEffect(() => {
		dispatch(allActions.dashboard.getCustomFolders());
	}, [dispatch]);

	const handleSubmit = (e) => {
		e.preventDefault();

		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });
		axios
			.put(`${BASEURL}/api/documents/${uuid}`, {
				documentName,
				...(createNewFolder && newFolderName && { newFolderName }),
				...(!createNewFolder && selectedFolder && { selectedFolder: selectedFolder?.value }),
			})
			.then(() => {
				button.current.disabled = false;
				reloadDocuments && reloadDocuments();
				// Close Modal
				document.querySelector('.editDocumentModalClose').click();
				setAlert && setAlert('Document information has been updated successfully!');
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};

	return (
		<form className="documentform needs-validation" onSubmit={handleSubmit}>
			<div className="mb-3">
				<Input
					type="text"
					value={documentName}
					name="firstname"
					placeholder="Document name"
					fn={setDocumentName}
					hasValidationError={errors.documentName}
					showLabel={true}
					required={true}
				/>
			</div>
			<div className="mb-3 d-flex justify-content-center">
				<div className="form-check text-start">
					<input
						className="form-check-input"
						type="checkbox"
						checked={createNewFolder}
						id="newFolderCheck"
						onChange={(e) => setCreateNewFolder(e.target.checked)}
					/>
					<label className="form-check-label fw-semibold text-start" htmlFor="newFolderCheck">
						Create new folder and place the documents in it
					</label>
				</div>
			</div>
			{createNewFolder && (
				<div className="mb-3">
					<Input
						type="text"
						value={newFolderName}
						name="newFolderName"
						placeholder="Folder name"
						fn={setNewFolderName}
						hasValidationError={errors.newFolderName}
						showLabel={true}
					/>
				</div>
			)}
			{!createNewFolder && customFolders && customFolders.length > 0 && (
				<div className="mb-3">
					<label htmlFor="folders" className="form-label">
						Select folder(s)
					</label>
					<ReactSelect
						id="folders"
						className="p-0 basic-single"
						placeholder="Select folder(s)"
						styles={customStyles}
						closeMenuOnSelect={true}
						options={customFolders?.map((u) => ({
							value: u.id,
							label: u.name,
						}))}
						onChange={setSelectedFolder}
						value={selectedFolder}
					/>
				</div>
			)}
			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mb-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 ? ' disabled' : ''
				}`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-3"
				buttonText="Update document"
			/>
		</form>
	);
}
