import AvatarLight from '../assets/img/avatar1.png';
import AvatarDark from '../assets/img/avatar2.png';

export default function UserAvatar({ avatar, alt, ...props }) {
	const isDarkTheme = document?.documentElement?.getAttribute('data-bs-theme') === 'dark' || false;
	return (
		<img
			src={avatar && avatar.length > 0 ? avatar[0].path : isDarkTheme ? AvatarDark : AvatarLight}
			alt={alt}
			{...props}
		/>
	);
}
