import { JOBS_ACTION_TYPE } from './Type';

const DEFAULT_STATE = {
	jobs: { message: '', status: 'idle', data: {} },
	cancelJobRequest: { message: '', status: 'idle', data: {} },
	markJobAsCompleted: { message: '', status: 'idle', data: {} },
};

const INITIAL_STATE = {
	...DEFAULT_STATE,
};

const jobs = (state = INITIAL_STATE, action) => {
	if (action.type === JOBS_ACTION_TYPE.getJobs) {
		return {
			...state,
			jobs: action.payload,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.resetJobs) {
		return {
			...state,
			jobs: DEFAULT_STATE.jobs,
		};
	}

	if (action.type === JOBS_ACTION_TYPE.cancelJobRequest) {
		return {
			...state,
			cancelJobRequest: action.payload,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.cancelJobRequestIdle) {
		return {
			...state,
			cancelJobRequest: DEFAULT_STATE.cancelJobRequest,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.markJobAsCompleted) {
		return {
			...state,
			markJobAsCompleted: action.payload,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.markJobAsCompletedIdle) {
		return {
			...state,
			markJobAsCompleted: DEFAULT_STATE.markJobAsCompleted,
		};
	}

	return state;
};

export default jobs;
