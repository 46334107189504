import { BASEURL } from '../../utilites';
import axios from 'axios';
import { JOBS_ACTION_TYPE } from './Type';

//  Function To Get Jobs By Tax Pro
const getJobsByTaxPro = ({ uuid }) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/jobs/getJob/${uuid}`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: JOBS_ACTION_TYPE.getJobs,
					payload: {
						message: 'success',
						status: response?.data?.success,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: JOBS_ACTION_TYPE.getJobs,
					payload: {
						message: handleJobsError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};

//  Function To GET JOBS BY LEAD
const getJobsByLead = () => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/jobs/getJob-bylead`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: JOBS_ACTION_TYPE.getJobs,
					payload: {
						message: 'success',
						status: response?.data?.success,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: JOBS_ACTION_TYPE.getJobs,
					payload: {
						message: handleJobsError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};

const cancelJobRequest = ({ uuid, obj }) => {
	return async (dispatch) => {
		axios
			.patch(`${BASEURL}/api/jobs/job-request/${uuid}`, obj)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: JOBS_ACTION_TYPE.cancelJobRequest,
					payload: {
						message: 'Request Submitted Successfully',
						status: response?.data?.success,
						data: response?.data?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: JOBS_ACTION_TYPE.cancelJobRequest,
					payload: {
						message: handleJobsError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};

const markJobAsCompleted = ({ uuid, reload }) => {
	return async (dispatch) => {
		axios
			.put(`${BASEURL}/api/jobs/mark-job-completed/${uuid}`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: JOBS_ACTION_TYPE.markJobAsCompleted,
					payload: {
						message: 'Request Submitted Successfully',
						status: response?.data?.success,
						data: response?.data?.data?.data,
					},
				});
			})
			.then(() => {
				reload && reload();
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: JOBS_ACTION_TYPE.markJobAsCompleted,
					payload: {
						message: handleJobsError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};

// FUNCTION TO HANDLE Jobs API ERRORS
const handleJobsError = (type, error) => {
	if (type === 'simple') {
		return error.code === 'ERR_NETWORK'
			? error?.message
			: error?.response?.data?.error
			? error?.response?.data?.error
			: 'Internel Server Error!';
	}
};

const resetJobs = () => {
	return async (dispatch) => {
		dispatch({ type: JOBS_ACTION_TYPE.resetJobs });
	};
};
export const jobs = {
	getJobsByTaxPro,
	getJobsByLead,
	resetJobs,
	cancelJobRequest,
	markJobAsCompleted,
};
