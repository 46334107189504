/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import moment from 'moment';
import { useCallback, useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Checkbox from '../../../components/forms/fields/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { BsThreeDotsVertical } from 'react-icons/bs';
import DatePicker from 'react-datepicker';
import FileSvg from '../../../assets/img/filesvg.svg'
import RequestDocumentSvg from '../../../assets/img/requestfilesvg.svg'
import PlusSvg from '../../../assets/img/plussvg.svg'
import FileBlueSvg from '../../../assets/img/filebluesvg.svg'
import BinSvg from '../../../assets/img/binsvg.svg'
import QuestionaireSvg from '../../../assets/img/quetenionairesvg.svg'
import EditDocumentSvg from '../../../assets/img/editrequestsvg.svg'

import { BASEURL, useAutoReplyHook } from '../../../utilites';
import allActions from '../../../Store/action';

import Header from '../components/Header';
import Preloader from '../components/Preloader';
import Select from '../../../components/forms/fields/Select';
import Input from '../../../components/forms/fields/Input';
import TextArea from '../../../components/forms/fields/TextArea';
import DropDown from '../../../components/forms/fields/DropDown';
import Message from '../../../components/forms/fields/Message';
import UserAvatar from '../../../components/UserAvatar';
import { GLOBAL_VARIABLES } from '../../../utilites/variables';
import Modal from '../../../components/Dashboard/Modal';
import FormPreviewModal from '../../../components/forms/Modal/FormPreviewModal';
import { getCurrentFormsObj } from '../../../utilites/functions';

const invoicePeriodOptions = [
	{ value: '3', label: '3' },
	{ value: '6', label: '6' },
	{ value: '12', label: '12' },
];
const partionalPaymentOption = [
	{ value: 20, label: '20%' },
	{ value: 40, label: '40%' },
	{ value: 60, label: '60%' },
	{ value: 80, label: '80%' },
	{ value: 'custom', label: 'Custom' },
];
const invoiceOptions = [
	{ value: 'FullPayment', label: 'Full Payment' },
	{ value: 'PartialPayment', label: 'Partial Payment' },
	// { value: 'RecurringPayment', label: 'Recurring Payment' },
];

const defaultDocument =[
	{id:1,name:'W-2 (Wages)',description:'W-2 (Wages)', message:''},
	{id:5,name:'Real Estate Taxes',description:'Real Estate Taxes', message:''},
	{id:2,name:'1099 (Banks and Brokerages)',description:'1099 (Banks and Brokerages)', message:''},
	{id:4,name:'1098s (Home Mortgage Interest)',description:'1098s (Home Mortgage Interest)', message:''}
]

const { DocumentOptions } = GLOBAL_VARIABLES;
const EditJob = () => {
	const [{ LoginData }, { personalConnections }, { documentNames, taxPreparation, taxPlanning }] = useSelector(
		(state) => [state.user, state.firebase, state.dashboard]
	);

    
	const [lead, setLead] = useState(null);
	const job = lead?.jobs[0];
	const [alertMessage, setAlertMessage] = useState({ show: false });
	// Form
	const [serviceType, setServiceType] = useState();
	const [completionDate, setCompletionDate] = useState();
	const [invoiceDeadline, setInvoiceDedline] = useState();
	const [cost, setCost] = useState();
	const [uncleKamFee, setUncleKamFee] = useState();
	const [remaning, setRemanning] = useState();
	const [message, setMessage] = useState();
	const [invoiceType, setInvoiceType] = useState();
	const [agreement, setAgreement] = useState(true);
	const [customPartial, setCustomPartial] = useState();
	const [subscriptionOption, setSubscriptionOption] = useState();
	const [deduction, setDeduction] = useState();
	const [isDraft, setIsDraft] = useState(false);
    //
	const [selectedDocuments, setSelectedDocuments]= useState([])
	const [editDocument,setEditDocument] = useState('')
	const [addMessageOnRequest,setAddMessageOnRequest] = useState('')
	const [documentsSelectedInDropdown,setDocumentsSelectedIndropDown] = useState([])
	const [selectedFormNames, setSelectedFormNames] = useState([]);
	const [selectedDocumentIds,setSelectedDocumentIds] = useState([])
	const [selectedForms,setSelectedForms] = useState([])
    
	// handle auto close edit modal
	const [closeEditModal,setCloseEditModal]=useState(false)
	// 
	const [documents, setDocuments] = useState([]);
	const [otherDocumentName, setOtherDocumentName] = useState('');
	const [otherDocumentId, setOtherDocumentId] = useState('')
	const [errors, setErrors] = useState({
        serviceType: false,
		completionDate: false,
		cost: false,
		message: false,
		documents: false,
		otherDocumentName: false,
		general: '',
        });
        
        const { uuid } = useParams();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const handleAutoReply = useAutoReplyHook(dispatch);
        
        const button = useRef();
		console.log(selectedFormNames)
        
        const loadJobByUUID = useCallback((UUID) => {
		axios
			.get(`${BASEURL}/api/jobs/${UUID}`)
			.then((res) => {
				console.log('job data>>>>', res.data);
				setLead(res.data.data);
			})
			.catch((err) => {
				console.log('error>>>>', err);
			});
	}, []);

	useEffect(() => {
		loadJobByUUID(uuid);
	}, [uuid, loadJobByUUID]);

    useEffect(() => {
		dispatch(allActions.dashboard.getDocumentNames());
	}, [dispatch]);

    useEffect(() => {
		const doc = documentsSelectedInDropdown?.find(doc => doc?.description?.toLowerCase() === 'others')

		if (doc) {
			setOtherDocumentId(doc?.id)
        }
	}, [selectedDocuments, setErrors, errors, documentNames, otherDocumentName, documentsSelectedInDropdown]);


	useEffect(() => {
		if (job) {
			if (job.status_id === 3 || job.status_id === 4) {
				navigate(`/dashboard/jobs/${job?.uuid}`);
			} else {
				setCompletionDate(new Date(job?.completed_at));
				setInvoiceDedline(new Date(job?.payment_due_date));
				setCost(job?.cost);
				// 
				// const givenDoc=job?.documents?.map((doc)=>doc?.jobdocument?.documentId.toString())

				// setDocuments()
				setUncleKamFee(((parseInt(LoginData?.userpro?.percentage) / 100) * job?.cost).toFixed(2));
				setRemanning(
					(
						job?.cost - ((parseInt(LoginData?.userpro?.percentage) / 100) * job?.cost).toFixed(2)
					).toFixed(2)
				);
				setMessage(job?.description);
				setServiceType(job?.service?.id)
				// If status_id is 5 (draft) then set isDraft to true
				if (job?.status_id === 5) {
					setIsDraft(true);
				}
				// Set Invoice Type
				if (job?.invoice_type === 'full') {
					setInvoiceType(invoiceOptions[0]);
				} else if (job?.invoice_type === 'partial') {
					setInvoiceType(invoiceOptions[1]);
					setDeduction(
						partionalPaymentOption.find((item) => item.value === job?.invoice_data?.deduction)
					);
				} else if (job?.invoice_type === 'subscription') {
					setInvoiceType(invoiceOptions[2]);
					setSubscriptionOption(
						invoicePeriodOptions.find((item) => item.value === job?.invoice_data?.deduction)
					);
				}
               
			}
            const filteredDocs = job?.documents?.map((doc) =>  {
                return {id:doc.jobdocument.doc_name_id,doc_id:doc.jobdocument.documentId,name:doc.jobdocument.name,description:doc.jobdocument.name,message:doc.jobdocument.message}
            } )
            const filteredFormData = job?.form_data 
            ? job.form_data
                .map((form) => {
                  if (Object.keys(form).includes('formData')) {
                    return { value: form.name, label: form.name, message:form.message };
                  }
                })
                .filter(item => item !== undefined) 
            : [];
            setSelectedDocuments(filteredDocs)
            setSelectedFormNames(filteredFormData)
		}
	}, [job]);



	useEffect(() => {
		if(closeEditModal === false) {return};
		// if Success
		if (taxPreparation?.state === true) {
			setAlertMessage({ show: false });
			changeLoading(false);
			navigate(`/dashboard/jobs/${job?.uuid}`);
		}

		// if Error
		if (taxPreparation?.state === false) {
			// Show Message Alert
			setAlertMessage({
				show: true,
				type: 'Error',
				text: taxPreparation.message,
			});
			changeLoading(false);
		}
	}, [taxPreparation.message, taxPreparation?.state,closeEditModal]);

	useEffect(() => {
		// if Success
		if (taxPlanning?.state === true) {
			setAlertMessage({ show: false });
			changeLoading(false);
			navigate(`/dashboard/jobs/${job?.uuid}`);
		}

		// if Error
		if (taxPlanning?.state === false) {
			// Show Message Alert
			setAlertMessage({
				show: true,
				type: 'Error',
				text: taxPlanning.message,
			});
			changeLoading(false);
		}
	}, [taxPlanning.message, taxPlanning?.state]);

	useEffect(() => {
		setSelectedDocumentIds(selectedDocuments?.map((doc) => doc?.id ))
	},[selectedDocuments])

	useEffect(() => {
       setSelectedForms(selectedFormNames?.map(name => name?.value))
	},[selectedFormNames])

	const getInvoiceData = () => {
		let obj = { invoice_data: {}, payment_due_date: invoiceDeadline };
		// For Partial Payment
		if (invoiceType?.value === 'PartialPayment') {
			obj.invoice_data.deductionType = deduction?.value === 'custom' ? '' : '%';
			obj.invoice_type = 'partial';
			obj.invoice_data.deduction = deduction?.value === 'custom' ? customPartial : deduction?.value;
			obj.invoice_data.fullAmount = cost;
			obj.invoice_data.remainingAmount =
				deduction?.value === 'custom'
					? cost - customPartial
					: cost - ((deduction?.value / 100) * cost).toFixed(2);
		}
		// For Subscription Payment
		if (invoiceType?.value === 'RecurringPayment') {
			obj.invoice_type = 'subscription';
			obj.invoice_data.deduction = subscriptionOption?.value;
		}
		if (invoiceType.value === 'FullPayment') {
			obj.invoice_type = 'full';
		}
		return obj;
	};
	// *******************************************
	const callTaxPlanning = (taxPlanObj) => {
		reset_mesage();
		dispatch(allActions.dashboard.resetTaxPlanning());
		dispatch(allActions.dashboard.taxPlanning({ obj: taxPlanObj }));
	};

	const callTaxPreparation = (taxPreparationObj) => {
		reset_mesage();
		dispatch(allActions.dashboard.resetTaxPreparation());
		dispatch(allActions.dashboard.taxPreparation({ obj: taxPreparationObj }));
	};

	const reset_mesage = () => {
		setAlertMessage({ show: false });
	};
	useEffect(() => {
		if(parseInt(serviceType) !== 1 && documentNames.length > 0){

			const getIds = job?.documents?.map((data) => documentNames.find((DD) => DD?.name === data?.name))
			if (getIds) {
				const ff = [...new Set(getIds.filter(data => data).map(data => data?.id?.toString()))];
				setDocuments(ff)
			}
		}
	}, [job?.documents?.length,serviceType,documentNames])

	
	// function to handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();
		const documents = selectedDocuments
		let selectedDocNames = documentNames?.filter((obj) => documents?.some(doc => doc.id === obj.id));
		let taxPlanObj = {
			lead_id: lead.id,
			service_id: serviceType,
			method: 'create',
			docName: selectedDocNames?.map((item) => item?.name),
		};
		// 
		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });
		changeLoading(true);
		dispatch(allActions.dashboard.resetTaxPlanning());
		dispatch(allActions.dashboard.resetTaxPreparation());
		setAlertMessage({ show: false });
		axios
			.put(`${BASEURL}/api/jobs/update/${job?.uuid}`, {
				pro_id: LoginData?.userpro?.id || job.userpro?.id,
				additional: message,
				completionDate,
				isDraft,
				cost,
				// 
				documents,
				other_document_name: otherDocumentName,
				customer_id: lead.user.id,
				service_id: serviceType,
                job_uuid:job.uuid,
				// 
				...getInvoiceData(),
			})
			.then((response) => {
				if (
					!(
						taxPlanObj?.service_id === 2 &&
						(LoginData?.isTaxPro || LoginData?.isAdmin || LoginData?.isAccountManager) &&
						taxPlanObj?.service_id === 1
					)
				) {
					button.current.disabled = false;
				}
				// if SERVICE IS TAX PLAN
				if (taxPlanObj?.service_id === 'others' || taxPlanObj?.service_id === 2 || taxPlanObj?.service_id === '2' ||  taxPlanObj?.service_id === 3  || taxPlanObj?.service_id === '3' ||  taxPlanObj?.service_id === 4  || taxPlanObj?.service_id === '4') {
					taxPlanObj.formName = selectedFormNames?.map((form) => {
						return { name: form.value, message: form.message }
					});
					taxPlanObj.formData = {};
					callTaxPlanning(taxPlanObj);
				}
				// if SERVICE IS TAX PREPARATION
				else if (taxPlanObj?.service_id === '1' || taxPlanObj?.service_id === 1) {
					// Modify Object To Tax Preparation
					delete taxPlanObj.docName;
					taxPlanObj.formName = selectedFormNames?.map((form) => {
						return { name: form.value, message: form.message }
					});
					taxPlanObj.formData = {};
					callTaxPreparation(taxPlanObj);
				}
				// FOR OTHER SERVICES
				else {
					changeLoading(false);
					// Navigate to lead details page
					navigate(`/dashboard/jobs/${response?.data?.data?.uuid}`, {
						state: { message: 'Contract Created and sent to customer Successfully!' },
					});
				}
				changeLoading(false);
				handleAutoMessaging(job.uuid);
				navigate(`/dashboard/jobs/${job?.uuid}`);
				setCloseEditModal(true)
			})
			.catch((error) => {
				console.log("calling", 6)
				changeLoading(false);
				setAlertMessage({
					show: true,
					type: 'Error',
					text: error?.response?.data?.error || error?.message,
				});
				button.current.disabled = false;
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

	const handleAutoMessaging = (uuid) => {
		if (!isDraft) {
			handleAutoReply({
				personalConnections,
				LoginData,
				receiver: lead?.user,
				type: 'simple',
				encodedString: `<p>Hi ${lead?.user?.firstname}, I have updated the contract based on your request. Please click the link below to view and sign here <a href="${window.location.origin}/dashboard/contract/${uuid}">Contract</a> to get started on the job. Thanks</p>`,
			});
		}
	};

	useEffect(() => {
		if (isDraft === true) {
			// Set default values for draft contract payment info part.
			setCompletionDate(new Date());
			setCost(0);
			setUncleKamFee(0);
			setRemanning(0);
			setInvoiceType({ label: 'Full Payment', value: 'FullPayment' });
			setInvoiceDedline(new Date());
		} else {
			// Clean up the default values for draft contract payment info part.
			setCompletionDate();
			setCost();
			setUncleKamFee();
			setRemanning();
			setInvoiceType();
			setInvoiceDedline();
		}
	}, [isDraft]);

    const handleSelectDocument = (document) => {
		const isDocument = selectedDocuments.find(doc => doc.id === document.id)
		if(!isDocument){
			setSelectedDocuments(prev => [...prev,document])
			setDocumentsSelectedIndropDown(prev => [...prev,document])
		}else{
		const filterAndDelete = selectedDocuments.filter(document => document.id !== isDocument.id)
		setSelectedDocuments(filterAndDelete)
		setDocumentsSelectedIndropDown(filterAndDelete)
		}
	} 

    const handleDeleteSelectedDocument = (id) => {
		const filterAndDelete = selectedDocuments.filter(document => document.id !== id)
		setSelectedDocuments(filterAndDelete)
		setDocumentsSelectedIndropDown(filterAndDelete)
	}

	const openJobData = (form) => {
		let formData={
			name:form?.value,
			formData:getCurrentFormsObj(form?.value)
		}
		dispatch(allActions.dashboard.resetTaxPreparation());
		dispatch(allActions.taxForms.dispatchViewFormDetails({document:formData,navigate:()=>document.querySelector('.formPreview').click()}));
	};


	if (lead !== null && job !== null) {
		return (
			<div className='clearfix'>
				<Header name='Edit Job' />

				<div className='container page'>
					<form className='clearfix pt-4 mb-4' onSubmit={handleSubmit}>
						{alertMessage.show ? <Message message={alertMessage} /> : <></>}
						<div className='card border-1 border-light rounded p-4 mb-4'>
							<div className='card-header d-flex justify-content-between bg-white border-0'>
								<h3 className='card-title text-primary fw-bold'>Client</h3>
								<div className='card-actions'>
									<span className='btn btn-sm text-primary'>+</span>
									<span className='btn btn-sm text-primary'>
										<BsThreeDotsVertical />
									</span>
								</div>
							</div>
							<div className='card-body'>
								<div className='d-flex align-items-center'>
									<div>
										<UserAvatar
											avatar={lead.user.avatar}
											alt={`${lead.user.firstname} ${lead.user.lastname}`}
											className='img-fluid object-fit-cover rounded-circle'
											style={{ height: 40 }}
										/>
									</div>
									<p className='text-dark m-0 ms-2'>
										{`${lead.user.firstname} ${lead.user.lastname}`}
									</p>
								</div>
							</div>
						</div>
						<div className='card border-1 border-light rounded p-4 mb-4'>
							<div className='card-header d-flex justify-content-between bg-white border-0'>
								<h3 className='card-title text-primary fw-bold'>Terms</h3>
								<div className='card-actions'>
									<span className='btn btn-sm text-primary'>+</span>
									<span className='btn btn-sm text-primary'>
										<BsThreeDotsVertical />
									</span>
								</div>
							</div>
							<div className='card-body'>
								<div className='mb-4'>
									<div className='text-dark'>Service type</div>
									<Select
										value={serviceType}
										name='serviceType'
										placeholder='Service type'
										fn={setServiceType}
									>
										<option value={job?.service?.id || 'others'}>
											{job?.service?.name || job?.others}
										</option>
									</Select>
								</div>
								<div className={`mb-${job?.status_id === 5 ? '4' : '0'}`}>
									<div className='text-dark'>Description</div>
									<TextArea
										inputName='form-control'
										defaultValue={message}
										placeholder='Enter details of the job here'
										name='message'
										fn={setMessage}
										row='6'
										required={false}
										showLabel={false}
									/>
								</div>
								{job?.status_id === 5 ? (
									<div className='mb-0'>
										<div className='form-check'>
											<input
												type='checkbox'
												name='isDraft'
												className='form-check-input'
												id='isDraft'
												value={isDraft ? 1 : null}
												checked={isDraft}
												onChange={(e) => setIsDraft(e.target.checked)}
											/>
											<label className='form-check-label' htmlFor='isDraft'>
												Draft Mode
											</label>
										</div>
									</div>
								) : null}
							</div>
						</div>
						{isDraft ? null : (
							<div className='card border-1 border-light rounded p-4 mb-4'>
								<div className='card-header d-flex justify-content-between bg-white border-0'>
									<h3 className='card-title text-primary fw-bold'>Payment</h3>
									<div className='card-actions'>
										<span className='btn btn-sm text-primary'>
											<BsThreeDotsVertical />
										</span>
									</div>
								</div>
								<div className='card-body'>
									<div className='mb-4'>
										<div className='text-dark'>Completion date</div>
										<div className='form-floating'>
											<DatePicker
												className='form-control'
												wrapperClassName='d-block'
												selected={completionDate}
												onChange={(date) => setCompletionDate(date)}
												dateFormat='MM/dd/yyyy'
												placeholderText='mm/dd/yyyy'
												minDate={new Date()}
												isClearable
											/>
										</div>
									</div>
									<div className='mb-4'>
										<div className='text-dark'>Total Cost</div>
										<Input
											type='number'
											value={cost}
											name='cost'
											placeholder='00.00'
											fn={(value) => {
												setCost(value);
												setUncleKamFee(
													((parseInt(LoginData?.userpro?.percentage) / 100) * value).toFixed(2)
												);
												setRemanning(
													(
														value -
														((parseInt(LoginData?.userpro?.percentage) / 100) * value).toFixed(2)
													).toFixed(2)
												);
											}}
											// hasValidationError={errors.reason}
											required={true}
											showLabel={false}
										/>
									</div>
									<div className='mb-4'>
										<div className='text-dark'>{LoginData?.userpro?.percentage}% Uncle Kam fee</div>
										<Input
											type='number'
											value={uncleKamFee}
											name='cost'
											placeholder='00.00'
											fn={setUncleKamFee}
											// hasValidationError={errors.reason}
											required={false}
											showLabel={false}
											isDisabled={true}
										/>
									</div>
									<div className='mb-4'>
										<div className='text-dark'>You'll receive</div>
										<Input
											type='number'
											value={remaning}
											name='cost'
											placeholder='00.00'
											fn={setRemanning}
											// hasValidationError={errors.reason}
											required={false}
											showLabel={false}
											isDisabled={true}
										/>
									</div>
									<div className='mb-4'>
										<div className='text-dark'>Select invoice type</div>
										<DropDown
											options={invoiceOptions}
											value={invoiceType}
											setValue={setInvoiceType}
											label={'Select Invoice Type'}
											showLabel={false}
										/>
									</div>
									{invoiceType?.value === 'RecurringPayment' ? (
										<div className='mb-3 col-md-12'>
											<div className='text-dark'>Select subscription duration</div>
											<DropDown
												options={invoicePeriodOptions}
												value={subscriptionOption}
												setValue={setSubscriptionOption}
												label={'Subscription Duration'}
												showLabel={false}
											/>
										</div>
									) : (
										''
									)}
									{invoiceType?.value === 'PartialPayment' ? (
										<div className='mb-3 col-md-12'>
											<DropDown
												options={partionalPaymentOption}
												value={deduction}
												setValue={setDeduction}
												label={'Deposit'}
												showLabel={false}
											/>
										</div>
									) : (
										''
									)}

									{deduction?.value === 'custom' && (
										<div className='mb-4'>
											<div className='text-dark'>Custom Amount</div>
											<Input
												type='number'
												value={customPartial}
												name='partialValue'
												placeholder='00.00'
												fn={(e) => {
													if (Number(e) <= Number(cost)) {
														setCustomPartial(e);
													}
												}}
												required={false}
												showLabel={false}
											/>
										</div>
									)}
									<div>
										<div className='mb-4'>
											<div className='text-dark'>Invoice expiry date</div>
											<div className='form-floating'>
												<DatePicker
													className='form-control'
													wrapperClassName='d-block'
													selected={invoiceDeadline}
													onChange={(date) => setInvoiceDedline(date)}
													dateFormat='MM/dd/yyyy'
													placeholderText='mm/dd/yyyy'
													minDate={moment().add(1, 'days').toDate()}
													isClearable
													required
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						{/* start updating missing code */}
                        <div className='card border-1 border-light rounded p-4 mb-4 px-5'>
							<h3 className="card-title text-primary fw-bold ">Tax Organization Form</h3>
							<div className='d-flex align-items-center justify-content-between mt-3 '>
								<div className='d-flex gap-2 align-items-center '>
									<img src={QuestionaireSvg} alt='file' />
									<p className='mt-0 mb-0 text-dark fw-bold'>Forms ({selectedFormNames?.length})</p>
								</div>
								{/* <div className='d-flex align-items-center gap-2'>
                                        <p className='mt-0 mb-0 text-dark fw-bold'>Add questionnaire</p>
                                                 <Input
										    		type="search"
												value={customPartial}
												name="partialValue"
												placeholder="Search or Select"
												required={false}
												showLabel={false}
											/>
                                    </div> */}
							</div>

							{selectedFormNames?.length > 0 && selectedFormNames.map((name) => {
                                    return <div key={name.value} className='mt-5 mb-3 d-flex align-items-start justify-content-between border-b pb-3'>
									<div className='d-flex gap-4' role='button' onClick={()=>{
										// openJobData(name);
									}}>
										<img className='mt-1 icon-h' src={QuestionaireSvg} alt='requestfile' onClick={() => {
											openJobData(name);
											}} />
										<div>
											<div className='d-flex align-itesm-center gap-5' onClick={() => {
											openJobData(name);
											}}>
												<p className='mb-0 fw-bold'>{name.value}</p>
											</div>
											<p className='mb-0 mt-1 max-w'>{name.label} </p>
											<p onClick={() => setAddMessageOnRequest(name.value)} className='mt-1 mb-o text-primary cursor-pointer'>Add a Message</p>
											{(addMessageOnRequest === name.value || name?.message) && <textarea
													className='w-100'
													value={name?.message}
													onChange={(e) => {
														const edited = selectedFormNames?.map(n => {
															if (n.value === name.value) {
																return {
																	value: n.value,
																	label:n.label,
																	message: e.target.value
																}
															}
															return n
														})
														setSelectedFormNames(edited)
													}}
												></textarea>}
										</div>
									</div>
									<div className='d-flex gap-4'>
										<img className='cursor-pointer icon' onClick={() => {
											const deletedFormName = selectedFormNames.filter(formname => formname.value !== name.value)
											setSelectedFormNames(deletedFormName)
										}} src={BinSvg} alt='delete' />
									</div>
								</div>
							})}


							<div className='border-dashed rounded mt-3 bg-muted-200  pb-5 bg-color'>
								<div className='mt-5 d-flex flex-column justify-content-center align-items-center'>
									<h3 className='text-center text-grey-400 fw-bold'>Smart Questionnaire</h3>
									<p className='text-center w-85 mt-2 text-2 px-5 '>Select from among our pre-built Smart Questionnaires. Your Client’s input are saved as they answer each question, allowing them to continue right from where they last left off.</p>
								</div>
								<div className='row d-flex justify-content-start  card-cont'>
									{/* <div className='border-solid d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded'>
                                        <img style={{height:'40px'}} src={PlusSvg} alt='plus' />
                                        Create New
                                    </div> */}
									{DocumentOptions.map((option) => {
										return <div key={option.label} onClick={() => 
										{const isForm = selectedFormNames?.find((name) => name.value === option.value)
											if(isForm){
												const deletedFormName = selectedFormNames?.filter((name) => name.value !== option.value ) 
												setSelectedFormNames(deletedFormName)
											}else{
												setSelectedFormNames((prev) => [...prev, {name:option.value,value:option.value,label:option.label, message:''}])
											}
											setAddMessageOnRequest('')
										}
										} className={`px-5 text-center border-solid ${selectedForms?.includes(option.value) ? 'border-primary' : ''} d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded`}>
											<img style={{ height: '40px' }} src={FileBlueSvg} alt='plus' />
											{option.value}
										</div>
									})}

								</div>
							</div>

						</div>

                        <div className="card border-1 border-light rounded p-4 mb-4 px-5">
							{/* <h3 className="card-title text-primary fw-bold">Documents request</h3> */}
							<h3 className="card-title text-primary fw-bold">Documents request</h3>
                        <div>								
								<div className='d-flex align-items-center justify-content-between '>
									<div className='d-flex gap-2 align-items-center '>
										<img src={FileSvg} alt='file' />
										<p className='mt-0 mb-0 text-dark fw-bold'>File Requests ({selectedDocuments?.length})</p>
									</div>
									{/* <div className='d-flex align-items-center gap-2'>
                                        <p className='mt-0 mb-0 text-dark fw-bold'>Add questionnaire</p>
                                                 <Input
										    		type="search"
												value={customPartial}
												name="partialValue"
												placeholder="Search or Select"
												required={false}
												showLabel={false}
											/>
                                    </div> */}
								</div>
								{selectedDocuments?.length > 0 && selectedDocuments.map(document => {
									return <div key={document.id} className='mt-5 mb-3 d-flex align-items-start justify-content-between border-b' >
										<div className='d-flex gap-4 w-50'>
											<img className='mt-1 icon-h' src={RequestDocumentSvg} alt='requestfile' />
											<div className='w-100'>
												<div className='d-flex align-itesm-center gap-5'>
													<input disabled={editDocument !== document?.id} className='mb-0 fw-bold w-100 border-0 outline-0' onChange={(e) => {
														const edited = selectedDocuments?.map(doc => {
															if (doc.id === document.id) {
																return {
																	id: doc.id,
																	name: e.target.value,
																	description: doc.description,
																	message: doc.message
																}
															}
															return doc
														})
														setSelectedDocuments(edited)
													}} value={document.name} />
												</div>
												<input onChange={(e) => {
													const edited = selectedDocuments?.map(doc => {
														if (doc.id === document.id) {
															return {
																id: doc.id,
																name: doc.name,
																description: e.target.value,
																message: doc.message
															}
														}
														return doc
													})
													setSelectedDocuments(edited)
												}} disabled={editDocument !== document?.id} type='text' value={document.description} className='mb-0 mt-1 max-w w-100 border-0 mb-3' />
												{/* {(addMessageOnRequest === document?.id || document.message) && <textarea
													className='w-100'
													value={document?.message}
													onChange={(e) => {
														const edited = selectedDocuments?.map(doc => {
															if (doc.id === document.id) {
																return {
																	id: doc.id,
																	name: doc.name,
																	description: doc.description,
																	message: e.target.value
																}
															}
															return doc
														})
														setSelectedDocuments(edited)
													}}
												></textarea>}
												<p onClick={() => setAddMessageOnRequest(document?.id)} className='mt-1 mb-o text-primary cursor-pointer'>Add a Message</p> */}
											</div>
										</div>
										<div className='d-flex gap-4 align-items-center justify-content-center'>
											<div className='d-flex align-items-center gap-2'>
												{editDocument && editDocument === document?.id ?
													<p className='mb-0 mt-0 text-success fw-bold cursor-pointer' onClick={() => setEditDocument('')}>Save</p>
													:
													<img onClick={() => setEditDocument(document?.id)} className='cursor-pointer icon' src={EditDocumentSvg} alt='edit' />
												}
												<img onClick={() => handleDeleteSelectedDocument(document.id)} className='cursor-pointer icon' src={BinSvg} alt='delete' />
											</div>
										</div>
									</div>
								})}
								<div className='border-dashed rounded mt-3 bg-muted-200 pb-5 bg-color'>
									<div className='mt-5 d-flex flex-column justify-content-center align-items-center px-3'>
										<h3 className='text-center text-grey-400 fw-bold'>Request Forms and Supporting Documents</h3>
										<p className='text-center w-85 mt-2 text-2 '>Pick from any of the pre-defined request templates or type your own and save as template.
											<br />  Your client can directly drag and drop files onto your request to complete them.</p>
									</div>
									<div className='row d-flex justify-content-start  card-cont'>
										<Link
											to="#"
											// className={`dropdown-item text-light invoiceModal`}
											className='border-solid d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded'
											data-bs-toggle="modal"
											data-bs-target="#editDocumentListModal"
										>
											<img style={{ height: '40px' }} src={PlusSvg} alt='plus' />
											Create New
										</Link>
										{/* <div data-bs-toggle='modal'
										data-bs-target='#doqumentsList'
										aria-controls='doqumentsList' className='border-solid d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded'>
									</div> */}
										{defaultDocument.map((document) => {
											return <div key={document.id} onClick={() => handleSelectDocument(document)} className={`px-5 text-center border-solid d-flex flex-column ${selectedDocumentIds.includes(document.id) ? 'border-primary' :''} justify-content-center align-items-center gap-2 custom-card rounded`}>
												<img style={{ height: '40px' }} src={FileBlueSvg} alt='plus' />
												{document.name}
											</div>
										})}
									</div>
								</div>
							</div>
                            </div>

						{/* end updating missing code */}
						<div className='clearfix p-2 mt-4'>
							<div className='form-check'>
								<input
									type='checkbox'
									name='agreed'
									className='form-check-input'
									id='agreed'
									value='1'
									checked={agreement}
									onChange={(e) => setAgreement(e.target.checked)}
									disabled={true}
								/>
								<label className='form-check-label' htmlFor='agreed'>
									Yes, I understand and agree to the Uncle Kam Terms of Service, including the{' '}
									<Link to='/user-agreement'>User Agreement</Link> and{' '}
									<Link to='/privacy-policy'>Privacy Policy</Link>.
								</label>
							</div>
						</div>
						<div className='clearfix p-2 mt-4 d-flex justify-content-end'>
							<span className='btn btn-outline-primary me-3' onClick={() => navigate(-1)}>
								Cancel
							</span>
							<button
								className={`btn btn-primary${Object.values(errors).indexOf(true) > -1 || !agreement
										? ' disabled'
										: ''
									}`}
								type='submit'
								ref={button}
							>
								{isDraft ? "Save" : "Publish"}
							</button>
						</div>
					</form>
				</div>
                <Modal
								title="Create new Document"
								bodyClass="p-2 text-center text-dark text-opacity-50 w-100"
								trigger="editDocumentListModal"
								showBtn={false}
								btnClass="editDocumentListModalClose"
							>
								<div className="card border-1 border-light rounded p-1">
									<div className="card-header d-flex justify-content-between bg-white border-0">

									</div>
									<div className="card-body">
										<div>

											<div className="row text-start">
												{documentNames?.map((documentName) => (
													<div onClick={() => {
														const isDocument = documentsSelectedInDropdown.find(doc => doc.id === documentName.id)
														if (isDocument) {
															const filterAndDelete = documentsSelectedInDropdown.filter(document => document.id !== isDocument.id)
															setDocumentsSelectedIndropDown(filterAndDelete)
														}
													}} className="col-6 mt-2" key={documentName.id}>
														<Checkbox
															wrapperClass="form-check"
															name="documents"
															state={documentsSelectedInDropdown}
															value={documentName.id}
															label={documentName.name}
															checked={selectedDocuments.find(doc => doc.id === documentName.id)}
															fn={() => setDocumentsSelectedIndropDown((prev) => [...prev, { id: documentName?.id, name: documentName?.name, description: documentName?.name, message: '' }])}
															id={`doc-${documentName.id}`}
															required={false}
														/>
													</div>
												))}
											</div>

											{/* {parseInt(serviceType) === 1 && (
												<div className="clearfix">
											<div className="text-dark">Form type</div>
											<DropDown
												options={DocumentOptions}
												value={selectedFormNames}
												setValue={setselectedFormNames}
												label={'Select Form Type'}
														showLabel={false}
													/>
												</div>
											)} */}
											<button disabled={!documentsSelectedInDropdown?.length > 0} onClick={(e) => {
												e.preventDefault()
												const isDocument = selectedDocuments?.find(doc => doc.id === document.id)
												const idsInB = new Set(selectedDocuments?.map(doc => doc.id));
												const filteredA = documentsSelectedInDropdown?.filter(doc => !idsInB.has(doc.id));
												if (!isDocument) {
													setSelectedDocuments((prev) => [...prev, ...filteredA])
												}
												document.querySelector('.editDocumentListModalClose').click()
											}} className='btn btn-primary mt-3'>Add Documents</button>
										</div>

										{otherDocumentId && (
											<div className="row text-start mt-4">
												<input type='text'
													onChange={(e) => {
														const edited = documentsSelectedInDropdown?.map(doc => {
															if (doc.id === otherDocumentId) {
																return {
																	id: doc.id,
																	name: e.target.value,
																	description: doc.description,
																	message: doc.message
																}
															}
															return doc
														})
														setSelectedDocuments(edited)
													}}

												/>
												{/* <Input
											type="text"
											value={() => {
												const filter = selectedDocuments?.find(doc => doc.name.toLowerCase() === 'others')
												return filter?.name
											}}
											name="documentName"
											placeholder="Document Name"
											label="Type document name here"
											fn={(e) => {
												const filter = selectedDocuments?.find(doc => doc.name.toLowerCase() === 'others')
												const edited = selectedDocuments?.map(doc => {
													if (doc.id === filter?.id) {
														return {
															id: doc.id,
															name: e,
															description:doc.description,
															message: doc.message
														}
													}
													return doc
												})
												setSelectedDocuments(edited)
											}}
											hasValidationError={errors.otherDocumentName}
											autoFocus={true}
										/> */}
											</div>
										)}

										{/* {isDraft && selectedFormNames ? (
											<>
												<div className="mt-4">
													<div className="text-dark">Additional documents</div>
													<div className="row text-start">
														{documentNames?.map((documentName) => (
															<div className="col-6 mt-2" key={documentName.id}>
																<Checkbox
																	wrapperClass="form-check"
																	name="documents"
																	state={documents}
																	value={documentName.id}
																	label={documentName.name}
																	fn={setDocuments}
																	id={`doc-${documentName.id}`}
																	required={false}
																/>
															</div>
														))}
													</div>
												</div>
											</>
										) : null} */}
									</div>
								</div>
							</Modal>
							<FormPreviewModal />
			</div>
		);
	} else {
		return <Preloader />;
	}
};

export default EditJob;
