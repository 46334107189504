import CustomerMessageBoard from '../leads/components/CustomerMessageBoard';
import Header from '../components/Header';
import Footer from '../components/Footer';

const MessageList = () => {
	return (
		<div className='clearfix dashboard-Page'>
			<Header name='Messages' />
			<div className='row justify-content-center'>
				<div className='col-md-12'>
					<CustomerMessageBoard />
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default MessageList;
