import React from "react";
import VerificationForm from "../../forms/VerificationForm";

const DriverLicense = ({ setActive }) => {
	return (
		<div>
			<div className="w-full  border-grey rounded px-2 py-2">
				{/* <DriverLicenseForm  active={active} setActive={setActive} /> */}
				<VerificationForm
					setActive={setActive}
					note='If you have already completed the Verification process, please select "Skip" to proceed to
					the next step.'
					steps={true}
				/>
			</div>
		</div>
	);
};

export default DriverLicense;
