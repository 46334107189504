import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//import { Redirect, Route, Switch } from "react-router";
import io from "socket.io-client";
import { BASEURL } from "../../utilites";
// Dashboard import
import OnBoard from "../../pages/dashboard/OnBoard";
import UnderReview from "../../pages/dashboard/UnderReview";
//import Otp from "../../pages/auth/Otp";

const ProtectDashboard = ({ Component }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user] = useSelector((state) => [state.user]);
  // if (user?.LoginData?.authorization==1 || user?.error=='session expired') {
  //   return <Otp/>;

  // }else{
  useEffect(() => {
    const socket = io(BASEURL, { transports: ["websocket"] });
    // Event listeners for various socket events
    socket.on("connect", () => {
      console.log("Connected to server");
      if (user && user.LoginData) {
        socket.emit("clientData", user.LoginData.uuid);
      }
      socket.on("disconnect", () => {
        console.log("Disconnected from server");
      });
    });
    // Other event listeners and logic
    return () => {
      socket.disconnect();
    };
  }, [user]);

  useEffect(() => {
    const currentTheme = localStorage.getItem("theme") || "light";
    document.documentElement.setAttribute("data-bs-theme", currentTheme);
    // Validate user theme
    if (user && user.LoginData) {
      const theme = user.LoginData?.settings?.theme;
      // Set user theme
      localStorage.setItem("theme", theme);
      document.documentElement.setAttribute("data-bs-theme", theme);
    }

    return () => {
      document.documentElement.removeAttribute("data-bs-theme");
    };
  }, [user]);

  if (user.isTaxPro) {
    switch (user.statusId) {
      case 0:
        if (user.statusId === 0) {
          if (location.pathname === "/dashboard/messages") return <Component />;
          return <OnBoard />;
          // if (location.pathname == "/dashboard/onboard") {
          // } else {
          //   navigate("/dashboard/onboard");
          // }
        }
        break;
      case 1:
        if (user.statusId === 1) {
          if (location.pathname === "/dashboard/messages") return <Component />;
          if (location.pathname === "/dashboard/settings") return <Component />;
          return <UnderReview />;
          // if (location.pathname == "/dashboard/underReview") {
          // } else {
          //   navigate("/dashboard/underReview");
          // }
        }
        break;
      case 2:
        if (user.statusId === 2) {
          if (!Component || location.pathname === "/dashboard/users") {
            navigate("/dashboard/");
            return;
          }
          return <Component />;
        }
        break;
      case 3:
        if (user.statusId === 3) {
          if (!Component) {
            navigate("/dashboard/");
            return;
          }
          return <Component />;
        }
        break;
        case 6:
          if (user.statusId === 6 ) {
            if (!Component) {
              navigate("/dashboard/");
              return;
            }
            return <Component />;
          }
          break;
      default:
        break;
    }
  } else if (
    user.isAdmin ||
    user.isRegular ||
    user.LoginData.isAccountManager
  ) {
    return <Component />;
  }
  // }
};
export default ProtectDashboard;
