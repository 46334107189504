// import { Link } from 'react-router-dom';

function getNotificationContentByActionType({ action_type, current_user, user_by, user_for }) {
	let initiator_fullname = 'System';
	let target_fullname = 'You';

	if (user_by && user_by !== 'system') {
		if (user_by?.uuid === current_user) {
			initiator_fullname = 'You';
		} else {
			initiator_fullname = `${user_by?.firstname} ${user_by?.lastname}`;
		}
	}

	if (user_for) {
		if (user_for?.uuid === current_user) {
			target_fullname = 'You';
		} else {
			target_fullname = `${user_for?.firstname} ${user_for?.lastname}`;
		}
	}

	switch (action_type) {
		case 'LEAD_ASSIGNED':
			return `${initiator_fullname} assigned a new lead to ${target_fullname}.`;
		case 'LEAD_DELETED':
			return `${target_fullname} deleted a lead.`;
		case 'DOCUMENTS_REQUESTED':
			return `${initiator_fullname} requested new documents from ${target_fullname}.`;
		case 'CUSTOMER_UPLOADED_DOCUMENT':
			return `${initiator_fullname} uploaded a document for ${target_fullname}.`;
		case 'TAXPRO_UPLOADED_DRAFT':
			return `${initiator_fullname} uploaded a draft document for ${target_fullname}.`;
		case 'TAXPRO_REGISTERED':
			return `${target_fullname} registered as a tax professional.`;
		case 'USER_LOGGED_IN':
			return `${target_fullname} logged in.`;
		case 'INVOICE_CREATED':
			return `${initiator_fullname} created a new invoice for ${target_fullname}.`;
		case 'INVOICE_PAID':
			return `${initiator_fullname} paid invoice for ${
				target_fullname === 'You' ? 'Your' : `${target_fullname}'s`
			} job.`;
		case 'JOB_CREATED':
			return `${initiator_fullname} created a new contract for ${target_fullname}.`;
		case 'JOB_UPDATED':
			return `${initiator_fullname} updated ${
				target_fullname === 'You' ? 'Your' : `${target_fullname}'s`
			} contract.`;
		case 'JOB_CANCELLED':
			return `${initiator_fullname} cancelled ${
				target_fullname === 'You' ? 'Your' : `${target_fullname}'s`
			} contract.`;
		case 'JOB_DELETED':
			return `${initiator_fullname} deleted ${
				target_fullname === 'You' ? 'Your' : `${target_fullname}'s`
			} contract.`;
		case 'CONTRACT_MODIFICATION_REQUESTED':
			return `${initiator_fullname} requested a modification for ${
				target_fullname === 'You' ? 'Your' : `${target_fullname}'s`
			} contract.`;
		case 'DRAFT_DOCUMENT_MODIFICATION_REQUESTED':
			return `${initiator_fullname} requested a modification for ${
				target_fullname === 'You' ? 'Your' : `${target_fullname}'s`
			} draft document.`;
		case 'JOB_CONFIRMED':
			return `${initiator_fullname} confirmed ${
				target_fullname === 'You' ? 'Your' : `${target_fullname}'s`
			} contract.`;
		case 'INVITED_TO_APPOINTMENT':
			return `${initiator_fullname} is inviting ${target_fullname} to an appointment.`;
		case 'EDITED_APPOINTMENT':
			return `${initiator_fullname} edited an appointment.`;
		case 'JOB_MODIFICATION_REQUESTED':
			return `${initiator_fullname} Request Modification ${
				target_fullname === 'You' ? 'Your' : `${target_fullname}'s`
			} Job.`;
		// case 2:
		// 	return `${fullName} signed up.`;
		// case 3:
		// 	return `New lead has been assigned to ${fullName}.`;
		// case 4:
		// 	return `New job has been started for ${fullName}.`;
		// case 5:
		// 	return `New document has been uploaded by ${fullName}.`;
		default:
			return 'No notification content available.';
	}
}

export { getNotificationContentByActionType };
