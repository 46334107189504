import React, { useEffect, useState } from "react";
import Button from "../../fields/Button";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../Store/action";
import MultiLineOneFieldInputs from "../../fields/MultiLineOneFieldInput";
import OtherDataAdditionForm from "./OtherDataAdditionForm";
import { HandleFormData } from "../../../../utilites";

const Income = ({ modules = [] }) => {
	//
	//  ---------------------------------------
	//  Variables , States & Store States
	//  ---------------------------------------
	//
	const dispatch = useDispatch();
	const [{ taxPayerFormData, taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);

	const uploadFormData = HandleFormData({ parentIndex: 3, myIndex: 0 });
	// For Choice Questions
	const [incomeTypes, setIncomeTypes] = useState(JSON.parse(JSON.stringify(modules)));
	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.BusinessIncomeForm) {
			if (Object.keys(taxPayerForm?.data?.BusinessIncomeForm).length === 0) return;

			const { incomeTypes } = taxPayerForm?.data?.BusinessIncomeForm;

			setIncomeTypes(incomeTypes);
		}
	}, [taxPayerForm?.data?.BusinessIncomeForm]);

	console.log("incomeTypes", incomeTypes);

	const handleAmountChange = (id, index) => {
		const myInputAmount = document.getElementById(id);
		let localDeduction = incomeTypes;
		localDeduction[index].amount = myInputAmount.value;
		setIncomeTypes(JSON.parse(JSON.stringify(localDeduction)));
	};

	// ---------------------------------------
	// Functions
	// ---------------------------------------

	const handleSubmit = (e) => {
		e.preventDefault();

		let FormObj = {
			incomeTypes,
		};

		let formData = taxPayerForm;
		taxPayerForm.data.BusinessIncomeForm = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);

	};

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 3;
		let myIndex = 0;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<MultiLineOneFieldInputs
				Options={incomeTypes}
				handleChange={handleAmountChange}
				placeholder={"Amount"}
			/>

			<OtherDataAdditionForm
				oldValues={incomeTypes}
				setState={setIncomeTypes}
				heading="Other Income Source"
				BtnText="Add Source"
				Datakeys={["question", "amount"]}
				DataLabels={["Type of income", "amount"]}
			/>
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={"w-25 custon-button  mt-4"}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={"w-25 custon-button  mt-4"}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default Income;
