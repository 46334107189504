import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="clearfix">
            <div className="container">
                <div className="clearfix text-center py-4">                    
                    <small className="text-dark text-opacity-75 mb-0">&copy; {new Date().getFullYear()} <Link to={'/'}>Uncle Kam</Link>. All Rights Reserved.</small>
                </div>
            </div>
        </div>
    );
}

export default Footer;