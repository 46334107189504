/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import DatePicker from "react-datepicker";
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';

const Covid19 = () => {
    const {covid19Options}= GLOBAL_VARIABLES

    const dispatch=useDispatch()
	const [{ taxPayerForm, taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 12 });

    const [errors, setErrors] = useState({}); // Track errors for form fields

    // for Current component States
    const [EIDLAmount, setEIDLAmount] = useState();
    const [sickEmployees, setSickEmployees] = useState();
    const [paidSickLeave, setPaidSickLeave] = useState();
    const [PPPLoanAmount, setPPPLoanAmount] = useState();
    const [totalWagesPaid, setTotalWagesPaid] = useState();
	const [howProvideReport, setHowProvideReport] = useState();
    const [employeesSickDays, setEmployeesSickDays] = useState();
    const [EIDLAdvanceAmount, setEIDLAdvanceAmount] = useState();
	const [specificQuestions, setSpecificQuestions] = useState([]);
    const [loanOriginationDate, setLoanOriginationDate] = useState(new Date());
    const [employeesMissedWork, setEmployeesMissedWork] = useState();
    const [missedWorkForSomeOne, setMissedWorkForSomeOne] = useState();
    const [remainingPPPLoanFunds, setRemainingPPPLoanFunds] = useState();
    const [PPPLoanOriginationDate, setPPPLoanOriginationDate] = useState(new Date());
    const [loanAmountSpentPayRoll, setLoanAmountSpentPayRoll] = useState();
    const [disasterRelatedExpenses, setDisasterRelatedExpenses] = useState();
    const [forgivenessAmountGranted, setForgivenessAmountGranted] = useState();
    const [loanAmountSpentNonPayRoll, setLoanAmountSpentNonPayRoll] = useState();

    console.log("LOD", loanOriginationDate)
    console.log("PLOD", PPPLoanOriginationDate)
    const multipleChoice = [] ;

    const handleQuestions = (index, bool) => {
        const localSpecificQuestions = specificQuestions.slice();
        localSpecificQuestions[index].bool = bool;
        setSpecificQuestions(localSpecificQuestions);
    };

    useEffect(() => {
		setSpecificQuestions(JSON.parse(JSON.stringify(covid19Options)));
	}, []);

    useEffect(() => {
    	if (taxPayerForm?.data?.Covid19) {
    		if (Object.keys(taxPayerForm?.data?.Covid19).length === 0) return;
			const {
                EIDLAmount,
                sickEmployees,
                paidSickLeave,
                PPPLoanAmount,
                totalWagesPaid,
                howProvideReport,
                employeesSickDays,
                EIDLAdvanceAmount,
                specificQuestions,
                loanOriginationDate,
                employeesMissedWork,
                missedWorkForSomeOne,
                remainingPPPLoanFunds,
                PPPLoanOriginationDate,
                loanAmountSpentPayRoll,
                disasterRelatedExpenses,
                forgivenessAmountGranted,
                loanAmountSpentNonPayRoll
			} = taxPayerForm?.data?.Covid19;
            setEIDLAmount(EIDLAmount)
            setSickEmployees(sickEmployees)
            setPaidSickLeave(paidSickLeave)
            setPPPLoanAmount(PPPLoanAmount)
            setTotalWagesPaid(totalWagesPaid)
            setHowProvideReport(howProvideReport)
            setEmployeesSickDays(employeesSickDays)
            setEIDLAdvanceAmount(EIDLAdvanceAmount)
            setSpecificQuestions(specificQuestions)
            setLoanOriginationDate(loanOriginationDate)
            setEmployeesMissedWork(employeesMissedWork)
            setMissedWorkForSomeOne(missedWorkForSomeOne)
            setRemainingPPPLoanFunds(remainingPPPLoanFunds)
            setPPPLoanOriginationDate(PPPLoanOriginationDate)
            setLoanAmountSpentPayRoll(loanAmountSpentPayRoll)
            setDisasterRelatedExpenses(disasterRelatedExpenses)
            setForgivenessAmountGranted(forgivenessAmountGranted)
            setLoanAmountSpentNonPayRoll(loanAmountSpentNonPayRoll)
    	}
    }, [taxPayerForm?.data?.Covid19]);

    const check_Validity = () => {
        setErrors({});
        const newErrors = {};
    
        // Add more validation rules as needed for other fields
        if (
            specificQuestions
                ?.flatMap((data) => {
                    return data?.bool;
                })
                .includes(null)
        ) {
            newErrors.AnswerOtherQuestions = 'Please Select Required Option';
        }
    
        setErrors(newErrors);
    
        let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
        // Check if there are any errors
        return ConditionCheck;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = check_Validity();
        if (errors) return;

        let FormObj = {
            EIDLAmount,
            sickEmployees,
            paidSickLeave,
            PPPLoanAmount,
            totalWagesPaid,
            howProvideReport,
            employeesSickDays,
            EIDLAdvanceAmount,
            specificQuestions,
            loanOriginationDate,
            employeesMissedWork,
            missedWorkForSomeOne,
            remainingPPPLoanFunds,
            PPPLoanOriginationDate,
            loanAmountSpentPayRoll,
            disasterRelatedExpenses,
            forgivenessAmountGranted,
            loanAmountSpentNonPayRoll
		};
        let formData = taxPayerForm;
        taxPayerForm.data.Covid19 = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));
        uploadFormData(formData);
    };

    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 12;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                <div className="col-md-6 mb-3 mt-2">
                    <small className="ml-4 fw-bold">Loan Origination Date</small>
                    <div className="form-floating">
                        <DatePicker
                            className="form-select"
                            wrapperClassName="d-block"
                            selected={new Date(loanOriginationDate)}
                            maxDate={new Date()}
                            onSelect={(date) => {
                                setLoanOriginationDate(date);
                            }}
                            dateFormat="MMMM d, yyyy"
                            placeholderText="Loan Origination Date"
                        />
                    </div>
                </div>
                <div className="col-md-6 mb-3 mt-2">
                    <small className="ml-4 fw-bold">PPP Loan origination date</small>
                    <div className="form-floating">
                        <DatePicker
                            className="form-select"
                            wrapperClassName="d-block"
                            selected={new Date(PPPLoanOriginationDate)}
                            maxDate={new Date()}
                            onSelect={(date) => {
                                setPPPLoanOriginationDate(date);
                            }}
                            dateFormat="MMMM d, yyyy"
                            placeholderText="PPP Loan origination date"
                        />
                    </div>
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="disasterRelatedExpenses"
                        value={disasterRelatedExpenses}
                        placeholder="What are the total disaster-related expenses incurred or plan to incur this year for affected employees?"
                        fn={setDisasterRelatedExpenses}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="employeesMissedWork"
                        value={employeesMissedWork}
                        placeholder="How many employees missed work due to being sick and/or under quarantine due to COVID-19?"
                        fn={setEmployeesMissedWork}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="sickEmployees"
                        value={sickEmployees}
                        placeholder="How many days total did these sick and quarantined employees miss (maximum 10 days per employee)?"
                        fn={setSickEmployees}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3 mt-3">
                    <Input
                        type="text"
                        name="missedWorkForSomeOne"
                        value={missedWorkForSomeOne}
                        placeholder="How many employees missed work due to caring for someone who was sick and under quarantine due to COVID-19?"
                        fn={setMissedWorkForSomeOne}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="employeesSickDays"
                        value={employeesSickDays}
                        placeholder="How many days total did these employees who were caring for someone sick due to COVID-19 miss (maximum 10 days per employee)?"
                        fn={setEmployeesSickDays}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="paidSickLeave"
                        value={paidSickLeave}
                        placeholder="What was the total employer portion of the health care premiums incurred while employees were covered under the Emergency Paid Sick Leave or Emergency Family & Medical Leave?"
                        fn={setPaidSickLeave}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="totalWagesPaid"
                        value={totalWagesPaid}
                        placeholder="What were the total wages paid out due to employees being on FMLA and Sick Leave?"
                        fn={setTotalWagesPaid}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="EIDLAdvanceAmount"
                        value={EIDLAdvanceAmount}
                        placeholder="Total EIDL Advance Amount"
                        fn={setEIDLAdvanceAmount}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="EIDLAmount"
                        value={EIDLAmount}
                        placeholder="Total EIDL Amount (Less Advance)"
                        fn={setEIDLAmount}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="PPPLoanAmount"
                        value={PPPLoanAmount}
                        placeholder="PPP loan amount"
                        fn={setPPPLoanAmount}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="loanAmountSpentPayRoll"
                        value={loanAmountSpentPayRoll}
                        placeholder="PPP Loan amount spent on payroll costs"
                        fn={setLoanAmountSpentPayRoll}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="txet"
                        name="loanAmountSpentNonPayRoll"
                        value={loanAmountSpentNonPayRoll}
                        placeholder="PPP Loan amount spent on non payroll eligible costs"
                        fn={setLoanAmountSpentNonPayRoll}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="remainingPPPLoanFunds"
                        value={remainingPPPLoanFunds}
                        placeholder="Remaining PPP loan funds not yet spent"
                        fn={setRemainingPPPLoanFunds}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="txet"
                        name="forgivenessAmountGranted"
                        value={forgivenessAmountGranted}
                        placeholder="Total forgiveness amount granted"
                        fn={setForgivenessAmountGranted}
                        mask={"999999999"}
                    />
                </div>
            </div>
            <div className=" mb-3 px-3">
					{specificQuestions?.map((data, i) => {
						return (
							<div key={i} className="w-100  align-items-center justify-content-between mt-2">
								<small className="col-md-12 form-check-label text-start" htmlFor={`checkbox-${i}`}>
									{data.question}
									{data.bool === null && (
										<span className="text-danger small-text mx-1">*Required</span>
									)}
								</small>
								<div className="col-md-12">
									<input
										onChange={() => handleQuestions(i, true)}
										checked={data?.bool === true}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 176}`}
										name={`checkbox-${i + 176}`}
									/>
									<label htmlFor={`checkbox-${i + 176}`} role="button" className="px-3 m-0 small">
										Yes
									</label>
									<input
										onChange={() => handleQuestions(i, false)}
										checked={data?.bool === false}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 999}`}
										name={`checkbox-${i + 999}`}
									/>
									<label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
										No
									</label>
								</div>
							</div>
						);
					})}
					{JSON.parse(JSON.stringify(multipleChoice))?.map((data, i) => {
						return (
							<div key={i} className="w-100 my-2">
								<small
									className="col-md-6 form-check-label text-start fw-bold mb-1"
									htmlFor={`howProvideReport-${i}`}
								>
									{data.question}
									{!howProvideReport && (
										<span className="text-danger small-text mx-1">*Required</span>
									)}
								</small>
								{data?.options?.map((option, index) => {
									return (
										<div>
											<input
												onChange={() => setHowProvideReport(option)}
												checked={howProvideReport === option}
												className="form-check-input"
												type="radio"
												id={`howProvideReport-${index}`}
												name={`howProvideReport-${index}`}
											/>
											<label className="px-3 small" htmlFor={`howProvideReport-${index}`}>
												{option}
											</label>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
                {Object.keys(errors)
    ?.map((key) => {
        return errors[key];
    })
    ?.map((data, index) => {
        return (
            <>
                <small className="text-primary" key={index}>
                    {data}*
                </small>
                <br />
            </>
        );
    })}
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button  mt-4'}
                        type="button"
                        callback={()=>goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default Covid19;