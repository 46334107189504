import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import allActions from '../../Store/action';
import Message from '../../components/forms/fields/Message';

const CalendlyVerification = () => {
	const dispatch = useDispatch();
	const [message] = useState({ show: false });
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [{ calendlyVerification }] = useSelector((state) => [state.dashboard]);

	useEffect(() => {
		if (calendlyVerification.status === 'idle') {
			const code = searchParams.get('code');
			dispatch(allActions.dashboard.calendlyVerification(code));
		}
		if (calendlyVerification.status === false) {
			navigate('/dashboard/settings', {
				state: {
					identity: 'calendlyVerification',
					calendlyVerificationStatus: false,
					calendlyVerificationError: calendlyVerification.message,
				},
			});
		}
		if (calendlyVerification.status === true) {
			navigate('/dashboard/settings', {
				state: {
					identity: 'calendlyVerification',
					calendlyVerificationStatus: true,
				},
			});
		}
	}, [dispatch, navigate, calendlyVerification, searchParams]);
	return (
		<div className='clearfix px-3'>{message.show ? <Message message={message} /> : <></>}</div>
	);
};

export default CalendlyVerification;
