import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../Store/action';

import UserAvatar from '../UserAvatar';

const UsersList = ({
	search,
	chatUsers,
	selectedReceiver,
	setOpenChat,
	loadChats,
	getMyStatus,
	showNotification = true,
}) => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	const dispatch = useDispatch();
	const [{ chatStatus, personalConnections }, { LoginData }] = useSelector((state) => [
		state.firebase,
		state.user,
	]);
	return (
		<>
			<div className="clearfix userlist">
				{personalConnections?.loaded === true &&
					chatStatus.loaded === true &&
					chatUsers &&
					chatUsers
						.sort((a, b) => b?.updatedAt - a?.updatedAt)
						.filter((data) => {
							let label = data?.firstname + ' ' + data?.lastname;
							return label?.toLowerCase()?.indexOf(search?.toLowerCase()) !== -1;
						})
						?.map((data, index) => {
							let active = selectedReceiver?.uuid === data?.uuid;
							return (
								<div
									role="button"
									key={index}
									className={`clearfix p-2 border border-light my-2 rounded ${
										active ? ' bg-dark bg-opacity-10' : ''
									}`}
									onClick={() => {
										if (loadChats) {
											setOpenChat(false);
											dispatch(allActions.firebase.resetReadPersonalChatsArray());
											loadChats(data, false);
										}
									}}
								>
									<div className="float-start">
										<div className="position-relative" style={{ width: 40, height: 40 }}>
											<UserAvatar
												avatar={data?.avatar}
												alt={`${data?.firstname} ${data?.lastname}`}
												className="rounded-circle w-100 h-100 object-fit-cover"
											/>
											<div className="float-start">
												{showNotification &&
												data?.[LoginData?.uuid] &&
												parseInt(data?.[LoginData?.uuid]) > 0 &&
												!active ? (
													<div style={{ position: 'relative' }}>
														<span className="badge badge-notification bg-secondary position-absolute text-dark">
															{data?.[LoginData?.uuid]}
														</span>
													</div>
												) : (
													''
												)}
											</div>
											<span
												className={`position-absolute rounded-circle border border-light bottom-0 end-0${
													getMyStatus(chatStatus?.data, data?.uuid, 'status') === 'online'
														? ' bg-success'
														: ' bg-white'
												}`}
												style={{
													width: 10,
													height: 10,
												}}
											></span>
										</div>
									</div>
									<div className="d-flex flex-row align-items-center justify-content-between px-2">
										<div className="col-md-12 d-flex flex-column align-items-start">
											<span className="elipsis mb-0">{data?.firstname + ' ' + data?.lastname}</span>
											<small className="text-muted">{data?.role}</small>
										</div>
									</div>
								</div>
							);
						})}
			</div>
		</>
	);
};

export default UsersList;
