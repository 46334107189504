import React, { useState, useRef } from 'react';
import axios from 'axios';
import ReactSelect from 'react-select';
import { BASEURL, getStatesList } from '../../utilites';
import Input from './fields/Input';
import Button from './fields/Button';
import Select from './fields/Select';
import TextArea from './fields/TextArea';
import Checkbox from './fields/Checkbox';

const UsaStatesList = getStatesList();

export default function EditUserBusinessInfoForm({
	userData,
	reloadUser,
	setAlert,
	accountManagers,
	services,
}) {
	const [businessName, setBusinessName] = useState(userData.userpro.businessName || '');
	const [phone, setPhone] = useState(userData.userpro.phone || '');
	const [street, setStreet] = useState(userData.userpro.street || '');
	const [street2, setStreet2] = useState(userData.userpro.street2 || '');
	const [city, setCity] = useState(userData.userpro.city || '');
	const [state, setState] = useState(
		userData.userpro.state ? UsaStatesList.find((s) => s.value === userData.userpro.state) : null
	);
	const [zipcode, setZipcode] = useState(userData.userpro.zipcode || '');
	const [percentage, setPercentage] = useState(userData.userpro.percentage || '0');
	const [experience, setExperience] = useState(userData.userpro.experience || '');
	const [roundRobin, setRoundRobin] = useState(userData.userpro.roundRobin || 0);
	const [status, setStatus] = useState(userData.userpro.status_id.toString() || '0');
	const [accountManager, setAccountManager] = useState(userData.userpro.manager_id || '');
	const [options, setOptions] = useState(
		[...userData?.userpro?.services?.map((service) => service.id.toString())] || []
	);

	const [errors, setErrors] = useState({
		businessName: false,
		phone: false,
		street: false,
		street2: false,
		city: false,
		state: false,
		zipcode: false,
		percentage: false,
		roundRobin: false,
		experience: false,
		options: false,
		general: '',
	});

	const button = useRef();

	// function to handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();
		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });
		axios
			.put(`${BASEURL}/api/professionals/update`, {
				id: userData.userpro.id,
				businessName,
				phone,
				street,
				street2,
				city,
				state: state?.value || '',
				zipcode,
				percentage,
				roundRobin,
				experience,
				status,
				accountManager,
				options,
			})
			.then(() => {
				button.current.disabled = false;
				reloadUser(userData.uuid);
				// Close Modal
				document.querySelector('.businessDetailModalClose').click();
				setAlert('Business information has been updated successfully!');
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({
						errors,
						general:
							error?.response?.data?.error ||
							error?.response?.data?.message ||
							error?.message ||
							'Internel Server Error!',
					});
			});
	};
	return (
		<form className="userform needs-validation" onSubmit={handleSubmit}>
			<div className="row g-2">
				<div className="col-md-7 mb-3">
					<Input
						type="text"
						value={businessName}
						name="businessName"
						placeholder="Business Name"
						showLabel={false}
						fn={setBusinessName}
						hasValidationError={errors.businessName}
					/>
				</div>
				<div className="col-md-5 mb-3">
					<Input
						type="text"
						value={phone}
						name="phone"
						placeholder="Phone Number"
						showLabel={false}
						fn={setPhone}
						onLeave={() => {
							if (!errors.phone && phone.length > 1 && phone.length < 14) {
								setErrors({ ...errors, phone: true });
							} else if (errors.phone) {
								setErrors({ ...errors, phone: false });
							}
						}}
						hasValidationError={errors.phone}
						mask="(999) 999-9999"
					/>
				</div>
			</div>
			<fieldset className="border-top mb-3 text-start mt-3">
				<legend className="w-auto float-none px-2 ms-3 fs-6">Business Address</legend>
			</fieldset>
			<div className="row">
				<div className="col-md-9 mb-3">
					<Input
						type="text"
						value={street}
						name="street"
						placeholder="Street"
						showLabel={false}
						fn={setStreet}
						hasValidationError={errors.street}
					/>
				</div>
				<div className="col-md-3 mb-3">
					<Input
						type="text"
						value={street2}
						name="street2"
						placeholder="Apt/Suite"
						showLabel={false}
						fn={setStreet2}
						hasValidationError={errors.street2}
						required={false}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						value={city}
						name="city"
						placeholder="City"
						showLabel={false}
						fn={setCity}
						hasValidationError={errors.city}
					/>
				</div>
				<div className="col-md-4 mb-3">
					<ReactSelect
						id="state"
						className="p-0 basic-single"
						placeholder="State"
						closeMenuOnSelect={true}
						options={UsaStatesList}
						onChange={setState}
						value={state}
					/>
				</div>
				<div className="col-md-2 mb-3">
					<Input
						type="text"
						value={zipcode}
						name="zipcode"
						placeholder="ie. 93882"
						showLabel={false}
						label="Zip Code"
						fn={setZipcode}
						onLeave={() => {
							if (
								!errors.zipcode &&
								zipcode.length > 1 &&
								(zipcode.length < 5 || zipcode.length > 9)
							) {
								setErrors({ ...errors, zipcode: true });
							} else if (errors.zipcode) {
								setErrors({ ...errors, zipcode: false });
							}
						}}
						hasValidationError={errors.zipcode}
						mask="99999999"
					/>
				</div>
			</div>
			<fieldset className="border-top mb-3 text-start mt-3">
				<legend className="w-auto float-none px-2 ms-3 fs-6">Business Status</legend>
			</fieldset>
			<div className="row">
				<div
					className={`${
						accountManagers && accountManagers.length > 0 ? 'col-md-3' : 'col-md-6'
					} mb-3`}
				>
					<Select
						value={status}
						name="status"
						placeholder="Business Status"
						showLabel={false}
						fn={setStatus}
					>
						<option value="0">Pending</option>
						<option value="1">Under Review</option>
						<option value="2">Activated</option>
						<option value="3">Paused</option>
						<option value="4">Suspended</option>
						<option value="5">Denied</option>
					</Select>
				</div>
				{accountManagers && accountManagers.length > 0 && (
					<div className="col-md-3 mb-3">
						<Select
							value={accountManager}
							name="account-manager"
							placeholder="Account Manager"
							fn={setAccountManager}
							showLabel={false}
							required={false}
						>
							<option value="">Select Account Manager</option>
							{accountManagers?.map((accountManager) => (
								<option key={accountManager.id} value={accountManager.id}>
									{`${accountManager.firstname} ${accountManager.lastname}`}
								</option>
							))}
						</Select>
					</div>
				)}
				<div className="col-md-3 mb-3">
					<Input
						type="text"
						value={percentage}
						name="percentage"
						placeholder="Percentage"
						fn={setPercentage}
						hasValidationError={errors.percentage}
						showLabel={false}
						max={100}
						min={0}
						mask={"999999999"}
					/>
				</div>
				<div className="col-md-3 mb-3">
					<Input
						type="text"
						value={roundRobin}
						name="roundRobin"
						placeholder="Round Robin"
						fn={setRoundRobin}
						hasValidationError={errors.roundRobin}
						showLabel={false}
						max={5}
						min={0}
						mask={"999999999"}
					/>
				</div>
			</div>
			<fieldset className="border-top mb-3 text-start mt-3">
				<legend className="w-auto float-none px-2 ms-3 fs-6">Business Details</legend>
			</fieldset>
			<div className="row">
				<div className="mb-3">
					<TextArea
						inputName="form-control"
						defaultValue={experience}
						placeholder="Experience"
						name="experience"
						fn={setExperience}
						showLabel={false}
						row="6"
						required={false}
					/>
				</div>
			</div>
			{services && (
				<div className="clearfix text-black">
					<div className="clearfix mt-3 mb-4">
						<h5 className="text-center">Provided services</h5>
					</div>
					<div className="row mt-3">
						{services?.map((service) => (
							<div key={service.id} className="col-md-4 mb-3">
								<Checkbox
									name="options"
									state={options}
									value={service.id}
									label={service.name}
									fn={setOptions}
									id={`option-${service.id}`}
									required={false}
								/>
							</div>
						))}
					</div>
				</div>
			)}
			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mb-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 ? ' disabled' : ''
				}`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-1"
				buttonText="Update Info"
				callback={() => {
					setErrors({ errors, general: '' });
				}}
			/>
		</form>
	);
}
