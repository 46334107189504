export const FIREBASE_ACTION_TYPE = {
	READ: 'read',
	admin: 'admin',
	WRITE: 'write',
	myUuid: 'myUuid',
	LoginDataUuid: 'LoginDataUuid',
	readIdle: 'readIdle',
	WriteIdle: 'WriteIdle',
	saveDocId: 'saveDocId',
	chatStatus: 'chatStatus',
	writeReply: 'writeReply',
	myConnection: 'myConnection',
	READPERSONAL: 'readPersonal',
	WRITEPERSONAL: 'writePersonal',
	topicUserList: 'topicUserList',
	readIdleArray: 'readIdleArray',
	writeReplyIdle: 'writeReplyIdle',
	myConnectionIdle: 'myConnectionIdle',
	readPersonalIdle: 'readPersonalIdle',
	topicUserListIdle: 'topicUserListIdle',
	WritePersonalIdle: 'WritePersonalIdle',
	writeReplyPersonal: 'writeReplyPersonal',
	personalConnections: 'personalConnections',
	personalGroupConnections: 'personalGroupConnections',

	firebaseNetworkError: 'Internet connection',
	writeReplyPersonalIdle: 'writeReplyPersonalIdle',
	personalConnectionsIdle: 'personalConnectionsIdle',
	// Notifications
	GET_NOTIFICATIONS_COUNT: 'GET_NOTIFICATIONS_COUNT',
	RECENT_NOTIFICATIONS_FETCHING_START: 'RECENT_NOTIFICATIONS_FETCHING_START',
	GET_RECENT_NOTIFICATIONS: 'GET_RECENT_NOTIFICATIONS',
	NOTIFICATIONS_FETCHING_START: 'NOTIFICATIONS_FETCHING_START',
	GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
	// Storage
	writeStorageSuccess: 'writeStorageSuccess',
	writeStorageFail: 'writeStorageFail',
	writeStorageIdle: 'writeStorageIdle',
	storageLinks: 'storageLinks',
	readStorage: 'readstorage',
	// Reset
	RESET: 'RESET',
};
