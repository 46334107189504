import React, { useState } from 'react';
import Header from '../components/Header';
import PaymentMembershipForm from '../../../components/forms/PaymentMembershipForm';
import SummaryCard from '../../../components/Dashboard/billing/summaryCard';
import Message from '../../../components/forms/fields/Message';
import { useLocation } from 'react-router-dom';
import Footer from '../components/Footer';


const AddPaymentMethods = () => {
	const [message, setMessage] = useState({ show: false });
    const {state} = useLocation();
    const [paymentMessage, setPaymentMessage] = useState({ show: false });

    return (
        <div>
            <Header name='Subscription' />
            {message.show ? <Message message={message} /> : <></>}
            {paymentMessage.show ? <Message message={paymentMessage} /> : <></>}
            <div className='w-100 d-flex flex-row flex-wrap justify-content-between'>
                <div className='col-lg-6 col-md-8 col-sm-12'>
                    <PaymentMembershipForm message={message} setMessage={setMessage} paymentMessage={paymentMessage} setPaymentMessage={setPaymentMessage} membership={true} selectedMembership={state}/>
                </div>
                <div className='col-lg-5 col-md-8 col-sm-12'>
                    <SummaryCard selectedMembership={state} setMessage={setMessage}/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default AddPaymentMethods;