import { useEffect, useState } from 'react';
import Agreement from '../../components/Dashboard/OnBoarding/Agreement';
import BusinessInfo from '../../components/Dashboard/OnBoarding/BusinessInfo';
import ContactInfo from '../../components/Dashboard/OnBoarding/ContactInfo';
import Progess from '../../components/Dashboard/OnBoarding/progressslider';
import DriverLicense from '../../components/Dashboard/OnBoarding/DriverLicense';
import PaymentInfo from '../../components/Dashboard/OnBoarding/PaymentInfo';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../Store/action';
import Message from '../../components/forms/fields/Message';
import SEO from '../../components/SEO';
// eslint-disable-next-line no-unused-vars
import { useNavigate } from 'react-router-dom';
import Availability from '../../components/Dashboard/OnBoarding/Availability';

const OnBoard = () => {
	const maxslides = 6;
	const dispatch = useDispatch();
	// const navigate = useNavigate();
	const [active, setActive] = useState(1);
	const [message, setMessage] = useState({ show: false });
	// eslint-disable-next-line no-unused-vars
	const [{ stripeAccountStatus, stripeAccountStatusMessage }, { LoginData }] = useSelector(
		(state) => [state.dashboard, state.user]
	);
	const statusCheckCondition =
		window.location.href.includes('#stripecheck') &&
		LoginData?.userpro?.agreement === 1 &&
		LoginData.document.length > 0;
		useEffect(() => {
		if (statusCheckCondition) {
			dispatch(allActions.dashboard.resetAccountStatus());
			dispatch(allActions.dashboard.getAccountStatus());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location.href, statusCheckCondition]);
	console.log('stripeAccountStatus', stripeAccountStatus);
	// useEffect(() => {
	//   if (!stripeAccountStatus) {
	//     setMessage({
	//       show: true,
	//       type: "Error",
	//       text: stripeAccountStatusMessage,
	//     });
	//     setActive(4);
	//     navigate("/dashboard/onboard");
	//   }
	//   if (stripeAccountStatus === true) {
	//     setMessage({
	//       show: true,
	//       type: "Success",
	//       text: "Account Verified Successfully",
	//     });
	//     navigate("/dashboard/onboard");
	//     setActive(6);
	//   }
	//   if (stripeAccountStatus === "idle") {
	//     resetMessage();
	//   }
	// }, [stripeAccountStatus, stripeAccountStatusMessage, navigate]);

	// eslint-disable-next-line no-unused-vars
	const resetMessage = () => {
		setMessage({ show: false });
	};

	if (statusCheckCondition) {
		return (
			<div className='w-50 page py-9 d-flex align-items-center justify-content-center responsive-form'>
				{message.show ? <Message message={message} /> : <h3>Loading.....</h3>}
			</div>
		);
	}

	return (
		<div className='page clearfix px-3 mt-4'>
			<SEO title='Onboarding' />
			<div className='container dashboard-container'>
				<div className='clearfix text-center mb-4'>
					<div className='row justify-content-center'>
						<div className='col-sm-10 col-lg-8 col-md-9'>
							<div className='center-div'>
								<div className='row align-items-center justify-content-center'>
									<h1>Onboarding</h1>
									<p className='text-muted'>Complete your onboarding process to get started.</p>
									<div className='col-lg-10 col-md-8 col-sm-6 col-10'>
										<Progess Progess={Math.round((active / maxslides) * 10)} />
									</div>
									<div className='col-sm-12 row align-items-center justify-content-center my-2'>
										<div className='row'>
											<p className='text-dark font-italic'>
												{active === 1
													? 'Agreement'
													: active === 2
													? 'Contact Info'
													: active === 3
													? 'Business Info'
													: active === 4
													? 'Payment Info Form'
													: active === 5
													? 'ID/Driver License'
													: active === 6
													? 'User Availability'
													: ''}
												<font className='text-secondary'> - </font>
												<font className='text-primary'>Step 0{active}</font>
											</p>
										</div>
										<div className='w-50'>
											{message.show ? <Message message={message} /> : <></>}
										</div>
										{active === 1 ? (
											<Agreement active={active} setActive={setActive} />
										) : active === 2 ? (
											<ContactInfo active={active} setActive={setActive} />
										) : active === 3 ? (
											<BusinessInfo active={active} setActive={setActive} />
										) : active === 4 ? (
											<PaymentInfo active={active} setActive={setActive} />
										) : active === 5 ? (
											<DriverLicense active={active} setActive={setActive} />
										) : active === 6 ? (
											<Availability active={active} setActive={setActive} />
										) : (
											<></>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OnBoard;
