import Header from '../dashboard/components/Header';
import SEO from '../../components/SEO';
import ReactPlayer from 'react-player';

const UnderReview = () => {
	return (
		<div className='clearfix px-3'>
			<SEO title='Under review' />
			<Header enableName={false} wrapperClass='p-3' />
			<div className='container dashboard-container page'>
				<div className='clearfix text-center mb-4'>
					<div className='row justify-content-center'>
						<div className='col-sm-10 col-lg-8 col-md-9'>
							<div className='center-div'>
								<div className='row align-items-center justify-content-center'>
									<h1>Under Review</h1>
									<p className='text-muted'>
										Your account has been completed and is under review.
										<br /> We will send you an email once the review process is completed.{' '}
									</p>
								</div>
								<div className="clearfix">
									<div className='player-wrapper'>
										<ReactPlayer
											controls={true}
											className='react-player'
											url={[{ src: 'https://unc-production.s3.amazonaws.com/videos/help/Onboarding.mp4', type: 'video/mp4'}]}
											width='100%'
											height='100%'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default UnderReview;
