import React from "react";
import { useSelector } from "react-redux";

const TextCard = ({ data, index }) => {
	const [{ LoginData }] = useSelector((state) => [state.user]);

	const sender = data?.senderId === LoginData?.uuid;
	if (document.getElementById(`mesasage-Input-area${index}`)) {
		document.getElementById(`mesasage-Input-area${index}`).innerHTML = data?.body;
	}
	if(typeof data?.body === 'string' ){
		return (
			data?.body?.replace(/\s/g, "") !== "" && (
				<font
					style={{ width: "fit-content" }}
					className={
						sender
							? "bg-dark text-white px-3 py-2 chat-item text-break fold-Text-small message-content"
							: "bg-primary bg-opacity-10 px-3 py-2 chat-item text-break fold-Text-small message-content"
					}
					id={`mesasage-Input-area${index}`}
				></font>
			)
		);
	}
};

export default TextCard;
