import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import allActions from '../../Store/action';
import { BASEURL, getJobStatus } from '../../utilites';
import Button from './fields/Button';
import Select from './fields/Select';

const jobStatuses = [0, 1, 2, 3, 4, 5, 6];

export default function JobStatusUpdateForm({
	uuid,
	currentStatus,
	currentProfessional,
	professionals,
	setMessage,
	reload,
}) {
	const [status, setStatus] = useState();
	const [pro, setPro] = useState();
	const [errors, setErrors] = useState({
		status: false,
		pro: false,
		general: '',
	});
	const button = useRef();
	const dispatch = useDispatch();

	useEffect(() => {
		setStatus(currentStatus);
	}, [currentStatus]);

	useEffect(() => {
		dispatch(allActions.dashboard.getUserRoles());
	}, [dispatch]);

	const handleSubmit = (e) => {
		e.preventDefault();

		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });
		axios
			.put(`${BASEURL}/api/jobs/update-status/${uuid}`, {
				status_id: status,
				pro_id: pro,
			})
			.then(() => {
				button.current.disabled = false;
				reload && reload();
				// Close Modal
				document.querySelector('.updateJobStatusModalClose').click();
				setMessage && setMessage('Job status has been updated successfully!');
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};

	return (
		<form className="userform needs-validation" onSubmit={handleSubmit}>
			{currentStatus === 4 ? (
				<div className="row">
					<div>
						<p>You can't update status of a job that is cancelled.</p>
					</div>
				</div>
			) : (
				<>
					<div className="row">
						<div className="mb-3">
							<Select value={status} name="JobStatus" placeholder="Job status" fn={setStatus}>
								{jobStatuses?.map((s) => (
									<option key={s} value={s}>
										{getJobStatus(s)}
									</option>
								))}
							</Select>
						</div>
					</div>
					{parseInt(status) !== currentStatus && parseInt(status) === 4 ? (
						<>
							<div className="row">
								<div>
									<p>Do you want to Re-assign this Lead to Another Tax Pro?</p>
								</div>
							</div>
							{professionals && (
								<div className="row">
									<div className="mb-3">
										<Select value={pro} name="taxpro" placeholder="Tax professional" fn={setPro}>
											<option>Select tax professional</option>
											{professionals?.map((r) => (
												<option
													key={r.userpro.id}
													value={r.userpro.id}
													disabled={r.userpro.id === currentProfessional ? true : false}
												>
													{`${r?.firstname} ${r?.lastname}`}
												</option>
											))}
										</Select>
									</div>
								</div>
							)}
						</>
					) : null}
					{errors.general && errors.general !== '' && (
						<div className="row">
							<div className="mb-3">
								<div className="alert alert-danger" role="alert">
									{errors.general}
								</div>
							</div>
						</div>
					)}
					<Button
						buttonRef={button}
						mainClass={`btn btn-primary btn-lg px-4${
							Object.values(errors).indexOf(true) > -1 || parseInt(status) === currentStatus
								? ' disabled'
								: ''
						}`}
						wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-3"
						buttonText="Update job status"
					/>
				</>
			)}
		</form>
	);
}
