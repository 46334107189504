import { BASEURL } from "../../utilites";
import axios from "axios";

//  Function To CREATE VIDEO ROOM
const sendMailReminder = (data) => {
	return async (dispatch) => {
		console.log("DATA>>>>>>>>::::::", data);
		await axios
			.post(`${BASEURL}/api/mail/send`, { data })
			.then((data) => {
				console.log("DATA>>>>>>", data);
			})
			.catch((error) => {
				console.log("ERROR????>>>>>", error);
			});
	};
};

export const sgMail = {
	sendMailReminder,
};
