import React, { useState } from "react";
import Stepper from "@keyvaluesystems/react-stepper";
import { IoMdCheckmark } from "react-icons/io";

const CompleteStepper = () => {
	const [currentStep, setCurrentStep] = useState(0);

	const handleStepClick = (step, index) => setCurrentStep(index);

	const styles = {
		LineSeparator: () => ({
			backgroundColor: "#4b57e7",
		}),
		ActiveNode: () => ({
			backgroundColor: "#4b57e7",
		}),
		CompletedNode: () => ({
			backgroundColor: "#4b57e7",
		}),
	};

	const renderHeading = () => {
		return (
				<p className="p-0 m-0">Project Start</p>
		);
	};

	const initialStepsArr = [
		{
			stepLabel:renderHeading(),
			stepDescription: "",
			completed: true,
            index:0
		},
		{
			stepLabel: "Payment details",
			stepDescription: "",
			completed: false,
            index:1
		},
		{
			stepLabel: "Review your order",
			stepDescription: "",
			completed: false,
            index:2
		},
		{
			stepLabel: "Review your order",
			stepDescription: "",
			completed: false,
            index:3
		}
	];
    const renderNodes=(data)=><div className={`w-100 h-100 rounded-circle d-flex align-items-center justify-content-center ${data.completed?'bg-primary':'bg-grey-200'}`}> 
    {data.completed && <IoMdCheckmark color="#fff" size={16}/>}
    </div>
	return (
		<Stepper
			styles={styles}
			steps={initialStepsArr}
			currentStepIndex={currentStep}
			showDescriptionsForAllSteps={false}
			orientation="vertical"
			labelPosition="right"
			onStepClick={handleStepClick}
			stepContent={(step) =><></>}
            renderNode={renderNodes}
		/>
	);
};

export default CompleteStepper;
