import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import Button from './fields/Button';
import { useDispatch } from 'react-redux';
import allActions from '../../Store/action';
import DropDown from './fields/DropDown';
import { useSelector } from 'react-redux';
import { getQueryParam } from '../../utilites';
import moment from 'moment';


const EditTaskForm = ({ task,setOuterMessage }) => {
	const [date, setDate] = useState(task?.trigger_at?.slice(0, 10))
	const [time,setTime] = useState(task?.trigger_at?.slice(10,19))
	const [ { editTask }] = useSelector((state) => [ state.tasks]);
	////
	const [keyword] = useState(getQueryParam('search') || '');
	const [createdDate] = useState(
		getQueryParam('created_after') ? new Date(getQueryParam('created_after')) : null
	);
	// Pagination
	const [perPage] = useState(getQueryParam('per_page') || 10);
	const [page] = useState(getQueryParam('page') || 1);
	const dispatch = useDispatch()

	useEffect(() => {
		setDate(task?.trigger_at?.slice(0, 10))
		setTime(task?.trigger_at?.slice(11,19))
	},[task])

	const handleDateChange = (date) => {
		if (date) {
			const formattedDate = date.toISOString().slice(0, 10);
			setDate(formattedDate);
		} else {
			setDate(null);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault()
		const editTaskObj = {
			id:task.id,
			trigger_at:`${date} ${time}`
		}
		dispatch(allActions.tasks.editTask(editTaskObj));
		
	}
	useEffect(() => {
		if(editTask?.status === true){
			setOuterMessage({
				show: true,
				type: 'Success',
				text: "Task Edited Successfully!",
			});
			dispatch(allActions.tasks.getTasks(keyword, createdDate, page, perPage));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[editTask.status])



	return (
		<form onSubmit={handleSubmit}>
			<div className='my-4'>

				<DropDown
					options={{label:'Appointments',value:'appointments'}}
					value={{label:'Appointments',value:'appointments'}}
					setValue={() => { }}
					label='Select Option'
					showLabel={false}
					isDisabled={true}
				/>
			</div>

			<div className='my-4'>
				<DropDown
					options={{}}
					value={null}
					setValue={{}}
					label='Select Appointment'
					showLabel={false}
					isDisabled={true}
				/>
			</div>
			<DatePicker
				className="form-control"
				wrapperClassName="d-block"
				selected={date ? new Date(date) : null}
				value={date}
				onChange={handleDateChange}
				dateFormat="MM/dd/yyyy"
				placeholderText="mm/dd/yyyy"
				minDate={moment().subtract(1, 'months')}
				required
			/>
			<input
				className="form-control mt-3"
				type="time"
				value={time}
				onKeyDown={(e) => e.preventDefault()}
				onChange={(e) => {
					const timeValue = e.target.value; 
					const formattedTime = `${timeValue}:00`;
					setTime(formattedTime)
				}
				}
                  />
					<Button
				mainClass={`btn btn-primary btn-lg px-4`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-3"
				buttonText="Edit Task"
			/>
		</form>
  )
}

export default EditTaskForm