import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../Store/action";
import Button from "./fields/Button";
import Message from "./fields/Message";
import { useNavigate } from "react-router-dom";

export default function DriverLicenseForm() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [{ LoginData }] = useSelector((state) => [state.user]);
	const documentPath = LoginData?.document;
	const [message, setMessage] = useState({ show: false });
	const fileInputRef1 = useRef(null);
	const fileInputRef2 = useRef(null);
	const [selectedImage1, setSelectedImage1] = useState(
		documentPath?.length > 0 ? documentPath?.filter((e) => e.name === "Front")?.[0]?.path : null
	);
	const [selectedImage2, setSelectedImage2] = useState(
		documentPath?.length > 0 ? documentPath?.filter((e) => e.name === "Back")?.[0]?.path : null
	);
	const [ImageData1, setImageData1] = useState(null);
	const [ImageData2, setImageData2] = useState(null);
	const [clear, setClear] = useState(false);
	const resetMessage = () => {
		setMessage({ show: false });
	};

	const handleCardClick = (ref) => {
		ref.current.click();
	};

	const handleImageInputChange = (e, index, clear) => {
		if (documentPath?.length > 0 && index === 1 && !clear) {
			setClear(true);
			setSelectedImage2(undefined);
		}
		if (documentPath?.length > 0 && index === 2 && !clear) {
			setClear(true);
			setSelectedImage1(undefined);
		}
		const file = e.target.files[0];
		if (file) {
			const imageUrl = URL.createObjectURL(file);
			if (index === 1) {
				setSelectedImage1(imageUrl);
				setImageData1(e.target.files[0]);
			} else if (index === 2) {
				setSelectedImage2(imageUrl);
				setImageData2(e.target.files[0]);
			}
		}
	};

	const handleSubmit = (e) => {
		resetMessage();
		e.preventDefault();
		if (
			selectedImage1 === documentPath?.filter((e) => e.name === "Front")?.[0]?.path ||
			selectedImage2 === documentPath?.filter((e) => e.name === "Back")?.[0]?.path
		) {
			moveToNext();
			return;
		}
		if (ImageData1 === null || ImageData2 === null) {
			setMessage({
				show: true,
				type: "Error",
				text: "Add Images Before Continue..",
			});
			return;
		}
		var formdata = new FormData();
		formdata.append("Front", ImageData1);
		formdata.append("Back", ImageData2);
		dispatch(allActions.global.Loader(true));
		dispatch(allActions.dashboard.AddDriverLicense(formdata, moveToNext, setMessage));
	};
	const moveToNext = () => {
		dispatch(
			allActions.user.ReGetUserData(() => {
				navigate("/dashboard");
				dispatch(allActions.dashboard.getAccountStatus());
			})
		);
	};

	return (
		<div>
			<form className="ContactInfoForm needs-validation" onSubmit={handleSubmit}>
				{message.show ? <Message message={message} /> : <></>}
				<p className="fw-bold text-center text-muted">
					Upload a copy of your Driver License or National ID below.
				</p>
				<div className="container-fluid content-row">
					<div className="row d-flex w-100 align-items-center justify-content-center">
						<div className="col-sm-12 col-lg-10">
							{selectedImage1 ? (
								<img
									src={selectedImage1}
									alt="SelectedImage1"
									className="IdContainer card col-sm-12 col-lg-12"
									onClick={() => handleCardClick(fileInputRef1)}
								/>
							) : (
								<div
									className="border rounded IdContainer d-flex align-items-center justify-content-center cursor-pointer"
									onClick={() => handleCardClick(fileInputRef1)}
								>
									<h5 className="text-primary fw-400">Front View</h5>
								</div>
							)}
						</div>
						<input
							type="file"
							accept="image/*"
							onChange={(e) => handleImageInputChange(e, 1, clear)}
							className="d-none"
							ref={fileInputRef1}
						/>
						<div className="col-sm-12 col-lg-10 my-3">
							{selectedImage2 ? (
								<img
									src={selectedImage2}
									alt="SelectedImage2"
									className="IdContainer card col-sm-12 col-lg-12"
									onClick={() => handleCardClick(fileInputRef2)}
								/>
							) : (
								<div
									className=" border rounded imageBox-container IdContainer d-flex align-items-center justify-content-center cursor-pointer"
									onClick={() => handleCardClick(fileInputRef2)}
								>
									<h5 className="text-primary fw-400">Back View</h5>
								</div>
							)}
						</div>
						<input
							type="file"
							accept="image/*"
							onChange={(e) => handleImageInputChange(e, 2, clear)}
							className="d-none"
							ref={fileInputRef2}
						/>
					</div>
				</div>
				<h6 className="text-dark text-center text-muted mt-1 small">
					Click on each image above to replace
				</h6>
				<div className="container-fluid content-row ">
					<div className="d-flex align-items-center justify-content-center row">
						<Button
							mainClass="btn btn-primary custon-button-text btn-md  w-100"
							buttonText="Upload Id"
							wrapperClass={"w-50 custon-button  mt-2"}
							type="submit"
							disabled={
								selectedImage1 === documentPath?.filter((e) => e.name === "Front")?.[0]?.path ||
								selectedImage2 === documentPath?.filter((e) => e.name === "Back")?.[0]?.path
							}
						/>
					</div>
				</div>
			</form>
		</div>
	);
}
