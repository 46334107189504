import React, { useEffect, useState } from "react";
import Button from "../../fields/Button";
import { GLOBAL_VARIABLES } from "../../../../utilites/variables";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../Store/action";
import MultiLineOneFieldInputs from "../../fields/MultiLineOneFieldInput";
import OtherDataAdditionForm from "./OtherDataAdditionForm";
import { HandleFormData } from "../../../../utilites";

const Expenses = () => {
	//
	//  ---------------------------------------
	//  Variables , States & Store States
	//  ---------------------------------------
	//
	const dispatch = useDispatch();

	const { expensesOptions } = GLOBAL_VARIABLES;

	const uploadFormData = HandleFormData({ parentIndex: 3, myIndex: 2 });

	const [{ taxPayerFormData, taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);

	const [expensesTypes, setExpensesTypes] = useState(JSON.parse(JSON.stringify(expensesOptions)));
	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.ExpensesForm) {
			if (Object.keys(taxPayerForm?.data?.ExpensesForm).length === 0) return;
			const { expensesTypes } = taxPayerForm?.data?.ExpensesForm;
			setExpensesTypes(expensesTypes);
		}
	}, [taxPayerForm?.data?.ExpensesForm]);

	const handleAmountChange = (id, index) => {
		const myInputAmount = document.getElementById(id);
		let localDeduction = expensesTypes;
		localDeduction[index].amount = myInputAmount.value;
		setExpensesTypes(JSON.parse(JSON.stringify(localDeduction)));
	};
	// ---------------------------------------
	// Functions
	// ---------------------------------------

	console.log("expensesTypes", expensesTypes);
	const handleSubmit = (e) => {
		e.preventDefault();
		let FormObj = {
			expensesTypes,
		};
		let formData = taxPayerForm;
		taxPayerForm.data.ExpensesForm = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));
		uploadFormData(formData);
	};

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 3;
		let myIndex = 2;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<MultiLineOneFieldInputs
				Options={expensesTypes}
				handleChange={handleAmountChange}
				placeholder={"Amount"}
			/>
			<OtherDataAdditionForm
				oldValues={expensesTypes}
				setState={setExpensesTypes}
				heading="Other Expenses"
				BtnText="Add Expenses"
				Datakeys={["question", "amount"]}
				DataLabels={["Type of expenses", "amount"]}
			/>
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={"w-25 custon-button  mt-4"}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={"w-25 custon-button  mt-4"}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default Expenses;
