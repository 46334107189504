/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from 'react-router-dom';

import { AiOutlineSend } from 'react-icons/ai';
import { FiUploadCloud } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { IoSync } from 'react-icons/io5';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { Editor } from 'react-draft-wysiwyg';
import { IoIosLock } from "react-icons/io";


const SubmitArea = ({
	selectedReceiver,
	myIds,
	setIsTyping,
	imagesArray,
	imageLinks,
	handleRemoveImage,
	fileUpload,
	editorState,
	handleKeyPress,
	onEditorStateChange,
	handleSubmit,
	chatDisable,
	editMode,
	docId,
	setEditId,
	setEditText,
	handleEdit
}) => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------

	const [{LoginData},{ personalConnections }] = useSelector((state) => [state.user,state.firebase]);

	return (
		<div className={`clearfix p-2 ${chatDisable && 'disabled'} editor`}>
		{LoginData?.userpro?.status_id === 6 && <>
			<div className='editor-overlay'>
			</div>
			<div className='editor-danger-text gap-2'>
			<IoIosLock style={{fontSize:'20px',color:'#a8a8a8'}} /> <p className='text-danger fw-bold'>Chat is currently disabled. Please contact support to reactivate your account</p>
			</div></>}
			<div className="isTypingArea">
				{personalConnections?.data
					?.find((data) => data?.ids?.includes(myIds[1]) && data?.ids?.includes(myIds[0]))
					?.isTyping?.includes(selectedReceiver?.uuid) ? (
					<small className="isType-text">
						{selectedReceiver?.firstname + ' ' + selectedReceiver?.lastname} is Typing....
					</small>
				) : (
					''
				)}
			</div>
			<form>
				<div className="chat-input border border-light d-flex align-items-center justify-content-center flex-column">
					<div className="input-group" ref={setIsTyping}>
						<Editor
							keyBindingFn={(event) => handleKeyPress(event)}
							editorState={editorState}
							handleReturn={(event) =>
								event.key === 'Enter' && event.shiftKey && handleKeyPress(event)
							}
							handlePastedText={() => false}
							onEditorStateChange={onEditorStateChange}
							wrapperClassName="demo-wrapper w-100"
							editorClassName="demo-editor px-2 text-break"
							placeholder="Start typing here..."
							toolbar={{
								options: ['inline', 'list', 'link'],
								inline: {
									options: ['bold', 'italic'],
								},
								link: {
									showOpenOptionOnHover: false,
									defaultTargetOption: '_blank',
								},
							}}
						/>
					</div>
					<div className="w-100 d-flex flex-row align-items-center justify-content-start  flex-wrap py-2 px-3">
						{imageLinks?.map((image, index) => (
							<div key={index} className="image-container">
								<div className="delete-icon animation-div">
									<Link onClick={() => handleRemoveImage(image)}>
										<IoIosCloseCircleOutline size={23} />
									</Link>
								</div>
								<img
									src={image.downloadURL}
									alt={`Unique ${index}`}
									className="rounded-2 upload-image shadow-sm bg-red rounded"
								/>
							</div>
						))}
						{imagesArray?.map((image, index) => {
							let loading = imageLinks.find((e) => e.path === image.path);
							return (
								<div key={index} className="image-container">
									{loading === undefined && (
										<>
											<div className="circle-animation d-flex align-items-center justify-content-center">
												<IoSync size={12} />
											</div>
											<img
												src={URL.createObjectURL(image)}
												alt={`Unique ${index}`}
												className="rounded-2 upload-image shadow-sm bg-red rounded"
											/>
										</>
									)}
								</div>
							);
						})}
					</div>
					<div className="w-100 d-flex align-items-center justify-content-between py-1 px-2">
						<FiUploadCloud
							size={20}
							onClick={() => {
								if (document.getElementById('image-picker')) {
									document.getElementById('image-picker').click();
								}
							}}
							className="mx-1"
							role="button"
						/>
						{!editMode ? <Link
							className="btn btn-sm btn-outline-primary send-button"
							onClick={() => handleSubmit({ type: 'simple' })}
						>
							<AiOutlineSend />
						</Link> : <div className='d-flex gap-2'>
						<Link
							className="btn btn-sm btn-outline-danger "
							onClick={() => {
								setEditText('')
								setEditId(null)
							}}
						>
							cancel
						</Link><Link
							className="btn btn-sm btn-outline-primary "
							onClick={() => handleEdit(docId)}
						>
							save
						</Link>
						</div> }
					</div>
				</div>
			</form>
			<input
				type="file"
				accept="image/*"
				className="d-none"
				id="image-picker"
				onChange={(e) => {
					console.log(e.target.files[0]);
					fileUpload(e.target.files[0], 'add');
				}}
				onClick={(e) => (e.target.value = '')}
			/>
		</div>
	);
};

export default SubmitArea;
