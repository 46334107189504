import React from "react";
import { useSelector } from "react-redux";

const SideMenuButton = ({ selectedReceiver, getMyStatus }) => {
	const [{ chatStatus }] = useSelector((state) => [state.firebase]);

	const CloseModalOverlay = () => {
		try {
			// Remove the modal backdrop element
			var backdrop = document.querySelector(".offcanvas-backdrop");
			if (backdrop) {
				backdrop.parentNode.removeChild(backdrop);
			}
		} catch (error) {}
	};
	return (
		<>
			{selectedReceiver && (
				<button
					className="btn btn-primary chat-list-button align-items-center justify-content-start flex-row"
					type="button"
					data-bs-toggle="offcanvas"
					data-bs-target="#messageMobileView"
					aria-controls="messageMobileView"
					onClick={()=>CloseModalOverlay()}
				>
					{selectedReceiver?.firstname + " " + selectedReceiver?.lastname} -{" "}
					{getMyStatus(chatStatus?.data, selectedReceiver?.uuid, "status")}
					<img
						src={require("../../assets/icons/Double Right.png")}
						alt="chevron-double-right"
						className="position-absolute chevron-double"
						width={30}
					/>
				</button>
			)}
		</>
	);
};

export default SideMenuButton;
