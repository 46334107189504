import MainOption from '../../Organizar/StepForm/MainOption';
import SubOption from '../../Organizar/StepForm/SubOption';
import Header from '../../Organizar/StepForm/Header';
import Footer from '../../Organizar/StepForm/Footer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../Store/action';

const StepForm = ({ Options }) => {
	const dispatch = useDispatch();
	const [{ taxPayerFormData }] = useSelector((state) => [state.taxForms]);
	return (
		<div className="w-100">
			<Header />
			<hr />

			{Options?.map((data, ind) => {
				return (
					<>
						<MainOption
							onClick={() => {
								dispatch(
									allActions.taxForms.openThisSideBar({ parentIndex: ind, taxPayerFormData })
								);
							}}
							key={ind}
							heading={data?.name}
							check={data?.completed}
							filled={data?.open}
						/>
						{data?.open &&
							data?.SubOption?.map((data, index) => {
								return (
									<SubOption
										onClick={() => {
											dispatch(allActions.dashboard.resetTaxPreparation());
											dispatch(
												allActions.taxForms.openThisForm({
													parentIndex: ind,
													myIndex: index,
													taxPayerFormData,
												})
											);
										}}
										key={index}
										heading={data?.name}
										check={data?.completed}
										filled={data?.open}
									/>
								);
							})}
					</>
				);
			})}
			<hr />
			<Footer />
		</div>
	);
};

export default StepForm;
