import { useEffect } from 'react';
import SEO from '../components/SEO';
import CookiesUsage from '../assets/img/cookies-agreement.png';

export default function UserAgreement() {

	useEffect(() => {
		// Scroll to top
		window?.scrollTo(0, 0);
	}, []);

	return (
		<div className='clearfix'>
			<SEO title='User agreement' />
			<div className='page px-3'>
				<div className='container'>
					<h1
						className='text-center text-decoration-underline fw-bold w-50 mx-auto mb-0'
						style={{ fontSize: 30 }}
					>
						TAX REDUCTION COMPANY, INC. DBA UNCLE KAM TERMS OF USE AGREEMENT FOR PURCHASE AND USE OF
						PRODUCTS AND SERVICES
					</h1>
					<h2 className='text-center mb-4' style={{ fontSize: 30 }}>
						(the “Agreement”)
					</h2>
					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>
						Acceptance of terms and conditions by using this site
					</h3>
					<p>
						By using this site (“Site”) or by clicking <strong>“I agree”</strong> to this Agreement,
						you, the user and /or customer (hereinafter referred to as{' '}
						<strong>“User” or “You”</strong>) signify your agreement to these terms and conditions.
					</p>
					<p>
						If you do not agree to this Agreement please do not use this Site or purchase any
						Products, do not click <strong>“I agree.”</strong> Please check this Agreement
						periodically for changes as the owner of this Site, TAX REDUCTION COMPANY, INC. dba
						UNCLE KAM, a California corporation (hereinafter the “Company”) reserves the right to
						revise this Agreement. In the event of a change to this Agreement, your continued use of
						this Site following the posting of any changes constitutes acceptance of such changes.
						The Company reserves the right to terminate a User’s use of this Site at any time
						without notice and may do so for any breach of this Agreement.
					</p>

					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>
						You must be over 18 or older to agree to this agreement and use this site
					</h3>
					<p>
						This Agreement must be completed, understood and agreed to by a person over 18. If a
						parent or guardian wishes to permit a person under 18 to access this Site, he or she
						should email the Company with his or her explicit permission and acceptance of full
						legal responsibility. If you are not yet 18 or are accessing this Site from any country
						where this material is prohibited, please exit now as you do not have proper
						authorization.
					</p>
					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>License to use this site</h3>
					<p>
						Upon your agreement, Company hereby grants you a non-exclusive, non-transferable limited
						license to use this Site in strict accordance with the terms and conditions in this
						Agreement. You agree not to make any false or fraudulent statements as you use this
						Site. You acknowledge and agree that all content and services available on this Site are
						property of the Company and are protected by copyrights, trademarks, service marks,
						patents, trade secrets, and other proprietary rights and laws, in the U.S. and
						internationally. All rights not expressly granted herein are fully reserved by the
						Company, its advertisers and licensors. You agree to pay for any and all purchases and
						services using your name and credit card through this Site, not to challenge any such
						charges and to pay for all collections and/or attorney’s fees resulting from any non-
						payment.
					</p>
					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>License restrictions</h3>
					<h4 className='text-start fw-bold text-uppercase fs-6 mb-3'>Use</h4>
					<p>
						Except as may be explicitly permitted, you agree not to save, download, cut and paste,
						sell, license, rent, lease, modify, distribute, copy, reproduce, transmit, publicly
						display, publicly perform, publish, adapt, edit, or create derivative works from
						materials from this Site. Systematic retrieval of data or other content from this Site
						to create or compile, directly or indirectly, a collection, database or directory
						without written permission from the Company is prohibited. In addition, use of the
						content or materials for any purpose not expressly permitted in this Agreement is
						prohibited.
					</p>
					<h4 className='text-start fw-bold text-uppercase fs-6 mb-3'>Security</h4>
					<p>
						You agree that if you are issued a Username and Password by the Company, you shall use
						your best efforts to prevent access to this Site through your Username and Password by
						anyone other than yourself, including but not limited to, keeping such information
						strict confidential, notifying the Company immediately if you discover loss or access to
						such information by an unauthorized party and by using a secure Username and Password
						not easily guessed by a third party.
					</p>
					<p>
						You agree that you shall not try to reverse assemble, reverse compile, decompile,
						disassemble, translate or otherwise alter any executable code, contents or materials on
						or received via this Site. You understand that such actions are likely to subject you to
						serious civil and criminal legal penalties and that the Company shall pursue such
						penalties to the full extent of the law to protect its rights and the rights of its
						other licensors.
					</p>
					<h4 className='text-start fw-bold text-uppercase fs-6 mb-3'>Export</h4>
					<p>
						You agree that you shall comply with all applicable export and import control laws and
						regulations in your use of this Site, or materials or services received through this
						Site, and, in particular, you shall not export or re-export anything on or received
						through this Site in violation of local or foreign export laws and/or without all
						required U.S. and foreign government licenses.
					</p>
					<h4 className='text-start fw-bold text-uppercase fs-6 mb-3'>Government use</h4>
					<p>
						If you are a branch or agency of the U.S. Government, the following provision applies.
						This Site, code, contents, services and accompanying documentation are comprised of
						“commercial computer software” and “commercial computer software documentation” as such
						terms are used in 48 C.F.R. 12.212 (SEPT 1995) and are provided to the Government (i)
						for acquisitions by or on behalf of civilian agencies, consistent with the policy set
						forth in 48 C.F.R. 12.212; or (ii) for acquisitions by or on behalf of units of the
						Department of Defense, consistent with the policies set forth in 48 C.F.R. 227.7202-1
						(JUN 1995) and 227.7202-3 (JUN 1995). Unpublished rights reserved under the copyright
						laws of the United States.
					</p>
					<h4 className='text-start fw-bold text-uppercase fs-6 mb-3'>Errors and corrections</h4>
					<p>
						While the Company makes reasonable efforts to include accurate and current information
						on the Company’s Site, the Company does not warrant or represent that the Site will be
						error-free. Data entry errors or other technical problems may sometimes result in
						inaccurate information being shown. The Company reserves the right to correct any
						inaccuracies or typographical errors on the Company’s Site, including pricing and
						availability of products and services, and shall have no liability for such errors. The
						Company may also make improvements and/or changes to the Site’s features, functionality,
						or content at any time. If you see any information or description you believe to be
						incorrect, please contact us and we’ll verify it for you.
					</p>
					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>Links to other websites</h3>
					<p>
						The Company’s Site contains links to other websites for your information and
						convenience, or to provide additional shopping for various other goods and services
						through our Merchant and Services Partners. These third-party websites are responsible
						for, and undertake to maintain, their own Site terms of use. The Company recommends that
						you carefully review the terms of use of each site you choose to access from the
						Company’s Site.
					</p>
					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>
						User’s license granted to site
					</h3>
					<p>
						Except with regards to personal information, all information which you post on this Site
						or communicate to the Company through this Site (collectively “Submissions”) shall
						forever be the property of the Company. The Company shall not treat any submission as
						confidential and shall not incur any liability as a result of any similarities that may
						appear in future Company services or products. Without copy, the Company shall have
						exclusive ownership of all present and future existing rights to any Submission of every
						kind and nature everywhere. You acknowledge that you are fully responsible for the
						message, including its legality, reliability, appropriateness, originality and
						copyright. You hereby represent and warrant that your Submission does not infringe the
						rights of any third party.
					</p>
					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>User conduct</h3>
					<p>
						By using features of this Site that allow you to post or otherwise transmit information
						to or through this Site, or which may be seen by other users, you agree that you shall
						not upload, post, or otherwise distribute or facilitate distribution of any content –
						including text, communications, video, software, images, sounds, data, or other
						information – that:
					</p>
					<p>
						A. is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive,
						fraudulent, invasive of another’s privacy, tortious, obscene, sexually explicit or
						graphic, or otherwise in violation of this Site’s rules or policies;
					</p>
					<p>
						B. infringes any patent, trademark, service mark, trade secret, copyright, moral right,
						right of publicity, privacy or other proprietary right of any party;
					</p>
					<p>
						C. constitutes unauthorized or unsolicited advertising, junk or bulk email (also known
						as “spamming”), chain letters, any other form of unauthorized solicitation, or any form
						of lottery or gambling;
					</p>
					<p>
						D. contains software viruses or any other computer code, files, or programs that are
						designed or intended to disrupt, damage, or limit the functioning of any software,
						hardware, or telecommunications equipment or to damage or obtain unauthorized access to
						any data or other information of any third party; or
					</p>
					<p>
						E. impersonates any person or entity, including any employee, agent or representative of
						this Site, its licensors or advertisers.
					</p>
					<p>
						You also agree that you shall not harvest or collect information about the users of this
						Site or use such information for the purpose of transmitting or facilitating
						transmission of unsolicited bulk electronic email or communications for any other
						commercial purpose of your own or a third party.
					</p>
					<p>
						You further agree that you shall not solicit or collect information, or attempt to
						induce any physical contact with, anyone 18 years old or younger without appropriate
						parental consent.
					</p>
					<p>
						This Site generally does not pre-screen, monitor, or edit the content posted by users of
						this Site. However, this Site and its agents have the right, at their sole discretion,
						to remove any content that, in this Site’s sole judgment, does not comply with the Site
						Submission Rules or is otherwise harmful, objectionable, or inaccurate. This Site is not
						liable for any failure, delay, damages or results, in removing such content.
					</p>
					<p>
						You agree that your use of this Site may be suspended or terminated immediately upon
						receipt of any notice which alleges that you have used this Site in violation of these
						Rules and/or for any purpose that violates any local, state, federal or law of other
						nations, including but not limited to the posting of information that may violate third
						party rights, that may defame a third party, that may be obscene or pornographic, that
						may harass or assault other, that may violate hacking or other criminal regulations,
						etc. of its agents, officers, directors, contractors or employees. In such event, you
						agree that the owner of this Site may disclose your identity and contact information, if
						requested by a government or law enforcement body or as a result of a subpoena or other
						legal action, and the owner of this Site shall not be liable for damages or result of a
						subpoena or other legal action, and the owner of this Site shall not be liable for
						damages or results thereof, and you agree not to bring any action or claim against the
						owner of this Site for such disclosure.
					</p>
					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>
						Intellectual property rights
					</h3>
					<h4 className='text-start fw-bold text-capitalize fs-6 mb-3'>1. Copyright</h4>
					<p>
						The Site design, text, content, selection and arrangement of elements, organization,
						graphics, compilation, magnetic translation, digital conversion, and other matters
						related to the Site are protected under applicable copyright laws, ALL RIGHTS RESERVED.
						The posting of any such elements on the Site does not constitute a waiver of any right
						in such elements. You do not acquire ownership rights to any such elements viewed
						through the Site. Except as otherwise provided herein, none of these elements may be
						used, copied, reproduced, downloaded, posted, displayed, transmitted, mechanical,
						photocopying, recording, or otherwise, without Company’s prior written permission.
					</p>
					<h4 className='text-start fw-bold text-capitalize fs-6 mb-3'>2. Trademark</h4>
					<p>
						<strong>TAX REDUCTION COMPANY, INC. DBA UNCLE KAM</strong>, its name, logo, and all
						product names, company names, and other logos, unless otherwise noted, are trademarks
						and/or trade dress of <strong>TAX REDUCTION COMPANY, INC</strong> and its fictitious
						business name, <strong>UNCLE KAM.</strong> The use or misuse of any Marks or any other
						materials contained on the Site, without the prior written permission of their owner, is
						expressly prohibited.
					</p>
					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>Third party sites</h3>
					<p>
						You may be transferred to online merchants or other third party sites through links or
						frames from this Site. You are cautioned to read their Terms and Conditions and/or
						Privacy Policies before using such sites. These sites may contain information or
						material that is illegal, unreasonable or that some people may find inappropriate or
						offensive. These other sites are not under the control of the Company and are not
						monitored or reviewed by the Company. The inclusion of such a link or frame does not
						imply endorsement of this Site by the Company, its advertisers or licensors, or any
						association with its operators and is provided solely for your convenience. You agree
						that the Company and its licensors have no liability whatsoever from such third party
						sites and your usage of them.
					</p>
					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>
						Disclaimer of warranties – site and products or services purchased or used
					</h3>
					<p>
						The Company, its advertisers and licensors make no representation or warranties about
						this Site, the suitability of the information contained on or received through use of
						this Site, or any service or products received through this Site. All information and
						use of this Site are provided “as is” without warranty of any kind. The Company,
						advertisers and/or its licensors hereby disclaim all warranties without regards to this
						Site, the information contained or received through use of this Site, and any services
						or products received through this Site, including all express, statutory, and implied
						warranties of merchantability, fitness for a particular purpose, title and
						non-infringement. The Company, advertisers and/or its licensors do not warrant that the
						contents or any information received through this Site are accurate, reliable or
						correct; that this Site will be available at any particular time or location; that any
						defects or errors will be corrected; or that the contents of any information received
						through this Site is free of viruses or other harmful components. Your use of this Site
						is solely at your risk. User agrees that User has relied on no warranties,
						representations or statements other than in this Agreement. Because some jurisdictions
						do not permit the exclusion of certain warranties, these exclusions may not apply to you
						but shall apply to the maximum extent permitted by law of the applicable jurisdiction.
					</p>
					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>
						Limitation of liability – use of site
					</h3>
					<p>
						Under no circumstances shall the Company, advertisers and/or its licensors be liable for
						any direct, indirect, punitive, incidental, special, or consequential damages that
						result from the use or inability to use, this Site. This limitation applies whether the
						alleged liability is based on contract, tort, negligence, strict liability, or any other
						basis, even if the Company, advertisers and/or its licensors have been advised of the
						possibility of such damage. Because some jurisdictions do not allow the exclusion or
						limitation of incidental or consequential damages, the Company, advertisers and/or its
						respective licensors’ liability in such jurisdictions shall be limited to the maximum
						extent permitted by law of the relevant jurisdiction.
					</p>
					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>
						Indemnification – use of site
					</h3>
					<p>
						You agree to defend, indemnify, and hold harmless the Company, its advertisers,
						licensors, subsidiaries and other affiliated companies, and their employees,
						contractors, officers, agents and directors from all liabilities, claims, and expenses,
						including attorney’s fees, that arise from your use of this Site, or any services,
						information or products from this Site, or any violation of this Agreement. The Company
						reserves the right, at its own expense, to assume the exclusive defense and control of
						any matter otherwise subject to indemnification by you, in which event you shall
						cooperate with the Company in asserting any available defenses.
					</p>
					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>
						Company’s legal compliance – use of site
					</h3>
					<p>
						Company may suspend or terminate this Agreement or User’s use immediately upon receipt
						of any notice which alleges that User has used this Site for any purpose that violates
						any local, state, federal or law of other nations, including but not limited to the
						posting of information that may violate third party rights, that may defame a third
						party, that may be obscene or pornographic, that may harass or assault other, that may
						violate hacking or other criminal regulations of its agent, officers, directors,
						contractors or employees. In such event, Company may disclose the User’s identity and a
						subpoena or other legal action, and Company shall not be liable for damages or results
						thereof and User agrees not to bring any action or claim against Company for such
						disclosure.
					</p>
					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>
						Privacy rights and compliance
					</h3>
					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>
						GDPR and California Privacy Act compliance
					</h3>
					<p>
						At present, the Company does not collect, store or use your “Personal Data.” “Personal
						Data” includes, “any information relating to an individual, whether it relates to his or
						her private, professional or public life. It can be anything from a name, a home
						address, a photo, an email address, bank details, posts on social networking websites,
						medical information, or a computer’s IP address.” More specifically, it includes:
					</p>
					<ul className='m-0 mb-3'>
						<li>
							Identification information: name, telephone, physical and email address and government
							ID numbers
						</li>
						<li>Website data: location, IP address, cookie histories and RFID tags</li>
						<li>Health, mental and genetic data</li>
						<li>Biometric data</li>
						<li>Racial, cultural or ethnic data</li>
						<li>Political opinions</li>
						<li>Sexual orientation</li>
						<li>Tagged photos</li>
					</ul>
					<p>
						At present, the Company does not sell or transfer your “Personal Data” to any third
						party.
					</p>
					<p>
						The Company will not collect, store or use your “Personal Data” or sell or transfer your
						“Personal Data” without your written consent. Should Company wish to do so, Company will
						first obtain your “freely given, specific, informed and unambiguous” consent.
					</p>
					<p>
						Company will first audit all of its current data, anonymize potentially personal
						identifying information (PII) of all of its users, such as an IP address, and then
						obtain explicit consent of any and all users before moving forward with loading the
						Google Analytics script for any user. Pop-ups or widgets will then offer first-time
						visitors – as well as returning visitors – the opportunity to opt in/out as to whether
						they agree to allow Company to use, sell or transfer any such information. The opt
						in/out opportunity will look something like the following:
					</p>
					<img className='img-fluid m-0 mb-3' src={CookiesUsage} alt='Cookies Usage' />
					<p>If you fall within the jurisdiction of the GDPR:</p>
					<ol className='m-0 mb-3'>
						<li>
							You have the right to be informed about the collection and use of your personal data.
						</li>
						<li>You have the right to view and request copies of your personal data.</li>
						<li>
							You have the right to request inaccurate or outdated personal information be updated
							or corrected.
						</li>
						<li>
							You have the right to request their personal data be deleted.{' '}
							<i>
								Note that this is not an absolute right and may be subject to exemptions based on
								certain laws.
							</i>
						</li>
						<li>
							You have the right to ask for your data to be transferred to another controller or
							provided to them. The data must be provided in a machine-readable electronic format.
						</li>
						<li>
							You have the right to request the restriction or suppression of your personal data.
						</li>
						<li>
							You have the right to withdraw previously given consent to process your personal data.
						</li>
						<li>You have the right to object to the processing of their personal data.</li>
						<li>
							You have the right to object to decisions being made with their data solely based on
							automated decision making.
						</li>
					</ol>
					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>
						Agreement with respect to any products or services purchased or used from or through the
						site
					</h3>

					<h4 className='text-start fw-light text-uppercase fs-6 mb-3'>
						You understand there are risks involved with the use of purchased products or services
						<strong className='fw-bold'> purchased or used from or through the site</strong>
					</h4>
					<p>
						You agree and confirm that Company is only arranging for third parties to provide
						Services to you. Nevertheless, there may hazards that you may encounter during the use
						or abuse of any Product or Services you may purchase or use from or through the Site.
						You agree to keep any and all Products purchased out of the reach of children under the
						age of 12; and you agree that you will not allow children under the age of 16 to use or
						participate in any Products or Services without adult supervision.
					</p>
					<h4 className='text-start fw-bold text-uppercase fs-6 mb-3'>
						You waive all claims and assume all risks in connection with the purchase or use of the
						products or services.
					</h4>
					<p>
						You agree and confirm that Company is only arranging for third parties to provide
						Services to you. Nevertheless, you hereby warrant and represent that you understand and
						expressly agree, to the maximum extent allowed by law, to RELEASE Company, its agents
						and employees from, and WAIVE AND ASSUME THE RISK with respect to any and all claims,
						damages or lawsuits for personal injury or wrongful death arising out of or relating to
						your purchase, use, and/or re-sale (if applicable) of any Products and Services you may
						purchase or use, including any and all claims whatsoever of NEGLIGENCE for personal
						injury or property damage that may arise out of or relate to any use or misuse
						whatsoever of the Products or Services, against the Company and its agents and
						employees, to the extent allowed under applicable law.
					</p>
					<h4 className='text-start fw-bold text-uppercase fs-6 mb-3'>
						You hereby provide the permission to the company to photograph and record you while you
						are at the company’s events and use your voice, image and likeness
					</h4>
					<p>
						You hereby grant permission to the Company to photograph you and to record your voice,
						performances, poses, acts, plays and appearances, and use your picture, photograph,
						silhouette and other reproductions of your physical likeness and sound as part of the
						photographs or videography contemplated by or taken by the Company prior to, during or
						after any of the Company’s events (“Productions”), including but not limited to the
						unlimited distribution, advertising, promotion, exhibition and exploitation of the
						Productions by any method or device now known or hereafter devised in which the same may
						be used, and/or incorporated and/or exhibited and/or exploited.
					</p>
					<p>
						You agree that you will not assert or maintain against the Company, its successors,
						assigns and licensees, any claim, action, suit or demand of any kind or nature
						whatsoever, including but not limited to, those grounded upon invasion of privacy,
						rights of publicity or other civil rights, or for any other reason in connection with
						the Company’s authorized use of your physical likeness and sound in the Productions, as
						herein provided.
					</p>
					<p>
						You hereby release the Company, its successors, assigns and licensees, and each of them,
						from and against any and all claims, liabilities, demands, actions, causes of action(s),
						costs and expenses whatsoever, at law or in equity, known or unknown, anticipated or
						unanticipated, which I ever had, now have, or may, shall or hereafter have by reason,
						matter, cause or thing arising out of the Company’s use of you, your voice,
						performances, poses, acts, plays and appearances, and use my picture, photograph,
						silhouette and other reproductions of your physical likeness and sound as part of the
						Company’s Productions, as herein provided.
					</p>
					<p>
						You affirm that neither you, nor anyone acting for you, gave or agreed to give anything
						of value to the Company or any of the Company’s agents or employees or any
						representative of any television station, network or production entity for arranging my
						appearance in any of the Company’s Productions.
					</p>
					<p>
						You affirm that you have read the foregoing release and fully understand the meaning and
						effect thereof and, intending to be legally bound by this release.
					</p>
					<h4 className='text-start fw-bold text-uppercase fs-6 mb-3'>
						YOU AGREE TO HOLD COMPANY HARMLESS & INDEMNIFY COMPANY FROM AND AGAINST ALL CLAIMS
						ARISING OUT OF OR RELATING TO YOUR PURCHASE OR USE OF ANY PRODUCTS OR SERVICES YOU MAY
						PURCHASE OR USE FROM OR THROUGH THE SITE
					</h4>
					<p>
						You agree and confirm that Company is only arranging for third parties to provide
						Services to you. Nevertheless, in view of the your understanding of the provisions of
						this Agreement, and your agreement, to the maximum extent allowed by law, to assume any
						and all risk for, waive and exempt the Company, and its agents and employees from, as
						well as hold harmless and indemnify the Company, and its agents and employees from and
						against, any and all claims, including subrogation by your insurer(s) or other insurers,
						against the Company for any claims, liability or damages to you or your property, or for
						injury or death to you or your family members, employees, customers, agents or
						contractors that may occur, arise out of or relate in any way to your purchase,
						ownership, use or misuse of the Products,{' '}
						<strong>
							even if it is alleged that the subject claim, liability, damages or injuries are or
							caused solely or partially by the negligence of the Company, its agents or employees.
						</strong>
					</p>
					<h4 className='text-start fw-bold text-uppercase fs-6 mb-3'>
						YOU AGREE COMPANY’S LIABILITY BASED ON ANY PRODUCTS OR SERVICES THAT YOU PURCHASE OR USE
						IS LIMITED
					</h4>
					<p>
						You agree and confirm that Company is only arranging for third parties to provide
						Services to you. Nevertheless, you agree to the maximum extent allowed by law, that
						should you or any of your insurers make any claim whatsoever (including one of
						subrogation) against the Company, or its agents or employees, including but not limited
						to a claim that Company was negligent; that Company breached this Agreement; or the
						Company is somehow liable for any actual or potential liability, exposure, payment or
						damage for any property damage or personal injury or death that may a result of your
						purchase, ownership, use or re-sale of the Products or Services purchased or used from
						the Site or through the Site, or the incorporation of the Products into some other
						product, you expressly agree that even if every other disclaimer or limitation of
						liability in this Agreement is deemed inapplicable or unenforceable Company’s liability
						on any such claim, demand or claim is expressly limited to no more than the purchase
						price you have paid for any Products or Services purchases or used (not including
						applicable taxes) or $1,000.00, whichever is less.
					</p>
					<h4 className='text-start fw-bold text-uppercase fs-6 mb-3'>
						YOU AGREE THAT COMPANY HAS NOT MADE ANY IMPLIED REPRESENTATIONS OR WARRANTIES REGARDING
						THE PRODUCTS OR SERVICES PURCHASED OR USED FROM OR THROUGH THE SITE
					</h4>
					<p>
						You agree and confirm that Company is only arranging for third parties to provide
						Services to you. Nevertheless, you acknowledge, agree and confirm that Company has not
						made any representations or warranties with respect to the nature, suitability,
						merchantability, fitness or condition of any Products or Services you may purchase or
						use from or through the Site, except as expressly set forth herein (which are exclusive
						and in lieu of any other warranties), and that to the maximum extent allowable under
						law, no such warranties or representations shall be implied.
					</p>
					<h4 className='text-start fw-bold text-uppercase fs-6 mb-3'>
						YOU AGREE THAT THERE IS A TIME LIMITATION FOR YOU TO BRING CLAIMS FOR DAMAGES AGAINST
						THE COMPANY BASED ON PRODUCTS OR SERVICES THAT YOU PURCHASE OR USE FROM OR THROUGH THE
						SITE
					</h4>
					<p>
						<strong>
							You agree and confirm that Company is only arranging for third parties to provide
							Services to you. Nevertheless, you expressly agree that, you must notify Company of
							any claim whatsoever you might have regarding any Products or Services you have
							purchased or used from or through the Site, or file suit against Company on any claim
							arising out of or relating to any Products or Services you have purchased or used from
							or through the Site, within six (6) months of Company or a third party delivering the
							subject Product or Products, or providing any Services, to you.
						</strong>
					</p>
					<h4 className='text-start fw-bold text-uppercase fs-6 mb-3'>
						YOU AGREE THAT THE COMPANY IS NOT LIABLE FOR ANY CONSEQUENTIAL OR PUNITIVE DAMAGES BASED
						ON CLAIMS FOR PRODUCTS OR SERVICES YOU HAVE PURCHASED OR USED FROM OR THROUGH THE SITE
					</h4>
					<p>
						You agree and confirm that Company is only arranging for third parties to provide
						Services to you. Nevertheless, you also agree that regardless of any claim made against
						you or Company regarding any Products or Services you may purchase or use from or
						through the Site, Company will not be liable for any indirect or consequential damages
						of any kind, as those terms are defined under California law, including but not limited
						to loss of use, lost profits, mitigation expenses, or any other such damage or expense
						regardless of the reason such damage or expense was incurred. You also agree that
						Company (or its agents or employees) will not be liable to have any punitive damages
						imposed against them as a result of any claim you make for any Products or Services you
						may purchase or use from or through the Site.
					</p>
					<h4 className='text-start fw-bold text-uppercase fs-6 mb-3'>MISCELLANEOUS</h4>
					<p>
						This Agreement incorporates by reference the Site Submission Rules if this Site allows
						posting and posts such Rules.
					</p>
					<h4 className='text-start fw-bold text-uppercase fs-6 mb-3'>
						CHOICE OF LAW AND FORUM – USE OF SITE AND PURCHASE OF PRODUCTS OR SERVICES
					</h4>
					<p>
						This Site (excluding third party linked sites) is controlled by the Company from its
						offices within the State of California, U.S. of America. It can be accessed from all 50
						states as well as from other countries around the world to the extent permitted by site.
						As each of these places has laws that may differ from of California by accessing this
						Site, both you and the Company agree that the statutes and laws of California shall
						apply to any actions or claims arising out of or in relation to this Agreement or your
						use of this Site, without regards to conflicts of laws principles thereof. You and the
						Company also agree and hereby submit to the filing of any claim only in the exclusive
						personal jurisdiction and venue of Orange County, California and any legal proceedings
						shall be conducted in English. The Company makes no representation that materials on
						this Site are appropriate or available for use in other locations, and accessing them
						from territories where their contents are illegal is prohibited.
					</p>
					<h4 className='text-start fw-bold text-uppercase fs-6 mb-3'>MEDIATION.</h4>
					<p className='fw-bold'>
						EXCEPT AS PROVIDED IN THE PARAGRAPHS BELOW, OR IN THE EVENT THAT ANY OTHER INJUNCTIVE OR
						EX PARTE RELIEF IS REQUIRED, PRIOR TO THE INITIATION OF ANY LEGAL ACTION OR ARBITRATION
						PROCEEDING BY YOU (OR YOUR COMPANY, AGENTS, DIRECTORS, OFFICERS OR EMPLOYEES), THE
						RELEVANT DISPUTING PARTIES SHALL FIRST MEDIATE ANY DISPUTE OR CLAIM BETWEEN THEM ARISING
						OUT OF OR RELATING TO THIS AGREEMENT OR ANY RESULTING RELATIONSHIP OR TRANSACTION
						BETWEEN SUCH PARTIES. ANY PARTY MAY DEMAND MEDIATION BY MEANS OF WRITTEN NOTICE TO ANY
						OTHER PARTY. WHICH NOTICE SHALL STATE GENERALLY THE NATURE OF THE DISPUTE TO BE
						RESOLVED. NOT LATER THAN TEN CALENDAR DAYS AFTER THE DATE SUCH WRITTEN NOTICE IS
						PROVIDED, THE PARTIES SHALL AGREE UPON A MEDIATOR. IF THE PARTIES CANNOT AGREE UPON A
						MEDIATOR, THE MATTER SHALL BE SUBMITTED TO ADR SERVICES, INC. FOR APPOINTMENT OF A
						MEDIATOR AND TO CONDUCT THE MEDIATION. THE MEDIATION SHALL TAKE PLACE IN ORANGE COUNTY,
						CALIFORNIA. THE PARTIES SHALL HAVE 30 CALENDAR DAYS FROM THE SELECTION OF A MEDIATOR TO
						COMMENCE THE FIRST MEDIATION SESSION. THE PARTIES SHALL SHARE ALL MEDIATION COSTS
						EQUALLY. THE PARTIES AGREE THAT ANY MEDIATED SETTLEMENT AGREEMENT MAY BE ENTERED AS AN
						ARBITRATION AWARD OR JUDGMENT (OR BOTH) AND ENFORCED IN ACCORDANCE WITH THE GOVERNING
						RULES OF CALIFORNIA CIVIL PROCEDURE. SHOULD ANY PARTY FAIL TO PARTICIPATE TIMELY AND IN
						GOOD FAITH IN THE SELECTION PROCESS FOR THE MEDIATOR, OR IN THE MEDIATION PROCESS, SUCH
						PARTY WILL BE DEEMED TO HAVE REFUSED MEDIATION, AND THAT PARTY MAY NOT BE ENTITLED TO
						ATTORNEY FEES THAT MIGHT BE OTHERWISE AVAILABLE TO IT IN ANY SUBSEQUENT COURT ACTION OR
						ARBITRATION (EXCEPT FOR FEE DISPUTES DISCUSSED BELOW).
					</p>
					<h4 className='text-start fw-bold text-uppercase fs-6 mb-3'>ARBITRATION</h4>
					<p className='fw-bold'>
						YOU HEREBY AGREE THAT WITH THE IF INJUNCTIVE OR EX PARTE RELIEF IS REQUIRED, ANY
						DISPUTE, CLAIM OR CONTROVERSY ARISING OUT OF OR RELATING TO THIS AGREEMENT MUST BE
						SETTLED BY BINDING ARBITRATION PURSUANT TO THE RULES AND PROCEDURES OF THE THEN
						APPLICABLE RULES OF ADR SERVICES, JAMS ENDISPUTE (“JAMS”) OR ANY OTHER AGREED DISPUTE
						RESOLUTION SERVICE UNDER CALIFORNIA LAW IN ORANGE COUNTY CALIFORNIA (BUT IF YOU ARE A
						CONSUMER, YOU MAY CHOOSE A DIFFERENT ARBITRATION ORGANIZATION WITH CONSUMER RULES THEN
						IN EFFECT FOR ARBITRATION IN ORANGE COUNTY, CALIFORNIA, THE UNITED STATES.)
					</p>
					<p className='fw-bold'>
						YOU HEREBY WAIVE ANY RIGHT TO REPRESENT ANY CLASS, OR JOIN OR TAKE PART IN ANY CLASS
						ACTION, WITH RESPECT TO ANY CLAIM OR DISPUTE THAT MAY ARISE OUT OF OR RELATED IN ANY WAY
						TO THIS AGREEMENT OR THE RELATIONSHIP OF THE PARTIES.
					</p>
					<p className='fw-bold'>
						YOU ALSO HEREBY WAIVE YOUR RIGHT TO DISCOVERY (EXCEPT AS ALLOWED UNDER THE THEN
						APPLICABLE RULES OF ADR SERVICES, JAMS ENDISPUTE (“JAMS”) OR ANY OTHER AGREED DISPUTE
						RESOLUTION SERVICE, A TRIAL BY JURY AND THE RIGHT OF APPEAL THAT WOULD NORMALLY APPLY TO
						COURT PROCEEDINGS AND JUDGMENTS. THE SUBSTANTIVE AND EVIDENTIARY LAW APPLICABLE TO ALL
						CONTROVERSIES DECIDED BY ARBITRATION SHALL BE CALIFORNIA LAW AND THE CALIFORNIA RULES OF
						EVIDENCE. THIS AGREEMENT SHALL BE ENFORCEABLE AND JUDGMENT UPON ANY AWARD RENDERED BY
						ANY ARBITRATOR MAY BE ENTERED BY ANY COURT HAVING JURISDICTION.
					</p>
					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>Entire agreement</h3>
					<p>
						This Agreement constitutes the entire agreement between the parties related to the
						subject matter thereof, supersedes any prior or contemporaneous (oral, written or
						electronic) agreement between the parties and shall not be changed except by written
						agreement signed by the President or CEO of the Company.
					</p>

					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>Severability</h3>
					<p>
						If any provision of this Agreement is prohibited by law are held to be unenforceable,
						the remaining provisions hereof shall not be affected, and this Agreement as much as
						possible under applicable law shall continue in full force and effect as if such
						unenforceable provision had never constituted a part hereof and the unenforceable
						provision shall be automatically amended so as to best accomplish the objectives of such
						unenforceable provision within the limits of applicable law.
					</p>
					<h3 className='text-start fw-bold text-uppercase fs-5 mb-3'>
						Reservation of company’s right to revise
					</h3>
					<p>
						This Site reserves the right to revise these provisions at its discretion, so check back
						from time to time to be sure you are complying with the current version.
					</p>
				</div>
			</div>
		</div>
	);
}
