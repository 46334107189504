export default function Button({
  buttonRef,
  mainClass,
  wrapperClass,
  buttonIcon,
  buttonText,
  spinnerClass,
  type,
  callback,
  disabled = false,
}) {
  return (
    <div className={wrapperClass}>
      <button
        disabled={disabled}
        ref={buttonRef}
        className={mainClass}
        type={type === "button" ? "button" : "submit"}
        onClick={(e) => (callback ? callback(e) : {})}
      >
        {buttonIcon ? buttonIcon : null}
        <div className={spinnerClass}></div>
        {buttonText}
      </button>
    </div>
  );
}