import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import TodoList from './TodoList';
import AppointmentsList from './AppointmentsList';
import ActivitiesList from './ActivitiesList';
import DocumentsList from './DocumentsList';
import CustomerDashboardAsideCard from './CustomerDashboardAsideCard';
import CustomerMessageBoard from '../leads/components/CustomerMessageBoard';
import Offcanvas from '../../../components/Dashboard/Offcanvas';
import Modal from '../../../components/Dashboard/Modal';
import RequestJobForm from '../../../components/forms/RequestJobForm';
import AppointmentOffcanvas from './AppointmentOffcanvas';
import UserAvatar from '../../../components/UserAvatar';
import allActions from '../../../Store/action';
import { getTaxproStatus } from '../../../utilites/getStatusNameById';

const CustomerDashboard = ({ LoginData, lastLead, reload }) => {
	const [{ recentNotifications }, { upcomingAppointments }] = useSelector((state) => [
		state.firebase,
		state.dashboard,
	]);
	const [selectedAppointment, setSelectedAppointment] = useState(null);
	const [taxProId, setTaxProId] = useState();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [contract] = useState(LoginData?.lead[0]?.jobs?.filter((v) => v?.status_id === 0) || []);
	useEffect(() => {
		if (lastLead?.userpro?.user?.uuid) {
			setTaxProId(lastLead?.userpro?.user?.uuid);
		}
	}, [reload, lastLead?.userpro?.user?.uuid]);

	return (
		<div className="clearfix">
			<Header name="Dashboard" />

			<div className="row">
				{lastLead && lastLead.status !== 3 && lastLead.status !== 4 ? (
					<>
						<div className="col-md-4">
							<div className="row mb-3">
								<div className="col d-flex flex-column gap-3">
									<CustomerDashboardAsideCard title="Your accountant">
										<div className="d-flex flex-column align-items-center my-4">
											<Link
												to="#"
												className="text-decoration-none text-center"
												data-bs-toggle="offcanvas"
												data-bs-target="#openTaxProDetailsPanel"
												aria-controls="openTaxProDetailsPanel"
											>
												<UserAvatar
													avatar={lastLead?.userpro?.user?.avatar}
													alt={`${lastLead?.userpro?.user.firstname} ${lastLead?.userpro?.user.lastname}`}
													className="img-fluid object-fit-cover rounded-circle"
													style={{ width: 70, height: 70 }}
												/>
												<p className="card-text text-black fw-bold mt-2 mb-1">
													{`${lastLead?.userpro?.user.firstname} ${lastLead?.userpro?.user.lastname}`}
													, CPA
												</p>
												<p className="card-text text-black mb-2">Tax Professional</p>
												<span className="badge bg-primary">
													{getTaxproStatus(lastLead?.userpro?.status_id)}
												</span>
											</Link>
										</div>
									</CustomerDashboardAsideCard>
									<CustomerDashboardAsideCard title="To Do List">
										<TodoList
											appointments={upcomingAppointments}
											assessment={LoginData?.assessment}
											jobs={lastLead?.jobs}
											payments={LoginData?.userpaymentmethod}
											unsignContract={contract}
										/>
									</CustomerDashboardAsideCard>
									<CustomerDashboardAsideCard
										title="Appointments"
										plusButtonClickHandler={() => navigate('/dashboard/appointments')}
									>
										<AppointmentsList
											data={upcomingAppointments}
											setSelectedAppointment={setSelectedAppointment}
											timezone={LoginData.settings?.timezone}
										/>
									</CustomerDashboardAsideCard>
									<CustomerDashboardAsideCard title="Activity">
										<ActivitiesList uuid={LoginData.uuid} notifications={recentNotifications} />
									</CustomerDashboardAsideCard>
									<CustomerDashboardAsideCard
										title="Documents"
										plusButtonClickHandler={() => navigate('/dashboard/jobDocuments')}
									>
										<DocumentsList
											data={lastLead?.jobs}
											userpro={lastLead?.userpro}
											userData={LoginData}
											reloadData={reload}
										/>
									</CustomerDashboardAsideCard>
								</div>
							</div>
						</div>
						<div className="col-md-8">
							<div className="clearfix sticky-top top-5" style={{ zIndex: 9 }}>
								{taxProId && <CustomerMessageBoard uuid={taxProId} group={false} editable={true} />}
							</div>
						</div>
					</>
				) : (
					<div className="clearfix p-5 text-center">
						<p className="text-muted py-5">You have not requested any job yet.</p>
						<Link
							to="#"
							className="btn btn-primary"
							data-bs-toggle="modal"
							data-bs-target="#requestJobModal"
						>
							Request new job
						</Link>
					</div>
				)}
			</div>

			<Footer />

			<Offcanvas
				header="Account Details"
				position="end"
				trigger="openTaxProDetailsPanel"
				style={{ width: 500 }}
			>
				<div className="clearfix p-4">
					<div className="text-center border border-light p-4 mb-4">
						<UserAvatar
							avatar={lastLead?.userpro?.user?.avatar}
							alt={`${lastLead?.userpro?.user.firstname} ${lastLead?.userpro?.user.lastname}`}
							className="img-fluid object-fit-cover rounded-circle"
							style={{ width: 70, height: 70 }}
						/>
						<p className="card-text text-black fw-bold mt-2 mb-1">
							{`${lastLead?.userpro?.user.firstname} ${lastLead?.userpro?.user.lastname}`}, CPA
						</p>
						<p className="card-text text-black mb-2">Tax Professional</p>
						<span className="badge bg-primary">{getTaxproStatus(lastLead?.userpro?.status_id)}</span>
					</div>

					{lastLead?.userpro.expertise &&
						typeof lastLead?.userpro.expertise === 'object' &&
						lastLead?.userpro.expertise?.length !== 0 && (
							<div className="clearfix mb-4">
								<h5>Expertise</h5>
								<p>
									{lastLead?.userpro.expertise?.map((exp) => (
										<span className="badge bg-grey-100 text-grey-900 me-2" key={exp.value}>
											{exp.label}
										</span>
									))}
								</p>
							</div>
						)}
					{lastLead?.userpro.experience && lastLead?.userpro.experience !== '' && (
						<div className="clearfix">
							<h5>About Me</h5>
							<p>{lastLead?.userpro.experience}</p>
						</div>
					)}
				</div>
			</Offcanvas>
			<Offcanvas
				header="Event"
				position="end"
				trigger="openAppointmentDetailsPanel"
				style={{ width: 500 }}
			>
				{selectedAppointment && (
					<AppointmentOffcanvas
						data={selectedAppointment}
						close={() => {
							document.getElementById('close-openAppointmentDetailsPanel').click();
						}}
						reload={() => {
							dispatch(allActions.dashboard.getUpcomingAppointments());
						}}
					/>
				)}
			</Offcanvas>
			<Modal
				title="Request New job"
				bodyClass="px-5 py-4 text-center"
				trigger="requestJobModal"
				showBtn={false}
				btnClass="requestJobModalClose"
				size="lg"
			>
				<RequestJobForm data={lastLead} user={LoginData} reload={reload} />
			</Modal>
		</div>
	);
};

export default CustomerDashboard;
