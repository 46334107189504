/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import TextArea from '../../fields/TextArea';
import Button from '../../fields/Button';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';
import { HandleFormData } from '../../../../utilites';

const Comments = () => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const dispatch = useDispatch();

	const uploadFormData = HandleFormData({ parentIndex: 5, myIndex: 1, redirect: '/dashboard' });

	const [{ taxPayerFormData, taxPayerForm, formInformation }, { LoginData }] = useSelector(
		(state) => [state.taxForms, state.user]
	);
	
	// For Text Input Field

	const [comments, setComments] = useState('');

	// For Choice Questions

	const [errors, setErrors] = useState({}); // Track errors for form fields

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.CommentsForm) {
			if (Object.keys(taxPayerForm?.data?.CommentsForm).length === 0) return;

			const { comments } = taxPayerForm?.data?.CommentsForm;

			setComments(comments);
		}
	}, [taxPayerForm?.data?.CommentsForm]);

	// ---------------------------------------
	// Functions
	// ---------------------------------------

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		setErrors(newErrors);

		// Check if there are any errors
		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let errors = check_Validity();
		if (errors) return;

		let FormObj = {
			comments,
		};

		let formData = taxPayerForm;
		taxPayerForm.data.CommentsForm = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		// if (!makeSureFormFilling()) {
		// 	setErrors({
		// 		FormError: "Check You Have Some Form Missed To Filled!, First Fill Them.",
		// 	});
		// 	return;
		// }

		let obj = {
			service_id: 1,
			method: 'upload',
			formName: formInformation?.name,
			formData: taxPayerForm.data,
		};
		if (LoginData?.isTaxPro || LoginData?.isAdmin || LoginData?.isAccountManager)
			obj.lead_id = formInformation?.lead_id;

		changeLoading(true);
		dispatch(allActions.dashboard.resetTaxPreparation());
		dispatch(allActions.dashboard.taxPreparation({ obj }));

		uploadFormData(formData);
	};

	// const makeSureFormFilling = () => {
	// 	let completed = true;
	// 	Object.keys(taxPayerForm.data).forEach((element) => {
	// 		if (Object.keys(taxPayerForm.data[element]).length === 0) {
	// 			completed = false;
	// 		}
	// 	});
	// 	return completed;
	// };

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 5;
		let myIndex = 1;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};
	console.log(
		'taxPayerFormData[4].SubOption[1].completed',
		taxPayerFormData[4].SubOption[1].completed
	);
	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<div className="mt-3 row justify-content-between px-3 mb-3 custom-left-form">
				<small className="text-dark fw-bold m-0 py-2">
					Enter Any Additional Information / Question here.
				</small>
				<div className="col-md-8 mb-3">
					<TextArea
						inputName="form-control"
						defaultValue={comments}
						placeholder="Comments"
						name="comments"
						fn={setComments}
						row="6"
						required={false}
					/>
				</div>
			</div>
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Submit"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default Comments;
