import axios from 'axios';
import React, { useState, useRef } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { BASEURL } from '../../utilites';
import Input from './fields/Input';
import Button from './fields/Button';

export default function UploadDraftDocumentForm({
	setAlert,
	userId,
	jobId,
	reloadData,
	targetUserUuid,
}) {
	const [file, setFile] = useState();
	const [documentName, setDocumentName] = useState('');
	// Progress
	const [uploading, setUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);

	const [errors, setErrors] = useState({
		file: false,
		general: '',
	});
	const button = useRef();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setUploading(true);
		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });
		// Create a new FormData instance
		const formData = new FormData();
		// Append properties to FormData
		formData.append('documents', file);
		formData.append('user_id', userId);
		formData.append('job_id', jobId);
		formData.append('type', 3);
		if (documentName) formData.append('document_name', documentName);
		if (targetUserUuid) formData.append('target_user_uuid', targetUserUuid);
		try {
			await axios
				.post(`${BASEURL}/api/documents`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					onUploadProgress: (progressEvent) => {
						// Calculate the upload progress percentage
						const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
						// Update the uploadProgress state for the current file
						setUploadProgress(progress);
					},
				})
				.then(() => {
					button.current.disabled = false;
					// Reload data
					reloadData && reloadData();
					// Close Modal
					document.querySelector('.uploadDraftDocumentModalClose').click();
					// Re-get user data
					setAlert && setAlert('Draft document has been uploaded successfully!');
				});
		} catch (error) {
			button.current.disabled = false;
			console.error(error.message);
			// Set general error
			if (errors.general === '')
				setErrors({ errors, general: error?.response?.data?.message || error.message });
		}
		// Reset the form
		setUploading(false);
		setUploadProgress(0);
		setFile();
		setDocumentName('');
	};

	return (
		<form className='draftdocform needs-validation' onSubmit={handleSubmit}>
			{!uploading && (
				<>
					<div className='row'>
						<div className='col-md-12 mb-4'>
							<FileUploader
								classes='form-control'
								multiple={false}
								values={file}
								handleChange={(f) => setFile(f)}
								name='file'
								types={['PDF']}
								onTypeError={() => {
									setErrors({ ...errors, file: true });
								}}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-12 mb-3'>
							<Input
								type='text'
								value={documentName}
								name='documentName'
								placeholder='Document Name'
								label='Enter the name of the Document below'
								fn={setDocumentName}
								hasValidationError={errors.documentName}
								showLabel={true}
							/>
						</div>
					</div>
				</>
			)}
			{uploading ? (
				<div className='progress'>
					<div
						className='progress-bar progress-bar-striped progress-bar-animated'
						role='progressbar'
						style={{ width: `${uploadProgress}%` }}
						aria-valuenow={uploadProgress}
						aria-valuemin='0'
						aria-valuemax='100'
					>
						{uploadProgress}%
					</div>
				</div>
			) : null}
			{errors.general && errors.general !== '' && (
				<div className='row'>
					<div className='mb-3'>
						<div className='alert alert-danger' role='alert'>
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 || !file ? ' disabled' : ''
				}`}
				wrapperClass='clearfix w-75 mx-auto d-grid text-center mt-3'
				buttonText='Upload'
			/>
		</form>
	);
}
