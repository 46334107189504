import UnderlineStroke from '../../assets/img/underline-stroke.png';
import HomeConnect from '../../assets/img/home-connect.png';
import HomeSupport from '../../assets/img/home-support.png';
import HomeReceive from '../../assets/img/home-receive.png';

const Blurbs = () => {
    return (
        <section className="clearfix pt-5 pb-0 pb-md-5 px-3">
            <div className="container">
                <div className="clearfix w-85 mx-auto text-dark mb-0 mb-md-5 blurbs">
                    <div className="clearfix text-center mb-5">
                        <h1 className="display-3 fw-bold"><span className="underline-stroke">How it works<img src={UnderlineStroke} className="img-fluid" alt="" /></span></h1>
                    </div>
                    <div className="row g-5 justify-content-center align-items-center align-middle text-center text-md-start">
                        <div className="col-md-5 order-1">
                            <img src={HomeConnect} alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-1 order-3 order-md-2"></div>
                        <div className="col-md-6 order-2 order-md-3">
                            <h2 className="display-6 mb-4 one">Connect with a tax expert specialized to your needs</h2>
                            <p>Enter your information and answer some key questions about your tax and financial standing. Regardless of whether you’re a small business owner, self-employed, or employed under a W-2, we will match you with an expert you can count on.</p>
                        </div>
                    </div>
                    <div className="row g-5 justify-content-center align-items-center align-middle text-center text-md-start">
                        <div className="col-md-6 order-3 order-md-1">
                            <h2 className="display-6 mb-4 two">Receive your <span className="text-primary fw-heavy">FREE</span> tax savings consultation</h2>
                            <p>Gain invaluable insights and well-informed recommendations from your trusted tax professional. Discover and learn how they can assist you in legally optimizing your tax savings.</p>
                        </div>
                        <div className="col-md-1 order-3 order-md-2"></div>
                        <div className="col-md-5 order-1 order-md-2">
                            <img src={HomeReceive} alt="" className="img-fluid" />
                        </div>
                    </div>
                    <div className="row g-5 justify-content-center align-items-center align-middle text-center text-md-start">
                        <div className="col-md-5 order-1">
                            <img src={HomeSupport} alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-1 order-3 order-md-2"></div>
                        <div className="col-md-6 order-2 order-md-3">
                            <h2 className="display-6 mb-4 three">Tax support on your time whenever you need it</h2>
                            <p>Say goodbye to tax-time stress for good! Rely on your expert year-round to guide you through any tax hurdles, lightening your load and allowing you to channel your energies into what you do best.</p>
                        </div>
                    </div>
                </div>
                    
            </div>
        </section>
    )
};

export default Blurbs;