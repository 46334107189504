import { MEMBERSHIP_ACTION_TYPE } from './Type';

const DEFAULT_STATE = {
	initialRegisterMembership: { message: '', status: 'idle', data: {} },
	getMembership: { message: '', status: 'idle', data: {} },
	updateMembership: { message: '', status: 'idle', data: {} },
	updateMembershipPlan: { message: '', status: 'idle', data: {} },
	allMembershipPlan: { message: '', status: 'idle', data: {} },
	createMembershipByAdmin: { message: '', status: 'idle', data: {} },
	getAllMembershipPlanByAdmin: { message: '', status: 'idle', data: {} },
	deleteMembershipPlan: { message: '', status: 'idle', data: {} },
};

const INITIAL_STATE = {
	...DEFAULT_STATE,
};

const membership = (state = INITIAL_STATE, action) => {
	if (action.type === MEMBERSHIP_ACTION_TYPE.registerMembership) {
		return {
			...state,
			initialRegisterMembership: action.payload,
		};
	}
	if (action.type === MEMBERSHIP_ACTION_TYPE.getMembership) {
		return {
			...state,
			getMembership: action.payload,
		};
	}
	if (action.type === MEMBERSHIP_ACTION_TYPE.updateMembership) {
		return {
			...state,
			updateMembership: action.payload,
		};
	}
	if (action.type === MEMBERSHIP_ACTION_TYPE.updateMembershipIdle) {
		return {
			...state,
			updateMembership: DEFAULT_STATE.updateMembership,
		};
	}
	if (action.type === MEMBERSHIP_ACTION_TYPE.updateMembershipPlanIdle) {
		return {
			...state,
			updateMembershipPlan: DEFAULT_STATE.updateMembershipPlan,
		};
	}
	if (action.type === MEMBERSHIP_ACTION_TYPE.registerMembershipIdle) {
		return {
			...state,
			initialRegisterMembership: DEFAULT_STATE.initialRegisterMembership,
		};
	}
	if (action.type === MEMBERSHIP_ACTION_TYPE.updateMembershipPlan) {
		return {
			...state,
			updateMembershipPlan: action.payload,
		};
	}
	if (action.type === MEMBERSHIP_ACTION_TYPE.allMembershipPlan) {
		return {
			...state,
			allMembershipPlan: action.payload,
		};
	}
	if (action.type === MEMBERSHIP_ACTION_TYPE.createMembershipByAdmin) {
		return {
			...state,
			createMembershipByAdmin: action.payload,
		};
	}
	if (action.type === MEMBERSHIP_ACTION_TYPE.createMembershipByAdminIdle) {
		return {
			...state,
			createMembershipByAdmin:DEFAULT_STATE.createMembershipByAdmin,
		};
	}
	if (action.type === MEMBERSHIP_ACTION_TYPE.getAllMembershipPlanByAdmin) {
		return {
			...state,
			getAllMembershipPlanByAdmin: action.payload,
		};
	}
	if (action.type === MEMBERSHIP_ACTION_TYPE.deleteMembershipPlan) {
		return {
			...state,
			deleteMembershipPlan: action.payload,
		};
	}
	if (action.type === MEMBERSHIP_ACTION_TYPE.deleteMembershipPlanIdle) {
		return {
			...state,
			deleteMembershipPlan: DEFAULT_STATE.deleteMembershipPlan,
		};
	}

	return state;
};

export default membership;
