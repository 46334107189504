import { BiDotsVerticalRounded } from "react-icons/bi";

const Header = () => {
	return (
		<div className="w-100 d-flex flex-row  justify-content-between header-footer" >
			<div>
				<p className="fw-bold mt-1">Form Heading</p>
			</div>
			<div className=" d-flex flex-row align-items-center justify-content-between">
				{/* <button className="btn  small-button border-1 border rounded ml-2">
					<small className="text-dark fw-bold">Save</small>
				</button> */}
				<BiDotsVerticalRounded role="button" className="pl-2" />
			</div>
		</div>
	);
};

export default Header;
