/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';

import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';

const BusinessExpenses = () => {
    const { businessExpencesDescAmount, businessExpencesStateDesc } = GLOBAL_VARIABLES

    const dispatch=useDispatch()
	const [{ taxPayerForm, taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 4 });

    // ---------------------------------------
    // Variables , States & Store States
    // ---------------------------------------
    //
    // const { otherQuestions } = GLOBAL_VARIABLES;

    // const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 0 });

    // const [{ taxPayerForm }] = useSelector((state) => [state.taxForms]);
    // form States

    // for Current component States
    const [materials, setMaterials] = useState();
    const [purchases, setPurchases] = useState();
    const [costOfLabour, setCostOfLabour] = useState();
    const [inventoryAtBegining, setInventoryAtBegining] = useState();
    const [businessExpensesStateDescription, setBusinessExpensesStateDescription] = useState([]);
    const [businessExpensesDescriptionAmount, setBusinessExpensesDescriptionAmount] = useState([]);
    console.log("BE", businessExpensesStateDescription)
    // ---------------------------------------
    // HOOKS TO STORE DATA
    // ---------------------------------------
    useEffect(() => {
        setBusinessExpensesDescriptionAmount(JSON.parse(JSON.stringify(businessExpencesDescAmount)));
    }, []);

    useEffect(() => {
        setBusinessExpensesStateDescription(JSON.parse(JSON.stringify(businessExpencesStateDesc)));
    }, []);
    // SAVE VALUES TO STATES
    useEffect(() => {
    	if (taxPayerForm?.data?.BusinessExpenses) {
    		if (Object.keys(taxPayerForm?.data?.BusinessExpenses).length === 0) return;
            const {
                materials,
                purchases,
                costOfLabour,
                inventoryAtBegining,
                businessExpensesStateDescription,
                businessExpensesDescriptionAmount
            } = taxPayerForm?.data?.BusinessExpenses;

            setMaterials(materials);
            setPurchases(purchases);
            setCostOfLabour(costOfLabour);
            setInventoryAtBegining(inventoryAtBegining);
            setBusinessExpensesStateDescription(businessExpensesStateDescription);
            setBusinessExpensesDescriptionAmount(businessExpensesDescriptionAmount)
    	}
    }, [taxPayerForm?.data?.BusinessExpenses]);
    const handleAmountChange = (id, index) => {
        const myInputAmount = document.getElementById(id);
        let localDeduction = businessExpensesDescriptionAmount;
        localDeduction[index].amount = myInputAmount.value;
        setBusinessExpensesDescriptionAmount(JSON.parse(JSON.stringify(localDeduction)));
    };

    const handleDiscrptionChange = (id, index) => {
        const myInputAmount = document.getElementById(id);
        let localDeduction = businessExpensesDescriptionAmount;
        localDeduction[index].description = myInputAmount.value;
        setBusinessExpensesDescriptionAmount(JSON.parse(JSON.stringify(localDeduction)));
    };

    const handleStateAmountChange = (id, index) => {
        const myInputAmount = document.getElementById(id);
        let localDeduction = businessExpensesStateDescription;
        localDeduction[index].state = myInputAmount.value;
        setBusinessExpensesStateDescription(JSON.parse(JSON.stringify(localDeduction)));
    };

    const handleDiscrptionStateChange = (id, index) => {
        const myInputAmount = document.getElementById(id);
        let localDeduction = businessExpensesStateDescription;
        localDeduction[index].description = myInputAmount.value;
        setBusinessExpensesStateDescription(JSON.parse(JSON.stringify(localDeduction)));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let FormObj = {
            materials,
            purchases,
            costOfLabour,
            inventoryAtBegining,
            businessExpensesStateDescription,
            businessExpensesDescriptionAmount
        };

        let formData = taxPayerForm;
        taxPayerForm.data.BusinessExpenses = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));

        uploadFormData(formData);
    };

    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 4;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            <small className="ml-4 fw-bold">Business Expenses</small>
            <hr className="m-0" />
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                <div className="col-md-6 mb-3">
                    <Input
                        type="text"
                        name="enityName"
                        value={inventoryAtBegining}
                        placeholder="Inventory at beginning of year"
                        fn={setInventoryAtBegining}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <Input
                        type="text"
                        name="changeInOwnership"
                        value={materials}
                        placeholder="Materials and supplies"
                        fn={setMaterials}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="ownBusinessPercentage"
                        value={purchases}
                        placeholder="Purchases less cost of items withdrawn for personal use"
                        fn={setPurchases}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="number"
                        name="spouseBusinessPercentage"
                        value={costOfLabour}
                        placeholder="Cost of labor (do not include salary paid to yourself)"
                        fn={setCostOfLabour}
                    />
                </div>
                <div className="w-100 d-flex flex-row flex-wrap justify-content-between align-items-end mb-3 px-4 mt-3">
                    {businessExpensesDescriptionAmount?.map((data, i) => {
                        return (
                            <div
                                onClick={() => { }}
                                key={i}
                                className="col-md-12 px-2 my-2"
                            >
                                <small className="text-start fw-bold col-md-12">{data.question}</small>
                                <div className="col-md-12 d-flex flex-row justify-content-between flex-wrap">
                                    <div className="col-md-5">
                                        <Input
                                            type="text"
                                            value={data?.amount}
                                            name="Amount"
                                            placeholder="Amount"
                                            formid={`Amount${i}`}
                                            fn={() => {
                                                handleAmountChange(`Amount${i}`, i);
                                            }}
                                            mask={'999999999'}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Input
                                            type="text"
                                            name="description"
                                            placeholder='Description'
                                            formid={`Descrip${i}`}
                                            value={data?.description}
                                            fn={() => {
                                                handleDiscrptionChange(`Descrip${i}`, i);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <small className="ml-4 fw-bold">state</small>
            <hr className="m-0" />
            <div className="w-100 d-flex flex-row flex-wrap justify-content-between align-items-end mb-3 px-4 mt-3">
                {businessExpensesStateDescription?.map((data, i) => {
                    return (
                        <div
                            onClick={() => { }}
                            key={i}
                            className="col-md-12 px-2 my-2"
                        >
                            <small className="text-start fw-bold col-md-12">{data.question}</small>
                            <div className="col-md-12 d-flex flex-row justify-content-between flex-wrap">
                                <div className="col-md-5">
                                    <Input
                                        type="text"
                                        value={data?.state}
                                        name="State"
                                        placeholder="State"
                                        formid={`States${i}`}
                                        fn={() => {
                                            handleStateAmountChange(`States${i}`, i);
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        type="text"
                                        name="description"
                                        placeholder="description"
                                        formid={`Descripi${i}`}
                                        value={data?.description}
                                        fn={() => {
                                            handleDiscrptionStateChange(`Descripi${i}`, i);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text  w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button  mt-4'}
                        type="button"
                        callback={()=>goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text   w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button  mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default BusinessExpenses;