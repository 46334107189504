/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { UsaStates } from 'usa-states';
import TextArea from '../../fields/TextArea';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import Input from '../../fields/Input';
// CSS
import 'react-datepicker/dist/react-datepicker.css';
import allActions from '../../../../Store/action';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';

const UsaStatesList = new UsaStates().states?.map((e) => {
	return { label: e.name, value: e.abbreviation };
});

const EstimateTaxes = () => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const dispatch = useDispatch();

	const uploadFormData = HandleFormData({ parentIndex: 4, myIndex: 0 });

	const { estimateTaxesOptions, federalPaymentsOptions } = GLOBAL_VARIABLES;

	const [{ taxPayerFormData, taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);
	// For Text Input Field

	const [comments, setComments] = useState('');

	// For Choice Questions

	const [state, setState] = useState({});
	const [estimateTaxesSelect, setEstimateTaxesSelect] = useState();
	const [federalPayments, setFederalPayments] = useState([]);

	// For Choice Questions

	const [errors, setErrors] = useState({}); // Track errors for form fields

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------
	useEffect(() => {
		setFederalPayments(JSON.parse(JSON.stringify(federalPaymentsOptions)));
	}, []);
	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.estimateTaxesForm) {
			if (Object.keys(taxPayerForm?.data?.estimateTaxesForm).length === 0) return;

			const { state, estimateTaxesSelect, federalPayments, comments } =
				taxPayerForm?.data?.estimateTaxesForm;
			setState(UsaStatesList.find((s) => s.value === state) || null);
			setEstimateTaxesSelect(estimateTaxesSelect);
			setFederalPayments(federalPayments);
			setComments(comments);
		}
	}, [taxPayerForm?.data?.estimateTaxesForm]);

	// ---------------------------------------
	// Functions
	// ---------------------------------------

	const handleAmountChange = (id, index) => {
		const myInputAmount = document.getElementById(id);
		let localDeduction = federalPayments;
		localDeduction[index].amount = myInputAmount.value;
		setFederalPayments(JSON.parse(JSON.stringify(localDeduction)));
	};

	const handleDateChange = (id, index) => {
		const myInputAmount = document.getElementById(id);
		let localDeduction = federalPayments;
		localDeduction[index].date = myInputAmount.value;
		setFederalPayments(localDeduction);
	};

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		if (!estimateTaxesSelect) {
			newErrors.estimateTaxesSelect = 'Select Deduction Options';
		}

		setErrors(newErrors);

		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let errors = check_Validity();
		if (errors) return;

		let FormObj = { state, estimateTaxesSelect, federalPayments, comments };

		let formData = taxPayerForm;
		taxPayerForm.data.estimateTaxesForm = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
	};

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 4;
		let myIndex = 0;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<div className=" mb-3 px-3">
					{estimateTaxesOptions?.map((data, i) => {
						return (
							<div key={i} className="w-100  align-items-center justify-content-between mt-2">
								<small
									className="col-md-12 form-check-label text-start fw-bold"
									htmlFor={`checkbox-${i}`}
								>
									{data.question}
								</small>
								{data?.options?.map((option, index) => {
									return (
										<div className="col-md-12">
											<input
												onChange={() => setEstimateTaxesSelect(option)}
												checked={estimateTaxesSelect === option}
												className="form-check-input"
												type="radio"
												id={`checkbox-${index}`}
												name={`checkbox-${index}`}
											/>
											<label htmlFor={`checkbox-${index}`} role="button" className="px-3 m-0 small">
												{option}
											</label>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
			<br />
			{estimateTaxesSelect === estimateTaxesOptions[0]?.options?.[0] && (
				<>
					<p className="fw-bold">In which states did you make estimated payments? </p>
					<div className="col-md-6 mb-3">
						<ReactSelect
							id="state"
							className="p-0 basic-single"
							placeholder="Select state"
							closeMenuOnSelect={true}
							options={UsaStatesList}
							onChange={setState}
							value={state}
						/>
					</div>
					<p className="fw-bold">Federal payments</p>
					<hr />
					<div className="w-100 d-flex flex-row flex-wrap justify-content-between align-items-end mb-3 px-4 mt-3">
						{federalPayments?.map((data, i) => {
							return (
								<div
									onClick={() => {}}
									key={i}
									className="col-md-12 d-flex align-items-end justify-content-between px-2 my-2"
								>
									<small className="text-start fw-bold col-md-6">{data.question}</small>
									<div className="col-md-6 d-flex flex-row justify-content-between flex-wrap">
										<div className="col-md-5">
											<Input
												type="text"
												value={data?.amount}
												name="Amount"
												placeholder="Amount"
												formid={`Amount${i}`}
												fn={() => {
													handleAmountChange(`Amount${i}`, i);
												}}
												mask={'999999999'}
											/>
										</div>
										{data.date !== 'not Exist' && (
											<div className="col-md-6">
												<Input
													type="text"
													name="dob"
													placeholder="Date (MM-DD-YYYY)"
													mask={'99-99-9999'}
													formid={`Date${i}`}
													value={data.date}
													fn={() => {
														handleDateChange(`Date${i}`, i);
													}}
												/>
											</div>
										)}
									</div>
								</div>
							);
						})}
					</div>
				</>
			)}
			<br />
			<small className="ml-4 fw-bold">Comments</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3 mb-3 custom-left-form">
				<small className="text-dark fw-bold m-0 py-2">
					Enter Any Additional Information / Question here.
				</small>
				<div className="col-md-8 mb-3">
					<TextArea
						inputName="form-control"
						defaultValue={comments}
						placeholder="Comments"
						name="comments"
						fn={setComments}
						row="6"
						required={false}
					/>
				</div>
			</div>
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default EstimateTaxes;
