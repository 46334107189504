import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import moment from "moment-timezone";
import axios from "axios";
import SEO from "../components/SEO";
import { BASEURL } from "../utilites";
import {
  convertTimeIntoSselectedTZ,
  timezoneShortFormatter,
} from "../utilites/getStatusNameById";

const Thankyou = () => {
  const [appointment, setAppointment] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    axios
      .get(`${BASEURL}/api/booking/lead/${searchParams.get("uuid")}`)
      .then((response) => {
        setAppointment(response.data.data);
      });
  }, [searchParams]);
  console.log("$$$$@", moment.tz.guess());
  return (
    <div className="clearfix">
      <SEO title="Thank you!" />
      <div className="page bg-secondary-400 px-3 pt-5 pb-3 pb-md-5 h-100">
        <div className="container">
          <div className="clearfix w-75 mx-auto text-center p-5">
            {/*<span className='small bg-primary text-white px-3 py-1 rounded-4'>Thank You</span>*/}
            {appointment?.appointment ?  
              <h1 className="mt-3">Appointment Booked</h1>
              :
              <h1 className="mt-3">Thank You</h1>
            }
            <p className="text-dark text-opacity-75 w-75 mx-auto mb-4">
              Thanks{" "}
              <span className="fw-bold">{appointment?.user.firstname}</span>. An
              email has been sent to your inbox. Please make sure to click on
              the link in that email to access your account and get started with
              your assigned Tax Professional{" "}
              <span className="fw-bold text-decoration-underline">{`${appointment?.userpro.user.firstname} ${appointment?.userpro.user.lastname}`}</span>{" "}
              immediately.
            </p>
            {appointment?.appointment && (
              <div className="row justify-content-center p-4 bg-white fs-6">
                <div className="col-md-9">
                  <div className="clearfix text-start">
                    <h4 className="text-dark fw-bold">
                      Tax savings strategies and planning
                    </h4>
                    <p className="text-grey-200 mb-2">
                      with{" "}
                      <span className="fw-bold text-dark">{`${appointment?.userpro.user.firstname} ${appointment?.userpro.user.lastname}`}</span>{" "}
                      on{" "}
                      <span className="fw-bold text-dark">
                        {appointment?.appointment
                          ? convertTimeIntoSselectedTZ(
                              appointment?.appointment?.timezone,
                              moment.tz.guess(),
                              moment.utc(appointment?.appointment.start_date),
                              "MMMM Do YYYY, h:mm a"
                            ) +
                            " " +
                            timezoneShortFormatter(moment.tz.guess())
                          : ""}
                      </span>
                    </p>
                    <p className="text-grey-300 mb-0">
                      by {appointment?.appointment?.room_id ? "Video" : "Audio"}{" "}
                      Appointment
                    </p>
                  </div>
                </div>
                <div className="col-md-3 align-middle">
                  <div className="clearfix pt-4">
                    {appointment?.appointment ? (
                      <AddToCalendarButton
                        name={appointment?.appointment.title}
                        description={appointment?.appointment.details}
                        hideBranding={true}
                        options={[
                          "Google",
                          "Yahoo",
                          "Apple",
                          "Microsoft365",
                          "MicrosoftTeams",
                          "Outlook.com",
                          "iCal",
                        ]}
                        location="World Wide Web"
                        startDate={convertTimeIntoSselectedTZ(
                          appointment?.appointment?.timezone,
                          moment.tz.guess(),
                          moment.utc(appointment?.appointment.start_date),
                          "YYYY-MM-DD"
                        )}
                        endDate={convertTimeIntoSselectedTZ(
                          appointment?.appointment?.timezone,
                          moment.tz.guess(),
                          moment.utc(appointment?.appointment.end_date),
                          "YYYY-MM-DD"
                        )}
                        startTime={convertTimeIntoSselectedTZ(
                          appointment?.appointment?.timezone,
                          moment.tz.guess(),
                          moment.utc(appointment?.appointment.start_date),
                          "HH:mm"
                        )}
                        endTime={convertTimeIntoSselectedTZ(
                          appointment?.appointment?.timezone,
                          moment.tz.guess(),
                          moment.utc(appointment?.appointment.end_date),
                          "HH:mm"
                        )}
                        timeZone={moment.tz.guess() || "America/New_York"}
                      ></AddToCalendarButton>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
            <div className="text-center mt-3">
              <p className="text-dark text-opacity-75">
                Don't forget to check your email for further instructions and to
                access your account.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
