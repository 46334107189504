import { GLOBAL_ACTYPE_TYPE } from "./Type"

const Loader=(boolean)=>{
    return async(dispatch)=>{
        dispatch({
            type:GLOBAL_ACTYPE_TYPE.LOADER,
            status:boolean
        })
    }
}

export const global={
    Loader
}