import { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BsThreeDotsVertical } from 'react-icons/bs';
import ReactSelect from 'react-select';
import DatePicker from 'react-datepicker';
import SweetAlert2 from 'react-sweetalert2';
import { IoIosLock } from "react-icons/io";
import {
	getLeadStatus,
	setQueryParams,
	getQueryParam,
	removeQueryParam,
	getLeadStatusClass,
	getUserVerifyStatus,
	getUserVerifyStatusClass,
} from '../../../utilites';
import allActions from '../../../Store/action';
import ListPageWrapper from '../components/ListPageWrapper';
import Modal from '../../../components/Dashboard/Modal';
import Input from '../../../components/forms/fields/Input';
import AddLeadForm from '../../../components/forms/AddLeadForm';
import AddLeadsForm from '../../../components/forms/AddLeadsForm';
import Button from '../../../components/forms/fields/Button';
import ReAssignTaxProForm from '../../../components/forms/ReAssignTaxProForm';
import ImpersonateUserForm from '../../../components/forms/ImpersonateUserForm';
import UserAvatar from '../../../components/UserAvatar';
import { CALL_ACTION_TYPE } from '../../../Store/call/Type';
// import CreateJobForm from '../../../components/forms/CreateJobForm';

const filteringOptions = [
	{ id: 0, label: 'All time', value: 'all-time' },
	{ id: 1, label: 'Last 24 hours', value: 'last-24-hours' },
	{ id: 2, label: 'Last 7 days', value: 'last-7-days' },
	{ id: 3, label: 'Last 30 days', value: 'last-30-days' },
];

const LeadAnalitycs = () => {
	const [{ loginStatus, LoginData, isAdmin }, { services, professionals }] = useSelector(
		(state) => [state.user, state.dashboard]
	);
	const [dashboard] = useSelector((state) => [state.dashboard]);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [selectedLead, setSelectedLead] = useState(null);
	const [alertMessage, setAlertMessage] = useState('');
	const [verificationMessage,setVerificationMessage] = useState({
		show: false,
		type: '',
		text: '',
	})

	const [swalProps, setSwalProps] = useState({});

	// Pagination
	const [perPage, setPerPage] = useState(getQueryParam('per_page') || 10);
	const [page, setPage] = useState(getQueryParam('page') || 1);

	// Filterings
	const [dateRange, setDateRange] = useState({
		startDate: getQueryParam('from') ? new Date(getQueryParam('from')) : null,
		endDate: getQueryParam('to') ? new Date(getQueryParam('to')) : null,
	});
	const [period, setPeriod] = useState(getQueryParam('period') || filteringOptions[0].value);
	// Search
	const [keyword, setKeyword] = useState(getQueryParam('search') || '');
	const [service, setService] = useState(getQueryParam('service') || '');
	const [pro, setPro] = useState(
		getQueryParam('taxpro') ? { value: getQueryParam('taxpro'), label: '' } : null
	);
	const [assignedDate, setAssignedDate] = useState(
		getQueryParam('assigned_after') ? new Date(getQueryParam('assigned_after')) : null
	);

	useEffect(() => {
		if (LoginData.isAdmin || LoginData.isAccountManager) {
			dispatch(allActions.dashboard.getTaxProfessionals());
		}
	}, [dispatch, LoginData.isAdmin, LoginData.isAccountManager]);

	useEffect(() => {
		if (dateRange && dateRange.startDate && dateRange.endDate && period) {
			setPeriod(null);
		}
	}, [dateRange, period]);

	useEffect(() => {
		if(verificationMessage.show){
			setAlertMessage('If an account is registered with us, an email will be sent with password reset instructions.')
		}
	},[verificationMessage])

	useEffect(() => {
		if (loginStatus) {
			dispatch(
				allActions.dashboard.getLeads(
					keyword,
					assignedDate,
					pro?.value,
					service,
					period,
					dateRange,
					page,
					perPage
				)
			);
		}
	}, [
		loginStatus,
		dispatch,
		period,
		dateRange,
		page,
		perPage,
		keyword,
		assignedDate,
		pro,
		service,
	]);

	const loadLeads = () => {
		dispatch(
			allActions.dashboard.getLeads(
				keyword,
				assignedDate,
				pro?.value,
				service,
				period,
				dateRange,
				page,
				perPage
			)
		);
	};

	const deleteLead = (id) => {
		dispatch(allActions.dashboard.deleteLead(id, setAlertMessage, loadLeads));
	};

	const handleDeleteClick = (id) => {
		setSwalProps((prev) => ({
			...prev,
			[id]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				text: 'You will not be able to recover this lead!',
				confirmButtonText: 'Confirm',
				denyButtonText: 'Cancel',
				didClose: () => setSwalProps((prev) => ({ ...prev, [id]: { show: false } })),
				onConfirm: () => deleteLead(id),
			},
		}));
	};

	const handleExportExcelClick = () => {
		setSwalProps((prev) => ({
			...prev,
			exportExcel: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				text: 'With this action you will export all the leads in an excel file!',
				confirmButtonText: 'Confirm',
				denyButtonText: 'Cancel',
				didClose: () => setSwalProps((prev) => ({ ...prev, exportExcel: { show: false } })),
				onConfirm: () => {
					dispatch(
						allActions.dashboard.exportLeadsData(
							keyword,
							assignedDate,
							pro?.value,
							service,
							period,
							dateRange
						)
					);
				},
			},
		}));
	};

	const showAlertOnTagClick = (tag_slug, tag_description) => {
		setSwalProps((prev) => ({
			...prev,
			[tag_slug]: {
				show: true,
				showDenyButton: false,
				text: tag_description || '',
				didClose: () => setSwalProps((prev) => ({ ...prev, [tag_slug]: { show: false } })),
			},
		}));
	};

	const moveTo = (page) => {
		setPage(page);
		// Set query param
		setQueryParams('page', page);
	};

	const changePerPage = (perPage) => {
		setPerPage(perPage);
		// Set query param
		setQueryParams('per_page', perPage);
	};

	const handleDateRangeChange = (dates) => {
		const [start, end] = dates;
		setDateRange({
			startDate: start,
			endDate: end,
		});
		// Set query param
		if (!start) {
			removeQueryParam('from');
		} else {
			setQueryParams('from', start);
		}
		if (!end) {
			removeQueryParam('to');
		} else {
			setQueryParams('to', end);
		}
		if (start && end) {
			handlePeriodChange(null);
		}
	};

	const handlePeriodChange = (val) => {
		setPeriod(val);
		// Set query param
		if (!val) {
			removeQueryParam('period');
		} else {
			handleDateRangeChange([null, null]);
			setQueryParams('period', val);
		}
	};

	const filterByAssignationDate = (date) => {
		setAssignedDate(date);
		// Set query param
		if (!date) {
			removeQueryParam('assigned_after');
		} else {
			setQueryParams('assigned_after', date);
		}
	};

	const filterByTaxpro = (pro) => {
		setPro(pro);
		// Set query param
		if (!pro) {
			removeQueryParam('taxpro');
		} else {
			setQueryParams('taxpro', pro?.value);
		}
	};

	const filterByService = (serv) => {
		setService(serv);
		// Set query param
		if (!serv) {
			removeQueryParam('service');
		} else {
			setQueryParams('service', serv);
		}
	};

	const filterByKeyword = (keyword) => {
		setKeyword(keyword);
		// Set query param
		if (!keyword) {
			removeQueryParam('search');
		} else {
			setQueryParams('search', keyword);
		}
	};

	const clearFilters = () => {
		// Clear state
		setKeyword('');
		setAssignedDate(null);
		setPro(null);
		setService('');
		setPeriod(null);
		setDateRange({ startDate: null, endDate: null });
		setPage(1);
		setPerPage(10);
		// Clear query params
		removeQueryParam('search');
		removeQueryParam('assigned_after');
		removeQueryParam('taxpro');
		removeQueryParam('service');
		removeQueryParam('period');
		removeQueryParam('from');
		removeQueryParam('to');
		removeQueryParam('page');
		removeQueryParam('per_page');
	};

	const resetMessages = () => {
		if (!window.location.href.includes('dashboard/messages')) {
			dispatch(allActions.firebase.resetPersonalConnection());
			dispatch(allActions.firebase.resetReadPersonalChatsArray());
		}
		// Reset Anointment Response
		dispatch(allActions.call.dispatchToStore({ type: CALL_ACTION_TYPE.audioCallTokenIdle }));
		dispatch(allActions.call.dispatchToStore({ type: CALL_ACTION_TYPE.videoCallRoomIdle }));
	};

	const handleSendVerificationEmail = (id,lead) => {
		setSwalProps((prev) => ({
			...prev,
			[`ud-${id}`]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				confirmButtonText: 'Confirm',
				denyButtonText: 'Cancel',
				text: 'Do you want to send verification email to this user?',
				didClose: () =>
					setSwalProps((prev) => ({
						...prev,
						[`ud-${id}`]: { show: false },
					})),
				onConfirm: () => dispatch(allActions.user.ResendLink({ email: lead.user.email },setVerificationMessage )),
			},
		}));
	}

	return (
		<ListPageWrapper
			heading="Leads"
			headerControls={
				LoginData.isAdmin || LoginData.isAccountManager ? (
					<Fragment>
						<div className="col-md-8">
							<DatePicker
								className="form-select"
								wrapperClassName="me-3"
								selected={dateRange.startDate || null}
								startDate={dateRange.startDate}
								endDate={dateRange.endDate}
								onChange={(date) => {
									handleDateRangeChange(date);
									if (page !== 1) moveTo(1);
								}}
								maxDate={new Date()}
								dateFormat="MMMM d, yyyy"
								placeholderText="Date Range"
								selectsRange
								isClearable
							/>
							<div className="btn-group" role="group" aria-label="Basic radio toggle button group">
								{filteringOptions?.map((option) => (
									<Fragment key={option.id}>
										<input
											type="radio"
											className="btn-check"
											name="btnradio"
											id={`opt-${option.id}`}
											autoComplete="off"
											checked={period === option.value}
											onChange={() => {
												handlePeriodChange(option.value);
												if (page !== 1) moveTo(1);
											}}
										/>
										<label className="btn btn-outline-primary" htmlFor={`opt-${option.id}`}>
											{option.label}
										</label>
									</Fragment>
								))}
							</div>
						</div>
						<div className="col-md-2">
							{LoginData?.userpro?.status_id === 6 ? <button
								className="btn btn-outline-primary px-4 float-md-end float-none d-flex align-tems-center gap-1"
								disabled={true}
							>
								Export Excel  <IoIosLock style={{ fontSize: '20px' }} />
							</button> : <button className="w-100 btn btn-outline-primary" onClick={handleExportExcelClick}>
								Export Excel
							</button>}
							<SweetAlert2 {...swalProps.exportExcel} />
						</div>
					</Fragment>
				) : null
			}
			underHeader={
				<Fragment>
					{dashboard.leads.statistics && (LoginData.isAdmin || LoginData.isAccountManager) ? (
						<Fragment>
							<div className="col-md-3">
								<div className="card border-light text-center">
									<div className="card-body">
										<h5 className="card-title">Leads 24 hours</h5>
										<p className="card-text fw-bold fs-3">
											{dashboard.leads.statistics.last24hours}
										</p>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="card border-light text-center">
									<div className="card-body">
										<h5 className="card-title">Leads last 7 days</h5>
										<p className="card-text fw-bold fs-3">{dashboard.leads.statistics.last7days}</p>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="card border-light text-center">
									<div className="card-body">
										<h5 className="card-title">Leads last 30 days</h5>
										<p className="card-text fw-bold fs-3">
											{dashboard.leads.statistics.last30days}
										</p>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="card border-light text-center">
									<div className="card-body">
										<h5 className="card-title">Leads All time</h5>
										<p className="card-text fw-bold fs-3">{dashboard.leads.statistics.alltime}</p>
									</div>
								</div>
							</div>
						</Fragment>
					) : null}
					<div className="row g-3">
						<div className="col-md-3">
							<Input
								type="search"
								value={keyword}
								name="name"
								placeholder="Search by lead name"
								fn={filterByKeyword}
								showLabel={false}
								inputClass="search-input border h-auto min-height-initial"
								extraSetter={() => {
									if (page !== 1) moveTo(1);
								}}
								required={false}
							/>
						</div>
						<div className="col-md-2">
							<div className="form-floating">
								<DatePicker
									className="form-select"
									wrapperClassName="d-block"
									selected={assignedDate}
									onChange={(date) => {
										filterByAssignationDate(date);
										if (page !== 1) moveTo(1);
									}}
									maxDate={new Date()}
									dateFormat="MMMM d, yyyy"
									placeholderText="Date Assigned"
									isClearable
								/>
							</div>
						</div>
						{(LoginData.isAdmin || LoginData.isAccountManager) &&
							professionals &&
							professionals.length > 0 && (
								<div className="col-md-2">
									<ReactSelect
										className="p-0 basic-single form-select"
										options={professionals?.map((professional) => ({
											value: professional.userpro.id,
											label: `${professional.firstname} ${professional.lastname}`,
										}))}
										onChange={(e) => {
											filterByTaxpro(e);
											if (page !== 1) moveTo(1);
										}}
										value={pro}
										placeholder="Tax Pro"
										closeMenuOnSelect={true}
										isClearable={true}
									/>
								</div>
							)}
						<div className="col-md-2">
							<select
								className="form-select"
								id="filter"
								name="services"
								onChange={(e) => {
									filterByService(e.target.value);
									if (page !== 1) moveTo(1);
								}}
								value={service}
							>
								<option value="">Service</option>
								{services?.map((service) => (
									<option key={service.id} value={service.id}>
										{service.name}
									</option>
								))}
							</select>
						</div>
						<div
							className={`col-md-${LoginData.isAdmin || LoginData.isAccountManager ? '3' : '5'}`}
						>
							{LoginData?.userpro?.status_id === 6 ? <button
									className="btn btn-outline-primary px-4 float-md-end float-none d-flex align-tems-center gap-1"
									disabled={true}
								>
									Add lead  <IoIosLock style={{ fontSize: '20px' }} />
								</button> : <Link
								to="#"
								className="btn btn-outline-primary px-4 float-md-end float-none"
								data-bs-toggle="modal"
								data-bs-target="#addLeadModal"
							>
								Add lead
							</Link> }
							
						</div>
					</div>
				</Fragment>
			}
			alert={{ message: alertMessage, setMessage: setAlertMessage }}
			dataState={{
				loaded: dashboard.leads.loaded,
				fetching: dashboard.leads.fetching,
			}}
			tableHeader={
				<Fragment>
					<th scope="col">ID</th>
					<th scope="col">Customer</th>
					<th scope="col">Interest</th>
					{(isAdmin || LoginData.isAccountManager) && <th scope="col">Assigned To</th>}
					<th scope="col">Assigned On</th>
					{!LoginData.isTaxPro && <th scope="col">Verified</th>}
					<th scope="col">Status</th>
					<th scope="col"></th>
				</Fragment>
			}
			tableBody={
				<Fragment>
					{dashboard.leads.data.length === 0 ? (
						<tr>
							<td className="text-center opacity-50" colSpan={6}>
								There are no leads available at this time
							</td>
						</tr>
					) : (
						dashboard.leads.data?.map((lead) => {
							return (
								<tr className="align-middle" key={lead.id}>
									<th scope="row">{lead.id}</th>
									<td className="text-md-start text-center">
										<Link
											to={`/dashboard/leads/${lead.uuid}`}
											className="text-decoration-none"
											onClick={() => resetMessages()}
										>
											<UserAvatar
												avatar={lead?.user.avatar}
												alt={`${lead?.user.firstname} ${lead?.user.lastname}`}
												className="rounded-circle"
												style={{ width: 35, height: 35 }}
											/>
											<span className="text-dark ps-1">
												{`${lead?.user.firstname} ${lead?.user.lastname}`}
											</span>
										</Link>
										{/* TODO: Improve the code below */}
										{lead?.tags && lead?.tags.length !== 0 ? (
											<span
												className="badge bg-primary ms-1 cursor-pointer"
												onClick={() => {
													showAlertOnTagClick(lead?.tags[0].slug, lead?.tags[0].description);
												}}
											>
												{lead?.tags[0].name}
												<SweetAlert2 {...swalProps[lead?.tags[0].slug]} />
											</span>
										) : null}
									</td>
									<td width="38%">
										<div className="w-75">
											{lead.services
												?.map((service) => {
													let result = [];
													result.push(service.name);
													return result;
												})
												.reduce((prev, curr) => [prev, ', ', curr])}
										</div>
									</td>
									{(isAdmin || LoginData.isAccountManager) && lead.userpro?.user && (
										<td>
											<Link
												className="text-decoration-none"
												to="#"
												data-bs-toggle="modal"
												data-bs-target="#reAssignTaxProModal"
												onClick={() => setSelectedLead(lead)}
											>
												{`${lead.userpro.user?.firstname} ${lead.userpro.user?.lastname}`}
											</Link>
										</td>
									)}
									<td>
										{new Date(lead.created_at).toLocaleDateString('en-US', {
											year: 'numeric',
											month: 'short',
											day: 'numeric',
										})}
									</td>
									{!LoginData.isTaxPro && (
										<td className="text-center">
											<span
												className={`badge bg-${getUserVerifyStatusClass(
													lead.user.isVerify
												)} rounded-pill cursor-pointer`}
											>
												{getUserVerifyStatus(lead.user.isVerify)}
											</span>
										</td>
									)}
									<td>
										<span className={`text-${getLeadStatusClass(lead.status)}`}>
											{getLeadStatus(lead.status)}
										</span>
									</td>
									{LoginData?.isTaxPro ? (
										<td>
											<div className="clearfix">
												<Button
													mainClass={`btn btn-sm btn-outline-primary ${
														lead.status === 3 && 'disabled'
													}`}
													wrapperClass="clearfix d-grid text-center "
													buttonText="Send Message"
													callback={() =>
														navigate('/dashboard/messages/', {
															state: { uuid: lead?.user?.uuid },
														})
													}
												/>
											</div>
										</td>
									) : (
										<td className="text-end">
											<div className="dropdown-center">
												<button
													type="button"
													className="btn btn-sm btn-outline-primary rounded-circle"
													data-bs-toggle="dropdown"
													aria-expanded="false"
												>
													<BsThreeDotsVertical className="" />
												</button>
												<ul className="dropdown-menu border-light">
													<li>
														<Link className="dropdown-item" to={`/dashboard/leads/${lead.uuid}`}>
															Details
														</Link>
													</li>
													<li>
														<Link className="dropdown-item" to={`/dashboard/jobs/create/${lead.uuid}`}>
															Create Job
														</Link>
													</li>
													{LoginData.isAccountManager && (
														<li>
															<Link
																className={`dropdown-item ${
																	!LoginData.impersonation ? 'primary' : 'grey disabled'
																}`}
																data-bs-toggle="modal"
																data-bs-target="#impersonateUser"
																onClick={() => setSelectedLead(lead)}
															>
																Login As
															</Link>
														</li>
													)}
												<Link
													to="#"
													className={`dropdown-item text-${LoginData.isAdmin || LoginData.isAccountManager 
															? 'black'
															: 'grey disabled'
														}`}
													onClick={() => {
														handleSendVerificationEmail(lead.id,lead);
													}}
												>
													Verification
													<SweetAlert2 {...swalProps[`ud-${lead.id}`]} />
												</Link>
													<li>
														<hr className="dropdown-divider border-light" />
													</li>
													<li>
														<Fragment>
															<Link
																to="#"
																className="dropdown-item text-danger"
																onClick={() => handleDeleteClick(lead.id)}
															>
																Delete
															</Link>
															<SweetAlert2 {...swalProps[lead.id]} />
														</Fragment>
													</li>
												</ul>
											</div>
										</td>
									)}
								</tr>
							);
						})
					)}
				</Fragment>
			}
			paginationProps={{
				pageCount: Math.ceil(dashboard?.leads.count / perPage),
				currentPage: parseInt(page),
				setCurrentPage: moveTo,
				perPage: perPage,
				setPerPage: (pc) => {
					changePerPage(pc);
					// Move to first page
					if (page !== 1) moveTo(1);
				},
			}}
			modals={
				<Fragment>
					{isAdmin || LoginData.isAccountManager ? (
						<Modal
							title="Re-assign Tax Pro"
							bodyClass="px-5 py-4 text-center"
							trigger="reAssignTaxProModal"
							showBtn={false}
							btnClass="reAssignTaxProModalClose"
							// size='sm'
						>
							{selectedLead && professionals && professionals.length > 0 ? (
								<ReAssignTaxProForm
									data={professionals}
									lead={selectedLead}
									reload={loadLeads}
									setAlert={setAlertMessage}
								/>
							) : null}
						</Modal>
					) : null}
					<Modal
						title="Add New Lead"
						bodyClass="px-5 py-4 text-center"
						trigger="addLeadModal"
						showBtn={false}
						btnClass="addLeadModalClose"
						size="lg"
					>
						<nav>
							<div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
								<button
									className="nav-link active"
									id="nav-single-tab"
									data-bs-toggle="tab"
									data-bs-target="#nav-single"
									type="button"
									role="tab"
									aria-controls="nav-single"
									aria-selected="true"
								>
									Upload Single Lead
								</button>
								<button
									className="nav-link"
									id="nav-multiple-tab"
									data-bs-toggle="tab"
									data-bs-target="#nav-multiple"
									type="button"
									role="tab"
									aria-controls="nav-multiple"
									aria-selected="false"
								>
									Upload Multiple Jobs
								</button>
							</div>
						</nav>
						<div className="tab-content mt-3" id="nav-tabContent">
							<div
								className="tab-pane show active"
								id="nav-single"
								role="tabpanel"
								aria-labelledby="nav-single-tab"
								tabIndex="0"
							>
								<AddLeadForm
									professionals={professionals}
									services={services}
									userRoles={{
										isAdmin: LoginData.isAdmin,
										isAccountManager: LoginData.isAccountManager,
										isTaxPro: LoginData.isTaxPro,
									}}
									proId={LoginData?.userpro?.id}
									reloadLeads={loadLeads}
									setAlert={setAlertMessage}
								/>
							</div>
							<div
								className="tab-pane"
								id="nav-multiple"
								role="tabpanel"
								aria-labelledby="nav-multiple-tab"
								tabIndex="0"
							>
								<AddLeadsForm
									professionals={professionals}
									userRoles={{
										isAdmin: LoginData.isAdmin,
										isAccountManager: LoginData.isAccountManager,
										isTaxPro: LoginData.isTaxPro,
									}}
									proId={LoginData?.userpro?.id}
									reloadLeads={loadLeads}
									setAlert={setAlertMessage}
								/>
							</div>
						</div>
					</Modal>
					<Modal
						title="Impersonate User"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="impersonateUser"
						showBtn={false}
						btnClass="impersonateUserModalClose"
					>
						{selectedLead && (
							<ImpersonateUserForm
								userId={selectedLead?.user?.id}
								cleanup={clearFilters}
								isLeadsPage
							/>
						)}
					</Modal>
				</Fragment>
			}
		/>
	);
};

export default LeadAnalitycs;
