import { Navigate, useLocation } from "react-router-dom";
import { IsLogin } from "../../utilites/functions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import allActions from "../../Store/action";
import DashboardLayout from "../../layouts/DashboardLayout";
import Otp from "../../pages/auth/Otp";
import Preloader from "../../pages/dashboard/components/Preloader";
import io from "socket.io-client";
import { BASEURL } from "../../utilites";
const ProtectedRoute = ({ Component, path, login, Dashboard }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [user] = useSelector((state) => [state.user]);

  // hook used for establish socket server connection //
  useEffect(() => {
    const socket = io(BASEURL, { transports: ["websocket"] });
    // Event listeners for various socket events
    socket.on("connect", () => {
      console.log("Connected to server");
      if (user && user.LoginData) {
        socket.emit("clientData", user.LoginData.uuid);
      }
      socket.on("disconnect", () => {
        console.log("Disconnected from server");
      });
      
    });
    // emit event for user typing //
    // socket.emit('IsTyping','data')
    // Other event listeners and logic
    return () => {
      socket.disconnect();
    };
  }, [user]);
  

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      user.loginStatus === false &&
      user.error === ""
    ) {
      dispatch(allActions.user.ReGetUserData());
    }
  }, [user, dispatch]);
  // console.log('nnnnnnnnnn', user);
  if ((IsLogin() && user.error) || user.loginStatus) {
  }

  if (location.pathname.startsWith('/appointment-details')) {
    return <Component />;
  }

  if (location.pathname.startsWith('/qr-code')) {
    return <Component />;
  }
  if (location.pathname.startsWith('/qr-upload')) {
    return <Component />;
  }

  return login ? (
    IsLogin() ? (
      //  || user?.LoginData?.authorization==1 && sessionStorage.getItem('number')
      user.error || user.loginStatus ? (
        user?.error === "session expired" ? (
          location.pathname === "/otp" ? (
            <Otp />
          ) : (
            <Navigate to={"/otp"} />
          )
        ) : location.pathname.startsWith("/dashboard") ? (
          <DashboardLayout />
        ) : (
          <Navigate to={"/dashboard/"} />
        )
      ) : (
        <>
          <Preloader />
        </>
      )
    ) : (
      <Navigate to={path} />
    )
  ) : !IsLogin() ? (
    <Component />
  )
  //  : user.isRegular ? (
  //   <Navigate to={"dashboard/messages"} />
  // ) 
  : (
    <Navigate to={path} />
  );
};
export default ProtectedRoute;
