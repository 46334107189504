function bytesToKilobytes(bytes) {
	bytes = parseInt(bytes);

	if (bytes) {
		return Math.floor(bytes / 1024);
	}

	return null;
}

export { bytesToKilobytes };
