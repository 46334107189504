import React from 'react';
import Header from '../components/Header';
import { BsCheckCircleFill } from 'react-icons/bs';
import SummaryCard from '../../../components/Dashboard/billing/summaryCard';

const Checkout = () => {
    // const [message, setMessage] = useState({ show: false });
    const renderSelectionCard = () => {
        return (<div className='card col-sm-12 col-md-8 col-lg-12 d-flex flex-row align-items-center justify-content-between' role='button'>
            <div className='d-flex flex-row align-items-center justify-content-start'>
                <div className='col-md-2 my-2 mx-4'>
                    <BsCheckCircleFill onClick={() => { }} size={20} color="#13056b" />
                </div>
                <div className='my-4 col-md-6'>
                    <div className='d-flex align-items-end'>
                        <p className='fw-bold m-0 p-0'>Enterprise</p>
                        <p className='small-text text-muted m-0 p-0'>&nbsp;/month</p>
                    </div>
                </div>
            </div>
            <div className='col-md-3 d-flex flex-column align-items-end justify-content-end mx-4'>
                <p className='text-muted m-0 p-0'>Total</p>
                <p className='m-0 p-0 fw-bold'>22,400.00</p>
            </div>
        </div>)
    }
    return (
        <div>
            <Header name='Subscription' />
            {/* {message.show ? <Message message={message} /> : <></>} */}
            <div className='w-100 row'>
                <div className='col-lg-6 col-md-8 col-sm-12'>
                    {renderSelectionCard()}
                    <button className='btn btn-primary my-5'>Proceed to checkout</button>
                </div>
                <div className='col-lg-6 col-md-8 col-sm-12'>
                   <SummaryCard />
                </div>
            </div>
        </div>
    )
}

export default Checkout