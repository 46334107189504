import { BASEURL } from "../../utilites";
import axios from "axios";
import { ADMIN } from "./type";

//  Function To CREATE VIDEO ROOM
const keapVerification = (code) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/keap/${code}`,)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: ADMIN.keapVerificarionSuccess,
					payload: {
						message: ADMIN.keapVerificarionSuccess,
						status: true,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: ADMIN.keapVerificarionError,
					payload: {
						message: error?.response?.data?.error,
						status: false,
						data: {},
					},
				});
			});
	};
};


export const Admin = {
	keapVerification,
};
