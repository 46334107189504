import { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { pdfjs } from 'react-pdf';
import SweetAlert2 from 'react-sweetalert2';
import DatePicker from 'react-datepicker';
import allActions from '../../../Store/action';
import { setQueryParams, getQueryParam, removeQueryParam, FileTypeIcon } from '../../../utilites';
import ListPageWrapper from '../components/ListPageWrapper';
import AddDocumentForm from '../../../components/forms/AddDocumentForm';
import EditDocumentForm from '../../../components/forms/EditDocumentForm';
import AddFolderForm from '../../../components/forms/AddFolderForm';
import Input from '../../../components/forms/fields/Input';
import RenderMultiPagePDF from '../components/RenderMultiPagePDF';
import Modal from '../../../components/Dashboard/Modal';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { IoIosLock } from "react-icons/io";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

// const documentFolders = [
// 	{ id: 1, name: 'All documents' },
// 	{ id: 2, name: 'Tax year 2023' },
// 	{ id: 3, name: 'Tax year 2023' },
// 	{ id: 4, name: 'My documents' },
// ];

const DocumentList = () => {
	const [searchParams] = useSearchParams();
	const [{ LoginData, isRegular }] = useSelector((state) => [state.user]);
	const [{ folders, documents }] = useSelector((state) => [state.dashboard]);

	const [selectedDocument, setSelectedDocument] = useState();
	const [alertMessage, setAlertMessage] = useState('');

	// Filtering
	const [keyword, setKeyword] = useState(getQueryParam('search') || '');
	const [dateAdded, setDateAdded] = useState(getQueryParam('added_after') || null);
	const [type] = useState(getQueryParam('type') || '');
	//const [owner, setOwner] = useState(getQueryParam('owner') || '');
	// const [status, setStatus] = useState(getQueryParam('status') || '');
	const [folder, setFolder] = useState(getQueryParam('folder') || '');

	const [swalProps, setSwalProps] = useState({});

	const [page, setPage] = useState(getQueryParam('page') || 1);
	const perPage = 12;

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(allActions.dashboard.getFolders(filterByFolder));
	}, [dispatch]);

	useEffect(() => {
		dispatch(
			allActions.dashboard.getDocuments(
				type,
				page,
				perPage,
				keyword,
				dateAdded,
				null, // owner,
				null, // Status
				folder
			)
		);
	}, [
		dispatch,
		page,
		perPage,
		keyword,
		isRegular,
		searchParams,
		dateAdded,
		//owner,
		// status,
		type,
		folder,
	]);

	const loadDocuments = () => {
		dispatch(
			allActions.dashboard.getDocuments(
				type,
				page,
				perPage,
				keyword,
				dateAdded,
				null, //owner,
				null, // Status
				folder
			)
		);
		dispatch(allActions.dashboard.getFolders());
		dispatch(allActions.dashboard.getCustomFolders());
	};

	const deleteDocument = (uuid) => {
		dispatch(allActions.dashboard.deleteDocument(uuid, null, loadDocuments, setAlertMessage));
	};

	const handleDeleteDocumentClick = (uuid) => {
		setSwalProps((prev) => ({
			...prev,
			[uuid]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				text: 'You will not be able to recover this document!',
				confirmButtonText: 'Yes, delete it!',
				denyButtonText: 'Cancel',
				didClose: () => setSwalProps((prev) => ({ ...prev, [uuid]: { show: false } })),
				onConfirm: () => deleteDocument(uuid),
			},
		}));
	};

	const moveTo = (page) => {
		setPage(page);
		// Set query param
		setQueryParams('page', page);
	};

	const filterByKeyword = (keyword) => {
		setKeyword(keyword);
		// Set query param
		if (!keyword) {
			removeQueryParam('search');
		} else {
			setQueryParams('search', keyword);
		}
	};

	const filterByAdditionDate = (date) => {
		setDateAdded(date);
		// Set query param
		if (!date) {
			removeQueryParam('added_after');
		} else {
			setQueryParams('added_after', date);
		}
	};

	/*const filterByType = (type) => {
		setType(type);
		// Set query param
		if (!type) {
			removeQueryParam('type');
		} else {
			setQueryParams('type', type);
		}
	};*/

	/*const filterByOwner = (owner) => {
		setOwner(owner);
		// Set query param
		if (!owner) {
			removeQueryParam('owner');
		} else {
			setQueryParams('owner', owner);
		}
	};*/

	// const filterByStatus = (status) => {
	// 	setStatus(status);
	// 	// Set query param
	// 	if (!status) {
	// 		removeQueryParam('status');
	// 	} else {
	// 		setQueryParams('status', status);
	// 	}
	// };

	const filterByFolder = (folder) => {
		setFolder(folder);
		// Set query param
		if (!folder) {
			removeQueryParam('folder');
		} else {
			setQueryParams('folder', folder);
		}
	};

	return (
		<ListPageWrapper
			heading="Documents"
			headerControls={
				<div className="row g-3">
					<div className="col-md-3">
						<Input
							type="search"
							value={keyword}
							name="name"
							placeholder="Search by document's name"
							fn={filterByKeyword}
							showLabel={false}
							inputClass="search-input border h-auto min-height-initial"
							extraSetter={() => {
								if (page !== 1) moveTo(1);
							}}
							required={false}
						/>
					</div>
					<div className="col-md-2">
						<div className="form-floating">
							<DatePicker
								className="form-select"
								wrapperClassName="d-block"
								selected={dateAdded}
								onChange={(date) => {
									filterByAdditionDate(date);
									if (page !== 1) moveTo(1);
								}}
								maxDate={new Date()}
								dateFormat="MMMM d, yyyy"
								placeholderText="Date added"
								isClearable
							/>
						</div>
					</div>
					<div className="col-md-2">
						{/*<select
							className='form-select'
							id='filterByType'
							name='type'
							onChange={(e) => {
								filterByType(e.target.value);
								if (page !== 1) moveTo(1);
							}}
							value={type}
						>
							<option value=''>Type</option>
							<option value='1'>Tax document</option>
							{/* {LoginData.isAdmin ? <option value='6'>Default avatars</option> : null}
							<option value='7'>User avatars</option>
						</select>*/}
					</div>
					<div className="col-md-2">
						{/*<select
							className='form-select'
							id='filterByOwner'
							name='owner'
							onChange={(e) => {
								filterByOwner(e.target.value);
								if (page !== 1) moveTo(1);
							}}
							value={owner}
						>
							<option value=''>Owner</option>
						</select>*/}
					</div>
					{/* <div className='col-md-2'>
						<select
							className='form-select'
							id='filterByStatus'
							name='status'
							onChange={(e) => {
								filterByStatus(e.target.value);
								if (page !== 1) moveTo(1);
							}}
							value={status}
						>
							<option value=''>Status</option>
						</select>
					</div> */}

					{LoginData?.userpro?.status_id === 6 ? <div className="col-md-3"> <button
						className="btn btn-outline-primary px-4 float-md-end float-none d-flex align-tems-center gap-1"
						disabled={true}
					>
						Add document  <IoIosLock style={{ fontSize: '20px' }} />
					</button> </div> : <div className="col-md-3">
						<Link
							to="#"
							className="btn btn-outline-primary px-4 float-md-end float-none"
							data-bs-toggle="modal"
							data-bs-target="#addDocumentModal"
						>
							Add document
						</Link>
					</div>}
				</div>
			}
			alert={{ message: alertMessage, setMessage: setAlertMessage }}
			dataState={{
				loaded: documents.loaded,
				fetching: documents.fetching,
			}}
			aside={
				<nav
					className="nav flex-column nav-pills sticky-top"
					id="v-pills-tab"
					style={{ zIndex: 1, top: 100 }}
				>
					{folders &&
						folders.length > 0 &&
						folders?.map((f) => (
							<button
								key={f.id}
								className={`text-start nav-link mx-1${
									getQueryParam('folder') === f.id.toString()
										? ' active'
										: getQueryParam('folder') === null && f.id === 1
										? ' active'
										: ''
								}`}
								onClick={() => {
									filterByFolder(f.id);
									if (page !== 1) moveTo(1);
								}}
							>
								{f.name}
							</button>
						))}
					{LoginData?.userpro?.status_id === 6 ? <button
						className="btn btn-link px-2 float-md-end float-none d-flex align-tems-center gap-1"
						disabled={true}
					>
						+ Add folder
						<IoIosLock style={{ fontSize: '20px' }} />
					</button> : <button
						className="btn btn-link text-decoration-none text-start mt-3"
						data-bs-toggle="modal"
						data-bs-target="#addFolderModal"
					>
						+ Add folder
					</button>}
				</nav>
			}
			content={
				documents.data.length === 0 ? (
					<div className="col-12">
						<p className="text-center text-dark text-opacity-50 p-5">No documents found.</p>
					</div>
				) : (
					<Fragment>
						{documents.data?.map((document) => (
							<div className="col-md-4" key={document.id} role="button" onClick={() => setSelectedDocument(document)}>
								<div className=" card overflow-hidden">
									{document.location.includes('.pdf') ? (
										// <Document file={document.path} className="card-img-top">
										// 	<Page pageNumber={1} height={260} />
										// </Document>
										<img
											className="card-img-top"
											data-bs-toggle="modal"
											data-bs-target="#openDocumentModal"
											aria-controls="openDocumentModal"
											data-bs-id={document.uuid}
											onClick={() => {
												setSelectedDocument(document);
											}}
											src={require('../../../assets/img/pdf.jpg')}
											// className="card-img-top"
											alt={document.name}
											style={{ height: 260, objectFit: 'cover' }}
										/>
									) : (
										<button
											className="dropdown-item"
											data-bs-toggle="modal"
											data-bs-target="#openDocumentModal"
											aria-controls="openDocumentModal"
										// data-bs-id={document.uuid}
										// onClick={() => setSelectedDocument(document)}
										>
											<img
												src={document.path}
												className="card-img-top"
												alt={document.name}
												style={{ height: 260, objectFit: 'cover' }}
											/>
										</button>
									
									)}
									<div className="card-body">
										<div className="d-flex justify-content-between">
											<div className="d-flex align-items-center">
												<FileTypeIcon extension={document?.extension} size={20} />
												<h6
													className="card-title m-0 ms-1 d-inline-block text-truncate"
													style={{ maxWidth: '80%' }}
												>
													{document.name}
												</h6>
											</div>
											<div className="d-flex align-items-center">
												<span className="badge bg-primary rounded-pill">badge</span>
												<div className="px-0 btn-group" style={{ width: 30 }}>
													<button
														type="button"
														className={`btn btn-sm text-primary border-0 rounded-circle${
															!document.path ? ' disabled' : ''
														}`}
														data-bs-toggle="dropdown"
														aria-expanded="false"
														style={{ width: 30, height: 30 }}
													>
														<BsThreeDotsVertical size={20} />
													</button>
													<ul className="dropdown-menu">
														<li>
															<button
																className="dropdown-item"
																data-bs-toggle="modal"
																data-bs-target="#openDocumentModal"
																aria-controls="openDocumentModal"
																// data-bs-id={document.uuid}
																// onClick={() => setSelectedDocument(document)}
															>
																View
															</button>
															{/* <SweetAlert2 {...swalProps[document.uuid]} /> */}
														</li>
														<li>
															<button
																className="dropdown-item"
																data-bs-toggle="modal"
																data-bs-target="#openEditDocumentModal"
																aria-controls="openEditDocumentModal"
																data-bs-id={document.uuid}
																onClick={() => setSelectedDocument(document)}
															>
																Edit
															</button>
														</li>
														<li>
															<button
																className="dropdown-item"
																onClick={() => handleDeleteDocumentClick(document.uuid)}
															>
																Delete
															</button>
															<SweetAlert2 {...swalProps[document.uuid]} />
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</Fragment>
				)
			}
			paginationProps={{
				pageCount: Math.ceil(documents.count / perPage),
				currentPage: parseInt(page),
				setCurrentPage: moveTo,
			}}
			modals={
				<Fragment>
					<Modal
						title="Add New Document"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="addDocumentModal"
						showBtn={false}
						btnClass="addDocumentModalClose"
					>
						<AddDocumentForm
							isModal={true}
							userId={LoginData.id}
							userFullname={`${LoginData.firstName} ${LoginData.lastName}`}
							setAlert={setAlertMessage}
							reloadDocuments={loadDocuments}
						/>
					</Modal>
					<Modal
						title="Document Viewer"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="openDocumentModal"
						showBtn={false}
						btnClass="documentModalClose"
						size="lg"
					>
						{selectedDocument &&
							(selectedDocument.location.includes('.pdf') ? (
								<>
									<RenderMultiPagePDF file={selectedDocument.path} />
								</>
							) : (
								<img
									src={selectedDocument.path}
									className="w-100 img-fluid rounded"
									alt={selectedDocument.name}
									style={{ objectFit: 'contain' }}
								/>
							))}
					</Modal>
					<Modal
						title="Edit document"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="openEditDocumentModal"
						showBtn={false}
						btnClass="editDocumentModalClose"
					>
						{selectedDocument && (
							<EditDocumentForm
								data={selectedDocument}
								setAlert={setAlertMessage}
								reloadDocuments={loadDocuments}
							/>
						)}
					</Modal>
					<Modal
						title="Add folder"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="addFolderModal"
						showBtn={false}
						btnClass="addFolderModalClose"
					>
						<AddFolderForm setAlert={setAlertMessage} reload={loadDocuments} />
					</Modal>
				</Fragment>
			}
		/>
	);
};

export default DocumentList;
