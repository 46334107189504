import React from "react";
import Switch from "react-switch";

function ToggleButton({ checked, setChecked }) {
	const handleChange = (newChecked) => {
		setChecked(newChecked);
	};
	return (
		<div className="example">
			<Switch width={45} height={20} onColor="#404AC4" onChange={handleChange} checked={checked} className="react-switch" />
		</div>
	);
}

export default ToggleButton;
