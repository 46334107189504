import { useDispatch } from 'react-redux';
import OtpForm from '../../components/forms/OtpForm';
import SEO from '../../components/SEO';
import allActions from '../../Store/action';
const Otp = () => {
	const dispatch = useDispatch();
	return (
		<div className='clearfix'>
			<SEO title='OTP' />
			<div className='page pt-5'>
				<div className='container'>
					<div className='clearfix text-center mb-2'>
						<div className='row justify-content-center'>
							<div className='col-md-4'>
								<div className='clearfix bg-white p-5'>
									<OtpForm />
									<button
										onClick={() => {
											dispatch(allActions.user.logout());
											dispatch(allActions.dashboard.reset());
											dispatch(allActions.firebase.reset());
										}}
										type='button'
										className='btn btn-link'
									>
										Logout
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Otp;
