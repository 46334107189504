import moment from 'moment';

function getTaxproStatus(statusId) {
	switch (parseInt(statusId)) {
		case 0:
			return 'Pending';
		case 1:
			return 'Under Review';
		case 2:
			return 'Activated';
		case 3:
			return 'Paused';
		case 4:
			return 'Suspended';
		case 5:
			return 'Denied';
		case 6:
			return 'Inactive';	
		default:
			return '';
	}
}

function getLeadStatus(statusId) {
	switch (parseInt(statusId)) {
		case 0:
			return 'Pending';
		case 1:
			return 'Active';
		case 2:
			return 'In Progress';
		case 3:
			return 'Reassigned';
		case 4:
			return 'Completed';
		default:
			return 'Pending';
	}
}

function getJobStatus(statusId) {
	switch (parseInt(statusId)) {
		case 0:
			return 'Pending';
		case 1:
			return 'Initiated';
		case 2:
			return 'In Progress';
		case 3:
			return 'Completed';
		case 4:
			return 'Canceled';
		case 5:
			return 'Draft';
		case 6:
			return 'Approved';
		default:
			return '';
	}
}

function getPayoutStatusName(status) {
	switch (status) {
		case 'pending':
			return 'Pending';
		case 'in_transit':
			return 'In Transit';
		case 'paid':
			return 'Paid';
		case 'failed':
			return 'Failed';
		default:
			return 'Pending';
	}
}

function moneyFormatter(value) {
	let amount = Number(value);
	return amount?.toLocaleString('en-US', {
		minimumFractionDigits: 2,
		style: 'currency',
		currency: 'USD',
	});
}

function getAppointmentStatus(statusId) {
	switch (parseInt(statusId)) {
		case 0:
			return 'Pending';
		case 1:
			return 'Confirmed';
		case 2:
			return 'Rejected';
		default:
			return 'Pending';
	}
}

function getUserVerifyStatus(statusId) {
	switch (parseInt(statusId)) {
		case 0:
			return 'No';
		case 1:
			return 'Yes';
		default:
			return 'No';
	}
}

function getDisputeStatus(statusId) {
	switch (parseInt(statusId)) {
		case 0:
			return 'Active';
		case 1:
			return 'Resolved';
		default:
			return 'Active';
	}
}

function timezoneFormatter(timezone) {
	switch (timezone) {
		case 'America/New_York':
			return 'Eastern Time';
		case 'America/Chicago':
			return 'Central Time';
		case 'America/Denver':
			return 'Mountain Time';
		case 'America/Los_Angeles':
			return 'Pacific Time';
		case 'America/Phoenix':
			return 'Arizona Time';
		case 'America/Anchorage':
			return 'Alaska Time';
		case 'Pacific/Honolulu':
			return 'Hawaii Time';
		case 'America/Adak':
			return 'Hawaii-Aleutian Time';
		case 'Pacific/Midway':
			return 'Samoa Time';
		case 'Pacific/Pago_Pago':
			return 'Samoa Time';
		case 'Pacific/Guam':
			return 'Chamorro Time';
		case 'Pacific/Saipan':
			return 'Chamorro Time';
		case 'Pacific/Tarawa':
			return 'Gilbert Time';
		case 'America/Boise':
			return 'Mountain Time (Boise)';
		case 'America/Indiana/Indianapolis':
			return 'Eastern Time (Indiana)';
		case 'America/Indiana/Knox':
			return 'Central Time (Indiana)';
		case 'America/Indiana/Marengo':
			return 'Eastern Time (Indiana)';
		case 'America/Indiana/Petersburg':
			return 'Eastern Time (Indiana)';
		case 'America/Indiana/Tell_City':
			return 'Central Time (Indiana)';
		case 'America/Indiana/Vevay':
			return 'Eastern Time (Indiana)';
		case 'America/Indiana/Vincennes':
			return 'Eastern Time (Indiana)';
		case 'America/Indiana/Winamac':
			return 'Eastern Time (Indiana)';
		case 'America/Kentucky/Louisville':
			return 'Eastern Time (Kentucky)';
		case 'America/Kentucky/Monticello':
			return 'Eastern Time (Kentucky)';
		case 'America/Menominee':
			return 'Central Time (Menominee)';
		case 'America/Nome':
			return 'Alaska Time (Nome)';
		case 'America/North_Dakota/Beulah':
			return 'Central Time (North Dakota)';
		case 'America/North_Dakota/Center':
			return 'Central Time (North Dakota)';
		case 'America/North_Dakota/New_Salem':
			return 'Central Time (North Dakota)';
		case 'Pacific/Fakaofo':
			return 'Tokelau Time';
		case 'America/Sitka':
			return 'Alaska Time (Sitka)';
		case 'America/Yakutat':
			return 'Alaska Time (Yakutat)';
		case 'America/Juneau':
			return 'Alaska Time (Juneau)';
		case 'America/Metlakatla':
			return 'Alaska Time (Metlakatla)';
		case 'America/Santa_Isabel':
			return 'Pacific Time (Santa Isabel)';
		case 'America/Tijuana':
			return 'Pacific Time (Tijuana)';
		case 'America/Detroit':
			return 'Eastern Time (Detroit)';
		default:
			return timezone;
	}
}

function timezoneShortFormatter(timezone) {
	switch (timezone) {
		case 'America/New_York':
			return 'EST';
		case 'America/Chicago':
			return 'CST';
		case 'America/Denver':
			return 'MST';
		case 'America/Los_Angeles':
			return 'PST';
		default:
			return timezone;
	}
}

function phoneNumberFormatter(phoneNumberString) {
	var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		var intlCode = match[1] ? '+1 ' : '';
		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}
	return null;
}

function twilioPhoneNumberFormatter(phoneNumberString) {
	var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		var intlCode = match[1] ? match[1] : '+1';
		return [intlCode, match[2], '', match[3], '', match[4]].join('');
	}
	return null;
}

function capitalizeFirstLetter(string) {
	return string ? string.charAt(0).toUpperCase() + string.slice(1) : '--';
}

function convertTime(timeZone, format) {
	return moment.tz(timeZone).format(format);
}
function convertTimeIntoSselectedTZ(timezoneAssign, targetTimeZone, date, format) {
	let currentTimeWithTZ = moment.tz(moment(date).format('YYYY-MM-DDTHH:mm:ss'), timezoneAssign);
	return moment(currentTimeWithTZ)
		.tz(targetTimeZone)
		.format(format ? format : 'YYYY-MM-DD HH:mm:ss');
}
export {
	getTaxproStatus,
	getLeadStatus,
	getJobStatus,
	getPayoutStatusName,
	moneyFormatter,
	getAppointmentStatus,
	getUserVerifyStatus,
	getDisputeStatus,
	timezoneFormatter,
	timezoneShortFormatter,
	phoneNumberFormatter,
	twilioPhoneNumberFormatter,
	capitalizeFirstLetter,
	convertTime,
	convertTimeIntoSselectedTZ,
};
