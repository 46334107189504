export const CALL_ACTION_TYPE = {
	// CALLING SUCCESS MESSAGES
	videoCallRoomFetchSucess: "Video Call Room Has Generated Successfully!",
	getZoomMeetingFetchSucess: "Meeting Data Get Successfully!",
	updateMeetingRoomSucess: "Meeting Room Update Successfully!",
	// CALLING OTHER VARIABLES
	videoCallTiming: {
		duration: 60,
	},

	// AUDIO CALL 
	audioCallToken: "audioCallToken",
	audioCallTokenIdle: "audioCallToken",

	// REDUCER TYPES
	videoCallRoom: "videoCallRoom",
	getZoomMeeting: "getZoomMeeting",
	updateMeetingRoom: "updateMeetingRoom",

	// RESETREDUCER TYPES
	videoCallRoomIdle: "videoCallRoomIdle",
	getZoomMeetingIdle: "getZoomMeetingIdle",
	updateMeetingRoomIdle: "updateMeetingRoomIdle"
};
