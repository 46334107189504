/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';

import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { HandleFormData } from '../../../../utilites';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';

const BusinessIncome = () => {
    const { businessIncome } = GLOBAL_VARIABLES

    const dispatch=useDispatch()
	const [{ taxPayerForm, taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 3 });
    // ---------------------------------------
    // Variables , States & Store States
    // ---------------------------------------
    //
    // const { otherQuestions } = GLOBAL_VARIABLES;

    // const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 0 });

    // const [{ taxPayerForm }] = useSelector((state) => [state.taxForms]);
    // form States

    // for Current component States
    const [showAnswers, setShowAnswers] = useState(null)
    const [businessExpensesDescriptionAmount, setBusinessExpensesDescriptionAmount] = useState([]);

    const [errors, setErrors] = useState({}); // Track errors for form fields


    // ---------------------------------------
    // HOOKS TO STORE DATA
    // ---------------------------------------

    // SAVE VALUES TO STATES

    useEffect(() => {
        setBusinessExpensesDescriptionAmount(JSON.parse(JSON.stringify(businessIncome)));
    }, []);

    useEffect(() => {
        if (taxPayerForm?.data?.BusinessIncome) {
            if (Object.keys(taxPayerForm?.data?.BusinessIncome).length === 0) return;
            const {
                showAnswers,
                businessExpensesDescriptionAmount
            } = taxPayerForm?.data?.BusinessIncome;

            setShowAnswers(showAnswers);
            setBusinessExpensesDescriptionAmount(businessExpensesDescriptionAmount)
        }
    }, [taxPayerForm?.data?.BusinessIncome]);



    const HandleToggle = (id, index) => {
        const myInputAmount = index
        setShowAnswers(index)
        let localDeduction = businessExpensesDescriptionAmount;
        localDeduction[id].bool = myInputAmount
        setBusinessExpensesDescriptionAmount(JSON.parse(JSON.stringify(localDeduction)));
    };
    const handleAmountChange = (id, index) => {
        const myInputAmount = document.getElementById(id);
        let localDeduction = businessExpensesDescriptionAmount;
        localDeduction[index].amount = myInputAmount.value;
        setBusinessExpensesDescriptionAmount(JSON.parse(JSON.stringify(localDeduction)));
    };

    const handleDiscrptionChange = (id, index) => {
        const myInputAmount = document.getElementById(id);
        let localDeduction = businessExpensesDescriptionAmount;
        localDeduction[index].description = myInputAmount.value;
        setBusinessExpensesDescriptionAmount(JSON.parse(JSON.stringify(localDeduction)));
    };

    const check_Validity = () => {
        setErrors({});
        const newErrors = {};
    
        // Add more validation rules as needed for other fields
        if (
            // businessExpensesDescriptionAmount
            //     ?.flatMap((data) => {
            //         return data?.bool;
            //     })
            //     .includes(null)
        showAnswers===null) {
            newErrors.AnswerOtherQuestions = 'Please Select Required Option';
        }
    
        setErrors(newErrors);
    
        let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
        // Check if there are any errors
        return ConditionCheck;
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = check_Validity();
        if (errors) return;

        let FormObj = {
            showAnswers,
            businessExpensesDescriptionAmount
        };

        let formData = taxPayerForm;
        taxPayerForm.data.BusinessIncome = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));

        uploadFormData(formData);
    };

    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 3;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
           
            <div className="mt-3 row justify-content-between px-3 custom-left-form">

                <div className="w-100 d-flex flex-row flex-wrap justify-content-between align-items-end mb-3 px-4 mt-3">
                    {businessExpensesDescriptionAmount?.map((data, i) => {
                        return (
                            <div
                                onClick={() => { }}
                                key={i}
                                className="col-md-12 px-2 my-2"
                            >
                                <small className="text-start fw-bold col-md-12">
                                    {data.question}
                                    {(showAnswers===null&&i===0) && (
                                    <span className="text-danger small-text mx-1">*Required</span>
                                )}
                                </small>
                                {
                                    i===0?
                                    <div className="col-md-12">
                                    <input
                                        onChange={() => HandleToggle(i,true)}
                                        checked={showAnswers === true}
                                        className="form-check-input"
                                        type="radio"
                                        id={`checkbox-${i + 1767}`}
                                        name={`checkbox-${i + 1767}`}
                                    />
                                    <label htmlFor={`checkbox-${i + 1767}`} role="button" className="px-3 m-0 small">
                                        Yes
                                    </label>
                                    <input
                                        onChange={() => HandleToggle(i,false)}
                                        checked={showAnswers === false}
                                        className="form-check-input"
                                        type="radio"
                                        id={`checkbox-${i + 9997}`}
                                        name={`checkbox-${i + 9997}`}
                                    />
                                    <label htmlFor={`checkbox-${i + 9997}`} role="button" className="px-3 m-0 small">
                                        No
                                    </label>
                                </div>
                                    :

                                <div className="col-md-12 d-flex flex-row justify-content-between flex-wrap">
                                    <div className="col-md-5">
                                        <Input
                                            type="text"
                                            value={data?.amount}
                                            name="Amount"
                                            placeholder="Amount"
                                            formid={`Amount${i}`}
                                            fn={() => {
                                                handleAmountChange(`Amount${i}`, i);
                                            }}
                                            mask={'999999999'}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Input
                                            type="text"
                                            name="description"
                                            placeholder='Description'
                                            formid={`Descrip${i}`}
                                            value={data?.description}
                                            fn={() => {
                                                handleDiscrptionChange(`Descrip${i}`, i);
                                            }}
                                        />
                                    </div>
                                </div>
                                }
                            </div>
                        );
                    })}
                </div>
            </div>
            {Object.keys(errors)
    ?.map((key) => {
        return errors[key];
    })
    ?.map((data, index) => {
        return (
            <>
                <small className="text-primary" key={index}>
                    {data}*
                </small>
                <br />
            </>
        );
    })}

            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text  w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button  mt-4'}
                        type="button"
                        callback={()=>goBack()}

                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text   w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button  mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default BusinessIncome;