function getTaxproStatusClass(statusId) {
	switch (parseInt(statusId)) {
		case 0:
			return 'primary';
		case 1:
			return 'secondary-600';
		case 2:
			return 'info';
		case 3:
			return 'warning';
		case 4:
			return 'danger';
		case 5:
			return 'grey';
		case 6:
			return 'black';	
		default:
			return 'primary';
	}
}

function getLeadStatusClass(statusId) {
	switch (parseInt(statusId)) {
		case 0:
			return 'primary';
		case 1:
			return 'secondary-700';
		case 2:
			return 'info';
		case 3:
			return 'danger';
		case 4:
			return 'warning';
		default:
			return 'primary';
	}
}

function getJobStatusClass(statusId) {
	switch (parseInt(statusId)) {
		case 0:
			return 'primary';
		case 1:
			return 'secondary-600';
		case 2:
			return 'info';
		case 3:
			return 'success';
		case 4:
			return 'danger';
		case 5:
			return 'grey-400';
		case 6:
			return 'info-600';
		default:
			return 'primary';
	}
}

function getAppointmentStatusClass(statusId) {
	switch (parseInt(statusId)) {
		case 0:
			return 'primary';
		case 1:
			return 'secondary-600';
		case 2:
			return 'danger';
		default:
			return 'primary';
	}
}

function getUserVerifyStatusClass(statusId) {
	switch (parseInt(statusId)) {
		case 0:
			return 'primary';
		case 1:
			return 'secondary-600';
		default:
			return 'primary';
	}
}

function getDisputeStatusClass(statusId) {
	switch (parseInt(statusId)) {
		case 0:
			return 'success';
		case 1:
			return 'primary';
		default:
			return 'success';
	}
}

export {
	getTaxproStatusClass,
	getLeadStatusClass,
	getJobStatusClass,
	getAppointmentStatusClass,
	getUserVerifyStatusClass,
	getDisputeStatusClass,
};
