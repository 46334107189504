/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';

const WorkOpportunity = () => {

    const dispatch=useDispatch()
	const [{ taxPayerForm, taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 28 });
    
    const [willingToMeeting, setWillingToMeeting] = useState(null);
    const [group1, setGroup1] = useState('');
    const [group2, setGroup2] = useState('');
    const [group3, setGroup3] = useState('');
    const [group4, setGroup4] = useState('');
    const [group5, setGroup5] = useState('');
    const [group6, setGroup6] = useState('');

    const [errors, setErrors] = useState({}); // Track errors for form fields

    useEffect(() => {
    	if (taxPayerForm?.data?.WorkOpportunity) {
    		if (Object.keys(taxPayerForm?.data?.WorkOpportunity).length === 0) return;
			const {
                willingToMeeting,
                group1,
                group2,
                group3,
                group4,
                group5,
                group6
			} = taxPayerForm?.data?.WorkOpportunity;
            setWillingToMeeting(willingToMeeting)
            setGroup1(group1)
            setGroup2(group2)
            setGroup3(group3)
            setGroup4(group4)
            setGroup5(group5)
            setGroup6(group6)
    	}
    }, [taxPayerForm?.data?.WorkOpportunity]);

    const check_Validity = () => {
        setErrors({});
        const newErrors = {};
    
        // Add more validation rules as needed for other fields
        if (willingToMeeting===null) {
            newErrors.AnswerOtherQuestions = 'Please Select Required Option';
        }
    
        setErrors(newErrors);
    
        let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
        // Check if there are any errors
        return ConditionCheck;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = check_Validity();
    if (errors) return;

        let FormObj = {
            willingToMeeting,
            group1,
            group2,
            group3,
            group4,
            group5,
            group6
		};
        let formData = taxPayerForm;
        taxPayerForm.data.WorkOpportunity = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));
        uploadFormData(formData);
    };
    
    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 28;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                <div className="col-md-12 mb-3">
                    <div
                        className="w-100 mt-2"
                    >
                        <small className="col-md-12 form-check-label text-start" htmlFor={`checkbox`}>
                            Are there any employees that would qualify for the work opportunity tax credit?
                            {willingToMeeting===null && (
                         <span className="text-danger small-text mx-1">*Required</span>
                        )}
                        </small>
                        <div className="col-md-12">
                            <input
                                onChange={() => setWillingToMeeting(true)} 
                                checked={willingToMeeting === true}
                                className="form-check-input"
                                type="radio"
                                id="checkbox-div2"
                                name="meeting-preference" 
                            />
                            <label htmlFor="checkbox-div2" role="button" className="px-3 m-0 small">
                                Yes
                            </label>
                            <input
                                onChange={() => setWillingToMeeting(false)} 
                                checked={willingToMeeting === false} 
                                className="form-check-input"
                                type="radio"
                                id="checkbox-div1"
                                name="meeting-preference" 
                            />
                            <label htmlFor="checkbox-div1" role="button" className="px-3 m-0 small">
                                No
                            </label>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-12 mb-3">
                    <div className="w-100 mt-2">
                        <small className="col-md-12 form-check-label text-start" htmlFor="checkbox">
                            Total number of employees that fall under the following qualifications
                        </small>
                        <div className="col-md-12">
                            <div className="d-flex align-items-center">
                                <input
                                    onChange={() => setSelectedQualification('snap-assistance')}
                                    checked={selectedQualification === 'snap-assistance'}
                                    className="form-check-input"
                                    type="radio"
                                    id="checkbox-div2"
                                    name="qualification" 
                                />
                                <label htmlFor="checkbox-div2" role="button" className="px-3 mb-2 small">
                                    a member of a family receiving SNAP assistance (food stamps) for at least a 3-month period during the 15-month period ending on the hiring date
                                </label>
                            </div>

                            <div className="d-flex align-items-center">
                                <input
                                    onChange={() => setSelectedQualification('unemployed')} 
                                    checked={selectedQualification === 'unemployed'} 
                                    className="form-check-input"
                                    type="radio"
                                    id="checkbox-div1"
                                    name="qualification" 
                                />
                                <label htmlFor="checkbox-div1" role="button" className="px-3 mb-2 small">
                                    unemployed for a period or periods totaling at least 4 weeks (whether or not consecutive) but less than 6 months in the 1-year period ending on the hiring date
                                </label>
                            </div>
                            <div className="d-flex align-items-center">
                                <input
                                    onChange={() => setSelectedQualification('other-targeted-group')} 
                                    checked={selectedQualification === 'other-targeted-group'}
                                    className="form-check-input"
                                    type="radio"
                                    id="checkbox-div3"
                                    name="qualification" 
                                />
                                <label htmlFor="checkbox-div3" role="button" className="px-3 mb-2 small">
                                    an employee certified as a member of any other targeted group that is not already listed below
                                </label>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div>
                    <div className="col-md-12 mb-3">
                        <Input
                            type="text"
                            name="qualifiedInvestmentPropertyGroup2"
                            value={group1}
                            placeholder="Total number of employees that fall under the following qualifications (Group 1): 
                    Qualified veteran certified as being either (a) a member of a family receiving SNAP assistance (food stamps) for at least a 3-month period during the 15-month period ending on the hiring date, or (b) unemployed for a period or periods totaling at least 4 weeks (whether or not consecutive) but less than 6 months in the 1-year period ending on the hiring date; OR an employee certified as a member of any other targeted group that is not already listed below."
                            fn={setGroup1}
                            mask={"9999999"}
                        />
                    </div>
                    <div className="col-md-12 mb-3">
                        <Input
                            type="text"
                            name="qualifiedInvestmentPropertyGroup2"
                            value={group2}
                            placeholder="Total number of employees that fall under the following qualifications (Group 2): 
                    Qualified veteran certified as being entitled to compensation for a service-connected disability and hired not more than 1 year after being discharged or released from active duty in the U.S. Armed Forces."
                            fn={setGroup2}
                            mask={"9999999"}
                        />
                    </div>
                    <div className="col-md-12 mb-3">
                        <Input
                            type="text"
                            name="qualifiedInvestmentPropertyGroup3"
                            value={group3}
                            placeholder="Total number of employees that fall under the following qualifications (Group 3): 
                    Qualified veteran certified as being unemployed for a period or periods totaling at least 6 months (whether or not consecutive) in the 1-year period ending on the hiring date"
                            fn={setGroup3}
                            mask={"9999999"}
                        />
                    </div>
                    <div className="col-md-12 mb-3">
                        <Input
                            type="text"
                            name="qualifiedInvestmentPropertyGroup4"
                            value={group4}
                            placeholder="Total number of employees that fall under the following qualifications (Group 4): 
                    Qualified veteran certified as being entitled to compensation for a service-connected disability, and unemployed for a period or periods totaling at least 6 months (whether or not consecutive), in the 1-year period ending on the hiring date."
                            fn={setGroup4}
                            mask={"9999999"}
                        />
                    </div>
                    <div className="col-md-12 mb-3">
                        <Input
                            type="text"
                            name="qualifiedInvestmentPropertyGroup5"
                            value={group5}
                            placeholder="Total number of employees that fall under the following qualifications (Group 5): 
                    The amount of qualified first-year wages that may be taken into account for any employee certified as a summer youth employee."
                            fn={setGroup5}
                            mask={"9999999"}
                        />
                    </div>
                    <div className="col-md-12 mb-3">
                        <Input
                            type="text"
                            name="qualifiedInvestmentPropertyGroup6"
                            value={group6}
                            placeholder="Total number of employees that fall under the following qualifications (Group 6): Long-Term Family Assistance recipients who are members of a family that has received TANF benefits for at least 18 consecutive months"
                            fn={setGroup6}
                            mask={"9999999"}
                        />
                    </div>
                </div>


            </div>
            {Object.keys(errors)
    ?.map((key) => {
        return errors[key];
    })
    ?.map((data, index) => {
        return (
            <>
                <small className="text-primary" key={index}>
                    {data}*
                </small>
                <br />
            </>
        );
    })}
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button  mt-4'}
                        type="button"
                        callback={()=>goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default WorkOpportunity;