import React from "react";
import ReactDOM from "react-dom/client";
// global styles
import "./App.scss";
import "bootstrap/dist/js/bootstrap.min.js";
import App from "./App";
// Import Style
import store from "./Store";
import { Provider } from "react-redux";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_KEY } from "./utilites";
const root = ReactDOM.createRoot(document.getElementById("root"));

axios.interceptors.request.use(function (config) {
	const token = localStorage.getItem("token");
	config.headers.Authorization = token;
	config.withCredentials = true;
	return config;
});
const stripePromise = loadStripe(STRIPE_KEY);

root.render(
	<Provider store={store}>
		{/* <React.StrictMode> */}
		<Elements stripe={stripePromise}>
			<App />
		</Elements>

		{/* </React.StrictMode> */}
	</Provider>
);
