export const JOBS_ACTION_TYPE = {
	// REDUCER TYPES
	getJobs: 'getJobs',
	getJobsIdle: 'getJobsIdle',
	resetJobs: 'resetJobs',

	cancelJobRequest: 'cancelJobRequest',
	cancelJobRequestIdle: 'cancelJobRequestIdle',

	markJobAsCompleted: 'markJobAsCompleted',
	markJobAsCompletedIdle: 'markJobAsCompletedIdle',
};
