export default function Checkbox({
  disabled,
  type = "checkbox",
  value,
  name,
  placeholder,
  required = true,
  label = placeholder,
  inputClass,
  labelClass,
  wrapperClass,
  id = name,
  fn=()=>{},
  state = [],
  checked,
  onChnage
}) {
  return (
    <div className={`form-check${wrapperClass ? ` ${wrapperClass}` : ""}`}>
      <input
        type={type}
        className={`form-check-input${inputClass ? ` ${inputClass}` : ""}`}
        placeholder={placeholder}
        aria-label={placeholder}
        value={value}
        id={id}
        required={required}
        disabled={disabled}
        onChange={(e) => {  
          if(onChnage)onChnage()        
          if (type === "radio") {
            fn([e.target.value]);
          } else {
            if (e.target.checked) {
              fn([...state, e.target.value]);
            } else if (state.includes(e.target.value)) {
              fn((prev) =>
                prev.filter((element) => element !== e.target.value)
              );
            }
          }
        }}
        // defaultChecked={type === 'radio' ? state[0] === value : state.includes(value)}
        checked={
          type === "radio"
            ? state[0] === value.toString()
            : state.includes(value.toString()) || checked
        }
      />
      <label
        htmlFor={id}
        className={`form-check-label${labelClass ? ` ${labelClass}` : ""}`}
      >
        {label}
      </label>
    </div>
  );
}
