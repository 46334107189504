import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactSelect from 'react-select';

import Input from './fields/Input';
import Button from './fields/Button';
import Select from './fields/Select';
import { BASEURL, getAmericanTimezones, getStatesList } from '../../utilites';

const american_timezones = getAmericanTimezones();
const UsaStatesList = getStatesList();

export default function ProfileForm({ data = {} }) {
	const [email, setEmail] = useState('');
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [theme, setTheme] = useState('');
	const [timezone, setTimezone] = useState('');
	// For Customers
	const [lastLead, setLastLead] = useState();
	// Additional fields for Customers
	const [phone, setPhone] = useState('');
	const [street, setStreet] = useState('');
	const [street2, setStreet2] = useState('');
	const [city, setCity] = useState();
	const [state, setState] = useState({});
	const [zipcode, setZipcode] = useState('');

	const [alert, setAlert] = useState({
		email: false,
		firstname: false,
		lastname: false,
		theme: false,
		timezone: false,
		phone: false,
		street: false,
		street2: false,
		city: false,
		state: false,
		zipcode: false,
		class: 'danger',
		general: '',
	});

	const button = document.querySelector('form.profile button.btn');

	// set the form data
	useEffect(() => {
		if (data) {
			setEmail(data.email || '');
			setFirstname(data.firstname || '');
			setLastname(data.lastname || '');
			setTheme(data?.settings?.theme || 'light');
			setTimezone(data?.settings?.timezone || american_timezones[3].tzCode); // Default to America/Los_Angeles
		}

		if (data.isCustomer) {
			loadLastLead();
		}
	}, [data]);

	const loadLastLead = () => {
		axios.get(`${BASEURL}/api/leads/by-customer`).then((res) => {
			const leadData = res.data.data;
			// Set fields
			setPhone(leadData?.phone);
			setStreet(leadData?.street || '');
			setStreet2(leadData?.street2 || '');
			setCity(leadData?.city || '');
			setState(leadData?.state ? UsaStatesList.find((s) => s.value === leadData.state) : null);
			setZipcode(leadData?.zipcode || '');
			// Set last lead
			setLastLead(leadData);
		});
	};

	// endpoint to post data
	const endpoint = `${BASEURL}/api/auth/profile/${data.uuid}`;

	// function to handle form form submission
	const handleFormSubmission = (e) => {
		e.preventDefault();
		// disable the button
		button.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Updating...`;
		button.disabled = true;

		let profileObj = {
			firstname,
			lastname,
			settings: {
				theme,
				timezone,
			},
			// For Customers
			phone: phone.split(/[ ()-]/).join(''),
			street,
			street2,
			city,
			state: state?.value || '',
			zipcode,
		};
		if (!data?.isAdmin && data?.isCustomer && !data?.impersonation)
			profileObj.settings.firstTimeCustomerUpdatePassword = true;
		// send the form data to the server
		axios
			.post(endpoint, profileObj)
			.then((response) => {
				localStorage.setItem('theme', theme);
				if (response.data.success && response.data.message !== '') {
					setAlert({
						class: 'success',
						general: response.data.message,
					});
				}
				// reload the page after 3 seconds for now
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			})
			.catch((error) => {
				console.error(error);
				button.disabled = false;
				button.innerHTML = 'Update Profile';
				setAlert({
					class: 'danger',
					general: error?.response?.data?.error,
				});
			});
	};

	return (
		<div>
			<form className="profile needs-validation" onSubmit={handleFormSubmission}>
				{alert.general && alert.general !== '' && (
					<div
						className={`alert alert-${alert.class} alert-dismissible fade show mb-4 text-center`}
						role="alert"
					>
						{alert.general}
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="alert"
							aria-label="Close"
						></button>
					</div>
				)}
				<div className="mb-3">
					<Input
						type="firstname"
						value={firstname}
						name="firstname"
						placeholder="First Name"
						fn={setFirstname}
						showLabel={false}
					/>
				</div>
				<div className="mb-3">
					<Input
						type="lastname"
						value={lastname}
						name="lastname"
						placeholder="Last Name"
						fn={setLastname}
						showLabel={false}
					/>
				</div>
				<div className="mb-3">
					<Input
						type="email"
						value={email}
						name="email"
						placeholder="Email Address"
						isDisabled={true}
						fn={setEmail}
						showLabel={false}
					/>
				</div>
				{/*<div className="mb-3">
					<Select
					value={theme}
					name="theme"
					placeholder="Color Scheme"
					showLabel={false}
					fn={setTheme}
					>
					<option value="light">Light</option>
					<option value="dark">Dark</option>
					</Select>
				</div>*/}
				{data?.isCustomer && lastLead && (
					<>
						<div className="mb-3">
							<Input
								type="phone"
								value={phone}
								name="phone"
								placeholder="Phone Number"
								fn={setPhone}
								showLabel={false}
								mask="(999) 999-9999"
							/>
						</div>
						<fieldset className="border-top p-1 mt-3">
							<legend className="fs-6 float-none w-auto px-2 mx-2 text-start">
								<small>Full Address</small>
							</legend>
						</fieldset>
						<div className="row g-3 mb-3">
							<div className="col-md-9">
								<Input
									type="street"
									value={street}
									name="street"
									placeholder="Street Address"
									fn={setStreet}
									showLabel={false}
									required={false}
								/>
							</div>
							<div className="col-md-3">
								<Input
									type="street2"
									value={street2}
									name="street2"
									placeholder="Apt/Suite"
									fn={setStreet2}
									showLabel={false}
									required={false}
								/>
							</div>
						</div>
						<div className="row g-3 mb-3">
							<div className="col-md-4">
								<Input
									type="city"
									value={city}
									name="city"
									placeholder="City"
									fn={setCity}
									showLabel={false}
									required={false}
								/>
							</div>
							<div className="col-md-4">
								<ReactSelect
									id="state"
									className="p-0 basic-single"
									placeholder="State"
									closeMenuOnSelect={true}
									options={UsaStatesList}
									onChange={setState}
									value={state}
								/>
							</div>
							<div className="col-md-4">
								<Input
									type="zipcode"
									value={zipcode}
									name="zipcode"
									placeholder="Zip Code"
									fn={setZipcode}
									showLabel={false}
									mask="99999999"
								/>
							</div>
						</div>
					</>
				)}
				<fieldset className="border-top p-1 mt-3">
					<legend className="fs-6 float-none w-auto px-2 mx-2 text-start">
						<small>Timezone</small>
					</legend>
				</fieldset>
				<div className="clearfix">
					<Select value={timezone} name="timezone" placeholder="Timezone" fn={setTimezone}>
						{american_timezones?.map((timezone) => (
							<option key={timezone.tzCode} value={timezone.tzCode}>
								{timezone.name}
							</option>
						))}
					</Select>
				</div>

				<Button
					mainClass="btn btn-dark btn-lg"
					wrapperClass="clearfix d-grid text-center mt-4"
					buttonText="Update Profile"
				/>
			</form>
		</div>
	);
}
