import React from "react";
import PaymentForm from "../../forms/PaymentForm";

const PaymentInfo = ({active,setActive}) => {
  return (
    <div>
      <div className="w-full  border-grey rounded px-2 py-2">
        <PaymentForm active={active} setActive={setActive}/>
      </div>
    </div>
  );
};

export default PaymentInfo;
