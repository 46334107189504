import Message from "./fields/Message";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import allActions from "../../Store/action";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Input from "./fields/Input";
import Button from "./fields/Button";

export default function VerifyEmail() {
	let dispatch = useDispatch();
	const navigate = useNavigate();
	const websiteUrl1 = window.location.href;
	const url1 = new URL(websiteUrl1);
	const status1 = url1.pathname.split("/")[3];
	const button = document.querySelector("form.loginform button.btn");
	const [reset, setReset] = useState(false);
	const [password, setPassword] = useState("");
	const [message, setMessage] = useState({ show: false });

	useEffect(() => {
		// Reset User Action Values
		dispatch(allActions.user.reset_user(setMessage, navigate));

		if (status1 === "verify") {
			// Call Verify Email Function
			dispatch(allActions.user.VerifyEmail(setMessage, navigate));
			setReset(false);
		} else if (status1 === "reset") {
			setReset(true);
		}
	}, [status1, navigate, dispatch]);

	const reset_mesage = () => {
		setMessage({ show: false });
	};

	const resetForm = () => {
		setPassword("");
	};
	const change_spinner = (spin) => {
		if (spin) {
			button.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...`;
		} else {
			button.innerHTML = `Reset Password`;
		}
	};

	const handleSubmit = (e) => {
		// Reset User Action Values
		dispatch(allActions.user.reset_user());
		// Reset User State Values
		reset_mesage();
		e.preventDefault();
		// adding a bootstrap spinner uppon form submission
		let user_obj = {
			password,
		};
		// adding a bootstrap spinner uppon form submission
		change_spinner(true);
		dispatch(
			allActions.user.ResetPassword(user_obj, setMessage, resetForm, change_spinner, navigate)
		);
	};

	return (
		<>
			<div className="page px-3">
				<div className="container">
					<div className="clearfix text-center mb-4">
						<div className="row justify-content-center">
							<div className="col-md-5">
								<div className="clearfix bg-white p-5 shadow rounded">
									{message.show ? <Message message={message} /> : <></>}
									{reset ? (
										<form className="loginform needs-validation" onSubmit={handleSubmit}>
											<p className="text-dark">Please enter your new password</p>
											<div className="mb-3 position-relative">
												<Input
													type="password"
													value={password}
													name="password"
													placeholder="Password"
													showLabel={false}
													fn={setPassword}
													secure={true}
												/>
											</div>
											<Button
												mainClass="btn btn-dark btn-lg px-4"
												wrapperClass="clearfix d-grid text-center mt-3"
												buttonText="Reset Password"
											/>
											<div className="text-center mt-3">
												<Link
													to="/login"
													className="d-inline-block px-2 border-end"
												>
													Log in to Account
												</Link>
												<Link
													to="/forgot-password"
													className="d-inline-block px-2"
												>
													Forgot Password
												</Link>
											</div>
										</form>
									) : (
										<></>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
