/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import RegistrationForm from '../../components/forms/RegistrationForm';
import Message from '../../components/forms/fields/Message';
import SEO from '../../components/SEO';

const RegisterTaxPro = () => {
	const params = useLocation();
	const navigation = useNavigate();
	const [message, setMessage] = useState();

	useEffect(() => {
		setMessage(params?.state?.error);
		window.history.replaceState({}, document.title);
	}, [navigation]);

	return (
		<div className='page px-3'>
			<SEO title='Register as a Tax Pro' />
			<div className='container'>
				<div className='clearfix pb-4'>
					<div className='row justify-content-center'>
						<div className='col-md-4'>
							<div className='clearfix bg-white p-5 shadow rounded'>
								<h4 className='ff-gothic text-dark mb-4'>Create your Tax Pro account</h4>
								{message?.show ? <Message message={message} /> : <></>}
								<RegistrationForm />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RegisterTaxPro;
