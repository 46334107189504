import React, { useState, useEffect } from 'react';
import { CiEdit } from "react-icons/ci";
import { IoIosCloseCircleOutline, IoMdCheckmarkCircle} from "react-icons/io";
import { MdToggleOn ,MdOutlineToggleOn} from "react-icons/md";

import DatePicker from 'react-datepicker'
import allActions from '../../../Store/action';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../../../components/forms/fields/Message';
import { MEMBERSHIP_ACTION_TYPE } from '../../../Store/membership/Type';
import ToggleButton from '../../../components/forms/fields/ToggleButton';
import Input from '../../../components/forms/fields/Input';

const Table = ({ user }) => {
    console.log({ user })
    const dispatch = useDispatch();
    const [defaultPlan, setdefaultPlan] = useState(false);
    const [leadAllow, setleadAllow] = useState(0);

    const [{ updateMembership, getMembership }] = useSelector((state) => [state.membership]);
    const customStyles = {
        width: '100px',
    };
    useEffect(() => {
        if (!updateMembership.status) {
            dispatch(allActions.global.Loader(false));
            setMessage({
                show: true,
                type: 'Error',
                text: updateMembership.message,
            });
        }
        if (updateMembership.status === true) {
            setEditableRow();
            dispatch(allActions.membership.getMembership(user.id))
            setMessage({
                show: false
            });
            dispatch(allActions.global.Loader(false));
        }
    }, [dispatch, updateMembership.message, updateMembership.status, user.id]);

    const [editableRow, setEditableRow] = useState();
    const [trialStart, setTrialStart] = useState();
    const [trialEnd, setTrialEnd] = useState();
    const [message, setMessage] = useState({ show: false });
    console.log({ editableRow })
    const getEditableRow = (data, index) => {
        setEditableRow(index);
        setTrialStart(data.trial_startTime)
        setTrialEnd(data.trial_endTime)
        setdefaultPlan(data?.default===1?true:false)
        setleadAllow(  data?.default === 1 ? (
            data?.membership?.membership_type?.Leads
        ) : (
            data?.membership_details?.Leads
        ))
        console.log("dataaaa", data)
    }
    const convertDate = (dateFormate) => {
        const dateString = dateFormate;
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        return formattedDate;
    }

    const updateMembershipData = (data) => {
		if(leadAllow<0) return  setMessage({
			show: true,
			type: 'Error',
			text: "Invalid count",
		});

        const updatedData = {
            userId: data.user_id,
            usermemershipId: data.id,
            trialStart: convertDate(trialStart),
            trialEnd: convertDate(trialEnd),
            defaultEnable:defaultPlan===true?1:0,
            leadsAllow:leadAllow
        }
        setMessage({ show: false });
        dispatch(allActions.global.Loader(true));
        dispatch({ type: MEMBERSHIP_ACTION_TYPE.updateMembershipIdle });
        dispatch(allActions.membership.updateMembership(updatedData));
    }

    return (
			<>
				{message.show ? <Message message={message} /> : <></>}
				<table className="table">
					<thead className="text-dark text-opacity-50">
						<tr>
							<th scope="col" className="small">
								Id
							</th>
							<th scope="col" className="small">
								Membership Name
							</th>
							<th scope="col" className="small">
								Trial Start Time
							</th>
							<th scope="col" className="small">
								Trial End Time
							</th>
							<th scope="col" className="small">
								Sub Start Time
							</th>
							<th scope="col" className="small">
								Sub End Time
							</th>
							<th scope="col" className="small">
								Next Renewal
							</th>
							<th scope="col" className="small">
								Default
							</th>
							<th scope="col" className="small">
								Lead allow
							</th>
							<th scope="col" className="small">
								Action
							</th>
						</tr>
					</thead>
					<tbody>
						{getMembership.status === true &&
							getMembership?.data?.map((data, index) => (
								<tr key={index}>
									<td className="small">{data?.id || '-'}</td>
									<td className="small">{data?.membership?.membership_name || '-'}</td>
									<td className="small text-center col-2">
										{editableRow === index ? (
											<DatePicker
												className="form-select"
												wrapperClassName="d-block"
												selected={new Date(trialStart)}
												onChange={(date) => setTrialStart(date)}
												dateFormat="MMMM d, yyyy"
												placeholderText="Date Created"
											/>
										) : (
											data?.trial_startTime
										)}
									</td>
									<td className="small text-center col-2">
										{editableRow === index ? (
											<DatePicker
												className="form-select"
												style={customStyles}
												wrapperClassName="d-block"
												selected={new Date(trialEnd)}
												onChange={(date) => setTrialEnd(date)}
												dateFormat="MMMM d, yyyy"
												placeholderText="Date Created"
											/>
										) : (
											data?.trial_endTime
										)}
									</td>
									<td className="small">{data?.sub_startTime || '-'}</td>
									<td className="small">{data?.sub_endTime || '-'}</td>
									<td className="small">{data?.next_renewal || '-'}</td>
									<td className="small text-center col-2">
										{editableRow === index ? (
											<ToggleButton checked={defaultPlan} setChecked={setdefaultPlan} />
										) : data?.default === 1 ? (
											<MdToggleOn size={29} role="button" color="#4b57e7" />
										) : (
											<MdOutlineToggleOn size={29} role="button" color="#4b57e7" />
										)}
									</td>
									<td className="small text-center col-2">
										{editableRow === index ? (
											<Input
												isDisabled={defaultPlan}
												type="number"
												value={leadAllow}
												name="phone"
												placeholder="e.g 30"
												fn={setleadAllow}
												showLabel={false}
											/>
										) : data?.default === 1 ? (
											data?.membership?.membership_type?.Leads
										) : (
											data?.membership_details?.Leads
										)}
									</td>
									<td className="small">
										{editableRow === index ? (
											<div>
												<IoIosCloseCircleOutline
													size={25}
													role="button"
													color="red"
													onClick={() => {
														setEditableRow();
														dispatch({ type: MEMBERSHIP_ACTION_TYPE.updateMembershipIdle });
														setMessage({ show: false });
													}}
												/>{' '}
												<IoMdCheckmarkCircle
													size={25}
													role="button"
													color="#4b57e7"
													onClick={() => updateMembershipData(data)}
												/>
											</div>
										) : (
											<CiEdit
												size={25}
												role="button"
												onClick={() => {
													setdefaultPlan(false);
													getEditableRow(data, index);
													dispatch({ type: MEMBERSHIP_ACTION_TYPE.updateMembershipIdle });
													setMessage({ show: false });
												}}
											/>
										)}
									</td>
								</tr>
							))}
					</tbody>
				</table>
				<div className="col-12 d-flex align-items-center justify-content-end">
					<button
						type="button"
						className="btn btn-primary"
						onClick={() => document.querySelector('.upgradeMembershipbtn').click()}
					>
						Add Membership
					</button>
				</div>
			</>
		);
}

export default Table