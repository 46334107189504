export const USER_ACTION_TYPE = {
	user: ' user',
	verify: 'verify',
	status: 'Status',
	adminRole: 'adminRole',
	taxproRole: 'taxproRole',
	customerRole: 'customerRole',
	accountManagerRole: 'accountManagerRole',
	reset: 'reset',
	statusId: 'statusId',
	existEmail: 'existEmail',
	existEmailReset: 'existEmailReset',
	addExistingUser: 'addExistingUser',
	


};
