import { GLOBAL_VARIABLES } from "./variables";

const {
	DocumentOptions,
	Options1040,
	Options1065,
	Options1120C,
	Options1120S,
	FormNames1040,
	FormNames1065,
	FormNames1120C,
	FormNames1120S,
	// OptionsTaxPlanningIndividual,
	// FormNamesTaxPlanningIndividual,
	TaxPlaningIndividual,
	TaxPlaningIndividualFormNames,
	OptionsTaxPreperationIndividualCurrentClientShort,
	OptionsTaxPreperationIndividualCurrentClientShortFormNames,
	OptionsTaxPreperationIndividualCurrentClientLong,
	OptionsTaxPreperationIndividualCurrentClientLongFormNames,
	OptionsTaxPreperationIndividualNewClientShort,
	OptionsTaxPreperationIndividualNewClientShortFormNames,

	OptionsTaxPreperationIndividualNewClientLong,
	OptionsTaxPreperationIndividualNewClientLongFormNames,

	OptionsTaxPreperationQuestionnaireBusinessCurrentClientShort,
	OptionsTaxPreperationQuestionnaireBusinessCurrentClientShortFormNames,

	OptionsTaxPreperationQuestionnaireBusinessNewClientShort,
	OptionsTaxPreperationQuestionnaireBusinessNewClientShortFormNames,

	OptionsTaxPreperationQuestionnaireBusinessCurrentClientLong,
	OptionsTaxPreperationQuestionnaireBusinessCurrentClientLongFormNames,

	OptionsTaxPreperationQuestionnaireBusinessNewClientLong,
	OptionsTaxPreperationQuestionnaireBusinessNewClientLongFormNames,

	TaxPlanningBusinessOwner,
	TaxPlanningBusinessOwnerFormNames

} = GLOBAL_VARIABLES;

export const IsLogin = () => {
	if (localStorage.getItem("token")) {
		return true;
	} else {
		return false;
	}
};

// Function that receive two arguments first as array and 2nd as number and tell number is repeted or not
export const isNumberRepeated = (arr, num) => arr.filter((item) => item === num).length > 1;

export const getCurrentFormData = (args) => {
	if (args === DocumentOptions[0].value) return Options1040;
	if (args === DocumentOptions[1].value) return Options1065;
	if (args === DocumentOptions[2].value) return Options1120C;
	if (args === DocumentOptions[3].value) return Options1120S;
	// if (args === DocumentOptions[4].value) return OptionsTaxPlanningIndividual;
	if (args === DocumentOptions[4].value) return TaxPlaningIndividual;

	if (args === DocumentOptions[5].value) return TaxPlanningBusinessOwner;

	if (args === DocumentOptions[6].value) return OptionsTaxPreperationIndividualCurrentClientShort;
	if (args === DocumentOptions[7].value) return OptionsTaxPreperationIndividualCurrentClientLong;
	if (args === DocumentOptions[8].value) return OptionsTaxPreperationIndividualNewClientShort;
	if (args === DocumentOptions[9].value) return OptionsTaxPreperationIndividualNewClientLong;
	if (args === DocumentOptions[10].value) return OptionsTaxPreperationQuestionnaireBusinessCurrentClientShort;
	if (args === DocumentOptions[11].value) return OptionsTaxPreperationQuestionnaireBusinessNewClientShort;
	if (args === DocumentOptions[12].value) return OptionsTaxPreperationQuestionnaireBusinessCurrentClientLong;
	if (args === DocumentOptions[13].value) return OptionsTaxPreperationQuestionnaireBusinessNewClientLong;


};

export const getCurrentFormsObj = (args) => {
	if (args === DocumentOptions[0].value) return FormNames1040;
	if (args === DocumentOptions[1].value) return FormNames1065;
	if (args === DocumentOptions[2].value) return FormNames1120C;
	if (args === DocumentOptions[3].value) return FormNames1120S;
	// if (args === DocumentOptions[4].value) return FormNamesTaxPlanningIndividual;
	if (args === DocumentOptions[4].value) return TaxPlaningIndividualFormNames;

	if (args === DocumentOptions[5].value) return TaxPlanningBusinessOwnerFormNames;

	if (args === DocumentOptions[6].value) return OptionsTaxPreperationIndividualCurrentClientShortFormNames;
	if (args === DocumentOptions[7].value) return OptionsTaxPreperationIndividualCurrentClientLongFormNames;
	if (args === DocumentOptions[8].value) return OptionsTaxPreperationIndividualNewClientShortFormNames;
	if (args === DocumentOptions[9].value) return OptionsTaxPreperationIndividualNewClientLongFormNames;
	if (args === DocumentOptions[10].value) return OptionsTaxPreperationQuestionnaireBusinessCurrentClientShortFormNames;
	if (args === DocumentOptions[11].value) return OptionsTaxPreperationQuestionnaireBusinessNewClientShortFormNames;
	if (args === DocumentOptions[12].value) return OptionsTaxPreperationQuestionnaireBusinessCurrentClientLongFormNames;
	if (args === DocumentOptions[13].value) return OptionsTaxPreperationQuestionnaireBusinessNewClientLongFormNames;
};

export function getSubObjectCount(obj) {
	try {
		let count = 0;
		Object.keys(obj)?.forEach((element) => {
			if (Object.keys(obj[element])?.length > 0) {
				++count;
			}
		});
		return count;
	} catch (error) {
		console.error("Error:", error);
		return 0;
	}
}

export const formatCurrency = (value) => {
	let formattedValue = value?.toString();
	const parts = formattedValue?.split(".");
	parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	formattedValue = parts?.join(".");
	return `$${formattedValue}`;
  };
