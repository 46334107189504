import { GrNext, GrPrevious } from 'react-icons/gr';

// An array to define options for rows per page
const showPerPage = [10, 20, 50, 100];

export default function Pagination({
	pageCount,
	currentPage,
	setCurrentPage,
	perPage,
	setPerPage,
}) {
	// Generate an array of page numbers based on pageCount
	const pages = [];
	for (let i = 0; i < pageCount; i++) {
		pages.push(i + 1);
	}

	// Function to render pagination items
	const renderPaginationItems = () => {
		// If there's only one page, no need to render pagination
		if (pageCount <= 1) return null;
		const paginationItems = []; // Initialize an array to store pagination items
		// Loop through each page number
		for (let i = 1; i <= pageCount; i++) {
			// If it's one of the first, last, current, or nearby pages, render it
			if (
				i === 1 ||
				i === pageCount ||
				i === currentPage ||
				(i >= currentPage - 1 && i <= currentPage + 1)
			) {
				paginationItems.push(
					<li className="page-item me-2" key={i}>
						<button
							className={`page-link rounded-1${currentPage === i ? ' active' : ''}`}
							onClick={() => setCurrentPage(i)}
						>
							{i}
						</button>
					</li>
				);
			} else if (
				(i === currentPage - 2 && currentPage > 3) || // If current page is not close to the first page
				(i === currentPage + 2 && currentPage < pageCount - 2) // If current page is not close to the last page
			) {
				// Render truncation
				paginationItems.push(
					<li className="page-item me-2" key={`truncate-${i}`}>
						<span className="page-link rounded-1">...</span>
					</li>
				);
			}
		}
		// Add previous button
		paginationItems.push(
			<li className={`page-item ms-2 me-2${currentPage === 1 ? ' disabled' : ''}`} key="previous">
				<button
					className="page-link rounded-1"
					onClick={() => setCurrentPage(currentPage - 1)}
					disabled={currentPage === 1}
				>
					<GrPrevious />
				</button>
			</li>
		);
		// Add next button
		paginationItems.push(
			<li className={`page-item${currentPage === pageCount ? ' disabled' : ''}`} key="next">
				<button
					className="page-link rounded-1"
					onClick={() => setCurrentPage(currentPage + 1)}
					disabled={currentPage === pageCount}
				>
					<GrNext />
				</button>
			</li>
		);
		return paginationItems; // Return the array of pagination items
	};

	// Render the pagination component
	return (
		<nav className="mt-4">
			<ul className="pagination justify-content-center">
				{perPage && (
					<>
						<li className="page-item d-flex align-items-center me-2">
							<span>Rows per page: </span>
						</li>
						<li className="page-item me-4">
							<select
								className="form-select rounded-1"
								value={perPage}
								onChange={(e) => setPerPage(e.target.value)}
							>
								{showPerPage?.map((i) => (
									<option key={i} value={i}>
										{i}
									</option>
								))}
							</select>
						</li>
					</>
				)}
				{renderPaginationItems()}
			</ul>
		</nav>
	);
}
