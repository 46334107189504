/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const Table = ({ Header, List, Keys }) => {
	return (
		<div className="my-2">
			<table className="table">
				<thead>
					<tr>
						{Header?.map((data) => (
							<th scope="col fw-bold">{data}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{List?.map((data) => {
						return (
							<tr>
								<td>{data?.question}</td>
								<td>{data?.DateAcquired}</td>
								<td>{data?.cost}</td>
								<td>{data?.applied ? 'Applied' : 'Not Applied'}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default Table;
