/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, Fragment } from 'react';
import axios from 'axios';
import { FileUploader } from 'react-drag-drop-files';
import { BASEURL } from '../../utilites';
import Button from './fields/Button';
import Select from './fields/Select';

export default function AddLeadsForm({ professionals, userRoles, proId, reloadLeads, setAlert }) {
	const [spreadsheet, setSpreadsheet] = useState();
	const [useRoundRobin, setUseRoundRobin] = useState(false);
	const [pro, setPro] = useState();

	// Progress
	const [uploading, setUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);

	const [errors, setErrors] = useState({
		spreadsheet: false,
		useRoundRobin: false,
		pro: false,
		general: '',
	});

	const button = useRef();

	useEffect(() => {
		if (proId) {
			setPro(proId.toString());
		}
	}, [proId]);

	// function to handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();
		setUploading(true);

		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });
		// Create a new FormData instance
		const formData = new FormData();
		// Append properties to FormData
		formData.append('documents', spreadsheet);
		if (useRoundRobin) {
			formData.append('useRoundRobin', useRoundRobin);
		}
		if (pro) {
			formData.append('taxpro_id', pro);
		}
		axios
			.post(`${BASEURL}/api/leads/using-spreadsheet`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				onUploadProgress: (progressEvent) => {
					// Calculate the upload progress percentage
					const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);

					// Update the uploadProgress state for the current file
					setUploadProgress(progress);
				},
			})
			.then(() => {
				button.current.disabled = false;
				reloadLeads();
				// Close Modal
				document.querySelector('.addLeadModalClose').click();
				setAlert('Jobs has been added successfully!');
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
		// Reset the form
		setUploading(false);
		setUploadProgress(0);
		setSpreadsheet();
	};
	return (
		<form className="leadsform needs-validation" onSubmit={handleSubmit}>
			{!uploading ? (
				<Fragment>
					<div className="row">
						<div className="col-md-12 mb-3">
							<FileUploader
								classes="form-control mw-100"
								multiple={false}
								values={spreadsheet}
								handleChange={(file) => setSpreadsheet(file)}
								name="spreadsheet"
								types={['XLSX', 'CSV']}
								onTypeError={() => {
									setErrors({ ...errors, spreadsheet: true });
								}}
							/>
						</div>
					</div>
					{!userRoles.isTaxPro && (
						<div className="row">
							<div className="col-md-12 mb-3">
								<div className="form-check d-inline-block  m-0">
									<input
										className="form-check-input"
										type="checkbox"
										id="useRoundRobin"
										onChange={(e) => setUseRoundRobin(e.target.checked)}
										checked={useRoundRobin}
									/>
									<label className="form-check-label" htmlFor="useRoundRobin">
										Use Round Robin system?
									</label>
								</div>
							</div>
						</div>
					)}
					{!useRoundRobin &&
						(userRoles.isAdmin || userRoles.isAccountManager) &&
						professionals.length > 0 && (
							<div className="row">
								<div className="col-md-12 mb-3">
									<Select value={pro} name="taxpro" placeholder="Tax Professional" fn={setPro}>
										<option value="">Select Tax Professional</option>
										{professionals?.map((professional) => (
											<option
												key={professional.id}
												value={professional.userpro.id}
											>{`${professional.firstname} ${professional.lastname}`}</option>
										))}
									</Select>
								</div>
							</div>
						)}
				</Fragment>
			) : (
				<div className="progress">
					<div
						className="progress-bar progress-bar-striped progress-bar-animated"
						role="progressbar"
						style={{ width: `${uploadProgress}%` }}
						aria-valuenow={uploadProgress}
						aria-valuemin="0"
						aria-valuemax="100"
					>
						{uploadProgress}%
					</div>
				</div>
			)}

			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mb-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 || !spreadsheet ? ' disabled' : ''
				}`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-3"
				buttonText="Add Jobs"
			/>
		</form>
	);
}
