export const FACT_AUTH_ACTION_TYPE = {
  sendOtp: "sendOtp",
  verifyOtp: "verifyOtp",
  sendOtpIdle: "sendOtpIdle",
  verifyOtpIdle: "verifyOtpIdle",
  EnableAuthSendOtp: "EnableAuthSendOtp",
  EnableAuthSendOtpIdle: "EnableAuthSendOtpIdle",
  EnableAuthVerifyOtp: "EnableAuthVerifyOtp",
  EnableAuthVerifyOtpIdle: "EnableAuthVerifyOtpIdle",
  OldAuthSendOtp: "OldAuthSendOtp",
  OldAuthSendOtpIdle: "OldAuthSendOtpIdle",
  OldAuthVerifyOtp: "OldAuthVerifyOtp",
  OldAuthVerifyOtpIdle: "OldAuthVerifyOtpIdle",
};
