/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../Store/action';

import {
	useStripe,
	useElements,
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
} from '@stripe/react-stripe-js';
import MembershipCardInput from '../Dashboard/billing/MembershipCardInput';
import { CiCreditCard1 } from 'react-icons/ci';
import PaymentForm from './PaymentForm';
import { MEMBERSHIP_ACTION_TYPE } from '../../Store/membership/Type';
import { useNavigate } from 'react-router';

const defaultMessage = 'Something went wrong while adding the card';

export default function PaymentMembershipForm({ setPaymentMessage, setMessage, invoice, membership, selectedMembership }) {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const stripe = useStripe();
	const elements = useElements();
	let dispatch = useDispatch();
	const navigate=useNavigate()
	const [
		{
			paymentCardAdd,
			paymentCardAddMessage
		},
		{ LoginData },
		{ createCustomer, createCustomerMessage },
		{ updateMembershipPlan }
	] = useSelector((state) => [state.payment, state.user, state.invoice, state.membership]);
	// Payment Card Modal States
	const [cvc, setCvc] = useState();
	const [mmYy, setMmYy] = useState();
	const [zipCode, setZipCode] = useState('');
	const [cardName, setCardName] = useState('');
	const [cardNumber, setCardNumber] = useState();
	const [cardObj, setCardObj] = useState();
	const [defaultSelection, setDefaultSelection] = useState();

	// Payment Bank Account
	const [type, setType] = useState();
	const [addCard, setAddCard] = useState();

	useEffect(() => {
		setDefaultSelection(LoginData?.userpaymentmethod.length === 0 ? true : false);
		setType('card')
	}, [LoginData?.userpaymentmethod.length]);

	// updatemembership useEffect 
	useEffect(() => {
		if (!updateMembershipPlan.status) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: updateMembershipPlan.message,
			});
		}
		if (updateMembershipPlan.status === true) {
			dispatch(allActions.user.ReGetUserData());
			navigate("/dashboard/successThanks");
			  dispatch({ type: MEMBERSHIP_ACTION_TYPE.updateMembershipPlanIdle });
			setMessage({ show: false });
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, updateMembershipPlan]);

	useEffect(() => {
		if (!createCustomer) {
			dispatch(allActions.global.Loader(false));
			setPaymentMessage({
				show: true,
				type: 'Error',
				text: createCustomerMessage,
			});
			handleButton(false);
		}
		if (createCustomer === true) {
			dispatch(allActions.payment.AddInvoiceCard(cardObj));
		}
	}, [createCustomer, createCustomerMessage]);

	// *****--Hooks To Handle Add Card--****
	useEffect(() => {
		if (paymentCardAdd === 'idle') {
			setMessage({ show: false });
			setPaymentMessage({ show: false })
		}
		// if Failure
		if (!paymentCardAdd) {
			dispatch(allActions.global.Loader(false));
			setPaymentMessage({
				show: true,
				type: 'Error',
				text: paymentCardAddMessage,
			});
			handleButton(false);
		}

		if (addCard) {
			// if Success
			if (paymentCardAdd === true) {

				// setMessage({ show: false });
				// dispatch(allActions.global.Loader(true));
				// dispatch({ type: MEMBERSHIP_ACTION_TYPE.updateMembershipIdle });
				// dispatch(allActions.membership.updateMembershipPlan({ membershipId: selectedMembership.plan.id }));


				dispatch(allActions.global.Loader(false));
				setMessage({
					show: true,
					type: 'Success',
					text: 'Payment Card Added Successfully',
				});
				reset_CardForm();
				setAddCard(false)
				handleButton(false);

			}
		}
	}, [paymentCardAdd, paymentCardAddMessage, addCard]);

	const reset_message = () => {
		dispatch(allActions.payment.resetModifyPayment());
		setMessage({ show: false });
	};

	const reset_payment_message = () => {
		dispatch(allActions.payment.resetAddPayment());
		setPaymentMessage({ show: false });
	};

	// *****--Function To Reset Payment Forms--****

	const resetWarnings = () => {
		reset_message();
		reset_payment_message();
		dispatch(allActions.invoice.resetCreateCustomer());
	};

	const reset_CardForm = () => {
		setCvc('');
		setMmYy('');
		setZipCode('');
		setCardName('');
		setCardNumber('');
		setDefaultSelection(LoginData?.userpaymentmethod.length === 0 ? true : false);
		if (elements.getElement(CardNumberElement))
			elements.getElement(CardNumberElement).update({ value: '', disabled: false });
		if (elements.getElement(CardCvcElement))
			elements.getElement(CardCvcElement).update({ value: '', disabled: false });
		if (elements.getElement(CardExpiryElement))
			elements.getElement(CardExpiryElement).update({ value: '', disabled: false });
	};

	const handleButton = (status) => {
		if (status) {
			const btn = document.getElementById('paymentSubmit');
			btn.setAttribute('disabled', status);
		} else {
			setTimeout(() => {
				const btn = document.getElementById('paymentSubmit');
				btn.removeAttribute('disabled');
			}, 1000);
		}
	};

	const handleSubmitCard = async (e) => {
		setAddCard(true)
		e.preventDefault();
		if (!stripe || !elements) {
			return;
		}

		handleButton(true);
		// Reset User State Values
		resetWarnings();
		let cardObj = {
			type,
			card: {
				def: defaultSelection,
			},

		};
		dispatch(allActions.global.Loader(true));
		// For CUSTOMER
		// Add Case For Invoice Payment
		if (membership) {
			if (type === 'card') {
				const { paymentMethod, error } = await stripe.createPaymentMethod({
					type: 'card',
					card: elements.getElement(CardNumberElement),
					billing_details: {
						name: cardName,
						address: {
							postal_code: zipCode,
						},
					},
				}, {
					stripeAccount: "acct_1OwUX0QhwNsicKyd",
				});
				if (error) {
					setPaymentMessage({
						show: true,
						type: 'Error',
						text: error?.message || defaultMessage,
					});
					handleButton(false);
					dispatch(allActions.global.Loader(false));
					return;
				}
				cardObj.card.cardData = paymentMethod;
			}
			let invoiceCardObj = {
				...cardObj.card,
			};
			if (LoginData?.customers?.length === 0) {
				let customerObj = {
					user_id: LoginData?.id,
					email: LoginData?.email,
					phone: LoginData?.lead?.[0]?.phone,
					name: LoginData?.firstname + ' ' + LoginData?.lastname,
				};
				setCardObj(invoiceCardObj);
				dispatch(allActions.invoice.createCustomer(customerObj));
			} else {
				dispatch(allActions.payment.AddInvoiceCard(invoiceCardObj));
			}
			return;
		}
	};

	return (
		<div>
			<div className="w-100 row m-0 px-2">
				<p className="w-100 text-align-start m-0 p-0">
					<CiCreditCard1 size={25} className="my-2 mt-4" />
				</p>
				<h6 className="fw-bold w-100 text-align-start m-0 p-0 mb-2">Payment Method</h6>
				<hr className="color-primary" />
				<p className="fw-bold m-0 p-0">Pay With:</p>
				<div className="col-auto mb-3 m-0 p-0">
					<div className="form-check form-check-inline">
						<label className="form-check-label-outer" htmlFor="agreeCheckbox">
							<input
								className="form-check-input custom-checkbox"
								type="radio"
								id="agreeCheckbox"
								checked={true}
								onChange={() => { }}
							/>
							Card
						</label>
					</div>
				</div>
			</div>
			{LoginData?.userpaymentmethod?.find((data) => data.payment_method_id.startsWith('pm')) ? (
				<>
				<PaymentForm membershipCheckout={selectedMembership?.plan} invoice={true} defaultSelect={true} children={
				<div className='col-lg-12 d-flex align-items-center justify-content-center'>
					<p className='text-decoration-underline' onClick={() => document.querySelector('.paymentformmodal').click()} role='button'>Add New Card</p>
				</div>
				} />
				</>
			) : (
				<MembershipCardInput
					cvc={cvc}
					setCvc={setCvc}
					mmYy={mmYy}
					setMmYy={setMmYy}
					zipCode={zipCode}
					setZipCode={setZipCode}
					cardName={cardName}
					setCardName={setCardName}
					cardNumber={cardNumber}
					setCardNumber={setCardNumber}
					// paymentMessage={paymentMessage}
					defaultSelection={defaultSelection}
					setDefaultSelection={setDefaultSelection}
					AllowDefaultSelect={LoginData?.userpaymentmethod.length === 0 ? false : true}
					resetWarnings={resetWarnings}
					handleSubmitCard={handleSubmitCard}
					OkButtonText={'Add Card'}
				/>
			)}
		</div>
	);
}
