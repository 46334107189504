import { FACT_AUTH_ACTION_TYPE } from "./Type";

const INITIAL_STATE = {
  sendOtp: "idle",
  verifyOtp: "idle",
  messageSend: "",
  messageVerify: "",
  EnableAuthSendOtp: "idle",
  EnableAuthSendMessage: "",
  EnableAuthVerifyOtp: "idle",
  EnableAuthVerifyMessage: "",
  OldAuthSendOtp: "idle",
  OldAuthSendMessage: "",
  OldAuthVerifyOtp: "idle",
  OldAuthVerifyMessage: "",
};

const factAuth = (state = INITIAL_STATE, action) => {
  if (action.type === FACT_AUTH_ACTION_TYPE.sendOtp) {
    return {
      ...state,
      sendOtp: action.status,
      messageSend: action.message,
    };
  } else if (action.type === FACT_AUTH_ACTION_TYPE.verifyOtp) {
    return {
      ...state,
      verifyOtp: action.status,
      messageVerify: action.message,
    };
  } else if (action.type === FACT_AUTH_ACTION_TYPE.sendOtpIdle) {
    return {
      ...state,
      sendOtp: "idle",
      messageSend: "",
    };
  } else if (action.type === FACT_AUTH_ACTION_TYPE.verifyOtpIdle) {
    return {
      ...state,
      verifyOtp: "idle",
      messageVerify: "",
    };
  } else if (action.type === FACT_AUTH_ACTION_TYPE.EnableAuthSendOtp) {
    return {
      ...state,
      EnableAuthSendOtp: action.status,
      EnableAuthSendMessage: action.message,
    };
  } else if (action.type === FACT_AUTH_ACTION_TYPE.EnableAuthSendOtpIdle) {
    return {
      ...state,
      EnableAuthSendOtp: "idle",
      EnableAuthSendMessage: "",
    };
  } else if (action.type === FACT_AUTH_ACTION_TYPE.EnableAuthVerifyOtp) {
    return {
      ...state,
      EnableAuthVerifyOtp: action.status,
      EnableAuthVerifyMessage: action.message,
    };
  } else if (action.type === FACT_AUTH_ACTION_TYPE.EnableAuthVerifyOtpIdle) {
    return {
      ...state,
      EnableAuthVerifyOtp: "idle",
      EnableAuthVerifyMessage: "",
    };
  } else if (action.type === FACT_AUTH_ACTION_TYPE.OldAuthSendOtp) {
    return {
      ...state,
      OldAuthSendOtp: action.status,
      OldAuthSendMessage: action.message,
    };
  } else if (action.type === FACT_AUTH_ACTION_TYPE.OldAuthSendOtpIdle) {
    return {
      ...state,
      OldAuthSendOtp: "idle",
      OldAuthSendMessage: "",
    };
  } else if (action.type === FACT_AUTH_ACTION_TYPE.OldAuthVerifyOtp) {
    return {
      ...state,
      OldAuthVerifyOtp: action.status,
      OldAuthVerifyMessage: action.message,
    };
  } else if (action.type === FACT_AUTH_ACTION_TYPE.OldAuthVerifyOtpIdle) {
    return {
      ...state,
      OldAuthVerifyOtp: "idle",
      OldAuthVerifyMessage: "",
    };
  }

  return state;
};

export default factAuth;
