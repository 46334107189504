export const RECORDINGS_ACTION_TYPE = {
	getRecordings:"getRecordings",
	saveRecording:"saveRecording",
	playRecording:"playRecording",
	// PLAID SUCCESS MESSAGES
	plaidCreateFetchSucess: "Verification Link Generated Successfully",
	plaidGetUploadFetchSucess: "User Verified Successfully",
	PlaidPictureUploadingMessage: "You Have Successfully Verified,Wait For Uploading Don't Reload The Page",
	// PLAID OTHER VARIABLES

	// REDUCER TYPES
	plaidCreate: "plaidCreate",
	plaidGetUpload: "plaidGetUpload",

	// RESETREDUCER TYPES
	plaidCreateIdle: "plaidCreateIdle",
	plaidGetUploadIdle: "plaidGetUploadIdle",
};
