import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/img/logo.png';
import SEO from '../components/SEO';

const NotFound = () => {
	const navigate = useNavigate();
	return (
		<div className='page px-3'>
			<SEO title='Page not found' />
			<div className='container'>
				<div className='clearfix pt-4 text-center mb-4'>
					<div className='mx-auto px-md-0 px-4 mb-4' style={{ width: 200 }}>
						<Link to='/'>
							<img className='img-fluid' src={Logo} alt='Logo' />
						</Link>
					</div>
					<h1 className='text-primary fw-bold' style={{ fontSize: 150 }}>
						404
					</h1>
					<p className='text-primary text-capitalize fw-bold'>Page not found</p>
					<button onClick={() => navigate(-1, { replace: true })} className='btn btn-primary'>
						&larr; Go back
					</button>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
