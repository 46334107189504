import Modal from "../../Dashboard/Modal";
import { Link } from "react-router-dom";
import Input from "../fields/Input";
import CreditCardInput from "../fields/Credit-Card-input";
import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import Message from "../fields/Message";
export default function PaymentModal({
	cardName,
	setCardName,
	cardNumber,
	mmYy,
	setMmYy,
	cvc,
	zipCode,
	setZipCode,
	defaultSelection,
	setDefaultSelection,
	handleSubmitCard,
	paymentMessage,
	resetWarnings,
	OkButtonText,
	editCard,
	AllowDefaultSelect,
}) {
	const customStyles = {
		style: {
			base: {
				fontSize: "1.2rem",
				lineHeight: "1.5",
			},
		},
	};

	const cardNumberElementOptions = {
		...customStyles,
		placeholder: "Card Number",
	};

	const cardExpiryElementOptions = {
		...customStyles,
		placeholder: "MM / YY",
	};

	const cardCvcElementOptions = {
		...customStyles,
		placeholder: "CVC",
	};

	const paymentElement = document.getElementsByClassName("__PrivateStripeElement");
	if (paymentElement) {
		for (let i = 0; i < paymentElement.length; i++) {
			paymentElement[i].style.height = "50px";
		}
	}

	return (
		<Modal
			title={editCard ? "Edit Payment Card" : "Add New Payment"}
			bodyClass="p-4"
			btnText={editCard ? "Edit Payment Card" : "Add New Payment"}
			trigger="paymentForm"
			showBtn={false}
			size={"lg"}
			close={false}
		>
			<form
				className="w-100 d-flex justify-content-between flex-wrap align-items-center"
				onSubmit={(e) => handleSubmitCard(e)}
			>
				<div className="w-100 d-flex align-items-center justify-content-center">
					{" "}
					{paymentMessage.show ? <Message message={paymentMessage} /> : <></>}
				</div>
				<div className="w-100 mb-4 row m-0 p-0">
					<Input
						type="text"
						value={cardName}
						name="Name on Card"
						placeholder="Name on Card"
						fn={setCardName}
						isDisabled={editCard ? true : false}
					/>
				</div>
				<fieldset className="border-top w-100 row m-0 p-1 mx-3">
					<legend className="float-none w-auto px-2 mx-2 text-start h6 fw-bold">
						Payment Method
					</legend>
				</fieldset>
				<div className="mb-2 w-100 m-0 p-0">
					<div className="row w-100 m-0 p-0 ">
						<div className="mb-3 col-lg-8 col-sm-12">
							{cardNumber ? (
								<CreditCardInput
									type="text"
									value={cardNumber}
									name="Card Number"
									placeholder="Card Number"
									paymentcard={true}
									editCard={editCard}
									isDisabled={true}
									showLabel={true}
									fn={() => {}}
								/>
							) : (
								<>
									<label className={`form-label`}>Card Number</label>
									<CardNumberElement className="form-control" options={cardNumberElementOptions} />
								</>
							)}
						</div>

						<div className="mb-3 col-lg-4 col-sm-12">
							{mmYy ? (
								<Input
									type="text"
									value={mmYy}
									name="MM / YYY"
									placeholder="MM / YY"
									fn={setMmYy}
								/>
							) : (
								<>
									<label className={`form-label`}>MM / YY</label>
									<CardExpiryElement className="form-control" options={cardExpiryElementOptions} />
								</>
							)}
						</div>

						<div className="mb-3 col-lg-6 col-sm-12">
							{cvc ? (
								<Input
									type="text"
									value={cvc}
									name="CVC"
									placeholder="CVC"
									fn={() => {}}
									mask={editCard ? "" : "999"}
									isDisabled={true}
								/>
							) : (
								<>
									<label className={`form-label`}>CVC</label>
									<CardCvcElement className="form-control" options={cardCvcElementOptions} />
								</>
							)}
						</div>

						<div className="mb-3 col-lg-6 col-sm-12">
							<Input
								type="text"
								value={zipCode}
								name="Zipcode"
								placeholder="Zipcode"
								fn={setZipCode}
								mask="99999999"
							/>
						</div>
						<div className="mb-3 col-lg-12 col-sm-12">
							<div className="w-full d-flex align-items-start justify-content-start">
								<input
									className="form-check-input custom-checkbox pl-2 "
									type="checkbox"
									id={`checkbox`}
									disabled={!AllowDefaultSelect}
									checked={defaultSelection}
									onChange={() => setDefaultSelection(!defaultSelection)}
								/>
								<label
									role="button"
									className="form-check-label text-start text-muted mx-2"
									htmlFor={`checkbox`}
								>
									Make this my default payment method
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className="container-fluid content-row  ">
					<div className="d-flex align-items-center justify-content-center row">
						<div className={"col-sm-4 custon-button  mt-4"}>
							<Link
								to="#"
								className="d-block text-decoration-none text-dark paymentmodal"
								data-bs-toggle="modal"
								data-bs-target="#paymentForm"
							>
								<button
									className={"btn btn-info custon-button-text text-white btn-lg w-100"}
									type={"button"}
									onClick={() => {
										resetWarnings();
									}}
								>
									Cancel
								</button>
							</Link>
						</div>

						<div className={"col-sm-4 custon-button  mt-4"}>
							<button
								id="paymentSubmit"
								className={"btn btn-primary custon-button-text btn-lg w-100"}
								type="submit"
							>
								{OkButtonText}
							</button>
						</div>
					</div>
				</div>
			</form>
		</Modal>
	);
}
