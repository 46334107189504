import React from 'react';
import Header from '../components/Header';

import ConfettiExplosion from 'react-confetti-explosion';
import { BsCheckCircleFill } from 'react-icons/bs';
import Footer from '../components/Footer';

const SuccessThanks = () => {

    return (
        <div >
            <Header name='Subscription' />
            <div className='w-100 d-flex align-items-center justify-content-center flex-column mb-4'>
                <p><BsCheckCircleFill className='text-primary' onClick={() => { }} size={140} /></p>
                <ConfettiExplosion />
                <p className='m-0 p-0 py-2 mt-2'>Thank you for upgrading to our Premium Plan!</p>
                <p className='col-lg-8 col-md-10 col-sm-12 text-align-center m-0 p-0'>
                    Your premium membership is now active. We're excited to have you experience the best we have to offer. You'll receive an email shortly with details on how to take full advantage of your new premium benefits. Should you have any questions or require assistance, our support team is here to help.
                </p>
                <p className='m-0 p-0 py-2 mb-4'>Enjoy your premium experience!</p>
            </div>
            <Footer/>
        </div>
    )
}

export default SuccessThanks