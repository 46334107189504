import { useEffect, useState } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import SweetAlert2 from 'react-sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import {
	getLeadStatus,
	BASEURL,
	getLeadStatusClass,
	convertTimeIntoSselectedTZ,
} from '../../utilites';
import allActions from '../../Store/action';
// Components
import DataChart from '../../components/Dashboard/DataChart';
import SidecardWrapper from '../../components/Dashboard/SidecardWrapper';
import AppointmentsList from '../dashboard/components/AppointmentsList';
import TotalStatisticCard from './components/TotalStatisticCard';
import ChartStatisticBox from './components/ChartStatisticBox';
import CustomerDashboard from './components/CustomerDashboard';
import Announcement from './components/Announcement';
import Offcanvas from '../../components/Dashboard/Offcanvas';
import ChatUserWrapper from '../../components/Dashboard/ChatUserWrapper';
import AppointmentOffcanvas from './components/AppointmentOffcanvas';

import UserAvatar from '../../components/UserAvatar';
import Header from './components/Header';
import Footer from './components/Footer';
import Button from '../../components/forms/fields/Button';

// CSS
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

const Dashboard = () => {
	const navigate = useNavigate();
	const chartPeriods = ['week','last-week' ,'month', 'last-month', 'year', 'last-year'];
	const [selectedPeriod, setSelectedPeriod] = useState('year');
	const [onlineUsers, setOnlineUsers] = useState(0);
	const [{ LoginData }, { upcomingAppointments }] = useSelector((state) => [
		state.user,
		state.dashboard,
	]);
	//const [{ activities }] = useSelector((state) => [state.firebase]);
	const [dashboard] = useSelector((state) => [state.dashboard]);
	const [selectedAppointment, setSelectedAppointment] = useState(null);
	const [swalProps, setSwalProps] = useState({});
	// Will Remove it later
	const [lastLead, setLastLead] = useState();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!LoginData.isCustomer) {
			dispatch(allActions.dashboard.getTotalCounts());
			dispatch(allActions.dashboard.getRecentLeads());
			dispatch(allActions.dashboard.getChartData());
			dispatch(allActions.dashboard.getCalendarData());
		} else {
			loadLastLead();
		}
		// Fetch upcoming appointments
		dispatch(allActions.dashboard.getUpcomingAppointments());
	}, [LoginData.isCustomer, dispatch]);

	const loadLastLead = () => {
		axios.get(`${BASEURL}/api/leads/by-customer`).then((res) => setLastLead(res.data.data));
	};

	useEffect(() => {
		if (!LoginData.isCustomer) {
			dispatch(allActions.dashboard.getChartData(selectedPeriod));
		}
	}, [LoginData.isCustomer, selectedPeriod, dispatch]);

	useEffect(() => {
		if (LoginData.isCustomer) {
			dispatch(allActions.firebase.getRecentNotifications(LoginData.uuid));
		}
	}, [LoginData.isCustomer, LoginData.uuid, dispatch]);

	const showAlertOnTagClick = (tag_slug, tag_description) => {
		setSwalProps((prev) => ({
			...prev,
			[tag_slug]: {
				show: true,
				showDenyButton: false,
				text: tag_description || '',
				didClose: () => setSwalProps((prev) => ({ ...prev, [tag_slug]: { show: false } })),
			},
		}));
	};

	if (!LoginData.isCustomer) {
		return (
			<div className="clearfix position-relative">
				<Header name="Dashboard" />
				<div className="row g-4">
					<div className="col-md-9">
						<div className="clearfix pt-0">
							<Announcement
								condition={LoginData.authorization !== '1'}
								buttonEnabled={true}
								alertClass="warning d-flex justify-content-between"
								mainClass="clearfix w-100 mb-3"
								title="Enable Two-Factor Authentication"
								details="Help us protect your info and information about your jobs safe by enabling two-factor authentication"
								buttonLink="/dashboard/settings?enable=2fa"
								buttonText="Activate"
							/>
							<div className="row justify-content-center g-3 d-none d-md-flex">
								<TotalStatisticCard
									heading="Total earning"
									value={`$${dashboard.totalCounts.earnings.toLocaleString('en-US')}`}
									progress="0.0"
								/>
								<TotalStatisticCard
									heading="Completed jobs"
									value={dashboard.totalCounts.completed}
									progress="0.0"
								/>
								<TotalStatisticCard
									heading="Total customers"
									value={dashboard.totalCounts.leads}
									progress="0.0"
								/>
							</div>
							<div className="clearfix mt-3">
								<div className="card h-100 border-1 border-light rounded mt-3 mt-md-0 mb-3">
									<div className="d-flex align-items-center justify-content-between py-3 px-4">
										<div className="float-none float-md-start">
											<h6 className="fw-bold m-0">Analytics</h6>
										</div>
										<div className="float-none float-md-end">
											<select
												className="form-select rounded-1"
												value={selectedPeriod}
												onChange={(e) => setSelectedPeriod(e.target.value)}
											>
												{chartPeriods?.map((period) => (
													<option key={period} value={period}>
														{console.log(period)}
														{period === 'last-year' ? 'Last year' : period==='last-week'? "Last week" : period==='last-month'? "Last Month" :` This ${period}`}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className="cleafix charthead text-center border-top border-bottom border-light">
										{dashboard.chartData && (
											<div className="row g-2 g-md-4 px-3">
												<ChartStatisticBox
													value={Object.values(dashboard.chartData.leadsCountData).reduce(
														(a, b) => a + b,
														0
													)}
													caption="Customers"
												/>
												<ChartStatisticBox
													value={`$${Object.values(dashboard.chartData.earningsData || {})
														.reduce((a, b) => a + b, 0)
														.toLocaleString('en-US')}`}
													caption="Earning"
												/>
												<ChartStatisticBox
													value={Object.values(dashboard.chartData.leadsCountDataCompleted).reduce(
														(a, b) => a + b,
														0
													)}
													caption="Completed"
												/>
												<ChartStatisticBox value="0" caption="Total ratio" />
											</div>
										)}
									</div>
									<div className="clearfix text-center p-1" style={{ height: 270 }}>
										<DataChart chartData={dashboard.chartData} />
									</div>
								</div>
							</div>
							<div className="clearfix mt-5">
								<div className="clearfix">
									<div className="clearfix">
										<div className="clearfix">
											<div className="float-start align-middle">
												<h6 className="fw-bold">Recent Customers</h6>
											</div>
										</div>
										<div className="table-responsive">
											<table className="table">
												<thead>
													<tr>
														<th scope="col">ID</th>
														<th scope="col">Customer</th>
														<th scope="col">Interest</th>
														<th scope="col" className="text-center">
															Status
														</th>
														<th scope="col" className="text-center">
															Actions
														</th>
													</tr>
												</thead>
												<tbody>
													{dashboard.recentLeads.length === 0 ? (
														<tr>
															<td className="text-center opacity-50" colSpan={5}>
																There are no jobs available at this time
															</td>
														</tr>
													) : (
														dashboard.recentLeads?.map((lead) => {
															return (
																<tr className="align-middle" key={lead.id}>
																	<th scope="row">{lead.id}</th>
																	<td className="text-md-start text-center">
																		<Link
																			to={`/dashboard/leads/${lead.uuid}`}
																			className="text-decoration-none"
																		>
																			<UserAvatar
																				className="rounded-circle"
																				avatar={lead.user?.avatar}
																				alt={`${lead.user?.firstname} ${lead.user?.lastname}`}
																				style={{ width: 35, height: 35 }}
																			/>
																			<span className="text-dark ps-1">{`${lead.user?.firstname} ${lead.user?.lastname}`}</span>
																		</Link>
																		{lead?.tags && lead?.tags.length !== 0 ? (
																			<span
																				className="badge bg-primary ms-1 cursor-pointer"
																				onClick={() => {
																					showAlertOnTagClick(
																						lead?.tags[0].slug,
																						lead?.tags[0].description
																					);
																				}}
																			>
																				{lead?.tags[0].name}
																				<SweetAlert2 {...swalProps[lead?.tags[0].slug]} />
																			</span>
																		) : null}
																	</td>
																	<td width="40%">
																		<div className="w-75">
																			{lead.services.length > 0 &&
																				lead.services
																					?.map((service) => {
																						let result = [];
																						result.push(service.name);
																						return result;
																					})
																					.reduce((prev, curr) => [prev, ', ', curr])}
																		</div>
																	</td>
																	<td className="text-center">
																		<span className={`text-${getLeadStatusClass(lead.status)}`}>
																			{getLeadStatus(lead.status)}
																		</span>
																	</td>
																	{LoginData?.isTaxPro ? (
																		<td>
																			<div className="clearfix">
																				<Button
																					mainClass="btn btn-sm btn-outline-primary "
																					wrapperClass="clearfix d-grid text-center "
																					buttonText="Send Message"
																					callback={() =>
																						navigate('/dashboard/messages/', {
																							state: { uuid: lead?.user?.uuid },
																						})
																					}
																				/>
																			</div>
																		</td>
																	) : (
																		<td className="text-end">
																			<div className="dropdown-center">
																				<button
																					type="button"
																					className="btn btn-sm btn-outline-primary rounded-circle"
																					data-bs-toggle="dropdown"
																					aria-expanded="false"
																				>
																					<BsThreeDotsVertical className="" />
																				</button>
																				<ul className="dropdown-menu border-light">
																					<li>
																						<Link
																							className="dropdown-item"
																							to={`/dashboard/leads/${lead.uuid}`}
																						>
																							Details
																						</Link>
																					</li>
																				</ul>
																			</div>
																		</td>
																	)}
																</tr>
															);
														})
													)}
												</tbody>
											</table>
										</div>
									</div>
									<div className="clearfix px-2">
										<span className="small text-dark text-opacity-75 float-start">
											{dashboard.recentLeads.length}/{dashboard.totalCounts.leads} records
										</span>
										<Link className="text-decoration-none float-end" to="/dashboard/leads">
											&rarr; View all Leads
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="clearfix min-vh-100 h-100">
							<div className="clearfix sticky-top" style={{ zIndex: 99 }}>
								<SidecardWrapper>
									<Calendar
										className="w-100 rounded border-light bg-white border-1 p-4"
										next2Label={null}
										prev2Label={null}
										formatShortWeekday={(locale, date) =>
											['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]
										}
										tileClassName="position-relative overflow-visible rounded-circle"
										tileContent={({ date, view }) => {
											if (view === 'month') {
												const count = dashboard.calendarData.appointments?.filter((appointment) => {
													const appointmentDate = new Date(
														convertTimeIntoSselectedTZ(
															appointment?.timezone,
															moment.tz.guess(),
															moment.utc(appointment.start_date)
														)
													);
													return (
														appointmentDate.getFullYear() === date.getFullYear() &&
														appointmentDate.getMonth() === date.getMonth() &&
														appointmentDate.getDate() === date.getDate()
													);
												}).length;
												if (count > 0) {
													return (
														<Link
															to="/dashboard/appointments"
															className="badge bg-danger position-absolute translate-middle rounded-circle text-decoration-none"
															style={{ zIndex: 1, top: 3 }}
														>
															{count}
														</Link>
													);
												}
											}
										}}
									/>
								</SidecardWrapper>
								<SidecardWrapper mainClass="px-3" heading="Appointments">
									<AppointmentsList
										data={upcomingAppointments}
										setSelectedAppointment={setSelectedAppointment}
										timezone={LoginData?.settings?.timezone}
									/>
								</SidecardWrapper>
								<SidecardWrapper
									mainClass="px-3 mt-3"
									heading="Activity"
									subHeading={`Online: ${onlineUsers}`}
								>
									<ChatUserWrapper setOnlineUsers={setOnlineUsers} />
								</SidecardWrapper>
							</div>
						</div>
					</div>
				</div>
				<Footer />
				<Offcanvas
					header="Event"
					position="end"
					trigger="openAppointmentDetailsPanel"
					style={{ width: 500 }}
				>
					{selectedAppointment && (
						<AppointmentOffcanvas
							data={selectedAppointment}
							close={() => {
								document.getElementById('close-openAppointmentDetailsPanel').click();
							}}
							reload={() => dispatch(allActions.dashboard.getUpcomingAppointments())}
						/>
					)}
				</Offcanvas>
			</div>
		);
	}
	return (
		<div className="clearfix px-3">
			<CustomerDashboard LoginData={LoginData} lastLead={lastLead} reload={loadLastLead} />
		</div>
	);
};

export default Dashboard;
