const FormHeader = () => {
    return (
        <section className="clearfix bg-primary text-white text-center px-2 py-4" id="leadForm">
          <div className="container">
            <h2 className="display-6 fw-semibold">Enter your information here for a <span className="text-primary-300 text-uppercase fw-heavy">Free</span> tax savings consultation</h2>
          </div>
      </section>
    )
}

export default FormHeader