import { BASEURL } from "../../utilites";
import axios from "axios";
import { RECORDINGS_ACTION_TYPE } from "./Type";

//  Function To CREATE VIDEO ROOM
const getRecordings = (user_id) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/meeting/get-recording`,{
				params:{
					user_id
				}
			})
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: RECORDINGS_ACTION_TYPE.getRecordings,
					payload: {
						message: RECORDINGS_ACTION_TYPE.getRecordings,
						status: true,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: RECORDINGS_ACTION_TYPE.getRecordings,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};
const saveRecording = (CallSid) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/meeting/save-recording`, {
				"RecordingStatusCallbackEvent": [
					"in-progress",
					"completed",
					"absent"
				],
				"call_ssid":CallSid 
			})
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: RECORDINGS_ACTION_TYPE.saveRecording,
					payload: {
						message: RECORDINGS_ACTION_TYPE.saveRecording,
						status: true,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: RECORDINGS_ACTION_TYPE.saveRecording,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};
const playRecording = (CallSid) => {
	// const encodedToken=`${process.env.TWILIO_VOICE_ACCOUNT_SID}:${process.env.TWILIO_AUTH_TOKEN}`;
	return async (dispatch) => {
		axios
			.get(`https://api.twilio.com/2010-04-01/Accounts/AC6beb3bf48d7b2ef5d168b5f69ec0e44b/Calls/CA151203fd46d6f79e3c8f0de13eefaec0/Recordings/RE371b65e517a830030d915dca15b4bea9.jmp3`, {
				headers:{
					Authorization: 'Basic QUM2YmViM2JmNDhkN2IyZWY1ZDE2OGI1ZjY5ZWMwZTQ0YjowOGEyMzViZTZlMDlmNzg2ZGI2Y2FiNmQ1ZDJjOTQ0Mw==',
				}
			})
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: RECORDINGS_ACTION_TYPE.playRecording,
					payload: {
						message: RECORDINGS_ACTION_TYPE.playRecording,
						status: true,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: RECORDINGS_ACTION_TYPE.playRecording,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};
//  Function To CREATE VIDEO ROOM
// const getPlaidUpload = ({ VerificationObj }) => {
// 	return async (dispatch) => {
// 		dispatch({
// 			type: PLAID_ACTION_TYPE.plaidGetUpload,
// 			payload: {
// 				message: PLAID_ACTION_TYPE.plaidGetUploadFetchSucess,
// 				status: 'progress',
// 				data: {},
// 			},
// 		});
// 		axios
// 			.post(`${BASEURL}/api/dashboard/onBoard/plaid-get-upload`, VerificationObj)
// 			.then((response) => {
// 				// HANDLE RESPONSE
// 				dispatch({
// 					type: PLAID_ACTION_TYPE.plaidGetUpload,
// 					payload: {
// 						message: PLAID_ACTION_TYPE.plaidGetUploadFetchSucess,
// 						status: true,
// 						data: response?.data?.data,
// 					},
// 				});
// 			})
// 			.catch((error) => {
// 				// HANDLE ERROR MESSAGE
// 				dispatch({
// 					type: PLAID_ACTION_TYPE.plaidGetUpload,
// 					payload: {
// 						message: handleMeetingError("simple", error),
// 						status: false,
// 						data: {},
// 					},
// 				});
// 			});
// 	};
// };

// FUNCTION TO HANDLE MEETING API ERRORS
const handleMeetingError = (type, error) => {
	if (type === "simple") {
		return error.code === "ERR_NETWORK" ? error?.message : error?.response?.data?.error;
	}
};

// FUNCTION TO DISPATCH SIGNLE VALUE DIRECTLY TO STORE

const dispatchToStore = ({ type, payload }) => {
	return async (dispatch) => {
		dispatch({
			type,
			payload,
		});
	};
};

export const recordings = {
	getRecordings,
	saveRecording,
	playRecording,
	// getPlaidUpload,
	dispatchToStore,
};
