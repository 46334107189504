import { BASEURL } from "../../utilites";
import axios from "axios";
import { PLAID_ACTION_TYPE } from "./Type";

//  Function To CREATE VIDEO ROOM
const createPlaid = () => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/dashboard/onBoard/plaid-create`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: PLAID_ACTION_TYPE.plaidCreate,
					payload: {
						message: PLAID_ACTION_TYPE.plaidCreateFetchSucess,
						status: true,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: PLAID_ACTION_TYPE.plaidCreate,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};

//  Function To CREATE VIDEO ROOM
const getPlaidUpload = ({ VerificationObj }) => {
	return async (dispatch) => {
		dispatch({
			type: PLAID_ACTION_TYPE.plaidGetUpload,
			payload: {
				message: PLAID_ACTION_TYPE.plaidGetUploadFetchSucess,
				status: 'progress',
				data: {},
			},
		});
		axios
			.post(`${BASEURL}/api/dashboard/onBoard/plaid-get-upload`, VerificationObj)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: PLAID_ACTION_TYPE.plaidGetUpload,
					payload: {
						message: PLAID_ACTION_TYPE.plaidGetUploadFetchSucess,
						status: true,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: PLAID_ACTION_TYPE.plaidGetUpload,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};

// FUNCTION TO HANDLE MEETING API ERRORS
const handleMeetingError = (type, error) => {
	if (type === "simple") {
		return error.code === "ERR_NETWORK" ? error?.message : error?.response?.data?.error;
	}
};

// FUNCTION TO DISPATCH SIGNLE VALUE DIRECTLY TO STORE

const dispatchToStore = ({ type, payload }) => {
	return async (dispatch) => {
		dispatch({
			type,
			payload,
		});
	};
};

export const plaid = {
	createPlaid,
	getPlaidUpload,
	dispatchToStore,
};
