import { Link } from 'react-router-dom';

import LeadForm from '../../components/home/LeadForm';
import SEO from '../../components/SEO';

import Logo from '../../assets/img/logo-light.png';
import { useLocation } from 'react-router-dom';

const Register = () => {
	const location =useLocation()
	const queryParams = new URLSearchParams(location.search);
	const stripe_account_id = queryParams.get('pro_id');

	return (
		<div>
			<SEO title='Register' />
			<main className='bg-light min-vh-100'>
				<div className='clearfix bg-dark-gradient border-bottom border-1 py-5 px-2 mb-4 mb-md-5'>
					<div className='container'>
						<div className='clearfix w-85 mx-auto'>
							<div className='clearfix'>
								<Link to='/' className='d-block py-4'>
									<img src={Logo} alt='Logo' width={280} />
								</Link>
								<h2 className='text-white fw-bold mt-4'>
									Professional, licensed, and vetted tax{' '}
									<span className=' d-inline d-md-block'>
										advisors who will <span className='text-primary-300'>save you money</span>
									</span>
								</h2>
							</div>
						</div>
					</div>
				</div>
				<div className='RegisterPage page px-3'>
					<div className='container'>
						<div className='clearfix w-85 mx-auto'>
							<div className='row justify-content-between'>
								<div className='col-md-5'>
									<h2 className='display-4 text-dark fw-bold mb-4'>How it works</h2>
									<ol className='blurbs p-0'>
										<li className='one ps-4 mb-4'>
											<h2 className='text-dark ps-4'>
												Create your account and get matched with a tax expert.
											</h2>
										</li>
										<li className='two ps-4 mb-4'>
											<h2 className='text-dark ps-4'>
												Receive your <span className='text-primary fw-heavy'>FREE</span> tax savings
												consultation.
											</h2>
										</li>
										<li className='three ps-4 mb-4'>
											<h2 className='text-dark ps-4'>
												Get dependable tax support whenever you need it.
											</h2>
										</li>
									</ol>
								</div>
								<div className='col-md-6 position-relative'>
									<LeadForm stripe_account_id={stripe_account_id} isRegistrationPage />
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
};

export default Register;
