import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useDispatch, useSelector } from 'react-redux';
import Input from './fields/Input';
import TextArea from './fields/TextArea';
import Button from './fields/Button';
import Checkbox from './fields/Checkbox';
import allActions from '../../Store/action';
import Message from './fields/Message';
import { BASEURL, getStatesList } from '../../utilites';
import { GLOBAL_VARIABLES } from '../../utilites/variables';
import DropDown from './fields/DropDown';

const UsaStatesList = getStatesList();
const { taxProExpertises } = GLOBAL_VARIABLES;
const organizationOptions = [
	{ value: 'individual', label: 'Individual' },
	{ value: 'company', label: 'Company' },
];
const optionLlc = [
	{ value: 'sole_proprietorship', label: 'Sole proprietorship' },
	{ value: 'single_member_llc', label: 'Single-member LLC' },
	{ value: 'multi_member_llc', label: 'Multi-member LLC' },
	{ value: 'private_partnership', label: 'Private partnership' },
	{ value: 'private_corporation', label: 'Private corporation' },
	{ value: 'other', label: "Other/I'm not sure" },
];

export default function EditCurrentProBusinessInfoForm({
	userData,
	reloadUser,
	setAlert,
	services,
}) {
	const dispatch = useDispatch();
	const [{ LoginData }, { stripeConnection, stripeConnectionMessage }] = useSelector((state) => [
		state.user,
		state.dashboard,
	]);

	const [businessName, setBusinessName] = useState();
	const [phone, setPhone] = useState();
	const [street, setStreet] = useState();
	const [street2, setStreet2] = useState();
	const [city, setCity] = useState();
	const [state, setState] = useState();
	const [zipcode, setZipcode] = useState();
	const [experience, setExperience] = useState();
	const [website, setWebsite] = useState();
	const [dob, setDob] = useState();
	const [options, setOptions] = useState();
	const [expertise, setExpertise] = useState();
	const [Llc, setLlc] = useState();
	const [usIdNumber, setusIdNumber] = useState();
	const [einNumber, seteinNumber] = useState();
	const [organization, setOrganization] = useState();
	const [message, setMessage] = useState({ show: false });

	const [errors, setErrors] = useState({
		businessName: false,
		phone: false,
		street: false,
		street2: false,
		city: false,
		state: false,
		zipcode: false,
		experience: false,
		website: false,
		dob: false,
		options: false,
		expertise: false,
		general: '',
	});

	const button = useRef();

	useEffect(() => {
		// Update state with user data
		if (userData.userpro) {
			setBusinessName(userData.userpro.businessName || '');
			setPhone(userData.userpro.phone || '');
			setStreet(userData.userpro.street || '');
			setStreet2(userData.userpro.street2 || '');
			setCity(userData.userpro.city || '');
			setState(
				userData.userpro.state
					? UsaStatesList.find((s) => s.value === userData.userpro.state)
					: null
			);
			setZipcode(userData.userpro.zipcode || '');
			setExperience(userData.userpro.experience || '');
			setWebsite(userData.userpro.website || '');
			setDob(userData.userpro.dob || '');
			setOptions([...userData?.userpro?.services?.map((service) => service.id.toString())] || []);
			setExpertise(userData.userpro.expertise || []);
		}
	}, [userData.userpro]);

	// HOOKS
	// useEffectTo Set Default Data For Stripe
	useEffect(() => {
		// resetStripeStates();
		if (LoginData?.userpro?.additional !== '' && LoginData?.userpro?.additional !== null) {
			const additionalInformation = LoginData?.userpro?.additional;
			// for new stripe account
			if (additionalInformation?.business_type) {
				// if individual
				if (additionalInformation?.id_number) {
					setusIdNumber(additionalInformation.id_number);
					setOrganization(
						organizationOptions.filter((e) => e.value === additionalInformation.business_type)[0]
					);
					return;
				}
				// else comapny options
				setOrganization(
					organizationOptions.filter((e) => e.value === additionalInformation.business_type)[0]
				);
			}
			if (additionalInformation?.business_structure) {
				setLlc(optionLlc.filter((e) => e.value === additionalInformation.business_structure)[0]);
			}
			if (additionalInformation?.ein) {
				seteinNumber(additionalInformation?.ein);
			}
		}
	}, [LoginData]);

	useEffect(() => {
		if (!stripeConnection) {
			dispatch(allActions.global.Loader(false));
			button.current.disabled = false;
			setMessage({
				show: true,
				type: 'Error',
				text: stripeConnectionMessage,
			});
		}
		if (stripeConnection === true) {
			reloadUser(userData.uuid);
			setMessage({ show: false });
			button.current.disabled = false;
			dispatch(allActions.global.Loader(false));
			dispatch(allActions.dashboard.getAccountStatus());
			dispatch(allActions.dashboard.stripeConnectionReset());
			// Close Modal
			document.querySelector('.updateBusinessInfoModalClose').click();
			setAlert('Business information has been updated successfully!');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stripeConnection, stripeConnectionMessage]);
	// function to handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();

		// RESET
		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });
		setMessage({ show: false });
		dispatch(allActions.global.Loader(true));
		dispatch(allActions.dashboard.stripeConnectionReset());
		axios
			.put(`${BASEURL}/api/professionals/update-business-info`, {
				id: userData.userpro.id,
				businessName,
				phone,
				street,
				street2,
				city,
				state: state?.value || '',
				zipcode,
				experience,
				website,
				dob,
				options,
				expertise,
			})
			.then(() => {
				connectionStripe();
			})
			.catch((error) => {
				dispatch(allActions.global.Loader(false));
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};

	const connectionStripe = () => {
		let connectAccount = {};
		let personDetails = {};
		// Connect Account Object
		if (organization?.value === 'individual') {
			connectAccount = {
				business_type: 'individual',
				individual: {
					first_name: LoginData?.firstname,
					last_name: LoginData?.lastname,
					email: LoginData?.email,
					phone: phone,
					dob: {
						day: dob?.slice(8, 11),
						month: dob.slice(5, 7),
						year: dob.slice(0, 4),
					},
					address: {
						line1: street,
						city: city,
						state: state?.value || '',
						postal_code: zipcode,
					},
					id_number: usIdNumber?.toString()?.replace(/[()\s-]/g, ''),
				},
				company: {
					name: businessName,
					tax_id: usIdNumber?.toString()?.replace(/[()\s-]/g, ''),
				},
			};
		}
		if (organization?.value === 'company') {
			// Connect Account Object
			connectAccount = {
				business_type: 'company',
				company: {
					structure:Llc?.value,
					tax_id: einNumber?.replace(/[()\s-]/g, ''),
					address: {
						line1: street,
						city: city,
						state: state?.value || '',
						postal_code: zipcode,
					},
					phone,
					name: businessName,
				},
			};
			// Person Details Object
			personDetails = {
				first_name: LoginData?.firstname,
				last_name: LoginData?.lastname,
				email: LoginData?.email,
				phone: phone,
				dob: {
					day: dob?.slice(8, 11),
					month: dob.slice(5, 7),
					year: dob.slice(0, 4),
				},
				address: {
					line1: street,
					city: city,
					state: state?.value || '',
					postal_code: zipcode,
				},
				relationship: { owner: true, representative: true, director: true },
				id_number: einNumber?.replace(/[()\s-]/g, ''),
			};
		}

		connectAccount['business_profile'] = {
			mcc: 7276,
			url:
				website !== null && website !== undefined && website !== ''
					? website
					: 'https://tpg.taxreductioncompany.com',
		};
		// Function To Connect Stripe
		console.log('@555', connectAccount, personDetails);
		dispatch(allActions.dashboard.stripeConnection({ connectAccount, personDetails }));
	};

	return (
		<form className="userform needs-validation" onSubmit={handleSubmit}>
			{message.show ? <Message message={message} /> : <></>}
			<div className="mb-2">
				<Input
					type="text"
					value={businessName}
					name="businessName"
					placeholder={organization?.value === 'company' ? 'Business Name' : 'Full Name'}
					fn={setBusinessName}
					hasValidationError={errors.businessName}
					showLabel={false}
				/>
			</div>
			<div className="row g-2 mb-2">
				<div className="col-md-6">
					<DropDown
						options={organizationOptions}
						value={organization}
						// isDisabled={
						// 	LoginData?.userpro?.additional !== '' && LoginData?.userpro?.additional !== null
						// }
						setValue={(e) => {setOrganization(e)}}
						resetValues={() => {}}
						label={'Select Organization*'}
						showLabel={false}
					/>
				</div>
				{organization?.value !== 'company' && (
					<div className="col-md-6">
						<Input
							type="text"
							value={usIdNumber}
							name="SS Number"
							placeholder="SS Number"
							mask="999 99-9999"
							fn={setusIdNumber}
							showLabel={false}
						/>
					</div>
				)}
				{organization?.value === 'company' && (
					<div className="col-md-6">
						<DropDown
							options={optionLlc}
							value={Llc}
							setValue={(e) => {setLlc(e)}}
							label={'Business Structure'}
							showLabel={false}
							// isDisabled={true}
						/>
					</div>
				)}
				{organization?.value === 'company' && (
					<div className="col-md-6">
						<Input
							type="text"
							value={einNumber}
							name="EIN Number"
							placeholder="EIN Number"
							fn={seteinNumber}
							mask="999999999"
							showLabel={false}
						/>
					</div>
				)}
				<div className="col-md-6">
					<Input
						type="text"
						value={phone}
						name="phone"
						placeholder="Business Phone Number"
						fn={setPhone}
						onLeave={() => {
							if (!errors.phone && phone.length > 1 && phone.length < 14) {
								setErrors({ ...errors, phone: true });
							} else if (errors.phone) {
								setErrors({ ...errors, phone: false });
							}
						}}
						hasValidationError={errors.phone}
						mask="(999) 999-9999"
						showLabel={false}
					/>
				</div>
				<div className="col-md-6">
					<Input
						type="date"
						value={dob}
						name="dob"
						placeholder="Date of Birth"
						fn={setDob}
						hasValidationError={errors.dob}
						showLabel={false}
					/>
				</div>
			</div>
			<div className="clearfix">
				<Input
					type="text"
					value={website}
					name="website"
					placeholder="Website"
					fn={setWebsite}
					hasValidationError={errors.website}
					showLabel={false}
					required={true}
				/>
			</div>
			<fieldset className="border-top mb-3 text-start mt-4">
				<legend className="w-auto float-none px-2 ms-3 fs-6">Business Address</legend>
			</fieldset>
			<div className="row g-2 mb-2">
				<div className="col-md-9">
					<Input
						type="text"
						value={street}
						name="street"
						placeholder="Street"
						fn={setStreet}
						hasValidationError={errors.street}
						showLabel={false}
					/>
				</div>
				<div className="col-md-3">
					<Input
						type="text"
						value={street2}
						name="street2"
						placeholder="Street 2"
						fn={setStreet2}
						hasValidationError={errors.street2}
						showLabel={false}
						required={false}
					/>
				</div>
			</div>
			<div className="row g-2">
				<div className="col-md-5">
					<Input
						type="text"
						value={city}
						name="city"
						placeholder="City"
						fn={setCity}
						hasValidationError={errors.city}
						showLabel={false}
					/>
				</div>
				<div className="col-md-4">
					<ReactSelect
						id="state"
						className="p-0 basic-single"
						placeholder="State"
						closeMenuOnSelect={true}
						options={UsaStatesList}
						onChange={setState}
						value={state}
					/>
				</div>
				<div className="col-md-3">
					<Input
						type="text"
						value={zipcode}
						name="zipcode"
						placeholder="ie. 93882"
						label="Zip Code"
						fn={setZipcode}
						onLeave={() => {
							if (
								!errors.zipcode &&
								zipcode.length > 1 &&
								(zipcode.length < 5 || zipcode.length > 9)
							) {
								setErrors({ ...errors, zipcode: true });
							} else if (errors.zipcode) {
								setErrors({ ...errors, zipcode: false });
							}
						}}
						hasValidationError={errors.zipcode}
						mask="99999999"
						showLabel={false}
					/>
				</div>
			</div>
			<fieldset className="border-top mb-3 text-start mt-3">
				<legend className="w-auto float-none px-2 ms-3 fs-6">Business Details</legend>
			</fieldset>

			<div className="row">
				<div className="mb-3">
					<TextArea
						inputName="form-control"
						defaultValue={experience}
						placeholder="Experience"
						name="experience"
						fn={setExperience}
						row="7"
						required={false}
						showLabel={false}
					/>
				</div>
			</div>
			<div className="clearfix text-black">
				<div className="clearfix my-3">
					<h5 className="text-center">Expertise</h5>
				</div>
				<div className="row mt-3">
					<CreatableSelect
						id="expertise"
						className="p-0 basic-single"
						placeholder="Expertise"
						options={taxProExpertises}
						onChange={setExpertise}
						value={expertise || []}
						isMulti
						isClearable
					/>
				</div>
			</div>
			{services && (
				<div className="clearfix text-black">
					<div className="clearfix my-3">
						<h5 className="text-center">Provided services</h5>
					</div>
					<div className="row mt-3">
						{services?.map((service) => (
							<div key={service.id} className="col-md-4 mb-3">
								<Checkbox
									name="options"
									state={options}
									value={service.id}
									label={service.name}
									fn={setOptions}
									id={`option-${service.id}`}
									required={false}
								/>
							</div>
						))}
					</div>
				</div>
			)}
			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mb-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 ? ' disabled' : ''
				}`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-1"
				buttonText="Update Info"
			/>
		</form>
	);
}
