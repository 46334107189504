export default function SidecardWrapper({ 
	heading, 
	children, 
	mainClass,
	subHeading
}) {
	return (
		<div className={`card border-0 ${mainClass}`}>
			{heading && (
				<div className='card-header bg-transparent border-0 p-0 mt-4 mb-3'>
					<h6 className='card-title mb-0 fw-bold'>{heading} {subHeading && (<small className="float-end">{subHeading}</small>)}</h6>
				</div>
			)}
			<div className='card-body p-0'>{children}</div>
		</div>
	);
}
