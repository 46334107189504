import { BASEURL } from "../../utilites";
import axios from "axios";
import { CALL_ACTION_TYPE } from "./Type";

//  Function To CREATE VIDEO ROOM
const createRoom = (meetingData,callback) => {
	return async (dispatch) => {
		axios
			.put(`${BASEURL}/api/meeting/createZoomMeeting`, meetingData)
			.then((response) => {
				// HANDLE RESPONSE
				if(callback){
					callback(response?.data?.data.meetingID)
				}
				dispatch({
					type: CALL_ACTION_TYPE.videoCallRoom,
					payload: {
						message: CALL_ACTION_TYPE.videoCallRoomFetchSucess,
						status: true,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: CALL_ACTION_TYPE.videoCallRoom,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};

//  Function To CREATE VIDEO ROOM
const getZoomMeeting = (meetingId) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/meeting/getZoomMeeting/${meetingId}`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: CALL_ACTION_TYPE.getZoomMeeting,
					payload: {
						message: CALL_ACTION_TYPE.getZoomMeetingFetchSucess,
						status: true,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: CALL_ACTION_TYPE.getZoomMeeting,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};

//  Function To UPDATE VIDEO MEETING ROOM
const updateMeetingRoom = ({ meetingObj }) => {
	return async (dispatch) => {
		axios
			.patch(`${BASEURL}/api/meeting/updateZoomMeeting`, meetingObj)
			.then((response) => {
				console.log(response);
				// HANDLE RESPONSE
				dispatch({
					type: CALL_ACTION_TYPE.updateMeetingRoom,
					payload: {
						message: CALL_ACTION_TYPE.updateMeetingRoomSucess,
						status: true,
						data: response?.data.data,
					},
				});
			})
			.catch((error) => {
				console.log(error);
				// HANDLE ERROR MESSAGE
				dispatch({
					type: CALL_ACTION_TYPE.updateMeetingRoom,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};




//  Function To CREATE AUDIO CALL TOKEN
const createAudioCallToken = (user_id) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/meeting/twillio-token/:${user_id}`)
			.then((response) => {
				console.log("RESPONE>>>", response);
				// HANDLE RESPONSE
				dispatch({
					type: CALL_ACTION_TYPE.audioCallToken,
					payload: {
						status: true,
						data: response?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: CALL_ACTION_TYPE.audioCallToken,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};
// FUNCTION TO HANDLE MEETING API ERRORS
const handleMeetingError = (type, error) => {
	if (type === "simple") {
		return error?.code === "ERR_NETWORK"
			? error?.message
			: error?.response?.data?.error?.message
				? "Something Went Wrong in Video Sdk!"
				: error?.response?.data?.error;
	}
};

// FUNCTION TO DISPATCH SIGNLE VALUE DIRECTLY TO STORE

const dispatchToStore = ({ type, payload }) => {
	return async (dispatch) => {
		dispatch({
			type,
			payload,
		});
	};
};

export const call = {
	createRoom,
	getZoomMeeting,
	dispatchToStore,
	updateMeetingRoom,
	createAudioCallToken
};
