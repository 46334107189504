/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import DropDown from '../../fields/DropDown';
import TextArea from '../../fields/TextArea';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';
import { HandleFormData } from '../../../../utilites';

const TaxPayer = () => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const dispatch = useDispatch();
	const { prefix, identificationOption, otherQuestions, usStates } = GLOBAL_VARIABLES;

	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 0 });

	const [{ taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);

	const [dob, setDob] = useState();
	const [ssnitin, setSsnitin] = useState();
	const [lastName, setLastName] = useState();
	const [comments, setComments] = useState();
	const [firstName, setFirstName] = useState();
	const [issueDate, setIssueDate] = useState();
	const [middleName, setMiddleName] = useState();
	const [occupation, setOccupation] = useState();
	const [phoneNumber, setphoneNumber] = useState();
	const [emailAddress, setEmailAddress] = useState();
	const [expirationDate, setExpirationDate] = useState();
	const [identificationNo, setIdentificationNo] = useState();

	// For dropdowns, initialize with null or your initial value.
	const [myprefix, setMyPrefix] = useState(null);
	const [insurance, setInsurance] = useState(null);

	// For Choice Questions
	const [identificationType, setIdentificationType] = useState(null);
	const [specificQuestions, setSpecificQuestions] = useState([]);

	const [errors, setErrors] = useState({}); // Track errors for form fields
	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	useEffect(() => {
		setSpecificQuestions(JSON.parse(JSON.stringify(otherQuestions)));
	}, []);

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.taxPayerForm) {
			if (Object.keys(taxPayerForm?.data?.taxPayerForm).length === 0) return;
			const {
				dob,
				firstName,
				ssnitin,
				lastName,
				myprefix,
				comments,
				insurance,
				issueDate,
				middleName,
				occupation,
				phoneNumber,
				emailAddress,
				expirationDate,
				identificationNo,
				specificQuestions,
				identificationType,
			} = taxPayerForm?.data?.taxPayerForm;

			setDob(dob);
			setFirstName(firstName);
			setSsnitin(ssnitin);
			setLastName(lastName);
			setMyPrefix(myprefix);
			setComments(comments);
			setInsurance(insurance);
			setIssueDate(issueDate);
			setMiddleName(middleName);
			setOccupation(occupation);
			setphoneNumber(phoneNumber);
			setEmailAddress(emailAddress);
			setExpirationDate(expirationDate);
			setIdentificationNo(identificationNo);
			setSpecificQuestions(specificQuestions);
			setIdentificationType(identificationType);
		}
	}, [taxPayerForm?.data?.taxPayerForm]);

	// ---------------------------------------
	// Functions
	// ---------------------------------------

	const handleQuestions = (index, bool) => {
		console.log(index, bool);
		const localSpecificQuestions = specificQuestions.slice();
		localSpecificQuestions[index].bool = bool;
		setSpecificQuestions(localSpecificQuestions);
	};

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		// Add validation rules for each field
		if (!identificationType) newErrors.identificationType = 'Please Select Identification Type';
		if (
			specificQuestions
				?.flatMap((data) => {
					return data?.bool;
				})
				.includes(null)
		) {
			newErrors.AnswerOtherQuestions = 'Please Answer All The Questions There';
		}

		setErrors(newErrors);

		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let errors = check_Validity();
		if (errors) return;

		let FormObj = {
			dob,
			firstName,
			ssnitin,
			lastName,
			myprefix,
			comments,
			insurance,
			issueDate,
			middleName,
			occupation,
			phoneNumber,
			emailAddress,
			expirationDate,
			identificationNo,
			specificQuestions,
			identificationType,
		};

		let formData = taxPayerForm;
		taxPayerForm.data.taxPayerForm = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<small className="ml-4 fw-bold">TaxPayer Name</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="firstName"
						value={firstName}
						placeholder="First Name"
						fn={setFirstName}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="middleName"
						value={middleName}
						placeholder="Middle Initial"
						fn={setMiddleName}
						required={false}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="lastName"
						value={lastName}
						placeholder="Last Name"
						fn={setLastName}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<DropDown
						options={prefix}
						setValue={(e) => {
							setMyPrefix(e);
						}}
						value={myprefix}
						label={'Select Prefix'}
						required={false}
					/>
				</div>
			</div>
			<small className="ml-4 fw-bold">TaxPayer Contact</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3  mb-3 custom-left-form">
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="phoneNumber"
						placeholder="Best Contact Phone No"
						mask="(999) 999-9999"
						value={phoneNumber}
						fn={setphoneNumber}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="emailAddress"
						placeholder="Email Address"
						value={emailAddress}
						fn={setEmailAddress}
					/>
				</div>
			</div>
			<small className="ml-4 fw-bold">TaxPayer Other Information</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3 mb-3 custom-left-form">
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="ssnitin"
						placeholder="SSN/ITIN"
						value={ssnitin}
						fn={setSsnitin}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="occupation"
						placeholder="Occupation"
						value={occupation}
						fn={setOccupation}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="dob"
						placeholder="Date Of Birth (MM-DD-YYYY)"
						mask={'99-99-9999'}
						value={dob}
						fn={setDob}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="identificationNo"
						placeholder="Identification No"
						value={identificationNo}
						fn={setIdentificationNo}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<DropDown
						options={usStates}
						value={insurance}
						label={'State of Insurance'}
						setValue={(e) => setInsurance(e)}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="issueDate"
						placeholder="Issue Date (MM-DD-YYYY)"
						value={issueDate}
						fn={setIssueDate}
						mask={'99-99-9999'}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="expirationDate"
						placeholder="Expiration Date (MM-DD-YYYY)"
						value={expirationDate}
						fn={setExpirationDate}
						mask={'99-99-9999'}
					/>
				</div>
				<div className=" mb-3 px-3">
					<small className="text-dark fw-bold m-0" htmlFor={`identificationType`}>
						Identification type
					</small>
					{identificationOption?.map((data, i) => {
						return (
							<div key={i} className="w-full d-flex align-items-start justfy-content-start mt-2">
								<input
									checked={identificationType?.question === data?.question}
									className="form-check-input"
									type="radio"
									id={`identificationType${i}`}
									name={`identificationType`}
									onChange={() => setIdentificationType(data)}
								/>
								<label
									htmlFor={`identificationType${i}`}
									role="button"
									className="form-check-label text-start px-3 small"
								>
									{data.question}
								</label>
							</div>
						);
					})}
				</div>
			</div>
			<small className="ml-4 fw-bold">TaxPayer-specific Questions</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<div className=" mb-3 px-3">
					<small className="text-dark fw-bold m-0">Please answer the following</small>
					{specificQuestions?.map((data, i) => {
						return (
							<div
								key={i}
								className="w-100 d-flex flex-row align-items-center justify-content-between mt-2"
							>
								<small className="col-md-9 form-check-label text-start" htmlFor={`checkbox-${i}`}>
									{data.question}
								</small>
								<div className="col-md-3">
									<input
										onChange={() => handleQuestions(i, true)}
										checked={data?.bool === true}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 176}`}
										name={`checkbox-${i + 176}`}
									/>
									<label htmlFor={`checkbox-${i + 176}`} role="button" className="px-3 m-0 small">
										Yes
									</label>
									<input
										onChange={() => handleQuestions(i, false)}
										checked={data?.bool === false}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 999}`}
										name={`checkbox-${i + 999}`}
									/>
									<label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
										No
									</label>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<small className="ml-4 fw-bold">Comments</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3 mb-3 custom-left-form">
				<small className="text-dark fw-bold m-0 py-2">
					Enter Any Additional Information / Question here.
				</small>
				<div className="col-md-8 mb-3">
					<TextArea
						inputName="form-control"
						defaultValue={comments}
						placeholder="Comments"
						name="comments"
						fn={setComments}
						row="6"
						required={false}
					/>
				</div>
			</div>
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default TaxPayer;
