import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { HandleFormData } from '../../../../utilites';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';
import DatePicker from 'react-datepicker'

const FederalPrioryear = () => {

    const { federalPriorYear, federalPriorYearStyles } = GLOBAL_VARIABLES;

    const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 18, redirect: '/dashboard' });
    const dispatch=useDispatch()
    const [{ taxPayerForm, taxPayerFormData, formInformation }, { LoginData }] = useSelector((state) => [state.taxForms, state.user]);

    const [show,setShow]=useState({zero:null,one:null})
    const [specificQuestions, setSpecificQuestions] = useState([]);
    const [specificQuestionsStyles, setSpecificQuestionsStyles] = useState([]);

    const [errors, setErrors] = useState({}); // Track errors for form fields


    useEffect(() => {
        setSpecificQuestions(JSON.parse(JSON.stringify(federalPriorYear)));
        setSpecificQuestionsStyles(JSON.parse(JSON.stringify(federalPriorYearStyles)));
    }, [federalPriorYear, federalPriorYearStyles]);

    const handleRadioChange = (index, value) => {
        if (index === 0) {
            setShow({...show,zero:value})
        }
        else{
            setShow({...show,one:value})
        }
        const updatedQuestions = [...specificQuestions];
        updatedQuestions[index].bool = value;
        setSpecificQuestions(updatedQuestions);
    };
    
    const handleInputChange = (index, value, data, valIdx) => {
        const filedKey = Object.keys(data).find(key => key !== 'placeholder' && key !== 'styleProp');
        const updatedQuestions = [...specificQuestions];
        updatedQuestions[index].values[valIdx][filedKey] = value;
        setSpecificQuestions(updatedQuestions);
    };

    useEffect(() => {
		if (taxPayerForm?.data?.FederalPrioryear) {
			if (Object.keys(taxPayerForm?.data?.FederalPrioryear).length === 0) return;
			const {
				specificQuestions
			} = taxPayerForm?.data?.FederalPrioryear;
			setSpecificQuestions(specificQuestions);
		}
	}, [taxPayerForm?.data?.FederalPrioryear]);

    const check_Validity = () => {
        setErrors({});
        const newErrors = {};
    
        // Add more validation rules as needed for other fields
        if (
            specificQuestions
                ?.flatMap((data) => {
                    return data?.bool;
                })
                .includes(null)
        ) {
            newErrors.AnswerOtherQuestions = 'Please Select Required Option';
        }
    
        setErrors(newErrors);
    
        let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
        // Check if there are any errors
        return ConditionCheck;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = check_Validity();
        if (errors) return;
        
        let FormObj = {
			specificQuestions
		};
		let formData = taxPayerForm;
		taxPayerForm.data.FederalPrioryear = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

        // last one to submit
        let obj = {
			service_id: 1,
			method: 'upload',
			formName: formInformation?.name,
			formData: taxPayerForm.data,
		};
		if (LoginData?.isTaxPro || LoginData?.isAdmin || LoginData?.isAccountManager)
			obj.lead_id = formInformation?.lead_id;

		changeLoading(true);
		dispatch(allActions.dashboard.resetTaxPreparation());
		dispatch(allActions.dashboard.taxPreparation({ obj }));
        // 

		uploadFormData(formData);
    };

    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 18;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};
    const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};


    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                {specificQuestions.map((data, i) => {
                    return (
                    <>
                            
                       {(i>=0&&i<=2)&& <small className="ml-4 fw-bold">
                        {data.question}
                        
                        {data.bool===null && (
                           <span className="text-danger small-text mx-1">*Required</span>
                        )}
                        </small>}
                       {/* {(i>=1&&i<=4)&& <small className="ml-4 fw-bold">{data.question}</small>} */}
                        
                      {(i>=0&&i<=2)&& (<div className="col-md-12">
                            <input
                                onChange={() => handleRadioChange(i,true)}
                                checked={specificQuestions[i].bool === true}
                                className="form-check-input"
                                type="radio"
                                id={`checkbox-${i + 176}`}
                                name={`checkbox-${i + 176}`}
                            />
                            <label htmlFor={`checkbox-${i + 176}`} role="button" className="px-3 m-0 small">
                                Yes
                            </label>
                            <input
                                onChange={() => handleRadioChange(i,false)}
                                checked={specificQuestions[i].bool === false}
                                className="form-check-input"
                                type="radio"
                                id={`checkbox-${i + 999}`}
                                name={`checkbox-${i + 999}`}
                            />
                            <label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
                                No
                            </label>
                        </div>)}
                        
                               {i>2&& <> 
                                    {data?.values?.map((data, index) => {
                                        return (
                                            ((i===5&&index===0)||(i===8&&index===0))?
                                            <div key={index} className={`my-1 col-lg-6 col-md-6 mt-1 col-sm-12 ${((i===5&&index===0)||(i===8&&index===0))? 'mt-3' : ''}`}>
                                          <small>{specificQuestionsStyles?.[i]?.valData[index]?.placeholder}</small>
                                         <DatePicker
                                             className="form-select"
                                             style={{ width: 100 }}
                                             wrapperClassName="d-block"
                                             selected={new Date(specificQuestions[i].values[index][Object.keys(data).find(key => key !== 'placeholder' && key !== 'styleProp')])}
                                             onChange={(date) => handleInputChange(i, date, data, index)}
                                             dateFormat="MMMM d, yyyy"
                                             placeholderText={specificQuestionsStyles?.[i]?.valData[index]?.placeholder}
                                         />
                                     </div>:
                                            <div className={`mt-1 ${specificQuestionsStyles?.[i]?.valData[index]?.styleProp}`}>
                                                <Input
                                                    type="text"
                                                    value={specificQuestions[i].values[index][Object.keys(data).find(key => key !== 'placeholder' && key !== 'styleProp')]}
                                                    placeholder={specificQuestionsStyles?.[i]?.valData[index]?.placeholder}
                                                    fn={(value) => handleInputChange(i, value, data, index)}
                                                    mask={specificQuestionsStyles?.[i]?.valData[index]?.mask}
                                                />
                                            </div>)
                                    })}
                                </>}
                    </>
                    )
                })}
            </div>
            {Object.keys(errors)
    ?.map((key) => {
        return errors[key];
    })
    ?.map((data, index) => {
        return (
            <>
                <small className="text-primary" key={index}>
                    {data}*
                </small>
                <br />
            </>
        );
    })}
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="button"
                        callback={()=>goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text w-100"
                        buttonText="Submit"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default FederalPrioryear;