/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';

const TaxFreeRental = () => {
	

	const dispatch=useDispatch()
	const [{ taxPayerForm, taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 8 });

    const [meetingRoomCost, setMeetingRoomCost]=useState();
    const [holdMeetings, setHoldMeetings]=useState();
    const [willingToMeeting, setWillingToMeeting]=useState(null);

	const [errors, setErrors] = useState({}); // Track errors for form fields

	useEffect(() => {
    	if (taxPayerForm?.data?.TaxFreeRental) {
    		if (Object.keys(taxPayerForm?.data?.TaxFreeRental).length === 0) return;
			const {
				meetingRoomCost,
				holdMeetings,
				willingToMeeting
			} = taxPayerForm?.data?.TaxFreeRental;
			setMeetingRoomCost(meetingRoomCost)
			setHoldMeetings(holdMeetings)
			setWillingToMeeting(willingToMeeting)
    	}
    }, [taxPayerForm?.data?.TaxFreeRental]);

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};
	
		// Add more validation rules as needed for other fields
		if (
			willingToMeeting===null) {
			newErrors.AnswerOtherQuestions = 'Please Select Required Option';
		}
	
		setErrors(newErrors);
	
		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		let errors = check_Validity();
    if (errors) return;
	
		let FormObj = {
			meetingRoomCost,
			holdMeetings,
			willingToMeeting
		};
        let formData = taxPayerForm;
        taxPayerForm.data.TaxFreeRental = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));
        uploadFormData(formData);
    };
	
	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 8;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			{/* <small className="ml-4 fw-bold">General Information</small>
			<hr className="m-0" /> */}
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<div className="col-md-12 mb-3">
					<Input
						type="text"
						name="enityName"
						value={holdMeetings}
						placeholder="How many days in 2021 would you be willing to hold these business-related meetings in your home?"
						fn={setHoldMeetings}
					/>
				</div>
				<div className="col-md-12 mb-3">
					<Input
						type="text"
						name="ownBusinessPercentage"
						value={meetingRoomCost}
						placeholder="How much does a meeting room cost for a day in your local area?"
						fn={setMeetingRoomCost}
						mask={"9999999"}
					/>
				</div>
                <div className="mt-3 row justify-content-between px-3 custom-left-form">
                <div className=" mb-3 px-3">
                    <small className="text-dark fw-bold m-0">Please answer the following</small>
                    <div
                        className="w-100 mt-2"
                    >
                        <small className="col-md-12 form-check-label text-start" htmlFor={`checkbox`}>
                        Would you be willing to have meetings in your personal residence in 2021 that are business-related?
                        {willingToMeeting===null && (
    <span className="text-danger small-text mx-1">*Required</span>
)}
						</small>
                        <div className="col-md-12">
                            <input
                                onChange={() => setWillingToMeeting(true)}
                                checked={willingToMeeting === true}
                                className="form-check-input"
                                type="radio"
                                id={`checkbox-div2`}
                                name={`checkbox-div2`}
                            />
                            <label htmlFor={`checkbox-div2`} role="button" className="px-3 m-0 small">
                                Yes
                            </label>
                            <input
                                onChange={() => setWillingToMeeting(false)}
                                checked={willingToMeeting === false}
                                className="form-check-input"
                                type="radio"
                                id={`checkbox-div1`}
                                name={`checkbox-div1`}
                            />
                            <label htmlFor={`checkbox-div1`} role="button" className="px-3 m-0 small">
                                No
                            </label>
                        </div>
                    </div>
                </div>
            </div>
			</div>
			{Object.keys(errors)
    ?.map((key) => {
        return errors[key];
    })
    ?.map((data, index) => {
        return (
            <>
                <small className="text-primary" key={index}>
                    {data}*
                </small>
                <br />
            </>
        );
    })}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button mt-4'}
						type="button"
						callback={()=>goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default TaxFreeRental;
