import { Fragment, useState } from 'react';
import moment from 'moment';
import { MdCloudUpload } from 'react-icons/md';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { bytesToKilobytes } from '../../../utilites';
import UserAvatar from '../../../components/UserAvatar';
import Modal from '../../../components/Dashboard/Modal';
import ChooseDocumentForm from '../../../components/forms/ChooseDocumentForm';
import AddDocumentForm from '../../../components/forms/AddDocumentForm';

export default function DocumentsList({ data, userpro, userData, reloadData }) {
	const [documentToUpload, setDocumentToUpload] = useState(null);
	const documentsToUpload = [];
	data?.forEach((item) => {
		item?.documents.forEach((document) => {
			if (!document.path) {
				documentsToUpload.push(document);
			}
		});
	});
	// Sort documents by updated_at
	documentsToUpload.sort((a, b) => {
		return new Date(b.updated_at) - new Date(a.updated_at);
	});
	return (
		<ul className="list-group list-group-flush">
			{documentsToUpload.length > 0 ? (
				documentsToUpload?.map((document) => (
					<li className="list-group-item" key={document.id}>
						<div className="row">
							<div className="col-2 px-2">
								<div
									className="text-center text-black bg-secondary rounded p-2 cursor-pointer"
									data-bs-toggle="modal"
									data-bs-target="#uploadDocumentModal"
									onClick={() =>
										setDocumentToUpload({
											...document,
											...{
												userProUuid: userpro?.user?.uuid,
											},
										})
									}
								>
									<MdCloudUpload />
								</div>
							</div>
							<div className="col px-3">
								<p className="text-black fw-bold m-0">{document.name}</p>
								<p className="text-muted m-0">
									{(document?.extension || document?.size) && (
										<>
											{document?.extension
												? document?.extension?.replace('.', '')?.toUpperCase()
												: ''}
											, {document?.size ? bytesToKilobytes(document?.size) + ' KB' : ''}
										</>
									)}
								</p>
								<div className="my-2">
									<div className="badge bg-primary">
										Updated {moment(document.updated_at).fromNow()}
									</div>
								</div>
								{userpro ? (
									<div className="d-flex align-items-center">
										<UserAvatar
											avatar={userpro.user.avatar}
											alt={`${userpro.user.firstname} ${userpro.user.lastname}`}
											className="img-fluid object-fit-cover rounded-circle"
											style={{ width: 25, height: 25 }}
										/>
										<p className="card-text text-black ms-2 mb-0">{`${userpro.user.firstname} ${userpro.user.lastname}`}</p>
									</div>
								) : null}
							</div>
							<div className="btn-group px-0" style={{ width: 30 }}>
								<button
									type="button"
									className="btn btn-sm border-1 border-grey-200 text-grey-200 rounded-circle disabled"
									data-bs-toggle="dropdown"
									aria-expanded="false"
									style={{ width: 30, height: 30 }}
								>
									<BsThreeDotsVertical />
								</button>
								<ul className="dropdown-menu">
									<li>
										<a className="dropdown-item" href="##">
											Action
										</a>
									</li>
								</ul>
							</div>
						</div>
					</li>
				))
			) : (
				<p className="text-muted text-center m-0">
					Your Tax Professional has not requested any new documents.
				</p>
			)}
			<Modal
				title="Upload Document"
				bodyClass="px-5 py-4 text-center"
				trigger="uploadDocumentModal"
				showBtn={false}
				btnClass="addDocumentModalClose"
				size="lg"
			>
				{documentToUpload ? (
					<Fragment>
						<nav>
							<div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
								<button
									className="nav-link active"
									id="nav-existing-tab"
									data-bs-toggle="tab"
									data-bs-target="#nav-existing"
									type="button"
									role="tab"
									aria-controls="nav-existing"
									aria-selected="true"
								>
									Choose an existing file
								</button>
								<button
									className="nav-link"
									id="nav-fromComputer-tab"
									data-bs-toggle="tab"
									data-bs-target="#nav-fromComputer"
									type="button"
									role="tab"
									aria-controls="nav-fromComputer"
									aria-selected="false"
								>
									Upload from computer
								</button>
							</div>
						</nav>
						<div className="tab-content mt-3" id="nav-tabContent">
							<div
								className="tab-pane show active"
								id="nav-existing"
								role="tabpanel"
								aria-labelledby="nav-existing-tab"
								tabIndex="0"
							>
								<ChooseDocumentForm
									userId={userData.id}
									documentId={documentToUpload.id}
									reloadDocuments={reloadData}
									targetUserUuid={documentToUpload?.userProUuid}
								/>
							</div>
							<div
								className="tab-pane"
								id="nav-fromComputer"
								role="tabpanel"
								aria-labelledby="nav-fromComputer-tab"
								tabIndex="0"
							>
								<AddDocumentForm
									isModal={true}
									userId={userData.id}
									multiple={false}
									documentId={documentToUpload.id}
									reloadDocuments={reloadData}
									showDocumentNameField={false}
									targetUserUuid={documentToUpload?.userProUuid}
								/>
							</div>
						</div>
					</Fragment>
				) : null}
			</Modal>
		</ul>
	);
}
