import { Helmet } from 'react-helmet-async';

const SEO = ({
	title,
	description = 'Uncle Kam App is a web application that allows users to submit their tax information and get linked with a tax professional.',
	keywords = 'tax, uncle kam, tax professional, tax app, tax application, tax software, tax software app, tax software application, tax software web app, tax software web application, tax web app, tax web application,',
	image = '/logo512.png',
	url = 'https://unclekam.com',
}) => {
	return (
		<Helmet prioritizeSeoTags>
			<title>Uncle Kam{title ? ` - ${title}` : ''}</title>
			<meta name='description' content={description} />
			<meta name='keywords' content={keywords} />
			<meta property='og:title' content={`Uncle Kam${title ? ` - ${title}` : ''}`} />
			<meta property='og:description' content={description} />
			<meta property='og:image' content={image} />
			<meta property='og:url' content={url} />
		</Helmet>
	);
};

export default SEO;
