export const DASHBOARD_ACTION_TYPE = {
	services: 'services',
	GET_USER_ROLES: 'GET_USER_ROLES',
	GET_TAX_PROFESSIONALS: 'GET_TAX_PROFESSIONALS',
	TOTAL_COUNTS: 'TOTAL_COUNTS',
	CHART_DATA: 'CHART_DATA',
	RECENT_LEADS: 'RECENT_LEADS',
	CALENDAR_DATA: 'CALENDAR_DATA',
	LEADS_FETCHING_START: 'LEADS_FETCHING_START',
	DISPUTES_FETCHING_START: 'DISPUTES_FETCHING_START',
	JOBS_FETCHING_START: 'JOBS_FETCHING_START',
	PROJECTS_FETCHING_START: 'PROJECTS_FETCHING_START',
	USERS_FETCHING_START: 'USERS_FETCHING_START',
	DOCUMENTS_FETCHING_START: 'DOCUMENTS_FETCHING_START',
	APPOINTMENTS_FETCHING_START: 'APPOINTMENTS_FETCHING_START',
	LEADS: 'LEADS',
	DISPUTES: 'DISPUTES',
	JOBS: 'JOBS',
	PROJECTS: 'PROJECTS',
	USERS: 'USERS',
	ALL_USERS: 'ALL_USERS',
	DOCUMENTS: 'DOCUMENTS',
	FOLDERS: 'FOLDERS',
	CUSTOM_FOLDERS: 'CUSTOM_FOLDERS',
	APPOINTMENTS: 'APPOINTMENTS',
	UPCOMING_APPOINTMENTS: 'UPCOMING_APPOINTMENTS',
	USERS_FOR_APPOINTMENT: 'USERS_FOR_APPOINTMENT',
	EXISTING_DOCUMENTS: 'EXISTING_DOCUMENTS',
	AVATARS: 'AVATARS',
	DOCUMENT_NAMES: 'DOCUMENT_NAMES',
	ACCOUNT_MANAGERS: 'ACCOUNT_MANAGERS',
	ACTIVITIES: 'ACTIVITIES',
	RESET: 'RESET',
	stripeConnection: 'stripeConnection',
	stripeConnectionMessage: 'stripeConnectionMessage',
	stripeConnectionIdle: 'stripeConnectionIdle',
	stripeAccountStatus: 'stripeAccountStatus',
	stripeAccountStatusIdle: 'stripeAccountStatusIdle',
	onBoardUpdateStatus: 'onBoardUpdateStatus',
	onBoardUpdateStatusIdle: 'onBoardUpdateStatusIdle',
	allUsersIdle: 'allUsersIdle',
	AVAILABILITY: 'AVAILABILITY',
	AVAILABILITYIDLE: 'AVAILABILITYIDLE',
	AVAILABILITY_SUCCESS: 'Availability Update Successfully!',
	PROFILE: 'PROFILE',
	PROFILEIDLE: 'PROFILEIDLE',
	PROFILE_SUCCESS: 'Profile Update Successfully!',
	customerModalDone: 'customerModalDone',
	taxPlanning: 'taxPlanning',
	taxPlanningIdle: 'taxPlanningIdle',
	taxPreparation: 'taxPreparation',
	taxPreparationIdle: 'taxPreparationIdle',
	leadByUUID: 'leadByUUID',
	leadByUUIDIdle: 'leadByUUIDIdle',

	// calendly
	calendlyVerificarionSuccess: 'Calendly Verification Completed Successfully',
	calendlyVerificarionIDLE: 'Verification IDLE',
	calendlyVerificarionError: 'Calendly Verification Error',

	// outlook calendar 
	outlookCalendarVerificarionSuccess: 'Outlook Verification Completed Successfully',
	outlookCalendarVerificarionIDLE: ' Outlook Verification IDLE',
	outlookCalendarVerificarionError: 'Outlook  Verification Error',

	// google calendar
	googleCalendarVerificationSuccess: 'Google Calendar Verification Completed Successfully',
	googleCalendarVerificationIDLE: ' Google Calendar  Verification IDLE',
	googleCalendarVerificationError: 'Google Calendar Verification Error',

	USER_APPOINTMENT: 'USER_APPOINTMENT',

	createFileUpload:'createFileUpload',
	updateJobStatusByTaxPro:'updateJobStatusByTaxPro',
	createFileUploadIdle:'createFileUploadIdle',
	updateJobStatusByTaxProIdle:'updateJobStatusByTaxProIdle',

	
};
