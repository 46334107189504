/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';

const RanDTaxCredits = () => {
   
    const dispatch=useDispatch()
	const [{ taxPayerForm, taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 27 });

    const [businessDepricate, setBusinessDepricate] = useState(null);
    const [projectedDevelopmentCost, setProjectedDevelopmentCost] = useState();
    const [estimatedCreditPercentages, setEstimatedCreditPercentages] = useState();

    const [errors, setErrors] = useState({}); // Track errors for form fields

    useEffect(() => {
    	if (taxPayerForm?.data?.RanDTaxCredits) {
    		if (Object.keys(taxPayerForm?.data?.RanDTaxCredits).length === 0) return;
			const {
                businessDepricate,
                projectedDevelopmentCost,
                estimatedCreditPercentages
			} = taxPayerForm?.data?.RanDTaxCredits;
            setBusinessDepricate(businessDepricate)
            setProjectedDevelopmentCost(projectedDevelopmentCost)
            setEstimatedCreditPercentages(estimatedCreditPercentages)
    	}
    }, [taxPayerForm?.data?.RanDTaxCredits]);
    
    const check_Validity = () => {
        setErrors({});
        const newErrors = {};
    
        // Add more validation rules as needed for other fields
        if (
            businessDepricate===null) {
            newErrors.AnswerOtherQuestions = 'Please Select Required Option';
        }
    
        setErrors(newErrors);
    
        let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
        // Check if there are any errors
        return ConditionCheck;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = check_Validity();
    if (errors) return;

        let FormObj = {
            businessDepricate,
            projectedDevelopmentCost,
            estimatedCreditPercentages
		};
        let formData = taxPayerForm;
        taxPayerForm.data.RanDTaxCredits = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));
        uploadFormData(formData);
    };

    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 27;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="projectedDevelopmentCost"
                        value={projectedDevelopmentCost}
                        placeholder="What is the projected research and development costs?"
                        fn={setProjectedDevelopmentCost}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="estimatedCreditPercentages"
                        value={estimatedCreditPercentages}
                        placeholder="What is the estimated research and development credit percentage?"
                        fn={setEstimatedCreditPercentages}
                        mask={"999999999"}
                    />
                </div>
            </div>
            <div
                className="w-100 mt-2"
            >
                <small className="col-md-8 form-check-label text-start" htmlFor={`checkbox`}>
				Did the business participate in any research and development (R&D) activities or perform an R&D tax credit study?
                {businessDepricate===null && (
                    <span className="text-danger small-text mx-1">*Required</span>
                )}
                </small>
                <div className="col-md-4">
                    <input
                        onChange={() => setBusinessDepricate(true)}
                        checked={businessDepricate === true}
                        className="form-check-input"
                        type="radio"
                        id={`checkbox-div4`}
                        name={`checkbox-div4`}
                    />
                    <label htmlFor={`checkbox-div4`} role="button" className="px-3 m-0 small">
                        Yes
                    </label>
                    <input
                        onChange={() => setBusinessDepricate(false)}
                        checked={businessDepricate === false}
                        className="form-check-input"
                        type="radio"
                        id={`checkbox-div5`}
                        name={`checkbox-div5`}
                    />
                    <label htmlFor={`checkbox-div5`} role="button" className="px-3 m-0 small">
                        No
                    </label>
                </div>
            </div>
            {Object.keys(errors)
    ?.map((key) => {
        return errors[key];
    })
    ?.map((data, index) => {
        return (
            <>
                <small className="text-primary" key={index}>
                    {data}*
                </small>
                <br />
            </>
        );
    })}
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button  mt-4'}
                        type="button"
                        callback={()=>goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default RanDTaxCredits;