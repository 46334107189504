import Header from '../components/Header';
import Footer from '../components/Footer';
import Pagination from '../../../components/Pagination';
import allActions from '../../../Store/action';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryParam, setQueryParams } from '../../../utilites';
import Message from '../../../components/forms/fields/Message';
import { formatCurrency } from '../../../utilites/functions';
const AdminBilling = () => {
	const dispatch = useDispatch();

	// const [editInvoiceObj, setEditInvoiceObj] = useState({});
	// const [editInvoice, setEditInvoice] = useState(false);
	// const [deleteInvoice, setDeleteInvoice] = useState(false);
	// const [selectedService, setselectedService] = useState('');
	// const [InvoiceName, setInvoiceName] = useState('');
	// const [InvoiceAmount, setInvoiceAmount] = useState('');
	// const [InvoiceDueDate, setInvoiceDueDate] = useState('');
	const [message, setMessage] = useState({ show: false });
	// const [listMessage, setListMessage] = useState({ show: false });
	// eslint-disable-next-line
	// const [outerMessage, setOuterMessage] = useState({ show: false });
	//   const [SelectedLead, setSelectedLead] = useState('');
	// const selectedJobId = SelectedLead?.jobs?.find(
	// 	(data) => data?.service_id === selectedService?.value
	// )?.id;
	// Filtering
	const keyword='subscription'
	// const [keyword, setKeyword] = useState('subscription');
	const [dateCreated] = useState(
		getQueryParam('created_after') ? new Date(getQueryParam('created_after')) : null
	);
	const [status] = useState(getQueryParam('status') || '');
	// Pagination
	const [perPage, setPerPage] = useState(getQueryParam('per_page') || 10);
	const [page, setPage] = useState(getQueryParam('page') || 1);
	const [
		{ LoginData },
		{
			invoices,
		},
        {getAllMembershipPlanByAdmin}
	] = useSelector((state) => [state.user, state.invoice, state.membership]);

	useEffect(() => {
        setMessage({ show: false });
        dispatch(allActions.global.Loader(true));
		dispatch(allActions.membership.getAllMembershipPlanByAdmin(keyword, status, dateCreated, page, perPage));
	}, [dispatch, keyword, status, dateCreated, page, perPage]);

    useEffect(() => {
		if (!getAllMembershipPlanByAdmin.status) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: getAllMembershipPlanByAdmin.message,
			});
		}
		if (getAllMembershipPlanByAdmin.status === true) {
			setMessage({
				show: false
			});
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, getAllMembershipPlanByAdmin]);

	const moveTo = (page) => {
		setPage(page);
		// Set query param
		setQueryParams('page', page);
	};

	const changePerPage = (perPage) => {
		setPerPage(perPage);
		// Set query param
		setQueryParams('per_page', perPage);
	};

	return (
		<div className='clearfix dashboard-Page'>
			<Header name='Membership Plan' />
            {message.show ? <Message message={message} /> : <></>}
			<div className='row justify-content-center'>
				<div className='col-md-12'>
					{/* filters */}
					<div className="clearfix mt-4">
						<div className="clearfix mt-4 mb-5">
							<div className="row justify-content-between">
								<div className="col-md-10">
									<div className="row g-3">
										{/* <div className="col-md-3">
													<Input
														type="text"
														value={keyword}
														name="keyword"
														inputClass="search-input border h-auto min-height-initial"
														showLabel={false}
														required={false}
														placeholder="Search by keyword"
														fn={filterByKeyword}
														extraSetter={() => {
															if (page !== 1) moveTo(1);
														}}
													/>
												</div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <BillingTable/> */}
					<div className="clearfix">
						<div className="card border-0">
							<div className="clearfix position-relative">
								<div className="table-responsive">
									<table className="table">
										<thead className="text-dark text-opacity-50">
											<tr>
												<th scope="col">ID</th>
												<th scope="col">Trial Start Time</th>
												<th scope="col">Trial End Time</th>
												<th scope="col">Sub Start Time</th>
												<th scope="col">Sub End Time</th>
												<th scope="col">Next Renewal</th>
												<th scope="col">Membership Price</th>
												<th scope="col">Membership Name</th>
												{/* <th className="text-center" scope="col">
													Actions
												</th> */}
											</tr>
										</thead>
										<tbody>
											{LoginData.isAdmin &&
												getAllMembershipPlanByAdmin.data.length>0 &&
												getAllMembershipPlanByAdmin?.data?.map((data, index) => {
													
													return (
														<tr key={index}>
															<th scope="row">{data?.id}</th>
															<td>
																{data?.trial_startTime || "-"}
															</td>
															<td>{data?.trial_endTime || "-"}</td>
															<td>{data?.sub_startTime || "-"}</td>
															<td>{data?.sub_endTime || "-"}</td>
															<td>
																{data?.next_renewal || "-"}
															</td>
															<td>
																{data?.membership?.membership_type?.Price === "3 Months Free Access" ? data?.membership?.membership_type?.Price : formatCurrency(data?.membership?.membership_type?.Price) || "-"}
															</td>
															<td>
																{data?.membership?.membership_name || "-"}
															</td>
															{/* <td className="text-end">
																<div className="dropdown-center">
																	

																	{!(true) && (
																		<ul className="dropdown-menu border-light">
																			<li>
																				<Link
																					onClick={() => {
																						reset_message();
																						set_EditForm(data);
																						// setEditInvoiceObj(data);
																					}}
																					className="dropdown-item"
																					to="#"
																				>
																					Edit
																				</Link>
																			</li>
																			<li>
																				<hr className="dropdown-divider border-light" />
																			</li>
																			<li>
																				<Link
																					to="#addNewInvoiceModal"
																					className="dropdown-item text-danger"
																					onClick={() => {
																						reset_message();
																						// setEditInvoice(false);
																						// setDeleteInvoice(true);
																						handleDelete(data);
																					}}
																				>
																					Delete
																				</Link>
																			</li>
																		</ul>
																	)}
																</div>
															</td> */}
														</tr>
													);
												}
                                                )}
											{invoices?.status === true && invoices?.data?.length === 0 ? (
												<tr className="text-center text-muted">
													<td colSpan={8}>You have not created any invoice yet.</td>
												</tr>
											) : (
												<></>
											)}
										</tbody>
									</table>
								</div>
							</div>
							<Pagination
								pageCount={Math.ceil(invoices.count / perPage)}
								currentPage={parseInt(page)}
								setCurrentPage={moveTo}
								perPage={perPage}
								setPerPage={(pc) => {
									changePerPage(pc);
									// Move to first page
									if (page !== 1) moveTo(1);
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default AdminBilling;