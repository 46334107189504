/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Link } from 'react-router-dom';
// Components
// Store
// Utilities
import Modal from '../Dashboard/Modal';
import RenderMultiPagePDF from '../../pages/dashboard/components/RenderMultiPagePDF';
// PDFJS worker from an external cdn
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default function ReviewDraftDocumentsForm({ drafts }) {
	const [selectedDocument, setSelectedDocument] = useState();
	return (
		<div className="documentsform needs-validation">
			{drafts && drafts.length > 0 ? (
				<div className="row py-3">
					{drafts?.map((draft, index) => (
						<div
							className="col-md-2 "
							// style={{ backgroundColor: "pink" }}
							role="button"
							data-bs-toggle="modal"
							data-bs-target="#openDocumentModal"
							aria-controls="openDocumentModal"
							data-bs-id={document.uuid}
							key={index}
							onClick={() => setSelectedDocument(draft)}
						>
							<div className="clearfix text-center border border-light p-3">
								<div className="clearfix">
									{draft?.location?.includes('.pdf') ? (
										<Document file={draft?.path}>
											<Page pageNumber={1} height={80} />
										</Document>
									) : (
										<img
											src={draft?.path}
											className="card-img-top"
											alt={draft?.name}
											style={{ height: 150, objectFit: 'cover' }}
										/>
									)}
								</div>
								<p className="text-truncate text-primary small mb-0">{draft?.name}</p>
							</div>
						</div>
					))}
				</div>
			) : null}
			<Modal
				title="Document Viewer"
				bodyClass="p-4 text-center text-dark text-opacity-50"
				trigger="openDocumentModal"
				showBtn={false}
				btnClass="documentModalClose"
				size="lg"
			>
				<div className="clearfix">
					{selectedDocument &&
						(selectedDocument?.location?.includes('.pdf') ? (
							<>
								<RenderMultiPagePDF file={selectedDocument.path} />
							</>
						) : (
							<img
								src={selectedDocument?.path}
								className="w-100 img-fluid rounded"
								alt={selectedDocument?.name}
								style={{ objectFit: 'contain' }}
							/>
						))}
					<Link
						to={selectedDocument?.path}
						className="btn btn-outline-primary btn-md d-inline-block mt-2"
						target="_blank"
						download
					>
						Download
					</Link>
				</div>
			</Modal>
		</div>
	);
}
