/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '../../fields/Button';
import DatePicker from 'react-datepicker'
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import Input from '../../fields/Input';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';

const TaxPaymentRefund = () => {

	const { TaxPaymentRefundData } = GLOBAL_VARIABLES

	const dispatch = useDispatch()
	const [{ taxPayerForm, taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 30 });

	const [howProvideReport, setHowProvideReport] = useState();
	const [specificQuestions, setSpecificQuestions] = useState([]);
	const [state1, setState1] = useState("")
	const [state2, setState2] = useState("")

	const [amount1, setAmount1] = useState("")
	const [amount2, setAmount2] = useState("")
	const [amount3, setAmount3] = useState("")

	const [date1, setDate1] = useState(new Date())
	const [date2, setDate2] = useState(new Date())

	const [errors, setErrors] = useState({}); // Track errors for form fields

	const multipleChoice = [];

	const handleQuestions = (index, bool) => {
		console.log(index, bool);
		const localSpecificQuestions = specificQuestions.slice();
		localSpecificQuestions[index].bool = bool;
		setSpecificQuestions(localSpecificQuestions);
	};

	useEffect(() => {
		setSpecificQuestions(JSON.parse(JSON.stringify(TaxPaymentRefundData)));
	}, []);

	useEffect(() => {
		if (taxPayerForm?.data?.TaxPaymentRefund) {
			if (Object.keys(taxPayerForm?.data?.TaxPaymentRefund).length === 0) return;
			const {
				howProvideReport,
				specificQuestions,
				state1,
				state2,
				amount1,
				amount2,
				amount3,
				date1,
				date2
			} = taxPayerForm?.data?.TaxPaymentRefund;
			setHowProvideReport(howProvideReport)
			setSpecificQuestions(specificQuestions)
			setState1(state1)
			setState2(state2)
			setAmount1(amount1)
			setAmount2(amount2)
			setAmount3(amount3)
			setDate1(date1)
			setDate2(date2)
		}
	}, [taxPayerForm?.data?.TaxPaymentRefund]);

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		// Add more validation rules as needed for other fields
		if (
			specificQuestions
				?.flatMap((data) => {
					return data?.bool;
				})
				.includes(null)
		) {
			newErrors.AnswerOtherQuestions = 'Please Select Required Option';
		}

		setErrors(newErrors);

		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		let errors = check_Validity();
		if (errors) return;

		let FormObj = {
			howProvideReport,
			specificQuestions,
			state1,
			state2,
			amount1,
			amount2,
			amount3,
			date1,
			date2
		};
		let formData = taxPayerForm;
		taxPayerForm.data.TaxPaymentRefund = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));
		uploadFormData(formData);
	};
	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 30;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<div className="mt-3 row justify-content-between px-3 custom-left-form">

				<div className=" mb-3 px-3">
					{specificQuestions?.map((data, i) => {
						return (
							<div key={i} className="w-100  align-items-center justify-content-between mt-2">
								<small className="col-md-12 form-check-label text-start" htmlFor={`checkbox-${i}`}>
									{data.question}
									{data.bool === null && (
										<span className="text-danger small-text mx-1">*Required</span>
									)}
								</small>
								<div className="col-md-12">
									<input
										onChange={() => handleQuestions(i, true)}
										checked={data?.bool === true}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 176}`}
										name={`checkbox-${i + 176}`}
									/>
									<label htmlFor={`checkbox-${i + 176}`} role="button" className="px-3 m-0 small">
										Yes
									</label>
									<input
										onChange={() => handleQuestions(i, false)}
										checked={data?.bool === false}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 999}`}
										name={`checkbox-${i + 999}`}
									/>
									<label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
										No
									</label>
								</div>
							</div>
						);
					})}
					{JSON.parse(JSON.stringify(multipleChoice))?.map((data, i) => {
						return (
							<div key={i} className="w-100 my-2">
								<small
									className="col-md-6 form-check-label text-start fw-bold mb-1"
									htmlFor={`howProvideReport-${i}`}
								>
									{data.question}
									{!howProvideReport && (
										<span className="text-danger small-text mx-1">*Required</span>
									)}
								</small>
								{data?.options?.map((option, index) => {
									return (
										<div>
											<input
												onChange={() => setHowProvideReport(option)}
												checked={howProvideReport === option}
												className="form-check-input"
												type="radio"
												id={`howProvideReport-${index}`}
												name={`howProvideReport-${index}`}
											/>
											<label className="px-3 small" htmlFor={`howProvideReport-${index}`}>
												{option}
											</label>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
				<div className="mb-3 px-3">
					<h6>Federal prior year overpayment applied</h6>
					<div className="row">
						<div className="col-md-6">
							<Input
								type="text"
								className="form-control"
								placeholder="State"
								value={state1}
								fn={setState1}
							/>
						</div>
						<div className="col-md-6">
							<Input
								type="text"
								className="form-control"
								placeholder="Amount"
								value={amount1}
								fn={setAmount1}
							/>
						</div>
					</div>
					<div className="row my-1">
						<div className="col-md-6 mt-3">
						<small className="ml-4 fw-bold">
                                Date
                            </small>
							<DatePicker
								className="form-select"
								style={{ width: 100 }}
								wrapperClassName="d-block"
								selected={new Date(date1)}
								onChange={(date) => setDate1(date)}
								dateFormat="MMMM d, yyyy"
								placeholderText={"select date"}
							/>
						</div>
						<div className="col-md-6 my-1">
							<Input
								type="text"
								className="form-control"
								placeholder="Amount"
								value={amount2}
								fn={setAmount2}
							/>
						</div>
					</div>
					<div className="row my-1">
						<div className="col-md-4 mt-1">
							<Input
								type="text"
								className="form-control"
								placeholder="State"
								value={state2}
								fn={setState2}
							/>
						</div>
						<div className="col-md-4 mt-3">
						<small className="ml-4 fw-bold">
                                Date
                            </small>
							<DatePicker
								className="form-select"
								style={{ width: 100 }}
								wrapperClassName="d-block"
								selected={new Date(date2)}
								onChange={(date) => setDate2(date)}
								dateFormat="MMMM d, yyyy"
								placeholderText={"select date"}
							/>
						</div>
						<div className="col-md-4 mt-1">
							<Input
								type="text"
								className="form-control"
								placeholder="Amount"
								value={amount3}
								fn={setAmount3}
							/>
						</div>
					</div>
				</div>


			</div>
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default TaxPaymentRefund;
