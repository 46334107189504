import Header from '../components/Header';
import Footer from '../components/Footer';
import BillingCard from '../../../components/Dashboard/billing/billingCard';
import DatePicker from "react-datepicker";
import Pagination from '../../../components/Pagination';
import allActions from '../../../Store/action';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getQueryParam, removeQueryParam, setQueryParams } from '../../../utilites';
import Message from '../../../components/forms/fields/Message';
const Billing = () => {
	const dispatch = useDispatch();
	const [listMessage, setListMessage] = useState({ show: false });
	// const [editInvoiceObj, setEditInvoiceObj] = useState({});
	// const [editInvoice, setEditInvoice] = useState(false);
	// const [deleteInvoice, setDeleteInvoice] = useState(false);
	// const [selectedService, setselectedService] = useState('');
	// const [InvoiceName, setInvoiceName] = useState('');
	// const [InvoiceAmount, setInvoiceAmount] = useState('');
	// const [InvoiceDueDate, setInvoiceDueDate] = useState('');
	// const [message, setMessage] = useState({ show: false });
	// const [listMessage, setListMessage] = useState({ show: false });
	// eslint-disable-next-line
	// const [outerMessage, setOuterMessage] = useState({ show: false });
	//   const [SelectedLead, setSelectedLead] = useState('');
	// const selectedJobId = SelectedLead?.jobs?.find(
	// 	(data) => data?.service_id === selectedService?.value
	// )?.id;
	// Filtering
	// const keyword=''
	const keyword='subscription'
	// const [keyword, setKeyword] = useState('subscription');
	const [dateCreated, setDateCreated] = useState(
		getQueryParam('created_after') ? new Date(getQueryParam('created_after')) : null
	);
	const [status, setStatus] = useState(getQueryParam('status') || '');
	// Pagination
	const [perPage, setPerPage] = useState(getQueryParam('per_page') || 10);
	const [page, setPage] = useState(getQueryParam('page') || 1);

	const [
		{ LoginData },
		{
			invoices,
			downloadInvoice,
			downloadInvoiceMessage
		},
	] = useSelector((state) => [state.user, state.invoice]);

	const reset_message = () => {
		// it indicate we are not  deleting invoice but clearing All messages
		// setDeleteInvoice(false);
		// setMessage({ show: false });
		// setOuterMessage({ show: false });
		reset_states();
	};
	const reset_states = () => {
		dispatch(allActions.invoice.resetCreateCustomer());
		dispatch(allActions.invoice.resetCreateInvoice());
		dispatch(allActions.invoice.resetcreateSubscription());
	};

	const set_EditForm = (data) => {
		// const formattedDate = moment(data?.due_date, 'YYYY-MM-DD').format('MM/DD/YYYY');
		// document.querySelector('.invoiceModaltoggle').click();
		// // setSelectedInvoice("");
		// setSelectedLead({
		// 	value: data.customers.user.id,
		// 	label: data.customers.user.firstname + ' ' + data.customers.user.lastname,
		// });
		// setInvoiceName(data.name);
		// setInvoiceAmount(data.amount);
		// // setInvoiceDueDate(moment.utc(data.created_at).format("MM/DD/YYYY"));
		// setInvoiceDueDate(formattedDate);
		// // setInvoicePeriod("");
		// setEditInvoice(true);
		// setDeleteInvoice(false);
	};


	const handleDelete = (data) => {
		dispatch(allActions.global.Loader(true));
		let invoiceObj = {
			invoiceId: data.stripe_invoice_id,
		};
		dispatch(allActions.invoice.removeInvoice(invoiceObj));
	};

	useEffect(() => {
		dispatch(allActions.invoice.getInvoices(keyword, status, dateCreated, page, perPage));
	}, [dispatch, keyword, status, dateCreated, page, perPage]);

	// ******************************
	// HOOKS FOR DOWNLOAD INVOICE
	// ******************************

	useEffect(() => {
		if (!downloadInvoice) {
			setListMessage({
				show: true,
				type: 'Error',
				text: downloadInvoiceMessage,
			});
			dispatch(allActions.global.Loader(false));
		}

		if (downloadInvoice === true) {
			dispatch(allActions.global.Loader(false));
			window.open(downloadInvoiceMessage?.invoice_pdf);
			dispatch(allActions.invoice.resetDownloadInvoice());
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [downloadInvoice, downloadInvoiceMessage]);

	const moveTo = (page) => {
		setPage(page);
		// Set query param
		setQueryParams('page', page);
	};

	const changePerPage = (perPage) => {
		setPerPage(perPage);
		// Set query param
		setQueryParams('per_page', perPage);
	};

	// const filterByKeyword = (keyword) => {
	// 	setKeyword(keyword);
	// 	// Set query param
	// 	if (!keyword) {
	// 		removeQueryParam('search');
	// 	} else {
	// 		setQueryParams('search', keyword);
	// 	}
	// };

	const filterByStatus = (st) => {
		setStatus(st);
		// Set query param
		if (!st) {
			removeQueryParam('status');
		} else {
			setQueryParams('status', st);
		}
	};

	const filterByDateCreated = (date) => {
		setDateCreated(date);
		// Set query param
		if (!date) {
			removeQueryParam('created_after');
		} else {
			setQueryParams('created_after', date);
		}
	};

	return (
		<div className='clearfix dashboard-Page'>
			<Header name='Billing' />
			<div className='row justify-content-center'>
			{listMessage?.show ? <Message message={listMessage} /> : <></>}
				<div className='col-md-12'>
					<BillingCard />
					{/* tabs */}
					<nav className='my-3'>
						<div class="nav nav-tabs" id="nav-tab" role="tablist">
							<button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Invoices</button>
						</div>
					</nav>
					<div class="tab-content" id="nav-tabContent">
						<div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0"></div>
					</div>
					{/* filters */}

					<div className="clearfix mt-4">
						<div className="clearfix mt-4 mb-5">
							<div className="row justify-content-between">
								<div className="col-md-10">
									<div className="row g-3">
										{/* <div className="col-md-3">
													<Input
														type="text"
														value={keyword}
														name="keyword"
														inputClass="search-input border h-auto min-height-initial"
														showLabel={false}
														required={false}
														placeholder="Search by keyword"
														fn={filterByKeyword}
														extraSetter={() => {
															if (page !== 1) moveTo(1);
														}}
													/>
												</div> */}
										<div className="col-md-2">
											<div className="form-floating">
												<DatePicker
													className="form-select"
													wrapperClassName="d-block"
													selected={dateCreated}
													maxDate={new Date()}
													onChange={(date) => {
														filterByDateCreated(date);
														if (page !== 1) moveTo(1);
													}}
													dateFormat="MMMM d, yyyy"
													placeholderText="Date Created"
													isClearable
												/>
											</div>
										</div>
										<div className="col-md-2">
											<select
												className="form-select"
												id="filter"
												name="status"
												onChange={(e) => {
													filterByStatus(e.target.value);
													if (page !== 1) moveTo(1);
												}}
												value={status}
											>
												<option value="">Filter by status</option>
												<option value="pending">Pending</option>
												<option value="paid">Paid</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <BillingTable/> */}
					<div className="clearfix">
						<div className="card border-0">
							<div className="clearfix position-relative">
								<div className="table-responsive">
									<table className="table">
										<thead className="text-dark text-opacity-50">
											<tr>
												<th scope="col">ID</th>
												<th scope="col">Title</th>
												<th scope="col">Client</th>
												<th scope="col">Services</th>
												<th scope="col">Amount</th>
												<th scope="col">Created On</th>
												<th scope="col">Status</th>
												<th className="text-center" scope="col">
													Actions
												</th>
											</tr>
										</thead>
										<tbody>
											{invoices.status &&
												invoices?.data &&
												invoices?.data.slice()?.map((data, index) => {
													let paidAmount = 0;
													data?.payments?.map(
														(i, v) => (paidAmount = paidAmount + parseFloat(i.amount_paid))
													);
													return (
														<tr key={index}>
															<th scope="row">{data.id}</th>
															<td>
																<Link
																	to={`/dashboard/invoices/${data.stripe_invoice_id}`}
																	onClick={() => {
																		dispatch(allActions.invoice.resetPayInvoice());
																		dispatch(allActions.payment.resetAddPayment());
																		dispatch(allActions.payment.resetModifyPayment());
																	}}
																>
																	{data.name}
																</Link>
															</td>
															<td>
																{data.customers?.user?.firstname +
																	' ' +
																	data.customers?.user?.lastname}
															</td>
															<td>{data?.job?.service?.name || data?.job?.others}</td>
															<td>{data?.amount}</td>
															<td>{new Date(data.created_at).toLocaleDateString()}</td>
															<td>
																{' '}
																{parseInt(data?.amount) - paidAmount <= 0 ? 'Paid' : ' Pending'}
															</td>
															<td className="text-end">
																<div className="dropdown-center">
																	<button
																		type="button"
																		className={`btn btn-sm btn-outline-primary rounded-circle ${LoginData.isCustomer &&
																			!(parseInt(data?.amount) - paidAmount <= 0) &&
																			'grey-200 disabled'
																			}`}
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																	>
																		<BsThreeDotsVertical className="" />
																	</button>
																	{parseInt(data?.amount) - paidAmount <= 0 && (
																		<ul className="dropdown-menu border-light">
																			<li>
																				<Link
																					onClick={() => {
																						// setListMessage({ show: false });
																						dispatch(allActions.global.Loader(true));
																						dispatch(allActions.invoice.resetDownloadInvoice());
																						dispatch(
																							allActions.invoice.downloadInvoice(
																								data?.stripe_invoice_id
																							)
																						);
																					}}
																					className="dropdown-item"
																					to="#"
																				>
																					Download
																				</Link>
																			</li>
																		</ul>
																	)}

																	{!(parseInt(data?.amount) - paidAmount <= 0) && (
																		<ul className="dropdown-menu border-light">
																			<li>
																				<Link
																					onClick={() => {
																						reset_message();
																						set_EditForm(data);
																						// setEditInvoiceObj(data);
																					}}
																					className="dropdown-item"
																					to="#"
																				>
																					Edit
																				</Link>
																			</li>
																			<li>
																				<hr className="dropdown-divider border-light" />
																			</li>
																			<li>
																				<Link
																					to="#addNewInvoiceModal"
																					className="dropdown-item text-danger"
																					onClick={() => {
																						reset_message();
																						// setEditInvoice(false);
																						// setDeleteInvoice(true);
																						handleDelete(data);
																					}}
																				>
																					Delete
																				</Link>
																			</li>
																		</ul>
																	)}
																</div>
															</td>
														</tr>
													);
												})}

											{invoices?.status === true && invoices?.data?.length === 0 ? (
												<tr className="text-center text-muted">
													<td colSpan={8}>You have not created any invoice yet.</td>
												</tr>
											) : (
												<></>
											)}
										</tbody>
									</table>
								</div>
							</div>
							<Pagination
								pageCount={Math.ceil(invoices.count / perPage)}
								currentPage={parseInt(page)}
								setCurrentPage={moveTo}
								perPage={perPage}
								setPerPage={(pc) => {
									changePerPage(pc);
									// Move to first page
									if (page !== 1) moveTo(1);
								}}
							/>
						</div>
					</div>

					<div className="card rounded p-4 cutomBorderBox mb-4">
					<p className='fw-bold'>Cancel Account</p>
					
					<p className='text-black'>Deactivating your account means you will loose all of your information.</p>
					<div className='col-lg-4 col-md-5 col-sm-12 d-flex align-items-center justify-content-between'>
						<button className={`btn btn-outline-danger mt-3 col-6 mb-2`}>Cancel Account</button>
					</div>
				</div>

				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Billing;