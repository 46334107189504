/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '../../fields/Button';
import allActions from '../../../../Store/action';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';

const QuestionnairePart2 = ({ questions = [], multipleChoice = [] }) => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const dispatch = useDispatch();
	const [{ taxPayerFormData, taxPayerForm, formInformation}] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 2, myIndex: 1 });
	const [questionariePart2, setQuestionariePart2] = useState([]);
	const [howProvideReport, setHowProvideReport] = useState();
	const [errors, setErrors] = useState({}); // Track errors for form fields
	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------
	useEffect(() => {
		setQuestionariePart2(JSON.parse(JSON.stringify(questions)));
	}, []);
	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.questionariePart2) {
			if (Object.keys(taxPayerForm?.data?.questionariePart2).length === 0) return;

			const { questionariePart2, howProvideReport } = taxPayerForm?.data?.questionariePart2;
			setQuestionariePart2(questionariePart2);
			setHowProvideReport(howProvideReport);
		}
	}, [taxPayerForm?.data?.questionariePart2]);

	// ---------------------------------------
	// Functions
	// ---------------------------------------
	const handleQuestions = (index, bool) => {
		const localQuestionariePart2 = questionariePart2.slice();
		localQuestionariePart2[index].bool = bool;
		setQuestionariePart2(localQuestionariePart2);
	};

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};
		// Add more validation rules as needed for other fields
		if (
			questionariePart2
				?.flatMap((data) => {
					return data?.bool;
				})
				.includes(null) ||
			!howProvideReport
		) {
			newErrors.AnswerOtherQuestions = 'Please Select Required Option';
		}

		setErrors(newErrors);

		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let errors = check_Validity();
		if (errors) return;
		let FormObj = { questionariePart2, howProvideReport };
		let formData = taxPayerForm;
		taxPayerForm.data.questionariePart2 = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));
		uploadFormData(formData);
	};

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 2;
		let myIndex = 1;
		dispatch(
			allActions.taxForms.moveToBack({
				parentIndex,
				myIndex,
				taxPayerFormData,
			})
		);
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<p className="fw-bold">
					The following questions apply to the 2023 tax year unless otherwise specified
				</p>
				<div className=" mb-3 px-3">
					{questionariePart2?.map((data, i) => {
						return (
							<div key={i} className="w-100  align-items-center justify-content-between mt-2">
								<small className="col-md-12 form-check-label text-start" htmlFor={`checkbox-${i}`}>
									{data.question}
									{data.bool === null && (
										<span className="text-danger small-text mx-1">*Required</span>
									)}
								</small>
								<div className="col-md-12">
									<input
										onChange={() => handleQuestions(i, true)}
										checked={data?.bool === true}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 176}`}
										name={`checkbox-${i + 176}`}
									/>
									<label htmlFor={`checkbox-${i + 176}`} role="button" className="px-3 m-0 small">
										Yes
									</label>
									<input
										onChange={() => handleQuestions(i, false)}
										checked={data?.bool === false}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 999}`}
										name={`checkbox-${i + 999}`}
									/>
									<label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
										No
									</label>
								</div>
							</div>
						);
					})}
					{JSON.parse(JSON.stringify(multipleChoice))?.map((data, i) => {
						return (
							<div key={i} className="w-100 my-2">
								<small
									className="col-md-6 form-check-label text-start fw-bold mb-1"
									htmlFor={`howProvideReport-${i}`}
								>
									{data.question}
									{!howProvideReport && (
										<span className="text-danger small-text mx-1">*Required</span>
									)}
								</small>
								{data?.options?.map((option, index) => {
									return (
										<div>
											<input
												onChange={() => setHowProvideReport(option)}
												checked={howProvideReport === option}
												className="form-check-input"
												type="radio"
												id={`howProvideReport-${index}`}
												name={`howProvideReport-${index}`}
											/>
											<label className="px-3 small" htmlFor={`howProvideReport-${index}`}>
												{option}
											</label>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
			<div className="w-100 d-flex flex-row flex-wrap justify-content-between align-items-end mb-3"></div>
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default QuestionnairePart2;
