/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Scrollbar } from 'react-scrollbars-custom';
import Calendar from 'react-calendar';

import { BASEURL, useAutoReplyHook, timezoneFormatter, convertTime, convertTimeIntoSselectedTZ } from '../../utilites';

import Input from './fields/Input';
import Checkbox from './fields/Checkbox';
import TextArea from './fields/TextArea';
import Button from './fields/Button';
import Select from './fields/Select';

// CSS
import 'react-datepicker/dist/react-datepicker.css';
import Message from './fields/Message';
import allActions from '../../Store/action';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import moment from "moment";

const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const durationOptions = [
	{ id: 30, name: '30 Minutes' },
	{ id: 60, name: '60 Minutes' },
];

export default function EditAppointmentForm({
	appointmentTypes,
	data,
	setAlert,
	reload,
}) {

	const topTimezones = [
		"America/New_York",
		"America/Chicago",
		"America/Denver",
		"America/Los_Angeles",
	  ];
	  const americanTimeZones = moment.tz.zonesForCountry("US");

	  const [availableTimeTZ, setAvailableTimeTZ] = useState([]);
	  const [timezoneName, setTimezoneName] = useState(
		moment.tz.guess().startsWith("America/")
		  ? moment.tz.guess()
		  : "America/Adak"
	  );
	const [message, setMessage] = useState({ show: false });

	const [type, setType] = useState();
	const [reason, setReason] = useState();
	const [details, setDetails] = useState();
	const [timezone, setTimezone] = useState(
		moment.tz.guess().startsWith("America/")
		  ? moment.tz.guess()
		  : "America/Adak"
	  );
	const [videoAppointment, setVideoAppointment] = useState();
	const [availableTime, setAvailableTime] = useState([]);
	const [startDate, setStartDate] = useState(new Date());
	const [duration, setDuration] = useState(['30']);
	const [step, setStep] = useState(1);
	const [errors, setErrors] = useState({
		type: false,
		reason: false,
		details: false,
		timezone: false,
		startDate: false,
		endDate: false,
		general: '',
	});
	const button = useRef();
	const dispatch = useDispatch();
	const handleAutoReply = useAutoReplyHook(dispatch);
	const [{ getZoomMeeting, updateMeetingRoom }, { personalConnections }, { LoginData }] =
		useSelector((state) => [state.call, state.firebase, state.user]);
		// user avail
		var userAvailTZ = null;
		switch (LoginData?.roles[0]?.name) {
		  case "customer":
			userAvailTZ = LoginData?.lead?.[0]?.userpro?.user;
			break;
		  case "taxpro":
			userAvailTZ = LoginData;
			break;
	  
		  default:
			break;
		}

	useEffect(() => {
		// if Error While Creating Video Call Room
		if (!updateMeetingRoom?.status) {
			// Reset Message and Loading
			resetMessage();
			setMessage({
				show: true,
				type: 'Error',
				text: updateMeetingRoom?.message,
			});
		}
		// if Success After Creating Video Call
		if (updateMeetingRoom?.status === true) {
			let newRoomId = videoAppointment && updateMeetingRoom?.data?.meetingID;
			// Reset Message and Loading

			handleSubmit(newRoomId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateMeetingRoom.status, updateMeetingRoom?.message, updateMeetingRoom?.data?.meetingID]);

	useEffect(() => {
		// if Error While Creating Video Call Room
		if (!getZoomMeeting?.status) {
			// Reset Message and Loading
			resetMessage();

			setMessage({
				show: true,
				type: 'Error',
				text: getZoomMeeting?.message,
			});
		}
		// if Success After Creating Video Call
		if (getZoomMeeting?.status === true) {
			document.querySelector('.appointmentQuickEditModalClose').click();
			// Reset Message and Loading
			resetMessage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getZoomMeeting.status, getZoomMeeting.message, getZoomMeeting?.data?.roomId]);

	useEffect(() => {
		setType(data.type_id || appointmentTypes[0].id.toString());
		setReason(data.title || '');
		setDetails(data.details || '');
		// Set default start date based on data.start_date (also, set the available time list for the selected date and set the selected time to the data.start_date's time)
		// Get duration (minutes) based on start and end date
		setDuration([
			Math.floor(
				(new Date(data.end_date)?.getTime() - new Date(data?.start_date)?.getTime()) / 60000
			).toString() || durationOptions[0].id.toString(),
		]);
		// setJoinButtonShow(startDate?.getTime() <= new Date().getTime());
		// setTimezone(data.timezone || american_timezones[3].tzCode);
		setVideoAppointment(data?.room_id ? true : false);
	}, [
		appointmentTypes,
		data.details,
		data.timezone,
		data.end_date,
		data?.start_date,
		data.title,
		data.type_id,
		// startDate,
		data?.room_id,
	]);


	useEffect(() => {
		if(data) {
			setStartDate(data?.start_date)
		}
	},[data])


	// function to handle form submission
	const handleSubmit = (roomId) => {
		if(button.current) button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });

		// Get endDate based on the selected duration and startDate
		const durationInMinutes = parseInt(duration[0]);
		const end_date = new Date(startDate);
		startDate&&end_date.setMinutes(startDate.getMinutes() + durationInMinutes);
		const appointmentObj = {
			type,
			reason,
			details,
			timezone: userAvailTZ?.settings?.timezone,
			startDate: convertTimeIntoSselectedTZ(
				timezone,
				userAvailTZ?.settings?.timezone,
				startDate
			  ),
			  endDate: convertTimeIntoSselectedTZ(
				timezone,
				userAvailTZ?.settings?.timezone,
				end_date
			  ),
			roomId,
		};
		axios
			.put(`${BASEURL}/api/appointments/${data?.uuid}`, appointmentObj)
			.then(() => {
				button.current.disabled = false;
				handleVideoMeetingReply();
				// Reload appointments
				reload();
				// Close Modal
				document.querySelector('.appointmentQuickEditModalClose').click();
				setAlert('Appointment has been updated successfully!');
				changeLoading(false);
				// Reset form
				setStep(1);
				setTimezone(
					moment.tz.guess().startsWith("America/")
					  ? moment.tz.guess()
					  : "America/Adak"
				  );
			})
			.catch((error) => {
				if(button.current){
					button.current.disabled = false;
				}
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};

	

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

	const handleVideoMeetingReply = () => {
		handleAutoReply({
			personalConnections,
			LoginData,
			receiver: data.users[0],	
			type: 'simple',
			encodedString: `<p>Hi, I just rescheduled our appointment to a later date. You should be receiving an email about the new schedule shortly or you can visit the appointments page by clicking on the link below to confirm the new date and time. <a href="${window.location.origin}/dashboard/appointments">View Appointment</a></p>`,
		});
	};

	const joinMeeting = () => {
		console.log(data);
		if (!data?.room_id) return;
		// reset Meeting Link
		resetMessage();
		// GENERATE NEW TOKEN
		dispatch(allActions.call.getZoomMeeting(data?.room_id));
		changeLoading(true);
	};

	const handleVideoMeeting = (e) => {
		e.preventDefault();
		changeLoading(true);

		// CHECK APPOINTMENT IS CHANGE OR NOT
		if ((data?.room_id && true === videoAppointment) || !data?.room_id === !videoAppointment) {
			console.log('No change>>>>');
			handleSubmit(data?.room_id);
		} else {
			console.log('change>>>>>>');
			if (videoAppointment === false) {
				if (!data?.room_id) return;
				dispatch(
					allActions.call.updateMeetingRoom({
						meetingObj: {
							meetingID: data?.room_id,
							videoAppointment: false,
							email: LoginData?.email,
							duration: parseInt(duration[0]),
						},
					})
				);
			}
			if (videoAppointment === true) {
				dispatch(
					allActions.call.updateMeetingRoom({
						meetingObj: {
							videoAppointment: true,
							email: LoginData?.email,
							duration: parseInt(duration[0]),
						},
					})
				);
			}
		}
	};
	const isExcludedDate = (date) => {
		const day = dayNames[date.getDay()];
		if (!userAvailTZ?.availability?.time_ranges[day]) return true;
		return false;
	  };
	
	  const resetMessage = () => {
		changeLoading(false);
		setMessage({ show: false });
	  };


	  const haandleChange = (date) => {
		setStartDate(date);
		const currentDate = moment(date);
		const dayOfWeek = currentDate.format("dddd");
		const slotDuration = duration;
		const timeSlots = [];
		const originaltimeSlots = [];
		setAvailableTime();
		userAvailTZ?.availability?.time_ranges[dayOfWeek].length > 0 &&
		  userAvailTZ?.availability?.time_ranges[dayOfWeek]?.map((v, i) => {
			originaltimeSlots.push(
			  ...getTimeSlots(
				v.from,
				v.to,
				slotDuration,
				currentDate.format("YYYY-MM-DD")
			  ).originaltimeSlots
			);
			timeSlots.push(
			  ...getTimeSlots(
				v.from,
				v.to,
				slotDuration,
				currentDate.format("YYYY-MM-DD")
			  ).timeSlots
			);
			return v;
		  });
	
		setAvailableTime(originaltimeSlots);
		setAvailableTimeTZ(timeSlots);
	  };


	  useEffect(() => {
		const currentDate = moment(startDate);
		const dayOfWeek = currentDate.format("dddd");
		const slotDuration = duration;
		const timeSlots = [];
		const originaltimeSlots = [];
		setAvailableTime();
		userAvailTZ?.availability?.time_ranges[dayOfWeek].length > 0 &&
		  userAvailTZ?.availability?.time_ranges[dayOfWeek]?.map((v, i) => {
			originaltimeSlots.push(
			  ...getTimeSlots(
				v.from,
				v.to,
				slotDuration,
				currentDate.format("YYYY-MM-DD")
			  ).originaltimeSlots
			);
			timeSlots.push(
			  ...getTimeSlots(
				v.from,
				v.to,
				slotDuration,
				currentDate.format("YYYY-MM-DD")
			  ).timeSlots
			);
			return v;
		  });
	
		setAvailableTime(originaltimeSlots);
		setAvailableTimeTZ(timeSlots);
	  },[startDate])
	

	  const getTimeSlots = (startTime, endTime, slotDuration, currentDate) => {
		  const timeSlots = [];
		  const originaltimeSlots = [];
		  
		  const startDateTime = moment(
			  `${currentDate} ${startTime}`,
			  "YYYY-MM-DD HH:mm:ss"
			);
			const endDateTime = moment(
				`${currentDate} ${endTime}`,
				"YYYY-MM-DD HH:mm:ss"
			);
			let currentTime = startDateTime;
			
		while (currentTime.isSameOrBefore(endDateTime)) {
		
		  const currentTimeWithTZ = moment.tz(
			currentTime.format("YYYY-MM-DDTHH:mm:ss"),

			userAvailTZ?.settings?.timezone
		  );
	
		  originaltimeSlots.push(
			moment(currentTimeWithTZ).format("YYYY-MM-DD HH:mm:ss")
		  );
		  timeSlots.push(
			moment(currentTimeWithTZ).tz(timezone).format("YYYY-MM-DD HH:mm:ss")
		  );
		  currentTime.add(slotDuration, "minutes");
		}
		originaltimeSlots.pop();
		timeSlots.pop();
		return {
		  originaltimeSlots,
		  timeSlots,
		};
	  };

	  const convertTimesToSelectedTimezone = (selectedTimezone) => {
		setTimezone(selectedTimezone.value);
		setTimezoneName(selectedTimezone.label);
		setAvailableTimeTZ(
		  availableTime?.map((time) => {
			const currentTimeWithTZ = moment.tz(
			  moment(time).format("YYYY-MM-DDTHH:mm:ss"),
			  userAvailTZ?.settings?.timezone
			);
			return moment(currentTimeWithTZ)
			  .tz(selectedTimezone.value)
			  .format("YYYY-MM-DD HH:mm:ss");
		  })
		);
	  };
	  const minDate = () => {
		var currentDate = new Date();
		currentDate.setDate(currentDate.getDate());
		return currentDate;
	  };
	
	  const titleDisabled = () => {
		return ({ date, view }) => {
		  return isExcludedDate(date);
		};
	  };
	
	  const customStyles = {
		option: (provided) => ({
		  ...provided,
		  textAlign: "left",
		}),
		singleValue: (provided) => ({
		  ...provided,
		  textAlign: "left",
		}),
		placeholder: (provided) => ({
		  ...provided,
		  textAlign: "left",
		  fontSize: 14,
		}),
	  };
	  const handleNextStep = () => {
		if (Object.values(errors).every((error) => !error)) {
		  setStep((prevStep) => prevStep + 1);
		} else {
		  setErrors({ ...errors, general: "Please fill all the required fields" });
		}
	  };
	
	  const handlePreviousStep = () => {
		setStep((prevStep) => prevStep - 1);
	  };
	
	  const isAllWeekUnavailable = () => {
		let data = LoginData;
		const role = LoginData?.roles[0]?.name;
		let allNull = false;
		if (role === "taxpro") {
		  const objData = data.availability?.time_ranges;
		  allNull = Object.values(objData).every((value) => value === null);
		} else if (role === "customer") {
		  const objData = data?.lead?.[0]?.userpro?.user.availability?.time_ranges;
		  allNull = Object.values(objData).every((value) => value === null);
		}
		if (allNull) {
		  return true;
		}
		return false;
	  };

	return (
		<form className="appointmentsform needs-validation" onSubmit={handleVideoMeeting}>
			<div className="row">{message.show ? <Message message={message} /> : <></>}</div>
			{step === 1 && (
				<>
					<div className="row">
						<div className="col-md-6 mb-3">
							<Input
								type="text"
								value={reason}
								name="reason"
								placeholder="Reason (title)"
								fn={setReason}
								hasValidationError={errors.reason}
								required={true}
								showLabel={false}
							/>
						</div>
						<div className="col-md-6 mb-3">
							<Select value={type} name="type" placeholder="Type of appointment" fn={setType}>
								{appointmentTypes?.map((t) => (
									<option key={t.id} value={t.id}>
										{t.name}
									</option>
								))}
							</Select>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12 mb-3">
							<TextArea
								inputName="form-control"
								defaultValue={details}
								placeholder="Details"
								name="details"
								fn={setDetails}
								row="6"
								required={false}
								showLabel={false}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12 mb-3">
							<input
								className="form-check-input me-2"
								id="flexCheckChecked"
								type="checkbox"
								onChange={(e) => setVideoAppointment(e.target.checked)}
								checked={videoAppointment}
							/>
							<label className="form-check-label" htmlFor="flexCheckChecked">
								Video Appointment
							</label>
						</div>
					</div>
					<Button
						mainClass={`btn btn-outline-primary${
							!type || !reason || !details ? ' disabled' : ''
						} px-4`}
						callback={handleNextStep}
						buttonText="Next"
					/>
				</>
			)}
			{step === 2 && (
				<>
					<div className="row">
						<div className="col-md-6">
							<div className="form-floating BookAppointmentForm__calendar">
								<Calendar
									className="border-0 bg-white"
									tileClassName={''}
									value={startDate}
									onChange={haandleChange}
									tileDisabled={titleDisabled()}
									minDate={minDate()}
									// maxDate={maxDate()}
									next2Label={null}
									prev2Label={null}
									formatShortWeekday={(locale, date) =>
										['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]
									}
									showFixedNumberOfWeeks={true}
								/>
							</div>
						</div>
						<div className="col-md-6 mt-3 mt-md-0">
							<ReactSelect
								id="users"
								className="p-0 basic-single"
								placeholder="Select User"
								styles={customStyles}
								closeMenuOnSelect={true}
								// options={americanTimeZones}
								options={[
									...topTimezones?.map((v) => ({
										value: v,
										label: timezoneFormatter(v),
									})),
									...americanTimeZones?.map((v) => ({
										value: v,
										label: timezoneFormatter(v),
									})),
								]}
								onChange={(v) => convertTimesToSelectedTimezone(v)}
								value={{
									value: timezone,
									label: `${timezoneFormatter(timezoneName)} (${convertTime(timezone, 'h:mm A')})`,
								}}
							/>
							<ul className="w-100 list-group list-group-flush mt-3" style={{ height: 216 }}>
								{isAllWeekUnavailable() && LoginData?.roles[0]?.name === 'customer' && (
									<div class="alert alert-muted">
										Your Tax Professional does not have any Availability right now
									</div>
								)}
								{isAllWeekUnavailable() && LoginData?.roles[0]?.name === 'taxpro' && (
									<div class="alert alert-danger">You do not have any availability</div>
								)}
								<Scrollbar width={3} className="w-100 h-100">
									{availableTimeTZ?.map((time, index) => (
										<li
											key={index}
											className={`list-group-item mt-2 py-1 cursor-pointer${
												startDate &&
												moment(time).hours() === new Date(startDate).getHours() &&
												moment(time).minutes() === new Date(startDate).getMinutes()
													? ' bg-primary text-white'
													: ' bg-light text-black'
											}`}
											onClick={() => {
												const newStartDate = moment(time);
												newStartDate.hours(moment(time).hours());
												newStartDate.minutes(moment(time).minutes());
												setStartDate(newStartDate.toDate());
											}}
										>
											{moment(time).format('h:mm A')}
										</li>
									))}
								</Scrollbar>
							</ul>
						</div>
						<div className="col-md-12 my-3">
							<div className="row justify-content-center">
								{durationOptions?.map((option) => (
									<div className="col-md-3" key={option.id}>
										<Checkbox
											type="radio"
											wrapperClass="text-start m-0"
											name="durationRadios"
											state={duration}
											value={option.id}
											label={option.name}
											fn={setDuration}
											id={`ad-radio-${option.id}`}
											required={true}
										/>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className="d-flex justify-content-center mt-3">
						<Button
							mainClass="btn btn-outline-primary me-3"
							callback={handlePreviousStep}
							buttonText="Previous"
						/>
						{data.room_id && (
							<button
								type="button"
								className={
									// !JoinButtonShow ? 'btn btn-info text-white' :
									'btn btn-primary me-3'
								}
								// disabled={!JoinButtonShow}
								onClick={() => joinMeeting()}
							>
								Join Meeting
							</button>
						)}
						<Button
							buttonRef={button}
							mainClass={`btn btn-primary${
								Object.values(errors).indexOf(true) > -1 ||
								!type ||
								!reason ||
								!details ||
								!timezone ||
								!startDate
									? ' disabled'
									: ''
							}`}
							buttonText="Update Appointment"
						/>
					</div>
				</>
			)}
			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mt-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
		</form>
	);
}
