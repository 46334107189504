import React, { useEffect, useState } from "react";
import Button from "../../fields/Button";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../Store/action";
import MultiLineDoubleFieldInputs from "../../fields/MultiLineDoubleFieldInputs";
import OtherDataAdditionForm from "./OtherDataAdditionForm";
import { HandleFormData } from "../../../../utilites";

const LiabilitiesAndCapital = ({ LiabilitiesOptions = [] }) => {
	//
	//  ---------------------------------------
	//  Variables , States & Store States
	//  ---------------------------------------
	//
	const dispatch = useDispatch();
	const uploadFormData = HandleFormData({ parentIndex: 4, myIndex: 1 });
	const [{ taxPayerFormData, taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);
	const [LiabilitiesTypes, setLiabilitiesTypes] = useState(
		JSON.parse(JSON.stringify(LiabilitiesOptions))
	);
	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------
	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.LiabilitiesAndCapitalForm) {
			if (Object.keys(taxPayerForm?.data?.LiabilitiesAndCapitalForm).length === 0) return;

			const { LiabilitiesTypes } = taxPayerForm?.data?.LiabilitiesAndCapitalForm;

			setLiabilitiesTypes(LiabilitiesTypes);
		}
	}, [taxPayerForm?.data?.LiabilitiesAndCapitalForm]);

	const handleAmountChange = (id, index, name) => {
		const myInputAmount = document.getElementById(id);
		let localDeduction = LiabilitiesTypes;
		console.log(myInputAmount.value, name);
		localDeduction[index][name] = myInputAmount.value;
		console.log(localDeduction);
		setLiabilitiesTypes(JSON.parse(JSON.stringify(localDeduction)));
	};
	// ---------------------------------------
	// Functions
	// ---------------------------------------
	const handleSubmit = (e) => {
		e.preventDefault();

		let FormObj = {
			LiabilitiesTypes,
		};

		let formData = taxPayerForm;
		taxPayerForm.data.LiabilitiesAndCapitalForm = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
	};
	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 4;
		let myIndex = 1;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<MultiLineDoubleFieldInputs
				Options={LiabilitiesTypes}
				firstPlaceHolder={"Beginning of year"}
				handleChange={handleAmountChange}
				secondPlaceHolder={"End of year"}
			/>
			<OtherDataAdditionForm
				oldValues={LiabilitiesTypes}
				setState={setLiabilitiesTypes}
				heading="Other Liabilities"
				BtnText="Add Liabilities"
				Datakeys={["question", "BeginningOfYear", "EndOfYear"]}
				DataLabels={["Question", "Beginning of year", "End of year"]}
			/>
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={"w-25 custon-button  mt-4"}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={"w-25 custon-button  mt-4"}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default LiabilitiesAndCapital;
