import { FIREBASE_ACTION_TYPE } from './Type.js';
import {
	updateDoc,
	arrayUnion,
	onSnapshot,
	collection,
	addDoc,
	query,
	where,
	doc,
	arrayRemove,
	orderBy,
	limit,
	getCountFromServer,
	startAfter,
} from 'firebase/firestore';
import { db } from '../../config/firebase';
import axios from 'axios';
import { BASEURL } from '../../utilites/api.js';
import { onLog } from 'firebase/app';
import io from 'socket.io-client';
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import moment from 'moment';
import allActions from '../action.js';

// *****--Write Chat topic Connection Old Chat UI (unused)--*****
const writeChatConnection = (messageObj) => {
	return async (dispatch) => {
		try {
			let { receiverId, senderId, createdAt, title, body, type, reply, draft } = messageObj;

			let mainCollection = 'messages';

			let refrenceObj = {
				ids: [senderId, receiverId],
				title,
				createdAt,
				draft,
			};

			let chat = { receiverId, senderId, body, createdAt, reply, type };

			// Define collection reference
			const messagesCollection = collection(db, mainCollection);

			// Add the message to the mainCollection collection
			const docRef = await addDoc(messagesCollection, refrenceObj);

			const nestedCollectionRef = collection(db, mainCollection, docRef.id, 'chats');

			await addDoc(nestedCollectionRef, chat).then(() => {
				dispatch({
					type: FIREBASE_ACTION_TYPE.WRITE,
					status: true,
					message: 'message send successfully!',
				});
			});
		} catch (error) {
			dispatch({
				type: FIREBASE_ACTION_TYPE.WRITE,
				status: false,
				message: error.toString(),
			});
		}
	};
};

// *****--Read Chat Users List Old Chat UI (unused)--*****
const readtopicUserList = (myId) => {
	return async (dispatch) => {
		try {
			const messagesCollection = collection(db, 'messages');
			const querySnapshot = query(messagesCollection, where('ids', 'array-contains', myId));

			onSnapshot(querySnapshot, (snapshot) => {
				let topics = [];
				topics.length = 0;
				snapshot.forEach((doc) => {
					let myNode = doc.data();
					myNode.docId = doc.id;
					myNode.createdAt = doc.data().createdAt.toDate();
					topics.push(myNode);
				});

				// Now you can use the updated 'topics' array here

				const allIds = topics.flatMap((obj) => obj.ids);

				// Convert the array to a Set to remove duplicates
				const uniqueIdsSet = new Set(allIds);

				// Convert the Set back to an array
				const uniqueIdsArray = Array.from(uniqueIdsSet);

				// Get Ids Details From SQL
				if (topics.length > 0) {
					axios
						.post(`${BASEURL}/api/users/retrive-user`, {
							uuid: uniqueIdsArray,
						})
						.then((response) => {
							let retrivedData = response?.data?.data;

							// Loop To Match IDS and Send Data For That
							for (const topic of topics) {
								const senderId = topic.ids[1];

								const matchingSender = retrivedData.find((sender) => sender.uuid === senderId);

								if (matchingSender) {
									topic.receiverFirstName = matchingSender.firstname;
									topic.receiverLastName = matchingSender.lastname;
								}
							}
							let sorted = topics.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
							dispatch({
								type: FIREBASE_ACTION_TYPE.topicUserList,
								status: true,
								data: sorted,
								message: 'Topics get sucessfully!',
							});
						})
						.catch((error) => {
							dispatch({
								type: FIREBASE_ACTION_TYPE.topicUserList,
								status: false,
								message:
									error.code === 'ERR_NETWORK' ? error?.message : error?.response?.data?.error,
							});
						});
				} else {
					dispatch({
						type: FIREBASE_ACTION_TYPE.topicUserList,
						status: true,
						data: [],
						message: 'Topics get sucessfully!',
					});
				}
			});
			// Firebase error Handling
			onLog((e) => {
				const { level, message } = e;
				if (level === 'error') {
					dispatch({
						type: FIREBASE_ACTION_TYPE.topicUserList,
						data: [],
						status: false,
						message: message.includes('Internet connection') ? 'Network Error' : message,
					});
				}
			});
		} catch (error) {}
	};
};

// *****--Read Chats List Old Chat UI (unused)--*****
const readChats = (myId) => {
	return async (dispatch) => {
		try {
			const parentCollection = 'messages';
			const subcollectionName = 'chats';

			// Create a reference to the subcollection
			const subcollectionRef = collection(db, parentCollection, myId, subcollectionName);

			// Create a query for the subcollection
			const subcollectionQuery = query(subcollectionRef);

			// Listen for real-time changes to the subcollection
			await onSnapshot(subcollectionQuery, (snapshot) => {
				let chats = [];
				chats.length = 0;
				snapshot.forEach((doc) => {
					let myNode = doc.data();
					myNode.docId = doc.id;
					myNode.createdAt = doc.data().createdAt.toDate();
					chats.push(myNode);
				});

				const uniqueIds = chats.reduce((ids, item) => {
					ids.add(item.senderId);
					ids.add(item.receiverId);
					return ids;
				}, new Set());

				const resultArray = Array.from(uniqueIds);
				// Get Ids Details From SQL
				if (chats.length > 0) {
					axios
						.post(`${BASEURL}/api/users/retrive-user`, {
							uuid: resultArray,
						})
						.then((response) => {
							let retrivedData = response?.data?.data;

							// Loop To Match IDS and Send Data For That
							for (const topic of chats) {
								const senderId = topic.senderId;

								const matchingSender = retrivedData.find((sender) => sender.uuid === senderId);

								if (matchingSender) {
									topic.senderFirstName = matchingSender.firstname;
									topic.senderLastName = matchingSender.lastname;
								}
							}
							// Sort According to time
							let sorted = chats.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

							dispatch({
								type: FIREBASE_ACTION_TYPE.READ,
								status: true,
								data: sorted,
								message: 'Data Get chats!',
							});
						})
						.catch((error) => {
							dispatch({
								type: FIREBASE_ACTION_TYPE.READ,
								status: false,
								data: [],
								message:
									error.code === 'ERR_NETWORK' ? error?.message : error?.response?.data?.error,
							});
						});
				} else {
					dispatch({
						type: FIREBASE_ACTION_TYPE.READ,
						status: true,
						data: [],
						message: 'Data Get chats!',
					});
				}
			});
			// Firebase error Handling
			onLog((e) => {
				const { level, message } = e;
				if (level === 'error') {
					dispatch({
						type: FIREBASE_ACTION_TYPE.READ,
						status: false,
						data: [],
						message: message.includes('Internet connection') ? 'Network Error' : message,
					});
				}
			});
		} catch (error) {}
	};
};

// *****--Write Chats List Old Chat UI (unused)--*****
const writeChatReply = (chatObject, docId) => {
	return async (dispatch) => {
		try {
			const nestedCollectionRef = collection(db, 'messages', docId, 'chats');
			await addDoc(nestedCollectionRef, chatObject).then(() => {
				dispatch({
					type: FIREBASE_ACTION_TYPE.writeReply,
					status: true,
					message: 'message send successfully!',
				});
			});
		} catch (error) {
			dispatch({
				type: FIREBASE_ACTION_TYPE.writeReply,
				status: false,
				message: error.toString(),
			});
		}
	};
};

// *****--Save Chat in Draft Old Chat UI (unused)--*****
const writeChatDraft = (chatObject, docId) => {
	return async (dispatch) => {
		try {
			const nestedCollectionRef = collection(db, 'messages', docId, 'chats');
			await addDoc(nestedCollectionRef, chatObject).then(async () => {
				const documentId = docId;

				const messagesRef = doc(db, 'messages', documentId);

				// Update the array field using arrayUnion
				await updateDoc(messagesRef, {
					draft: arrayUnion(chatObject?.senderId),
				}).then(() => {
					dispatch({
						type: FIREBASE_ACTION_TYPE.writeReply,
						status: true,
						message: 'message send successfully!',
					});
				});
			});
		} catch (error) {
			dispatch({
				type: FIREBASE_ACTION_TYPE.writeReply,
				status: false,
				message: error.toString(),
			});
		}
	};
};

// *****--Shift Message From Draft To Active Message Old Chat UI (unused)--*****
const ChangeDraftToMessage = (IdsObject) => {
	return async (dispatch) => {
		try {
			let { parentDocId, myDocId, myUuid, removeDraft, body, createdAt } = IdsObject;

			// Step 1: Navigate to the subcollection and update the document
			const subCollectionRef = doc(db, 'messages', parentDocId, 'chats', myDocId);
			await updateDoc(subCollectionRef, {
				type: 'active',
				body: body,
				createdAt: createdAt,
			});

			if (removeDraft === true) {
				// Step 2: Update the draft array in the parent collection
				const messagesRef = doc(db, 'messages', parentDocId);
				await updateDoc(messagesRef, {
					draft: arrayRemove(myUuid),
				});
			}
			dispatch({
				type: FIREBASE_ACTION_TYPE.writeReply,
				status: true,
				message: 'message send successfully!',
			});
		} catch (error) {
			dispatch({
				type: FIREBASE_ACTION_TYPE.writeReply,
				status: false,
				message: error.toString(),
			});
		}
	};
};

const getRecentNotifications = (uuid, setCount) => {
	return async (dispatch, getState) => {
		const { env, isAdmin, isAccountManager } = getState().user.LoginData;
		let { professionals } = getState().dashboard;
		if (isAccountManager) {
			if (!professionals || professionals.length === 0) {
				await dispatch(allActions.dashboard.getTaxProfessionals());
			}
			professionals = getState().dashboard.professionals?.map((item) => item.uuid);
		}
		// Start fetching
		dispatch({
			type: FIREBASE_ACTION_TYPE.RECENT_NOTIFICATIONS_FETCHING_START,
		});

		const notificationsCollection = collection(db, `notifications-${env}`);
		let selectionQuery;

		if (isAdmin) {
			selectionQuery = query(
				notificationsCollection,
				where('seen', '==', false),
				orderBy('createdAt', 'desc'),
				limit(4)
			);
		} else if (isAccountManager) {
			selectionQuery = query(
				notificationsCollection,
				where('createdFor', 'in', [uuid, ...professionals]),
				where('seen', '==', false),
				orderBy('createdAt', 'desc'),
				limit(4)
			);
		} else {
			selectionQuery = query(
				notificationsCollection,
				where('createdFor', '==', uuid),
				where('seen', '==', false),
				orderBy('createdAt', 'desc'),
				limit(4)
			);
		}
		const unsubscribe = onSnapshot(selectionQuery, async (snapshot) => {
			const notifications = [];
			const uniqueIdsArray = [];

			await Promise.all(
				snapshot.docs?.map(async (doc) => {
					let data = { ...doc.data(), id: doc.id };
					data.createdAt = data.createdAt.toDate();

					notifications.push(data);

					!uniqueIdsArray.includes(data.createdBy) &&
						data.createdBy !== 'system' &&
						uniqueIdsArray.push(data.createdBy);
					!uniqueIdsArray.includes(data.createdFor) && uniqueIdsArray.push(data.createdFor);
				})
			);
			setCount && setCount(notifications.length);
			if (uniqueIdsArray.length > 0) {
				axios
					.post(`${BASEURL}/api/users/retrive-user`, {
						uuid: uniqueIdsArray,
					})
					.then((response) => {
						let retrievedData = response?.data?.data;

						// Loop To Match IDS and Send Data For That
						for (const notification of notifications) {
							const createdBy = retrievedData.find((user) => user.uuid === notification.createdBy);
							const createdFor = retrievedData.find(
								(user) => user.uuid === notification.createdFor
							);

							notification.userBy = createdBy ? createdBy : null;
							notification.userFor = createdFor ? createdFor : null;
						}

						dispatch({
							type: FIREBASE_ACTION_TYPE.GET_RECENT_NOTIFICATIONS,
							status: true,
							payload: notifications,
							message: 'Notifications fetched sucessfully!',
						});
					})
					.catch((error) => {
						dispatch({
							type: FIREBASE_ACTION_TYPE.GET_RECENT_NOTIFICATIONS,
							status: false,
							message: error.code === 'ERR_NETWORK' ? error?.message : error?.response?.data?.error,
						});
					});
			} else {
				dispatch({
					type: FIREBASE_ACTION_TYPE.GET_RECENT_NOTIFICATIONS,
					status: true,
					payload: [],
					message: 'No notifications!',
				});
			}
		});
		// Firebase error Handling
		onLog((e) => {
			const { level, message } = e;
			if (level === 'error') {
				dispatch({
					type: FIREBASE_ACTION_TYPE.GET_RECENT_NOTIFICATIONS,
					status: false,
					payload: [],
					message: message.includes('Internet connection') ? 'Network Error' : message,
				});
			}
		});

		return unsubscribe;
	};
};

const getNotifications = (uuid, per_page = 5, lastVisible = null) => {
	return async (dispatch, getState) => {
		const { env, isAdmin, isAccountManager } = getState().user.LoginData;
		let { professionals } = getState().dashboard;
		if (isAccountManager) {
			if (!professionals || professionals.length === 0) {
				await dispatch(allActions.dashboard.getTaxProfessionals());
			}
			professionals = getState().dashboard.professionals?.map((item) => item.uuid);
		}
		// Start fetching
		dispatch({
			type: FIREBASE_ACTION_TYPE.NOTIFICATIONS_FETCHING_START,
		});

		const notificationsCollection = collection(db, `notifications-${env}`);
		let selectionQuery;

		if (isAdmin) {
			selectionQuery = lastVisible
				? query(
						notificationsCollection,
						orderBy('createdAt', 'desc'),
						startAfter(lastVisible),
						limit(per_page)
				  )
				: query(notificationsCollection, orderBy('createdAt', 'desc'), limit(per_page));
		} else if (isAccountManager) {
			selectionQuery = lastVisible
				? query(
						notificationsCollection,
						where('createdFor', 'in', [uuid, ...professionals]),
						orderBy('createdAt', 'desc'),
						startAfter(lastVisible),
						limit(per_page)
				  )
				: query(
						notificationsCollection,
						where('createdFor', 'in', [uuid, ...professionals]),
						orderBy('createdAt', 'desc'),
						limit(per_page)
				  );
		} else {
			selectionQuery = lastVisible
				? query(
						notificationsCollection,
						where('createdFor', '==', uuid),
						orderBy('createdAt', 'desc'),
						startAfter(lastVisible),
						limit(per_page)
				  )
				: query(
						notificationsCollection,
						where('createdFor', '==', uuid),
						orderBy('createdAt', 'desc'),
						limit(per_page)
				  );
		}

		const unsubscribe = onSnapshot(selectionQuery, async (snapshot) => {
			let notifications = [];
			const uniqueIdsArray = [];

			await Promise.all(
				snapshot.docs?.map(async (doc) => {
					let data = { ...doc.data(), id: doc.id };
					data.createdAt = data.createdAt.toDate();

					notifications.push(data);

					!uniqueIdsArray.includes(data.createdBy) &&
						data.createdBy !== 'system' &&
						uniqueIdsArray.push(data.createdBy);
					!uniqueIdsArray.includes(data.createdFor) && uniqueIdsArray.push(data.createdFor);
				})
			);

			if (uniqueIdsArray.length > 0) {
				axios
					.post(`${BASEURL}/api/users/retrive-user`, {
						uuid: uniqueIdsArray,
					})
					.then((response) => {
						let retrievedData = response?.data?.data;

						// Remove Notifications if user is not found
						/* notifications = notifications.filter((notification) => {
							const res = retrievedData.find(
								(user) =>
									user.uuid === notification.createdBy || user.uuid === notification.createdFor
							);
							if (!res) {
								console.log('REMOVE: ', notification);
								return false;
							}

							return true;
						}); */

						// Loop To Match IDS and Send Data For That
						for (const notification of notifications) {
							const createdBy = retrievedData.find((user) => user.uuid === notification.createdBy);
							const createdFor = retrievedData.find(
								(user) => user.uuid === notification.createdFor
							);

							notification.userBy = createdBy ? createdBy : null;
							notification.userFor = createdFor ? createdFor : null;
						}

						dispatch({
							type: FIREBASE_ACTION_TYPE.GET_NOTIFICATIONS,
							status: true,
							payload: notifications,
							message: 'Notifications fetched sucessfully!',
						});
					})
					.catch((error) => {
						dispatch({
							type: FIREBASE_ACTION_TYPE.GET_NOTIFICATIONS,
							status: false,
							message: error.code === 'ERR_NETWORK' ? error?.message : error?.response?.data?.error,
						});
					});
			} else {
				dispatch({
					type: FIREBASE_ACTION_TYPE.GET_NOTIFICATIONS,
					status: true,
					payload: [],
					message: 'No notifications!',
				});
			}
		});
		// Firebase error Handling
		onLog((e) => {
			const { level, message } = e;
			if (level === 'error') {
				dispatch({
					type: FIREBASE_ACTION_TYPE.GET_NOTIFICATIONS,
					status: false,
					payload: [],
					message: message.includes('Internet connection') ? 'Network Error' : message,
				});
			}
		});

		return unsubscribe;
	};
};

const getNotificationsCount = (uuid) => {
	return async (dispatch, getState) => {
		let { env, isAdmin, isAccountManager } = getState().user.LoginData;
		let { professionals } = getState().dashboard;
		if (isAccountManager) {
			if (!professionals || professionals.length === 0) {
				await dispatch(allActions.dashboard.getTaxProfessionals());
			}
			professionals = getState().dashboard.professionals?.map((item) => item.uuid);
		}
		// Start fetching
		dispatch({
			type: FIREBASE_ACTION_TYPE.NOTIFICATIONS_FETCHING_START,
		});

		const notificationsCollection = collection(db, `notifications-${env}`);
		let selectionQuery;

		if (isAdmin) {
			selectionQuery = query(notificationsCollection);
		} else if (isAccountManager) {
			selectionQuery = query(
				notificationsCollection,
				where('createdFor', 'in', [uuid, ...professionals])
			);
		} else {
			selectionQuery = query(notificationsCollection, where('createdFor', '==', uuid));
		}

		const unsubscribe = onSnapshot(selectionQuery, async (snapshot) => {
			await getCountFromServer(selectionQuery).then((countSnapshot) => {
				dispatch({
					type: FIREBASE_ACTION_TYPE.GET_NOTIFICATIONS_COUNT,
					status: true,
					payload: countSnapshot.data().count,
					message: 'Notifications fetched sucessfully!',
				});
			});
		});

		// Firebase error Handling
		onLog((e) => {
			const { level, message } = e;
			if (level === 'error') {
				dispatch({
					type: FIREBASE_ACTION_TYPE.GET_NOTIFICATIONS_COUNT,
					status: false,
					payload: 0,
					message: message.includes('Internet connection') ? 'Network Error' : message,
				});
			}
		});

		return unsubscribe;
	};
};

const seenNotification = (uuid, id) => {
	return async (dispatch, getState) => {
		let { env } = getState().user.LoginData;
		if (!env) return;
		const docRef = doc(db, `notifications-${env}`, id);

		await updateDoc(docRef, {
			seen: true,
		});
		await dispatch(getRecentNotifications(uuid));
	};
};

// *****--Get Admin For Every Role Which in needed For Chats--*****
const getAdminUser = (invoiceObj) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/users/retrive-admin`)
			.then((response) => {
				dispatch({
					type: FIREBASE_ACTION_TYPE.admin,
					payload: { loaded: true, data: response.data.data, message: '' },
				});
			})
			.catch((error) => {
				dispatch({
					type: FIREBASE_ACTION_TYPE.admin,
					payload: {
						loaded: false,
						data: [],
						message:
							error.code === 'ERR_NETWORK'
								? error?.message
								: error?.response?.data?.error?.raw?.message
								? error?.response?.data?.error?.raw?.message
								: error?.response?.data?.error,
					},
				});
			});
	};
};

// *****--Get My Active Connections with anyone Chats--*****
const getMyConnection = ({ senderId, receiverId }) => {
	return async (dispatch) => {
		const activitiesCollection = collection(db, 'messages');
		const selectionQuery = query(
			activitiesCollection,
			where('ids', 'array-contains', receiverId),
			where('title', '==', 'General Chat Room')
		);
		onSnapshot(selectionQuery, (snapshot) => {
			const myConnection = [];
			snapshot.forEach((doc) => {
				if (doc.data().ids.includes(senderId)) {
					let myNode = doc.data();
					myNode.docId = doc.id;
					myNode.createdAt = doc.data().createdAt.toDate();
					myConnection.push(myNode);
				}
			});
			dispatch({
				type: FIREBASE_ACTION_TYPE.myConnection,
				payload: { status: true, data: myConnection, message: '' },
			});
		});
		// Firebase error Handling
		onLog((e) => {
			const { level, message } = e;
			if (level === 'error') {
				dispatch({
					type: FIREBASE_ACTION_TYPE.myConnection,
					payload: {
						status: false,
						data: [],
						message: message.includes('Internet connection') ? 'Network Error' : message,
					},
				});
			}
		});
	};
};

// *****--Read Some One Personal Chats--*****
const readPersonalChats = (myId) => {
	console.log('MY ID>>>>', myId);
	return async (dispatch) => {
		try {
			const parentCollection = 'messages';
			const subcollectionName = 'chats';

			// Create a reference to the subcollection
			const subcollectionRef = collection(db, parentCollection, myId, subcollectionName);

			// Create a query for the subcollection
			const subcollectionQuery = query(subcollectionRef);

			// Listen for real-time changes to the subcollection
			await onSnapshot(subcollectionQuery, (snapshot) => {
				let chats = [];
				chats.length = 0;
				snapshot.forEach((doc) => {
					let myNode = doc.data();
					myNode.docId = doc.id;
					myNode.createdAt = doc.data().createdAt.toDate();
					chats.push(myNode);
				});
				dispatch({
					type: FIREBASE_ACTION_TYPE.READPERSONAL,
					status: true,
					data: chats,
					message: 'Data Get chats!',
					id: myId,
				});
			});
			// Firebase error Handling
			onLog((e) => {
				const { level, message } = e;
				if (level === 'error') {
					dispatch({
						type: FIREBASE_ACTION_TYPE.READPERSONAL,
						status: false,
						data: [],
						message: message.includes('Internet connection') ? 'Network Error' : message,
					});
				}
			});
		} catch (error) {}
	};
};

// *****--Write Chat Personal Connection if not Exist--*****
const writeChatPersonalConnection = (messageObj, addMessage) => {
	return async (dispatch) => {
		try {
			let {
				receiverId,
				senderId,
				createdAt,
				title,
				body,
				draft,
				updatedAt,
				isTyping,
				messageType,
			} = messageObj;

			let mainCollection = 'messages';

			let refrenceObj = {
				ids: [senderId, receiverId],
				title,
				createdAt,
				draft,
				updatedAt,
				isTyping,
				[senderId]: 0,
				[receiverId]: 0,
			};
			let chat = { receiverId, senderId, body, createdAt, messageType };

			// Define collection reference
			const messagesCollection = collection(db, mainCollection);

			if (!addMessage) {
				delete refrenceObj.updatedAt;
			}
			// Add the message to the mainCollection collection
			const docRef = await addDoc(messagesCollection, refrenceObj);

			if (senderId && docRef?.id) {
				const socket = io(BASEURL, { transports: ['websocket'] });
				socket.emit('IsTyping', { uuid: senderId, docId: docRef.id });
			}

			if (addMessage) {
				// Store Chat in store while create connection

				const nestedCollectionRef = collection(db, mainCollection, docRef.id, 'chats');

				await addDoc(nestedCollectionRef, chat).then(() => {
					dispatch({
						type: FIREBASE_ACTION_TYPE.WRITEPERSONAL,
						status: true,
						message: 'message send successfully!',
					});
					// Update Notification
					dispatch(updateNotification({ docId: docRef.id, notificationObj: { [receiverId]: 1 } }));
				});
			} else {
				dispatch({
					type: FIREBASE_ACTION_TYPE.WRITEPERSONAL,
					status: true,
					message: 'message send successfully!',
				});
			}
		} catch (error) {
			console.log('ERROR>>>>>>', error);
			dispatch({
				type: FIREBASE_ACTION_TYPE.WRITEPERSONAL,
				status: false,
				message: error.toString(),
			});
		}
	};
};

// *****--Write Chat Reply To Someone--*****
const writeChatPersonalReply = (chatObject, docId, updateChatConn) => {
	return async (dispatch) => {
		try {
			const messageRef = doc(db, 'messages', docId);
			await updateDoc(messageRef, updateChatConn);
			const nestedCollectionRef = collection(db, 'messages', docId, 'chats');
			await addDoc(nestedCollectionRef, chatObject).then(() => {
				dispatch({
					type: FIREBASE_ACTION_TYPE.writeReplyPersonal,
					status: true,
					message: 'message send successfully!',
				});
			});
		} catch (error) {
			dispatch({
				type: FIREBASE_ACTION_TYPE.writeReplyPersonal,
				status: false,
				message: error.toString(),
			});
		}
	};
};

// *****--Read Personal Connections--*****
const readPersonalConnections = (myId) => {
	return async (dispatch) => {
		try {
			const messagesCollection = collection(db, 'messages');
			const querySnapshot = query(messagesCollection, where('ids', 'array-contains', myId));

			onSnapshot(querySnapshot, (snapshot) => {
				let topics = [];
				topics.length = 0;
				let notification = 0;
				snapshot.forEach((doc) => {
					let obj = doc.data();
					obj.docId = doc.id;
					obj.createdAt = doc.data().createdAt?.toDate();
					obj.updatedAt = doc.data().updatedAt?.toDate();
					obj.new = Math.random() * 1000;
					topics.push(obj);
					notification = notification + doc.data()[myId];
					// console.log("COUNT>>>>", doc.data(), myId);
				});

				dispatch({
					type: FIREBASE_ACTION_TYPE.personalConnections,
					payload: { loaded: true, data: topics, message: '', notification, myId },
				});
			});

			// Firebase error Handling
			onLog((e) => {
				const { level, message } = e;
				if (level === 'error') {
					dispatch({
						type: FIREBASE_ACTION_TYPE.personalConnections,
						payload: {
							loaded: false,
							data: [],
							message: message.includes('Internet connection') ? 'Network Error' : message,
						},
					});
				}
			});
		} catch (error) {}
	};
};

const readPersonalGroupConnections = (myId) => {
	return async (dispatch) => {
		try {
			const messagesCollection = collection(db, 'messages');
			const querySnapshot = query(messagesCollection, where('ids', 'array-contains', myId));

			onSnapshot(querySnapshot, (snapshot) => {
				let topics = [];
				topics.length = 0;
				let notification = 0;
				snapshot.forEach((doc) => {
					let obj = doc.data();
					obj.docId = doc.id;
					obj.createdAt = doc.data().createdAt?.toDate();
					obj.updatedAt = doc.data().updatedAt?.toDate();
					obj.new = Math.random() * 1000;
					topics.push(obj);
					notification = notification + doc.data()[myId];
					// console.log("COUNT>>>>", doc.data(), myId);
				});

				dispatch({
					type: FIREBASE_ACTION_TYPE.personalGroupConnections,
					payload: { loaded: true, data: topics, message: '', notification, myId },
				});
			});

			// Firebase error Handling
			onLog((e) => {
				const { level, message } = e;
				if (level === 'error') {
					dispatch({
						type: FIREBASE_ACTION_TYPE.personalGroupConnections,
						payload: {
							loaded: false,
							data: [],
							message: message.includes('Internet connection') ? 'Network Error' : message,
						},
					});
				}
			});
		} catch (error) {}
	};
};

// *****--Update Connection For User Typing or not--*****
const updateConnection = (uuid, myId, action) => {
	return async (dispatch) => {
		if (uuid === undefined) {
			return;
		}
		try {
			const docRef = doc(db, 'messages', uuid);
			if (action === 'add') {
				await updateDoc(docRef, {
					isTyping: arrayUnion(myId),
				});
			} else {
				await updateDoc(docRef, {
					isTyping: arrayRemove(myId),
				});
			}
		} catch (error) {
			console.log(error);
		}
	};
};

// *****--Get And Filter Firebase Users--*****
const getFirebaseUsers = (chatUsers) => {
	return async (dispatch) => {
		const uuids = chatUsers?.map((data) => data?.uuid);
		const activitiesCollection = collection(db, 'chatUsers');
		const selectionQuery = query(activitiesCollection);
		onSnapshot(selectionQuery, (snapshot) => {
			const myUsers = [];
			snapshot.forEach((doc) => {
				if (uuids.includes(doc.data()?.uuid)) {
					myUsers.push(doc.data());
				}
			});
			dispatch({
				type: FIREBASE_ACTION_TYPE.chatStatus,
				payload: { loaded: true, data: myUsers, message: 'Success' },
			});
		});
		// Firebase error Handling
	};
};

// *****--Write Chat Reply To Someone--*****
const updateNotification = ({ docId, notificationObj }) => {
	console.log(docId, notificationObj);
	return async (dispatch) => {
		try {
			const messageRef = doc(db, 'messages', docId);
			await updateDoc(messageRef, notificationObj);
		} catch (error) {
			console.log(error);
		}
	};
};

// *****--Store Doc Id in Store To Track Record--*****
const saveDocID = (id) => {
	return async (dispatch) => {
		dispatch({
			type: FIREBASE_ACTION_TYPE.saveDocId,
			payload: id,
		});
	};
};

// *****--Reset Store State--*****
const resetPersonalConnection = () => {
	return async (dispatch) => {
		dispatch({
			type: FIREBASE_ACTION_TYPE.personalConnectionsIdle,
		});
	};
};

// *****--Reset Store State--*****
const resetWriteMessage = () => {
	return async (dispatch) => {
		dispatch({
			type: FIREBASE_ACTION_TYPE.WriteIdle,
		});
	};
};

// *****--Reset Store State--*****
const resetReplyMessage = () => {
	return async (dispatch) => {
		dispatch({
			type: FIREBASE_ACTION_TYPE.writeReplyIdle,
		});
	};
};

// *****--Reset Store State--*****
const resetReadChats = () => {
	return async (dispatch) => {
		dispatch({
			type: FIREBASE_ACTION_TYPE.readIdle,
		});
	};
};

// *****--Reset Store State--*****
const resetReadChatsArray = () => {
	return async (dispatch) => {
		dispatch({
			type: FIREBASE_ACTION_TYPE.readIdleArray,
		});
	};
};

// *****--Reset Store State--*****
const resetReadTopics = () => {
	return async (dispatch) => {
		dispatch({
			type: FIREBASE_ACTION_TYPE.topicUserListIdle,
		});
	};
};

// *****--Reset Store State--*****
const resetMyConnection = () => {
	return async (dispatch) => {
		dispatch({
			type: FIREBASE_ACTION_TYPE.myConnectionIdle,
		});
	};
};

// *****--Reset Store State--*****
const resetWritePersonalMessage = () => {
	return async (dispatch) => {
		dispatch({
			type: FIREBASE_ACTION_TYPE.WritePersonalIdle,
		});
	};
};

// *****--Reset Store State--*****
const resetReadPersonalChatsArray = () => {
	return async (dispatch) => {
		dispatch({
			type: FIREBASE_ACTION_TYPE.readPersonalIdle,
		});
	};
};

// *****--Reset Store State--*****
const resetReplyPersonalMessage = () => {
	return async (dispatch) => {
		dispatch({
			type: FIREBASE_ACTION_TYPE.writeReplyPersonalIdle,
		});
	};
};

const writeStorge = (childRef, file, emit) => {
	return async (dispatch) => {
		try {
			const storage = getStorage();
			const storageRef = ref(storage, childRef);
			const uploadTask = uploadBytesResumable(storageRef, file);
			uploadTask.on(
				'state_changed',
				(snapshot) => {
					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
					// const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				},
				(error) => {
					console.log('eeee', error);
					dispatch({
						type: FIREBASE_ACTION_TYPE.writeStorageFail,
						status: false,
					});
				},
				() => {
					// Upload completed successfully, now we can get the download URL
					getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
						console.log('File available at', downloadURL);

						if (emit) {
							const socket = io(BASEURL, { transports: ['websocket'] });
							socket.emit('imageUpload', emit);
						}

						dispatch({
							type: FIREBASE_ACTION_TYPE.writeStorageSuccess,
							status: true,
							payload: { downloadURL, path: childRef },
						});
					});
				}
			);
		} catch (error) {
			console.log('eeee', error);
			dispatch({
				type: FIREBASE_ACTION_TYPE.writeStorageFail,
				status: false,
			});
		}
	};
};
const writeStorageIdle = () => {
	return async (dispatch) => {
		dispatch({
			type: FIREBASE_ACTION_TYPE.writeStorageIdle,
			status: 'idle',
		});
	};
};
//  *****--Function to Get Status of (Online, Offline)--****
const getMyStatus = (chatStatus, uuid, type) => {
	let filter = chatStatus.find((data) => data?.uuid === uuid);
	if (type === 'status') {
		if (filter) {
			return filter.status;
		} else {
			return 'offline';
		}
	}
	if (type === 'lastSeen') {
		if (filter === undefined) {
			return 'offline';
		}
		if (filter.status === 'online') {
			return filter.status;
		}
		const lastSeen = moment(filter.lastSeen.toDate());
		const now = moment();

		const diffInMinutes = now.diff(lastSeen, 'minutes');

		const diffInMonth = now.diff(lastSeen, 'months');

		const formattedTime =
			diffInMinutes < 1
				? 'just now'
				: diffInMinutes < 60
				? `${diffInMinutes} mins ago`
				: diffInMonth > 0 && diffInMonth < 12
				? `${diffInMonth} mon ago`
				: lastSeen.fromNow() === 'a month ago'
				? `1 mon ago`
				: lastSeen.fromNow();

		return formattedTime;
	}
};

const reset = () => {
	return async (dispatch) => {
		dispatch({ type: FIREBASE_ACTION_TYPE.RESET });
	};
};

export const firebase = {
	readChats,
	saveDocID,
	getAdminUser,
	writeChatReply,
	resetReadChats,
	writeChatDraft,
	resetReadTopics,
	getMyConnection,
	getNotifications,
	seenNotification,
	updateConnection,
	getFirebaseUsers,
	resetWriteMessage,
	readtopicUserList,
	resetReplyMessage,
	resetMyConnection,
	readPersonalChats,
	writeChatConnection,
	resetReadChatsArray,
	ChangeDraftToMessage,
	getNotificationsCount,
	getRecentNotifications,
	writeChatPersonalReply,
	readPersonalConnections,
	resetPersonalConnection,
	resetWritePersonalMessage,
	resetReplyPersonalMessage,
	resetReadPersonalChatsArray,
	writeChatPersonalConnection,
	updateNotification,
	writeStorge,
	writeStorageIdle,
	getMyStatus,
	readPersonalGroupConnections,
	reset,
};
