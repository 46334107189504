import React, { useState, useEffect } from "react";
import Input from "./fields/Input";
import Button from "./fields/Button";
import OtpInputForm from "./OtpInputForm";
import Message from "./fields/Message";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../Store/action";
import axios from "axios";
import { BASEURL } from "../../utilites";
export default function TwoAuthVerificationForm({
  phoneNo,
  setPhone,
  message,
  setMessage,
  otpForm,
  setOtpForm,
  LoginData,
  twoAuthEnabled,
  setTwoAuthEnabled,
  CloseModalOverlay,
}) {
  const dispatch = useDispatch();
  const otplength = 6;
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [formNote, setFormNote] = useState("");
  const [loading,setLoading] = useState(false)
  const [is2factPaused,setIs2factPaused] = useState(LoginData.authorization === 'paused' ? true : false )

  const [
    {
      EnableAuthSendOtp,
      EnableAuthSendMessage,
      EnableAuthVerifyOtp,
      EnableAuthVerifyMessage,
      OldAuthSendOtp,
      OldAuthSendMessage,
      OldAuthVerifyOtp,
      OldAuthVerifyMessage,
    },
  ] = useSelector((state) => [state.factAuth]);
  // set the form data
  useEffect(() => {
    if (LoginData.authorization === "0") {
      setTwoAuthEnabled(false);
      setFormNote("Enter Your Phone Number to Enable Two-Auth Verification");
    } else {
      setTwoAuthEnabled(true);
      setFormNote("Enter Your New Number to Change Two-Auth Verification");
    }
    setEmail(LoginData?.email);
    setPhone(LoginData?.phone);
  }, [LoginData, setTwoAuthEnabled, setEmail, setPhone, setFormNote]);

  // HOOK To SET/Reset Enable Send OTP MESSAGE
  useEffect(() => {
    if (!EnableAuthSendOtp) {
      setMessage({
        show: true,
        type: "Error",
        text: EnableAuthSendMessage,
      });
    }
    if (EnableAuthSendOtp === true) {
      setMessage({
        show: true,
        type: "Success",
        text: EnableAuthSendMessage,
      });
      setOtpForm(true);
    }
  }, [EnableAuthSendOtp, EnableAuthSendMessage, setOtpForm, setMessage]);

  // HOOK To SET/Reset Enable Verify OTP MESSAGE
  useEffect(() => {
    if (!EnableAuthVerifyOtp) {
      setMessage({
        show: true,
        type: "Error",
        text: EnableAuthVerifyMessage,
      });
    }
    if (EnableAuthVerifyOtp === true) {
      // Re Get User Data / Remove User Old Data / Reset Enable Verify Otp  / Close Modal Background overlay
      dispatch(allActions.user.removeUserData());
      dispatch(allActions.user.ReGetUserData());
      dispatch(allActions.factAuth.resetEnableVerifyOtp());
      CloseModalOverlay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EnableAuthVerifyOtp, EnableAuthVerifyMessage]);

  // HOOK To Reverify Old Send OTP MESSAGE
  useEffect(() => {
    if (!OldAuthSendOtp) {
      setMessage({
        show: true,
        type: "Error",
        text: OldAuthSendMessage,
      });
    }
    if (OldAuthSendOtp === true) {
      setMessage({
        show: true,
        type: "Success",
        text: OldAuthSendMessage,
      });
      setOtpForm(true);
      setTwoAuthEnabled(false);
    }
  }, [
    OldAuthSendOtp,
    OldAuthSendMessage,
    setMessage,
    setOtpForm,
    setTwoAuthEnabled,
  ]);
  // HOOK To Reverify Old Verify OTP MESSAGE
  useEffect(() => {
    if (!OldAuthVerifyOtp) {
      setMessage({
        show: true,
        type: "Error",
        text: OldAuthVerifyMessage,
      });
    }
    if (OldAuthVerifyOtp === true) {
      setOtpForm(false);
      setTwoAuthEnabled(false);
      setMessage({
        show: true,
        type: "Success",
        text: OldAuthVerifyMessage,
      });
    }
  }, [
    OldAuthVerifyOtp,
    OldAuthVerifyMessage,
    setOtpForm,
    setTwoAuthEnabled,
    setMessage,
  ]);

  // Function To check Number is Validate USA number
  function ValidateNumber(phoneNumber) {
    if (phoneNumber && phoneNumber?.length === 14) {
      return true;
    } else {
      return false;
    }
  }
  // Function to Reset Message
  const reset_mesage = () => {
    setMessage({ show: false });
    setOtp("");
  };
  // function to handle form form submission
  const handleFormSubmission = () => {
    reset_mesage();
    const phoneNoValidity = ValidateNumber(phoneNo);
    if (phoneNoValidity) {
      dispatch(allActions.factAuth.resetEnableSendOtp());
      dispatch(
        allActions.factAuth.enableTwoFactAuthSendOtp({
          phoneNumber: phoneNo,
        })
      );
    } else {
      setMessage({
        show: true,
        type: "Error",
        text: "Enter Valid Phone Number! Like : (999) 999-9999",
      });
    }
  };

  const handleToggle2factAuth = async (e) => {
    if(e.target.checked){
      setLoading(true)
      try {
        axios.post(`${BASEURL}/api/2fact/setting/pause2fact`)
        setTimeout(() => {
          setLoading(false)
          setIs2factPaused(true)
        },1000)
       } catch (error) {
        console.log(error);
       }
    }else{
      setLoading(true)
      try {
        axios.post(`${BASEURL}/api/2fact/setting/resume2fact`)
        setTimeout(() => {
          setLoading(false)
          setIs2factPaused(false)
        },1000)
       } catch (error) {
        console.log(error);
       }
    }
  }
  // Function to handle Send Otp if user is already Registered
  const handleAlreadyOldOtpSend = () => {
    dispatch(allActions.factAuth.resetOldSendOtp());
    dispatch(
      allActions.factAuth.OtpSendOld({
        phoneNumber: phoneNo,
        method: "phone",
      })
    );
  };
  return (
    <div>
      {message.show ? <Message message={message} /> : <></>}
      {twoAuthEnabled ? (
        <>
          <div>
            <p className="text-center small-text text-muted">
              You Have {is2factPaused ? 'Paused' : 'Enabled'} Two-Auth Verification On These Details
            </p>
            <div className="mb-3">
              <Input
                type="phonNo"
                value={LoginData?.phone}
                name="phoneNo"
                placeholder="Phone No"
                fn={setPhone}
                isDisabled={true}
                mask="(999) 999-9999"
                verified={true}
              />
            </div>
            <div className="mb-3">
              <Input
                type="Email"
                value={email}
                name="Email"
                placeholder="Email"
                fn={setEmail}
                isDisabled={true}
                verified={true}
              />
            </div>
            <div className="d-flex align-items-center  gap-2">
             {loading ?  <div class="spinner-border spinner-border-sm text-primary" role="status">
              </div> :  <input
                className="cursor-pointer"
                type="checkbox"
                checked={is2factPaused}
                id="enable-2fact"
                name="enable-2fact"
                onChange={(e) => handleToggle2factAuth(e)}
              />}
              <label className="cursor-pointer text-muted" htmlFor="enable-2fact">{is2factPaused ? "Enable" : 'Pause'} 2fact Authentication{is2factPaused ? " with These Details" : ""}</label>
            </div>
            <Button
              mainClass="btn btn-dark btn-lg"
              wrapperClass="clearfix d-grid text-center mt-4"
              buttonText="Change Number"
              type={"button"}
              callback={() => {
                handleAlreadyOldOtpSend();
              }}
            />
          </div>
        </>
      ) : otpForm ? (
        <OtpInputForm
          setMessage={setMessage}
          otplength={otplength}
          phoneNo={phoneNo}
          otp={otp}
          setOtp={setOtp}
          setOtpForm={setOtpForm}
          LoginData={LoginData}
          setTwoAuthEnabled={setTwoAuthEnabled}
          note={"Please Check Your Inbox Otp is Alerady Shared On"}
          reset_mesage={reset_mesage}
        />
      ) : (
        <div>
          <p className="text-center small-text text-muted">{formNote}</p>
          <div className="mb-3">
            <Input
              type="phonNo"
              value={phoneNo}
              name="phoneNo"
              placeholder="Phone No"
              mask="(999) 999-9999"
              fn={setPhone}
            />
          </div>
          <Button
            mainClass="btn btn-dark btn-lg"
            wrapperClass="clearfix d-grid text-center mt-4"
            buttonText="Submit"
            type={"button"}
            callback={() => handleFormSubmission()}
          />
        </div>
      )}
    </div>
  );
}
