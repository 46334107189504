import allActions from '../Store/action';

export const useAutoReplyHook = (dispatch) => {
	const handleAutoReply = ({ personalConnections, LoginData, receiver, type, encodedString }) => {
		
		// console.log(
		// 	'FILTERED CONNECTION>>>>>>',
		// 	personalConnections,
		// 	LoginData,
		// 	receiver,
		// 	type,
		// 	encodedString
		// );
		if (!personalConnections || !LoginData || !receiver || !type || !encodedString) return;

		let filteredConnection = personalConnections?.data?.filter(
			(data) => data?.ids?.includes(LoginData?.uuid) && data?.ids?.includes(receiver?.uuid)
		);

		// console.log("filteredConnection>>>>>>>",filteredConnection);

		if (filteredConnection?.length > 0) {
			let chatObject = {
				body: encodedString ? encodedString : '<p></p>\n',
				senderId: LoginData?.uuid,
				receiverId: receiver?.uuid,
				createdAt: new Date(),
				messageType: type,
			};

			// console.log("chatObject",chatObject);
			// it Update Notification as well updated At property
			let updateChatConn = {
				[receiver?.uuid]: filteredConnection?.[0]?.[receiver?.uuid] + 1,
				updatedAt: new Date(),
			};

			dispatch(
				allActions.firebase.writeChatPersonalReply(
					chatObject,
					filteredConnection?.[0].docId,
					updateChatConn
				)
			);
		}
		if (filteredConnection?.length === 0) {
			let topicObject = {
				title: 'General Chat Room',
				body: encodedString,
				senderId: LoginData?.uuid,
				receiverId: receiver?.uuid,
				createdAt: new Date(),
				draft: [],
				updatedAt: new Date(),
				isTyping: [],
				messageType: type,
			};
			let addMessage = true;

			dispatch(allActions.firebase.writeChatPersonalConnection(topicObject, addMessage));
		}
	};

	return handleAutoReply;
};

