import React, { useEffect } from 'react'
import { useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASEURL } from '../utilites';
import allActions from '../Store/action';
import { useDispatch,useSelector } from 'react-redux';

const AppointmentStatus = () => {
  const { statusId,uuid } = useParams();
  const [{ LoginData }] =
  useSelector((state) => [state.user, state.call]);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const changeLoading = (status) => {
    dispatch(allActions.global.Loader(status));
  };

  const getAppointment = async () => {
		changeLoading(true)
		try {
			const resp = await axios.get(`${BASEURL}/api/appointments/unauthorized/${uuid}`, { uuid })
      if(LoginData.id === resp.data.data.creator.id){
        navigate(`/appointment-details/${uuid}`)
        return
      }
      editAppointmentUserStatus(resp.data.data.id,statusId)
		} catch (error) {
			console.log(error)
		}
	}

  	const editAppointmentUserStatus = (id, status_id) => {
		changeLoading(true)
		axios
			.put(`${BASEURL}/api/appointments/change-status/${id}/${status_id}`)
			.then(() => {
        navigate(`/appointment-details/${uuid}`)
        changeLoading(false)
			})
	};
  useEffect(() => {
    getAppointment()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <></>
  )
}

export default AppointmentStatus