/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SingleCheckLabelSelector = ({ mapData, setValue, value, heading }) => {
	const random = Math.random() * 1000000;
	return (
		<div className="mt-3 row justify-content-between">
			<div className=" mb-3 px-3">
				<small className="text-dark fw-bold m-0" htmlFor={`SingleCheckLabelSelector-${heading}`}>
					{heading}
				</small>
				{JSON.parse(JSON.stringify(mapData))?.map((data, i) => {
					return (
						<div
							key={i}
							className="w-100 d-flex flex-row align-items-center justify-content-between mt-2"
						>
							<div className="col-md-12">
								<input
									onChange={() => setValue(data)}
									checked={value?.label === data?.label}
									className="form-check-input"
									type="radio"
									id={`addressType-${i}-${random.toFixed(0)}`}
									name={`SingleCheckLabelSelector-${heading}`}
								/>
								<label
									htmlFor={`addressType-${i}-${random.toFixed(0)}`}
									role="button"
									className="px-3 m-0 small"
								>
									{data?.label}
								</label>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SingleCheckLabelSelector;
