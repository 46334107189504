/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import TextArea from '../../fields/TextArea';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import allActions from '../../../../Store/action';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';

const FinancialEvents = () => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const dispatch = useDispatch();

	const { FinancialEventOptions } = GLOBAL_VARIABLES;
	const uploadFormData = HandleFormData({ parentIndex: 1, myIndex: 1 });
	const [{ taxPayerFormData, taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);

	// For Text Input Field

	const [comments, setComments] = useState('');

	// For Choice Questions

	const [Financialoptions, setFinancialoptions] = useState([]);

	const [errors, setErrors] = useState({}); // Track errors for form fields

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------
	useEffect(() => {
		setFinancialoptions(JSON.parse(JSON.stringify(FinancialEventOptions)));
	}, []);
	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.financialEventForm) {
			if (Object.keys(taxPayerForm?.data?.financialEventForm).length === 0) return;

			const { Financialoptions, comments } = taxPayerForm?.data?.financialEventForm;
			setFinancialoptions(Financialoptions);
			setComments(comments);
		}
	}, [taxPayerForm?.data?.financialEventForm]);

	// ---------------------------------------
	// Functions
	// ---------------------------------------
	const handleQuestions = (index, bool) => {
		const localFinancialoptions = Financialoptions.slice();
		localFinancialoptions[index].bool = bool;
		setFinancialoptions(localFinancialoptions);
	};

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		// Add more validation rules as needed for other fields
		if (
			Financialoptions?.flatMap((data) => {
				return data?.bool;
			}).includes(null)
		) {
			newErrors.AnswerOtherQuestions = 'Please Select Required Option';
		}

		setErrors(newErrors);

		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let errors = check_Validity();
		if (errors) return;

		let FormObj = { Financialoptions, comments };

		let formData = taxPayerForm;
		taxPayerForm.data.financialEventForm = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
	};

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 1;
		let myIndex = 1;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<p className="fw-bold">
					The following questions apply to the 2023 tax year unless otherwise specified
				</p>
				<div className=" mb-3 px-3">
					{Financialoptions?.map((data, i) => {
						return (
							<div key={i} className="w-100  align-items-center justify-content-between mt-2">
								<small className="col-md-12 form-check-label text-start" htmlFor={`checkbox-${i}`}>
									{data.question}
									{data.bool === null && (
										<span className="text-danger small-text mx-1">*Required</span>
									)}
								</small>
								<div className="col-md-12">
									<input
										onChange={() => handleQuestions(i, true)}
										checked={data?.bool === true}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 566}`}
										name={`checkbox-${i + 566}`}
									/>
									<label htmlFor={`checkbox-${i + 566}`} role="button" className="px-3 m-0 small">
										Yes
									</label>
									<input
										onChange={() => handleQuestions(i, false)}
										checked={data?.bool === false}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 999}`}
										name={`checkbox-${i + 999}`}
									/>
									<label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
										No
									</label>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<small className="ml-4 fw-bold">Comments</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3 mb-3 custom-left-form">
				<small className="text-dark fw-bold m-0 py-2">
					Enter Any Additional Information / Question here.
				</small>
				<div className="col-md-8 mb-3">
					<TextArea
						inputName="form-control"
						defaultValue={comments}
						placeholder="Comments"
						name="comments"
						fn={setComments}
						row="6"
						required={false}
					/>
				</div>
			</div>
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default FinancialEvents;
