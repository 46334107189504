export default function CustomerDashboardAsideCard({ title, plusButtonClickHandler, children }) {
	return (
		<div className='card border-1 border-light rounded'>
			<div className='card-body'>
				<div className='card-title d-flex justify-content-between align-items-center'>
					<h6 className='fw-bold m-0'>{title}</h6>
					{plusButtonClickHandler && (
						<button className='btn btn-sm text-secondary' onClick={plusButtonClickHandler}>
							+
						</button>
					)}
				</div>
				{children}
			</div>
		</div>
	);
}

