import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BASEURL } from '../../../../utilites';
import calendlyImage from '../../../../assets/img/calendly-logo.png';

const Calendly = ({ user, calendlyVerification }) => {
	const [calendlyAuthUrl, setCalendlyAuthUrl] = useState('');

	useEffect(() => {
		axios
			.get(`${BASEURL}/api/calendly/auth-url`)
			.then((res) => setCalendlyAuthUrl(res.data.data))
			.catch((error) => console.error('Error fetching Calendly auth URL:', error));
	}, []);

	return (
		<>
			{(user.isAdmin || user.isTaxPro || user.isAccountManager) && (
				<div className='clearfix py-3 border-1 border-light border-bottom'>
					<h4 className='text-dark'>Calendly Integration</h4>
					<p>
						<img src={calendlyImage} alt='Calendly' className='w-25' />
					</p>
					<p>
						<strong>Calendly</strong> is the scheduling automation platform with team-based
						scheduling, solutions and integrations for every department, and advanced security
						features.
					</p>
					<p className='text-dark'>
						<strong>Status: </strong>
						{calendlyVerification || user?.isCalendlyAuth ? 'Integrated' : 'No Integration'}
					</p>
					<p>
						<Link to={calendlyAuthUrl} className='btn btn-outline-primary' target='_blank'>
							{calendlyVerification || user?.isCalendlyAuth
								? 'Re-Authenticate Application'
								: 'Authenticate Application'}
						</Link>
					</p>
				</div>
			)}
		</>
	);
};

export default Calendly;
