import React from "react";
import BusinessInfoForm from "../../forms/BusinessInfoForm";

const BusinessInfo = ({active,setActive}) => {
  return (
    <div>
      <div className="w-full  border-grey rounded px-2 py-2">
        <BusinessInfoForm  active={active} setActive={setActive} />
      </div>
    </div>
  );
};

export default BusinessInfo;
