import moment from 'moment';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { getNotificationContentByActionType } from '../../../utilites';

export default function ActivitiesList({ uuid, notifications }) {
	return (
		<ul className="list-group list-group-flush">
			{notifications.fetching ? (
				<p>Loading...</p>
			) : (
				<>
					{notifications.data && notifications.data.length > 0 ? (
						notifications.data?.map((item) => (
							<li className="list-group-item" key={item.id}>
								<div className="row align-items-center">
									<div className="col ps-1 pe-3">
										<p className="text-black fw-bold m-0">
											{getNotificationContentByActionType({
												action_type: item.actionType,
												current_user: uuid,
												user_by: item.userBy,
												user_for: item.userFor,
											})}
										</p>
										<p className="text-muted m-0">{moment(item.createdAt).fromNow()}</p>
									</div>
									<div className="px-0 btn-group" style={{ width: 30 }}>
										<button
											type="button"
											className="btn btn-sm border-1 border-grey-200 text-grey-200 rounded-circle disabled"
											data-bs-toggle="dropdown"
											aria-expanded="false"
											style={{ width: 30, height: 30 }}
										>
											<BsThreeDotsVertical />
										</button>
										<ul className="dropdown-menu">
											<li>
												<a className="dropdown-item" href="##">
													Action
												</a>
											</li>
										</ul>
									</div>
								</div>
							</li>
						))
					) : (
						<p className="text-muted text-center m-0">
							You have no new notifications available to load right now.
						</p>
					)}
				</>
			)}
		</ul>
	);
}
