export default function Message({
  message,
  boxclass = message?.type === "Error"
    ? "alert-danger text-break "
    : "alert-success text-break ",
  wrapperClass = "clearfix mb-3",
}) {
  if (!message || !message?.type || (!message?.text && !message?.html)) return;
  return (
    <div className={wrapperClass}>
      <div className={"alert " + boxclass}>
        <font className="form-label">
          {typeof message?.type === "string" && message?.type}!{" "}
        </font>

        {message?.html ? (
          message?.html
        ) : (
          <>
            {message?.text && (
              <>
                {typeof message?.text === "string" && message?.text ? (
                  <font className="form-label">{message?.text}.</font>
                ) : (
                  <font className="form-label"> Internel Server Error.</font>
                )}
              </>
            )}
            {message?.text1 && (
              <>
                {typeof message?.text1 === "string" && message?.text1 ? (
                  <font className="form-label">{message?.text1}.</font>
                ) : (
                  <font className="form-label"> Internel Server Error.</font>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
