/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineCheck } from 'react-icons/ai';
import questions from '../../../utilites/questionnaireData.json';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../Store/action';
import { useEffect } from 'react';
import { getCurrentFormsObj, getSubObjectCount } from '../../../utilites/functions';

export default function TodoList({ appointments, assessment, jobs, payments, unsignContract }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [{LoginData},{ projects }] = useSelector((state) => [state.user,state.dashboard]);

	let assessmentContent = assessment?.content;
	// if (assessmentContent) {
	// 	assessmentContent = JSON.parse(assessment.content);
	// }
	const questionnaireState = {
		min: 0,
		max: questions.length,
		value: assessment !== null ? Object?.keys(assessmentContent).length : 0,
	};

	useEffect(() => {
		dispatch(allActions.dashboard.getProjects());
	}, []);

	const OpenMyForm = (document, jobId) => {
		if (!jobId || !document) return;
		document.lead_id = jobId;
		dispatch(allActions.dashboard.resetTaxPreparation());
		dispatch(allActions.taxForms.dispatchViewFormDetails({ document, navigate }));
	};



	return (
		<ul className="list-group list-group-flush">
			<li className="list-group-item">
				{LoginData.lead[0].userpro.user.settings?.allow_appointments && <div className="row align-items-center py-2">
					<div className="col-2 px-2">
						<div
							className={`rounded-circle bg-${
								appointments?.length > 0 ? 'success' : 'grey-100'
							} text-white d-flex justify-content-center align-items-center`}
							style={{ width: 20, height: 20 }}
						>
							<AiOutlineCheck />
						</div>
					</div>
					<div className="col-6 px-2">
						<p className="text-black fw-bold m-0">Schedule appointment</p>
					</div>
					<div className="col-4 px-1">
						<Link
							to="/dashboard/appointments"
							className={`btn btn-sm btn-outline-primary w-100${
								appointments?.length > 0 ? ' disabled' : ''
							}
						}`}
						>
							{appointments?.length > 0 ? 'Scheduled' : 'Schedule'}
						</Link>
					</div>
				</div> }
			</li>
			<li className="list-group-item">
				<div className="row align-items-center">
					<div className="col-2">
						<div
							className={`rounded-circle text-white d-flex justify-content-center align-items-center${
								questionnaireState.value !== questionnaireState.max ? ' bg-grey-100' : ' bg-success'
							}`}
							style={{ width: 20, height: 20 }}
						>
							<AiOutlineCheck />
						</div>
					</div>
					<div className="col-6 px-2">
						<p className="text-black fw-bold m-0">Questionnaire</p>
						<div className="progress my-1" style={{ height: 10 }}>
							<div
								className="progress-bar progress-bar-striped"
								role="progressbar"
								aria-valuenow={questionnaireState.value}
								aria-valuemin={questionnaireState.min}
								aria-valuemax={questionnaireState.max}
								style={{
									width: `${(questionnaireState.value / questionnaireState.max) * 100}%`,
								}}
							></div>
						</div>
						<small className="text-muted" style={{ fontSize: 13 }}>
							({Math.round((questionnaireState.value / questionnaireState.max) * 100)}
							%)
						</small>
					</div>
					<div className="col-4 px-1">
						<Link
							to="/dashboard/questionnaire"
							className={`btn btn-sm w-100 btn-${
								questionnaireState.value !== questionnaireState.max ? 'outline-primary' : 'primary'
							}`}
						>
							{assessment === null
								? 'Start'
								: questionnaireState.value === questionnaireState.max
								? 'Completed'
								: 'Continue'}
						</Link>
					</div>
				</div>
			</li>
			{projects?.loaded &&
				jobs?.length !== 0 &&
				jobs?.map((item) => {
					const formData = projects?.data?.find((data) => data.id === item?.id)?.form_data;
					const filledForms =
						formData?.[0]?.formData && item?.service?.id === 1
							? getSubObjectCount(formData?.[0]?.formData)
							: 0;
					const TotalForms =
						formData?.[0]?.name && item?.service?.id === 1
							? Object?.keys(getCurrentFormsObj(formData?.[0]?.name))?.length
							: 0;
					const filledRatio = (filledForms * 100) / TotalForms;
					return (
						<li className={`list-group-item ${item.status_id === 4 && 'd-none'}`} key={item.id}>
							<div className="row align-items-center pt-2">
								<div className="col-2 px-2">
									<div
										className={`rounded-circle  text-white d-flex justify-content-center align-items-center ${
											filledRatio.toFixed(0) > 99.99 ? 'bg-success' : 'bg-grey-100'
										}`}
										style={{ width: 20, height: 20 }}
									>
										<AiOutlineCheck />
									</div>
								</div>
								<div className="col-6 px-2">
									<p className="text-black fw-bold m-0">
										{item?.service?.id === 1 ? 'Tax Form' : 'Upload Document'}
									</p>
									{item?.service?.id === 1 && (
										<div className="progress my-1" style={{ height: 10 }}>
											<div
												className="progress-bar progress-bar-striped"
												role="progressbar"
												style={{ width: `${filledRatio.toFixed(0)}%` }}
												aria-valuenow={0}
												aria-valuemin="0"
												aria-valuemax="100"
											></div>
										</div>
									)}
									{item?.service?.id === 1 && (
										<span className="text-muted">({filledRatio.toFixed(0)}%)</span>
									)}
								</div>
								<div className="col-4 px-1">
									{item?.service?.id === 1 ? (
										<Link
											to="#"
											onClick={() => {
												OpenMyForm(formData?.[0], item?.id);
											}}
											className="btn btn-sm btn-outline-primary w-100"
										>
											{filledRatio.toFixed(0) > 99.99 ? 'View' : 'Complete'}
										</Link>
									) : (
										<Link
											to={`/dashboard/projects/${item.uuid}`}
											className="btn btn-sm btn-outline-primary w-100"
										>
											Complete
										</Link>
									)}
								</div>
							</div>
						</li>
					);
				})}
			{unsignContract?.map((item) => (
				<li className="list-group-item" key={item.id}>
					<div className="row align-items-center">
						<div className="col-2">
							<div
								className={`rounded-circle text-white d-flex justify-content-center align-items-center${
									item.status_id === 0 ? ' bg-grey-100' : ' bg-secondary-200'
								}`}
								style={{ width: 20, height: 20 }}
							>
								<AiOutlineCheck />
							</div>
						</div>
						<div className="col-6 px-2">
							<p className="text-black fw-bold m-0">Review + Sign Contract</p>
						</div>

						<div className="col-4 px-1">
							<Link
								to={`/dashboard/projects`}
								className={`btn btn-sm w-100 btn-${
									item.status_id === 0 ? 'outline-primary' : 'primary'
								}`}
							>
								{'GO'}
							</Link>
						</div>
					</div>
				</li>
			))}
			<li className="list-group-item">
				<div className="row align-items-center py-2">
					<div className="col-2 px-2">
						<div
							className={`rounded-circle bg-${
								payments.length > 0 ? 'success' : 'grey-100'
							} text-white d-flex justify-content-center align-items-center`}
							style={{ width: 20, height: 20 }}
						>
							<AiOutlineCheck />
						</div>
					</div>
					<div className="col-6 px-2">
						<p className="text-black fw-bold m-0">
							{payments.length > 0 ? 'Payment Verified' : 'Payment Method'}
						</p>
					</div>
					<div className="col-4 px-1">
						<Link to="/dashboard/settings" className="btn btn-sm btn-outline-primary w-100">
							{payments.length > 0 ? 'Update' : 'Add'}
						</Link>
					</div>
				</div>
			</li>
		</ul>
	);
}
