import React, { useEffect, useState } from "react";
import { usePaymentInputs } from "react-payment-inputs";
// import images from "react-payment-inputs/images";

export default function CreditCardInput({
	type,
	value,
	name,
	placeholder,
	required = true,
	label = placeholder,
	autoComplete = "on",
	inputClass = "form-control",
	wrapperClass = "clearfix",
	fn,
	onLeave,
	hasValidationError,
	isDisabled = false,
	showLabel = true,
	formid = name,
	// paymentcard,
	// editCard
}) {
	const {
		getCardNumberProps,
		//  getCardImageProps
		meta,
	} = usePaymentInputs();
	const [formattedValue, setFormattedValue] = useState("");
	useEffect(() => {
		if (value === "" || !value) {
			fn(value);
			setFormattedValue(value);
		} else {
			fn(value);
			setFormattedValue(value);
		}
	}, [value, fn]);
	const handleInputChange = (e) => {
		let inputValue = e.target.value;
		if (meta.cardType === undefined || meta.cardType.displayName === "Visa") {
			// Add spaces after every 4 characters
			inputValue = inputValue.replace(/[^0-9]/g, "").substring(0, 16);
			inputValue = inputValue.replace(/(.{4})/g, "$1 ");
		}
		setFormattedValue(inputValue);
		fn(inputValue);
	};

	return (
		<div className={wrapperClass}>
			{showLabel ? (
				<label htmlFor={name} className="form-label">
					{label + (required ? "*" : "")}
				</label>
			) : (
				""
			)}
			<input
				type="text"
				className={`${inputClass}${hasValidationError ? " is-invalid" : ""}`}
				placeholder={placeholder}
				aria-label={placeholder}
				required={required}
				disabled={isDisabled}
				onBlur={onLeave}
				id={formid}
				autoComplete={type === "password" ? "off" : autoComplete}
				{...getCardNumberProps({
					onChange: handleInputChange,
					value: formattedValue,
				})}
			/>
			{/* {paymentcard && !editCard && (
        <div className="position-absolute top-0 end-0 p-2">
          <svg {...getCardImageProps({ images })} className="mb-1" />
        </div>
      )} */}
		</div>
	);
}
