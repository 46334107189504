import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { HandleFormData } from '../../../../utilites';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';


const RealEstateTaxes = () => {

    const { realEstateTaxes, realEstateTaxesStyles } = GLOBAL_VARIABLES;

    const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 14 });
    const dispatch = useDispatch()
    const [{ taxPayerForm, taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);

    const [show, setShow] = useState({ zero: null, one: null })
    const [specificQuestions, setSpecificQuestions] = useState([]);
    const [specificQuestionsStyles, setSpecificQuestionsStyles] = useState([]);

    const [errors, setErrors] = useState({}); // Track errors for form fields

    useEffect(() => {
        setSpecificQuestions(JSON.parse(JSON.stringify(realEstateTaxes)));
        setSpecificQuestionsStyles(JSON.parse(JSON.stringify(realEstateTaxesStyles)));
    }, [realEstateTaxes, realEstateTaxesStyles]);

    const handleRadioChange = (index, value) => {
        if (index === 0) {
            setShow({ ...show, zero: value })
        }
        else {
            setShow({ ...show, one: value })
        }
        const updatedQuestions = [...specificQuestions];
        updatedQuestions[index].bool = value;
        setSpecificQuestions(updatedQuestions);
    };

    const handleInputChange = (index, value, data, valIdx) => {
        const filedKey = Object.keys(data).find(key => key !== 'placeholder' && key !== 'styleProp');
        const updatedQuestions = [...specificQuestions];
        updatedQuestions[index].values[valIdx][filedKey] = value;
        setSpecificQuestions(updatedQuestions);
    };

    useEffect(() => {
        if (taxPayerForm?.data?.RealEstateTaxes) {
            if (Object.keys(taxPayerForm?.data?.RealEstateTaxes).length === 0) return;
            const {
                specificQuestions
            } = taxPayerForm?.data?.RealEstateTaxes;
            setSpecificQuestions(specificQuestions);
        }
    }, [taxPayerForm?.data?.RealEstateTaxes]);

    const check_Validity = () => {
        setErrors({});
        const newErrors = {};

        // Add more validation rules as needed for other fields
        if (
            specificQuestions
                ?.flatMap((data) => {
                    return data?.bool;
                })
                .includes(null)
        ) {
            newErrors.AnswerOtherQuestions = 'Please Select Required Option';
        }

        setErrors(newErrors);

        let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
        // Check if there are any errors
        return ConditionCheck;
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        let errors = check_Validity();
        if (errors) return;

        let FormObj = {
            specificQuestions
        };
        let formData = taxPayerForm;
        taxPayerForm.data.RealEstateTaxes = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));

        uploadFormData(formData);
    };

    const goBack = () => {
        // CHANGE FORM TO BACK
        let parentIndex = 0;
        let myIndex = 14;
        dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
    };


    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                {specificQuestions.map((data, i) => {
                    return (
                        <>
                            {i === 0 && <small className="ml-4 fw-bold">{data.question}</small>}
                            {(i >= 1 && i <= 4) && <small className="ml-4 mt-2 fw-bold">
                                {data.question}

                                {data.bool === null && (
                                    <span className="text-danger small-text mx-1">*Required</span>
                                )}
                            </small>}

                            {(i >= 1 && i <= 4) && (<div className="col-md-12">
                                <input
                                    onChange={() => handleRadioChange(i, true)}
                                    checked={specificQuestions[i].bool === true}
                                    className="form-check-input"
                                    type="radio"
                                    id={`checkbox-${i + 176}`}
                                    name={`checkbox-${i + 176}`}
                                />
                                <label htmlFor={`checkbox-${i + 176}`} role="button" className="px-3 m-0 small">
                                    Yes
                                </label>
                                <input
                                    onChange={() => handleRadioChange(i, false)}
                                    checked={specificQuestions[i].bool === false}
                                    className="form-check-input"
                                    type="radio"
                                    id={`checkbox-${i + 999}`}
                                    name={`checkbox-${i + 999}`}
                                />
                                <label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
                                    No
                                </label>
                            </div>)}

                            {i === 0 && <>
                                {data?.values?.map((data, index) => {
                                    return (
                                        <div className={`my-1 ${specificQuestionsStyles?.[i]?.valData[index]?.styleProp}`}>
                                            <Input
                                                mask={specificQuestionsStyles?.[i]?.valData[index]?.mask}
                                                type="text"
                                                value={specificQuestions[i].values[index][Object.keys(data).find(key => key !== 'placeholder' && key !== 'styleProp')]}
                                                placeholder={specificQuestionsStyles?.[i]?.valData[index]?.placeholder}
                                                fn={(value) => handleInputChange(i, value, data, index)}
                                            />
                                        </div>)
                                })}
                            </>}
                            {(i >= 5 && i <= 7) && <>
                                {/* <small className="ml-4 fw-bold">{data.question}</small> */}
                                {data?.values?.map((data, index) => {
                                    return (
                                        <div className={`my-1 ${specificQuestionsStyles?.[i]?.valData[index]?.styleProp}`}>
                                            <Input
                                                mask={specificQuestionsStyles?.[i]?.valData[index]?.mask}
                                                type="text"
                                                value={specificQuestions[i].values[index][Object.keys(data).find(key => key !== 'placeholder' && key !== 'styleProp')]}
                                                placeholder={specificQuestionsStyles?.[i]?.valData[index]?.placeholder}
                                                fn={(value) => handleInputChange(i, value, data, index)}
                                            />
                                        </div>)
                                })}
                            </>}

                        </>
                    )
                })}
            </div>
            {Object.keys(errors)
                ?.map((key) => {
                    return errors[key];
                })
                ?.map((data, index) => {
                    return (
                        <>
                            <small className="text-primary" key={index}>
                                {data}*
                            </small>
                            <br />
                        </>
                    );
                })}
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="button"
                        callback={() => goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default RealEstateTaxes;