import React from 'react';

const MultipleCheckLabelSelector = ({
	col,
	label,
	mapData,
	handleAppliedOptions,
	selectedOptions,
}) => {
	console.log("@appliedOptionkks",selectedOptions);
	return (
		<div className="mt-3 row justify-content-between px-3 mb-3 custom-left-form">
			<div className=" mb-3 px-3">
				<small className="text-dark fw-bold m-0">{label}{selectedOptions?.length===0&&<span className='text-danger small-text mx-1'>*Required</span>}</small>
				<div className="w-100 d-flex flex-row flex-wrap">
					{mapData?.map((data, i) => {
						return (
							<div
								onClick={() => {
									handleAppliedOptions(data);
								}}
								key={i}
								className={`col-md-${col} d-flex align-items-start justfy-content-start mt-2`}
							>
								<input
									checked={
										selectedOptions?.length > 0 &&
										selectedOptions.some((item) => item?.question === data?.question)
									}
									className="form-check-input"
									type="checkbox"
									id={`identificationType${i}`}
								/>
								<small role="button" className="form-check-label text-start px-3">
									{data.question}
								</small>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default MultipleCheckLabelSelector;
