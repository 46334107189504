import SEO from '../components/SEO';

const About = () => {
	return (
		<div className='page px-3'>
			<SEO title='About' />
			<div className='container'>
				<div className='clearfix pt-4 text-center mb-4'>
					<h1>About Us</h1>
					<p>Contents for the About Us page of the company will go here.</p>
				</div>
			</div>
		</div>
	);
};

export default About;
