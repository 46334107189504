import axios from 'axios';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import Calendar from 'react-calendar';
import { Scrollbar } from 'react-scrollbars-custom';
import { useNavigate } from 'react-router-dom';
import {
	BASEURL,
	convertTime,
	convertTimeIntoSselectedTZ,
	timezoneFormatter,
} from '../../utilites';
import Button from './fields/Button';
import Checkbox from './fields/Checkbox';
// CSS
import 'react-datepicker/dist/react-datepicker.css';
import allActions from '../../Store/action';
import { useDispatch, useSelector } from 'react-redux';
import Message from './fields/Message';
import { CALL_ACTION_TYPE } from '../../Store/call/Type';
import ReactSelect from 'react-select';


const americanTimeZones = moment.tz.zonesForCountry('US');
const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export default function BookAppointmentForm({ appointments, lead }) {
	const [message, setMessage] = useState({ show: false });

	// seoul.getTimeZoneOffset();
	const [timezone, setTimezone] = useState(
		moment.tz.guess().startsWith('America/') ? moment.tz.guess() : 'America/Adak'
	);
	const [timezoneName, setTimezoneName] = useState(
		moment.tz.guess().startsWith('America/') ? moment.tz.guess() : 'America/Adak'
	);

	const [startDate, setStartDate] = useState();
	const [duration, setDuration] = useState(45);
	const [appointmentType, setAppointmentType] = useState(['video']);
	const [availableTimeTZ, setAvailableTimeTZ] = useState([]);
	const [availableTime, setAvailableTime] = useState([]);
	const [{ videoCallRoom }] = useSelector((state) => [state.call]);
	const [taxProAvailability, settaxProAvailability] = useState(false);
	const topTimezones = [
		'America/New_York',
		'America/Chicago',
		'America/Denver',
		'America/Los_Angeles',
	];

	const [errors, setErrors] = useState({
		timezone: false,
		startDate: false,
		duration: false,
		general: '',
	});

	const button = useRef();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (videoCallRoom.status === 'idle') resetMessage();
		// if Error While Creating Video Call Room
		if (!videoCallRoom.status) {
			button.current.disabled = false;
			// Reset Message and Loading
			resetMessage();
			setMessage({
				show: true,
				type: 'Error',
				text: videoCallRoom.message,
			});
			changeLoading(false);
		}

		// if Success After Creating Video Call
		if (videoCallRoom.status === true) {
			changeLoading(false);
			button.current.disabled = false;
			// Reset Message and Loading
			resetMessage();
			// Encode Room ID String
			if (startDate && videoCallRoom?.data?.meetingID) {
				bookVideoAppoinment(videoCallRoom?.data?.meetingID);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videoCallRoom, videoCallRoom.message, videoCallRoom.status]);

	// Function To video Appoinment
	const bookVideoAppoinment = (roomId) => {
		let endDateTime = moment(startDate).add(45, 'minutes');
		endDateTime = endDateTime.format('YYYY-MM-DD HH:mm:ss');
		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });

		const appointmentObj = {
			timezone: lead?.userpro?.user?.settings?.timezone,
			startDate: convertTimeIntoSselectedTZ(
				timezone,
				lead?.userpro?.user?.settings?.timezone,
				startDate
			),
			duration,
			roomId,
			endDate: convertTimeIntoSselectedTZ(
				timezone,
				lead?.userpro?.user?.settings?.timezone,
				endDateTime
			),
		};
		axios
			.post(
				`${BASEURL}/api/booking/${lead?.uuid}/${lead?.user?.uuid}/${lead?.userpro?.user?.uuid}`,
				appointmentObj
			)
			.then((res) => {
				button.current.disabled = false;
				changeLoading(false);
				navigate(`/thankyou?uuid=${lead?.uuid}&appointment=${res.data.data.appointment.uuid}`);
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				changeLoading(false);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

	// function to handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();
		resetMessage();
		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });
		changeLoading(true);
		let endDateTime = moment(startDate).add(45, 'minutes');
		endDateTime = endDateTime.format();
		await addAppointmentWithOutlook(endDateTime);
	};
	const addAppointmentWithOutlook = async (end_date) => {
		// add appointment into outlook calendar
		let appointmentDetail = {
			startTime: moment(startDate).format(),
			startDate,
			endTime: end_date,
			timeZone: timezone,
			subject: 'Booking',
			userId: lead?.userpro?.user?.id,
		};
		await axios
			.post(`${BASEURL}/api/outlookcalendar/add-event`, appointmentDetail)
			.then(async () => {
				await addAppointment(end_date);
			})
			.catch(async (error) => {
				if (error?.response?.data?.error === 'Appointment is not available for that timeslot') {
					button.current.disabled = false;
					console.error(error.message);
					changeLoading(false);
					// Set general error
					if (errors.general === '')
						setErrors({ errors, general: error?.response?.data?.error || error.message });
				} else {
					//  Bypass error  //
					await addAppointment(end_date);
				}
			});
	};
	const addAppointment = async (end_date) => {
		// reset Meeting Link
		if (appointmentType[0] === 'video') {
			const meetingData = {
				email: lead?.user?.email,
				startTime: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
				duration,
				timezone,
			};
			dispatch(allActions.call.dispatchToStore({ type: CALL_ACTION_TYPE.videoCallRoomIdle }));
			dispatch(allActions.call.createRoom(meetingData));
			return;
		}
		const appointmentObj = {
			timezone: lead?.userpro?.user?.settings?.timezone,
			startDate: convertTimeIntoSselectedTZ(
				timezone,
				lead?.userpro?.user?.settings?.timezone,
				startDate
			),
			duration,
			endDate: convertTimeIntoSselectedTZ(
				timezone,
				lead?.userpro?.user?.settings?.timezone,
				end_date
			),
		};
		axios
			.post(
				`${BASEURL}/api/booking/${lead?.uuid}/${lead?.user?.uuid}/${lead?.userpro?.user?.uuid}`,
				appointmentObj
			)
			.then((res) => {
				button.current.disabled = false;
				changeLoading(false);
				navigate(`/thankyou?uuid=${lead?.uuid}&appointment=${res.data.data.appointment.uuid}`);
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				changeLoading(false);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});

		// Reset the form
		setTimezone(moment.tz.guess().startsWith('America/') ? moment.tz.guess() : 'America/Adak');
		// setDefaultDateTime();
		setDuration(45);
		setAppointmentType(['video']);
	};

	const isExcludedDate = (date) => {
		const day = dayNames[date.getDay()];
		if (!lead?.userpro?.user?.availability?.time_ranges[day]) return true;
		return false;
	};

	const resetMessage = () => {
		changeLoading(false);
		setMessage({ show: false });
	};

	useEffect(() => {
		// check taxpro availability
		let taxProAvailability = false;
		for (let index = 0; index < dayNames.length; index++) {
			if (lead?.userpro?.user?.availability?.time_ranges[dayNames[index]]) {
				taxProAvailability = true;
				break;
			}
		}
		if (taxProAvailability) {
			settaxProAvailability(lead?.userpro?.user?.availability?.time_ranges);
			istaxProAvailable();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lead?.userpro?.user?.availability?.time_ranges]);

	const istaxProAvailable = () => {
		let i = 0;
		let date = new Date();
		while (i < 1) {
			date.setDate(date.getDate() + 1);
			const day = dayNames[date.getDay()];
			if (lead?.userpro?.user?.availability?.time_ranges[day] !== null) {
				setStartDate(date);
				const currentDate = moment(date);
				const dayOfWeek = currentDate.format('dddd');
				const slotDuration = 45;
				const timeSlots = [];
				const originaltimeSlots = [];
				// eslint-disable-next-line array-callback-return
				lead?.userpro?.user?.availability?.time_ranges[dayOfWeek]?.map((v, i) => {
					originaltimeSlots.push(
						...getTimeSlots(v.from, v.to, slotDuration, currentDate.format('YYYY-MM-DD'))
							.originaltimeSlots
					);
					timeSlots.push(
						...getTimeSlots(v.from, v.to, slotDuration, currentDate.format('YYYY-MM-DD')).timeSlots
					);
				});

				setAvailableTime(originaltimeSlots);
				setAvailableTimeTZ(timeSlots);
				i++;
			}
		}
	};

	const haandleChange = (date) => {
		setStartDate(date);
		const currentDate = moment(date);
		const dayOfWeek = currentDate.format('dddd');
		const slotDuration = 45;
		const timeSlots = [];
		const originaltimeSlots = [];
		setAvailableTime();
		// eslint-disable-next-line array-callback-return
		lead?.userpro?.user?.availability?.time_ranges[dayOfWeek]?.map((v, i) => {
			originaltimeSlots.push(
				...getTimeSlots(v.from, v.to, slotDuration, currentDate.format('YYYY-MM-DD'))
					.originaltimeSlots
			);
			timeSlots.push(
				...getTimeSlots(v.from, v.to, slotDuration, currentDate.format('YYYY-MM-DD')).timeSlots
			);
		});
		setAvailableTime(originaltimeSlots);
		setAvailableTimeTZ(timeSlots);
	};

	const getTimeSlots = (startTime, endTime, slotDuration, currentDate) => {
		const timeSlots = [];
		const originaltimeSlots = [];

		const startDateTime = moment(`${currentDate} ${startTime}`, 'YYYY-MM-DD HH:mm:ss');
		const endDateTime = moment(`${currentDate} ${endTime}`, 'YYYY-MM-DD HH:mm:ss');
		let currentTime = startDateTime;

		while (currentTime.isSameOrBefore(endDateTime)) {
			const currentTimeWithTZ = moment.tz(
				currentTime.format('YYYY-MM-DDTHH:mm:ss'),
				lead?.userpro?.user?.settings?.timezone
			);

			originaltimeSlots.push(moment(currentTimeWithTZ).format('YYYY-MM-DD HH:mm:ss'));
			timeSlots.push(moment(currentTimeWithTZ).tz(timezone).format('YYYY-MM-DD HH:mm:ss'));
			currentTime.add(slotDuration, 'minutes');
		}
		originaltimeSlots.pop();
		timeSlots.pop();
		return {
			originaltimeSlots,
			timeSlots,
		};
	};

	const convertTimesToSelectedTimezone = (selectedTimezone) => {
		setTimezone(selectedTimezone.value);
		setTimezoneName(selectedTimezone.label);
		setAvailableTimeTZ(
			availableTime?.map((time) => {
				const currentTimeWithTZ = moment.tz(
					moment(time).format('YYYY-MM-DDTHH:mm:ss'),
					lead?.userpro?.user?.settings?.timezone
				);
				return moment(currentTimeWithTZ).tz(selectedTimezone.value).format('YYYY-MM-DD HH:mm:ss');
			})
		);
	};
	const minDate = () => {
		var currentDate = new Date();
		currentDate.setDate(currentDate.getDate() + 1);
		return currentDate;
	};
	const maxDate = () => {
		if (taxProAvailability) {
			return (() => {
				var currentDate = new Date();
				currentDate.setDate(currentDate.getDate() + 1);
				let i = 0;
				let date = currentDate;
				while (i < 4) {
					date.setDate(date.getDate() + 1);
					if (!isExcludedDate(date)) {
						i++;
					}
				}
				return date;
			})();
		}
	};
	const titleDisabled = () => {
		return ({ date, view }) => {
			return isExcludedDate(date);
		};
	};

	const customStyles = {
		option: (provided) => ({
			...provided,
			textAlign: 'left',
		}),
		singleValue: (provided) => ({
			...provided,
			textAlign: 'left',
		}),
		placeholder: (provided) => ({
			...provided,
			textAlign: 'left',
			fontSize: 14,
		}),
	};
	function isTimeSlotBooked(slot, appointment) {  		  
		const slotTime = new Date(slot).getTime();
		const appointmentStartTime = new Date(convertTimeIntoSselectedTZ(
		  appointment?.timezone,
		  timezone,
		  moment.utc(appointment.start_date))).getTime();
		const appointmentEndTime = new Date(convertTimeIntoSselectedTZ(
		  appointment?.timezone,
		  timezone,
		  moment.utc(appointment.end_date))).getTime();
		
		// Check if the slot time is within the range of the appointment
		if(
		  slotTime >= appointmentStartTime && slotTime <= appointmentEndTime
	
		){
		  return slot
		}
	  }
	const availableTimeTZ2=()=>{
		const existingAppointments = appointments;
		var timezoneFinal=availableTimeTZ
		// Filter out any time slot that falls within any existing appointment range
		timezoneFinal = timezoneFinal.filter(slot => {
		  return !existingAppointments.some(appointment => isTimeSlotBooked(slot, appointment));
		});
	  
	   
		return timezoneFinal
	  }
	return (
		<>
			{taxProAvailability && (
				<form className="appointmentsform needs-validation" onSubmit={handleSubmit}>
					<div className="row">{message.show ? <Message message={message} /> : <></>}</div>

					<div className="row p-4 bg-white rounded">
						<div className="col-md-6">
							<div className="form-floating BookAppointmentForm__calendar">
								<Calendar
									className="border-0 bg-white"
									tileClassName=""
									value={startDate}
									onChange={haandleChange}
									tileDisabled={titleDisabled()}
									minDate={minDate()}
									maxDate={maxDate()}
									next2Label={null}
									prev2Label={null}
									formatShortWeekday={(locale, date) =>
										['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]
									}
									showFixedNumberOfWeeks={true}
								/>
							</div>
						</div>
						<div className="col-md-6 mt-3 mt-md-0">
							<ReactSelect
								id="users"
								className="p-0 basic-single"
								placeholder="Select User"
								styles={customStyles}
								closeMenuOnSelect={true}
								// options={americanTimeZones}
								options={[
									...topTimezones?.map((v) => ({
										value: v,
										label: timezoneFormatter(v),
									})),
									...americanTimeZones?.map((v) => ({
										value: v,
										label: timezoneFormatter(v),
									})),
								]}
								onChange={(v) => convertTimesToSelectedTimezone(v)}
								value={{
									value: timezone,
									label: `${timezoneFormatter(timezoneName)} (${convertTime(timezone, 'h:mm A')})`,
								}}
							/>
							<ul className="w-100 list-group list-group-flush mt-3" style={{ height: 250 }}>
								<Scrollbar width={3} className="w-100 h-100">
									{availableTimeTZ2()?.map((time, index) => (
										<li
											key={index}
											className={`list-group-item mt-2 py-1 cursor-pointer${
												startDate &&
												moment(time).hours() === startDate.getHours() &&
												moment(time).minutes() === startDate.getMinutes()
													? ' bg-primary text-white'
													: ' bg-light text-black'
											}`}
											onClick={() => {
												const newStartDate = moment(time);
												newStartDate.hours(moment(time).hours());
												newStartDate.minutes(moment(time).minutes());
												setStartDate(newStartDate.toDate());
											}}
										>
											{moment(time).format('h:mm A')}
										</li>
									))}
								</Scrollbar>
							</ul>
						</div>
					</div>

					<div className="row justify-content-center p-3 mb-3 bg-white">
						<div className="col-md-7 mx-auto rounded-bottom d-flex">
							<Checkbox
								type="radio"
								wrapperClass="text-start m-0 me-3"
								name="typeRadio"
								state={appointmentType}
								value={'video'}
								label={'Video Appointment'}
								fn={setAppointmentType}
								id={`radio-video-appointment-video`}
								required={true}
							/>
							<Checkbox
								type="radio"
								wrapperClass="text-start m-0"
								name="typeRadio"
								state={appointmentType}
								value={'call'}
								label={'Phone Appointment'}
								fn={setAppointmentType}
								id={`radio-call-appointment-phone`}
								required={true}
							/>
						</div>
					</div>
					<Button
						buttonRef={button}
						mainClass={`btn btn-lg btn-primary px-5${
							Object.values(errors).indexOf(true) > -1 || !timezone || !startDate ? ' disabled' : ''
						}`}
						buttonText="Book Appointment"
					/>
					{errors.general && errors.general !== '' && (
						<div className="row">
							<div className="mt-3">
								<div className="alert alert-danger" role="alert">
									{errors.general}
								</div>
							</div>
						</div>
					)}
				</form>
			)}
		</>
	);
}
