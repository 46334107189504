const Progess = ({Progess}) => {
  return (
    <div className="progress w-100 progessbar">
      <div
        className="progress-bar"
        style={{width: `${Progess*10}%` }}
        role="progressbar"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="40"
      ></div>
    </div>
  );
};
export default Progess;
