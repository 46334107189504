import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import allActions from '../../../../Store/action';
// Data
import questions from '../../../../utilites/questionnaireData.json';

const CustomerQuestionnaires = ({ data }) => {
	let assessmentContent = data.user.assessment?.content;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(allActions.global.Loader(false));
	}, [dispatch]);

	return (
		<div className="clearfix mt-3">
			{data.user.assessment && data.user.assessment !== null ? (
				<ul className="list-group list-group-flush border-0">
					{questions?.map((question, index) => (
						<li className="list-group-item border-0 px-0" key={question.id}>
							<div className="row">
								<div className="col-md-12 mb-3">
									<h5 className="mb-2 fw-bold">
										{question.id}) {question.question}
									</h5>
									{assessmentContent[question.id] ? (
										<ul>
											{question.answers?.map((option) => (
												<li key={option.id}>
													{assessmentContent[question.id].includes(option.id.toString()) ||
													(option.isField && assessmentContent[question.id][0] === 'custom') ? (
														<p className="text-primary mb-0">
															{option.isField ? assessmentContent[question.id][1] : option.answer}
														</p>
													) : (
														<p className="text-muted mb-0">
															{option.isField ? '[ Insert a custom answer ]' : option.answer}
														</p>
													)}
												</li>
											))}
										</ul>
									) : (
										<p className="text-muted mb-0">No answer</p>
									)}
								</div>
							</div>
						</li>
					))}
				</ul>
			) : (
				<div className="card p-5 border-1 border-secondary-100 text-center rounded shadow-hover">
					<h4 className="text-dark text-opacity-25">
						The customer has not started the assessment yet.
					</h4>
				</div>
			)}
		</div>
	);
};

export default CustomerQuestionnaires;
