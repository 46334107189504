import React from 'react';
import { useSelector } from 'react-redux';
const VideoCall = ({ data, index, chatUsers }) => {
	//
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const [{ LoginData }] = useSelector((state) => [state.user]);
	const sender = data?.senderId === LoginData?.uuid;
	if (document.getElementById(`mesasage-Input-area${index}`)) {
		document.getElementById(`mesasage-Input-area${index}`).innerHTML = data?.body;
	}
	// RETRIVE RECEIVER USER DETAILS
	const receiverUser = chatUsers?.find((user) => user?.uuid === data?.senderId);
	// RETRIVE SERNER USER DETAILS
	const senderUser = chatUsers?.find((user) => user?.uuid === data?.receiverId);
	//
	// ---------------------------------------
	// Functions, Renders
	// ---------------------------------------
	//
	// RESPONSSES ACCORDING TO SEND MESSAGES
	const receivedMessage = (
		<>
			You have received a meeting invitation from{' '}
			<strong>{receiverUser?.firstname + ' ' + receiverUser?.lastname}</strong>
		</>
	);
	const senderMessage = (
		<>
			You have send a meeting invitation to{' '}
			<strong>{senderUser?.firstname + ' ' + senderUser?.lastname}</strong>
		</>
	);

	// OPEN MEETING LINK
	const OpenMeetingLink = () => {
		const decodedDataBody = JSON.parse(window.atob(data?.body));
		if (decodedDataBody.creatorUserserId === LoginData?.id) {
			window.open(decodedDataBody?.startUrl, '_blank');
		} else {
			window.open(decodedDataBody?.joinUrl, '_blank');
		}
	};
	return (
		data?.messageType === 'meeting/video' && (
			<div
				className={
					sender
						? 'bg-dark text-white  chat-item d-flex flex-column w-75'
						: 'bg-primary  bg-opacity-10  chat-item d-flex flex-column w-75'
				}
			>
				<i className={sender ? 'text-white px-3 py-2' : 'px-3 py-2'}>Let's do a meet up now</i>
				<small
					className={
						sender
							? 'text-white text-responsive fold-Text-small px-3 py-2'
							: 'text-responsive fold-Text-small mt-1 px-3 py-2'
					}
				>
					{receiverUser ? receivedMessage : senderMessage}, by video call you can share your details
					experiences wth others.
				</small>
				{data.body !== 'no-meeting' && (
					<>
						<hr />
						<div
							className="w-100 d-flex align-items-end justify-content-end"
							style={{ marginTop: -10 }}
						>
							<button
								onClick={() => OpenMeetingLink()}
								type="button"
								className={
									sender
										? 'btn btn-light bg-opacity-10 mx-2 my-2 px-5'
										: 'btn btn-dark   mx-2 my-2 px-5'
								}
							>
								Join
							</button>
						</div>
					</>
				)}
			</div>
		)
	);
};

export default VideoCall;
