import { BASEURL } from "../../utilites";
import { FACT_AUTH_ACTION_TYPE } from "./Type";
import axios from "axios";

const sendOtp = (payload) => {
  return async (dispatch) => {
    axios
      .post(`${BASEURL}/api/2fact/sendotp`, payload)
      .then((response) => {
        dispatch({
          type: FACT_AUTH_ACTION_TYPE.sendOtp,
          status: true,
          message: response.data.data.message,
        });
      })
      .catch((error) => {
        dispatch({
          type: FACT_AUTH_ACTION_TYPE.sendOtp,
          status: false,
          message:
            error.code === "ERR_NETWORK"
              ? error?.message
              : error?.response?.data?.error,
        });
      });
  };
};

const verifyOtp = (payload) => {
  return async (dispatch) => {
    axios
      .post(`${BASEURL}/api/2fact/verifyotp`, payload)
      .then((response) => {
        dispatch({
          type: FACT_AUTH_ACTION_TYPE.verifyOtp,
          status: true,
          message: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FACT_AUTH_ACTION_TYPE.verifyOtp,
          status: false,
          message:
            error.code === "ERR_NETWORK"
              ? error?.message
              : error?.response?.data?.error,
        });
      });
  };
};

// First Time Send Otp if user not have enable 2 Fact Auth : Enable2fact/SendOtp
const enableTwoFactAuthSendOtp = (payload) => {
  return async (dispatch) => {
    axios
      .post(`${BASEURL}/api/2fact/setting/enable2fact/sendotp`, payload)
      .then((response) => {
        dispatch({
          type: FACT_AUTH_ACTION_TYPE.EnableAuthSendOtp,
          status: true,
          message: response.data.data.message,
        });
      })
      .catch((error) => {
        dispatch({
          type: FACT_AUTH_ACTION_TYPE.EnableAuthSendOtp,
          status: false,
          message:
            error.code === "ERR_NETWORK"
              ? error?.message
              : error?.response?.data?.error,
        });
      });
  };
};
// First Time Verify Otp if user not have enable 2 Fact Auth Enable2fact
const enableTwoFactAuthVerifyOtp = (payload) => {
  return async (dispatch) => {
    axios
      .post(`${BASEURL}/api/2fact/setting/enable2fact`, payload)
      .then((response) => {
        dispatch({
          type: FACT_AUTH_ACTION_TYPE.EnableAuthVerifyOtp,
          status: true,
          message: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FACT_AUTH_ACTION_TYPE.EnableAuthVerifyOtp,
          status: false,
          message:
            error.code === "ERR_NETWORK"
              ? error?.message
              : error?.response?.data?.error,
        });
      });
  };
};

// 2nd Time For Send Otp To his old number
const OtpSendOld = (payload) => {
  return async (dispatch) => {
    axios
      .post(`${BASEURL}/api/2fact/setting/sendotp`, payload)
      .then((response) => {
        dispatch({
          type: FACT_AUTH_ACTION_TYPE.OldAuthSendOtp,
          status: true,
          message: response.data.data.message,
        });
      })
      .catch((error) => {
        dispatch({
          type: FACT_AUTH_ACTION_TYPE.OldAuthSendOtp,
          status: false,
          message:
            error.code === "ERR_NETWORK"
              ? error?.message
              : error?.response?.data?.error,
        });
      });
  };
};

// 2nd Time For Verify Otp To his old number
const otpVerifyOld = (payload) => {
  return async (dispatch) => {
    axios
      .post(`${BASEURL}/api/2fact/setting/verifyotp`, payload)
      .then((response) => {
        dispatch({
          type: FACT_AUTH_ACTION_TYPE.OldAuthVerifyOtp,
          status: true,
          message: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FACT_AUTH_ACTION_TYPE.OldAuthVerifyOtp,
          status: false,
          message:
            error.code === "ERR_NETWORK"
              ? error?.message
              : error?.response?.data?.error,
        });
      });
  };
};

// For Enable Again verify OTP New Number
const updateNumberVerifyOtp = (payload) => {
  return async (dispatch) => {
    axios
      .patch(`${BASEURL}/api/2fact/setting/changenumber/verify`, payload)
      .then((response) => {
        dispatch({
          type: FACT_AUTH_ACTION_TYPE.EnableAuthVerifyOtp,
          status: true,
          message: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FACT_AUTH_ACTION_TYPE.EnableAuthVerifyOtp,
          status: false,
          message:
            error.code === "ERR_NETWORK"
              ? error?.message
              : error?.response?.data?.error,
        });
      });
  };
};

const resetSendOtp = () => {
  return async (dispatch) => {
    dispatch({
      type: FACT_AUTH_ACTION_TYPE.sendOtpIdle,
    });
  };
};

const resetVerifyOtp = () => {
  return async (dispatch) => {
    dispatch({
      type: FACT_AUTH_ACTION_TYPE.verifyOtpIdle,
    });
  };
};

const resetEnableSendOtp = () => {
  return async (dispatch) => {
    dispatch({
      type: FACT_AUTH_ACTION_TYPE.EnableAuthSendOtpIdle,
    });
  };
};

const resetEnableVerifyOtp = () => {
  return async (dispatch) => {
    dispatch({
      type: FACT_AUTH_ACTION_TYPE.EnableAuthVerifyOtpIdle,
    });
  };
};

const resetOldSendOtp = () => {
  return async (dispatch) => {
    dispatch({
      type: FACT_AUTH_ACTION_TYPE.OldAuthSendOtpIdle,
    });
  };
};

const resetOldVerifyOtp = () => {
  return async (dispatch) => {
    dispatch({
      type: FACT_AUTH_ACTION_TYPE.OldAuthVerifyOtpIdle,
    });
  };
};

export const factAuth = {
  sendOtp,
  verifyOtp,
  resetSendOtp,
  resetVerifyOtp,
  enableTwoFactAuthSendOtp,
  enableTwoFactAuthVerifyOtp,
  OtpSendOld,
  otpVerifyOld,
  resetEnableSendOtp,
  resetEnableVerifyOtp,
  resetOldSendOtp,
  resetOldVerifyOtp,
  updateNumberVerifyOtp,
};
