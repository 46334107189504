import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
// Components
import Button from './fields/Button';
import TextArea from './fields/TextArea';
import UserAvatar from '../UserAvatar';
// Utilities
import { BASEURL } from '../../utilites';
// Icons
import { FaStar } from 'react-icons/fa';
//
const rates = [1, 2, 3, 4, 5];
const ratings = ['skills', 'availability', 'communication', 'quality'];

const ReviewProfessionalForm = ({ proData, job_id, customerData, reload, setMessage }) => {
	const [rating, setRating] = useState({
		general: 0,
		skills: 0,
		availability: 0,
		communication: 0,
		quality: 0,
	});
	const [details, setDetails] = useState('');
	const [errors, setErrors] = useState({
		rating: false,
		details: false,
		general: '',
	});
	const button = useRef();

	useEffect(() => {
		const userproReviews = proData.ratings;
		// Check if the customer has already reviewed the professional
		const hasReviewed =
			userproReviews?.filter((review) => review?.user_id === customerData?.id).length > 0
				? true
				: false;
		if (hasReviewed) {
			// If the customer has already reviewed the professional, then set the rating to the one that the customer gave
			const customerReview = userproReviews?.filter(
				(review) => review?.user_id === customerData?.id
			)[0];
			const customerReviewRating = customerReview?.ratings;
			setRating({
				general: customerReviewRating?.general || 0,
				skills: customerReviewRating?.skills || 0,
				availability: customerReviewRating?.availability || 0,
				communication: customerReviewRating?.communication || 0,
				quality: customerReviewRating?.quality || 0,
			});
			setDetails(customerReview?.details);
		}
	}, [customerData?.id, proData?.ratings]);

	const handleSubmit = (e) => {
		e.preventDefault();
		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });
		axios
			.post(`${BASEURL}/api/professionals/rate`, {
				pro_id: proData?.id,
				job_id: job_id,
				ratings: rating,
				details,
			})
			.then(() => {
				button.current.disabled = false;
				reload();
				// Close Modal
				document.querySelector('.ReviewProfessionalModalClose').click();
				setMessage({ show: false });
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
		// Reset the form
		setRating({
			general: 0,
			skills: 0,
			availability: 0,
			communication: 0,
			quality: 0,
		});
		setDetails('');
	};

	const calculateGeneralRating = (rating) => {
		const otherRatings = Object.keys(rating).filter((key) => key !== 'general');
		// Sum all the other ratings
		const sum = otherRatings.reduce((acc, key) => acc + rating[key], 0);
		// Divide by the number of other ratings to get the average
		return Math.round(sum / otherRatings.length);
	};

	const calculateRatingBasedOnGeneral = (general) => {
		const otherRatings = Object.keys(rating).filter((key) => key !== 'general');
		// Chnage the other ratings to be the same as general
		const newRating = otherRatings.reduce((acc, key) => ({ ...acc, [key]: general }), {});
		// Set the new rating
		setRating({
			general,
			...newRating,
		});
	};
	return (
		<form className="ratingsform needs-validation" onSubmit={handleSubmit}>
			<div className="row">
				<div className="col-md-12 mb-3">
					<p className="text-start text-black mb-0">
						Your final payment in escrow has been released. Please, take a moment to rate the
						experience you had with {proData?.user?.firstname}.
					</p>
				</div>
			</div>
			<div className="row mb-3">
				<div className="col-md-6">
					<div className="border border-light rounded px-3 py-5">
						<UserAvatar
							avatar={proData?.user?.avatar}
							alt={`${proData?.user?.firstname} ${proData?.user?.lastname}`}
							className="img-fluid rounded-circle w-25"
						/>
						<p className="text-black fw-bold mt-2 mb-0">
							{`${proData?.user?.firstname} ${proData?.user?.lastname}`}, CPA
						</p>
						<p className="text-black m-0" style={{ fontSize: 12 }}>
							Tax Accountant
						</p>
					</div>
				</div>
				<div className="col-md-6">
					<div className="d-flex justify-content-center mb-4">
						{rates?.map((rate) => (
							<>
								<FaStar
									className={`fs-1${rate <= rating.general ? ' text-primary' : ' text-light'}`}
									onClick={() => {
										// Calculate the other ratings based on the general rating
										calculateRatingBasedOnGeneral(rate);
									}}
								/>
							</>
						))}
					</div>
					<div className="row">
						{ratings?.map((ratingName) => (
							<div className="col-6 mb-3">
								<div className="text-black text-start text-capitalize">{ratingName}</div>
								<div className="d-flex justify-content-between">
									{rates?.map((rate) => (
										<>
											<FaStar
												className={`fs-3${
													rate <= rating[ratingName] ? ' text-primary' : ' text-light'
												}`}
												onClick={() => {
													setRating({
														...rating,
														general: calculateGeneralRating({ ...rating, [ratingName]: rate }),
														[ratingName]: rate,
													});
												}}
											/>
										</>
									))}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12 mb-3">
					<TextArea
						inputName="form-control"
						defaultValue={details}
						placeholder="Add any details..."
						name="details"
						fn={setDetails}
						row="6"
						required={false}
						showLabel={false}
					/>
				</div>
			</div>
			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mb-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<div className="d-flex justify-content-between align-items-center">
				<div>
					<div className="btn btn-outline-primary" data-bs-dismiss="modal">
						Cancel
					</div>
				</div>
				<div className="d-flex">
					<div className="btn btn-light me-2" data-bs-dismiss="modal">
						Skip
					</div>
					<Button buttonRef={button} mainClass="btn btn-primary" buttonText="Submit" />
				</div>
			</div>
		</form>
	);
};

export default ReviewProfessionalForm;
