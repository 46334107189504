/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Input from '../../../fields/Input';
import Button from '../../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../../utilites/variables';
import SingleCheckLabelSelector from '../../../fields/SingleCheckLabelSelector';
import MultipleCheckLabelSelector from '../../../fields/MultipleCheckLabelSelector';

const AddOfficerInformation = ({ OfficerList, setOfficerList }) => {
	const { addressType, OfficerApplicableOptions } = GLOBAL_VARIABLES;

	const [email, setEmail] = useState();
	const [ssnitin, setSsnitin] = useState();
	const [lastName, setLastName] = useState();
	const [firstName, setFirstName] = useState();
	const [middleName, setMiddleName] = useState();
	const [addresType, setAdresType] = useState();
	const [phoneNumber, setPhoneNumber] = useState();
	const [salaryOrOther, setSalaryOrOther] = useState();
	const [devotedPercentage, setDevotedPercentage] = useState();
	const [title, setTitle] = useState();
	// For Choice Questions
	const [officerAppliedOptions, setOfficeAppliedOptions] = useState([]);
	const [errors, setErrors] = useState({}); // Track errors for form fields
	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	function resetFormStates() {
		setEmail();
		setSsnitin();
		setLastName();
		setFirstName();
		setMiddleName();
		setAdresType();
		setTitle();
		setPhoneNumber();
		setSalaryOrOther();
		setDevotedPercentage();
		setOfficeAppliedOptions([]);
	}

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		if (!addresType) newErrors.addresType = 'Please Select Address Type';

		setErrors(newErrors);
		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmitForm = () => {
		let errors = check_Validity();
		if (errors) return;
		window.scrollTo(0, 0);
		let FormObj = {
			email,
			lastName,
			firstName,
			middleName,
			addresType,
			phoneNumber,
			title,
			salaryOrOther,
			devotedPercentage,
			officerAppliedOptions,
		};

		let OldOfficerList = OfficerList;
		OldOfficerList.push(FormObj);
		setOfficerList(OldOfficerList);
		resetFormStates();
	};
	const handleAppliedOptions = (option) => {
		const localOptions = officerAppliedOptions.slice();
		if (localOptions?.includes(option)) {
			setOfficeAppliedOptions(localOptions.filter((data) => data !== option));
		} else {
			localOptions.push(option);
			setOfficeAppliedOptions(localOptions);
		}
	};

	return (
		<div className="my-2">
			<table className="table">
				<thead>
					<tr>
						<th scope="col">Officer Name</th>
						<th scope="col">Title</th>
						<th scope="col">compensation</th>
						<th scope="col">Shareholders</th>
					</tr>
				</thead>
				<tbody>
					{OfficerList &&
						OfficerList?.map((data) => {
							const shareHolder = data.officerAppliedOptions?.includes(OfficerApplicableOptions[0]);
							return (
								<tr>
									<td>{data?.firstName}</td>
									<td>{data?.email}</td>
									<td>{data?.salaryOrOther}</td>
									<td>{shareHolder ? 'yes' : 'no'}</td>
								</tr>
							);
						})}
				</tbody>
			</table>
			<form
				className="col-md-11 bg-light mt-3 shadow-sm mx-4 p-3"
				onSubmit={(e) => {
					e.preventDefault();
					handleSubmitForm();
				}}
			>
				<small className="ml-4 fw-bold">Officer Information</small>
				<hr className="m-0" />
				<div className="mt-3 row justify-content-between px-3  custom-left-form">
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="firstName"
							value={firstName}
							placeholder="First Name"
							fn={setFirstName}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="middleName"
							value={middleName}
							placeholder="Middle Initial"
							fn={setMiddleName}
							required={false}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="lastName"
							value={lastName}
							placeholder="Last Name"
							fn={setLastName}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="ssnitin"
							placeholder="SSN/ITIN"
							value={ssnitin}
							fn={setSsnitin}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input type="text" name="title" value={title} placeholder="Title" fn={setTitle} />
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="OwnershipPercentageBeginning"
							value={devotedPercentage}
							fn={setDevotedPercentage}
							placeholder="Percentage of time devoted to business %"
							mask={'999'}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="salaryOrOther"
							value={salaryOrOther}
							fn={setSalaryOrOther}
							placeholder="Salary / other compensation"
							mask={'999999'}
						/>
					</div>
				</div>
				<MultipleCheckLabelSelector
					col={12}
					mapData={OfficerApplicableOptions}
					selectedOptions={officerAppliedOptions}
					handleAppliedOptions={handleAppliedOptions}
					label={'Select all that apply to this person'}
				/>
				<small className="ml-4 fw-bold">Officer Contact</small>
				<hr className="m-0" />
				<div className="mt-3 row justify-content-between  ">
					<div className="col-md-6 mb-3">
						<Input type="text" name="email" value={email} fn={setEmail} placeholder="Email" />
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="PhoneNumber"
							placeholder="Phone Number"
							value={phoneNumber}
							fn={setPhoneNumber}
							mask="(999) 999-9999"
						/>
					</div>
					<SingleCheckLabelSelector
						heading={'Select address type'}
						value={addresType}
						setValue={setAdresType}
						mapData={addressType}
					/>
				</div>
				{Object.keys(errors)
					?.map((key) => {
						return errors[key];
					})
					?.map((data, index) => {
						return (
							<>
								<small className="text-primary" key={index}>
									{data}*
								</small>
								<br />
							</>
						);
					})}
				<div className="container-fluid content-row pb-4">
					<div className="d-flex align-items-center justify-content-center row">
						<Button
							mainClass="btn btn-primary custon-button-text   w-100"
							buttonText="Add Officer"
							wrapperClass={'w-25 custon-button  mt-4'}
							type={'submit'}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default AddOfficerInformation;
