/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Input from '../../../fields/Input';
import Button from '../../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../../utilites/variables';
import SingleCheckLabelSelector from '../../../fields/SingleCheckLabelSelector';
import DropDown from '../../../fields/DropDown';

const AddShareHolderInformation = ({ ShareHolderList, setShareHolderList }) => {
	const { addressType, insuranceOptions } = GLOBAL_VARIABLES;

	const [shareHolderType, setShareHolderType] = useState();
	const [email, setEmail] = useState();
	const [ssnitin, setSsnitin] = useState();
	const [lastName, setLastName] = useState();
	const [firstName, setFirstName] = useState();
	const [middleName, setMiddleName] = useState();
	const [addresType, setAdresType] = useState();
	const [phoneNumber, setPhoneNumber] = useState();

	const [shareStartDate, setShareStartDate] = useState();
	const [shareEndDate, setShareEndDate] = useState();
	const [capitalContribution, setcapitalContribution] = useState();
	const [distributionDuringYear, setdistributionDuringYear] = useState();

	// For Choice Questions
	const [errors, setErrors] = useState({}); // Track errors for form fields

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	function resetFormStates() {
		setEmail();
		setSsnitin();
		setLastName();
		setFirstName();
		setMiddleName();
		setAdresType();
		setPhoneNumber();
		setShareHolderType();
		setShareStartDate();
		setShareEndDate();
		setcapitalContribution();
		setdistributionDuringYear();
	}

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		if (!addresType) newErrors.addresType = 'Please Select Address Type';

		setErrors(newErrors);
		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmitForm = () => {
		let errors = check_Validity();
		if (errors) return;
		window.scrollTo(0, 0);

		let FormObj = {
			email,
			lastName,
			firstName,
			middleName,
			addresType,
			phoneNumber,
			shareHolderType,
		};

		let OldShareHolderList = ShareHolderList;
		OldShareHolderList.push(FormObj);
		setShareHolderList(OldShareHolderList);
		resetFormStates();
	};

	return (
		<div className="my-2">
			<table className="table">
				<thead>
					<tr>
						<th scope="col">ShareHolder name</th>
						<th scope="col">Identification number</th>
					</tr>
				</thead>
				<tbody>
					{ShareHolderList &&
						ShareHolderList?.map((data) => {
							return (
								<tr>
									<td>
										{data?.middleName
											? data?.firstName + ' ' + data?.middleName + ' ' + data?.lastName
											: data?.firstName + ' ' + data?.lastName}
									</td>
									<td>{data?.phoneNumber}</td>
								</tr>
							);
						})}
				</tbody>
			</table>
			<form
				className="col-md-11 bg-light mt-3 shadow-sm mx-4 p-3"
				onSubmit={(e) => {
					e.preventDefault();
					handleSubmitForm();
				}}
			>
				<small className="ml-4 fw-bold">ShareHolder Information</small>

				<hr className="m-0" />
				<div className="mt-3 row justify-content-between px-3  custom-left-form">
					<div className="col-md-6 mb-3">
						<DropDown
							options={insuranceOptions}
							value={shareHolderType}
							label={'Share Holder Type'}
							setValue={(e) => setShareHolderType(e)}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="firstName"
							value={firstName}
							placeholder="First Name"
							fn={setFirstName}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="middleName"
							value={middleName}
							placeholder="Middle Initial"
							fn={setMiddleName}
							required={false}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="lastName"
							value={lastName}
							placeholder="Last Name"
							fn={setLastName}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="ssnitin"
							placeholder="SSN/ITIN"
							value={ssnitin}
							fn={setSsnitin}
						/>
					</div>
				</div>

				<small className="ml-4 fw-bold">ShareHolder Contact</small>

				<hr className="m-0" />
				<div className="mt-3 row justify-content-between  ">
					<div className="col-md-6 mb-3">
						<Input type="text" name="email" value={email} fn={setEmail} placeholder="Email" />
					</div>

					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="PhoneNumber"
							placeholder="Phone Number"
							value={phoneNumber}
							fn={setPhoneNumber}
							mask="(999) 999-9999"
						/>
					</div>
					<SingleCheckLabelSelector
						heading={'Select address type'}
						value={addresType}
						setValue={setAdresType}
						mapData={addressType}
					/>
				</div>

				<small className="ml-4 fw-bold">ShareHolder Ownership</small>

				<hr className="m-0" />
				<div className="mt-3 row justify-content-between  ">
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="shareStartDate"
							placeholder="Shares owned at beginning of year"
							value={shareStartDate}
							fn={setShareStartDate}
							mask="99-99-9999"
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="shareEndDate"
							placeholder="Shares owned at end of year"
							value={shareEndDate}
							fn={setShareEndDate}
							mask="99-99-9999"
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="capitalContribution"
							placeholder="Capital contribution during the year"
							value={capitalContribution}
							fn={setcapitalContribution}
							mask="99999999"
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="distributionDuringYear"
							placeholder="Distributions during the year"
							value={distributionDuringYear}
							fn={setdistributionDuringYear}
							mask="99999999"
						/>
					</div>
				</div>
				{Object.keys(errors)
					?.map((key) => {
						return errors[key];
					})
					?.map((data, index) => {
						return (
							<>
								<small className="text-primary" key={index}>
									{data}*
								</small>
								<br />
							</>
						);
					})}
				<div className="container-fluid content-row pb-4">
					<div className="d-flex align-items-center justify-content-center row">
						<Button
							mainClass="btn btn-primary custon-button-text   w-100"
							buttonText="Add ShareHolder"
							wrapperClass={'w-25 custon-button  mt-4'}
							type={'submit'}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default AddShareHolderInformation;
