import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';

const ForeignAccounts = () => {

    const dispatch=useDispatch()
	const [{ taxPayerForm, taxPayerFormData,formInformation }, { LoginData }] = useSelector((state) => [state.taxForms,state.user]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 31, redirect: '/dashboard' });

    const [formsData, setFormData] = useState({
        financialInstitute: '',
        addressInstitute: '',
        accountNumber: '',
        ownerPercentage: '',
        jointName: '',
        jointOwner: '',
        AccountType: null
    });

    const [errors, setErrors] = useState({}); // Track errors for form fields

    useEffect(() => {
    	if (taxPayerForm?.data?.ForeignAccounts) {
    		if (Object.keys(taxPayerForm?.data?.ForeignAccounts).length === 0) return;
			const {
				formsData
			} = taxPayerForm?.data?.ForeignAccounts;
           setFormData(formsData)
    	}
    }, [taxPayerForm?.data?.ForeignAccounts]);

    const check_Validity = () => {
        setErrors({});
        const newErrors = {};
    
        // Add more validation rules as needed for other fields
        if (
            formsData.AccountType===null) {
            newErrors.AnswerOtherQuestions = 'Please Select Required Option';
        }
    
        setErrors(newErrors);
    
        let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
        // Check if there are any errors
        return ConditionCheck;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = check_Validity();
    if (errors) return;

        let FormObj = {
           formsData
		};
        let formData = taxPayerForm;
        taxPayerForm.data.ForeignAccounts = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));



        let obj = {
			service_id: 1,
			method: 'upload',
			formName: formInformation?.name,
			formData: taxPayerForm.data,
		};
		if (LoginData?.isTaxPro || LoginData?.isAdmin || LoginData?.isAccountManager)
			obj.lead_id = formInformation?.lead_id;

		changeLoading(true);
		dispatch(allActions.dashboard.resetTaxPreparation());
		dispatch(allActions.dashboard.taxPreparation({ obj }));

		uploadFormData(formData);


    };

    const handleChange = (fieldName, value) => {
        setFormData({
            ...formsData,
            [fieldName]: value
        });
    };

    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 31;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

    const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                <h6>Did the business, at any time during the year, have an interest or signature authority in a foreign bank or securities account or control a foreign entity with a foreign bank or securities account where the the aggregate value of all the accounts exceeded U.S. $10,000 at any time during the year?</h6>
                <div>
                    <div className="col-md-12 mb-3">
                        <Input
                            type="text"
                            name="financialInstitution"
                            value={formsData.financialInstitute}
                            placeholder="Name of financial institution"
                            fn={(value) => handleChange('financialInstitute', value)}
                        />
                    </div>
                    <div className="col-md-12 mb-3">
                        <Input
                            type="text"
                            name="addressInstitute"
                            value={formsData.addressInstitute}
                            placeholder="Address of financial institution"
                            fn={(value) => handleChange('addressInstitute', value)}
                        />
                    </div>
                    <div className="col-md-12 mb-3">
                        <Input
                            type="text"
                            name="accountNumber"
                            value={formsData.accountNumber}
                            placeholder="Account Number"
                            fn={(value) => handleChange('accountNumber', value)}
                            mask={"999999999"}
                        />
                    </div>
                    <div className="col-md-12">
                        <h6>
                            Account Type   
                        {formsData.AccountType===null && (
                            <span className="text-danger small-text mx-1">*Required</span>
                        )}
                        </h6>
                        <input
                            onChange={() => handleChange('AccountType', "bank")} 
                            checked={formsData.AccountType === "bank"}
                            className="form-check-input"
                            type="radio"
                            id="checkbox-div2"
                            name="meeting-preference" 
                        />
                        <label htmlFor="checkbox-div2" role="button" className="px-3 m-0 small">
                            Bank
                        </label>
                        <input
                            onChange={() => handleChange('AccountType', "security")} 
                            checked={formsData.AccountType === "security"}
                            className="form-check-input"
                            type="radio"
                            id="checkbox-div3"
                            name="meeting-preference" 
                        />
                        <label htmlFor="checkbox-div3" role="button" className="px-3 m-0 small">
                            Securities
                        </label>
                        <input
                            onChange={() => handleChange('AccountType', "other")} 
                            checked={formsData.AccountType === "other"}
                            className="form-check-input"
                            type="radio"
                            id="checkbox-div4"
                            name="meeting-preference" 
                        />
                        <label htmlFor="checkbox-div4" role="button" className="px-3 m-0 small">
                            Other
                        </label>
                    </div>
                </div>
            </div>
{Object.keys(errors)
    ?.map((key) => {
        return errors[key];
    })
    ?.map((data, index) => {
        return (
            <>
                <small className="text-primary" key={index}>
                    {data}*
                </small>
                <br />
            </>
        );
    })}
        <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="button"
                        callback={()=>goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text w-100"
                        buttonText="Submit"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default ForeignAccounts;