import { Link, useNavigate } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import Button from '../../../components/forms/fields/Button';
import SEO from '../../../components/SEO';

const Header = ({ enableBreadcrumb = false, name = 'Dashboard', progress, dropdownContent }) => {
	const navigate = useNavigate();
	return (
		<header className='w-100 my-4 d-flex justify-content-between'>
			<SEO title={name} />

			{enableBreadcrumb ? (
				<nav aria-label='breadcrumb'>
					<ol className='breadcrumb'>
						<li className='breadcrumb-item'>
							<Link to={`/dashboard/${name.toLowerCase()}`} className='text-decoration-none'>
								{name}
							</Link>
						</li>
						<li className='breadcrumb-item active' aria-current='page'>{`${name} Details`}</li>
					</ol>
				</nav>
			) : (
				<h5 className='fw-bold m-0 mt-2'>{name}</h5>
			)}
			{progress ? (
				<div className='d-flex align-items-center' style={{ gap: 10 }}>
					<span>
						{progress.value} of {progress.max}
					</span>
					<div className='progress my-1' style={{ width: 200, height: 10 }}>
						<div
							className='progress-bar progress-bar-primary'
							role='progressbar'
							aria-valuenow={progress.value}
							aria-valuemin={progress.min}
							aria-valuemax={progress.max}
							style={{ width: `${(progress.value / progress.max) * 100}%` }}
						></div>
					</div>
					<Button
						mainClass={`btn btn-sm btn-outline-primary d-flex align-items-center${
							progress.max === progress.value ? ' disabled' : ''
						}`}
						wrapperClass='clearfix d-grid text-center'
						buttonText='Finish later'
						callback={(e) => {
							e.currentTarget.parentElement.innerHTML = `<div class='spinner-border text-primary' role='status'>
							<span class='visually-hidden'>Loading...</span>
						</div>`;
							setTimeout(() => {
								navigate('/dashboard/');
							}, 1000);
						}}
					/>
				</div>
			) : dropdownContent ? <div className='btn-group'>
				<button
					type='button'
					className={`btn border-2 border-primary text-primary rounded-3${!dropdownContent ? ' disabled' : ''
						}`}
					data-bs-toggle='dropdown'
					aria-expanded='false'
				>
					<BsThreeDotsVertical className='' />
				</button>
				<ul className='dropdown-menu'>{dropdownContent}</ul>
			</div> : (
				<></>
			)}
		</header>
	);
};

export default Header;
