import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';

import Button from './fields/Button';


export default function AddJobForm() {
	const [{ leads }] = useSelector((state) => [state.dashboard]);
	const [selectedLead, setSelectedLead] = useState();

	const [errors, setErrors] = useState({
		selectedLead: false,
		general: '',
	});

	const button = useRef();
	const navigate = useNavigate();

	// function to handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();
		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });
		// Close Modal
		document.querySelector('.jobAdditionModalClose').click();
		// Generate contract creation link
		const createContractUrl = `/dashboard/jobs/create/${selectedLead?.value}`;
		navigate(createContractUrl);
	};

	// useEffect(() => {
	// 	dispatch(allActions.dashboard.getLeads(null, null, null, null, null, null, null, null, true));
	// }, [dispatch]);


	return (
		<form className="disputessform needs-validation" onSubmit={handleSubmit}>
			<ReactSelect
				id="lead"
				className="p-0"
				placeholder="Select lead"
				closeMenuOnSelect={true}
				options={leads?.data?.map((u) => ({
					value: u.uuid,
					label: `${u?.user?.firstname} ${u?.user?.lastname}`,
				}))}
				onChange={(opt) => {
					setSelectedLead(opt);
				}}
				value={selectedLead}
			/>
			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mb-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 || !selectedLead ? ' disabled' : ''
				}`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-3"
				buttonText="Create job"
			/>
		</form>
	);
}
