import { PLAID_ACTION_TYPE } from "./Type";

const DEFAULT_STATE = {
	plaidCreate: { message: "", status: "idle", data: {} },
	plaidGetUpload: { message: "", status: "idle", data: {} },
};

const INITIAL_STATE = {
	...DEFAULT_STATE,
};

const plaid = (state = INITIAL_STATE, action) => {
	if (action.type === PLAID_ACTION_TYPE.plaidCreate) {
		return {
			...state,
			plaidCreate: action.payload,
		};
	}
	if (action.type === PLAID_ACTION_TYPE.plaidCreateIdle) {
		return {
			...state,
			plaidCreate: DEFAULT_STATE.plaidCreate,
		};
	}
	if (action.type === PLAID_ACTION_TYPE.plaidGetUpload) {
		return {
			...state,
			plaidGetUpload: action.payload,
		};
	}
	if (action.type === PLAID_ACTION_TYPE.plaidGetUploadIdle) {
		return {
			...state,
			plaidGetUpload: DEFAULT_STATE.plaidGetUpload,
		};
	}

	return state;
};

export default plaid;
