/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useEffect, useState } from 'react';
import SweetAlert2 from 'react-sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { Link } from 'react-router-dom';

import { RiDeleteBin5Line } from 'react-icons/ri';
import { BsThreeDotsVertical, BsCameraVideo, BsPhone } from 'react-icons/bs';
import { ImParagraphLeft } from 'react-icons/im';


import UserAvatar from '../../../components/UserAvatar';
import Modal from '../../../components/Dashboard/Modal';
import RescheduleAppointmentForm from '../../../components/forms/RescheduleAppointmentForm';

import {
	BASEURL,
	convertTimeIntoSselectedTZ,
	getAppointmentStatus,
	getAppointmentStatusClass,
	timezoneShortFormatter,
} from '../../../utilites';

import allActions from '../../../Store/action';
import { CALL_ACTION_TYPE } from '../../../Store/call/Type';
import Message from '../../../components/forms/fields/Message';
import moment from 'moment-timezone';

export default function AppointmentOffcanvas({ data, close, setAlert, reload }) {
	const [{ LoginData }, { getZoomMeeting }, { usersForAppointment, currentUserAppointments }] =
		useSelector((state) => [state.user, state.call, state.dashboard]);
	const [swalProps, setSwalProps] = useState({});
	const [message, setMessage] = useState({ show: false });
	// Assuming data.end_date is the date string you want to modify
	const endDate = moment(data.end_date).add(15, 'minute');
	const currentDate = moment();

	const dispatch = useDispatch();

	useEffect(() => {
		// if Error While Creating Video Call Room
		if (!getZoomMeeting.status) {
			// Reset Message and Loading
			resetMessage();
			setMessage({
				show: true,
				type: 'Error',
				text: getZoomMeeting?.message,
			});
		}

		// if Success After Creating Video Call
		if (getZoomMeeting.status === true) {
			// Reset Message and Loading
			resetMessage();

			if (data?.creator_user_id === LoginData?.id) {
				window.open(getZoomMeeting?.data?.start_url, '_blank');
			} else {
				window.open(getZoomMeeting?.data?.join_url, '_blank');
			}
		}
	}, [getZoomMeeting.status, getZoomMeeting.message]);

	console.log('ID@@', data?.creator_user_id, LoginData?.id);

	const handleDeleteAppointmentClick = (id) => {
		setSwalProps((prev) => ({
			...prev,
			[`ad-${id}`]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				confirmButtonText: 'Confirm',
				denyButtonText: 'Cancel',
				didClose: () =>
					setSwalProps((prev) => ({
						...prev,
						[`ad-${id}`]: { show: false },
					})),
				onConfirm: () =>
					dispatch(allActions.dashboard.deleteAppointment(id, close, setAlert, reload)),
			},
		}));
	};

	const editAppointmentUserStatus = (id, status_id) => {
		axios
			.put(`${BASEURL}/api/appointments/change-status/${id}/${status_id}`)
			.then(() => {
				reload();
			})
			.then(() => close());
	};
	const handleStatusClick = (id) => {
		setSwalProps((prev) => ({
			...prev,
			[id]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				confirmButtonText: 'Confirm',
				denyButtonText: 'Reject',
				didClose: () => setSwalProps((prev) => ({ ...prev, [id]: { show: false } })),
				onResolve: (event) => {
					if (event.isConfirmed) {
						editAppointmentUserStatus(id, 1);
					} else {
						editAppointmentUserStatus(id, 2);
					}
				},
			},
		}));
	};

	const joinMeeting = () => {
		if (!data?.room_id) return;
		// reset Meeting Link
		resetMessage();
		// GENERATE NEW TOKEN
		dispatch(allActions.call.getZoomMeeting(data?.room_id));
		changeLoading(true);
	};

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

	const resetMessage = () => {
		dispatch(allActions.call.dispatchToStore({ type: CALL_ACTION_TYPE.getZoomMeetingIdle }));
		dispatch(allActions.call.dispatchToStore({ type: CALL_ACTION_TYPE.updateMeetingRoomIdle }));
		setMessage({ show: false });
		changeLoading(false);
	};

	return (
		<div className="clearfix p-4">
			<div className="row">
				{message.show ? <Message message={message} /> : <></>}
				<div className="col-9">
					<h5 className="fw-bold">{data.title}</h5>
					<p style={{ fontSize: 14 }}>
						{convertTimeIntoSselectedTZ(
							data?.timezone,
							moment.tz.guess(),
							moment.utc(data.start_date),
							'lll'
						)}{' '}
						{timezoneShortFormatter(moment.tz.guess())}
					</p>
				</div>

				<div className="col d-flex justify-content-end" style={{ gap: 5 }}>
					{data.creator_user_id === LoginData.id ? (
						<button
							className="btn btn-sm btn-outline-primary rounded-circle"
							style={{ width: 30, height: 30 }}
							onClick={() => handleDeleteAppointmentClick(data.id)}
						>
							<RiDeleteBin5Line />
							<SweetAlert2 {...swalProps[`ad-${data.id}`]} />
						</button>
					) : null}
					<div className="dropdown-center">
						<button
							className="btn btn-sm btn-outline-primary rounded-circle"
							style={{ width: 30, height: 30 }}
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<BsThreeDotsVertical />
						</button>
						<ul className="dropdown-menu border-light">
							<li>
								<Link
									to="#"
									className={`dropdown-item${
										data.creator_user_id === LoginData.id ||
										data.users.some((e) => e.appointmentuser.status_id === 2)
											? ' disabled'
											: ''
									}`}
									data-bs-toggle="modal"
									data-bs-target="#openAppointmentRescheduleModal2"
								>
									Reschedule
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row text-black mb-4">
				<div className="col-12 d-flex align-items-center mb-3" style={{ gap: 10 }}>
					<span>{data.room_id ? <BsCameraVideo /> : <BsPhone />}</span>
					<p className="m-0">{data.room_id ? 'Video Appointment' : 'Phone Appointment'}</p>
				</div>
				{data.details ? (
					<div className="col-12 d-flex mb-3" style={{ gap: 10 }}>
						<span>
							<ImParagraphLeft />
						</span>
						<p className="m-0">{data.details}</p>
					</div>
				) : null}
				{data ? (
					<AddToCalendarButton
						name={data?.title}
						description={data?.details}
						hideBranding={true}
						options={[
							'Google',
							'Yahoo',
							'Apple',
							'Microsoft365',
							'MicrosoftTeams',
							'Outlook.com',
							'iCal',
						]}
						location={data.room_id ? 'Zoom Meeting' : 'Phone Call'}
						startDate={convertTimeIntoSselectedTZ(
							data?.timezone,
							moment.tz.guess(),
							moment.utc(data.start_date),
							'YYYY-MM-DD'
						)}
						endDate={convertTimeIntoSselectedTZ(
							data?.timezone,
							moment.tz.guess(),
							moment.utc(data.end_date),
							'YYYY-MM-DD'
						)}
						startTime={convertTimeIntoSselectedTZ(
							data?.timezone,
							moment.tz.guess(),
							moment.utc(data.start_date),
							'HH:mm'
						)}
						endTime={convertTimeIntoSselectedTZ(
							data?.timezone,
							moment.tz.guess(),
							moment.utc(data.end_date),
							'HH:mm'
						)}
						timeZone={moment.tz.guess() || 'America/Los_Angeles'}
					></AddToCalendarButton>
				) : null}
			</div>
			<div className="clearfix text-black mb-4">
				<p className="m-0">
					{1 + data.users.length} guest
					{data.users.length > 0 ? 's' : ''}
				</p>
				<ul className="list-group list-group-flush">
					<li className="list-group-item">
						<div className="row align-items-center">
							<div className="col-2">
								<UserAvatar
									avatar={data?.creator?.avatar}
									alt={`${data?.creator?.firstname} ${data?.creator?.lastname}`}
									className="img-fluid object-fit-cover rounded-circle"
								/>
							</div>
							<div className="col">
								<p className="text-black m-0">
									{`${data?.creator?.firstname} ${data?.creator?.lastname}`}
								</p>
								<p className="text-muted m-0">Organizer</p>
							</div>
							<div className="col d-flex justify-content-end">
								<span className="text-primary">Confirmed</span>
							</div>
						</div>
					</li>
					{data.users.length > 0 ? (
						<>
							{data.users?.map((user) => (
								<li className="list-group-item" key={user.id}>
									<div className="row align-items-center">
										<div className="col-2">
											<UserAvatar
												avatar={user?.avatar}
												alt={`${user?.firstname} ${user?.lastname}`}
												className="img-fluid object-fit-cover rounded-circle"
											/>
										</div>
										<div className="col">
											<p className="text-black m-0">{`${user.firstname} ${user.lastname}`}</p>
											<p className="text-muted m-0">Guest</p>
										</div>
										<div className="col d-flex justify-content-end">
											{user.appointmentuser.user_id === LoginData.id ? (
												<>
													<span
														className={`badge bg-${getAppointmentStatusClass(
															user.appointmentuser.status_id
														)} cursor-pointer`}
														onClick={() => handleStatusClick(user.appointmentuser.appointment_id)}
													>
														{getAppointmentStatus(user.appointmentuser.status_id)}
													</span>
													<SweetAlert2 {...swalProps[user.appointmentuser.appointment_id]} />
												</>
											) : (
												<span
													className={`text-${getAppointmentStatusClass(
														user.appointmentuser.status_id
													)}`}
												>
													{getAppointmentStatus(user.appointmentuser.status_id)}
												</span>
											)}
										</div>
									</div>
								</li>
							))}
						</>
					) : null}
				</ul>
                 {data?.room_id && convertTimeIntoSselectedTZ(
							data?.timezone,
							moment.tz.guess(),
							moment.utc(endDate),
						) > currentDate.format('YYYY-MM-DD HH:mm:ss') && (
					<div className="clearfix text-center mt-3">
						<button
							type="button"
							className={`btn btn-primary btn-sm w-50 my-3`}
							onClick={() => joinMeeting()}
						>
							Join Meeting
						</button>
					</div>
				)}
			</div>

			<Modal
				title="Reschedule Appointment"
				bodyClass="p-4 text-center text-dark text-opacity-50"
				trigger="openAppointmentRescheduleModal2"
				showBtn={false}
				btnClass="AppointmentRescheduleModalClose2"
				size="lg"
			>
				<RescheduleAppointmentForm
					data={data}
					users={usersForAppointment}
					currentUserAppointments={currentUserAppointments}
					reload={() => {
						reload();
						close();
					}}
					setAlert={setAlert}
					btnClass="AppointmentRescheduleModalClose2"
				/>
			</Modal>
		</div>
	);
}
