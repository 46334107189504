import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa';

const Footer = () => {
	return (
		<footer className='clearfix bg-dark-gradient py-5 px-3'>
			<div className='container'>
				<div className='w-85 mx-auto'>
					<div className='clearfix text-white'>
						<p>
							Uncle Kam is a nationwide network that connects people to tax savings experts you can
							trust. The certified professionals we recommend are in excellent standings in their
							industry and provide top-notch customer service.
						</p>
					</div>
					<div className='row mt-5'>
						<div className='col-md-6 text-center text-md-start'>
							<p className='text-primary-300'>
								&copy;{new Date().getFullYear()} Uncle Kam. All rights reserved.{' '}
								<Link to='/privacy-policy' className='text-primary-300 ms-4'>
									Privacy Policy
								</Link>
							</p>
						</div>
						<div className='col-md-4 text-center text-md-start'>
							<Link to='/#' className='d-inline-block text-white me-4'>
								<FaLinkedin className='fs-2' />
							</Link>
							<Link to='/#' className='d-inline-block text-white me-4'>
								<FaInstagram className='fs-2' />
							</Link>
							<Link to='/#' className='d-inline-block text-white me-4'>
								<FaFacebook className='fs-2' />
							</Link>
							<Link to='/#' className='d-inline-block text-white me-4'>
								<FaTwitter className='fs-2' />
							</Link>
							<Link to='/#' className='d-inline-block text-white'>
								<FaYoutube className='fs-2' />
							</Link>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
