/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '../../../fields/Button';
import AddShareHolderInformation from './AddShareInformation';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../../Store/action';
import { GLOBAL_VARIABLES } from '../../../../../utilites/variables';
import { HandleFormData } from '../../../../../utilites';

const ShareHolder = () => {
	const { ShareHolderOptions } = GLOBAL_VARIABLES;
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const dispatch = useDispatch();

	const uploadFormData = HandleFormData({ parentIndex: 1, myIndex: 1 });

	const [{ taxPayerFormData, taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);

	const [ShareHolderList, setShareHolderList] = useState([]);
	const [ShareHolderOption, setShareHolderOption] = useState([]);

	const [errors, setErrors] = useState({}); // Track errors for form fields

	// For Choice Questions

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------
	useEffect(() => {
		setShareHolderOption(JSON.parse(JSON.stringify(ShareHolderOptions)));
	}, []);
	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.ShareHolderForm) {
			if (Object.keys(taxPayerForm?.data?.ShareHolderForm).length === 0) return;

			const { ShareHolderList, ShareHolderOption } = taxPayerForm?.data?.ShareHolderForm;
			setShareHolderList(ShareHolderList);
			setShareHolderOption(ShareHolderOption);
		}
	}, [taxPayerForm?.data?.ShareHolderForm]);

	// ---------------------------------------
	// Functions
	// ---------------------------------------
	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		// Add more validation rules as needed for other fields
		if (
			ShareHolderOption?.flatMap((data) => {
				return data?.bool;
			}).includes(null)
		) {
			newErrors.AnswerOtherQuestions = 'Please Select Required Option';
		}

		setErrors(newErrors);

		// Check if there are any errors

		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = () => {
		let errors = check_Validity();
		if (errors) return;

		let FormObj = { ShareHolderList, ShareHolderOption };

		let formData = taxPayerForm;
		taxPayerForm.data.ShareHolderForm = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
	};

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 1;
		let myIndex = 1;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	const handleQuestions = (index, bool) => {
		const localShareHolderOption = ShareHolderOption.slice();
		localShareHolderOption[index].bool = bool;
		setShareHolderOption(localShareHolderOption);
	};

	return (
		<div
			className="w-100 mt-3"
			onSubmit={(e) => {
				e.preventDefault();
			}}
		>
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<div className=" mb-3 px-3">
					{ShareHolderOption?.map((data, i) => {
						return (
							<div key={i} className="w-100   mt-2">
								<small className="col-md-12 form-check-label text-start" htmlFor={`checkbox-${i}`}>
								{data.question}{data.bool===null&&<span className='text-danger small-text mx-1'>*Required</span>}
								</small>
								<div className="col-md-6 mt-2">
									<input
										onChange={() => handleQuestions(i, true)}
										checked={data?.bool === true}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 174}`}
										name={`checkbox-${i + 174}`}
									/>
									<label htmlFor={`checkbox-${i + 174}`} role="button" className="px-3 m-0 small">
										Yes
									</label>
									<input
										onChange={() => handleQuestions(i, false)}
										checked={data?.bool === false}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 999}`}
										name={`checkbox-${i + 999}`}
									/>
									<label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
										No
									</label>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			{ShareHolderOption?.[0]?.bool && (
				<AddShareHolderInformation
					ShareHolderList={ShareHolderList}
					setShareHolderList={setShareHolderList}
				/>
			)}
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}

			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => handleSubmit()}
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</div>
	);
};

export default ShareHolder;
