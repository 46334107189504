import { Link } from "react-router-dom";
import Modal from "../../Dashboard/Modal";
import Organizer from "../../../pages/dashboard/organizer/organizer";
import { useSelector } from "react-redux";


export default function FormPreviewModal() {
    const [{ formInformation }] = useSelector((state) => [state.taxForms]);
	return (
		<div>
			<Link to="#" className='d-none formPreview' data-bs-toggle="modal" data-bs-target="#formPreview" />
			<Modal
				title={formInformation?.name}
				bodyClass=""
				trigger="formPreview"
				showBtn={false}
				btnClass="formPreviewButton"
				size="xl"
			>
				<Organizer modal={true} editable={false}/>
			</Modal>
		</div>
	);
}

