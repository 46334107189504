import { PAYMENT_ACTION_TYPE } from "./Type";

const DEFAULT_STATE = {
  paymentCardAdd: "idle",
  paymentCardAddMessage: "",
  paymentModificationStatus: "idle",
  paymentModificationStatusMessage: "",
};

const INITIAL_STATE = {
  ...DEFAULT_STATE,
};

const payment = (state = INITIAL_STATE, action) => {
  if (action.type === PAYMENT_ACTION_TYPE.paymentCardAdd) {
    return {
      ...state,
      paymentCardAdd: action.status,
      paymentCardAddMessage: action.message,
    };
  } else if (action.type === PAYMENT_ACTION_TYPE.paymentCardAddIdle) {
    return {
      ...state,
      paymentCardAdd: "idle",
      paymentCardAddMessage: "",
    };
  } else if (action.type === PAYMENT_ACTION_TYPE.paymentModificationStatus) {
    return {
      ...state,
      paymentModificationStatus: action.status,
      paymentModificationStatusMessage: action.message,
    };
  } else if (
    action.type === PAYMENT_ACTION_TYPE.paymentModificationStatusIdle
  ) {
    return {
      ...state,
      paymentModificationStatus: "idle",
      paymentModificationStatusMessage: "",
    };
  } else if (action.type === PAYMENT_ACTION_TYPE.RESET) {
    return { ...state, ...DEFAULT_STATE };
  }
  return state;
};

export default payment;
