import Button from "./fields/Button";
import Select from "./fields/Select";
import { useEffect, useState } from "react";
import { BASEURL } from "../../utilites";
import axios from "axios";
import { useDispatch } from "react-redux";
import allActions from "../../Store/action";
import moment from "moment";
import DatePicker from 'react-datepicker';
import { MEMBERSHIP_ACTION_TYPE } from '../../Store/membership/Type'

export default function ApproveTaxProForm({userData, reloadUser, alertMessage, setAlertMessage}) {
	const [status, setStatus] = useState(userData?.userpro?.status_id?.toString() || '2');
	const accountManager = userData?.userpro?.manager_id || '';
    const [enableMembership,setEnableMembership] = useState(false)
    const [freeTrilDates, setFreeTrialDates] = useState({
        from: moment().toDate(), 
        to: moment().add(3, 'months').toDate() 
    });
    const dispatch = useDispatch()

    const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

    const convertDate = (dateFormat) => {
        const formattedDate = moment(dateFormat).format('YYYY-MM-DD');
        return formattedDate;
    };

    useEffect(() => {
        setStatus(userData?.userpro?.status_id?.toString())
    },[userData])

    const handleSubmit = (e) => {
        e.preventDefault()
        changeLoading(true)
        axios
            .put(`${BASEURL}/api/professionals/update/status`, {
                id: userData.userpro.id,
                status,
                accountManager
            })
            .then(() => {
                reloadUser(userData.uuid);
                // Close Modal
                setAlertMessage('Tax Pro Status has been updated successfully!');

                setTimeout(() => {
                    document.querySelector('.TaxProStatusForm').click();
                    changeLoading(false)
                }, 600)
                setTimeout(() => {
                    setAlertMessage('');
                }, 2000)

                const membership = {
                    "userId": userData?.id,
                    "memershipId": 1,
                    "trialStart": convertDate(freeTrilDates.from),
                    "trialEnd": convertDate(freeTrilDates.to)
                }

                if (enableMembership && status === '2') {
                    // dispatch(allActions.global.Loader(true));
                    dispatch({ type: MEMBERSHIP_ACTION_TYPE.createMembershipByAdminIdle });
                    dispatch(allActions.membership.createMembershipByAdmin(membership));
                }
            })

        .catch((error) => {
            console.error(error.message);
            changeLoading(false)
            setAlertMessage('Failed to update business information. Please try again.');
        });
    }
	return (
		<form onSubmit={handleSubmit}>
            <Select
                value={status}
                name="status"
                placeholder="Business Status"
                showLabel={false}
                fn={setStatus}
            >
                <option value="0">Pending</option>
                <option value="1">Under Review</option>
                <option value="2">Activated</option>
                <option value="3">Paused</option>
                <option value="4">Suspended</option>
                <option value="5">Denied</option>
                <option value="6">Inactive</option>
            </Select>
            {status === '2' && <div className="d-flex align-items-center gap-2 mt-2">
                <input onClick={(e) => setEnableMembership(e.target.checked)} value={enableMembership} className="cursor-pointer" type="checkbox" id="membership" />
                <label className="cursor-pointer" htmlFor="membership">Do you want to enable Free Trial Members for this Tax Pro?</label>
            </div>}
            {enableMembership && status === '2' && <div className="d-flex gap-2 mt-2" >
                <div>
                    <DatePicker
                        className="form-select"
                        wrapperClassName="d-block"
                        selected={new Date(freeTrilDates.from)}
                        onChange={(date) => setFreeTrialDates({ ...freeTrilDates, from: date })}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Date Created"
                    />
                </div>
                <div className="h-full"></div>
                <div>
                    <DatePicker
                        className="form-select"
                        wrapperClassName="d-block"
                        selected={new Date(freeTrilDates.to)}
                        onChange={(date) => setFreeTrialDates({ ...freeTrilDates, to: date })}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Date Created"
                    />
                </div>
            </div>}
         <Button
				mainClass={`btn btn-primary btn-lg mt-3 px-4${
					Object.values({}).indexOf(true) > -1 ? ' disabled' : ''
				}`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-1"
				buttonText="Submit"
			/>
        </form>
	);
}
