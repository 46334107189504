import SEO from '../components/SEO';

const Contact = () => {
	return (
		<div className='page px-3'>
			<SEO title='Contact' />
			<div className='container'>
				<div className='clearfix pt-4 text-center mb-4'>
					<h1>Contact Us</h1>
					<p>Content of the Company's contact info will go here.</p>
				</div>
			</div>
		</div>
	);
};

export default Contact;
