import Smile from '../../assets/img/smile.svg';
import Dollar from '../../assets/img/dollar.svg';
import Plus from '../../assets/img/plus.svg';

const Stats = () => {
    return (
        <section className="clearfix bg-dark-gradient py-5 px-3">
            <div className="container">
                <div className="clearfix px-0 px-md-5 text-white text-center">
                    <div className="clearfix w-75 mx-auto mb-5">
                        <h1 className="display-3 fw-bold"><span className="d-inline d-md-block">Creating <span className="text-lighter">financial certainty</span></span> one client at a time</h1>
                    </div>
                    <div className="row justify-content-center align-items-center align-middle text-center">
                        <div className="col-md-4 mb-4">
                            <h1 className="display-1 fw-bold">488<img src={Smile} className="ms-1" alt="" /></h1>
                            <h4 className="text-secondary">Happy Clients saving<br/>money nationwide</h4>
                        </div>
                        <div className="col-md-4 mb-4">
                            <h1 className="display-1 fw-bold"><img src={Dollar} className="me-1" alt="" />2.3M</h1>
                            <h4 className="text-secondary">Dollars saved<br/>collectively since 2021</h4>
                        </div>
                        <div className="col-md-4">
                            <h1 className="display-1 fw-bold">250<img src={Plus} className="ms-1" alt="" /></h1>
                            <h4 className="text-secondary">Certified tax<br/>strategists nationwide</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Stats;