import React, { useState, useRef } from 'react';
import axios from 'axios';

import Input from './fields/Input';
import Button from './fields/Button';
import { BASEURL } from '../../utilites';

export default function AddFolderForm({ setAlert, reload }) {
	const [folderName, setFolderName] = useState('');

	const [errors, setErrors] = useState({
		folderName: false,
		general: '',
	});

	const button = useRef();
	// function to handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();

		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });

		axios
			.post(`${BASEURL}/api/documents/create-folder`, {
				folderName,
			})
			.then(() => {
				button.current.disabled = false;
				reload && reload();
				// Close Modal
				document.querySelector('.addFolderModalClose').click();
				setAlert('Folder has been created successfully!');
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
			setFolderName('');
	};

	return (
		<form className='documentsform needs-validation' onSubmit={handleSubmit}>
			<div className='row'>
				<div className='col-md-12 mb-3'>
					<Input
						type='text'
						value={folderName}
						name='folderName'
						placeholder='Folder name'
						fn={setFolderName}
						hasValidationError={errors.folderName}
						showLabel={true}
					/>
				</div>
			</div>
			{errors.general && errors.general !== '' && (
				<div className='row'>
					<div className='mb-3'>
						<div className='alert alert-danger' role='alert'>
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 || !folderName ? ' disabled' : ''
				}`}
				wrapperClass='clearfix w-75 mx-auto d-grid text-center mt-3'
				buttonText='Create folder'
			/>
		</form>
	);
}
