/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import allActions from "../../Store/action";
import CreateSectionIcon from '../../assets/img/createsection.svg'
import DeleteIcon2 from '../../assets/img/delete1.svg'
import DotsIcon from '../../assets/img/dots.svg'
import CloseIcon from '../../assets/img/close.svg'
import EditIcon from '../../assets/img/edit.svg'
import Select from 'react-select';
import { Cascader } from 'antd';
import 'antd/dist/reset.css';
import ReactSelect from "react-select";





const filinigStatusData = ['Single','Married filing jointly', 'Married filing separately', 'Head of household', 'Qualifying widow(er)']

const americanStatesData = [
  "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", 
  "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", 
  "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", 
  "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", 
  "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", 
  "New Hampshire", "New Jersey", "New Mexico", "New York", 
  "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", 
  "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", 
  "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", 
  "West Virginia", "Wisconsin", "Wyoming"
];


const generateUniqueId = () => {
    const timestamp = Date.now().toString(36); 
    const randomString = Math.random().toString(36).substring(2, 15); 
    const randomSegment = Math.random().toString(36).substring(2, 15); 
    return timestamp + randomString + randomSegment;
    
}

const fieldOptions =  [
  {label:'Section Divider',value:'divider'},
  {label:'Text',value:'text-one-line',children:[{label:'One line', value:'text-one-line'},{label:'Multi line',value:'text-multi-line'},{label:'Email',value:'text-email'}]},
  {label:'Number',value:'number',children:[{label:'Any number',value:'number-any-number'},{label:'Percentage',value:'number-percentage'},{label:'Amount',value:'number-amount'}]},
  {label:'Yes/No',value:'yes-no'},
  {label:'Date',value:'date'},
  {label:'Dropdown',value:'dropdown',children:[{label:'States',value:'dropdown-states'},{label:'Filing status',value:'dropdown-filing-status'}]},

  
]

const CreateSectionTitleModal = ({setCustomModalOpen,sectionTitle,setSectionTitle,createNewSection}) => {
  return <div className="custom-modal-overlay">
      <div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
          <h2 className="custom-modal-heading">Create a new section</h2>
          <p className="custom-modal-description">Enter a single sentence description that best describes the purpose of this section.</p>
          <div className="custom-modal-input-cont">
                <label htmlFor="section-title">Enter section tittle</label>
                <input type="text" id="section-title" value={sectionTitle} onChange={(e) => setSectionTitle(e.target.value)} />
          </div>
          <div className="custom-modal-buttons">
            <button className="btn" onClick={() => {
              setCustomModalOpen(null)
            }}>Cancel</button>
            <button di className="btn btn-primary" onClick={() => {
              createNewSection()
            }} disabled={sectionTitle?.length < 3}>Save</button>
          </div>
      </div>
  </div>;
};

const CreateSubSectionTitleModal = ({setCustomModalOpen,customModalOpen,subSectionTitle,setSubSectionTitle,createNewSubSection}) => {
  return <div className="custom-modal-overlay" onClick={() => setCustomModalOpen(null)}>
      <div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
          <h2 className="custom-modal-heading">Create a Sub-section</h2>
          <p className="custom-modal-description">Enter a single sentence description that best describes the purpose of this section.</p>
          <div className="custom-modal-input-cont">
                <label htmlFor="section-title">Enter section tittle</label>
                <input type="text" id="section-title" value={subSectionTitle} onChange={(e) => setSubSectionTitle(e.target.value)} />
          </div>
          <div className="custom-modal-buttons">
            <button className="btn" onClick={() => {
              setCustomModalOpen(null)
            }}>Cancel</button>
            <button di className="btn btn-primary" onClick={() => {
              createNewSubSection(customModalOpen?.data)
            }} disabled={subSectionTitle?.length < 3}>Save</button>
          </div>
      </div>
  </div>;
};

const CustomDeleteSectionModal = ({setCustomModalOpen,customModalOpen,taxForm,setTaxForm}) => {
	return <div className="custom-modal-overlay" onClick={() => setCustomModalOpen(null)} >
	<div className="custom-modal-cont-delete" onClick={(e) => e.stopPropagation()}>
		<h2 className="custom-modal-heading">Delete Section</h2>
		<p className="custom-modal-description">Are you sure you want to delete this section?</p>
		<div className="custom-modal-buttons">
            <button className="btn" onClick={() => {
              setCustomModalOpen(null)
            }}>Cancel</button>
            <div  className="btn btn-danger" onClick={() => {
			           let updatedForm = {...taxForm}
                 delete updatedForm[customModalOpen?.data]
                 setTaxForm(updatedForm)
                 setCustomModalOpen(null)
            }} >Delete</div>
          </div>
		<div className="custom-modal-buttons">
		</div>
	</div>
</div>;
}	

const CustomDeleteSubSectionModal = ({setCustomModalOpen,customModalOpen,taxForm,setTaxForm}) => {
	return <div className="custom-modal-overlay" onClick={() => setCustomModalOpen(null)} >
	<div className="custom-modal-cont-delete" onClick={(e) => e.stopPropagation()}>
		<h2 className="custom-modal-heading">Delete Sub-Section</h2>
		<p className="custom-modal-description">Are you sure you want to delete this sub-section?</p>
		<div className="custom-modal-buttons">
            <button className="btn" onClick={() => {
              setCustomModalOpen(null)
            }}>Cancel</button>
            <div  className="btn btn-danger" onClick={() => {
			           let updatedForm = {...taxForm}
                 delete updatedForm[customModalOpen?.data[0]][customModalOpen?.data[1]]
                 setTaxForm(updatedForm)
                 setCustomModalOpen(null)
            }} >Delete</div>
          </div>
		<div className="custom-modal-buttons">
		</div>
	</div>
</div>;
}	


const AddNewQuestion = ({setCustomModalOpen,setTaxForm,taxForm,activeSectionIndex,activeSubSectionIndex}) => {
  const [fieldType,setFieldType] = useState('')
  const [questionText,setQuestionText] = useState('')
  const [showSubMenu, setShowSubMenu] = useState(false);
  // const [yesNoField,setYesNoField] = useState([])


  
  const handleChange = (selected) => {
    const field = selected?.length > 1 ? selected[1] : selected[0] 
    setFieldType(field)
  };

  
  return (
    <div className="custom-modal-overlay">
      <div
        className="custom-modal-cont-add-question"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="d-flex justify-content-end">
          <img
            className="cursor-pointer"
            src={CloseIcon}
            alt="close"
            onClick={() => setCustomModalOpen(null)}
          />
        </div>
        <h2 className="custom-modal-heading-add-question">
          Configure question
        </h2>
        <div className="d-flex align-items-center justify-content-between mt-5 px-3">
          <label htmlFor="question-text">Question title</label>
          <input
            className="Question-text-input"
            type="text"
            id="question-text"
            placeholder="Short answer text ..."
            value={questionText}
            onChange={(e) => setQuestionText(e.target.value) }
          />
        </div>
        <div className="d-flex align-items-center justify-content-between mt-5 px-3">
          <div>Field type</div>
         <Cascader options={fieldOptions} onChange={handleChange}  getPopupContainer={(trigger) => trigger.parentElement} placeholder="Select Field Type..." />
        </div>
        <div className="d-flex align-items-center justify-content-between mt-5 px-3">
          <label htmlFor="question-text">Requeired</label>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckChecked"
              checked
            />
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center gap-3 mt-5">
          <div
            className="text-danger cursor-pointer"
            onClick={() => setCustomModalOpen(null)}
          >
            Cancel (close)
          </div>
          <div className="close-form-btn" onClick={() => {
            const uniqId = generateUniqueId()
            const updatedTaxForm = {...taxForm}
            let questionData;

            if (
              fieldType === "text-one-line" ||
              fieldType === "text-multi-line" ||
              fieldType === "text-email" ||
              fieldType === "number-any-number" ||
              fieldType === "number-percentage" ||
              fieldType === "number-amount" ||
              fieldType === "date" ||
              fieldType === "divider"
            ) {
              questionData = {
                id:uniqId,
                identifier:questionText + '-' + uniqId,
                type:fieldType,
                fieldType:'',
                question:questionText,
                answer:''
              }
            }

            if ( fieldType === "yes-no" ){
              questionData = {
                id:uniqId,
                identifier:questionText + '-' + uniqId,
                type:fieldType,
                fieldType:'',
                question:questionText,
                questionData:['yes','no'],
                answer:''
              }
            }
            if ( fieldType === "dropdown-filing-status" ){
              questionData = {
                id:uniqId,
                identifier:questionText + '-' + uniqId,
                type:fieldType,
                fieldType:'',
                question:questionText,
                questionData:filinigStatusData,
                answer:''
              }
            }
            if ( fieldType === "dropdown-states" ){
              questionData = {
                id:uniqId,
                identifier:questionText + '-' + uniqId,
                type:fieldType,
                fieldType:'',
                question:questionText,
                questionData:americanStatesData,
                answer:''
              }
            }
            if (
              !Array.isArray(
                updatedTaxForm[activeSectionIndex][
                  activeSubSectionIndex
                ]
              )
            ) {
              updatedTaxForm[activeSectionIndex][
                activeSubSectionIndex
              ] = [];
            }
            updatedTaxForm[activeSectionIndex][
              activeSubSectionIndex
            ] = [
              ...updatedTaxForm[activeSectionIndex][
                activeSubSectionIndex
              ],
              questionData
            ];
            setTaxForm(updatedTaxForm)
            setCustomModalOpen(null)
          }}>Done (close)</div>
        </div>
      </div>
    </div>
  );
}


const CreateNewForm = ({setSelectedDynamicForms,formTitle,setFormTitle,setCreateFormModalOpen,editmode,formToEdit}) => {
  const [taxForm, setTaxForm] = useState(editmode ? formToEdit : {});
  const [sectionCreation, setSectionCreation] = useState(false);
  const [subSectionCreation, setSubSectionCreation] = useState(false);
  const [sectionTitle, setSectionTitle] = useState("");
  const [subSectionTitle, setSubSectionTitle] = useState("");
  const [sectionIndex, setSectionIndex] = useState(null);
  const [activeSectionIndex,setActiveSectionIndex] = useState(null)
  const [activeSubSectionIndex,setActiveSubSectionIndex] = useState(null)
  const [isSelectingFieldType,setIsSelectingFieldType] = useState(false)
  const [fieldType,setFieldType] = useState('text-one-line')
  const [options,setOptions] = useState({id:generateUniqueId(),data:[]})
  const [selectBox,setSelectBox] = useState({id:generateUniqueId(),data:[]})
  const [inputFields,setInputFields] = useState({id:generateUniqueId(),data:[]})
  const [checkboxes,setCheckboxes] = useState({id:generateUniqueId(),data:[]})
  const [inputType,setInputType] = useState('')
  const [editingSection,setEditingSection] = useState(null)
  const [customModalOpen,setCustomModalOpen] = useState(null)
  const dispatch = useDispatch()




  const changeLoading = (status) => {
    dispatch(allActions.global.Loader(status));
  };
    
  const createNewSection = () => {
    setTaxForm((prev) => {
      return { ...prev, [sectionTitle]: null };
    });
    setSectionCreation(false);
    setSectionTitle("");
    setCustomModalOpen(null)
  };
  const createNewSubSection = (sectionTitle) => {
    setTaxForm((prev) => {
      const updatedSection = { ...prev[sectionTitle] };
      updatedSection[subSectionTitle] = null;
      setActiveSectionIndex(sectionTitle)
      setActiveSubSectionIndex(subSectionTitle)
      setCustomModalOpen(null)
      return {
        ...prev,
        [sectionTitle]: updatedSection,
      };
    }  
  );

    setSubSectionCreation(false);
    setSubSectionTitle("");
  };


  const handleDeleteField = (data) => {
    let updatedTaxForm = { ...taxForm };
    let subsection = updatedTaxForm[activeSectionIndex][activeSubSectionIndex];
    let section = subsection.find((seq) => seq.id === editingSection);
    if (section) {
        let fields = section.data;
        let filteredData = fields.filter((input) => input.id !== data.id);
        section.data = filteredData;
        updatedTaxForm[activeSectionIndex][activeSubSectionIndex] = subsection;
        setTaxForm(updatedTaxForm);
    }
  }



  const handleSaveForm = async () => {
      setSelectedDynamicForms((prev) => [...prev, {name:formTitle,value:formTitle,label:formTitle, type:'custom-form', data:taxForm, message:''}])
      setCreateFormModalOpen(false)
      setFormTitle('')
      setIsSelectingFieldType(false)
      setEditingSection(false)
      setActiveSectionIndex(null)
      setActiveSubSectionIndex(null)
      setCheckboxes({id:generateUniqueId(),data:[]})
      setInputFields({id:generateUniqueId(),data:[]})
      setOptions({id:generateUniqueId(),data:[]})
      setSelectBox({id:generateUniqueId(),data:[]})
      setSectionCreation(false)
      setSubSectionCreation(false)
      setInputType('')
      setSectionTitle('')
      setSubSectionTitle('')
      setTaxForm({})
}

useEffect(() => {
  setCustomModalOpen({state:'sectiontitle',data:{}})
},[])

console.log(customModalOpen);

  return (
    <div>
      {customModalOpen?.state === "sectiontitle" && (
        <CreateSectionTitleModal
          setCustomModalOpen={setCustomModalOpen}
          sectionTitle={sectionTitle}
          setSectionTitle={setSectionTitle}
          createNewSection={createNewSection}
        />
      )}
       {customModalOpen?.state === "subsectiontitle" && (
        <CreateSubSectionTitleModal
          setCustomModalOpen={setCustomModalOpen}
          subSectionTitle={subSectionTitle}
          setSubSectionTitle={setSubSectionTitle}
          createNewSubSection={createNewSubSection}
          customModalOpen={customModalOpen}
        />
      )}
      {customModalOpen?.state === "addquestion" && (
        <AddNewQuestion
          setCustomModalOpen={setCustomModalOpen}
          setTaxForm={setTaxForm}
          taxForm={taxForm}
          activeSectionIndex={activeSectionIndex}
          activeSubSectionIndex={activeSubSectionIndex}
        />
      )}
      {customModalOpen?.state === "deletesection" && (
        <CustomDeleteSectionModal
          setCustomModalOpen={setCustomModalOpen}
          customModalOpen={customModalOpen}
          setTaxForm={setTaxForm}
          taxForm={taxForm}
        />
      )}
       {customModalOpen?.state === "deletesubsection" && (
        <CustomDeleteSubSectionModal
          setCustomModalOpen={setCustomModalOpen}
          customModalOpen={customModalOpen}
          setTaxForm={setTaxForm}
          taxForm={taxForm}
        />
      )}

      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex  gap-3  sticky col-md-5 ">
          {/* <div className="d-flex flex-column gap-2">
            <label htmlFor="formtitle" className="text-left fw-bold">
              Enter Form title
            </label>
            <input
              className="form-control"
              type="text"
              id="formtitle"
              placeholder="Please enter the form title"
              value={formTitle}
              onChange={(e) => setFormTitle(e.target.value)}
            />
          </div> */}
          

          {/* {sectionCreation && (
            <input
              type="email"
              className="form-control w-md border-0 outline-0"
              aria-describedby="emailHelp"
              placeholder="Enter section title"
              value={sectionTitle}
              onChange={(e) => setSectionTitle(e.target.value)}
            ></input>
          )} */}
         <div className="border-cont w-100">
         <div className="d-flex gap-3">
              <div
                onClick={() => {
                  setCustomModalOpen({state:"sectiontitle",data:{}});
                  setSectionCreation(true);
                }}
                className="w-100 d-flex justify-content-between align-items-center sections-bg "
              >
                <div className="">Sections</div>
                <img
                  className="cursor-pointer "
                  src={CreateSectionIcon}
                  alt="Create section"
                />
              </div>

              
    
            {/* {sectionCreation && (
              <button
                onClick={cancelSectionCriation}
                className="btn btn-danger"
              >
                Cancel
              </button>
            )}
            {sectionCreation && sectionTitle?.length > 3 && (
              <button onClick={createNewSection} className="btn btn-success">
                Save
              </button>
            )} */}
          </div>
        <div className="overflow-y-scroll w-100 sections-cont">
        {Object.keys(taxForm)?.length > 0 &&
            Object.keys(taxForm).map((section, index) => {
              return (
                <div>
                  <div className="d-flex flex justify-content-between gap-2 p-3 border-bottom align-items-center">
                    <div className="d-flex align-items-center gap-3">
                      <img src={DotsIcon} className="icon-dots" alt="icon" />
                      <div className="d-flex gap-2">
                        <div
                          className={`text-start  custom-font cursor-pointer ${
                            activeSectionIndex === section
                              ? "text-primary"
                              : "text-black"
                          }`}
                        >
                          {section}
                        </div>
                        <div
                          className="text-sm text-success cursor-pointer custom-sunsection-btn"
                          onClick={() => {
                            setSubSectionCreation(true);
                            setSectionIndex(index);
                            setCustomModalOpen({
                              state: "subsectiontitle",
                              data: section,
                            });
                          }}
                        >
                          {`${"+ Add Sub-section"}`}
                        </div>
                      </div>
                    </div>
                    <img src={DeleteIcon2} alt="icon" className="icon-delete" onClick={() => {
                      setCustomModalOpen({state:'deletesection',data:section})
                     }} />

                    {/* {subSectionCreation && sectionIndex === index && (
                    <input
                      type="email"
                      className="form-control w-md border-0 outline-0"
                      aria-describedby="emailHelp"
                      placeholder="Enter subsection title"
                      value={subSectionTitle}
                      onChange={(e) => setSubSectionTitle(e.target.value)}
                    ></input>
                  )} */}
                    {/* <div className="d-flex gap-2">
                    {subSectionCreation && sectionIndex === index && (
                      <>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={cancelSubSectionCreation}
                        >
                          Cancel
                        </button>
                        {subSectionTitle?.length > 3 && (
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => createNewSubSection(section)}
                          >
                            Save
                          </button>
                        )}
                      </>
                    )}
                  </div> */}
                  </div>
                  <div className="d-flex flex-column gap-1">
                    {taxForm[section] &&
                      Object.keys(taxForm[section])?.length > 0 &&
                      Object.keys(taxForm[section]).map((subsection, sub) => {
                        return (
                          <div className="d-flex justify-content-between py-2 sub-section-cont" lign-items-center>
                           <div className="d-flex align-items-center">
                           <img
                              src={DotsIcon}
                              className="icon-dots-sub"
                              alt="icon"
                            />
                            <div
                              className={`text-start mx-3  custom-font-sub  cursor-pointer ${
                                activeSubSectionIndex === subsection
                                  ? "text-primary"
                                  : ""
                              }`}
                              key={sub}
                              onClick={() => {
                                setActiveSubSectionIndex(subsection);
                                setActiveSectionIndex(section);
                              }}
                            >
                              {subsection}
                            </div>
                           </div>
                            <img
                              src={DeleteIcon2}
                              alt="icon"
                              className="icon-delete"
                              onClick={() => {
                                setCustomModalOpen({state:'deletesubsection',data:[section,subsection]})
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
        </div>
         </div>
        </div>
        <div className="d-flex justify-content-start col-md-7">
          <div className="p-1 w-100">
            <div className="form-heading">{formTitle}</div>
            <div className="form-questoins-cont">
              <div className="form-seub-section-title">{activeSubSectionIndex}</div>
              {activeSectionIndex && activeSubSectionIndex && taxForm[activeSectionIndex][activeSubSectionIndex]?.length > 0 &&  taxForm[activeSectionIndex][activeSubSectionIndex]?.map((question) => {
                if (question?.type === "divider") {
                  return (
                    <div className="section-divider">
                      <div className="d-flex align-items-center">
                        <img src={DotsIcon} alt="dots" className="dots-icon" />
                        <div className="divider-title">
                          {question?.question}
                        </div>
                      </div>
                      <div>
                        <img
                          src={DeleteIcon2}
                          alt="edit"
                          className="delete-section-icon"
                          onClick={() => {
                            const updatedForm = { ...taxForm };
                            const filteredData = taxForm[activeSectionIndex][
                              activeSubSectionIndex
                            ]?.filter(
                              (divider) => divider?.id !== question?.id
                            );
                            updatedForm[activeSectionIndex][
                              activeSubSectionIndex
                            ] = filteredData;
                            setTaxForm(updatedForm);
                          }}
                        />
                      </div>
                    </div>
                  );
                }
                if (question?.type === "text-one-line") {
                  return (
                    <div className="question-cont">
                      <div>
                        <img src={DotsIcon} alt="dots" className="dots-icon" />
                        <label htmlFor={question?.question}>
                          {question?.question}
                        </label>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="text"
                          id={question?.question}
                          placeholder="Enter your answer"
                          className="question-input input-cont"
                        />
                        <img
                          src={EditIcon}
                          alt="edit"
                          className="delete-section-icon"
                        />
                      </div>
                    </div>
                  );
                }
                if (question?.type === "text-multi-line") {
                  return (
                    <div className="question-cont">
                      <div>
                        <img src={DotsIcon} alt="dots" className="dots-icon" />
                        <label htmlFor={question?.question}>
                          {question?.question}
                        </label>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <textarea
                          id={question?.question}
                          placeholder="Enter your answer"
                          className="question-textarea input-cont" 
                        ></textarea>
                        <img
                          src={EditIcon}
                          alt="edit"
                          className="delete-section-icon"
                        />
                      </div>
                    </div>
                  );
                }

                if (question?.type === "text-email") {
                  return (
                    <div className="question-cont">
                      <div>
                        <img src={DotsIcon} alt="dots" className="dots-icon" />
                        <label htmlFor={question?.question}>
                          {question?.question}
                        </label>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="email"
                          id={question?.question}
                          placeholder="Enter your email"
                          className="question-input input-cont"
                        />
                        <img
                          src={EditIcon}
                          alt="edit"
                          className="delete-section-icon"
                        />
                      </div>
                    </div>
                  );
                }
                if (question?.type === "number-any-number") {
                  return (
                    <div className="question-cont">
                      <div>
                        <img src={DotsIcon} alt="dots" className="dots-icon" />
                        <label htmlFor={question?.question}>
                          {question?.question}
                        </label>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="number"
                          id={question?.question}
                          placeholder="0"
                          className="question-input input-cont"
                        />
                        <img
                          src={EditIcon}
                          alt="edit"
                          className="delete-section-icon"
                        />
                      </div>
                    </div>
                  );
                }
                if (question?.type === "number-amount") {
                  return (
                    <div className="question-cont">
                      <div>
                        <img src={DotsIcon} alt="dots" className="dots-icon" />
                        <label htmlFor={question?.question}>
                          {question?.question}
                        </label>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <div className="amount-input-cont input-cont">
                          <div className="amount-icon-cont">$</div>
                          <input
                            type="number"
                            id={question?.question}
                            placeholder="0"
                          />
                        </div>
                        <img
                          src={EditIcon}
                          alt="edit"
                          className="delete-section-icon"
                        />
                      </div>
                    </div>
                  );
                }
                if (question?.type === "number-percentage") {
                  return (
                    <div className="question-cont">
                      <div>
                        <img src={DotsIcon} alt="dots" className="dots-icon" />
                        <label htmlFor={question?.question}>
                          {question?.question}
                        </label>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <div className="amount-input-cont input-cont">
                          <input
                            type="number"
                            id={question?.question}
                            placeholder="0"
                          />
                          <div className="amount-icon-cont">%</div>
                        </div>
                        <img
                          src={EditIcon}
                          alt="edit"
                          className="delete-section-icon"
                        />
                      </div>
                    </div>
                  );
                }

                 if (question?.type === "yes-no") {
                  return (
                    <div className="question-cont">
                      <div>
                        <img src={DotsIcon} alt="dots" className="dots-icon" />
                        <label htmlFor={question?.question}>
                          {question?.question}
                        </label>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <div className="d-flex gap-3">
                            {question?.questionData?.map((answer) => {
                              return <div className="yes-no-cont">{answer}</div>
                            })}
                        </div>
                        <img
                          src={EditIcon}
                          alt="edit"
                          className="delete-section-icon"
                        />
                      </div>
                    </div>
                  );
                }
                if (question?.type === "date") {
                  return (
                    <div className="question-cont">
                      <div>
                        <img src={DotsIcon} alt="dots" className="dots-icon" />
                        <label htmlFor={question?.question}>
                          {question?.question}
                        </label>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="date"
                          id={question?.question}
                          placeholder="Enter your answer"
                          className="question-input input-cont"
                        />
                        <img
                          src={EditIcon}
                          alt="edit"
                          className="delete-section-icon"
                        />
                      </div>
                    </div>
                  );
                }
                 if (question?.type.includes('dropdown')) {
               const options =   question?.questionData?.map((state) => ({
                               value: state,
                               label: state,
                             }))
                   return (
                     <div className="question-cont">
                       <div>
                         <img src={DotsIcon} alt="dots" className="dots-icon" />
                         <label htmlFor={question?.question}>
                           {question?.question}
                         </label>
                       </div>
                       <div className="d-flex align-items-center gap-2">
                         <ReactSelect
                         className="input-cont"
                           options={options}
                         />
                         <img
                           src={EditIcon}
                           alt="edit"
                           className="delete-section-icon"
                         />
                       </div>
                     </div>
                   );
                 }
              } 
              
            )
              }
              
              

              
            </div>
            <div className="d-flex flex-column">
             
              {/* {activeSectionIndex &&
                activeSubSectionIndex &&
                taxForm[activeSectionIndex][activeSubSectionIndex]?.length >
                  0 &&
                taxForm[activeSectionIndex][activeSubSectionIndex].map(
                  (section, index) => {
                    if (editingSection && editingSection !== section?.id) {
                      return "";
                    } else {
                      return (
                        <div
                          key={index}
                          className={`row g-3 p-3 ${
                            editingSection === section?.id
                              ? "editing-section"
                              : ""
                          }`}
                        >
                          <div>
                            <div className="d-flex justify-content-between">
                              <p className="section-title-styles">
                                {section.sectionTitle}
                              </p>
                              {editingSection && (
                                <p className="section-title-styles text-danger fw-bold">
                                  Editing
                                </p>
                              )}
                            </div>
                            {section.sectionTitle && (
                              <div className="custom-line"></div>
                            )}
                          </div>
                          {section?.data?.map((data) => {
                            if (data?.type === "checkbox") {
                              return (
                                <div key={data?.id}>
                                  <h1 className="custom-gray-text fw-normal text-left fs-5 mt-2 d-flex justify-content-between">
                                    <p className="m-0">{data?.optionTitle}</p>
                                    {editingSection && (
                                      <p
                                        className="m-0 text-danger cursor-pointer"
                                        onClick={() => handleDeleteField(data)}
                                      >
                                        Delete
                                      </p>
                                    )}
                                  </h1>
                                  {data?.optionFields?.map((field) => {
                                    return (
                                      <div
                                        className={`w-full d-flex align-items-center gap-3 justfy-content-start mt-3 py-3 px-3 border border-light rounded`}
                                      >
                                        <input
                                          className={`custom-radio-labelf`}
                                          type="checkbox"
                                          name={`field`}
                                          disabled={true}
                                        />
                                        <label
                                          className={`custom-form-check-inputd`}
                                          htmlFor={`field`}
                                        >
                                          {field?.value}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            }
                            if (data?.type === "options") {
                              return (
                                <div>
                                  <h1 className="custom-gray-text fw-normal text-left fs-5 mt-2 d-flex justify-content-between">
                                    <p className="m-0">{data?.optionTitle}</p>
                                    {editingSection && (
                                      <p
                                        className="m-0 text-danger cursor-pointer"
                                        onClick={() => handleDeleteField(data)}
                                      >
                                        Delete
                                      </p>
                                    )}
                                  </h1>
                                  {data?.optionFields?.map((field) => {
                                    return (
                                      <div
                                        className={`w-full d-flex align-items-center gap-3 justfy-content-start mt-3 py-3 px-3 border border-light rounded`}
                                      >
                                        <input
                                          className={`custom-radio-labelf`}
                                          type="radio"
                                          name={`field`}
                                          disabled={true}
                                        />
                                        <label
                                          className={`custom-form-check-inputd`}
                                          htmlFor={`field`}
                                        >
                                          {field?.value}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            } else if (data?.type === "selectbox") {
                              return (
                                <div className="">
                                  <h1 className="custom-gray-text fw-normal text-left fs-5 d-flex justify-content-between">
                                    <p className="m-0">
                                      {data?.selectBoxTitle}
                                    </p>
                                    {editingSection && (
                                      <p
                                        className="m-0 text-danger cursor-pointer"
                                        onClick={() => handleDeleteField(data)}
                                      >
                                        Delete
                                      </p>
                                    )}
                                  </h1>
                                  <ReactSelect
                                    className="mt-3 mb-3"
                                    options={data?.selectBoxFields?.map(
                                      (field) => ({
                                        value: field?.value,
                                        label: field?.value,
                                      })
                                    )}
                                  />
                                </div>
                              );
                            } else if (data?.type === "input") {
                              return (
                                <div key={data?.id} className="col-md-6">
                                  <div className="d-flex flex-column justify-content-start">
                                    <label
                                      htmlFor={`${data?.label}`}
                                      className="form-label text-left d-flex justify-content-between"
                                    >
                                      <p className="m-0">{data?.label}:</p>
                                      {editingSection && (
                                        <p
                                          className="m-0 text-danger fw-bold cursor-pointer"
                                          onClick={() =>
                                            handleDeleteField(data)
                                          }
                                        >
                                          Delete
                                        </p>
                                      )}
                                    </label>
                                    {data?.type === "textarea" ? (
                                      <textarea
                                        disabled={true}
                                        className="form-control"
                                      ></textarea>
                                    ) : (
                                      <input
                                        disabled={true}
                                        className="form-control"
                                        type={
                                          data?.fieldType === "numberfield"
                                            ? "number"
                                            : data?.fieldType === "emailfield"
                                            ? "email"
                                            : "text"
                                        }
                                        id={`${data?.label}`}
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          })}
                          {editingSection !== section?.id && (
                            <button
                              className="btn btn-sm editing-section d-inline"
                              onClick={() => {
                                setEditingSection(section?.id);
                                setIsSelectingFieldType(false);
                              }}
                            >
                              Edit section
                            </button>
                          )}
                          {editingSection === section?.id && (
                            <div className="d-flex flex-column ">
                              {activeSectionIndex && activeSubSectionIndex && (
                                <div className="d-flex align-items-center gap-2">
                                  {!isSelectingFieldType && (
                                    <button
                                      className="btn btn-primary btn-styles "
                                      onClick={() => {
                                        setIsSelectingFieldType(true);
                                      }}
                                    >
                                      Add in section
                                    </button>
                                  )}
                                  <button
                                    className="btn btn-danger btn-styles"
                                    onClick={() => {
                                      setEditingSection(null);
                                      setIsSelectingFieldType(false);
                                    }}
                                  >
                                    Stop Editing
                                  </button>
                                </div>
                              )}
                              {isSelectingFieldType && (
                                <select
                                  className="form-select mt-3"
                                  onChange={(e) => setFieldType(e.target.value)}
                                >
                                  <option value="options">Options</option>
                                  <option value="checkbox">Checkbox</option>
                                  <option value="selectbox">Select Box</option>
                                  <option value="textfield">Input Field</option>
                                </select>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    }
                  }
                )}
        
                 </div>} */}
                {Object.keys(taxForm)?.length > 0 && activeSectionIndex && activeSubSectionIndex &&  taxForm[activeSectionIndex][activeSubSectionIndex] === null  &&   <div className="add-cuestions-cont">
                  <div lassName="text-center add-question-title">Add Question</div>
                  <div className="text-center add-question-description">{activeSubSectionIndex ? 'Click the button below to add a question to this section and specify the field type. You can pick from text fields, dropdowns, lists and more.' : 'To add a new question, you need to create a sub-section first'}</div>
                </div> }

                {!activeSubSectionIndex &&   <div className="add-cuestions-cont">
                  <div lassName="text-center add-question-title">Add Sub-section</div>
                  <div className="text-center add-question-description">{activeSubSectionIndex ? 'Click the button below to add a question to this section and specify the field type. You can pick from text fields, dropdowns, lists and more.' : 'To add a new question, you need to create a sub-section first'}</div>
                </div>}
               {activeSectionIndex &&
                activeSubSectionIndex && 
               <div className="add-question-btn-cont">
                 <div className="add-question-btn" onClick={() => setCustomModalOpen({state:'addquestion',data:null})}>
                    Add Question
                  </div>
                 </div>}
              {/* {!editingSection && (
                <div className="d-flex flex-column p-3 mt-5">
                  {activeSectionIndex && activeSubSectionIndex && (
                    <button
                      className="btn btn-primary btn-styles "
                      onClick={() => {
                        setIsSelectingFieldType(true);
                      }}
                    >
                      Add new input field
                    </button>
                  )}
                  {Object.keys(taxForm)?.length > 0 && (
                    <button
                      className="btn btn-success mt-3"
                      onClick={handleSaveForm}
                    >
                      Done  
                    </button>
                  )}
                  {isSelectingFieldType && (
                    <select
                      className="form-select mt-3"
                      onChange={(e) => setFieldType(e.target.value)}
                    >
                      <option value="options">Options</option>
                      <option value="checkbox">Checkbox</option>
                      <option value="selectbox">Select Box</option>
                      <option value="textfield">Input Field</option>
                    </select>
                  )}
                </div>
              )} */}

              {!editingSection && Object.keys(taxForm)?.length > 0 && (
                  <div className="d-flex justify-content-center mt-5"> 
                     <button
                      className="close-form-btn"
                      onClick={handleSaveForm}
                    >
                     {`Done editing ${'(Close)'} `}
                    </button>
                   </div>
                  )}
              {fieldType === "options" && (
                <div className="p-3">
                  {!editingSection && (
                    <div className="d-flex flex-column align-items-start justify-content-start mt-3">
                      <label
                        htmlFor="section-title"
                        className="text-left fw-bold text-black"
                      >
                        Enter section title
                      </label>
                      <input
                        className="form-control mt-3"
                        placeholder="Leave blank if you don't want to create new section"
                        type="text"
                        id="section-title"
                        name="section-title"
                        onChange={(e) =>
                          setOptions({
                            ...options,
                            type: "options",
                            sectionTitle: e.target.value,
                          })
                        }
                      />
                    </div>
                  )}
                  <hr className="mt-3" />
                  <div>
                    {Object.keys(options?.data)?.length > 0 &&
                      options?.data?.map((option, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="d-flex flex-column align-items-start justify-content-start mt-3"
                            >
                              <label
                                htmlFor="option-title"
                                className="text-left fw-bold text-black"
                              >
                                Enter option title
                              </label>
                              <input
                                className="form-control mt-3"
                                type="text"
                                id="option-title"
                                name="option-title"
                                onChange={(e) => {
                                  const updatedOptionsData = options.data.map(
                                    (opt) =>
                                      opt.id === option.id
                                        ? {
                                            ...opt,
                                            optionTitle: e.target.value,
                                          }
                                        : opt
                                  );
                                  setOptions({
                                    ...options,
                                    data: updatedOptionsData,
                                  });
                                }}
                              />
                              <hr className="m-0" />
                            </div>
                            {option.optionFields?.map((optionField, index) => {
                              return (
                                <div
                                  key={index}
                                  className="d-flex flex-column align-items-start justify-content-start mt-3"
                                >
                                  <label htmlFor="option" className="text-left">
                                    option
                                  </label>
                                  <input
                                    className="form-control mt-3"
                                    type="text"
                                    id="option"
                                    name="option"
                                    onChange={(e) => {
                                      let updatedOptionsData = options.data.map(
                                        (opt) => {
                                          if (opt.id === option.id) {
                                            return {
                                              ...opt,
                                              optionFields:
                                                opt.optionFields.map((field) =>
                                                  field.id === optionField.id
                                                    ? {
                                                        ...field,
                                                        value: e.target.value,
                                                      }
                                                    : field
                                                ),
                                            };
                                          }
                                          return opt;
                                        }
                                      );
                                      setOptions({
                                        ...options,
                                        data: updatedOptionsData,
                                      });
                                    }}
                                  />
                                </div>
                              );
                            })}
                            <div className="d-flex justify-content-start mt-2">
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                  let updatedOptionsData = options.data.map(
                                    (opt) =>
                                      opt.id === option.id
                                        ? {
                                            ...opt,
                                            optionFields: [
                                              ...opt.optionFields,
                                              {
                                                id: generateUniqueId(),
                                                value: "",
                                              },
                                            ],
                                          }
                                        : opt
                                  );

                                  setOptions({
                                    ...options,
                                    data: updatedOptionsData,
                                  });
                                }}
                              >
                                Add Option
                              </button>
                            </div>
                          </>
                        );
                      })}
                    <div className="d-flex justify-content-start mt-3 gap-3">
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() =>
                          setOptions({
                            ...options,
                            data: [
                              ...options.data,
                              {
                                id: generateUniqueId(),
                                type: "options",
                                optionTitle: "",
                                optionFields: [],
                              },
                            ],
                          })
                        }
                      >
                        Add New Option Field
                      </button>
                      {!editingSection ? (
                        <button
                          className="btn btn-sm btn-success"
                          onClick={() => {
                            let updatedTaxForm = { ...taxForm };

                            if (
                              !Array.isArray(
                                updatedTaxForm[activeSectionIndex][
                                  activeSubSectionIndex
                                ]
                              )
                            ) {
                              updatedTaxForm[activeSectionIndex][
                                activeSubSectionIndex
                              ] = [];
                            }
                            updatedTaxForm[activeSectionIndex][
                              activeSubSectionIndex
                            ] = [
                              ...updatedTaxForm[activeSectionIndex][
                                activeSubSectionIndex
                              ],
                              options,
                            ];
                            setTaxForm(updatedTaxForm);
                            setOptions({
                              id: generateUniqueId(),
                              data: [],
                            });
                            setIsSelectingFieldType(false);
                          }}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            const newData = options?.data;
                            let updatedTaxForm = JSON.parse(
                              JSON.stringify(taxForm)
                            );
                            let sections =
                              updatedTaxForm[activeSectionIndex][
                                activeSubSectionIndex
                              ];
                            let updatedSection = sections?.find(
                              (sec) => sec?.id === editingSection
                            );
                            if (updatedSection) {
                              updatedSection.data = [
                                ...(updatedSection?.data || []),
                                ...newData,
                              ];
                              updatedTaxForm[activeSectionIndex][
                                activeSubSectionIndex
                              ] = updatedTaxForm[activeSectionIndex][
                                activeSubSectionIndex
                              ]?.map((sec) => {
                                if (sec?.id === editingSection) {
                                  return {
                                    ...sec,
                                    data: [...sec?.data, updatedSection?.data],
                                  };
                                }
                                return sec;
                              });
                              setTaxForm(updatedTaxForm);
                            }
                            setEditingSection(null);
                            setOptions({
                              id: generateUniqueId(),
                              data: [],
                            });
                            setIsSelectingFieldType(false);
                          }}
                          className="btn btn-sm btn-success"
                        >
                          Save Edited
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {fieldType === "checkbox" && (
                <div className="p-3">
                  {!editingSection && (
                    <div className="d-flex flex-column align-items-start justify-content-start mt-3">
                      <label
                        htmlFor="section-title"
                        className="text-left fw-bold text-black"
                      >
                        Enter section title
                      </label>
                      <input
                        className="form-control mt-3"
                        placeholder="Leave blank if you don't want to create new section"
                        type="text"
                        id="section-title"
                        name="section-title"
                        onChange={(e) =>
                          setCheckboxes({
                            ...checkboxes,
                            type: "checkbox",
                            sectionTitle: e.target.value,
                          })
                        }
                      />
                    </div>
                  )}
                  <hr className="mt-3" />
                  <div>
                    {Object.keys(checkboxes?.data)?.length > 0 &&
                      checkboxes?.data?.map((checkbox, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="d-flex flex-column align-items-start justify-content-start mt-3"
                            >
                              <label
                                htmlFor="option-title"
                                className="text-left fw-bold text-black"
                              >
                                Enter checkbox title
                              </label>
                              <input
                                className="form-control mt-3"
                                type="text"
                                id="option-title"
                                name="option-title"
                                onChange={(e) => {
                                  const updatedCheckboxesData =
                                    checkboxes.data.map((chk) =>
                                      chk.id === checkbox.id
                                        ? {
                                            ...chk,
                                            optionTitle: e.target.value,
                                          }
                                        : chk
                                    );
                                  setCheckboxes({
                                    ...checkboxes,
                                    data: updatedCheckboxesData,
                                  });
                                }}
                              />
                              <hr className="m-0" />
                            </div>
                            {checkbox.optionFields?.map(
                              (optionField, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="d-flex flex-column align-items-start justify-content-start mt-3"
                                  >
                                    <label
                                      htmlFor="option"
                                      className="text-left"
                                    >
                                      option
                                    </label>
                                    <input
                                      className="form-control mt-3"
                                      type="text"
                                      id="option"
                                      name="option"
                                      onChange={(e) => {
                                        let updatedCheckboxesData =
                                          checkboxes.data.map((opt) => {
                                            if (opt.id === checkbox.id) {
                                              return {
                                                ...opt,
                                                optionFields:
                                                  opt.optionFields.map(
                                                    (field) =>
                                                      field.id ===
                                                      optionField.id
                                                        ? {
                                                            ...field,
                                                            value:
                                                              e.target.value,
                                                          }
                                                        : field
                                                  ),
                                              };
                                            }
                                            return opt;
                                          });
                                        setCheckboxes({
                                          ...checkboxes,
                                          data: updatedCheckboxesData,
                                        });
                                      }}
                                    />
                                  </div>
                                );
                              }
                            )}
                            <div className="d-flex justify-content-start mt-2">
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                  let updatedChecboxData = checkboxes.data.map(
                                    (opt) =>
                                      opt.id === checkbox.id
                                        ? {
                                            ...opt,
                                            optionFields: [
                                              ...opt.optionFields,
                                              {
                                                id: generateUniqueId(),
                                                value: "",
                                              },
                                            ],
                                          }
                                        : opt
                                  );
                                  setCheckboxes({
                                    ...checkboxes,
                                    data: updatedChecboxData,
                                  });
                                }}
                              >
                                Add Option
                              </button>
                            </div>
                          </>
                        );
                      })}
                    <div className="d-flex justify-content-start mt-3 gap-3">
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() =>
                          setCheckboxes({
                            ...checkboxes,
                            data: [
                              ...checkboxes.data,
                              {
                                id: generateUniqueId(),
                                type: "checkbox",
                                optionTitle: "",
                                optionFields: [],
                              },
                            ],
                          })
                        }
                      >
                        Add New Checkbox Field
                      </button>
                      {!editingSection ? (
                        <button
                          className="btn btn-sm btn-success"
                          onClick={() => {
                            let updatedTaxForm = { ...taxForm };

                            if (
                              !Array.isArray(
                                updatedTaxForm[activeSectionIndex][
                                  activeSubSectionIndex
                                ]
                              )
                            ) {
                              updatedTaxForm[activeSectionIndex][
                                activeSubSectionIndex
                              ] = [];
                            }
                            updatedTaxForm[activeSectionIndex][
                              activeSubSectionIndex
                            ] = [
                              ...updatedTaxForm[activeSectionIndex][
                                activeSubSectionIndex
                              ],
                              checkboxes,
                            ];
                            setTaxForm(updatedTaxForm);
                            setCheckboxes({
                              id: generateUniqueId(),
                              data: [],
                            });
                            setIsSelectingFieldType(false);
                          }}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            const newData = checkboxes?.data;
                            let updatedTaxForm = JSON.parse(
                              JSON.stringify(taxForm)
                            );
                            let sections =
                              updatedTaxForm[activeSectionIndex][
                                activeSubSectionIndex
                              ];
                            let updatedSection = sections?.find(
                              (sec) => sec?.id === editingSection
                            );
                            if (updatedSection) {
                              updatedSection.data = [
                                ...(updatedSection?.data || []),
                                ...newData,
                              ];
                              updatedTaxForm[activeSectionIndex][
                                activeSubSectionIndex
                              ] = updatedTaxForm[activeSectionIndex][
                                activeSubSectionIndex
                              ]?.map((sec) => {
                                if (sec?.id === editingSection) {
                                  return {
                                    ...sec,
                                    data: [...sec?.data, updatedSection?.data],
                                  };
                                }
                                return sec;
                              });
                              setTaxForm(updatedTaxForm);
                            }
                            setEditingSection(null);
                            setCheckboxes({
                              id: generateUniqueId(),
                              data: [],
                            });
                            setIsSelectingFieldType(false);
                          }}
                          className="btn btn-sm btn-success"
                        >
                          Save Edited
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {fieldType === "selectbox" && (
                <div className="p-3">
                  {!editingSection && (
                    <div className="d-flex flex-column align-items-start justify-content-start ">
                      <label
                        htmlFor="section-title"
                        className="text-left fw-bold text-black"
                      >
                        Enter section title
                      </label>
                      <input
                        className="form-control mt-3"
                        placeholder="Leave blank if you don't want to create new section"
                        type="text"
                        id="section-title"
                        name="section-title"
                        onChange={(e) =>
                          setSelectBox({
                            ...selectBox,
                            sectionTitle: e.target.value,
                          })
                        }
                      />
                    </div>
                  )}
                  {Object.keys(selectBox?.data)?.length > 0 &&
                    selectBox?.data?.map((select, index) => {
                      return (
                        <div key={index}>
                          <div className="d-flex flex-column align-items-start justify-content-start mt-3">
                            <label
                              htmlFor="selectBox-title"
                              className="text-left fw-bold text-black"
                            >
                              Enter select Box title
                            </label>
                            <input
                              className="form-control mt-3"
                              type="text"
                              id="selectBox-title"
                              name="selectBox-title"
                              onChange={(e) => {
                                const updatedSelectBoxData = selectBox.data.map(
                                  (sel) =>
                                    sel.id === select.id
                                      ? {
                                          ...sel,
                                          selectBoxTitle: e.target.value,
                                        }
                                      : sel
                                );
                                setSelectBox({
                                  ...selectBox,
                                  data: updatedSelectBoxData,
                                });
                              }}
                            />
                            <hr className="m-0" />
                          </div>
                          {select.selectBoxFields?.map((selectField, index) => {
                            return (
                              <div
                                key={index}
                                className="d-flex flex-column align-items-start justify-content-start mt-3"
                              >
                                <label
                                  htmlFor="select-option"
                                  className="text-left"
                                >
                                  select option
                                </label>
                                <input
                                  className="form-control mt-3"
                                  type="text"
                                  id="select-option"
                                  name="select-option"
                                  onChange={(e) => {
                                    let updatedSelectBoxData =
                                      selectBox.data.map((sel) => {
                                        if (sel.id === select.id) {
                                          return {
                                            ...sel,
                                            selectBoxFields:
                                              sel.selectBoxFields.map((field) =>
                                                field.id === selectField.id
                                                  ? {
                                                      ...field,
                                                      value: e.target.value,
                                                    }
                                                  : field
                                              ),
                                          };
                                        }
                                        return sel;
                                      });

                                    setSelectBox({
                                      ...selectBox,
                                      data: updatedSelectBoxData,
                                    });
                                  }}
                                />
                              </div>
                            );
                          })}
                          <div className="d-flex justify-content-start mt-2">
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                let updatedSelectBoxData = selectBox.data.map(
                                  (sel) =>
                                    sel.id === select.id
                                      ? {
                                          ...sel,
                                          selectBoxFields: [
                                            ...sel.selectBoxFields,
                                            {
                                              id: generateUniqueId(),
                                              value: "",
                                            },
                                          ],
                                        }
                                      : sel
                                );

                                setSelectBox({
                                  ...selectBox,
                                  data: updatedSelectBoxData,
                                });
                              }}
                            >
                              Add select box Option
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  <div className="d-flex justify-content-start mt-3 gap-3">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() =>
                        setSelectBox({
                          ...selectBox,
                          data: [
                            ...selectBox.data,
                            {
                              id: generateUniqueId(),
                              type: "selectbox",
                              selectBoxTitle: "",
                              selectBoxFields: [],
                            },
                          ],
                        })
                      }
                    >
                      Add New Select box
                    </button>
                    {!editingSection ? (
                      <button
                        className="btn btn-sm btn-success"
                        onClick={() => {
                          let updatedTaxForm = { ...taxForm };

                          if (
                            !Array.isArray(
                              updatedTaxForm[activeSectionIndex][
                                activeSubSectionIndex
                              ]
                            )
                          ) {
                            updatedTaxForm[activeSectionIndex][
                              activeSubSectionIndex
                            ] = [];
                          }
                          updatedTaxForm[activeSectionIndex][
                            activeSubSectionIndex
                          ] = [
                            ...updatedTaxForm[activeSectionIndex][
                              activeSubSectionIndex
                            ],
                            selectBox,
                          ];
                          setTaxForm(updatedTaxForm);
                          setSelectBox({
                            id: generateUniqueId(),
                            data: [],
                          });
                          setIsSelectingFieldType(false);
                        }}
                      >
                        Save section
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          const newData = selectBox?.data;
                          let updatedTaxForm = JSON.parse(
                            JSON.stringify(taxForm)
                          );
                          let sections =
                            updatedTaxForm[activeSectionIndex][
                              activeSubSectionIndex
                            ];
                          let updatedSection = sections?.find(
                            (sec) => sec?.id === editingSection
                          );
                          if (updatedSection) {
                            updatedSection.data = [
                              ...(updatedSection?.data || []),
                              ...newData,
                            ];
                            updatedTaxForm[activeSectionIndex][
                              activeSubSectionIndex
                            ] = updatedTaxForm[activeSectionIndex][
                              activeSubSectionIndex
                            ]?.map((sec) => {
                              if (sec?.id === editingSection) {
                                return {
                                  ...sec,
                                  data: [...sec?.data, updatedSection?.data],
                                };
                              }
                              return sec;
                            });
                            setTaxForm(updatedTaxForm);
                          }
                          setEditingSection(null);
                          setSelectBox({
                            id: generateUniqueId(),
                            data: [],
                          });
                          setIsSelectingFieldType(false);
                        }}
                        className="btn btn-sm btn-success"
                      >
                        Save Edited
                      </button>
                    )}
                  </div>
                </div>
              )}

              {fieldType === "textfield" && (
                <div className="p-3">
                  {!editingSection && (
                    <div className="d-flex flex-column align-items-start justify-content-start mt-3">
                      <label
                        htmlFor="section-title"
                        className="text-left fw-bold text-black"
                      >
                        Enter section title
                      </label>
                      <input
                        className="form-control mt-3"
                        placeholder="Leave blank if you don't want to create new section"
                        type="text"
                        id="section-title"
                        name="section-title"
                        onChange={(e) =>
                          setInputFields({
                            ...inputFields,
                            sectionTitle: e.target.value,
                          })
                        }
                      />
                    </div>
                  )}
                  {Object.keys(inputFields?.data)?.length > 0 &&
                    inputFields?.data?.map((input, index) => {
                      return (
                        <div key={input?.id}>
                          <div className="d-flex flex-column mt-3">
                            <label htmlFor="label" className="text-left">
                              Input label:
                            </label>
                            <input
                              className="form-control mt-2"
                              type="text"
                              value={input?.label}
                              onChange={(e) => {
                                const updatedInputsData =
                                  inputFields?.data?.map((inp) =>
                                    inp.id === input.id
                                      ? {
                                          ...inp,
                                          type: "input",
                                          fieldType: inputType,
                                          label: e.target.value,
                                        }
                                      : inp
                                  );
                                setInputFields({
                                  ...inputFields,
                                  data: updatedInputsData,
                                });
                              }}
                            />
                          </div>
                          <select
                            className="form-select mt-3"
                            onChange={(e) => {
                              setInputType(e.target.value);
                              const updatedInputsData = inputFields?.data?.map(
                                (inp) =>
                                  inp.id === input.id
                                    ? {
                                        ...inp,
                                        fieldType: e.target.value,
                                      }
                                    : inp
                              );
                              setInputFields({
                                ...inputFields,
                                data: updatedInputsData,
                              });
                            }}
                          >
                            <option value={"textfield"}>Text Field</option>
                            <option value={"numberfield"}>Number Field</option>
                            <option value={"emailfield"}>Email Field</option>
                            <option value={"textarea"}>Large Text Field</option>
                          </select>
                        </div>
                      );
                    })}

                  <div className="d-flex justify-content-start mt-3 gap-3">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() =>
                        setInputFields({
                          ...inputFields,
                          data: [
                            ...inputFields.data,
                            {
                              id: generateUniqueId(),
                              type: "input",
                              fieldType: "textfield",
                              label: "",
                            },
                          ],
                        })
                      }
                    >
                      Add Input Field
                    </button>
                    {!editingSection ? (
                      <button
                        className="btn btn-sm btn-success"
                        onClick={() => {
                          let updatedTaxForm = { ...taxForm };

                          if (
                            !Array.isArray(
                              updatedTaxForm[activeSectionIndex][
                                activeSubSectionIndex
                              ]
                            )
                          ) {
                            updatedTaxForm[activeSectionIndex][
                              activeSubSectionIndex
                            ] = [];
                          }
                          updatedTaxForm[activeSectionIndex][
                            activeSubSectionIndex
                          ] = [
                            ...updatedTaxForm[activeSectionIndex][
                              activeSubSectionIndex
                            ],
                            inputFields,
                          ];
                          setTaxForm(updatedTaxForm);
                          setInputFields({
                            id: generateUniqueId(),
                            data: [],
                          });
                          setIsSelectingFieldType(false);
                        }}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          const newData = inputFields?.data;
                          let updatedTaxForm = JSON.parse(
                            JSON.stringify(taxForm)
                          );
                          let sections =
                            updatedTaxForm[activeSectionIndex][
                              activeSubSectionIndex
                            ];
                          let updatedSection = sections?.find(
                            (sec) => sec?.id === editingSection
                          );
                          if (updatedSection) {
                            updatedSection.data = [
                              ...(updatedSection?.data || []),
                              ...newData,
                            ];
                            updatedTaxForm[activeSectionIndex][
                              activeSubSectionIndex
                            ] = updatedTaxForm[activeSectionIndex][
                              activeSubSectionIndex
                            ]?.map((sec) => {
                              if (sec?.id === editingSection) {
                                return {
                                  ...sec,
                                  data: [...sec?.data, updatedSection?.data],
                                };
                              }
                              return sec;
                            });
                            setTaxForm(updatedTaxForm);
                          }
                          setEditingSection(null);
                          setInputFields({
                            id: generateUniqueId(),
                            data: [],
                          });
                          setIsSelectingFieldType(false);
                        }}
                        className="btn btn-sm btn-success"
                      >
                        Save Edited
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewForm;
