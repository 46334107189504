import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BASEURL, validate, nameRexExp, emailRegExp, useAutoReplyHook } from '../../utilites';
import Input from './fields/Input';
import Checkbox from './fields/Checkbox';
import Button from './fields/Button';
import Select from './fields/Select';
import { useDispatch, useSelector } from 'react-redux';

export default function AddLeadForm({
	professionals,
	services,
	userRoles,
	proId,
	reloadLeads,
	setAlert,
}) {
	const dispatch = useDispatch();
	const handleAutoReply = useAutoReplyHook(dispatch);
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [zipcode, setZipcode] = useState('');
	const [options, setOptions] = useState([]);
	const [pro, setPro] = useState();
	const [{ personalConnections }, { LoginData }] = useSelector((state) => [
		state.firebase,
		state.user,
	]);
	const [errors, setErrors] = useState({
		firstname: false,
		lastname: false,
		email: false,
		phone: false,
		zipcode: false,
		options: false,
		pro: false,
		general: '',
	});

	const button = useRef();

	useEffect(() => {
		if (proId) {
			setPro(proId.toString());
		}
	}, [proId]);

	// function to handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();
		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });
		axios
			.post(`${BASEURL}/api/leads/create`, {
				firstname,
				lastname,
				email,
				phone: phone.split(/[ ()-]/).join(''),
				zipcode,
				options,
				pro,
			})
			.then((data) => {
				console.log('data?.data?.user?.uuid', data?.data?.user?.uuid);
				if (data?.data?.user?.uuid) {
					handleAutoMessaging({ user: data.data.user });
				}
				button.current.disabled = false;
				reloadLeads();
				// Close Modal
				document.querySelector('.addLeadModalClose').click();
				setAlert('Lead has been added successfully!');
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};

	const handleAutoMessaging = ({ user }) => {
		handleAutoReply({
			personalConnections,
			LoginData,
			receiver: user,
			type: 'simple',
			encodedString: `<p>Hi ${user?.firstname + ' ' + user?.lastname} <br /><br />
	 				  Thanks for signing up to Unclekam for your tax preparation interest. Your Tax professional is going to be with you very shortly. In the meantime, please go ahead and complete the questionnaires in the Todo List on your Dashboard. This will help your assigned Tax Professional to be able to serve you better in understanding your Tax and financial needs. Should you have any questions, please don't hesitate to contact support at any time. Thanks, and we look forward to serving you better. 
	 				  <br /><br />Thanks
	 				  <br /><br />
	 				  Support Team, Unclekam 
	 				  </p> 
					  `,
		});
	};

	return (
		<form className="leadsform needs-validation" onSubmit={handleSubmit}>
			<div className="row">
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						value={firstname}
						name="firstname"
						placeholder="First Name"
						fn={setFirstname}
						onLeave={validate('firstname', firstname, nameRexExp, errors, setErrors)}
						hasValidationError={errors.firstname}
						showLabel={false}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						value={lastname}
						name="lastname"
						placeholder="Last Name"
						fn={setLastname}
						onLeave={validate('lastname', lastname, nameRexExp, errors, setErrors)}
						hasValidationError={errors.lastname}
						showLabel={false}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6 mb-3">
					<Input
						type="email"
						value={email}
						name="email"
						placeholder="Email Address"
						fn={setEmail}
						onLeave={validate('email', email, emailRegExp, errors, setErrors)}
						hasValidationError={errors.email}
						showLabel={false}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						value={phone}
						name="phone"
						placeholder="Phone Number"
						fn={setPhone}
						onLeave={() => {
							if (!errors.phone && phone.length > 1 && phone.length < 14) {
								setErrors({ ...errors, phone: true });
							} else if (errors.phone) {
								setErrors({ ...errors, phone: false });
							}
						}}
						hasValidationError={errors.phone}
						mask="(999) 999-9999"
						showLabel={false}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						value={zipcode}
						name="zipcode"
						placeholder="ie. 93882"
						label="Zip Code"
						fn={setZipcode}
						onLeave={() => {
							if (
								!errors.zipcode &&
								zipcode.length > 1 &&
								(zipcode.length < 5 || zipcode.length > 9)
							) {
								setErrors({ ...errors, zipcode: true });
							} else if (errors.zipcode) {
								setErrors({ ...errors, zipcode: false });
							}
						}}
						hasValidationError={errors.zipcode}
						mask="99999999"
						showLabel={false}
					/>
				</div>
				{(userRoles.isAdmin || userRoles.isAccountManager) && professionals.length > 0 && (
					<div className="col-md-6 mb-3">
						<Select value={pro} name="taxpro" placeholder="Tax Professional" fn={setPro}>
							<option value="">Select Tax Professional</option>
							{professionals?.map((professional) => (
								<option
									key={professional.id}
									value={professional.userpro.id}
								>{`${professional.firstname} ${professional.lastname}`}</option>
							))}
						</Select>
					</div>
				)}
			</div>
			{services && (
				<div className="clearfix">
					<div className="clearfix mt-3 mb-4">
						<h5 className="text-center"> What services are you interested in?</h5>
					</div>
					<div className="row mt-3">
						{services?.map((service) => (
							<div key={service.id} className="col-md-4 mb-3">
								<Checkbox
									name="options"
									state={options}
									value={service.id}
									label={service.name}
									fn={setOptions}
									id={`option-${service.id}`}
									required={false}
								/>
							</div>
						))}
					</div>
				</div>
			)}
			{LoginData.isTaxPro && LoginData?.userpro?.status_id !== 2 && (
				<div className="text-danger text-center">
					You can not add a new lead at this time because your account is paused. Please activate
					your account to continue
				</div>
			)}
			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mb-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 ? ' disabled' : ''
				}`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-3"
				buttonText="Add Lead"
			/>
		</form>
	);
}
