/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';

const International = () => {
  
    const dispatch=useDispatch()
	const [{ taxPayerForm, taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 29 });

    const [netProfit, setNetProfit] = useState();
    const [grossRevenue, setGrossRevenue] = useState();
  
    useEffect(() => {
    	if (taxPayerForm?.data?.International) {
    		if (Object.keys(taxPayerForm?.data?.International).length === 0) return;
			const {
                netProfit,
                grossRevenue
			} = taxPayerForm?.data?.International;
            setNetProfit(netProfit)
            setGrossRevenue(grossRevenue)
    	}
    }, [taxPayerForm?.data?.International]);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        let FormObj = {
            netProfit,
            grossRevenue
		};
        let formData = taxPayerForm;
        taxPayerForm.data.International = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));
        uploadFormData(formData);
    };

    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 29;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="projectedDevelopmentCost"
                        value={netProfit}
                        placeholder="What is the entity's expected net profit or loss in 2021 from export property exclusively?"
                        fn={setNetProfit}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="estimatedCreditPercentages"
                        value={grossRevenue}
                        placeholder="What is the entity's expected gross revenue in 2021 from export property exclusively?"
                        fn={setGrossRevenue}
                        mask={"999999999"}
                    />
                </div>
            </div>
            {/* <div
                className="w-100 mt-2"
            >
                <small className="col-md-8 form-check-label text-start" htmlFor={`checkbox`}>
				Did the business participate in any research and development (R&D) activities or perform an R&D tax credit study?
                </small>
                <div className="col-md-4">
                    <input
                        onChange={() => setBusinessDepricate(true)}
                        checked={businessDepricate === true}
                        className="form-check-input"
                        type="radio"
                        id={`checkbox-div4`}
                        name={`checkbox-div4`}
                    />
                    <label htmlFor={`checkbox-div4`} role="button" className="px-3 m-0 small">
                        Yes
                    </label>
                    <input
                        onChange={() => setBusinessDepricate(false)}
                        checked={businessDepricate === false}
                        className="form-check-input"
                        type="radio"
                        id={`checkbox-div5`}
                        name={`checkbox-div5`}
                    />
                    <label htmlFor={`checkbox-div5`} role="button" className="px-3 m-0 small">
                        No
                    </label>
                </div>
            </div> */}
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button  mt-4'}
                        type="button"
                        callback={()=>goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default International;