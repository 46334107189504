const Alert = ({ notice = '', message, type = 'success', clickHandler,className }) => {
	return (
		<div className={`alert alert-${type} alert-dismissible fade show ${className && className}`} role="alert">
			{notice && (<strong>{notice} </strong>)}
			{Object.keys(message)?.length!==0 && message ? message:"Internel Server Error."}
			<button
				type="button"
				className="btn-close"
				aria-label="Close"
				onClick={clickHandler}
			></button>
		</div>
	);
};

export default Alert;
