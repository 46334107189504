/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';

const Depreciation = () => {
    const {depreciationRadioOptions}= GLOBAL_VARIABLES

    const dispatch=useDispatch()
	const [{ taxPayerForm, taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 25 });
    
    // for Current component States
    const [purchaseAmount, setPurchaseAmount] = useState();
    const [approximateValue, setApproximateValue] = useState();
    const [segregationStudy, setSegregationStudy] = useState();
	const [howProvideReport, setHowProvideReport] = useState();
	const [specificQuestions, setSpecificQuestions] = useState([]);
    const [expectedDepriciation, setExpectedDepriciation] = useState();
    const [qualifiedInvestmentProperty, setQualifiedInvestmentProperty] = useState();
    const [qualifiedInvestmentPropertyPrior, setQualifiedInvestmentPropertyPrior] = useState();
    const [qualifiedInvestmentPropertyTwenty, setQualifiedInvestmentPropertyTwenty] = useState();

    const [errors, setErrors] = useState({}); // Track errors for form fields

    const multipleChoice = [] ;

    const handleQuestions = (index, bool) => {
        const localSpecificQuestions = specificQuestions.slice();
        localSpecificQuestions[index].bool = bool;
        setSpecificQuestions(localSpecificQuestions);
    };

    useEffect(() => {
		setSpecificQuestions(JSON.parse(JSON.stringify(depreciationRadioOptions)));
	}, []);

    useEffect(() => {
    	if (taxPayerForm?.data?.Depreciation) {
    		if (Object.keys(taxPayerForm?.data?.Depreciation).length === 0) return;
			const {
                purchaseAmount,
                approximateValue,
                segregationStudy,
                howProvideReport,
                specificQuestions,
                expectedDepriciation,
                qualifiedInvestmentProperty,
                qualifiedInvestmentPropertyPrior,
                qualifiedInvestmentPropertyTwenty
			} = taxPayerForm?.data?.Depreciation;
            setPurchaseAmount(purchaseAmount)
            setApproximateValue(approximateValue)
            setSegregationStudy(segregationStudy)
            setHowProvideReport(howProvideReport)
            setSpecificQuestions(specificQuestions)
            setExpectedDepriciation(expectedDepriciation)
            setQualifiedInvestmentProperty(qualifiedInvestmentProperty)
            setQualifiedInvestmentPropertyPrior(qualifiedInvestmentPropertyPrior)
            setQualifiedInvestmentPropertyTwenty(qualifiedInvestmentPropertyTwenty)
    	}
    }, [taxPayerForm?.data?.Depreciation]);

    const check_Validity = () => {
        setErrors({});
        const newErrors = {};
    
        // Add more validation rules as needed for other fields
        if (
            specificQuestions
                ?.flatMap((data) => {
                    return data?.bool;
                })
                .includes(null)
        ) {
            newErrors.AnswerOtherQuestions = 'Please Select Required Option';
        }
    
        setErrors(newErrors);
    
        let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
        // Check if there are any errors
        return ConditionCheck;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = check_Validity();
        if (errors) return;

        let FormObj = {
            purchaseAmount,
                approximateValue,
                segregationStudy,
                howProvideReport,
                specificQuestions,
                expectedDepriciation,
                qualifiedInvestmentProperty,
                qualifiedInvestmentPropertyPrior,
                qualifiedInvestmentPropertyTwenty
		};
        let formData = taxPayerForm;
        taxPayerForm.data.Depreciation = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));
        uploadFormData(formData);
    };

    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 25;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="approximateValue"
                        value={approximateValue}
                        placeholder="What is the approximate value of all real property owned or expected to purchase since the last tax return?"
                        fn={setApproximateValue}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="purchaseAmount"
                        value={purchaseAmount}
                        placeholder="What was the qualified investment property purchase amount in 2020?"
                        fn={setPurchaseAmount}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="qualifiedInvestmentProperty"
                        value={qualifiedInvestmentProperty}
                        placeholder={`What was the qualified investment property purchase amount in ${new Date().getFullYear()}?`}
                        fn={setQualifiedInvestmentProperty}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="qualifiedInvestmentProperty"
                        value={expectedDepriciation}
                        placeholder="What is the expected depreciation (without a cost segregation study)?"
                        fn={setExpectedDepriciation}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="qualifiedInvestmentProperty"
                        value={segregationStudy}
                        placeholder="What is the expected depreciation after a cost segregation study?"
                        fn={setSegregationStudy}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="qualifiedInvestmentProperty"
                        value={qualifiedInvestmentPropertyTwenty}
                        placeholder="What depreciation was taken on the qualified investment property in 2020?"
                        fn={setQualifiedInvestmentPropertyTwenty}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="qualifiedInvestmentProperty"
                        value={qualifiedInvestmentPropertyPrior}
                        placeholder={`What depreciation was taken on the qualified investment property in ${new Date().getFullYear()}?`}
                        fn={setQualifiedInvestmentPropertyPrior}
                    />
                </div>
            </div>
            <div className=" mb-3 px-3">
					{specificQuestions?.map((data, i) => {
						return (
							<div key={i} className="w-100  align-items-center justify-content-between mt-2">
								<small className="col-md-12 form-check-label text-start" htmlFor={`checkbox-${i}`}>
									{data.question}
									{data.bool === null && (
										<span className="text-danger small-text mx-1">*Required</span>
									)}
								</small>
								<div className="col-md-12">
									<input
										onChange={() => handleQuestions(i, true)}
										checked={data?.bool === true}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 176}`}
										name={`checkbox-${i + 176}`}
									/>
									<label htmlFor={`checkbox-${i + 176}`} role="button" className="px-3 m-0 small">
										Yes
									</label>
									<input
										onChange={() => handleQuestions(i, false)}
										checked={data?.bool === false}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 999}`}
										name={`checkbox-${i + 999}`}
									/>
									<label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
										No
									</label>
								</div>
							</div>
						);
					})}
					{JSON.parse(JSON.stringify(multipleChoice))?.map((data, i) => {
						return (
							<div key={i} className="w-100 my-2">
								<small
									className="col-md-6 form-check-label text-start fw-bold mb-1"
									htmlFor={`howProvideReport-${i}`}
								>
									{data.question}
									{!howProvideReport && (
										<span className="text-danger small-text mx-1">*Required</span>
									)}
								</small>
								{data?.options?.map((option, index) => {
									return (
										<div>
											<input
												onChange={() => setHowProvideReport(option)}
												checked={howProvideReport === option}
												className="form-check-input"
												type="radio"
												id={`howProvideReport-${index}`}
												name={`howProvideReport-${index}`}
											/>
											<label className="px-3 small" htmlFor={`howProvideReport-${index}`}>
												{option}
											</label>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
                {Object.keys(errors)
    ?.map((key) => {
        return errors[key];
    })
    ?.map((data, index) => {
        return (
            <>
                <small className="text-primary" key={index}>
                    {data}*
                </small>
                <br />
            </>
        );
    })}
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button  mt-4'}
                        type="button"
                        callback={()=>goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default Depreciation;