import Header from '../components/home/Header';
import FormHeader from '../components/home/FormHeader';
import Features from '../components/home/Features';
import LeadForm from '../components/home/LeadForm';
import Blurbs from '../components/home/Blurbs';
import Stats from '../components/home/Stats';
import Faq from '../components/home/Faq';
import SEO from '../components/SEO';

const Home = () => {
	return (
		<div>
			<SEO title='Home' />
			<Header />
			<FormHeader />
			<LeadForm />
			<Features />
			<Stats />
			<Blurbs />
			<Faq />
		</div>
	);
};

export default Home;
