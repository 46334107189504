import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker'
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { HandleFormData } from '../../../../utilites';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';


const IntangibleTaxLong = () => {

    const { IntangibleTaxDetail_Long, IntangibleTaxDetailStyles_Long, IntangibleTaxDetail_Long_seperateForDate} = GLOBAL_VARIABLES;

    const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 18 });
    const dispatch=useDispatch()
    const [{ taxPayerForm, taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);

    const [specificQuestions, setSpecificQuestions] = useState([]);
    const [specificQuestionsStyles, setSpecificQuestionsStyles] = useState([]);

    const [errors, setErrors] = useState({}); // Track errors for form fields


    useEffect(() => {
        setSpecificQuestions(JSON.parse(JSON.stringify(IntangibleTaxDetail_Long)));
        setSpecificQuestionsStyles(JSON.parse(JSON.stringify(IntangibleTaxDetailStyles_Long)));
    }, [IntangibleTaxDetail_Long, IntangibleTaxDetailStyles_Long, IntangibleTaxDetail_Long_seperateForDate]);

    const handleRadioChange = (index, value) => {
        const updatedQuestions = [...specificQuestions];
        updatedQuestions[index].bool = value;
        setSpecificQuestions(updatedQuestions);
    };

    const handleInputChange = (index, value, data, valIdx) => {
        const filedKey = Object.keys(data).find(key => key !== 'placeholder' && key !== 'styleProp');
        const updatedQuestions = [...specificQuestions];
        updatedQuestions[index].values[valIdx][filedKey] = value;
        setSpecificQuestions(updatedQuestions);
    };

   useEffect(() => {
		if (taxPayerForm?.data?.IntangibleTaxLong) {
			if (Object.keys(taxPayerForm?.data?.IntangibleTaxLong).length === 0) return;
			const {
				specificQuestions,
			} = taxPayerForm?.data?.IntangibleTaxLong;
			setSpecificQuestions(specificQuestions);
		}
	}, [taxPayerForm?.data?.IntangibleTaxLong]);

    const check_Validity = () => {
        setErrors({});
        const newErrors = {};
    
        // Add more validation rules as needed for other fields
        if (
            specificQuestions
                ?.flatMap((data) => {
                    return data?.bool;
                })
                .includes(null)
        ) {
            newErrors.AnswerOtherQuestions = 'Please Select Required Option';
        }
    
        setErrors(newErrors);
    
        let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
        // Check if there are any errors
        return ConditionCheck;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = check_Validity();
        if (errors) return;

        let FormObj = {
			specificQuestions,
		};

		let formData = taxPayerForm;
		taxPayerForm.data.IntangibleTaxLong = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
    };

    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 18;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};
    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            {/* <small className="ml-4 fw-bold">General Information</small>
            <hr className="m-0" /> */}
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                {specificQuestions.map((data, i) => {
                    return (
                    <div key={i} className="col-md-12 mb-3">
                            
                    {i>=0&& <small className="ml-4 fw-bold">
                            {data.question}
                            {data.bool===null && (
                                <span className="text-danger small-text mx-1">*Required</span>
                            )}
                        </small>}
                        
                  {(i>0)&& <div className="col-md-12">
                        <input
                            onChange={() => handleRadioChange(i,true)}
                            checked={specificQuestions[i].bool === true}
                            className="form-check-input"
                            type="radio"
                            id={`checkbox-${i + 176}`}
                            name={`checkbox-${i + 176}`}
                        />
                        <label htmlFor={`checkbox-${i + 176}`} role="button" className="px-3 m-0 small">
                            Yes
                        </label>
                        <input
                            onChange={() => handleRadioChange(i,false)}
                            checked={specificQuestions[i].bool === false}
                            className="form-check-input"
                            type="radio"
                            id={`checkbox-${i + 999}`}
                            name={`checkbox-${i + 999}`}
                        />
                        <label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
                            No
                        </label>
                    </div>}
                    <div className='row'>
                        { ((i ===0 ) || ((i>=1&&i<=8) && specificQuestions[i].bool)) && (
                               data?.values?.map((data, index) => {
                                   return (
                                    (
                                    (i === 3 && index === 1) || 
                                    (i === 7 && (index === 3 || index === 6)) || 
                                    (i === 8 && (index === 2 || index === 4)))?
                                       <div key={index} className='my-1 col-lg-6 col-md-6 mt-3 col-sm-12'>
                                        <small>{specificQuestionsStyles?.[i]?.valData[index]?.placeholder}</small>
                                  <DatePicker
                                      className="form-select"
                                      style={{ width: 100 }}
                                      wrapperClassName="d-block"
                                      selected={new Date(specificQuestions[i].values[index][Object.keys(data).find(key => key !== 'placeholder' && key !== 'styleProp')])}
                                      onChange={(date) => handleInputChange(i, date, data, index)}
                                      dateFormat="MMMM d, yyyy"
                                      placeholderText={specificQuestionsStyles?.[i]?.valData[index]?.placeholder}
                                  />
                              </div>
                                      :( <div className={`my-1 ${specificQuestionsStyles?.[i]?.valData[index]?.styleProp}`}>
                                           <Input
                                           mask={specificQuestionsStyles?.[i]?.valData[index]?.mask}
                                               type="text"
                                               value={specificQuestions[i].values[index][Object.keys(data).find(key => key !== 'placeholder' && key !== 'styleProp')]}
                                               placeholder={specificQuestionsStyles?.[i]?.valData[index]?.placeholder}
                                               fn={(value) => handleInputChange(i, value, data, index)}
                                           />
                                       </div>))
                               })
                            )} 
                           </div>
                       
                    </div>
                    )
                })} 
            </div>
            {Object.keys(errors)
    ?.map((key) => {
        return errors[key];
    })
    ?.map((data, index) => {
        return (
            <>
                <small className="text-primary" key={index}>
                    {data}*
                </small>
                <br />
            </>
        );
    })}
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="button"
                        callback={()=>goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default IntangibleTaxLong;