import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SweetAlert2 from 'react-sweetalert2';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import allActions from '../../../Store/action';
import moment from 'moment';
import Header from '../../dashboard/components/Header';
import Modal from '../../../components/Dashboard/Modal';
import CustomerQuestionnaires from '../../dashboard/leads/components/CustomerQuestionnaires';
import CustomerMessageBoard from '../../dashboard/leads/components/CustomerMessageBoard';
import CustomerDocuments from '../leads/components/CustomerDocuments';
import CustomerInvoices from '../../dashboard/leads/components/CustomerInvoices';
import Preloader from '../components/Preloader';
import Footer from '../components/Footer';
import JobRequestForm from '../../../components/forms/JobRequestForm';
import JobStatusUpdateForm from '../../../components/forms/JobStatusUpdateForm';
import Offcanvas from '../../../components/Dashboard/Offcanvas';
import UserAvatar from '../../../components/UserAvatar';

import {
	BASEURL,
	getJobStatus,
	getJobStatusClass,
	getLeadStatus,
	getLeadStatusClass,
	getTaxproStatus,
	getUserVerifyStatus,
	getUserVerifyStatusClass,
	moneyFormatter,
	phoneNumberFormatter,
} from '../../../utilites';

const LeadDetails = () => {
	const [{ LoginData }, { professionals }] = useSelector((state) => [state.user, state.dashboard]);
	const [lead, setLead] = useState(null);
	const [selectedJob, setSelectedJob] = useState(null);
	const [swalProps, setSwalProps] = useState({});

	const { uuid } = useParams();

	const dispatch = useDispatch();

	useEffect(() => {
		if (LoginData.isAdmin || LoginData.isAccountManager) {
			dispatch(allActions.dashboard.getTaxProfessionals());
		}
	}, [dispatch, LoginData.isAdmin, LoginData.isAccountManager]);

	const loadLeadByUUID = useCallback((UUID) => {
		axios
			.get(`${BASEURL}/api/leads/${UUID}`)
			.then((res) => {
				console.log('lead data>>>>', res.data);
				setLead(res.data);
			})
			.catch((err) => {
				console.log('error>>>>', err);
			});
	}, []);

	useEffect(() => {
		loadLeadByUUID(uuid);
	}, [uuid, loadLeadByUUID]);

	const showAlertOnTagClick = (tag_slug, tag_description) => {
		setSwalProps((prev) => ({
			...prev,
			[tag_slug]: {
				show: true,
				showDenyButton: false,
				text: tag_description || '',
				didClose: () => setSwalProps((prev) => ({ ...prev, [tag_slug]: { show: false } })),
			},
		}));
	};

	if (lead) {
		return (
			<div className="clearfix">
				<Header name="Leads" enableBreadcrumb={true} />

				<div className="container page mb-5">
					<div className="clearfix pt-4 mb-4">
						<h5 className="fw-bold">{`${lead.user.firstname} ${lead.user.lastname}`}</h5>
						{lead?.tags && lead?.tags.length !== 0 ? (
							<span
								className="badge bg-primary ms-1 cursor-pointer"
								onClick={() => {
									showAlertOnTagClick(lead?.tags[0].slug, lead?.tags[0].description);
								}}
							>
								{lead?.tags[0].name}
								<SweetAlert2 {...swalProps[lead?.tags[0].slug]} />
							</span>
						) : null}
					</div>
					<div className="clearfix">
						<ul className="nav nav-tabs" id="myTab" role="tablist">
							<li className="nav-item border-light" role="presentation">
								<button
									className={`nav-link ${lead.jobs && lead.jobs.length > 0 ? 'active' : ''}`}
									id="jobs-tab"
									data-bs-toggle="tab"
									data-bs-target="#jobs-tab-pane"
									type="button"
									role="tab"
									aria-controls="jobs-tab-pane"
									aria-selected="true"
								>
									Jobs
								</button>
							</li>
							{!LoginData.isTaxPro && (
								<li className={`nav-item`} role="presentation">
									<button
										className={`nav-link ${lead.jobs && lead.jobs.length === 0 ? 'active' : ''}`}
										id="details-tab"
										data-bs-toggle="tab"
										data-bs-target="#details-tab-pane"
										type="button"
										role="tab"
										aria-controls="details-tab-pane"
										aria-selected="true"
									>
										Details
									</button>
								</li>
							)}
							<li className="nav-item" role="presentation">
								<button
									className={`nav-link ${LoginData.isTaxPro && lead.status === 3 && 'disabled'}`}
									id="questionaires-tab"
									data-bs-toggle="tab"
									data-bs-target="#questionaires-tab-pane"
									type="button"
									role="tab"
									aria-controls="questionaires-tab-pane"
									aria-selected="false"
								>
									Questionnaire
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className={`nav-link ${LoginData.isTaxPro && lead.status === 3 && 'disabled'}`}
									id="messages-tab"
									data-bs-toggle="tab"
									data-bs-target="#messages-tab-pane"
									type="button"
									role="tab"
									aria-controls="messages-tab-pane"
									aria-selected="false"
								>
									Messages
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className={`nav-link ${LoginData.isTaxPro && lead.status === 3 && 'disabled'}`}
									id="documents-tab"
									data-bs-toggle="tab"
									data-bs-target="#documents-tab-pane"
									type="button"
									role="tab"
									aria-controls="documents-tab-pane"
									aria-selected="false"
								>
									Documents
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className={`nav-link ${LoginData.isTaxPro && lead.status === 3 && 'disabled'}`}
									id="invoices-tab"
									data-bs-toggle="tab"
									data-bs-target="#invoices-tab-pane"
									type="button"
									role="tab"
									aria-controls="invoices-tab-pane"
									aria-selected="false"
								>
									Invoices
								</button>
							</li>
						</ul>
						<div className="tab-content" id="myTabContent">
							<div
								className={`tab-pane fade ${
									LoginData.isTaxPro && lead.jobs && lead.jobs.length === 0 && 'show active'
								} ${lead.jobs && lead.jobs.length > 0 ? 'show active' : ''}`}
								id="jobs-tab-pane"
								role="tabpanel"
								aria-labelledby="jobs-tab"
								tabIndex="0"
							>
								{lead.jobs.length > 0 ? (
									<>
										{lead.jobs?.map((job) => (
											<div key={job.id} className="clearfix border border-light rounded-3 p-4 mt-4">
												<div className="clearfix mb-3">
													<Link
														to={`/dashboard/jobs/${job.uuid}`}
														className="d-inline-block text-primary ff-gothic text-decoration-none fs-2"
													>
														{job?.service?.name || job?.others}
													</Link>
													<div className="dropdown-center float-end">
														<button
															type="button"
															className="btn btn-sm text-primary rounded-circle"
															data-bs-toggle="dropdown"
															aria-expanded="false"
														>
															<BsThreeDotsVertical className="" />
														</button>
														<ul className="dropdown-menu border-light">
															<li>
																<Link
																	className={`dropdown-item ${job.status_id > 1 ? 'disabled' : ''}`}
																	to={`/dashboard/jobs/edit/${job.uuid}`}
																>
																	Edit
																</Link>
															</li>
															{LoginData.isTaxPro && job.status_id > 0 && (
																<li>
																	<Link
																		to="#"
																		className={`dropdown-item text-danger`}
																		data-bs-toggle="modal"
																		data-bs-target="#jobCanelRequestForm"
																	>
																		Request to Cancel
																	</Link>
																</li>
															)}
														</ul>
													</div>
												</div>
												<div className="clearfix">
													<p>
														Status:{' '}
														<span
															className={`ms-3 text-${getJobStatusClass(job.status_id)}${
																!LoginData.isAdmin && !LoginData.isAccountManager
																	? ' pe-none'
																	: ' cursor-pointer'
															}`}
															data-bs-toggle="modal"
															data-bs-target="#updateJobStatusModal"
															onClick={() => {
																setSelectedJob(job);
															}}
														>
															{getJobStatus(job.status_id)}
														</span>
													</p>
													<p>
														Completed By:{' '}
														<span className="ms-3">
															{new Date(job.completed_at).toLocaleString('en-US', {
																month: 'long',
																day: 'numeric',
																year: 'numeric',
															})}
														</span>
													</p>
													<p>
														Total Cost:{' '}
														<span className="ms-3">{`${moneyFormatter(job.cost)}`}</span>
													</p>
													<Link to={`/dashboard/jobs/${job.uuid}`} className="btn btn-primary">
														View Job
													</Link>
												</div>
											</div>
										))}
									</>
								) : (
									<div className="clearfix border border-light rounded-3 p-4 mt-4 text-center">
										<h4 className="text-dark text-opacity-25">
											The lead has no jobs associated with it.
										</h4>
									</div>
								)}
							</div>
							{!LoginData.isTaxPro && (
								<div
									className={`tab-pane fade ${
										lead.jobs && lead.jobs.length === 0 ? 'show active' : ''
									}`}
									id="details-tab-pane"
									role="tabpanel"
									aria-labelledby="details-tab"
									tabIndex="0"
								>
									<div className="row justify-content-center g-4">
										<div className="col-md-7">
											<div className="clearfix border border-light rounded-3 p-4 mt-4">
												<div className="card-header d-flex justify-content-between bg-white border-0">
													<h1 className="text-primary mb-3">Contact</h1>
													<div className="card-actions">
														<div className="dropdown-center">
															<button
																type="button"
																className="btn btn-sm text-primary rounded-circle border-0 disabled"
																data-bs-toggle="dropdown"
																aria-expanded="false"
															>
																<BsThreeDotsVertical className="" />
															</button>
															<ul className="dropdown-menu border-light">
																<li>
																	<Link className={`dropdown-item`} to={`#`}>
																		Edit
																	</Link>
																</li>
															</ul>
														</div>
													</div>
												</div>
												<div className="row mt-4">
													<div className="col-md-7">
														<p>
															<strong>First Name:</strong>{' '}
															{lead.user.firstname + ' ' + lead.user.lastname}
														</p>
														<p>
															<strong>Email:</strong>{' '}
															<Link
																to={`mailTo: ${lead.user.email}`}
																className="text-decoration-none text-dark"
															>
																{lead.user.email}
															</Link>
														</p>
														<p>
															<strong>Phone:</strong>{' '}
															<Link
																to="#"
																className="text-decoration-none text-dark"
																data-bs-toggle="modal"
																data-bs-target="#callLeadPhoneModal"
															>
																{phoneNumberFormatter(lead.phone)}
															</Link>
														</p>
														<p>
															<strong>Address:</strong> {lead.street ?? 'N/A'} {lead.street2 ?? ''}
														</p>
														<p>
															<strong>City:</strong> {lead.city}
														</p>
														<p>
															<strong>State:</strong> {lead.state}
														</p>
														<p>
															<strong>Zip:</strong> {lead.zipcode}
														</p>
														<p>
															<strong>Assigned To:</strong>{' '}
															<Link
																to="#"
																className="text-dark"
																data-bs-toggle="offcanvas"
																data-bs-target="#openTaxProDetailsPanel"
																aria-controls="openTaxProDetailsPanel"
															>
																{lead?.userpro?.user?.firstname +
																	' ' +
																	lead?.userpro?.user?.lastname ?? 'N/A'}
															</Link>
														</p>
														<p>
															<strong>Date Assigned:</strong>{' '}
															{moment(lead.created_at).format('MMMM Do YYYY, h:mm a')}
														</p>
													</div>
													<div className="col-md-5">
														<p>
															<strong>Account Verify:</strong>{' '}
															<span
																className={`badge bg-${getUserVerifyStatusClass(
																	lead.user.isVerify
																)}`}
															>
																{getUserVerifyStatus(lead.user.isVerify)}
															</span>
														</p>
														<p>
															<strong>Lead Status:</strong>{' '}
															<span className={`badge bg-${getLeadStatusClass(lead.status)}`}>
																{getLeadStatus(lead.status)}
															</span>
														</p>
														<p>
															<strong>IP Address:</strong> {lead.id_address ?? 'N/A'}
														</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-5">
											<div className="clearfix border border-light rounded-3 p-4 mt-4">
												<div className="clearfix">
													<p>
														<strong>Source:</strong> {lead.utm_data?.utm_source ?? 'N/A'}
													</p>
													<p>
														<strong>Campaign:</strong> {lead.utm_data?.utm_campaign ?? 'N/A'}
													</p>
													<p>
														<strong>Medium:</strong> {lead.utm_data?.utml_medium ?? 'N/A'}
													</p>
												</div>
											</div>
											<div className="clearfix border border-light rounded-3 p-4 mt-4">
												<div className="clearfix">
													<p>
														<strong>ISP Data</strong>
													</p>
													<p>
														<strong>City:</strong> {lead.isp_data?.city ?? 'N/A'}
													</p>
													<p>
														<strong>State:</strong> {lead.isp_data?.regionName ?? 'N/A'}
													</p>
													<p>
														<strong>Zip:</strong> {lead.isp_data?.zip ?? 'N/A'}
													</p>
													<p>
														<strong>Timezone:</strong> {lead.isp_data?.timezone ?? 'N/A'}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							<div
								className="tab-pane fade"
								id="questionaires-tab-pane"
								role="tabpanel"
								aria-labelledby="questionaires-tab"
								tabIndex="0"
							>
								<div className="clearfix border border-light rounded-3 p-4 mt-4">
									<h1 className="text-primary">Questionaires</h1>
									<CustomerQuestionnaires data={lead} />
								</div>
							</div>
							<div
								className="tab-pane fade"
								id="messages-tab-pane"
								role="tabpanel"
								aria-labelledby="messages-tab"
								tabIndex="0"
							>
								<div className="clearfix border border-light rounded-3 p-4 mt-4">
									<h1 className="text-primary">Messages</h1>
									<CustomerMessageBoard
										uuid={lead?.user?.uuid}
										group={
											(LoginData?.roles[0]?.name === 'admin' ||
												LoginData?.roles[0]?.name === 'accountmanager') && [
												lead?.userpro?.user?.uuid,
												lead?.user?.uuid,
											]
										}
										editable={
											LoginData?.roles[0]?.name === 'admin' ||
											LoginData?.roles[0]?.name === 'accountmanager'
												? false
												: true
										}
									/>
								</div>
							</div>
							<div
								className="tab-pane fade"
								id="documents-tab-pane"
								role="tabpanel"
								aria-labelledby="documents-tab"
								tabIndex="0"
							>
								<div className="clearfix border border-light rounded-3 p-4 mt-4">
									<h1 className="text-primary">Documents</h1>
									<CustomerDocuments
										pro_id={LoginData.userpro?.id || lead.userpro?.id}
										customer_id={lead.user.id}
										reloadData={() => loadLeadByUUID(uuid)}
										data={lead}
									/>
								</div>
							</div>
							<div
								className="tab-pane fade"
								id="invoices-tab-pane"
								role="tabpanel"
								aria-labelledby="invoices-tab"
								tabIndex="0"
							>
								<div className="clearfix border border-light rounded-3 p-4 mt-4">
									<h1 className="text-primary">Invoices</h1>
									<CustomerInvoices activeLead={lead} uuid={uuid} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal
					title="Job Cancel Request"
					bodyClass="p-4 text-center text-dark text-opacity-50"
					trigger="jobCanelRequestForm"
					showBtn={false}
					btnClass="jobCanelRequestModalClose"
				>
					<JobRequestForm />
				</Modal>
				{LoginData.isAdmin || LoginData.isAccountManager ? (
					<>
						<Modal
							title="Update job status"
							bodyClass="p-4 text-center text-dark text-opacity-50"
							btnText="Update"
							trigger="updateJobStatusModal"
							btnClass="updateJobStatusModalClose"
							showBtn={false}
						>
							{selectedJob && professionals && professionals.length > 0 ? (
								<JobStatusUpdateForm
									uuid={selectedJob?.uuid}
									currentStatus={selectedJob?.status_id}
									currentProfessional={selectedJob?.userpro?.id}
									professionals={professionals}
									reload={() => loadLeadByUUID(uuid)}
								/>
							) : null}
						</Modal>
						<Modal
							title="Call Lead Phone"
							bodyClass="p-4 text-center text-dark text-opacity-50"
							trigger="callLeadPhoneModal"
							btnClass="callLeadPhoneModalClose"
							btnText="Call"
							showBtn={false}
						>
							<p>Coming Soon</p>
						</Modal>
						<Offcanvas
							header="Tax Professional"
							position="end"
							trigger="openTaxProDetailsPanel"
							style={{ width: 500 }}
						>
							<div className="clearfix p-4">
								<div className="text-center bg-grey-50 p-4 mb-4">
									<UserAvatar
										avatar={lead?.userpro.user?.avatar}
										alt={`${lead?.userpro?.user?.firstname} ${lead?.userpro?.user?.lastname}`}
										className="img-fluid object-fit-cover rounded-circle"
										style={{ width: 70, height: 70 }}
									/>
									<p className="card-text text-black fw-bold mt-2 mb-1">
										{`${lead?.userpro?.user?.firstname} ${lead?.userpro?.user?.lastname}`}, CPA
									</p>
									<p className="card-text text-black mb-1">Tax Professional</p>
									<span className="badge bg-primary">
										{getTaxproStatus(lead?.userpro.status_id)}
									</span>
								</div>
								{lead?.userpro.experience && lead?.userpro.experience !== '' && (
									<div className="clearfix">
										<p>{lead?.userpro.experience}</p>
									</div>
								)}
								<div className="clearfix mb-3">
									<strong>{lead?.userpro.businessName}</strong>
									<address>
										{lead?.userpro.street} {lead?.userpro.street2 && <br />} {lead?.userpro.street2}
										<br />
										{lead?.userpro.city}, {lead?.userpro.state} {lead?.userpro.zipcode}
										<br />
										{lead?.userpro.phone}
									</address>
								</div>
								{lead?.userpro.expertise &&
									typeof lead?.userpro.expertise === 'object' &&
									lead?.userpro.expertise?.length !== 0 && (
										<div className="clearfix mb-4">
											<p>
												<strong>
													Expertise:
													<br />
												</strong>
												{lead?.userpro.expertise?.map((exp) => (
													<span className="badge bg-grey-100 text-grey-900 me-2" key={exp.value}>
														{exp.label}
													</span>
												))}
											</p>
										</div>
									)}
								<div className="clearfix mt-5 text-center">
									<Link
										to={`/dashboard/users/${lead?.userpro.user?.uuid}`}
										className="btn btn-primary px-4 rounded-3"
										target="_blank"
									>
										View Profile
									</Link>
								</div>
							</div>
						</Offcanvas>
					</>
				) : null}
				<Footer />
			</div>
		);
	}

	return <Preloader />;
};

export default LeadDetails;
