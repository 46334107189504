import { BASEURL } from '../../utilites';
import axios from 'axios';
import { MEMBERSHIP_ACTION_TYPE } from './Type';

//  Function To POST Membership
const initialRegisterMembership = () => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/membership/register-usermembership`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: MEMBERSHIP_ACTION_TYPE.registerMembership,
					payload: {
						message: 'success',
						status: response?.data?.success,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: MEMBERSHIP_ACTION_TYPE.registerMembership,
					payload: {
						message: handleMembershipError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};
const getMembership = (id) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/membership/get-usermembership`, {id})
			.then((response) => {
				console.log("****",response.data?.data);
				// HANDLE RESPONSE
				dispatch({
					type: MEMBERSHIP_ACTION_TYPE.getMembership,
					payload: {
						message: 'success',
						status: response?.data?.success,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: MEMBERSHIP_ACTION_TYPE.getMembership,
					payload: {
						message: handleMembershipError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};
const updateMembership = (membershipData) => {
	return async (dispatch) => {
		axios
			.patch(`${BASEURL}/api/membership/update-usermembership-byAdmin`, membershipData)
			.then((response) => {
				console.log("****",response.data?.data);
				// HANDLE RESPONSE
				dispatch({
					type: MEMBERSHIP_ACTION_TYPE.updateMembership,
					payload: {
						message: 'success',
						status: response?.data?.success,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: MEMBERSHIP_ACTION_TYPE.updateMembership,
					payload: {
						message: handleMembershipError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};

const updateMembershipPlan = (id) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/membership/create-usermembership`, id)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: MEMBERSHIP_ACTION_TYPE.updateMembershipPlan,
					payload: {
						message: 'success',
						status: response?.data?.success,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: MEMBERSHIP_ACTION_TYPE.updateMembershipPlan,
					payload: {
						message: handleMembershipError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};

const allMembershipPlan = () => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/membership/get-allMembershipPlan`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: MEMBERSHIP_ACTION_TYPE.allMembershipPlan,
					payload: {
						message: 'success',
						status: response?.data?.success,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: MEMBERSHIP_ACTION_TYPE.allMembershipPlan,
					payload: {
						message: handleMembershipError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};
// get all membership plan
const getAllMembershipPlanByAdmin = () => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/membership/getAll-usermembership-byAdmin`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: MEMBERSHIP_ACTION_TYPE.getAllMembershipPlanByAdmin,
					payload: {
						message: 'success',
						status: response?.data?.success,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: MEMBERSHIP_ACTION_TYPE.getAllMembershipPlanByAdmin,
					payload: {
						message: handleMembershipError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};

// 
const createMembershipByAdmin = (membership) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/membership/create-usermembership-byAdmin`, membership)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: MEMBERSHIP_ACTION_TYPE.createMembershipByAdmin,
					payload: {
						message: 'success',
						status: response?.data?.success,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: MEMBERSHIP_ACTION_TYPE.createMembershipByAdmin,
					payload: {
						message: handleMembershipError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};
// delete membership
const deleteMembershipPlan = (stripeSubId) => {
	console.log("action####", stripeSubId)
	return async (dispatch) => {
		axios
			.delete(`${BASEURL}/api/membership/delete-membership/${stripeSubId}`)
			.then((response) => {
				console.log("****",response.data?.data);
				// HANDLE RESPONSE
				dispatch({
					type: MEMBERSHIP_ACTION_TYPE.deleteMembershipPlan,
					payload: {
						message: 'success',
						status: response?.data?.success,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: MEMBERSHIP_ACTION_TYPE.deleteMembershipPlan,
					payload: {
						message: handleMembershipError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};
// 
const handleMembershipError = (type, error) => {
	if (type === 'simple') {
		return error.code === 'ERR_NETWORK'
			? error?.message
			: error?.response?.data?.error
				? error?.response?.data?.error
				: 'Internel Server Error!';
	}
};

export const membership = {
	initialRegisterMembership,
	getMembership,
	updateMembership,
	updateMembershipPlan,
	allMembershipPlan,
	createMembershipByAdmin,
	getAllMembershipPlanByAdmin,
	deleteMembershipPlan
};
