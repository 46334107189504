import axios from 'axios';
import { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { BASEURL } from '../../utilites';
import allActions from '../../Store/action';
// Components
import Header from './components/Header';
import Button from '../../components/forms/fields/Button';
import Checkbox from '../../components/forms/fields/Checkbox';
import Footer from './components/Footer';
// Data
import questions from '../../utilites/questionnaireData.json';

export default function Questionnaire() {
	const [{ LoginData }] = useSelector((state) => [state.user]);
	const [lastLead, setLastLead] = useState();
	const [currentQuestion, setCurrentQuestion] = useState(1);
	const [answers, setAnswers] = useState();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let assessmentContent = LoginData.assessment?.content;
	// if (assessmentContent) {
	// 	assessmentContent = JSON.parse(LoginData.assessment?.content);
	// }

	useEffect(() => {
		if (LoginData.isCustomer) {
			axios.get(`${BASEURL}/api/leads/by-customer`).then((res) => setLastLead(res.data.data));
		}
	}, [LoginData.isCustomer, dispatch]);

	useEffect(() => {
		if (!LoginData.isCustomer) {
			navigate('/dashboard/');
		} else {
			if (LoginData?.assessment === null) {
				dispatch(allActions.dashboard.startQuestionnaire(lastLead?.userpro.id));
			} else {
				const content = assessmentContent;
				// const content = LoginData.assessment?.content;
				for (let i = 0; i < questions.length; i++) {
					if (!content[(i + 1).toString()]) {
						setCurrentQuestion(i + 1);
						break;
					}
				}
				setDefaultAnswersForQuestion(currentQuestion);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastLead]);

	const setDefaultAnswersForQuestion = (question_id) => {
		const content = assessmentContent;
		// const content = LoginData.assessment?.content;
		if (content[question_id.toString()]) {
			setAnswers(content[question_id.toString()]);
		} else {
			setAnswers([]);
		}
	};

	const handleBack = () => {
		if (currentQuestion > 1) {
			setCurrentQuestion(currentQuestion - 1);
			setDefaultAnswersForQuestion(currentQuestion - 1);
		}
	};

	const handleSkip = () => {
		if (currentQuestion < questions.length) {
			setCurrentQuestion(currentQuestion + 1);
			setDefaultAnswersForQuestion(currentQuestion + 1);
		}
	};

	const handleNext = () => {
		if (answers && answers.length > 0) {
			dispatch(
				allActions.dashboard.answerQuestion(
					lastLead?.userpro.id,
					LoginData.assessment?.content,
					currentQuestion,
					answers,
					questions.length
				)
			);
			if (currentQuestion === questions.length + 1) {
				// If all questions are not answered, go back to unanswered question
				if (Object.keys(assessmentContent).length < questions.length) {
					// Go back to unanswered question
					for (let i = 0; i < questions.length; i++) {
						if (!assessmentContent[(i + 1).toString()]) {
							setCurrentQuestion(i + 1);
							setDefaultAnswersForQuestion(i + 1);
							break;
						}
					}
				}
			} else if (currentQuestion <= questions.length) {
				setCurrentQuestion(currentQuestion + 1);
				setDefaultAnswersForQuestion(currentQuestion + 1);
			}
		}
	};

	return (
		<div className="clearfix">
			{LoginData.assessment !== null ? (
				<Header
					name="Questionnaire"
					progress={{
						min: 0,
						max: questions.length,
						value: Object.keys(assessmentContent).length || 0,
					}}
				/>
			) : null}
			<div>
				<p className="text-black">
					Please complete this short questionnaire to share important information about your unique
					tax and financial circumstances. Your responses play a vital role in enabling your tax
					expert to offer you the most effective solutions.
				</p>
			</div>
			{currentQuestion ? (
				<div className="card p-4">
					<div className="card-body">
						{currentQuestion === questions.length + 1 ? (
							<Fragment>
								<h5 className="card-title text-black fw-bold" style={{ fontSize: 30 }}>
									Awesome, thanks for completing the assessment. Click below to return to the
									dashboard.
								</h5>
								<Button
									mainClass="btn btn-lg btn-primary"
									wrapperClass="clearfix d-block text-center mt-5"
									buttonText="Return to Dashboard"
									callback={() => navigate('/dashboard/')}
								/>
							</Fragment>
						) : (
							<Fragment>
								<h5 className="card-title text-black fw-bold" style={{ fontSize: 30 }}>
									{currentQuestion}. {questions[currentQuestion - 1]?.question}
								</h5>
								{questions[currentQuestion - 1]?.checkMany === true ? (
									<p className="text-black mb-5">Choose as many that apply.</p>
								) : null}
								{questions[currentQuestion - 1]?.answers?.map((answer) => (
									<Fragment key={answer.id}>
										<Checkbox
											type={questions[currentQuestion - 1].checkMany ? 'checkbox' : 'radio'}
											wrapperClass="mb-3"
											labelClass="text-dark fw-semibold"
											name="answers"
											state={answers}
											value={answer.isField ? 'custom' : answer.id}
											label={answer.answer}
											fn={setAnswers}
											id={`option-${answer.id}`}
											required={true}
										/>
										{answer.isField && answers[0] === 'custom' ? (
											<input
												className="form-control mb-4"
												type="text"
												placeholder="Enter your answer"
												value={answers[1] || ''}
												onChange={(e) => setAnswers(['custom', e.target.value])}
											/>
										) : null}
									</Fragment>
								))}
							</Fragment>
						)}
					</div>
					<div className="card-footer border-0 bg-white d-flex justify-content-between">
						<div>
							<Button
								mainClass={`btn btn-outline-primary d-flex align-items-center${
									currentQuestion > 1 ? '' : ' disabled'
								}`}
								wrapperClass="clearfix d-grid text-center"
								buttonIcon={<IoIosArrowRoundBack />}
								buttonText="Back"
								callback={handleBack}
							/>
						</div>
						<div className="d-flex">
							{currentQuestion < questions.length ? (
								<Button
									mainClass="btn btn-outline-primary"
									wrapperClass="clearfix d-grid text-center"
									buttonText="Skip"
									callback={handleSkip}
								/>
							) : null}
							<Button
								mainClass={`btn btn-primary ms-2${
									answers && answers.length > 0 ? '' : ' disabled'
								}${currentQuestion === questions.length + 1 ? ' d-none' : ''}`}
								wrapperClass="clearfix d-grid text-center"
								buttonText={currentQuestion < questions.length ? 'Next' : 'Finish'}
								callback={handleNext}
							/>
						</div>
					</div>
				</div>
			) : null}
			<Footer />
		</div>
	);
}
