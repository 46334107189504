/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import Input from './fields/Input';
import Button from './fields/Button';
import Message from './fields/Message';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../Store/action';
import DropDown from './fields/DropDown';
import moment from 'moment';
import { GLOBAL_VARIABLES } from '../../utilites/variables';

// Default expertises
const { taxProExpertises } = GLOBAL_VARIABLES;

export default function BusinessInfoForm({ active, setActive }) {
	let dispatch = useDispatch();
	const [
		{ services, stripeConnection, stripeConnectionMessage, stripeAccountStatusData },
		{ LoginData },
	] = useSelector((state) => [state.dashboard, state.user]);
	// Day month year
	const day = moment(LoginData?.userpro.dob).format('DD');
	const month = moment(LoginData?.userpro.dob).format('MM');
	const year = moment(LoginData?.userpro.dob).format('YYYY');

	// States
	const [Llc, setLlc] = useState();
	const [usIdNumber, setusIdNumber] = useState();
	const [einNumber, seteinNumber] = useState();
	const [website, setWebsite] = useState(
		LoginData?.userpro?.website === 'https://tpg.taxreductioncompany.com'
			? ''
			: LoginData?.userpro?.website
	);
	const [message, setMessage] = useState({ show: false });
	const [BusinessName, setBusinessName] = useState(LoginData?.userpro?.businessName);
	const [organization, setOrganization] = useState();
	const [BusinessDetails, setBusinessDetails] = useState(LoginData?.userpro?.experience);
	const PreReservedServices = LoginData?.userpro?.services?.map((v) => v.id);
	const [selectedServices, setSelectedServices] = useState(PreReservedServices);
	const [expertise, setExpertise] = useState(LoginData?.userpro?.expertise || []);

	// Drop Down Options
	const organizationOptions = [
		{ value: 'individual', label: 'Individual' },
		{ value: 'company', label: 'Company' },
	];
	const optionLlc = [
		{ value: 'sole_proprietorship', label: 'Sole proprietorship' },
		{ value: 'single_member_llc', label: 'Single-member LLC' },
		{ value: 'multi_member_llc', label: 'Multi-member LLC' },
		{ value: 'private_partnership', label: 'Private partnership' },
		{ value: 'private_corporation', label: 'Private corporation' },
		{ value: 'other', label: "Other/I'm not sure" },
	];

	useEffect(() => {
		// resetStripeStates();
		if (LoginData?.userpro?.additional !== '' && LoginData?.userpro?.additional !== null) {
			const additionalInformation = LoginData?.userpro?.additional;

			// for new stripe account
			if (additionalInformation?.business_type) {
				// if individual
				if (additionalInformation?.id_number) {
					setusIdNumber(additionalInformation.id_number);
					setOrganization(
						organizationOptions.filter((e) => e.value === additionalInformation.business_type)[0]
					);
					return;
				}

				// else comapny options
				setOrganization(
					organizationOptions.filter((e) => e.value === additionalInformation.business_type)[0]
				);
			}

			if (additionalInformation?.business_structure) {
				setLlc(optionLlc.filter((e) => e.value === additionalInformation.business_structure)[0]);
			}
			if (additionalInformation?.ein) {
				seteinNumber(additionalInformation?.ein);
			}
		}
		// for old stripe account
		else if (stripeAccountStatusData?.data) {
			setOrganization(
				organizationOptions.filter(
					(e) => e.value === stripeAccountStatusData?.data?.business_type
				)[0]
			);
			setLlc(
				optionLlc.filter((e) => e.value === stripeAccountStatusData?.data?.company?.structure)[0]
			);
		}
	}, [LoginData]);

	useEffect(() => {
		if (!stripeConnection) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: stripeConnectionMessage,
			});
		}
		if (stripeConnection === true) {
			dispatch(allActions.global.Loader(false));
			moveToNext();
		}
	}, [stripeConnection, stripeConnectionMessage]);
	// Reset Function to Reset Alert
	const reset_message = () => {
		setMessage({ show: false });
	};

	const resetStripeStates = () => {
		setLlc(undefined);
		seteinNumber(undefined);
		setusIdNumber(undefined);
	};
	/* Uncomment when ready to use
  const resetForm = () => {
    setBusinessName("");
  };*/

	const handleSubmit = (e) => {
		// Function To Reset Connection Stripe
		dispatch(allActions.dashboard.stripeConnectionReset());
		reset_message();
		e.preventDefault();
		if (selectedServices.length === 0) {
			setMessage({
				show: true,
				type: 'Error',
				text: 'Please Select Services Also!',
			});
			return;
		}
		let servicesObj = {
			serviceIds: selectedServices,
			businessName: BusinessName,
			experience: BusinessDetails,
			expertise: expertise,
		};
		dispatch(allActions.dashboard.addService(servicesObj, connectionStripe));
		// resetForm();
	};

	const connectionStripe = () => {
		// if (
		//   LoginData?.userpro?.additional !== "" &&
		//   LoginData?.userpro?.additional !== null
		// ) {
		//   return;
		// }
		let connectAccount = {};
		let personDetails = {};
		// Connect Account Object
		if (organization?.value === 'individual') {
			connectAccount = {
				business_type: 'individual',
				individual: {
					first_name: LoginData?.firstname,
					last_name: LoginData?.lastname,
					email: LoginData?.email,
					phone: LoginData?.userpro?.phone?.replace(/[()\s-]/g, ''),
					dob: {
						day: day,
						month: month,
						year: year,
					},
					address: {
						line1: LoginData?.userpro.street,
						city: LoginData?.userpro.city,
						state: LoginData?.userpro.state,
						postal_code: LoginData?.userpro.zipcode,
					},

					id_number: usIdNumber?.toString()?.replace(/[()\s-]/g, ''),
				},
				company: {
					name: BusinessName,
					tax_id: usIdNumber?.toString()?.replace(/[()\s-]/g, ''),
				},
			};
		}
		if (organization?.value === 'company') {
			// Connect Account Object
			connectAccount = {
				business_type: 'company',
				company: {
					structure: Llc?.value,
					tax_id: einNumber?.replace(/[()\s-]/g, ''),
					address: {
						line1: LoginData?.userpro.street,
						city: LoginData?.userpro.city,
						state: LoginData?.userpro.state,
						postal_code: LoginData?.userpro.zipcode,
					},
					phone: LoginData?.userpro.phone.replace(/[()\s-]/g, ''),
					name: BusinessName,
				},
			};
			// Person Details Object
			personDetails = {
				first_name: LoginData?.firstname,
				last_name: LoginData?.lastname,
				email: LoginData?.email,
				phone: LoginData?.userpro.phone?.replace(/[()\s-]/g, ''),
				dob: {
					day: day,
					month: month,
					year: year,
				},
				address: {
					line1: LoginData?.userpro.street,
					city: LoginData?.userpro.city,
					state: LoginData?.userpro.state,
					postal_code: LoginData?.userpro.zipcode,
				},
				relationship: { owner: true, representative: true, director: true },
				id_number: einNumber?.replace(/[()\s-]/g, ''),
			};
		}

		connectAccount['business_profile'] = {
			mcc: 7276,
			url:
				website !== null && website !== undefined && website !== ''
					? website
					: 'https://tpg.taxreductioncompany.com',
		};
		// Function To Connect Stripe
		dispatch(allActions.global.Loader(true));
		console.log('@555', personDetails, LoginData);
		dispatch(allActions.dashboard.stripeConnection({ connectAccount, personDetails }));
	};

	const handleCheckboxChange = (id) => {
		if (selectedServices.includes(id)) {
			setSelectedServices(selectedServices.filter((serviceId) => serviceId !== id));
		} else {
			setSelectedServices([...selectedServices, id]);
		}
	};
	const moveToNext = () => {
		dispatch(allActions.dashboard.stripeConnectionReset());
		setActive(4);
	};
	const GoBack = () => {
		dispatch(allActions.dashboard.stripeConnectionReset());
		dispatch(
			allActions.user.ReGetUserData(() => {
				setActive(2);
			})
		);
	};
	return (
		<div>
			<form className="BusinessInfoForm needs-validation" onSubmit={handleSubmit}>
				{message.show ? <Message message={message} /> : <></>}

				<div className="row g-3 mb-3">
					<div className="col-md-5">
						<DropDown
							options={organizationOptions}
							value={organization}
							// isDisabled={
							//   LoginData?.userpro?.additional !== "" &&
							//   LoginData?.userpro?.additional !== null
							// }
							setValue={setOrganization}
							resetValues={resetStripeStates}
							label={'Select Organization*'}
							showLabel={false}
						/>
					</div>
					<div className="col-md-7">
						<Input
							type="text"
							value={BusinessName}
							name="Business Name"
							placeholder={organization?.value === 'company' ? 'Business Name' : 'Full Name'}
							fn={setBusinessName}
							showLabel={false}
							// mask={letterMasking}
						/>
					</div>
				</div>
				<div className="row g-3 mb-3">
					<div className="col-md-3">
						<Input
							type="text"
							value={usIdNumber}
							name="SS Number"
							placeholder="SS Number"
							mask="999 99-9999"
							fn={setusIdNumber}
							showLabel={false}
							isDisabled={
								organization?.value === 'company' ||
								organization === '' ||
								organization === undefined ||
								stripeAccountStatusData?.data?.individual?.ssn_last_4_provided
								// ||
								// (LoginData?.userpro?.additional !== "" &&
								//   LoginData?.userpro?.additional !== null)
							}
						/>
					</div>
					<div className="col-md-4">
						<Input
							type="text"
							value={einNumber}
							name="EIN Number"
							placeholder="EIN Number"
							fn={seteinNumber}
							mask="999999999"
							showLabel={false}
							isDisabled={
								organization?.value === 'individual' ||
								organization === '' ||
								organization === undefined ||
								stripeAccountStatusData?.data?.company?.tax_id_provided
								// ||
								// (LoginData?.userpro?.additional !== "" &&
								//   LoginData?.userpro?.additional !== null)
							}
						/>
					</div>
					<div className="col-md-5">
						<DropDown
							options={optionLlc}
							value={Llc}
							setValue={setLlc}
							label={'Business Structure'}
							showLabel={false}
							isDisabled={
								organization?.value === 'individual' ||
								organization === '' ||
								organization === undefined
								// ||
								// (LoginData?.userpro?.additional !== "" &&
								//   LoginData?.userpro?.additional !== null)
							}
						/>
					</div>
				</div>
				<div className="row g-3 mb-4">
					<div className="col-md-10">
						<Input
							type="text"
							value={website}
							name="Website"
							placeholder="Website"
							fn={setWebsite}
							required={false}
							showLabel={false}
						/>
					</div>
				</div>
				<div className="row g-3">
					<li className="text-muted text-start">
						If you don’t have a business yet, enter your full name.
					</li>
					<div className="w-100 mb-3">
						<textarea
							className="form-control h-100"
							rows="5"
							defaultValue={BusinessDetails}
							aria-label="Detail about you or your business"
							placeholder="Detail about you or your business"
							name="Detail about you or your business"
							onChange={(e) => setBusinessDetails(e.target.value)}
							required
						></textarea>
					</div>
					<fieldset className="border-top p-1 w-100">
						<legend className="float-none w-auto px-2 mx-2 text-start legned-text">
							<small>Expertise</small>
						</legend>
					</fieldset>
					<div className="mb-3 w-full row  d-flex align-items-start justify-content-start">
						<CreatableSelect
							id="expertise"
							className="p-0 basic-single"
							placeholder="Expertise"
							options={taxProExpertises}
							onChange={setExpertise}
							value={expertise || []}
							required
							isMulti
							isClearable
						/>
					</div>
					<fieldset className="border-top p-1 w-100">
						<legend className="float-none w-auto px-2 mx-2 text-start legned-text">
							<small>Services</small>
						</legend>
					</fieldset>
					<div className="mb-3 w-100 d-flex justify-content-between flex-wrap">
						<p className="text-dark fw-bold">
							Which of the below listed services you hope to offer customers on our platform.
						</p>
					</div>
					<div className="mb-3 w-full row  d-flex align-items-start justify-content-start">
						{services?.map((v, i) => {
							return (
								<div key={i} className="w-full d-flex align-items-start justfy-content-start">
									<input
										className="form-check-input custom-checkbox"
										type="checkbox"
										id={`checkbox-${i}`}
										checked={selectedServices.includes(v.id)}
										onChange={() => handleCheckboxChange(v.id)}
									/>
									<label className="form-check-label text-start" htmlFor={`checkbox-${i}`}>
										{v.name}
									</label>
								</div>
							);
						})}
					</div>
				</div>
				<div className="container-fluid content-row ">
					<div className="d-flex align-items-center justify-content-center row">
						<Button
							mainClass="btn btn-outline-primary btn-lg w-100"
							buttonText="Previous"
							wrapperClass={'w-25 custon-button  mt-4'}
							type="button"
							callback={() => {
								GoBack();
							}}
						/>
						<Button
							mainClass="btn btn-primary custon-button-text btn-lg  w-100"
							buttonText="Next"
							wrapperClass={'w-25 custon-button  mt-4'}
							type="submit"
						/>
					</div>
				</div>
			</form>
		</div>
	);
}
