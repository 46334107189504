const Header = ({ myFormObj }) => {
	return (
		<div
			className="w-100 d-flex flex-row  justify-content-between align-items-center  px-3"
			style={{ height: 55 }}
		>
			<div>
				<p className="fw-bold mt-4">
					{myFormObj?.parent} - <small className="fw-400">{myFormObj?.name}</small>
				</p>
			</div>
			{/* <small role="button" className="text-muted">
				All Changes Saved
			</small> */}
		</div>
	);
};

export default Header;
