/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';

const FICATipCredit = () => {
    

    const dispatch=useDispatch()
	const [{ taxPayerForm , taxPayerFormData, formInformation}] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 26 });

    const [totalWages, setTotalWages] = useState();
    const [totalTipsPaid, setTotalTipsPaid] = useState();
    const [tippedEmployees, setTippedEmployees] = useState(null);
    const [totalAdjustment, setTotalAdjustment] = useState();

    const [errors, setErrors] = useState({}); // Track errors for form fields
   
    useEffect(() => {
    	if (taxPayerForm?.data?.FICATipCredit) {
    		if (Object.keys(taxPayerForm?.data?.FICATipCredit).length === 0) return;
			const {
                totalWages,
                totalTipsPaid,
                tippedEmployees,
                totalAdjustment
			} = taxPayerForm?.data?.FICATipCredit;
            setTotalWages(totalWages)
            setTotalTipsPaid(totalTipsPaid)
            setTippedEmployees(tippedEmployees)
            setTotalAdjustment(totalAdjustment)
    	}
    }, [taxPayerForm?.data?.FICATipCredit]);

    const check_Validity = () => {
        setErrors({});
        const newErrors = {};
    
        // Add more validation rules as needed for other fields
        if (
            tippedEmployees===null) {
            newErrors.AnswerOtherQuestions = 'Please Select Required Option';
        }
    
        setErrors(newErrors);
    
        let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
        // Check if there are any errors
        return ConditionCheck;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = check_Validity();
        if (errors) return;

        let FormObj = {
            totalWages,
                totalTipsPaid,
                tippedEmployees,
                totalAdjustment
		};
        let formData = taxPayerForm;
        taxPayerForm.data.FICATipCredit = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));
        uploadFormData(formData);
    };

    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 26;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="approximateValue"
                        value={totalTipsPaid}
                        placeholder="What is the total tips paid or plan to be incurred and paid to employees?"
                        fn={setTotalTipsPaid}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="purchaseAmount"
                        value={totalWages}
                        placeholder="What is the total wages (excluding tips) paid or plan to be incurred and paid to employees that make less than $5.15/hour?"
                        fn={setTotalWages}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="qualifiedInvestmentProperty"
                        value={totalAdjustment}
                        placeholder="What is the total adjustment for tipped employees earning over the FICA Income limit?"
                        fn={setTotalAdjustment}
                        mask={"999999999"}
                    />
                </div>
            </div>
            <div className="w-100 mt-2">
                <small className="col-md-8 form-check-label text-start" htmlFor={`checkbox`}>
				Does the business have tipped employees?
                {tippedEmployees===null && (
                    <span className="text-danger small-text mx-1">*Required</span>
                )}
                </small>
                <div className="col-md-4">
                    <input
                        onChange={() => setTippedEmployees(true)}
                        checked={tippedEmployees === true}
                        className="form-check-input"
                        type="radio"
                        id={`checkbox-div4`}
                        name={`checkbox-div4`}
                    />
                    <label htmlFor={`checkbox-div4`} role="button" className="px-3 m-0 small">
                        Yes
                    </label>
                    <input
                        onChange={() => setTippedEmployees(false)}
                        checked={tippedEmployees === false}
                        className="form-check-input"
                        type="radio"
                        id={`checkbox-div5`}
                        name={`checkbox-div5`}
                    />
                    <label htmlFor={`checkbox-div5`} role="button" className="px-3 m-0 small">
                        No
                    </label>
                </div>
            </div>
            {Object.keys(errors)
    ?.map((key) => {
        return errors[key];
    })
    ?.map((data, index) => {
        return (
            <>
                <small className="text-primary" key={index}>
                    {data}*
                </small>
                <br />
            </>
        );
    })}
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button  mt-4'}
                        type="button"
                        callback={()=>goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default FICATipCredit;