import { PAYOUT_ACTION_TYPE } from "./Type";

const DEFAULT_STATE = {
	requestPayout: { message: "", status: "idle", data: {} },
	getPayout: { message: "", status: "idle", data: {} },
	balance: { message: "", status: "idle", data: {} },
};

const INITIAL_STATE = {
	...DEFAULT_STATE,
};

const payout = (state = INITIAL_STATE, action) => {
	if (action.type === PAYOUT_ACTION_TYPE.requestPayout) {
		return {
			...state,
			requestPayout: action.payload,
		};
	}
	if (action.type === PAYOUT_ACTION_TYPE.requestPayoutIdle) {
		return {
			...state,
			requestPayout: DEFAULT_STATE.requestPayout,
		};
	}
	if (action.type === PAYOUT_ACTION_TYPE.getPayout) {
		return {
			...state,
			getPayout: action.payload,
		};
	}
	if (action.type === PAYOUT_ACTION_TYPE.getPayoutIdle) {
		return {
			...state,
			getPayout: DEFAULT_STATE.getPayout,
		};
	}
	if (action.type === PAYOUT_ACTION_TYPE.balance) {
		return {
			...state,
			balance: action.payload,
		};
	}
	if (action.type === PAYOUT_ACTION_TYPE.balanceIdle) {
		return {
			...state,
			balance: DEFAULT_STATE.balance,
		};
	}
	return state;
};

export default payout;
