/* eslint-disable react-hooks/exhaustive-deps */
import SweetAlert2 from 'react-sweetalert2';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import DatePicker from 'react-datepicker';
import {
	setQueryParams,
	getQueryParam,
	removeQueryParam,
	getTaxproStatus,
	getTaxproStatusClass,
} from '../../../utilites';
import allActions from '../../../Store/action';
import ListPageWrapper from '../components/ListPageWrapper';
import Input from '../../../components/forms/fields/Input';
import Modal from '../../../components/Dashboard/Modal';
import AddUserForm from '../../../components/forms/AddUserForm';
import EditUserForm from '../../../components/forms/EditUserForm';
import EditRoundRobinForm from '../../../components/forms/EditRoundRobinForm';
import EditUserRoleForm from '../../../components/forms/EditUserRoleForm';
import ImpersonateUserForm from '../../../components/forms/ImpersonateUserForm';
import EditTaxproStatusForm from '../../../components/forms/EditTaxproStatusForm';
import ApproveTaxProForm from '../../../components/forms/ApproveTaxProForm';
//import moment from 'moment';

const UserList = () => {
	const [{ loginStatus, LoginData }, { stripeConnection, stripeConnectionMessage }] = useSelector(
		(state) => [state.user, state.dashboard]
	);
	const [dashboard] = useSelector((state) => [state.dashboard]);

	const [swalProps, setSwalProps] = useState({});

	const dispatch = useDispatch();

	const [keyword, setKeyword] = useState(getQueryParam('search') || '');
	const [alertMessage, setAlertMessage] = useState('');
	const [alertError, setAlertError] = useState(false);
	const [verificationMessage,setVerificationMessage] = useState({
		show: false,
		type: '',
		text: '',
	})

	const [userToEdit, setUserToEdit] = useState();

	const [dateCreated, setDateCreated] = useState(
		getQueryParam('created_after') ? new Date(getQueryParam('created_after')) : null
	);
	const [role, setRole] = useState(getQueryParam('role') || '');
	const [verifyStatus, setVerifyStatus] = useState(getQueryParam('verify') || '');
	// Pagination
	const [perPage, setPerPage] = useState(getQueryParam('per_page') || 10);
	const [page, setPage] = useState(getQueryParam('page') || 1);

	const navigate = useNavigate();

	useEffect(() => {
		dispatch(allActions.dashboard.getUserRoles());
	}, [dispatch]);

	// get users list
	useEffect(() => {
		dispatch(
			allActions.dashboard.getUsers(keyword, dateCreated, role, verifyStatus, page, perPage)
		);
	}, [dispatch, keyword, dateCreated, page, perPage, role, verifyStatus]);

	useEffect(() => {
		if (loginStatus && LoginData) {
			if (!LoginData.isAdmin && !LoginData.isAccountManager) {
				navigate('/dashboard/');
			}
		}
	}, [loginStatus, LoginData, navigate]);

	useEffect(() => {
		if (!stripeConnection) {
			dispatch(allActions.global.Loader(false));
			setAlertMessage(stripeConnectionMessage);
			setAlertError(true);
		}
		if (stripeConnection === true) {
			dispatch(allActions.global.Loader(false));
			setAlertMessage('Stripe account connected successfully!');
			setAlertError(false);
		}
	}, [stripeConnection, stripeConnectionMessage]);

	useEffect(() => {
		if(verificationMessage.show){
			setAlertMessage('If an account is registered with us, an email will be sent with password reset instructions.')
		}
	},[verificationMessage])

	const loadUsers = () => {
		dispatch(
			allActions.dashboard.getUsers(keyword, dateCreated, role, verifyStatus, page, perPage)
		);
	};

	const moveTo = (page) => {
		setPage(page);
		// Set query param
		setQueryParams('page', page);
	};

	const changePerPage = (perPage) => {
		setPerPage(perPage);
		// Set query param
		setQueryParams('per_page', perPage);
	};

	const filterByRole = (rol) => {
		setRole(rol);
		// Set query param
		if (!rol) {
			removeQueryParam('role');
		} else {
			setQueryParams('role', rol);
		}
	};

	const filterByVerifyStatus = (status) => {
		setVerifyStatus(status);
		// Set query param
		if (!status) {
			removeQueryParam('verify');
		} else {
			setQueryParams('verify', status);
		}
	};

	const filterByKeyword = (keyword) => {
		setKeyword(keyword);
		// Set query param
		if (!keyword) {
			removeQueryParam('search');
		} else {
			setQueryParams('search', keyword);
		}
	};

	const filterByDateCreated = (date) => {
		setDateCreated(date);
		// Set query param
		if (!date) {
			removeQueryParam('created_after');
		} else {
			setQueryParams('created_after', date);
		}
	};

	const toggleTaxProPause = (id) => {
		dispatch(allActions.user.toggleTaxProPause(id, loadUsers));
	};

	const handlePauseSwitchClick = (id) => {
		setSwalProps((prev) => ({
			...prev,
			[`tp-${id}`]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				confirmButtonText: 'Confirm',
				denyButtonText: 'Cancel',
				text: 'Do you want to pause/unpause this tax pro?',
				didClose: () => setSwalProps((prev) => ({ ...prev, [`tp-${id}`]: { show: false } })),
				onConfirm: () => toggleTaxProPause(id),
			},
		}));
	};

	const deleteUser = (id) => {
		dispatch(allActions.user.deleteUser(id, loadUsers));
	};

	const handleDeleteUserClick = (id) => {
		setSwalProps((prev) => ({
			...prev,
			[`ud-${id}`]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				confirmButtonText: 'Confirm',
				denyButtonText: 'Cancel',
				text: 'Do you want to delete this user?',
				didClose: () =>
					setSwalProps((prev) => ({
						...prev,
						[`ud-${id}`]: { show: false },
					})),
				onConfirm: () => deleteUser(id),
			},
		}));
	};

	const handleSendVerificationEmail = (id,user) => {
		setSwalProps((prev) => ({
			...prev,
			[`ud-${id}`]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				confirmButtonText: 'Confirm',
				denyButtonText: 'Cancel',
				text: 'Do you want to send verification email to this user?',
				didClose: () =>
					setSwalProps((prev) => ({
						...prev,
						[`ud-${id}`]: { show: false },
					})),
				onConfirm: () => dispatch(allActions.user.ResendLink({ email: user?.email },setVerificationMessage )),
			},
		}));
	}


	const clearFilters = () => {
		// Clear state
		setKeyword('');
		setDateCreated(null);
		setRole('');
		setVerifyStatus('');
		setPage(1);
		setPerPage(10);
		// Set query param
		removeQueryParam('search');
		removeQueryParam('created_after');
		removeQueryParam('role');
		removeQueryParam('verify');
		removeQueryParam('page');
		removeQueryParam('per_page');
	};

	/*const handleConnectUser = (user) => {
		setAlertMessage("");
		setAlertError(false);
		dispatch(allActions.dashboard.stripeConnectionReset());

		const organization = user?.userpro?.additional?.business_type;

		// Day month year
		const day = moment(user?.userpro.dob).format("DD");
		const month = moment(user?.userpro.dob).format("MM");
		const year = moment(user?.userpro.dob).format("YYYY");

		let connectAccount = {};
		let personDetails = {};
		// Connect Account Object
		if (organization === "individual") {
			connectAccount = {
				business_type: "individual",
				individual: {
					first_name: user?.firstname,
					last_name: user?.lastname,
					email: user?.email,
					phone: user?.userpro?.phone?.replace(/[()\s-]/g, ""),
					dob: {
						day: day,
						month: month,
						year: year,
					},
					address: {
						line1: user?.userpro?.street,
						city: user?.userpro?.city,
						state: user?.userpro?.state,
						postal_code: user?.userpro?.zipcode,
					},

					id_number: user?.userpro?.additional?.id_number?.toString()?.replace(/[()\s-]/g, ""),
				},
				company:{
					name:user?.userpro?.businessName,
					tax_id: user?.userpro?.additional?.id_number?.toString()?.replace(/[()\s-]/g, "")
				}
			}	
		}

		if (organization === "company") {
			const additional = user?.userpro?.additional;
			// Connect Account Object
			connectAccount = {
				business_type: "company",
				company: {
					structure: additional?.business_structure,
					tax_id: additional?.ein,
					address: {
						line1: user?.userpro?.street,
						city: user?.userpro?.city,
						state: user?.userpro?.state,
						postal_code: user?.userpro?.zipcode,
					},
					phone: user?.userpro?.phone?.replace(/[()\s-]/g, ""),
					name: user?.userpro?.businessName,
				},
			};
			// Person Details Object
			personDetails = {
				first_name: user?.firstname,
				last_name: user?.lastname,
				email: user?.email,
				phone: user?.userpro.phone?.replace(/[()\s-]/g, ""),
				dob: {
					day: day,
					month: month,
					year: year,
				},
				address: {
					line1: user?.userpro?.street,
					city: user?.userpro?.city,
					state: user?.userpro?.state,
					postal_code: user?.userpro?.zipcode,
				},
				relationship: { owner: true, representative: true, director: true },
				id_number: additional?.ein,
			};
		}

		connectAccount["business_profile"] = {
			mcc: 7276,
			url:
				user?.userpro?.website !== null &&
				user?.userpro?.website !== undefined &&
				user?.userpro?.website !== ""
					? user?.userpro?.website
					: "https://tpg.taxreductioncompany.com",
		};

		const connectObj={
			connectAccount,
			personDetails,
			id: user?.userpro?.id,
			additional:user?.userpro?.additional,
			stripe_account_id:user?.userpro?.stripe_account_id,
			uuid:user?.uuid
		}
		// Function To Connect Stripe
		dispatch(allActions.global.Loader(true));
		console.log("@555", personDetails, connectAccount);
		dispatch(
			allActions.dashboard.stripeConnection(connectObj)
		);
	};*/
console.log("@dashboard.users",dashboard.users);
	return (
		<ListPageWrapper
			heading="Users"
			headerControls={
				<Fragment>
					<div className="col-md-10">
						<div className="row g-3">
							<div className="col-md-3">
								<Input
								type="search"
									value={keyword}
									name="name"
									inputClass="search-input border h-auto min-height-initial"
									required={false}
									showLabel={false}
									placeholder="Search by name or email"
									fn={filterByKeyword}
									extraSetter={() => {
										if (page !== 1) moveTo(1);
									}}
								/>
							</div>
							<div className="col-md-2">
								<div className="form-floating">
									<DatePicker
										className="form-select"
										wrapperClassName="d-block"
										selected={dateCreated}
										maxDate={new Date()}
										onChange={(date) => {
											filterByDateCreated(date);
											if (page !== 1) moveTo(1);
										}}
										dateFormat="MMMM d, yyyy"
										placeholderText="Date Created"
										isClearable
									/>
								</div>
							</div>
							{LoginData.isAdmin && (
								<>
									<div className="col-md-2">
										<select
											className="form-select"
											id="filter"
											name="roles"
											onChange={(e) => {
												filterByRole(e.target.value);
												if (page !== 1) moveTo(1);
											}}
											value={role}
										>
											<option value="">Role</option>
											{dashboard.roles.length > 0 &&
												dashboard.roles?.map((role) => (
													<option key={role.id} value={role.id}>
														{role.display_name}
													</option>
												))}
										</select>
									</div>
									<div className="col-md-2">
										<select
											className="form-select"
											id="filterByVerifyStatus"
											name="verifyStatus"
											onChange={(e) => {
												filterByVerifyStatus(e.target.value);
												if (page !== 1) moveTo(1);
											}}
											value={verifyStatus}
										>
											<option value="">Is verify</option>
											<option value="1">Yes</option>
											<option value="0">No</option>
										</select>
									</div>
								</>
							)}
						</div>
					</div>
					<div className="col-md-2">
						<Link
							to="#"
							className="w-100 btn btn-outline-primary"
							data-bs-toggle="modal"
							data-bs-target="#addUserModal"
						>
							Add user
						</Link>
					</div>
				</Fragment>
			}
			alert={{ message: alertMessage, setMessage: setAlertMessage, alertError }}
			dataState={{
				loaded: dashboard.users.loaded,
				fetching: dashboard.users.fetching,
			}}
			tableHeader={
				<Fragment>
					<th scope="col">ID</th>
					<th scope="col">Name</th>
					<th scope="col">Email</th>
					{(LoginData.isAccountManager ||
						(LoginData.isAdmin &&
							dashboard.roles &&
							dashboard.roles.length > 0 &&
							role &&
							dashboard.roles.filter((r) => r.id === parseInt(role))[0].name === 'taxpro')) && (
						<>
							<th scope="col">No of Leads</th>
							<th scope="col">
								Leads Per
								<br /> Last 30 Days
							</th>
							<th scope="col">Invoice Fee (%)</th>
							<th scope="col">Round Robin</th>
						</>
					)}
					{LoginData.isAdmin && !role && <th scope="col">Role</th>}
					<th scope="col">Date Created</th>
					<th scope="col">{LoginData.isAccountManager ? 'Status' : 'Verified'}</th>
					<th className="text-center" scope="col">
						Action
					</th>
				</Fragment>
			}
			tableBody={
				<Fragment>
					{dashboard.users.data.length > 0 ? (
						dashboard.users.data?.map((user) => (
							<tr className="align-middle" key={user.id}>
								<th scope="row">{user.id}</th>
								<td>
									<Link
										to={`/dashboard/users/${user.uuid}`}
										className="text-decoration-none text-dark"
									>{`${user.firstname} ${user.lastname}`}</Link>
								</td>
								<td>
									<Link
										to={`/dashboard/users/${user.uuid}`}
										className="text-decoration-none text-dark"
									>
										{user.email}{' '}
									</Link>
								</td>
								{(LoginData.isAccountManager ||
									(LoginData.isAdmin &&
										dashboard.roles &&
										dashboard.roles.length > 0 &&
										role &&
										dashboard.roles.filter((r) => r.id === parseInt(role))[0].name ===
											'taxpro')) && (
									<>
										<td>
											<Link
												to={`/dashboard/leads/?taxpro=${user.userpro?.id}`}
												className="text-decoration-none text-dark text-decoration-underline"
											>
												{user.userpro?.leadCount}
											</Link>
										</td>
										<td>
											<Link
												to={`/dashboard/leads/?taxpro=${user.userpro?.id}&assigned_after=${new Date(
													new Date().setDate(new Date().getDate() - 30)
												)}`}
												className="text-decoration-none text-dark text-decoration-underline"
											>
												{user.userpro?.leadCountLastThirtyDays}
											</Link>
										</td>
										<td>{user.userpro?.percentage}</td>
										<td>
											{user.userpro?.status_id === 3 ? (
												<Link
													className="badge bg-danger text-white text-decoration-none"
													onClick={() => handlePauseSwitchClick(user?.userpro?.id)}
												>
													On Pause
													<SweetAlert2 {...swalProps[`tp-${user.userpro?.id}`]} />
												</Link>
											) : (
												<Link
													className="text-decoration-underline text-dark"
													data-bs-toggle="modal"
													data-bs-target="#openRoundRobinQuickEditModal"
													aria-controls="openRoundRobinQuickEditModal"
													data-bs-id={user.uuid}
													onClick={() => setUserToEdit(user)}
												>
													{user?.userpro?.status_id === 3 ? 'On Paused' : user.userpro?.roundRobin}
												</Link>
											)}
										</td>
									</>
								)}
								{LoginData.isAdmin && !role && (
									<td>
										<Link
											className="text-decoration-none text-dark"
											data-bs-toggle="modal"
											data-bs-target="#openUserRoleQuickEditModal"
											aria-controls="openUserRoleQuickEditModal"
											data-bs-id={user.uuid}
											onClick={() => setUserToEdit(user)}
										>
											{user.roles[0].display_name || ''}
										</Link>
									</td>
								)}
								<td>
									{new Date(user.created_at).toLocaleDateString('en-US', {
										year: 'numeric',
										month: 'short',
										day: 'numeric',
									})}
								</td>
								{LoginData.isAdmin && user.roles[0].name !== 'taxpro' ? (
									<td>
										<Link
											className={`text-decoration-none badge ${
												user.isVerify ? 'bg-secondary-600' : 'bg-primary'
											}`}
											data-bs-toggle="modal"
											data-bs-target="#openUserQuickEditModal"
											aria-controls="openUserQuickEditModal"
											data-bs-id={user.uuid}
											onClick={() => setUserToEdit(user)}
										>
											{user.isVerify ? 'Yes' : 'No'}
										</Link>
									</td>
								) : (
									<td>
										<Link
											className={`text-decoration-none badge bg-${getTaxproStatusClass(
												user.userpro?.status_id
											)}`}
											data-bs-toggle="modal"
											data-bs-target="#ApproveTaxPro"
											aria-controls="ApproveTaxPro"
											data-bs-id={user.uuid}
											onClick={() => setUserToEdit(user)}
										>
											{getTaxproStatus(user.userpro?.status_id)}
										</Link>
									</td>
								)}
								<td className="text-end">
									<div className="dropdown-center">
										<button
											type="button"
											className="btn btn-sm btn-outline-primary rounded-circle"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											<BsThreeDotsVertical className="" />
										</button>
										<ul className="dropdown-menu border-light">
										{user.userpro &&  <li>
												<Link
													to="#"
													data-bs-toggle="modal"
													data-bs-target="#ApproveTaxPro"
													aria-controls="ApproveTaxPro"
													data-bs-id={user.uuid}
													onClick={() => setUserToEdit(user)}
													className="dropdown-item"
												>
												   Status
												</Link>
											</li>}
											<li>
												<Link
													to="#"
													data-bs-toggle="modal"
													data-bs-target="#openUserQuickEditModal"
													aria-controls="openUserQuickEditModal"
													data-bs-id={user.uuid}
													onClick={() => setUserToEdit(user)}
													className="dropdown-item"
												>
													Quick Edit
												</Link>
											</li>
											
											<li>
												{(LoginData.isAccountManager ||
													(LoginData.isAdmin &&
														dashboard.roles &&
														dashboard.roles.length > 0 &&
														role &&
														dashboard.roles.filter((r) => r.id === parseInt(role))[0].name ===
															'taxpro')) && (
													<Link
														className={`dropdown-item ${
															user?.userpro?.status_id === 3 ? 'primary-300' : 'primary'
														}`}
														onClick={() => handlePauseSwitchClick(user?.userpro?.id)}
													>
														{user?.userpro?.status_id === 3 ? 'Unpause Account' : 'Pause Account'}
														<SweetAlert2 {...swalProps[`tp-${user.userpro?.id}`]} />
													</Link>
												)}
											</li>
											<li>
												<Link
													className={`dropdown-item ${
														!user.roles.filter((role) => role.name === 'admin').length > 0 &&
														!LoginData.impersonation
															? 'primary'
															: 'grey disabled'
													}`}
													data-bs-toggle="modal"
													data-bs-target="#impersonateUser"
													onClick={() => setUserToEdit(user)}
												>
													Login As
												</Link>
											</li>
											
											{/*user?.roles?.[0]?.name==='taxpro' && user?.userpro?.stripe_account_id
											&&(LoginData?.isAdmin || LoginData?.isAccountManager) &&
											<li>
											<Link
												className={`dropdown-item ${
													!user.roles.filter((role) => role.name === 'admin').length > 0 &&
													!LoginData.impersonation
														? 'primary'
														: 'grey disabled'
												}`}
												onClick={() => handleConnectUser(user)}
											>
												Connect
											</Link>
											</li>*/}
											{!user.isVerify ? <li>
												<Link
													to="#"
													className={`dropdown-item text-${(LoginData.isAdmin || LoginData.isAccountManager) && !(user?.userpro?.leads.length > 0)

															? 'black'
															: 'grey disabled'
														}`}
													onClick={() => {
														handleSendVerificationEmail(user.id,user);
													}}
												>
													Verification
													<SweetAlert2 {...swalProps[`ud-${user.id}`]} />
												</Link>
											</li> :''}
											<li>
												<hr className="dropdown-divider border-light" />
											</li>
											<li>
												<Link
													to="#"
													className={`dropdown-item text-${
														LoginData.isAdmin && !user?.userpro?.leads.length > 0
															? 'danger'
															: 'grey disabled'
													}`}
													onClick={() => {
														handleDeleteUserClick(user.id);
													}}
												>
													Delete
													<SweetAlert2 {...swalProps[`ud-${user.id}`]} />
												</Link>
											</li>
										</ul>
									</div>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="7" className="text-muted text-center">
								No users found
							</td>
						</tr>
					)}
				</Fragment>
			}
			paginationProps={{
				pageCount: Math.ceil(dashboard?.users.count / perPage),
				currentPage: parseInt(page),
				setCurrentPage: moveTo,
				perPage: perPage,
				setPerPage: (pc) => {
					changePerPage(pc);
					// Move to first page
					if (page !== 1) moveTo(1);
				},
			}}
			modals={
				<Fragment>
					<Modal
						title={'Approve Tax Pro'}
						bodyClass="p-4"
						btnClass="TaxProStatusForm"
						btnText={'Approve Tax Pro'}
						trigger="ApproveTaxPro"
						showBtn={false}
						size={"lg"}
						close={true}
					>
						<ApproveTaxProForm alertMessage={alertMessage} setAlertMessage={setAlertMessage} reloadUser={loadUsers} userData={userToEdit} />
					</Modal>
					<Modal
						title="Impersonate User"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="impersonateUser"
						showBtn={false}
						btnClass="impersonateUserModalClose"
					>
						{userToEdit && <ImpersonateUserForm userId={userToEdit?.id} userToEdit={userToEdit} cleanup={clearFilters} />}
					</Modal>
					<Modal
						title="Add User"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="addUserModal"
						showBtn={false}
						btnClass="addUserModalClose"
					>
						<AddUserForm reloadUsers={loadUsers} setAlert={setAlertMessage} />
					</Modal>

					<Modal
						title="Quick Edit"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="openUserQuickEditModal"
						showBtn={false}
						btnClass="userDetailModalClose"
					>
						{userToEdit && (
							<EditUserForm
								userData={userToEdit}
								reloadUser={loadUsers}
								setAlert={setAlertMessage}
							/>
						)}
					</Modal>
					<Modal
						title="Update Round Robin Circle"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="openRoundRobinQuickEditModal"
						showBtn={false}
						btnClass="roundRobinQuickEditModalClose"
					>
						{userToEdit && userToEdit.userpro && (
							<EditRoundRobinForm
								proId={userToEdit?.userpro.id}
								roundRobin={userToEdit?.userpro.roundRobin}
								reloadUser={loadUsers}
								setAlert={setAlertMessage}
							/>
						)}
					</Modal>
					<Modal
						title="User Role Quick Edit"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="openUserRoleQuickEditModal"
						showBtn={false}
						btnClass="userRoleQuickEditModalClose"
					>
						{userToEdit && (
							<EditUserRoleForm
								userId={userToEdit.id}
								userRole={userToEdit.roles[0].userrole.role_id}
								reloadUser={loadUsers}
								setAlert={setAlertMessage}
							/>
						)}
					</Modal>
					<Modal
						title="Tax Pro Status Edit"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="openTaxProStatusEditModal"
						showBtn={false}
						btnClass="userTaxStatusEditModalClose"
					>
						{LoginData.isAccountManager && userToEdit && (
							<EditTaxproStatusForm
								proId={userToEdit?.userpro?.id}
								currentStatus={userToEdit?.userpro?.status_id}
								reload={loadUsers}
								setAlert={setAlertMessage}
							/>
						)}
					</Modal>
				</Fragment>
			}
		/>
	);
};

export default UserList;
