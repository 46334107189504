/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import allActions from '../../Store/action';
import UsersList from '../Messaging/UsersList';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const ChatUserWrapper = ({ setOnlineUsers }) => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [chatUsers, setChatUsers] = useState([]);
	const [openChat, setOpenChat] = useState(false);
	const [loading, setLoading] = useState(false);
	const [
		{ user, dashboard },
		{ LoginData, loginStatus },
		{ adminRole, personalConnections, chatStatus },
	] = useSelector((state) => [state, state.user, state.firebase]);

	// --------------------------------------------
	// HOOKS (UseEffect used For Different Purposes)
	// --------------------------------------------

	useEffect(() => {
		if (
			personalConnections?.loaded === true &&
			chatStatus.loaded === true &&
			chatUsers &&
			setOnlineUsers
		) {
			let onlineCount = 0;
			chatUsers?.forEach((element) => {
				if (
					allActions.firebase.getMyStatus(chatStatus?.data, element?.uuid, 'status') === 'online'
				) {
					++onlineCount;
				}
			});
			setOnlineUsers(onlineCount);
		}
	}, [chatUsers, chatStatus, personalConnections]);

	//  *****--HOOKS To Retrive Chat Users(last seen , Online /offline)--****
	useEffect(() => {
		if (chatUsers?.length > 0) {
			dispatch(allActions.firebase.getFirebaseUsers(chatUsers));
		}
	}, [chatUsers]);

	//  *****--HOOKS To Apply Filter on Chats according to Last Chat--****
	useEffect(() => {
		manageFilter(loginStatus, adminRole.loaded, personalConnections);
	}, [loginStatus, adminRole.loaded, personalConnections, openChat, user, chatUsers.length]);

	// *****--Load Customer & Account manager If User is Tax Pro--*****
	useEffect(() => {
		if (
			loginStatus &&
			!user?.isRegular &&
			!user?.isAdmin &&
			!user?.isAccountManager &&
			!dashboard?.leads.loaded
		) {
			dispatch(allActions.dashboard?.getLeads('', ''));
		}
		if (
			loginStatus &&
			!user?.isRegular &&
			!user?.isAdmin &&
			dashboard?.leads.loaded &&
			adminRole.loaded === true
		) {
			let options = [];
			let filteredData = dashboard?.leads?.data?.map((data) => {
				return {
					role: 'Customer',
					id: data?.user?.id,
					uuid: data?.user?.uuid,
					lastname: data?.user?.lastname,
					firstname: data?.user?.firstname,
				};
			});
			options = filteredData;
			// check account manager exist or not
			if (user?.LoginData?.userpro?.manager) {
				let manager = user?.LoginData?.userpro?.manager;
				manager.role = 'Account Manager';
				options.push(manager);
			}

			let admin = adminRole?.data?.map((data) => {
				return {
					firstname: data?.firstname,
					lastname: data?.lastname,
					id: data?.roles[0].userrole?.user_id,
					uuid: data?.uuid,
					role: 'Admin',
				};
			});
			options.push(admin[0]);
			setChatUsers(options);
		}
	}, [
		loginStatus,
		dashboard?.leads.loaded,
		user?.isRegular,
		user?.isAdmin,
		user.isAccountManager,
		adminRole.loaded,
	]);

	// *****--Load Customers & TaxPro If User is Admin--*****
	useEffect(() => {
		if (loginStatus && !user?.isRegular && !user?.isTaxPro && !dashboard?.allUsers.loaded) {
			dispatch(allActions.dashboard?.getAllUsers('', ''));
		}
		if (loginStatus && !user?.isRegular && !user?.isTaxPro && dashboard?.allUsers.loaded) {
			let filteredData = dashboard?.allUsers?.data
				.filter((data) => data.roles[0].name !== 'customer')
				?.map((data) => {
					if (data.roles[0].display_name !== 'Admin') {
						return {
							firstname: data?.firstname,
							lastname: data?.lastname,
							id: data?.id,
							uuid: data?.uuid,
							role: data?.roles[0]?.display_name,
						};
					} else return undefined;
				});
			let others = filteredData.filter((data) => data !== undefined);
			setChatUsers(others);
		}
	}, [loginStatus, dashboard.allUsers.loaded, user?.isTaxPro, user?.isRegular]);

	// *****--Load TaxPro & Account manager If User is Customer--*****
	useEffect(() => {
		let options = [];
		if (
			loginStatus &&
			!user?.isTaxPro &&
			!user?.isAdmin &&
			!user?.isAccountManager
			// &&
			// adminRole.loaded === true
		) {
			let taxpro = LoginData?.lead[0]?.userpro?.user;
			taxpro.role = 'Tax Pro';
			options.push(taxpro);
			// check account manager exist or not
			if (LoginData?.lead[0]?.userpro?.manager) {
				let manager = LoginData?.lead[0]?.userpro?.manager;
				manager.role = 'Account Manager';
				options.push(manager);
			}
			setChatUsers(options);
		}
	}, [
		loginStatus,
		user?.isTaxPro,
		user?.isAdmin,
		user?.isAccountManager,
		LoginData,
		// adminRole.loaded,
	]);

	// *****--Load Customer & Account manager If User is Account Manager--*****
	useEffect(() => {
		if (loginStatus && user?.isAccountManager && !dashboard.leads.loaded) {
			dispatch(allActions.dashboard.getLeads('', ''));
		}
		if (loginStatus && user?.isAccountManager && !dashboard.allUsers.loaded) {
			dispatch(allActions.dashboard.getUsers('', ''));
		}
	}, [
		loginStatus,
		user.isAccountManager,
		dashboard?.leads.loaded,
		dashboard?.allUsers.loaded,
		LoginData,
	]);

	// *****--Check Leads Exist or not & Customers as well--*****
	useEffect(() => {
		// check leads exist or not
		if (
			loginStatus &&
			!user?.isRegular &&
			!user?.isAdmin &&
			!user?.isTaxPro &&
			dashboard?.leads.loaded &&
			dashboard?.allUsers.loaded &&
			adminRole.loaded === true
		) {
			let options = [];
			let filteredLeadsData = dashboard?.leads?.data?.map((data) => {
				return {
					firstname: data?.user?.firstname,
					lastname: data?.user?.lastname,
					id: data?.user?.id,
					uuid: data?.user?.uuid,
					role: 'Customer',
				};
			});
			// check users exist or not
			let filteredUsersData = dashboard?.allUsers?.data?.map((data) => {
				return {
					id: data?.id,
					uuid: data?.uuid,
					firstname: data?.firstname,
					lastname: data?.lastname,
					role: data?.roles[0]?.display_name,
				};
			});
			options = filteredLeadsData.concat(filteredUsersData);
			let admin = adminRole?.data?.map((data) => {
				return {
					firstname: data?.firstname,
					lastname: data?.lastname,
					id: data?.roles[0].userrole?.user_id,
					uuid: data?.uuid,
					role: 'Admin',
				};
			});
			options.push(admin[0]);
			setChatUsers(options);
		}
	}, [
		loginStatus,
		dashboard?.leads,
		dashboard?.allUsers,
		user?.isRegular,
		user?.isTaxPro,
		user?.isAdmin,
		adminRole,
	]);

	// *****--Handle Store Response Of Loading Of Every HOOKS--*****
	useEffect(() => {
		// check user is login
		if (loginStatus) {
			if (
				// For Leads Put Condition
				(!user?.isRegular && !user?.isAdmin && !dashboard?.leads.loaded) ||
				// For Customer Put Condition
				(!user?.isTaxPro && !user?.isAdmin && !LoginData?.lead[0]?.userpro?.user) ||
				// For Getting Admin user
				(!user?.isAdmin ? adminRole.loaded !== 'idle' : false) ||
				// personal firebase connection details
				personalConnections?.loaded === 'idle'
			) {
				setLoading(true);
			}
			if (
				// For Leads Put Condition
				((!user?.isRegular && !user?.isAdmin && dashboard?.leads.loaded) ||
					// For Customer Put Condition
					(!user?.isTaxPro && !user?.isAdmin && LoginData?.lead[0]?.userpro?.user) ||
					// For admin Put Condition
					(!user?.isTaxPro &&
						!user?.isTaxPro &&
						!user?.isAccountManager &&
						dashboard?.allUsers.loaded)) &&
				// For Getting Admin user
				(!user?.isAdmin ? adminRole.loaded !== 'idle' : true) &&
				// personal firebase connection details
				personalConnections?.loaded === true
			) {
				setLoading(false);
			}
		}
	}, [
		dashboard?.allUsers.loaded,
		dashboard?.leads.loaded,
		user.isRegular,
		user.isAdmin,
		user.isTaxPro,
		user.isAccountManager,
		LoginData?.lead,
		loginStatus,
		adminRole?.loaded,
		personalConnections?.loaded,
	]);
	// *****--Function to Manage Filter According to Last Chat--****
	const manageFilter = () => {
		if (adminRole.loaded === 'idle' && loginStatus) {
			dispatch(allActions.firebase.getAdminUser());
		}
		if (personalConnections?.loaded === 'idle' && loginStatus) {
			dispatch(allActions.firebase.readPersonalConnections(LoginData?.uuid));
		}
		if (personalConnections?.loaded === true) {
			filteredChatUsers(personalConnections.data);
		}
	};

	//  *****--Function to Filter Chat Users--****
	const filteredChatUsers = (connection) => {
		chatUsers?.map((data) => {
			let filtered = connection.find((v) => v?.ids?.includes(data?.uuid));
			if (filtered) {
				data['updatedAt'] = filtered['updatedAt'];
				data[LoginData?.uuid] = filtered[LoginData?.uuid];
				data['docId'] = filtered['docId'];
			}
			return 0;
		});
		const sortedArray = chatUsers
			.slice()
			.sort((a, b) => (a.updatedAt ? b.updatedAt - a.updatedAt : 1) || -1);

		if (sortedArray.length > 0) {
			setChatUsers(sortedArray);
		}
	};

	// Function To Navigate to Messages
	const gotoMessages = () => {
		if (!window.location.href.includes('messages')) {
			dispatch(allActions.firebase.resetReadPersonalChatsArray());
		}
	};

	// Function To Navigate to Messages & OPEN CHATS
	const loadChats = (data) => {
		dispatch(allActions.firebase.resetReadPersonalChatsArray());
		navigate('/dashboard/messages', { state: data });
	};
	return (
		<div className="d-flex align-items-center justify-content-center flex-column">
			{loading ? (
				<p className="text-decoration-none float-center">Loading...</p>
			) : (
				<>
					<div className="w-100" role="button">
						<UsersList
							search={''}
							chatUsers={chatUsers.slice(0, 5)}
							loadChats={loadChats}
							setOpenChat={setOpenChat}
							getMyStatus={allActions.firebase.getMyStatus}
						/>
					</div>
					<Link
						className="text-decoration-none float-center"
						to="/dashboard/messages"
						onClick={() => {
							gotoMessages();
						}}
					>
						View more user activities
					</Link>
				</>
			)}
		</div>
	);
};

export default ChatUserWrapper;
