/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import Input from '../../fields/Input';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';
import { HandleFormData } from '../../../../utilites';

const BusinessDetails = ({ type }) => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const dispatch = useDispatch();

	const { accountingMethods, accountingPeriods } = GLOBAL_VARIABLES;
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 2 });

	const [{ taxPayerFormData, taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);
	
	// For Text Input Field

	// For Choice Questions
	const [taxEndDate, setTaxEndDate] = useState();
	const [accountMethod, setAccountingMethod] = useState();
	const [accountingPeriod, setAccountingPeriod] = useState();
	const [BusinessActivity, setBusinessActivity] = useState();
	const [productOrService, setProductOrService] = useState();
	const [taxBeginningDate, setTaxBeginningDate] = useState();

	// For Choice Questions

	const [errors, setErrors] = useState({}); // Track errors for form fields

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.BusinessDetailsForm) {
			if (Object.keys(taxPayerForm?.data?.BusinessDetailsForm).length === 0) return;

			const {
				accountMethod,
				accountingPeriod,
				BusinessActivity,
				productOrService,
				taxBeginningDate,
				taxEndDate,
			} = taxPayerForm?.data?.BusinessDetailsForm;

			setTaxEndDate(taxEndDate);
			setAccountingMethod(accountMethod);
			setBusinessActivity(BusinessActivity);
			setProductOrService(productOrService);
			setTaxBeginningDate(taxBeginningDate);
			setAccountingPeriod(accountingPeriod);
		}
	}, [taxPayerForm?.data?.BusinessDetailsForm]);

	// ---------------------------------------
	// Functions
	// ---------------------------------------

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		if (!accountMethod || !accountingPeriod) {
			newErrors.accountMethod = 'Select Accounting Information';
		}

		setErrors(newErrors);

		// Check if there are any errors
		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let errors = check_Validity();
		if (errors) return;

		let FormObj = {
			accountMethod,
			accountingPeriod,
			BusinessActivity,
			productOrService,
			taxBeginningDate,
			taxEndDate,
		};

		let formData = taxPayerForm;
		taxPayerForm.data.BusinessDetailsForm = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
	};

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 2;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<small className="ml-4 fw-bold">Bank Details</small>

			<hr />
			<div className="w-100 d-flex flex-row flex-wrap justify-content-between align-items-end mb-3 mt-3">
				{JSON.parse(JSON.stringify(accountingMethods))?.map((data, i) => {
					return (
						<div
							key={i}
							className="w-100  align-items-center justify-content-start mt-3 d-flex flex-row my-2"
						>
							<small
								className="col-md-4 form-check-label text-start fw-bold"
								htmlFor={`checkbox-HolderType-${i}`}
							>
								{data.question}
							</small>
							{data?.options?.map((option, index) => {
								return (
									<div>
										<input
											onChange={() => setAccountingMethod(option)}
											checked={accountMethod === option}
											className="form-check-input"
											type="radio"
											id={`checkbox-HolderType-${index}`}
											name={`checkbox-HolderType-${index}`}
										/>
										<label
											htmlFor={`checkbox-HolderType-${index}`}
											role="button"
											className="px-3 m-0 small"
										>
											{option}
										</label>
									</div>
								);
							})}
						</div>
					);
				})}
				{JSON.parse(JSON.stringify(accountingPeriods))?.map((data, i) => {
					return (
						<div
							key={i}
							className="w-100  align-items-center justify-content-start mt-3 d-flex flex-row my-2"
						>
							<small
								className="col-md-4 form-check-label text-start fw-bold"
								htmlFor={`checkbox-accountNature-${i}`}
							>
								{data.question}
							</small>
							{data?.options?.map((option, index) => {
								return (
									<div>
										<input
											onChange={() => setAccountingPeriod(option)}
											checked={accountingPeriod === option}
											className="form-check-input"
											type="radio"
											id={`checkbox-accountNature-${index}`}
											name={`checkbox-accountNature-${index}`}
										/>
										<label
											htmlFor={`checkbox-accountNature-${index}`}
											role="button"
											className="px-3 m-0 small"
										>
											{option}
										</label>
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="BusinessActivity"
						value={BusinessActivity}
						placeholder="Business activity"
						fn={setBusinessActivity}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="productOrService"
						value={productOrService}
						placeholder="Product or service"
						fn={setProductOrService}
					/>
				</div>
				{type !== '1120' && (
					<>
						<div className="col-md-6 mb-3">
							<Input
								type="text"
								name="ein"
								value={taxBeginningDate}
								placeholder="Tax beginning date"
								fn={setTaxBeginningDate}
								mask="99-99-9999"
							/>
						</div>
						<div className="col-md-6 mb-3">
							<Input
								type="text"
								name="ein"
								value={taxEndDate}
								placeholder="Tax end date"
								fn={setTaxEndDate}
								mask="99-99-9999"
							/>
						</div>
					</>
				)}
			</div>
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default BusinessDetails;
