import HomeMainFeatures from '../../assets/img/home-main-features.png';

const Features = () => {
    return (
        <section className="clearfix py-5 px-3">
            <div className="container">
                <div className="clearfix w-85 mx-auto">
                    <div className="row g-5 justify-content-center align-items-center align-middle">
                        <div className="col-md-6 order-2 order-md-1">
                            <div className="clearfix">
                                <h2 className="display-6 text-dark fw-bold">Professional, licensed, and vetted tax advisors who will <span className="text-primary">save you money</span></h2>
                                <p className="text-dark my-4">Get help from top-tier tax experts who understand the ins and outs of the tax code. These professionals are far from your average accountants; they are an elite collective of vetted and experienced tax advisors who, together, have saved millions and are just getting started.</p>
                                <p className="text-dark mb-4">As your trusted partner, their mission is to share their extensive knowledge so that you can reach your fullest potential. Never leave Uncle Sam a tip ever again!</p>
                                <div className="clearfix text-center text-md-start">
                                    <a href="/#leadForm" className="btn btn-primary fs-5 fw-semibold px-3 px-md-5 py-2">Get matched with a tax expert</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 order-1 order-md-2">
                            <div className="clearfix">
                                <img src={HomeMainFeatures} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Features;