import { Link } from 'react-router-dom';
// Icons
import { GoQuestion } from 'react-icons/go';
import { BiMessageRounded } from 'react-icons/bi';
import { TiDocumentText } from 'react-icons/ti';

export default function GetHelpForm({ isTaxPro = false }) {
	const closeModal = () => {
		document.querySelector('.getHelpModalClose').click();
	};

	return (
		<>
			<div className='row'>
				<div className='col-md-12 mb-4'>
					<p className='text-start text-black mb-0'>Choose an option to get help.</p>
				</div>
			</div>
			<div className='row text-start mb-5'>
				<div className='col-md-4'>
					<a
						href='/faq'
						target='_blank'
						rel='noreferrer noopener'
						className='card p-2 cursor-pointer text-decoration-none'
						style={{ height: 230 }}
					>
						<div className='card-body'>
							<GoQuestion fontSize={40} />
							<h5 className='card-title fw-bold mt-4 mb-3'>FAQs</h5>
							<p className='card-text opacity-75'>View the help FAQs to get answers you need.</p>
						</div>
					</a>
				</div>
				<div className='col-md-4'>
					<Link
						to={`/dashboard/${isTaxPro ? 'messages' : ''}`}
						className='card p-2 cursor-pointer text-decoration-none'
						style={{ height: 230 }}
						onClick={closeModal}
					>
						<div className='card-body'>
							<BiMessageRounded fontSize={40} />
							<h5 className='card-title fw-bold mt-4 mb-3'>Message Support</h5>
							<p className='card-text opacity-75'>Contact support through our chat.</p>
						</div>
					</Link>
				</div>
				<div className='col-md-4'>
					<div
						className='card p-2 cursor-pointer'
						data-bs-toggle='modal'
						data-bs-target='#createDisputeModal'
						style={{ height: 230 }}
						onClick={closeModal}
					>
						<div className='card-body'>
							<TiDocumentText fontSize={40} />
							<h5 className='card-title fw-bold mt-4 mb-3'>File a Dispute</h5>
							<p className='card-text opacity-75'>
								Can't get resolution? File a dispute and have support arbitrate.
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
