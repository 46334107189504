/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import TextArea from '../../fields/TextArea';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import Input from '../../fields/Input';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';
import { HandleFormData } from '../../../../utilites';

const Deduction = () => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const dispatch = useDispatch();

	const { deductionOptions, possibledeductionOptions } = GLOBAL_VARIABLES;
	const uploadFormData = HandleFormData({ parentIndex: 3, myIndex: 1 });

	const [{ taxPayerFormData, taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);

	// For Text Input Field

	const [comments, setComments] = useState('');

	// For Choice Questions

	const [deductionsSelect, setDeductionsSelect] = useState();
	const [possibleDeductions, setPossibleDeductions] = useState([]);

	// For Choice Questions

	const [errors, setErrors] = useState({}); // Track errors for form fields

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------
	useEffect(() => {
		setPossibleDeductions(JSON.parse(JSON.stringify(possibledeductionOptions)));
	}, []);
	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.defuctionForm) {
			if (Object.keys(taxPayerForm?.data?.defuctionForm).length === 0) return;

			const { deductionsSelect, possibleDeductions, comments } = taxPayerForm?.data?.defuctionForm;
			setDeductionsSelect(deductionsSelect);
			setPossibleDeductions(possibleDeductions);
			setComments(comments);
		}
	}, [taxPayerForm?.data?.defuctionForm]);

	// ---------------------------------------
	// Functions
	// ---------------------------------------

	const handleAmountChange = (id, index) => {
		const myInputAmount = document.getElementById(id);
		let localDeduction = possibleDeductions;
		localDeduction[index].amount = myInputAmount.value;
		setPossibleDeductions(JSON.parse(JSON.stringify(localDeduction)));
	};

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		if (!deductionsSelect) {
			newErrors.deductionsSelect = 'Select Deduction Options';
		}

		setErrors(newErrors);

		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let errors = check_Validity();
		if (errors) return;
		let FormObj = { deductionsSelect, possibleDeductions, comments };
		let formData = taxPayerForm;
		taxPayerForm.data.defuctionForm = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
	};

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 3;
		let myIndex = 1;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};
	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<small className="fw-bold">
					You generally have the option to take either the standard deduction or itemize your
					deductions_ A Itst of common itemized deductions is shown below.{' '}
				</small>
				<div className=" mb-3 px-3">
					{deductionOptions?.map((data, i) => {
						return (
							<div key={i} className="w-100  align-items-center justify-content-between mt-2">
								<small className="col-md-12 form-check-label text-start" htmlFor={`checkbox-${i}`}>
									{data.question}
								</small>
								{data?.options?.map((option, index) => {
									return (
										<div className="col-md-12">
											<input
												onChange={() => setDeductionsSelect(option)}
												checked={deductionsSelect === option}
												className="form-check-input"
												type="radio"
												id={`checkbox-${index}`}
												name={`checkbox-${index}`}
											/>
											<label htmlFor={`checkbox-${index}`} role="button" className="px-3 m-0 small">
												{option}
											</label>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
			<br />
			{deductionsSelect === deductionOptions[0]?.options?.[0] && (
				<>
					<p className="fw-bold">Itemized deduction</p>
					<hr />
					<div className="w-100 d-flex flex-row flex-wrap justify-content-between align-items-center">
						{possibleDeductions?.map((data, index) => {
							return (
								<li className="my-2 text-muted w-50 text-align-start">
									<small>{data.question}</small>
								</li>
							);
						})}
					</div>
				</>
			)}
			{deductionsSelect === deductionOptions[0]?.options?.[1] && (
				<>
					<p className="fw-bold">Itemized deduction</p>
					<hr />
					<div className="w-100 d-flex flex-row flex-wrap justify-content-between align-items-end mb-3 px-0 mt-3">
						{possibleDeductions?.map((data, i) => {
							return (
								<div
									onClick={() => {}}
									key={i}
									className="w-50 d-flex align-items-end justify-content-between px-2 my-2"
								>
									<small className="form-check-label text-start">{data.question}</small>
									<div className="col-md-5">
										<Input
											type="text"
											value={data?.amount}
											name="Amount"
											placeholder="Amount"
											formid={`Amount${i}`}
											fn={() => {
												handleAmountChange(`Amount${i}`, i);
											}}
											mask={'999999999'}
										/>
									</div>
								</div>
							);
						})}
					</div>
				</>
			)}
			<br />
			<small className="ml-4 fw-bold">Comments</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3 mb-3 custom-left-form">
				<small className="text-dark fw-bold m-0 py-2">
					Enter Any Additional Information / Question here.
				</small>
				<div className="col-md-8 mb-3">
					<TextArea
						inputName="form-control"
						defaultValue={comments}
						placeholder="Comments"
						name="comments"
						fn={setComments}
						row="6"
						required={false}
					/>
				</div>
			</div>
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default Deduction;
