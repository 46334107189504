import React from 'react';
import AvailabilityForm from '../../forms/AvailabilityForm';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../Store/action';

const Availability = ({ active, setActive }) => {
	const dispatch = useDispatch();

	const [{ LoginData }] = useSelector((state) => [state.user]);

	return (
		<div>
			<div className='w-full  border-grey rounded px-2 py-2'>
				<AvailabilityForm
					active={active}
					setActive={setActive}
					data={LoginData.availability?.time_ranges}
					reloadUser={() => {
						dispatch(
							allActions.user.ReGetUserData(() => dispatch(allActions.dashboard.getAccountStatus()))
						);
					}}
				/>
			</div>
		</div>
	);
};

export default Availability;
