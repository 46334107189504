import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../Store/action";
import { MEMBERSHIP_ACTION_TYPE } from "../../../Store/membership/Type";

const SummaryCard = ({ selectedMembership, setMessage }) => {

  const dispatch = useDispatch();

  const [{ LoginData }] = useSelector((state) => [state.user]);

  const updateMembership = async () => {
    setMessage({ show: false });
    dispatch(allActions.global.Loader(true));
    dispatch({ type: MEMBERSHIP_ACTION_TYPE.updateMembershipIdle });
    dispatch(allActions.membership.updateMembershipPlan({ membershipId: selectedMembership.plan.id }));
  }

  console.log("@000", selectedMembership)
  return (
    <>
      <div className='card p-4 mb-4 rounded bg-grey-10 d-flex flex-column align-items-center justify-content-center cutomBorderBox'>
        <p className='w-100 fw-bold'>Order Summary</p>
        <h4 className='w-100 fw-bold'>${selectedMembership?.plan?.membership_type?.Price}</h4>
        <div className='w-100 d-flex align-items-center justify-content-between my-3'>
          <div className='d-flex align-items-end'>
            <p className='m-0 p-0'>Enterprise</p>
            <p className='small-text text-muted m-0 p-0'>&nbsp;/month</p>
          </div>
          <p className='m-0 p-0'>${selectedMembership?.plan?.membership_type?.Price}</p>
        </div>
        <div className='col-10'> <hr className='color-primary' /></div>
        <div className='w-100 d-flex align-items-center justify-content-between my-3'>
          <div className='d-flex align-items-end'>
            <p className='fw-bold m-0 p-0'>Total</p>
          </div>
          <p className='fw-bold m-0 p-0'>${selectedMembership?.plan?.membership_type?.Price}</p>
        </div>
      </div>
      <div >
        <button
          disabled={!LoginData?.userpaymentmethod?.find((data) => data.payment_method_id.startsWith('pm'))}
          id="paymentSubmit"
          className={'btn btn-primary custon-button-text btn-md w-100'}
          onClick={() => { updateMembership() }}
        >
          Pay Now
        </button>
        <p className="text-muted small-text mt-2 px-2">
				Your personal data will be used to process your order, support your experience throughout
				this website, and for other purposes described in our privacy policy.
			</p>
      </div>
    </>
  )
}


export default SummaryCard;