import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../dashboard/components/Header';
import Footer from '../dashboard/components/Footer';
import Preloader from './components/Preloader';
import Activity from '../../components/Dashboard/Activity';
import allActions from '../../Store/action';
import { getNotificationContentByActionType } from '../../utilites';

const Notifications = () => {
	const [{ LoginData }, { notificationsCount, notifications }] = useSelector((state) => [
		state.user,
		state.firebase,
	]);
	const per_page = 10;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(allActions.firebase.getNotificationsCount(LoginData.uuid));
		dispatch(allActions.firebase.getNotifications(LoginData.uuid, per_page));
	}, [dispatch, LoginData.uuid]);

	const loadMore = () => {
		dispatch(
			allActions.firebase.getNotifications(
				LoginData.uuid,
				per_page,
				notifications.data[notifications.data.length - 1].createdAt
			)
		);
	};

	return (
		<div className="clearfix px-3">
			<Header name="Notifications" />
			<div className="container page">
				<div className="clearfix pt-4 mb-4">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="clearfix text-center mb-5">
								<h1>Notifications</h1>
								<p>Full list of notifications</p>
							</div>
							<div className="row justify-content-center">
								<div className="col-12">
									<ul
										className={`list-group list-group-flush Notifications__list${
											notifications.loaded && notifications.fetching ? ' pe-none opacity-50' : ''
										}`}
									>
										{!notifications.loaded && notifications.fetching ? (
											<Preloader wrapperClass="h-100" />
										) : (
											<>
												{notifications.loaded && notifications.data.length === 0 ? (
													<p>You have no notifications available to load right now.</p>
												) : (
													<>
														{notifications.data?.map((notification) => (
															<Activity
																key={notification.id}
																textContent={getNotificationContentByActionType({
																	action_type: notification.actionType,
																	current_user: LoginData.uuid,
																	user_by: notification.userBy,
																	user_for: notification.userFor,
																})}
																createdAt={notification.createdAt}
																createdBy={notification.userBy}
																createdFor={notification.userFor}
																maxHeight={90}
															/>
														))}
													</>
												)}
											</>
										)}
									</ul>
									{notifications.loaded &&
										!notifications.fetching &&
										notifications.data.length < notificationsCount && (
											<button className="btn btn-primary d-block mx-auto" onClick={loadMore}>
												Load more
											</button>
										)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Notifications;
