import React, { useState, useRef } from 'react';
import axios from 'axios';
import CreatableSelect from 'react-select/creatable';
import { FileUploader } from 'react-drag-drop-files';
import Button from './fields/Button';
import { BASEURL } from '../../utilites';
import TextArea from './fields/TextArea';

const disputeReasons = [
	{ label: 'Service not delivered', value: 'Service not delivered' },
	{ label: 'Service not as described', value: 'Service not as described' },
	{ label: 'Service not as expected', value: 'Service not as expected' },
];

export default function AddDisputeForm({
	jobId,
	setAlert,
	reload,
	types = ['PNG', 'JPG', 'PDF', 'JPEG'],
}) {
	const [reason, setReason] = useState();
	const [details, setDetails] = useState('');
	const [documents, setDocuments] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);

	const [errors, setErrors] = useState({
		reason: false,
		details: false,
		general: '',
	});

	const button = useRef();

	// function to handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();

		setUploading(true);

		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });

		// Create a new FormData instance
		const formData = new FormData();
		// Append all properties to FormData
		if (documents) {
			for (let i = 0; i < documents.length; i++) {
				formData.append('documents', documents[i]);
			}
		} else {
			formData.append('documents', documents);
		}
		formData.append('job_id', jobId);
		formData.append('reason', reason.value);
		formData.append('details', details);

		try {
			// Make an Axios request to the backend API endpoint for each file individually
			await axios
				.post(`${BASEURL}/api/disputes`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					onUploadProgress: (progressEvent) => {
						// Calculate the upload progress percentage
						const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
						// Update the uploadProgress state for the current file
						setUploadProgress(progress);
					},
				})
				.then(() => {
					button.current.disabled = false;
					document.querySelector('.createDisputeModalClose').click();
					reload();
					setAlert && setAlert('Dispute created successfully!');
				});
		} catch (error) {
			button.current.disabled = false;
			console.error(error.message);
			// Set general error
			if (errors.general === '')
				setErrors({ errors, general: error?.response?.data?.message || error.message });
		}
		// Reset the form
		setReason();
		setDetails('');
		setDocuments();
		setUploading(false);
		setUploadProgress(0);
	};

	return (
		<form className='disputessform needs-validation' onSubmit={handleSubmit}>
			{uploading ? (
				<div className='progress mb-3'>
					<div
						className='progress-bar progress-bar-striped progress-bar-animated'
						role='progressbar'
						style={{ width: `${uploadProgress}%` }}
						aria-valuenow={uploadProgress}
						aria-valuemin='0'
						aria-valuemax='100'
					>
						{uploadProgress}%
					</div>
				</div>
			) : (
				<>
					<div className='row'>
						<div className='col-md-12 mb-4'>
							<p className='text-start text-black mb-0'>
								We are sorry you aren't happy with the service provided to you on this project.
								Please fill out the information as detailed as you can below.
							</p>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-4 mb-3'>
							<CreatableSelect
								id='reason'
								className='p-0 basic-single'
								placeholder='Reason for dispute'
								options={disputeReasons}
								onChange={setReason}
								value={reason}
								required
								isClearable
							/>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-12 mb-3'>
							<TextArea
								inputName='form-control'
								defaultValue={details}
								placeholder='Add any details here...'
								name='details'
								fn={setDetails}
								row='10'
								required={true}
								showLabel={false}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-12 mb-3 text-start'>
							<label htmlFor='documents' className='form-label'>
								Attachments
							</label>
							<FileUploader
								classes='form-control w-100 mw-100'
								multiple={true}
								values={documents}
								handleChange={(files) => setDocuments(files)}
								name='documents'
								types={types}
								onTypeError={() => {
									setErrors({ ...errors, documents: true });
								}}
							/>
						</div>
					</div>
				</>
			)}
			{errors.general && errors.general !== '' && (
				<div className='row'>
					<div className='mb-3'>
						<div className='alert alert-danger' role='alert'>
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<div className='d-flex justify-content-between align-items-center'>
				<div className='btn btn-outline-primary' data-bs-dismiss='modal'>
					Cancel
				</div>
				<Button buttonRef={button} mainClass='btn btn-primary' buttonText='Submit' />
			</div>
		</form>
	);
}
