import { Link } from "react-router-dom";
import Modal from "../../Dashboard/Modal";
import DialpadModal from "./DialpadModal";
import { useDispatch } from "react-redux";
import { CALL_ACTION_TYPE } from "../../../Store/call/Type";
import allActions from "../../../Store/action";

export default function AudioCallModal({ callData, toggleModal, CallEnd, selectedReceiver,audioConnection }) {

    const dispatch = useDispatch();
	// const navigate = useNavigate()

    const hangUp = async () => {
        await CallEnd();
        // dispatch(allActions.global.Loader(true));
        // navigate('/dashboard/messages?reload')
        setTimeout(() => {
            // dispatch(allActions.firebase.resetPersonalConnection());
            // dispatch(allActions.firebase.resetReadPersonalChatsArray());
            dispatch(allActions.call.dispatchToStore({ type: CALL_ACTION_TYPE.videoCallRoomIdle }));
            toggleModal()
            // navigate('/dashboard/messages');
        }, 1000);
}

    return (
        
        <Modal
            title={"Audio Call"}
            bodyClass="p-4"
            btnText={"Audio Call"}
            trigger="AudioCallModal"
            showBtn={false}
            size={"sm"}
            className='centerclass'
            close={false}
            width={'400px'}
            sideClose={false}
            backdrop={false} // Prevent clicking on the backdrop from closing the modal
            keyboard={false}
        >
            <div className="container ">
                <div className="row ">
                    <div className="col-md-12">
                        <div className=" border-0">
                            <div className="">
                                <div className="text-center mb-4">
                                    <h2>{callData?.status}</h2>
                                    <p className="text-muted">Calling {selectedReceiver?.firstname + " " + selectedReceiver?.lastname}</p>
                                </div>
                                <DialpadModal hangUp={hangUp} connection={audioConnection} />
                                <Link
                                        data-bs-toggle="modal"
                                        data-bs-target="#AudioCallModal"
                                        aria-controls="AudioCallModal"
                                        className="AudioCallModalButton"
                                    >
                                    </Link>
                                <div className="text-center">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
