export const MEMBERSHIP_ACTION_TYPE = {
	// REDUCER TYPES
	registerMembership:'registerMembership',
	registerMembershipIdle:'registerMembershipIdle',
	getMembership:'getMembership',
	updateMembership:'updateMembership',
	updateMembershipPlan:'updateMembershipPlan',
	updateMembershipIdle:'updateMembershipIdle',
	updateMembershipPlanIdle:'updateMembershipPlanIdle',
	allMembershipPlan:'allMembershipPlan',
	createMembershipByAdmin:'createMembershipByAdmin',
	createMembershipByAdminIdle:'createMembershipByAdminIdle',
	getAllMembershipPlanByAdmin:'getAllMembershipPlanByAdmin',
	deleteMembershipPlan:'deleteMembershipPlan',
	deleteMembershipPlanIdle:'deleteMembershipPlanIdle',
};
