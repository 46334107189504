/* eslint-disable array-callback-return */
import React, { useState } from 'react'
import CloseIcon from '../../assets/img/close.svg'
import ArrowIcon from '../../assets/img/drop-down-arrow.svg'
import CircleIcon from '../../assets/img/circle.svg'
import ActiveCircleIcon from '../../assets/img/active-circle.svg'
import RightArrow from '../../assets/img/right-arrow.svg'
import { useDispatch } from 'react-redux'
import allActions from '../../Store/action'
import ReactSelect from 'react-select'



const OpenJobForm = ({setIsFormModalOpen,activeFormData,setActiveFormData}) => {
    const [activeSection,setActiveSection] = useState(Object.keys(activeFormData?.data)[0])
    const [activeSubSection,setActiveSubSection] = useState()
    const [activeQustionId,setActiveQuestionId] = useState('')
    const [activeDivider,setActiveDivider] = useState(0)
    const dispatch = useDispatch()



    const groupedData = activeFormData?.data[activeSection][activeSubSection]?.reduce((acc, item) => {
        if (item.type === 'divider') {
          acc.push({ header: item.question, children: [] });
        } else if (acc.length > 0) {
          acc[acc.length - 1].children.push(item);
        }
        return acc;
      }, []);

      const callTaxPreparation = (taxPreparationObj) => {
        dispatch(allActions.dashboard.resetTaxPreparation());
        dispatch(allActions.dashboard.taxPreparation({ obj: taxPreparationObj }));
      };

    const handleSubmit = () => {
      dispatch(allActions.dashboard.resetTaxPlanning());
      dispatch(allActions.dashboard.resetTaxPreparation());
        const taxPlanObj = {
          formData:activeFormData?.data,
          formName:activeFormData?.name,
          service_id:1,
          method:'upload'
        }
					callTaxPreparation(taxPlanObj);
          setIsFormModalOpen(null)
    }
      

  return (
    <div>
      <div className="d-flex justify-content-end cursor-pointer">
        <img
          src={CloseIcon}
          alt="img"
          onClick={() => setIsFormModalOpen(false)}
        />
      </div>
      <div className="bg-pimary d-flex gap-3 justify-content-between">
        <div className="col-md-4">
          <div className="form-name">{activeFormData?.name}</div>
          <div className="left-bar-cont">
            {Object.keys(activeFormData?.data)?.map((section, index) => {
              return (
                <div>
                  <div
                    className="d-flex gap-4 my-3 cursor-pointer"
                    onClick={() => setActiveSection(section)}
                  >
                    <img
                      className={`${
                        activeSection === section ? "arrow-down" : "arrow-right"
                      }`}
                      src={ArrowIcon}
                      alt="arrow-down"
                    />
                    <div className="section-title">{section}</div>
                  </div>
                  {activeSection === section &&
                    activeFormData?.data[section] &&
                    Object.keys(activeFormData?.data[section])?.map(
                      (subsection) => {
                        return (
                          <div
                            className="sub-section-title"
                            onClick={() => setActiveSubSection(subsection)}
                          >
                            {subsection}
                          </div>
                        );
                      }
                    )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-md-8">
          <div className="mt-5 customer-form-section-title">{activeSection}</div>
          <div className="form-questoins-cont">
            <div className="customer-form-subsection-title">{activeSubSection}</div>
            <div className='p-4'>
            {groupedData?.length > 0 && groupedData.map((group, index) => (
              <div key={index} className={`form-questoins-cont`} onClick={() => setActiveDivider(index)}>
              <div className='d-flex align-items-center py-2 px-3'>
                {group?.children?.find(field => field?.answer === '') ?  <img src={CircleIcon} alt='circle' /> : <img src={ActiveCircleIcon} alt='circle' />}
              <div className='p-3 segment-header cursor-pointer'>{group.header}</div>
              </div>
                <div className={`${activeDivider === index ? 'd-flex' : 'd-none'}  flex-column flex-wrap `}>
                  {group.children.map((child, i) => {
                    if(child?.type === 'text-one-line'){
                      return <div className='customer-form-input-cont gap-3' onClick={() => setActiveQuestionId(child?.id)}>
                          <label>{child?.question}</label>
                          <input  onChange={(e) => {
                            const updatedForm = {...activeFormData.data}
                            updatedForm[activeSection][activeSubSection] = updatedForm[activeSection][activeSubSection]?.map((question) => {
                                if(question?.id === activeQustionId ){
                                  return {
                                      ...question,
                                      answer:e.target.value
                                  }
                                }
                                return question
                            }
                          )
                          setActiveFormData((prev) => {
                            return {...prev,data:updatedForm}
                          } )
                          } } type='text' placeholder='Enter your answer here ...' />
                      </div>
                    }
                    if(child?.type === 'text-multi-line'){
                      return <div className='customer-form-input-cont gap-3' onClick={() => setActiveQuestionId(child?.id)}>
                          <label>{child?.question}</label>
                          <textarea  onChange={(e) => {
                            const updatedForm = {...activeFormData.data}
                            updatedForm[activeSection][activeSubSection] = updatedForm[activeSection][activeSubSection]?.map((question) => {
                                if(question?.id === activeQustionId ){
                                  return {
                                      ...question,
                                      answer:e.target.value
                                  }
                                }
                                return question
                            }
                          )
                          setActiveFormData((prev) => {
                            return {...prev,data:updatedForm}
                          } )
                          } } type='text' placeholder='Enter your answer here ...' ></textarea>
                      </div>
                    }
                    if(child?.type === 'text-email'){
                      return <div className='customer-form-input-cont gap-3' onClick={() => setActiveQuestionId(child?.id)}>
                          <label>{child?.question}</label>
                          <input  onChange={(e) => {
                            const updatedForm = {...activeFormData.data}
                            updatedForm[activeSection][activeSubSection] = updatedForm[activeSection][activeSubSection]?.map((question) => {
                                if(question?.id === activeQustionId ){
                                  return {
                                      ...question,
                                      answer:e.target.value
                                  }
                                }
                                return question
                            }
                          )
                          setActiveFormData((prev) => {
                            return {...prev,data:updatedForm}
                          } )
                          } } type='email' placeholder='Enter your email here ...' />
                      </div>
                    }
                    if(child?.type === 'number-any-number'){
                      return <div className='customer-form-input-cont gap-3' onClick={() => setActiveQuestionId(child?.id)}>
                          <label>{child?.question}</label>
                          <input  onChange={(e) => {
                            const updatedForm = {...activeFormData.data}
                            updatedForm[activeSection][activeSubSection] = updatedForm[activeSection][activeSubSection]?.map((question) => {
                                if(question?.id === activeQustionId ){
                                  return {
                                      ...question,
                                      answer:e.target.value
                                  }
                                }
                                return question
                            }
                          )
                          setActiveFormData((prev) => {
                            return {...prev,data:updatedForm}
                          } )
                          } } type='number' placeholder='Enter your answer here ...' />
                      </div>
                    }
                    if(child?.type === 'date'){
                      return <div className='customer-form-input-cont gap-3' onClick={() => setActiveQuestionId(child?.id)}>
                          <label>{child?.question}</label>
                          <input  onChange={(e) => {
                            const updatedForm = {...activeFormData.data}
                            updatedForm[activeSection][activeSubSection] = updatedForm[activeSection][activeSubSection]?.map((question) => {
                                if(question?.id === activeQustionId ){
                                  return {
                                      ...question,
                                      answer:e.target.value
                                  }
                                }
                                return question
                            }
                          )
                          setActiveFormData((prev) => {
                            return {...prev,data:updatedForm}
                          } )
                          } } type='date' placeholder='Enter your answer here ...' />
                      </div>
                    }
                    if(child?.type?.includes('dropdown')){
                      const options =   child?.questionData?.map((state) => ({
                        value: state,
                        label: state,
                      }))
                      return <div className='customer-form-input-cont gap-3' onClick={() => setActiveQuestionId(child?.id)}>
                          <label>{child?.question}</label>
                          <ReactSelect
                         className="input-cont"
                           options={options}
                         />
                      </div>
                    }
                    if(child?.type?.includes('yes-no')){
                      return <div className='customer-form-input-cont gap-3' onClick={() => setActiveQuestionId(child?.id)}>
                          <label>{child?.question}</label>
                          <div className="d-flex align-items-center gap-2">
                        <div className="d-flex gap-3">
                            {child?.questionData?.map((answer) => {
                              return <div className="yes-no-cont">{answer}</div>
                            })}
                        </div>
                      </div>
                      </div>
                    }
                    

                    


                    }
                    
                    
                    
                    
                    )}
                </div>
              </div>
            ))}

            {groupedData?.length === 0 && activeSection && activeSubSection &&  activeFormData?.data[activeSection][activeSubSection]?.map((form) => {
              if(form?.type === 'text-one-line'){
                      return <div className='customer-form-input-cont gap-3' onClick={() => setActiveQuestionId(form?.id)}>
                          <label>{form?.question}</label>
                          <input  onChange={(e) => {
                            const updatedForm = {...activeFormData.data}
                            updatedForm[activeSection][activeSubSection] = updatedForm[activeSection][activeSubSection]?.map((question) => {
                                if(question?.id === activeQustionId ){
                                  return {
                                      ...question,
                                      answer:e.target.value
                                  }
                                }
                                return question
                            }
                          )
                          setActiveFormData((prev) => {
                            return {...prev,data:updatedForm}
                          } )
                          } } type='text' placeholder='Enter your answer here ...' />
                      </div>
                    }
                    if(form?.type === 'text-multi-line'){
                      return <div className='customer-form-input-cont gap-3' onClick={() => setActiveQuestionId(form?.id)}>
                          <label>{form?.question}</label>
                          <textarea  onChange={(e) => {
                            const updatedForm = {...activeFormData.data}
                            updatedForm[activeSection][activeSubSection] = updatedForm[activeSection][activeSubSection]?.map((question) => {
                                if(question?.id === activeQustionId ){
                                  return {
                                      ...question,
                                      answer:e.target.value
                                  }
                                }
                                return question
                            }
                          )
                          setActiveFormData((prev) => {
                            return {...prev,data:updatedForm}
                          } )
                          } } type='text' placeholder='Enter your answer here ...' ></textarea>
                      </div>
                    }
                    if(form?.type === 'text-email'){
                      return <div className='customer-form-input-cont gap-3' onClick={() => setActiveQuestionId(form?.id)}>
                          <label>{form?.question}</label>
                          <input  onChange={(e) => {
                            const updatedForm = {...activeFormData.data}
                            updatedForm[activeSection][activeSubSection] = updatedForm[activeSection][activeSubSection]?.map((question) => {
                                if(question?.id === activeQustionId ){
                                  return {
                                      ...question,
                                      answer:e.target.value
                                  }
                                }
                                return question
                            }
                          )
                          setActiveFormData((prev) => {
                            return {...prev,data:updatedForm}
                          } )
                          } } type='email' placeholder='Enter your email here ...' />
                      </div>
                    }

                    if(form?.type === 'number-any-number'){
                      return <div className='customer-form-input-cont gap-3' onClick={() => setActiveQuestionId(form?.id)}>
                          <label>{form?.question}</label>
                          <input  onChange={(e) => {
                            const updatedForm = {...activeFormData.data}
                            updatedForm[activeSection][activeSubSection] = updatedForm[activeSection][activeSubSection]?.map((question) => {
                                if(question?.id === activeQustionId ){
                                  return {
                                      ...question,
                                      answer:e.target.value
                                  }
                                }
                                return question
                            }
                          )
                          setActiveFormData((prev) => {
                            return {...prev,data:updatedForm}
                          } )
                          } } type='number' placeholder='Enter your answer here ...' />
                      </div>
                    }
                    if(form?.type === 'date'){
                      return <div className='customer-form-input-cont gap-3' onClick={() => setActiveQuestionId(form?.id)}>
                          <label>{form?.question}</label>
                          <input  onChange={(e) => {
                            const updatedForm = {...activeFormData.data}
                            updatedForm[activeSection][activeSubSection] = updatedForm[activeSection][activeSubSection]?.map((question) => {
                                if(question?.id === activeQustionId ){
                                  return {
                                      ...question,
                                      answer:e.target.value
                                  }
                                }
                                return question
                            }
                          )
                          setActiveFormData((prev) => {
                            return {...prev,data:updatedForm}
                          } )
                          } } type='date' placeholder='Enter your answer here ...' />
                      </div>
                    }
                    if(form?.type.includes('dropdown')){
                      const options =   form?.questionData?.map((state) => ({
                        value: state,
                        label: state,
                      }))
                      return <div className='customer-form-input-cont gap-3' onClick={() => setActiveQuestionId(form?.id)}>
                          <label>{form?.question}</label>
                          <ReactSelect
                         className="input-cont"
                           options={options}
                         />
                      </div>
                    }
                    if(form?.type?.includes('yes-no')){
                      return <div className='customer-form-input-cont gap-3' onClick={() => setActiveQuestionId(form?.id)}>
                          <label>{form?.question}</label>
                          <div className="d-flex align-items-center gap-2">
                        <div className="d-flex gap-3">
                            {form?.questionData?.map((answer) => {
                              return <div className="yes-no-cont">{answer}</div>
                            })}
                        </div>
                      </div>
                      </div>
                    }
            })}
            </div>
            <div className='d-flex justify-content-end px-5 gap-3 '>
                <button className='btn btn-primary p-2 fw-bold gap-2 mb-3'>Continue <img src={RightArrow} alt='arrow' /></button>
                <button className='btn btn-primary p-2 fw-bold gap-2 mb-3' onClick={handleSubmit}>Submit <img src={RightArrow} alt='arrow' /></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenJobForm

