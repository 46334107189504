import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// Data
import data from '../../utilites/FAQ.json';

const Faq = ({ isFaqPage }) => {
	const [faqData, setFaqData] = useState([]);
	
	useEffect(() => {
		if (!isFaqPage) {
			setFaqData(data.slice(0, 5));
		} else {
			setFaqData(data);
		}
	}, [isFaqPage]);

	return (
		<section className="clearfix bg-light py-5 px-3">
			<div className="container">
				<div className="clearfix w-75 mx-auto">
					<div className="clearfix text-center mb-3">
						<h1 className="display-3 text-primary fw-bold">Frequently asked questions</h1>
					</div>
					<div className="accordion px-0 px-md-5" id="accordionExample">
						{faqData?.map((item) => (
							<div
								className="accordion-item border-bottom border-top-0 border-start-0 border-end-0 border-2 border-primary-300"
								key={item.id}
							>
								<h4 className="accordion-header">
									<button
										className={`accordion-button text-dark bg-light ps-0 py-4 shadow-none${
											item.id !== 1 ? ' collapsed' : ''
										}`}
										type="button"
										data-bs-toggle="collapse"
										data-bs-target={`#collapse-${item.id}`}
										aria-expanded={item.id === 1 ? 'true' : 'false'}
										aria-controls={`#collapse-${item.id}`}
									>
										{item.question}
									</button>
								</h4>
								<div
									id={`collapse-${item.id}`}
									className={`accordion-collapse collapse${item.id === 1 ? ' show' : ''}`}
									data-bs-parent="#accordionExample"
								>
									<div className="accordion-body bg-light text-dark px-0 pb-5">
										{item.answer?.map((a, index) => (
											<p key={index}>{a}</p>
										))}
									</div>
								</div>
							</div>
						))}
					</div>
					<div className="clearfix mt-4 text-center">
						{isFaqPage ? (
							<>
								<h3 className="text-dark fs-3 fw-bold">Ready to get started?</h3>
								<Link
									to="/register"
									className="btn btn-sm btn-primary fs-6 fw-semibold px-5 py-2 mt-3"
								>
									Get matched with a tax expert
								</Link>
							</>
						) : (
							<>
								<Link to="/faq" className="btn btn-link d-block fs-5 fw-semibold">
									More frequently asked questions
								</Link>
								<a href="/#leadForm" className="btn btn-primary fs-5 fw-semibold px-5 py-2 mt-3">
									Get started today
								</a>
							</>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Faq;
