import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCe2FlwUegquE3IUTS_DdfIq1Elh_C03nk",
  authDomain: "tpghq-1f143.firebaseapp.com",
  projectId: "tpghq-1f143",
  storageBucket: "tpghq-1f143.appspot.com",
  messagingSenderId: "33505303434",
  appId: "1:33505303434:web:6be0c2c7aaaa316de20673",
  measurementId: "G-P1YC2B05KF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);