import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { HandleFormData } from '../../../../utilites';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';


const PurchaseAssets = () => {
    const { PurchaseAssetsAnswers } = GLOBAL_VARIABLES;
    
    const dispatch=useDispatch()
	const [{ taxPayerForm, taxPayerFormData,formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 16 });

    const [showAnswers, setShowAnswers] = useState(null)
    const [specificQuestions, setSpecificQuestions] = useState([]);
    const [errors, setErrors] = useState({}); // Track errors for form fields


    useEffect(() => {
        setSpecificQuestions(JSON.parse(JSON.stringify(PurchaseAssetsAnswers)));
    }, [PurchaseAssetsAnswers]);

    const handleInputChange = (index, value, data, valIdx) => {
        const filedKey = Object.keys(data).find(key => key !== 'placeholder' && key !== 'styleProp');
        const updatedQuestions = [...specificQuestions];
        updatedQuestions[index].values[valIdx][filedKey] = value;
        setSpecificQuestions(updatedQuestions);
    };

    
    useEffect(() => {
    	if (taxPayerForm?.data?.PurchaseAssets) {
    		if (Object.keys(taxPayerForm?.data?.PurchaseAssets).length === 0) return;
            const {
                showAnswers,
                specificQuestions
            } = taxPayerForm?.data?.PurchaseAssets;

            setShowAnswers(showAnswers);
            setSpecificQuestions(specificQuestions)
    	}
    }, [taxPayerForm?.data?.PurchaseAssets]);

    const check_Validity = () => {
        setErrors({});
        const newErrors = {};
    
        // Add more validation rules as needed for other fields
        if (
            showAnswers===null) {
            newErrors.AnswerOtherQuestions = 'Please Select Required Option';
        }
    
        setErrors(newErrors);
    
        let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
        // Check if there are any errors
        return ConditionCheck;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = check_Validity();
        if (errors) return;
             // Handle form submission here
             let FormObj = {
                showAnswers,
                  specificQuestions
              };
              let formData = taxPayerForm;
              taxPayerForm.data.PurchaseAssets = FormObj;
              dispatch(allActions.taxForms.StoreFormData({ formData }));
              uploadFormData(formData);
    };

    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 16;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            {/* <small className="ml-4 fw-bold">General Information</small>
            <hr className="m-0" /> */}
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                {specificQuestions.map((data, i) => {
                    return (<div key={i} className="col-md-12 mb-3">
                        <small className="ml-4 fw-bold">
                            {data.question}
                            {showAnswers===null && (
                            <span className="text-danger small-text mx-1">*Required</span>
                        )}
                        </small>
                        <div className="col-md-12">
                            <input
                                onChange={() => setShowAnswers(true)}
                                checked={showAnswers === true}
                                className="form-check-input"
                                type="radio"
                                id={`checkbox-${i + 176}`}
                                name={`checkbox-${i + 176}`}
                            />
                            <label htmlFor={`checkbox-${i + 176}`} role="button" className="px-3 m-0 small">
                                Yes
                            </label>
                            <input
                                onChange={() => setShowAnswers(false)}
                                checked={showAnswers === false}
                                className="form-check-input"
                                type="radio"
                                id={`checkbox-${i + 999}`}
                                name={`checkbox-${i + 999}`}
                            />
                            <label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
                                No
                            </label>
                        </div>
                        {
                            showAnswers && (
                                <div className='row'>
                                    {data?.values?.map((data, index) => {
                                        return (
                                            <div className={`my-1 ${data.styleProp}`}>
                                                <Input
                                                    type="text"
                                                    value={specificQuestions[i].values[index][Object.keys(data).find(key => key !== 'placeholder' && key !== 'styleProp')]}
                                                    placeholder={data.placeholder}
                                                    fn={(value) => handleInputChange(i, value, data, index)}
                                                />
                                            </div>)
                                    })}
                                </div>
                            )
                        }
                    </div>)
                })}
            </div>
            {Object.keys(errors)
    ?.map((key) => {
        return errors[key];
    })
    ?.map((data, index) => {
        return (
            <>
                <small className="text-primary" key={index}>
                    {data}*
                </small>
                <br />
            </>
        );
    })}
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="button"
                        callback={()=>goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default PurchaseAssets;
