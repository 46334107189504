/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker'
import allActions from '../../../Store/action';
import Message from '../../../components/forms/fields/Message';
import { MEMBERSHIP_ACTION_TYPE } from '../../../Store/membership/Type';
import MembershipCards from '../../../components/Dashboard/billing/memberShipCards';
import moment from 'moment';

const UpgradeMembershipTable = ({ user, setMessage }) => {

    const dispatch = useDispatch();
    const [trialStart, setTrialStart] = useState(new Date());
    const [trialEnd, setTrialEnd] = useState(new Date());
    const [selectedCard, setSelectedCard] = useState()
    const [upgradeMessage, setUpgradeMessage] = useState({ show: false })
    const [{ createMembershipByAdmin, allMembershipPlan }] = useSelector((state) => [state.membership]);
    const updateCardBGColor = (selectedCardIndex) => setSelectedCard(selectedCardIndex)


    useEffect(() => {
        dispatch(allActions.membership.allMembershipPlan());
    }, [dispatch])

    useEffect(() => {
        if (!createMembershipByAdmin.status) {
            dispatch(allActions.global.Loader(false));
            setUpgradeMessage({
                show: true,
                type: 'Error',
                text: createMembershipByAdmin.message,
            });
            dispatch({ type: MEMBERSHIP_ACTION_TYPE.createMembershipByAdminIdle });
        }
        if (createMembershipByAdmin.status === true) {
            setMessage({
                show: true,
                type: 'Success',
                text: createMembershipByAdmin.message,
            });
            reset_States()
            dispatch(allActions.global.Loader(false));
            document.querySelector('.upgradeMembershipbtn').click()
            dispatch(allActions.membership.getMembership(user.id))
            dispatch({ type: MEMBERSHIP_ACTION_TYPE.createMembershipByAdminIdle });
        }
    }, [createMembershipByAdmin.status]);
    // 

    const convertDate = (dateFormat) => {
        const formattedDate = moment(dateFormat).format('YYYY-MM-DD');
        return formattedDate;
    };
    const reset_States = () => {
        setTrialStart(new Date())
        setTrialEnd(new Date())
        setSelectedCard()
        setUpgradeMessage({ show: false })
    }

    const handleSubmit = () => {
        if (selectedCard === undefined) return setUpgradeMessage({ show: true, type: 'Error', text: 'Please Select Card' })

        const membership = {
            "userId": user?.id,
            "memershipId": selectedCard + 1,
            "trialStart": convertDate(trialStart),
            "trialEnd": convertDate(trialEnd)
        }
        setMessage({ show: false })
        setUpgradeMessage({ show: false });
        dispatch(allActions.global.Loader(true));
        dispatch({ type: MEMBERSHIP_ACTION_TYPE.createMembershipByAdminIdle });
        dispatch(allActions.membership.createMembershipByAdmin(membership));
    }
    return (
        <div>
            {upgradeMessage.show ? <Message message={upgradeMessage} /> : <></>}
            <div className='row'>
                <div className='container d-flex align-items-center justify-content-center'>
                    <div className='col-lg-5 col-md-8 col-sm-12 d-flex align-items-center flex-column justify-content-center'>
                        <h1 className='fw-bold text-black'>Choose Your Plan</h1>
                        {/* <p className='text-dark text-center text-muted mt-2 fw-bold px-4'>help cursor to provide additional context on hover and to users of assistive technologies.</p> */}
                    </div>
                </div>
                <div className='col-sm-12 d-flex flex-row align-items-center justify-content-between flex-wrap'>
                    <p className='col-lg-6 col-md-12 col-sm-12 text-muted'>Select Trial start and End Date</p>
                    <div className='col-lg-6 col-md-12 col-sm-12 d-flex flex-row align-items-center justify-content-around'>
                        <DatePicker className="form-select" style={{ width: 100 }} wrapperClassName="d-block" selected={trialStart}
                            onChange={(date) => setTrialStart(date)}
                            dateFormat="MMMM d, yyyy" placeholderText="Trial Start" />

                        <DatePicker className="form-select" style={{ width: 100 }} wrapperClassName="d-block" selected={trialEnd}
                            onChange={(date) => setTrialEnd(date)}
                            dateFormat="MMMM d, yyyy" placeholderText="Trial End" />
                    </div>
                </div>
                <div className='container my-3'>
                    <div className="row">
                    
                                          {allMembershipPlan?.data?.length >0 && allMembershipPlan.data.map((card, index) => {
                            const DBData = allMembershipPlan?.data?.find((data) => data.id === index + 1)
                            return (<MembershipCards card={card} DBData={DBData} index={index} selectedCard={selectedCard}  updateCardBGColor={updateCardBGColor} />)

                        })}
                    </div>
                </div>
            </div>

            <div className='col-12 d-flex align-items-center justify-content-end'>
                <button type="button" className="btn btn-primary" onClick={() => { handleSubmit() }}>
                    Submit
                </button>
            </div>
        </div>
    )
}

export default UpgradeMembershipTable