import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactSelect from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { BASEURL, getStatesList } from '../../utilites';
import Input from './fields/Input';
import Checkbox from './fields/Checkbox';
import Button from './fields/Button';
import allActions from '../../Store/action';

const annualIncomes = [
	{ id: 1, name: '$12,000-$49,999' },
	{ id: 2, name: '$50,000-$99,999' },
	{ id: 3, name: '$100,000-$249,999' },
	{ id: 4, name: '$250,000-$499,999' },
	{ id: 5, name: '$500,000+' },
];

const UsaStatesList = getStatesList();

export default function RequestJobForm({ data, user, reload }) {
	const [{ services }] = useSelector((state) => [state.dashboard]);
	const [phone, setPhone] = useState('');
	const [zipcode, setZipcode] = useState('');
	const [options, setOptions] = useState([]);
	const [income, setIncome] = useState([]);
	const [city, setCity] = useState('');
	const [state, setState] = useState({});
	const [street, setStreet] = useState('');
	const [street2, setStreet2] = useState('');
	const [assignToDifferentTaxPro, setAssignToDifferentTaxPro] = useState(false);
	const [step, setStep] = useState(1);
	const [errors, setErrors] = useState({
		phone: false,
		zipcode: false,
		options: false,
		income: false,
		city: false,
		state: false,
		street: false,
		street2: false,
		assignToDifferentTaxPro: false,
		general: '',
	});
	const dispatch = useDispatch();
	const button = useRef();

	useEffect(() => {
		dispatch(allActions.dashboard.getServices());
	}, [dispatch]);

	useEffect(() => {
		if (data) {
			setPhone(data.phone);
			setZipcode(data.zipcode);
			if (data.city) setCity(data.city);
			if (data.state) setState(UsaStatesList.find((s) => s.value === data.state) || null);
			if (data.street) setStreet(data.street);
			if (data.street2) setStreet2(data.street2);
		}
	}, [data]);

	// function to handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();
		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });
		axios
			.post(`${BASEURL}/api/leads`, {
				firstname: user.firstname,
				lastname: user.lastname,
				email: user.email,
				phone: phone.split(/[ ()-]/).join(''),
				zipcode,
				options,
				income,
				city,
				state: state?.value || '',
				street,
				street2,
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				...(data && data.userpro.pro_number && !assignToDifferentTaxPro
					? {
							taxpro_number: data.userpro.pro_number,
					  }
					: {}),
			})
			.then(() => {
				button.current.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...`;
			})
			.then(() => {
				// Reload dashboard data
				reload && reload();
				// Close the modal
				document.querySelector('.requestJobModalClose').click();
				// Reload the page
				window.location.reload(); // TODO: Remove this line
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({
						...errors,
						general: error?.response?.data?.message || error.message,
						email: !error?.response?.data?.isEmailValid || false,
						phone: !error?.response?.data?.isPhoneValid || false,
					});
			});
	};

	const handleNextStep = () => {
		if (Object.values(errors).every((error) => !error)) {
			setStep((prevStep) => prevStep + 1);
		} else {
			setErrors({ ...errors, general: 'Please fill all the required fields' });
		}
	};

	const handlePreviousStep = () => {
		setStep((prevStep) => prevStep - 1);
	};

	return (
		<div>
			{/* <form className='leadsform needs-validation' onSubmit={handleSubmit}>
				<Button
					buttonRef={button}
					mainClass={`btn btn-primary btn-lg px-4${
						Object.values(errors).indexOf(true) > -1 ? ' disabled' : ''
					}`}
					wrapperClass='clearfix w-75 mx-auto d-grid text-center mt-3'
					buttonText='Request job'
				/>
			</form> */}
			<form className="leadsform needs-validation" onSubmit={handleSubmit}>
				{step === 1 && (
					<>
						{services && (
							<div className="clearfix">
								<div className="clearfix mt-3 mb-4">
									<h4 className="text-center">What services are you interested in?</h4>
								</div>
								<div className="row mt-3">
									{services?.map((service) => (
										<div key={service.id} className="col-md-4 mb-3">
											<Checkbox
												name="options"
												state={options}
												value={service.id}
												label={service.name}
												fn={setOptions}
												id={`option-${service.id}`}
												required={false}
											/>
										</div>
									))}
								</div>
							</div>
						)}
						{annualIncomes && (
							<div className="clearfix">
								<div className="clearfix mt-3 mb-4">
									<h4 className="text-center">What is your annual income?</h4>
								</div>
								<div className="row justify-content-center mt-3">
									{annualIncomes?.map((ai) => (
										<div key={ai.id} className="col-md-4 mb-3">
											<Checkbox
												type="radio"
												wrapperClass="mb-2 text-start"
												name="incomeRadios"
												state={income}
												value={ai.id}
												label={ai.name}
												fn={setIncome}
												id={`ai-radio-${ai.id}`}
												required={true}
											/>
										</div>
									))}
								</div>
							</div>
						)}
						{data ? (
							<div className="clearfix my-3">
								<div className="form-check form-check-inline">
									<label className="form-check-label" htmlFor="assignToDifferentTaxPro">
										<input
											className="form-check-input custom-checkbox"
											type="checkbox"
											id="assignToDifferentTaxPro"
											checked={assignToDifferentTaxPro}
											onChange={(e) => setAssignToDifferentTaxPro(e.target.checked)}
										/>
										Assign to different tax pro?
									</label>
								</div>
							</div>
						) : null}
						<Button
							mainClass={`btn btn-outline-primary${
								options.length < 1 || income.length < 1 ? ' disabled' : ''
							} px-4`}
							callback={handleNextStep}
							buttonText="Next"
						/>
					</>
				)}
				{step === 2 && (
					<>
						<div className="row">
							<div className="col-md-12 mb-3">
								<Input
									type="text"
									value={phone}
									name="phone"
									placeholder="Phone Number"
									fn={setPhone}
									onLeave={() => {
										if (!errors.phone && phone.length > 1 && phone.length < 14) {
											setErrors({ ...errors, phone: true });
										} else if (errors.phone) {
											setErrors({ ...errors, phone: false });
										}
									}}
									hasValidationError={errors.phone}
									mask="(999) 999-9999"
									showLabel={false}
									required={true}
								/>
							</div>
						</div>
						<fieldset className="border-top p-1 mt-3">
							<legend className="fs-6 float-none w-auto px-2 mx-2 text-start">
								<small>Full Address</small>
							</legend>
						</fieldset>
						<div className="row">
							<div className="col-md-9 mb-3">
								<Input
									type="text"
									value={street}
									name="street"
									placeholder="Street"
									fn={setStreet}
									hasValidationError={errors.street}
									showLabel={false}
									required={true}
								/>
							</div>
							<div className="col-md-3 mb-3">
								<Input
									type="text"
									value={street2}
									name="street2"
									placeholder="Apt/Suite"
									fn={setStreet2}
									hasValidationError={errors.street2}
									showLabel={false}
									required={true}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-5 mb-3">
								<Input
									type="text"
									value={city}
									name="city"
									placeholder="City"
									fn={setCity}
									hasValidationError={errors.city}
									showLabel={false}
									required={true}
								/>
							</div>
							<div className="col-md-4 mb-3">
								<ReactSelect
									id="state"
									className="p-0 basic-single"
									placeholder="State"
									closeMenuOnSelect={true}
									options={UsaStatesList}
									onChange={setState}
									value={state}
								/>
							</div>
							<div className="col-md-3 mb-3">
								<Input
									type="text"
									value={zipcode}
									name="zipcode"
									placeholder="ie. 93882"
									label="Zip Code"
									fn={setZipcode}
									onLeave={() => {
										if (
											!errors.zipcode &&
											zipcode.length > 1 &&
											(zipcode.length < 5 || zipcode.length > 9)
										) {
											setErrors({ ...errors, zipcode: true });
										} else if (errors.zipcode) {
											setErrors({ ...errors, zipcode: false });
										}
									}}
									hasValidationError={errors.zipcode}
									showLabel={false}
									mask="99999999"
									required={true}
								/>
							</div>
						</div>
						<div className="d-flex justify-content-center mt-3">
							<Button
								mainClass="btn btn-outline-primary me-3"
								callback={handlePreviousStep}
								buttonText="Previous"
							/>
							<Button
								buttonRef={button}
								mainClass={`btn btn-primary${
									Object.values(errors).indexOf(true) > -1 ||
									!phone ||
									!city ||
									!state ||
									!zipcode ||
									!street ||
									!street2
										? ' disabled'
										: ''
								}`}
								buttonText="Request job"
							/>
						</div>
					</>
				)}
				{errors.general && errors.general !== '' && (
					<div className="row">
						<div className="mb-3">
							<div className="alert alert-danger" role="alert">
								{errors.general}
							</div>
						</div>
					</div>
				)}
			</form>
		</div>
	);
}
