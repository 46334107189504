/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import allActions from "../Store/action";
import Announcement from "../pages/dashboard/components/Announcement";
import { BASEURL } from "../utilites";
import UpdatePassword from "../components/forms/UpdatePassword";
import Modal from "../components/Dashboard/Modal";
import { DASHBOARD_ACTION_TYPE } from "../Store/dashboard/Type";

import Navbar from "../pages/dashboard/components/Navbar";

export default function DashboardLayout() {
	// --------------------------------------------
	// React States , Variable , Build in Redux
	// --------------------------------------------
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [customerModal,setCustomerModal]=useState()
	const [user, {customerModalDone, profile }] = useSelector((state) => [state.user, state.dashboard]);
	const conditionCheckForPasswordModal =
		user?.LoginData?.isCustomer &&
		!user?.LoginData?.isAdmin &&
		!user?.LoginData?.settings?.firstTimeCustomerUpdatePassword &&
		!user?.LoginData?.impersonation &&
		!customerModalDone && !customerModal
	// --------------------------------------------
	// HOOKS TO HANDLE RESPONSES OF API's
	// --------------------------------------------
	useEffect(() => {
		if (localStorage.getItem("token") && user.loginStatus === false && user.error === "") {
			dispatch(allActions.user.ReGetUserData());
		}
		if (user.error === "Invalid Token") {
			dispatch(allActions.user.logout());
			dispatch(allActions.dashboard.reset());
			dispatch(allActions.firebase.reset());
		}
	}, [user, dispatch]);

	useEffect(() => {
		// if Profile Update Successfully !

		if (profile?.state === true  && conditionCheckForPasswordModal) {
			document.getElementById("updateCustomerPasswordModal").click();
			dispatch({
				type: DASHBOARD_ACTION_TYPE.PROFILEIDLE,
			});
		}
	}, [profile.state, conditionCheckForPasswordModal]);

	useEffect(() => {
		if (conditionCheckForPasswordModal) {
			dispatch(allActions.global.Loader(false));
			updateCustomerStatus();
			document.getElementById("updateCustomerPasswordModal").click();
			setCustomerModal(true)
		}
	}, [conditionCheckForPasswordModal]);
	
	// --------------------------------------------------
	// Local Functions to handle local States , Call Apis
	// --------------------------------------------------
	const stopImpersonation = () => {
		axios
			.delete(`${BASEURL}/api/users/impersonate`)
			.then(() => {
				// Reset Messages Store To Get Data Again
				dispatch(allActions.firebase.resetPersonalConnection());
				dispatch(allActions.firebase.resetReadPersonalChatsArray());
				// Reset global state for dashboard
				dispatch(allActions.dashboard.reset());
				// Reset global state for firebase
				dispatch(allActions.firebase.reset());
				// Reset global state for user and re-fetch
				dispatch(allActions.user.removeUserData());
				dispatch(allActions.user.ReGetUserData());
				dispatch(allActions.jobs.resetJobs());
				dispatch(allActions.invoice.resetPayInvoice());

			})
			.then(() => navigate("/dashboard/users"))
			.catch((error) => console.error(error));
	};

	const updateCustomerStatus = () => {
		const profileObj = {
			settings: {
				...user?.LoginData?.settings,
				firstTimeCustomerUpdatePassword: true,
			},
		};
		dispatch(allActions.dashboard.updateProfile({ uuid: user?.LoginData?.uuid, profileObj }));
		// Call Function To Track Of Assigning of Tax Pro
		dispatch(allActions.dashboard.assignRandomTaxProTracker());
		// Store Response That it Already Done Because We Are Not Getting Data From Database After Updating
		dispatch({type:DASHBOARD_ACTION_TYPE.customerModalDone})
	};
	const isAllWeekUnavailable = () => {
		let data = user?.LoginData;
		const role = user?.LoginData?.roles[0]?.name;
		let allNull = false;
		if (role === "taxpro") {
		  const objData = data?.availability?.time_ranges;
		if(objData){
			allNull = Object.values(objData).every((value) => value === null);
		}
		} else if (role === "customer") {
		  const objData = data?.lead?.[0]?.userpro?.user.availability?.time_ranges;
		  allNull = Object.values(objData).every((value) => value === null);
		}
		if (allNull) {
		  return true;
		}
		return false;
	  };

		if(!user.loginStatus || !user.LoginData) return
		
	return (
		<div className="dashboard">
			<main className="clearfix w-100">
				{user?.LoginData?.impersonation && user?.LoginData?.impersonation !== null ? (
					<Announcement
						condition={true}
						buttonEnabled={true}
						alertClass="warning d-flex justify-content-between py-2"
						mainClass="clearfix w-100 mx-auto"
						containerClass="container"
						title="User impersonation is Activated!"
						details={`You are currently impersonating ${user?.LoginData?.firstname} ${user?.LoginData?.lastname}. Click the button below to stop impersonating`}
						buttonLink={null}
						buttonText="Stop Impersonation"
						buttonClass="badge bg-dark py-2 text-decoration-none px-3"
						clickHandler={stopImpersonation}
					/>
				) : (user?.LoginData?.isTaxPro) && (
					<></>
				)}
				{ user?.LoginData?.isTaxPro &&  isAllWeekUnavailable() &&
					<Announcement
						condition={true}
						buttonEnabled={false}
						alertClass="primary d-flex justify-content-between text-center py-2"
						mainClass="clearfix w-100 mx-auto"
						containerClass="container"
						title="Tax Professional Schedular"
						details={<>- Hi {user?.LoginData?.firstname}, Please adjust your available appointment times on the {<span onClick={()=>navigate('/dashboard/settings',{state:{openModal:'.userAvailablityForm'}})}><Link>Settings Page</Link>.</span>}</>}
						buttonLink={null}
						buttonText="Edit Availability"
						buttonClass="badge bg-dark py-2 text-decoration-none px-3"
						clickHandler={stopImpersonation}
					/>
				}
				<Navbar />
				<div className="container">
					<Outlet />
				</div>
			</main>
			<Modal
				title="Change Password"
				bodyClass="p-4"
				btnText="Update Password"
				trigger="updateCustomerPassword"
				showBtn={false}
			>
				<h4 className="text-center w-100">Please set a new Password</h4>
				<p className="text-muted text-center w-100">
					Please make sure to set your new password in order to complete setting up your account
				</p>
				<UpdatePassword data={user?.LoginData} oldPassword={false} />
			</Modal>
			<Link
				to="#"
				id="updateCustomerPasswordModal"
				className="text-decoration-none text-dark"
				data-bs-toggle="modal"
				data-bs-target="#updateCustomerPassword"
			></Link>
		</div>
	);
}
