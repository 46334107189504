import { Outlet, Link } from 'react-router-dom';

import Logo from '../assets/img/logo.png';

export default function AuthLayout() {
	return (
		<div className='authentication'>
			<main className='bg-light-gradient min-vh-100'>
				<div className='clearfix bg-white text-center border-bottom border-1 p-2 mb-5'>
					<Link to='/'>
						<img src={Logo} alt='Logo' width={280} />
					</Link>
				</div>
				<Outlet />
			</main>
		</div>
	);
}
