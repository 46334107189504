/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import {
	BASEURL,
	capitalizeFirstLetter,
	getTaxproStatus,
	getTaxproStatusClass,
} from '../../../utilites';
import { FiEdit } from 'react-icons/fi';
import axios from 'axios';
import allActions from '../../../Store/action';
import Header from '../../dashboard/components/Header';
import Modal from '../../../components/Dashboard/Modal';
import Footer from '../../dashboard/components/Footer';
import EditUserForm from '../../../components/forms/EditUserForm';
import EditUserPasswordForm from '../../../components/forms/EditUserPasswordForm';
import EditUserBusinessInfoForm from '../../../components/forms/EditUserBusinessInfoForm';
import Preloader from '../components/Preloader';
import Alert from '../../dashboard/components/Alert';
import UserAvatar from '../../../components/UserAvatar';
import Table from './table';
import Message from '../../../components/forms/fields/Message';
import UpgradeMembershipTable from './upgradeMembershipTable';
import moment from 'moment';
import ImpersonateUserForm from '../../../components/forms/ImpersonateUserForm'

const UserDetails = () => {
	const navigate = useNavigate();
	const [{LoginData},{ getMembership }] = useSelector((state) => [state.user,state.membership]);
	const [{ accountManagers, services }] = useSelector((state) => [state.dashboard]);
	const [user, setUser] = useState(null);
	const [message, setMessage] = useState({ show: false });
	const [alertMessage, setAlertMessage] = useState('');
	const { uuid } = useParams();
	console.log({ uuid })
	const dispatch = useDispatch();
	// Membership Loader
	useEffect(() => {
		if (!getMembership.status) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: getMembership.message,
			});
		}
		if (getMembership.status === true) {
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, getMembership]);

	const loadUserByUUID = useCallback((UUID) => {
		axios
			.get(`${BASEURL}/api/users/${UUID}`)
			.then((res) => {
				console.log('user data>>>>', res.data);
				setUser(res.data);
				dispatch(allActions.global.Loader(true));
				dispatch(allActions.membership.getMembership(res.data.id))
			})
			.catch((err) => {
				console.log('error>>>>', err);
			});
	}, [dispatch]);

	useEffect(() => {
		loadUserByUUID(uuid);
	}, [uuid, loadUserByUUID]);

	useEffect(() => {
		dispatch(allActions.dashboard.getAccountManagers());
	}, [dispatch]);

	const goBack = () => {
		navigate(-1, { replace: true });
	};

	console.log("MESSAGE>>>",message);
	if (user !== null) {
		return (
			<div className='clearfix px-3'>
				<Header name='Users' enableBreadcrumb={true} />
				{message.show ? <Message message={message} /> : <></>}
				<div className='container page'>
					<div className='clearfix pt-4 mb-4'>
						<div className='row justify-content-center'>
							<div className='col-md-9'>
								<div className='clearfix text-center mb-5'>
									<Link
										to='#'
										className='d-inline-block mb-3'
										data-bs-toggle='modal'
										data-bs-target='#updateProfilePicture'
									>
										<UserAvatar
											avatar={user.avatar}
											alt={`${user.firstname} ${user.lastname}`}
											className='img-fluid rounded-circle border border-gray border-4'
											style={{ width: 120, height: 120 }}
										/>
									</Link>
									<h3>{`${user.firstname} ${user.lastname}`}</h3>
								</div>
								{alertMessage && (
									<Alert message={alertMessage} clickHandler={() => setAlertMessage('')} />
								)}
								<div className='row justify-content-center'>
									<div className='col-md-6'>
										<div className='card p-4 border-1 border-light rounded shadow-hover'>
											<p className='fw-bold mb-4'>
												Details{' '}
												<Link
													className='float-end'
													to='#'
													data-bs-toggle='modal'
													data-bs-target='#editUser'
												>
													<FiEdit />
												</Link>
											</p>
											<div className='row justify-content-center mb-3'>
												<div className='col-md-4'>User Status:</div>
												<div className='col-md-8'>
													<span
														className={`badge rounded-pill text-bg-${user.isVerify ? 'success' : 'secondary-300'
															}`}
													>
														{user.isVerify ? 'Active' : 'Unactive'}
													</span>
												</div>
											</div>
											<div className='row justify-content-center mb-3'>
												<div className='col-md-4'>Username:</div>
												<div className='col-md-8'>{user.username}</div>
											</div>
											<div className='row justify-content-center mb-3'>
												<div className='col-md-4'>Email:</div>
												<div className='col-md-8'>{user.email}</div>
											</div>
											<div className='row justify-content-center mb-3'>
												<div className='col-md-4'>Phone:</div>
												<div className='col-md-8'>{user.phone ? user.phone : 'N/A'}</div>
											</div>
											<div className='row justify-content-center'>
												<div className='col-md-4'>Created On:</div>
												<div className='col-md-8'>
													{new Date(user.created_at).toLocaleDateString('en-US', {
														year: 'numeric',
														month: 'long',
														day: 'numeric',
														hour: 'numeric',
														minute: 'numeric',
													})}
												</div>
											</div>
										<button className='btn btn-primary d-block mt-3 rounded-5' style={{ width: '120px' }}>
										<Link
													className={`dropdown-item ${
														!user.roles.filter((role) => role.name === 'admin').length > 0 &&
														!LoginData.impersonation
															? 'primary'
															: 'grey disabled'
													}`}
													data-bs-toggle="modal"
													data-bs-target="#impersonateUser"
												>
													Login As
												</Link>
										</button>
										</div>
										<div className='card p-4 border-1 border-light rounded shadow-hover mt-4'>
											<p className='fw-bold mb-4'>Update Password</p>
											<div className='row justify-content-center mb-3'>
												<div className='col-md-12'>
													<Link
														to='#'
														className='btn btn-danger rounded-5'
														data-bs-toggle='modal'
														data-bs-target='#updatePassword'
													>
														Update Password
													</Link>
												</div>
											</div>
										</div>
										{user.roles[0].name === 'taxpro' && (
											<div className='card p-4 border-1 border-light rounded shadow-hover mt-4'>
												<p className='fw-bold mb-2'>Update Membership</p>
												{getMembership?.data.length > 0 && getMembership?.data?.map((data, index) => {
													return <div key={index} className=''>
														<div className='d-flex gap-2'>
															<div className='fw-bold'>Type: </div>
															<div>{data?.next_renewal && '$'}{data?.membership?.membership_type?.Price}</div>
														</div>
														<div className='d-flex gap-2'>
														<div className='fw-bold'>{data?.next_renewal ? 'Next Billing Date:' : 'Trial End Date:'} </div>
															<p className=''>{data?.next_renewal ? moment(data?.next_renewal).format('MMMM D, YYYY') : moment(data?.trial_endTime).format('MMMM D, YYYY')}</p>
														</div>
													</div>
												})}
												<div className='row justify-content-center mb-3'>
													<div className='col-md-12'>
														<Link
															to='#'
															className='btn btn-danger rounded-5'
															data-bs-toggle='modal'
															data-bs-target='#updateMembership'
															onClick={()=>setMessage({show:false})}
														>
															Update Membership
														</Link>
													</div>
												</div>
											</div>)
										}
										<div className='d-none border-1 border-light rounded shadow-hover mt-4'>
											<p className='fw-bold mb-4'>Upgrade Membership</p>
											<div className='row justify-content-center mb-3'>
												<div className='col-md-12'>
													<Link
														to='#'
														className='btn btn-danger rounded-5 upgradeMembershipbtn'
														data-bs-toggle='modal'
														data-bs-target='#upgradeMembership'
													>
														Upgrade Membership
													</Link>
												</div>
											</div>
										</div>
									</div>
									{user.roles[0].name === 'taxpro' && (
										<div className='col-md-6'>
											<div className='card p-4 border-1 border-light rounded shadow-hover'>
												<p className='fw-bold mb-4'>
													Business Info{' '}
													<Link
														className='float-end'
														to='#'
														data-bs-toggle='modal'
														data-bs-target='#updateBusinessInfo'
													>
														<FiEdit />
													</Link>
												</p>
												<div className='row mb-3'>
													<div className='col-md-5'>Type of Business:</div>
													<div className='col-md-7'>
														{user.userpro
															? capitalizeFirstLetter(user.userpro.additional?.business_type)
															: ''}
													</div>
												</div>
												<div className='row justify-content-center mb-3'>
													<div className='col-md-5'>Name of Business</div>
													<div className='col-md-7'>
														{user.userpro ? capitalizeFirstLetter(user.userpro.businessName) : ''}
													</div>
												</div>
												<div className='row justify-content-center mb-3'>
													<div className='col-md-5'>Account Status:</div>
													<div className='col-md-7'>
														{user.userpro && (
															<span
																className={`badge rounded-pill bg-${getTaxproStatusClass(
																	user.userpro.status_id
																)}`}
															>
																{getTaxproStatus(user.userpro.status_id)}
															</span>
														)}
													</div>
												</div>
												<div className='row justify-content-center mb-3'>
													<div className='col-md-5'>Phone:</div>
													<div className='col-md-7'>
														{user.userpro ? user.userpro.phone || 'N/A' : 'N/A'}
													</div>
												</div>
												<div className='row justify-content-center mb-3'>
													<div className='col-md-5'>Address:</div>
													<div className='col-md-7'>
														{`${user.userpro ? user.userpro.street + ',' : ''} ${user.userpro ? user.userpro.street2 : ''
															}`}
														<br />
														{`${user.userpro ? user.userpro.city + ',' : ''} ${user.userpro ? user.userpro.state : ''
															} ${user.userpro ? user.userpro.zipcode : ''}`}
													</div>
												</div>
												<div className='row justify-content-center mb-3'>
													<div className='col-md-5'>Percentage:</div>
													<div className='col-md-7'>
														{user.userpro ? user.userpro.percentage : '20'}%
													</div>
												</div>
												<div className='row justify-content-center mb-3'>
													<div className='col-md-5'>Round-Robin:</div>
													<div className='col-md-7'>
														{user.userpro ? user.userpro.roundRobin : '1'}{' '}
														<small className='text-italic text-muted'>per circle</small>
													</div>
												</div>
												<div className='row justify-content-center mb-3'>
													<div className='col-md-5'>Account Manager:</div>
													<div className='col-md-7'>
														{user.userpro.manager?.firstname + ' ' + user.userpro.manager?.lastname}
													</div>
												</div>
												<hr />
												<div className='row justify-content-center mb-3'>
													<div className='col-md-3 fw-bold'>Experience:</div>
													<div className='col-md-9'>
														{user.userpro ? user.userpro.experience : ''}
													</div>
												</div>
											</div>
											<div className="card p-4 border-1 border-light rounded shadow-hover mt-4">
												<p className="fw-bold mb-4">Tax Pro Link</p>
												<div className="clearfix">
												<pre className='p-4 bg-light'>
													<code>
														{`${window.location.origin}/register?pro_id?=${user.userpro.stripe_account_id}`}
													</code>
												</pre>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className='clearfix d-grid w-50 mx-auto mt-5'>
									<button type='button' className='btn btn-dark rounded-5' onClick={goBack}>
										&larr; Go Back
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
				<Modal
						title="Impersonate User"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="impersonateUser"
						showBtn={false}
						btnClass="impersonateUserModalClose"
					>
						 <ImpersonateUserForm userId={user?.id} userToEdit={user} isLeadsPage={true} />
					</Modal>
				<Modal
					title='Edit User Details'
					bodyClass='p-4 text-center text-dark text-opacity-50'
					btnText='Update'
					btnClass='userDetailModalClose'
					trigger='editUser'
					showBtn={false}
					size='md'
				>
					<EditUserForm userData={user} reloadUser={loadUserByUUID} setAlert={setAlertMessage} />
				</Modal>
				<Modal
					title='Update Password'
					bodyClass='p-4 text-center text-dark text-opacity-50'
					btnText='Update'
					btnClass='passwordChangeModalClose'
					trigger='updatePassword'
					showBtn={false}
					size='md'
				>
					<EditUserPasswordForm
						userId={user.id}
						userEmail={user.email}
						setAlert={setAlertMessage}
					/>
				</Modal>
					<Modal
						title='Update Membership'
						bodyClass='p-4 text-center text-dark text-opacity-50'
						btnText='Update'
						btnClass='passwordChangeModalClose'
						trigger='updateMembership'
						showBtn={false}
						size='xl'
					>
						{/* <EditUserPasswordForm
						userId={user.id}
						userEmail={user.email}
						setAlert={setAlertMessage}
					/> */}

						<Table user={user}/>
					</Modal>


					<Modal
						title='Upgrade Membership'
						bodyClass='p-4 text-center text-dark text-opacity-50'
						btnText='Upgrade'
						btnClass='upgradeMembershipClose'
						trigger='upgradeMembership'
						showBtn={false}
						size='xl'
					>
						<UpgradeMembershipTable message={message} setMessage={setMessage} user={user} />
					</Modal>
				<Modal
					title='Update Profile Picture'
					bodyClass='p-4 text-center text-dark text-opacity-50'
					btnText='Update'
					btnClass='profilePictureModalClose'
					trigger='updateProfilePicture'
					showBtn={false}
				>
					<p>Coming Soon</p>
				</Modal>
				{user.userpro ? (
					<Modal
						title='Edit Business Info'
						bodyClass='p-4 text-center text-dark text-opacity-50'
						btnText='Update'
						btnClass='businessDetailModalClose'
						trigger='updateBusinessInfo'
						showBtn={false}
						size='lg'
					>
						<EditUserBusinessInfoForm
							userData={user}
							reloadUser={loadUserByUUID}
							setAlert={setAlertMessage}
							accountManagers={accountManagers}
							services={services}
						/>
					</Modal>
				) : null}
			</div>
		);
	} else {
		return <Preloader />;
	}
};

export default UserDetails;
