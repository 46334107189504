import { BsFiletypePdf, BsFiletypeJpg, BsFiletypePng, BsFileEarmark } from 'react-icons/bs';

function FileTypeIcon({ extension, ...props }) {
	if (extension) {
		if (extension.toLowerCase().includes('pdf')) {
			return <BsFiletypePdf {...props} />;
		}

		if (extension.toLowerCase().includes('jpg') || extension.toLowerCase().includes('jpeg')) {
			return <BsFiletypeJpg {...props} />;
		}

		if (extension.toLowerCase().includes('png')) {
			return <BsFiletypePng {...props} />;
		}
	}

	return <BsFileEarmark {...props} />;
}

export { FileTypeIcon };
