import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';
import { HandleFormData } from '../../../../utilites';
import DatePicker from 'react-datepicker'

const PersonalInformation = () => {

    const { PersonalInformationAnswers, PersonalInformationAnswersStyles } = GLOBAL_VARIABLES;

    const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 0 });
    const dispatch=useDispatch()
    const [{ taxPayerForm, formInformation}] = useSelector((state) => [state.taxForms]);

    const [show,setShow]=useState(null)
    const [specificQuestions, setSpecificQuestions] = useState([]);
    const [specificQuestionsStyles, setSpecificQuestionsStyles] = useState([]);

    const [errors, setErrors] = useState({}); // Track errors for form fields

    useEffect(() => {
        setSpecificQuestions(JSON.parse(JSON.stringify(PersonalInformationAnswers)));
        setSpecificQuestionsStyles(JSON.parse(JSON.stringify(PersonalInformationAnswersStyles)));
    }, [PersonalInformationAnswers, PersonalInformationAnswersStyles]);

    const handleRadioChange = (index, value) => {
        if (index === 8) {
            setShow(value)
        }
        const updatedQuestions = [...specificQuestions];
        updatedQuestions[index].bool = value;
        setSpecificQuestions(updatedQuestions);
    };

    const handleInputChange = (index, value, data, valIdx) => {
        const filedKey = Object.keys(data).find(key => key !== 'placeholder' && key !== 'styleProp');
        const updatedQuestions = [...specificQuestions];
        updatedQuestions[index].values[valIdx][filedKey] = value;
        setSpecificQuestions(updatedQuestions);
    };

    useEffect(() => {
		if (taxPayerForm?.data?.PersonalInformation) {
			if (Object.keys(taxPayerForm?.data?.PersonalInformation).length === 0) return;
			const {
				specificQuestions
			} = taxPayerForm?.data?.PersonalInformation;
			setSpecificQuestions(specificQuestions);
		}
	}, [taxPayerForm?.data?.PersonalInformation]);

    const check_Validity = () => {
        setErrors({});
        const newErrors = {};
    
        // Add more validation rules as needed for other fields
        if (
            specificQuestions
                ?.flatMap((data) => {
                    return data?.bool;
                })
                .includes(null)
        ) {
            newErrors.AnswerOtherQuestions = 'Please Select Required Option';
        }
    
        setErrors(newErrors);
    
        let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
        // Check if there are any errors
        return ConditionCheck;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = check_Validity();
    if (errors) return;

        let FormObj = {
			specificQuestions
		};

		let formData = taxPayerForm;
		taxPayerForm.data.PersonalInformation = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
    };


    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            {/* <small className="ml-4 fw-bold">General Information</small>
            <hr className="m-0" /> */}
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                {specificQuestions.map((data, i) => {
                    return (
                    <>
                        { i===0 || i===8 ?(
                            <>
                            
                        <small className="ml-4 fw-bold">
                            {data.question}
                            
                            {data.bool===null && (
                                <span className="text-danger small-text mx-1">*Required</span>
                            )}
                        </small>
                        
                        <div className="col-md-12">
                            <input
                                onChange={() => handleRadioChange(i,true)}
                                checked={specificQuestions[i].bool === true}
                                className="form-check-input"
                                type="radio"
                                id={`checkbox-${i + 176}`}
                                name={`checkbox-${i + 176}`}
                            />
                            <label htmlFor={`checkbox-${i + 176}`} role="button" className="px-3 m-0 small">
                                Yes
                            </label>
                            <input
                                onChange={() => handleRadioChange(i,false)}
                                checked={specificQuestions[i].bool === false}
                                className="form-check-input"
                                type="radio"
                                id={`checkbox-${i + 999}`}
                                name={`checkbox-${i + 999}`}
                            />
                            <label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
                                No
                            </label>
                        </div>
                        </>
                        ):
                         (i > 0 && i < 8 ) ? (
                                    data?.values?.map((data, index) => (
                                        ((i===1&&index===0)||(i===4&&index===0))?
                                        <div key={index} className={`my-1 col-lg-6 col-md-6 mt-1 col-sm-12 ${((i === 1 && index === 0)||(i === 4 && index === 0)) ? 'mt-3' : ''}`}>
                                      <small>{specificQuestionsStyles?.[i]?.valData[index]?.placeholder}</small>
                                     <DatePicker
                                         className="form-select"
                                         style={{ width: 100 }}
                                         wrapperClassName="d-block"
                                         selected={new Date(specificQuestions[i].values[index][Object.keys(data).find(key => key !== 'placeholder' && key !== 'styleProp')])}
                                         onChange={(date) => handleInputChange(i, date, data, index)}
                                         dateFormat="MMMM d, yyyy"
                                         placeholderText={specificQuestionsStyles?.[i]?.valData[index]?.placeholder}
                                     />
                                 </div>:
                                            <div className={`my-1 ${specificQuestionsStyles?.[i]?.valData[index]?.styleProp}`}>
                                                <Input
                                                    type={specificQuestionsStyles?.[i]?.valData[index]?.type}
                                                    value={specificQuestions[i].values[index][Object.keys(data).find(key => key !== 'placeholder' && key !== 'styleProp')]}
                                                    placeholder={specificQuestionsStyles?.[i]?.valData[index]?.placeholder}
                                                    fn={(value) => handleInputChange(i, value, data, index)}
                                                />
                                            </div>)
                                    )
                            ):
                            show && (
                                <div className='row'>
                                    {data?.values?.map((data, index) => {
                                        return (
                                            <div className={`my-1 ${specificQuestionsStyles?.[i]?.valData[index]?.styleProp}`}>
                                                <Input
                                                mask={data?.mask}
                                                    type="text"
                                                    value={specificQuestions[i].values[index][Object.keys(data).find(key => key !== 'placeholder' && key !== 'styleProp')]}
                                                    placeholder={specificQuestionsStyles?.[i]?.valData[index]?.placeholder}
                                                    fn={(value) => handleInputChange(i, value, data, index)}
                                                />
                                            </div>)
                                    })}
                                </div>
                            )
                            }
                        
                    </>
                    )
                })}
            </div>
            {Object.keys(errors)
    ?.map((key) => {
        return errors[key];
    })
    ?.map((data, index) => {
        return (
            <>
                <small className="text-primary" key={index}>
                    {data}*
                </small>
                <br />
            </>
        );
    })}
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="button"
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default PersonalInformation;
