/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useNavigate } from 'react-router-dom';
import ForgotPasswordForm from '../../components/forms/ForgotPasswordForm';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../Store/action';
import { useEffect, useState } from 'react';
import Message from '../../components/forms/fields/Message';
import SEO from '../../components/SEO';

const Existingaccount = () => {
	const [message, setMessage] = useState({ show: false });
	const [{ existEmail, addExistingUser }] = useSelector((state) => [state.user]);
	const navigate = useNavigate();
	let dispatch = useDispatch();

	//  check existEmail useEffect
	useEffect(() => {
		console.log('@4777', existEmail);
		if (existEmail.status === false) {
			dispatch(allActions.global.Loader(false));
			dispatch(allActions.user.checkExistingUserReset());
			const error = {
				show: true,
				type: 'Error',
				text:
					existEmail?.error === 'no user found'
						? 'We could not find an account for that email. Please sign up here or contact our support'
						: existEmail?.error,
			};
			if (existEmail?.error === 'no user found') {
				navigate('/registerTaxPro', { state: { error } });
				return;
			}
			setMessage(error);
		}
		if (existEmail.status === true) {
			dispatch(allActions.user.checkExistingUserReset());
			dispatch(allActions.user.addExistingUser(existEmail.data));
		}
	}, [existEmail]);

	//  add existUser useEffect
	useEffect(() => {
		console.log('@4777', addExistingUser);
		if (addExistingUser.status === true) {
			dispatch(allActions.global.Loader(false));
			const error = {
				show: true,
				type: 'Success',
				text: 'Your account has already been transferred. Please log in with your email and existing password from our old website',
			};
			navigate('/login', { state: { error } });
		}
		if (addExistingUser.status === false) {
			dispatch(allActions.global.Loader(false));
			const error = {
				show: true,
				type: addExistingUser?.error === 'user Already Register' ? 'Success' : 'Error',
				text:
					addExistingUser?.error === 'user Already Register'
						? 'Your account has already been transferred. Please log in with your email and existing password from our old website'
						: addExistingUser?.error,
			};
			if (addExistingUser?.error === 'user Already Register') {
				navigate('/login', { state: { error } });
				return;
			}
			setMessage(error);
		}
	}, [addExistingUser]);

	const checkEmail = async (email, setMessage, resetForm, change_spinner) => {
		dispatch(allActions.global.Loader(true));
		dispatch(allActions.user.checkExistingUser(email, setMessage, resetForm, change_spinner));
	};
	return (
		<div className='page px-3'>
			<SEO title='Existing account' />
			<div className='container'>
				<div className='clearfix mb-4'>
					<div className='row justify-content-center'>
						<div className='col-md-4'>
							<div className='clearfix bg-white p-5 shadow rounded'>
								<h4 className='ff-gothic text-dark mb-4'>Sign in to access your account</h4>
								{message.show ? <Message message={message} /> : <></>}
								<p className='fs-7 text-dark'>
									Enter the email address associated with your original TPG lead account and we’ll
									send you a link to reset your password. TPG has now been rebranded to{' '}
									<Link to='https://unclekam.com'>unclekam.com</Link> which is a all new advanced
									lead and practice management platform to help you close more advisory deals. You
									get access for free with no additional fees.
								</p>
								<ForgotPasswordForm buttonName={'signup'} checkEmail={checkEmail} />
							</div>
							<div className='text-center mt-4'>
								<p className='text-muted'>
									Don't have an account? <Link to='/register'>Sign up</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Existingaccount;
