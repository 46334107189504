import React, {useState} from "react";
import Button from "../../../components/forms/fields/Button";
import Message from "../../forms/fields/Message";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {useEffect} from "react";
import allActions from "../../../Store/action";
const Agreement = ({active, setActive}) => {
  const dispatch = useDispatch();
  const [agree, setAgree] = useState(null);
  const [message, setMessage] = useState({show: false});
  const [{stripeAccountStatus},{LoginData}] = useSelector((state) => [state.dashboard,state.user]);

// useEffect for check stripe account status
	useEffect(() => {
		if (stripeAccountStatus === true) {
			setActive(2);
			dispatch(allActions.dashboard.resetAccountStatus());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stripeAccountStatus]);

  useEffect(() => {
    if (LoginData?.userpro?.agreement === 1) {
      handleAgreeChange();
    } else {
      handleDeclineChange()
    }
  }, [LoginData]);
  const handleAgreeChange = () => {
    setAgree(true);
  };

  const handleDeclineChange = () => {
    setAgree(false);
  };
  const reset_mesage = () => {
    setMessage({show: false});
  };
  // function to handle form form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    reset_mesage();
    if (agree === null || !agree) {
      setMessage({
        show: true,
        type: "Error",
        text: "Select Agreement First!",
      });
      return;
    }

    // Call Dispatch Function For Agreement
    dispatch(allActions.dashboard.Agreement(moveToNext, setMessage,LoginData?.email));
  };
  const moveToNext = (boolean) => {
    setActive(2);
  };

  return (
    <div>
      <div className="w-full border border-1 border-grey rounded px-2 py-2">
        {/* Agreement content */}
        {message.show ? <Message message={message} /> : <></>}

        <p className="text-start text-responsive">
          This Service Provider Agreement (“the Agreement”) is made and entered
          into effective immediately once agreed up and clicked on the button
          below. Accessing the website at www.unclekam.com you
          are agreeing to be bound by these terms all applicable by law and
          regulations. If you do not agree with any of these terms you are
          prohibited from using or accessing this site. The material contained
          are protected by and applicable copyright and trademark laws.
        </p>
        <p className="text-start text-responsive">by and between</p>
        <p className="text-start text-responsive">
          <li>
            The Uncle Kam, whose principal place of business is at 4
            Centerpointe Dr Suite, #310 La Palma, California 90623, (hereinafter
            referred to as the
          </li>
          <li>
            “Company”, which expression shall, unless inconsistent with the
            context or meaning thereof, be deemed to mean and include its
            affiliates, successors-in-interest and assigns) of the ONE PART;
          </li>
        </p>
        <p className="text-start text-responsive">And</p>
        <p className="text-start text-responsive">
          [●], whose principal place of business is at [●], (hereinafter
          referred to as the “Vendor”,{" "}
          <Link to="/user-agreement" className="text-primary" target="_blank">Read More</Link>
        </p>
      </div>
      <form className="py-2 mx-2" onSubmit={handleSubmit}>
        <div className="row align-items-center">
          <div className="col-auto">
            <div className="form-check form-check-inline">
              <label className="form-check-label" htmlFor="agreeCheckbox">
                <input
                  className="form-check-input custom-checkbox"
                  type="checkbox"
                  id="agreeCheckbox"
                  checked={agree}
                  onChange={handleAgreeChange}
                />
                I agree
              </label>
            </div>
          </div>
          <div className="col-auto">
            <div className="form-check form-check-inline">
              <label className="form-check-label" htmlFor="dontAgreeCheckbox">
                <input
                  className="form-check-input custom-checkbox"
                  type="checkbox"
                  id="dontAgreeCheckbox"
                  checked={!agree}
                  onChange={handleDeclineChange}
                />
                I decline and log out
              </label>
            </div>
          </div>
        </div>
        <div className="container-fluid content-row ">
          <div className="d-flex align-items-center justify-content-center row">
            <Button
              mainClass="btn btn-outline-primary btn-lg w-100 disabled"
              buttonText="Previous"
              wrapperClass={"w-25 custon-button  mt-4"}
              type="button"
            />
            <Button
              mainClass="btn btn-primary custon-button-text btn-lg  w-100"
              buttonText="Next"
              wrapperClass={"w-25 custon-button  mt-4"}
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Agreement;
