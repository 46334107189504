import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BASEURL } from '../../../../utilites';
import outlookCalendarImage from '../../../../assets/img/outlook.png';

const OutlookCalendar = ({ user, outlookCalendarVerification }) => {
	const [outlookCalendarAuthUrl, setoutlookCalendarAuthUrl] = useState('');

	useEffect(() => {
		axios
			.get(`${BASEURL}/api/outlookcalendar/auth-url`)
			.then((res) => setoutlookCalendarAuthUrl(res.data.data))
			.catch((error) => console.error('Error fetching outlookCalendar auth URL:', error));
	}, []);

	return (
		<>
			{user.isTaxPro && (
				<div className="clearfix py-3 border-1 border-light border-bottom">
					<h4 className="text-dark">Outlook-Calendar Integration</h4>
					<p>
						<img src={outlookCalendarImage} alt="outlookCalendar" className="w-25" />
					</p>
					<p>
						<strong>Outlook-Calendar</strong> is the scheduling automation platform with team-based
						scheduling, solutions and integrations for every department, and advanced security
						features.
					</p>
					<p className="text-dark">
						<strong>Status: </strong>
						{outlookCalendarVerification || user?.isOutlookAuth ? 'Integrated' : 'No Integration'}
					</p>
					<p>
						<Link to={outlookCalendarAuthUrl} className="btn btn-outline-primary" target="_blank">
							{outlookCalendarVerification || user?.isOutlookAuth
								? 'Re-Authenticate Application'
								: 'Authenticate Application'}
						</Link>
					</p>
				</div>
			)}
		</>
	);
};

export default OutlookCalendar;
