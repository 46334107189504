/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import TextArea from '../../fields/TextArea';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import Input from '../../fields/Input';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';
import { HandleFormData } from '../../../../utilites';

const RefundAndPayment = () => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const dispatch = useDispatch();
	const uploadFormData = HandleFormData({ parentIndex: 4, myIndex: 1, redirect: '/dashboard' });

	const {
		refundPaymentOptions,
		refundPaymentOptions1,
		refundBankDetails,
		refundAccountHolderType,
		refundAccountNature,
		refundAccountHolderNationality,
	} = GLOBAL_VARIABLES;

	const [{ taxPayerFormData, taxPayerForm, formInformation }, { LoginData }] = useSelector(
		(state) => [state.taxForms, state.user]
	);
	// For Text Input Field

	const [comments, setComments] = useState('');

	// For Choice Questions
	const [estimateTaxesSelect, setEstimateTaxesSelect] = useState();
	const [estimateTaxesSelectOther, setEstimateTaxesSelectOther] = useState();
	const [federalPayments, setFederalPayments] = useState([]);
	const [accountHolderType, setAccountHolderType] = useState();
	const [refundAccountHolderNature, setRefundAccountHolderNature] = useState();
	const [refundAccountHoldersNationality, setRefundAccountHoldersNationality] = useState();

	// For Choice Questions

	const [errors, setErrors] = useState({}); // Track errors for form fields

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	useEffect(() => {
		setFederalPayments(JSON.parse(JSON.stringify(refundBankDetails)));
	}, []);

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.refundAndPaymentForm) {
			if (Object.keys(taxPayerForm?.data?.refundAndPaymentForm).length === 0) return;

			const {
				estimateTaxesSelect,
				estimateTaxesSelectOther,
				federalPayments,
				accountHolderType,
				refundAccountHolderNature,
				refundAccountHoldersNationality,
				comments,
			} = taxPayerForm?.data?.refundAndPaymentForm;
			setEstimateTaxesSelect(estimateTaxesSelect);
			setEstimateTaxesSelectOther(estimateTaxesSelectOther);
			setFederalPayments(federalPayments);
			setAccountHolderType(accountHolderType);
			setRefundAccountHolderNature(refundAccountHolderNature);
			setRefundAccountHoldersNationality(refundAccountHoldersNationality);
			setComments(comments);
		}
	}, [taxPayerForm?.data?.refundAndPaymentForm]);

	console.log('federalPayments', federalPayments, refundBankDetails);
	const handleAmountChange = (id, index) => {
		const myInputAmount = document.getElementById(id);
		let localDeduction = federalPayments;
		localDeduction[index].amount = myInputAmount.value;
		setFederalPayments(JSON.parse(JSON.stringify(localDeduction)));
	};
	// ---------------------------------------
	// Functions
	// ---------------------------------------

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		if (!estimateTaxesSelect || !estimateTaxesSelectOther) {
			newErrors.estimateTaxesSelect = 'Select Refund Options';
		}
		if (!accountHolderType || !refundAccountHolderNature) {
			newErrors.accountHolderType = 'Select Account Holder Information';
		}

		setErrors(newErrors);

		// Check if there are any errors
		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let errors = check_Validity();
		if (errors) return;

		let FormObj = {
			estimateTaxesSelect,
			estimateTaxesSelectOther,
			federalPayments,
			accountHolderType,
			refundAccountHolderNature,
			refundAccountHoldersNationality,
			comments,
		};

		let formData = taxPayerForm;
		taxPayerForm.data.refundAndPaymentForm = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		// if (!makeSureFormFilling()) {
		// 	setErrors({
		// 		FormError: "Check You Have Some Form Missed To Filled!, First Fill Them.",
		// 	});
		// 	return;
		// }

		let obj = {
			service_id: 1,
			method: 'upload',
			formName: formInformation?.name,
			formData: taxPayerForm.data,
		};
		if (LoginData?.isTaxPro || LoginData?.isAdmin || LoginData?.isAccountManager)
			obj.lead_id = formInformation?.lead_id;

		changeLoading(true);
		dispatch(allActions.dashboard.resetTaxPreparation());
		dispatch(allActions.dashboard.taxPreparation({ obj }));

		uploadFormData(formData);
	};

	// const makeSureFormFilling = () => {
	// 	let completed = true;
	// 	Object.keys(taxPayerForm.data).forEach((element) => {
	// 		if (Object.keys(taxPayerForm.data[element]).length === 0) {
	// 			completed = false;
	// 		}
	// 	});
	// 	return completed;
	// };
	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 4;
		let myIndex = 1;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<div className=" mb-3 px-3">
					{JSON.parse(JSON.stringify(refundPaymentOptions))?.map((data, i) => {
						return (
							<div key={i} className="w-100  align-items-center justify-content-between mt-2">
								<small
									className="col-md-12 form-check-label text-start fw-bold"
									htmlFor={`checkbox-estimate-${i}`}
								>
									{data.question}{!estimateTaxesSelect &&<span className='text-danger small-text mx-1'>*Required</span>}
								</small>
								{data?.options?.map((option, index) => {
									return (
										<div className="col-md-12">
											<input
												onChange={() => setEstimateTaxesSelect(option)}
												checked={estimateTaxesSelect === option}
												className="form-check-input"
												type="radio"
												id={`checkbox-estimate-${index}`}
												name={`checkbox-estimate-${index}`}
											/>
											<label
												htmlFor={`checkbox-estimate-${index}`}
												role="button"
												className="px-3 m-0 small"
											>
												{option}
											</label>
										</div>
									);
								})}
							</div>
						);
					})}
					{JSON.parse(JSON.stringify(refundPaymentOptions1))?.map((data, i) => {
						return (
							<div key={i} className="w-100  align-items-center justify-content-between mt-2">
								<small
									className="col-md-12 form-check-label text-start fw-bold"
									htmlFor={`checkbox-option-${i}`}
								>
									{data.question}{!estimateTaxesSelectOther &&<span className='text-danger small-text mx-1'>*Required</span>}
								</small>
								{data?.options?.map((option, index) => {
									return (
										<div className="col-md-12">
											<input
												onChange={() => setEstimateTaxesSelectOther(option)}
												checked={estimateTaxesSelectOther === option}
												className="form-check-input"
												type="radio"
												id={`checkbox-option-${index}`}
												name={`checkbox-option-${index}`}
											/>
											<label
												htmlFor={`checkbox-option-${index}`}
												role="button"
												className="px-3 m-0"
											>
												{option}
											</label>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
			<br />
			<>
				<p className="fw-bold">Bank information</p>
				<hr />
				<div className="w-100 d-flex flex-row flex-wrap justify-content-between align-items-end mb-3 px-4 mt-3">
					{JSON.parse(JSON.stringify(federalPayments))?.map((data, i) => {
						return (
							<div
								key={i}
								className="w-100 d-flex align-items-end justify-content-between px-2 my-2"
							>
								<small className="form-check-label text-start fw-bold">{data.question}</small>
								<div className="w-50 d-flex flex-row justify-content-between">
									<div className="col-md-8">
										<Input
											type="text"
											value={data?.amount}
											name="Amount"
											placeholder={data.question}
											formid={`Amount${i}`}
											showLabel={data.numeric?true:false}
											fn={() => {
												handleAmountChange(`Amount${i}`, i);
											}}
											mask={data?.numeric && '999999999'}
										/>
									</div>
								</div>
							</div>
						);
					})}
					{JSON.parse(JSON.stringify(refundAccountHolderType))?.map((data, i) => {
						return (
							<div
								key={i}
								className="w-100  align-items-center justify-content-start mt-3 d-flex flex-row my-2 px-2"
							>
								<small
									className="col-md-6 form-check-label text-start fw-bold"
									htmlFor={`checkbox-HolderType-${i}`}
								>
									{data.question}
								</small>
								{data?.options?.map((option, index) => {
									return (
										<div>
											<input
												onChange={() => setAccountHolderType(option)}
												checked={accountHolderType === option}
												className="form-check-input"
												type="radio"
												id={`checkbox-HolderType-${index}`}
												name={`checkbox-HolderType-${index}`}
											/>
											<label
												htmlFor={`checkbox-HolderType-${index}`}
												role="button"
												className="px-3 m-0 small"
											>
												{option}
											</label>
										</div>
									);
								})}
							</div>
						);
					})}
					{JSON.parse(JSON.stringify(refundAccountNature))?.map((data, i) => {
						return (
							<div
								key={i}
								className="w-100  align-items-center justify-content-start mt-3 d-flex flex-row my-2 px-2"
							>
								<small
									className="col-md-6 form-check-label text-start fw-bold"
									htmlFor={`checkbox-accountNature-${i}`}
								>
									{data.question}
								</small>
								{data?.options?.map((option, index) => {
									return (
										<div>
											<input
												onChange={() => setRefundAccountHolderNature(option)}
												checked={refundAccountHolderNature === option}
												className="form-check-input"
												type="radio"
												id={`checkbox-accountNature-${index}`}
												name={`checkbox-accountNature-${index}`}
											/>
											<label
												htmlFor={`checkbox-accountNature-${index}`}
												role="button"
												className="px-3 m-0 small"
											>
												{option}
											</label>
										</div>
									);
								})}
							</div>
						);
					})}
					{JSON.parse(JSON.stringify(refundAccountHolderNationality))?.map((data, i) => {
						return (
							<div
								key={i}
								className="w-100  align-items-center justify-content-start mt-3 d-flex flex-row my-2 px-2"
							>
								<small
									className="col-md-6 form-check-label text-start fw-bold"
									htmlFor={`checkbox-refund-${i}`}
								>
									{data.question}
								</small>
								{data?.options?.map((option, index) => {
									return (
										<div>
											<input
												onChange={() => setRefundAccountHoldersNationality(option)}
												checked={refundAccountHoldersNationality === option}
												className="form-check-input"
												type="checkbox"
												id={`checkbox-refund-${index}`}
												name={`checkbox-refund-${index}`}
											/>
											<label
												htmlFor={`checkbox-refund-${index}`}
												role="button"
												className="px-3 m-0 small"
											>
												{option}
											</label>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</>
			<br />
			<small className="ml-4 fw-bold">Comments</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3 mb-3 custom-left-form">
				<small className="text-dark fw-bold m-0 py-2">
					Enter Any Additional Information / Question here.
				</small>
				<div className="col-md-8 mb-3">
					<TextArea
						inputName="form-control"
						defaultValue={comments}
						placeholder="Comments"
						name="comments"
						fn={setComments}
						row="6"
						required={false}
					/>
				</div>
			</div>
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => goBack()}
					/>
					{/* {taxPayerForm?.state === "idle" && ( */}
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Submit"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default RefundAndPayment;
