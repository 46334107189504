/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AiOutlineArrowUp } from "react-icons/ai";
import Header from "../components/Header";
import Modal from "../../../components/Dashboard/Modal";
import Input from "../../../components/forms/fields/Input";
import Button from "../../../components/forms/fields/Button";
import Message from "../../../components/forms/fields/Message";
import { PAYOUT_ACTION_TYPE } from "../../../Store/payout/Type";
import DropDown from "../../../components/forms/fields/DropDown";
import allActions from "../../../Store/action";
import {
  getPayoutStatusName,
  moneyFormatter,
  getQueryParam,
  removeQueryParam,
  setQueryParams,
} from "../../../utilites";
import Footer from "../components/Footer";
import { IoIosLock } from "react-icons/io";
import DatePicker from 'react-datepicker';
import ListPageWrapper from '../components/ListPageWrapper'




const Payouts = () => {
	const dispatch = useDispatch();
	const [{ requestPayout, getPayout, balance }, { LoginData, loginStatus }, { user, dashboard }] =
		useSelector((state) => [state.payout, state.user, state]);
	const [amount, setAmount] = useState('');
	const [message, setMessage] = useState({ show: false });
	const [selectedPeriod, setSelectedPeriod] = useState('');
	const [modalMessage, setModalMessage] = useState({ show: false });
	const [handleAmountMethod, setHandleAmountMethod] = useState();
	const [errors, setErrors] = useState({}); // Track errors for form fields
	const [selectedTaxPro, setSelectedTaxPro] = useState();
	const [total_balance, setTotal_balance] = useState(LoginData?.userpro?.total_balance);

	// Search
	const [keyword, setKeyword] = useState(getQueryParam('search') || '');
	const [dateCreated, setDateCreated] = useState(
		getQueryParam('created_after') ? new Date(getQueryParam('created_after')) : null
	);

	// Pagination
	const [perPage, setPerPage] = useState(getQueryParam('per_page') || 10);
	const [page, setPage] = useState(getQueryParam('page') || 1);


	// Tax Pro List For Admin & Account Manager
	const taxProList = dashboard?.allUsers?.data
		?.filter((data) => data?.roles?.[0]?.display_name === 'Tax Pro')
		?.map((data) => ({
			value: data.firstname + ' ' + data.lastname,
			label: data.firstname + ' ' + data.lastname,
			id: data?.userpro?.id,
			amount: data?.userpro?.total_balance,
		}));
console.log("TPLMain@@", dashboard)
	// Total Amount of All Taxpro List
	const totalAmount = taxProList.reduce(
		(sum, item) =>
			item.amount !== null && item.amount !== undefined ? sum + parseFloat(item.amount) : sum,
		0
	);

	const adddecimal = (cents) => {
		if (typeof cents === 'undefined' || isNaN(cents)) {
			return '';
		}
		if (cents === 0 || cents === '0') return '0.00';
		const dec = (cents + '').split('.')[1];
		const len = dec && dec.length > 2 ? dec.length : 2;
		return Number(cents).toFixed(len);
	};

	const remaningBalance =
		parseFloat(total_balance ? total_balance : 0) -
		parseFloat(
			balance?.data?.pending?.[0]?.amount
				? adddecimal(balance?.data?.pending?.[0]?.amount / 100)
				: 0
		);

		// TO GET TAXPRO DATA
	useEffect(() => {
		if (selectedTaxPro) {
			setTotal_balance();
			dispatch({ type: PAYOUT_ACTION_TYPE.balanceIdle });
			resetState();
			dispatch(allActions.payout.getBalance(selectedTaxPro?.id));
		}
	}, [selectedTaxPro]);

	// GET DATA
	useEffect(() => {
		// FOR ADMIN
		if (loginStatus && !user?.isRegular && !user?.isTaxPro) {
			dispatch(allActions.dashboard?.getAllUsers('', ''));
		}
		// FOR ACCOUNT MANAGER
		if (loginStatus && user?.isAccountManager) {
			dispatch(allActions.dashboard?.getAllUsers('', ''));
		}
	}, [window.location.href]);

	// Request Payout
	useEffect(() => {
		if (requestPayout.status === 'idle') {
			setMessage({ show: false });
			setModalMessage({ show: false });
		}

		if (!requestPayout.status) {
			changeLoading(false);
			setModalMessage({
				show: true,
				type: 'Error',
				text: requestPayout?.message,
			});
		}
		if (requestPayout.status === true) {
			setAmount('');
			setHandleAmountMethod();
			toggleModal();
			setMessage({
				show: true,
				type: 'Success',
				text: 'payout request created successfully',
			});
			dispatch(allActions.payout.getPayout());
			dispatch(allActions.payout.getBalance(selectedTaxPro?.id));
		}
	}, [requestPayout, requestPayout.status]);

	// Get Payout Requests
	useEffect(() => {
		if (getPayout.status === 'idle') {
			changeLoading(true);
			setMessage({ show: false });
			dispatch(allActions.payout.getPayout());
		}
		if (!getPayout.status) {
			changeLoading(false);
			setMessage({
				show: true,
				type: 'Error',
				text: getPayout?.message,
			});
		}
		if (getPayout.status === true && balance.status === true) {
			changeLoading(false);
		}
		// FOR ADMIN AND ACCOUNT MANAGER
		if (getPayout.status === true && !LoginData?.isTaxPro) {
			changeLoading(false);
		}
	}, [getPayout, getPayout.status, balance.status]);

	// getBalance
	useEffect(() => {
		if (balance.status === 'idle' && LoginData?.isTaxPro) {
			setMessage({ show: false });
			dispatch(allActions.payout.getBalance());
		}
		if (!balance.status) {
			changeLoading(false);
			setMessage({
				show: true,
				type: 'Error',
				text: balance?.message,
			});
		}

		// In CASE OF ADMIN & ACCOUNT MANAGER
		if (!LoginData?.isTaxPro && balance.status === true) {
			changeLoading(false);
			setTotal_balance(selectedTaxPro?.amount);
		}
		if (!LoginData?.isTaxPro && !balance.status) {
			changeLoading(false);
			setModalMessage({
				show: true,
				type: 'Error',
				text: balance?.message,
			});
		}
		if (!LoginData?.isTaxPro && balance.status === 'idle' && selectedTaxPro) {
			changeLoading(true);
		}
	}, [balance.status]);

	const resetState = () => {
		setModalMessage({ show: false });
		changeLoading(true);
	};
	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};
	const toggleModal = () => {
		document.getElementById('RequestPayoutPaymentModal').click();
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		setErrors({});
		const newErrors = {};
		// Add more validation rules as needed for other fields
		if (handleAmountMethod === null || handleAmountMethod === undefined) {
			newErrors.AnswerOtherQuestions = 'Please Select Required Option';
			setErrors(newErrors);
			return;
		}
		if (amount > remaningBalance) {
			newErrors.amountError = 'Insufficient balance';
			setErrors(newErrors);
			return;
		}
		dispatch({ type: PAYOUT_ACTION_TYPE.requestPayoutIdle });
		let requestedData = {
			amount: Number(amount),
		};
		if (!LoginData.isTaxPro) {
			requestedData.id = selectedTaxPro?.id;
		}
		dispatch(allActions.payout.requestPayout(requestedData));
		changeLoading(true);
	};


	useEffect(() => {
		dispatch(allActions.payout.getPayout(keyword,dateCreated,page,perPage));
	}, [dispatch, keyword, dateCreated, page, perPage]);


	const moveTo = (page) => {
		setPage(page);
		// Set query param
		setQueryParams('page', page);
	};

	const filterByKeyword = (keyword) => {
		setKeyword(keyword);
		// Set query param
		if (!keyword) {
			removeQueryParam('search');
		} else {
			setQueryParams('search', keyword);
		}
	};

	const filterByDateCreated = (date) => {
		setDateCreated(date);
		// Set query param
		if (!date) {
			removeQueryParam('created_after');
		} else {
			setQueryParams('created_after', date);
		}
	};

	const changePerPage = (perPage) => {
		setPerPage(perPage);
		// Set query param
		setQueryParams('per_page', perPage);
	};


	return (
    <ListPageWrapper
      heading="Payouts"
      headerControls={
        <Fragment>
          <div className="clearfix mb-4">
            {LoginData.isTaxPro && message.show ? (
              <Message message={message} />
            ) : (
              <></>
            )}
            <div className="row justify-content-center">
              <div className="col-md-12 p-0">
                <div className="row g-3">
                  <div className="col-md-2">
                    <select
                      className="form-select rounded-1"
                      onChange={(e) => setSelectedPeriod(e.target.value)}
                      value={selectedPeriod}
                    >
                      <option value="">This Year</option>
                      <option value="">Last Year</option>
                      <option value="">This Month</option>
                      <option value="">This Week</option>
                    </select>
                  </div>
                  {LoginData?.userpro?.status_id === 6 ? (
                    <div className="col-md-2">
                      {" "}
                      <button
                        className="btn btn-sm btn-outline-primary px-2 float-md-end float-none d-flex align-tems-center gap-1"
                        disabled={true}
                      >
                        Request Payout{" "}
                        <IoIosLock style={{ fontSize: "20px" }} />
                      </button>{" "}
                    </div>
                  ) : (
                    <div
                      className="col-md-2"
                      onClick={() => {
                        toggleModal();
                        setSelectedTaxPro();
                        setHandleAmountMethod();
                        setModalMessage({ show: false });
                        if (!LoginData?.isTaxPro) {
                          dispatch({
                            type: PAYOUT_ACTION_TYPE.requestPayoutIdle,
                          });
                          dispatch({ type: PAYOUT_ACTION_TYPE.balanceIdle });
                          setTotal_balance();
                        }
                      }}
                    >
                      <Link to="#" className={`btn btn-primary rounded-1`}>
                        Request Payout
                      </Link>
                    </div>
                  )}
                </div>
                <div className="row g-3 justify-content-start mt-2">
                  <div className="col-md-4">
                    <div className="clearfix card border-1 border-light rounded">
                      <div className="card-body">
                        <h5 className="fw-bold m-0">Total Jobs</h5>
                        <p className="fw-bold display-6 my-3">0</p>
                        <span className="badge bg-secondary-700 text-light d-inline-flex align-items-center">
                          <AiOutlineArrowUp className="me-1" />0
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-3">
									<div className="clearfix card border-1 border-light rounded">
										<div className="card-body">
											<h5 className="fw-bold m-0">Total Pendings</h5>
											<p className="fw-bold display-6 my-3">	$									
												{balance?.data?.pending?.[0]?.amount
													? adddecimal(balance?.data?.pending?.[0]?.amount / 100)
													: "0"}
											</p>
											<span className="badge bg-secondary-700 text-light d-inline-flex align-items-center">
												<AiOutlineArrowUp className="me-1" />0
											</span>
										</div>
									</div>
								</div> */}
                  <div className="col-md-4">
                    <div className="clearfix card border-1 border-light rounded">
                      <div className="card-body">
                        <h5 className="fw-bold m-0">Total Payouts</h5>
                        <p className="fw-bold display-6 my-3">
                          {getPayout?.data?.length ? getPayout?.count : "$0"}
                        </p>
                        <span className="badge bg-secondary-700 text-light d-inline-flex align-items-center">
                          <AiOutlineArrowUp className="me-1" />0
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="clearfix card border-1 border-light rounded">
                      <div className="card-body">
                        <h5 className="fw-bold m-0">Total Balance</h5>
                        {/* For Tax Pro  */}
                        {LoginData.isTaxPro && (
                          <p className="fw-bold display-6 my-3">
                            ${total_balance ? total_balance : "0"}
                          </p>
                        )}
                        {/* For Others  */}
                        {!LoginData.isTaxPro && (
                          <p className="fw-bold display-6 my-3">
                            ${totalAmount ? totalAmount : "0"}
                          </p>
                        )}
                        <span className="badge bg-secondary-700 text-light d-inline-flex align-items-center">
                          <AiOutlineArrowUp className="me-1" />0
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix mt-5">
                <div className="col-md-4">
                  <div className="d-flex flex-row gap-3">
                    {(LoginData?.isAdmin || LoginData?.isAccountManager) && (
                      <Input
                        type="search"
                        value={keyword}
                        name="name"
                        inputClass="search-input border h-auto min-height-initial"
                        required={false}
                        showLabel={false}
                        placeholder="Search by fullname"
                        fn={filterByKeyword}
                        extraSetter={() => {
                          if (page !== 1) moveTo(1);
                        }}
                      />
                    )}
                    <div className="form-floating">
                      <DatePicker
                        className="form-select"
                        wrapperClassName="d-block"
                        selected={dateCreated}
                        maxDate={new Date()}
                        onChange={(date) => {
                          filterByDateCreated(date);
                          if (page !== 1) moveTo(1);
                        }}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Date Withdrawed"
                        isClearable
                      />
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <tbody></tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      }
      paginationProps={{
        pageCount: Math.ceil(getPayout?.count / perPage),
        currentPage: parseInt(page),
        setCurrentPage: moveTo,
        perPage: perPage,
        setPerPage: (pc) => {
          changePerPage(pc);
          // Move to first page
          if (page !== 1) moveTo(1);
        },
      }}
      alert={{}}
      dataState={{
        loaded: true,
        fetching: false,
      }}
      tableHeader={
        <Fragment>
          <th scope="col">ID</th>
          {(LoginData?.isAdmin || LoginData?.isAccountManager) && (
            <th scope="col">Tax Pro</th>
          )}
          <th scope="col">Amount</th>
          <th scope="col">Date Withdrawed</th>
          <th scope="col" className="text-center">
            Status
          </th>
          <th scope="col" className="text-center d-none"></th>
        </Fragment>
      }
      tableBody={
        <Fragment>
          {getPayout?.data &&
            getPayout?.data?.length > 0 &&
            getPayout?.data?.map((data, index) => {
              console.log(data);
              return (
                <tr key={index}>
                  <td>{data?.id}</td>
                  {(LoginData?.isAdmin || LoginData?.isAccountManager) && (
                    <td>{data?.fullname}</td>
                  )}
                  <td>{moneyFormatter(data?.amount)}</td>
                  <td>
                    {data?.created_at &&
                      moment(data?.created_at).format("DD-MM-YYYY")}
                  </td>
                  <td className="text-center">
                    {getPayoutStatusName(data.status)}
                  </td>
                  <td className="text-center d-none"></td>
                </tr>
              );
            })}
          <tr>
            {getPayout?.status === true && getPayout?.data?.length === 0 && (
              <td colSpan={5} className="text-muted text-center">
                You have not created any payout request yet.
              </td>
            )}
          </tr>
        </Fragment>
      }
      modals={
        <Fragment>
          <Modal
            title="Request Payout"
            bodyClass="p-4"
            btnText="Request Payout"
            trigger="RequestPayoutPayment"
            showBtn={false}
          >
            {modalMessage.show ? <Message message={modalMessage} /> : <></>}
            <div className=" mb-3">
              <div className="w-100 d-flex align-items-center justify-content-between ">
                <div>
                  <small>Total Balance</small>
                  <h4>${total_balance ? total_balance : 0}</h4>
                </div>

                <div>
                  <small>Pending Balance</small>
                  <h4>
                    $
                    {balance?.data?.pending?.[0]?.amount
                      ? adddecimal(balance?.data?.pending?.[0]?.amount / 100)
                      : 0}
                  </h4>
                </div>
              </div>
              <hr />
              {!user?.isTaxPro && (
                <div className="clearfix w-100 mb-3">
                  <div className="clearfix">
                    <DropDown
                      options={taxProList}
                      value={selectedTaxPro}
                      setValue={setSelectedTaxPro}
                      label={"Select Taxpro"}
                      showLabel={false}
                    />
                  </div>
                </div>
              )}
              <div className="w-100">
                <p className="text-left w-100">
                  Select the amount you want to request payout
                </p>
                <div class="form-check">
                  <input
                    onChange={() => {
                      setHandleAmountMethod(true);
                      setAmount(remaningBalance.toFixed(2));
                    }}
                    checked={handleAmountMethod === true}
                    className="form-check-input"
                    type="radio"
                    id={`balance`}
                    name={`checkbox`}
                  />
                  <label class="form-check-label" for="balance">
                    Available for Withdraw : $
                    {remaningBalance ? remaningBalance?.toFixed(2) : "0"}
                  </label>
                </div>
                <div class="form-check">
                  <input
                    onChange={() => {
                      setHandleAmountMethod(false);
                      setAmount("");
                    }}
                    checked={handleAmountMethod === false}
                    className="form-check-input"
                    type="radio"
                    id={`other`}
                    name={`checkbox`}
                  />
                  <label className="form-check-label" for="other">
                    Custom Balance
                  </label>
                </div>
              </div>
            </div>

            <div>
              <form
                className="passwordReset needs-validation"
                onSubmit={handleFormSubmit}
              >
                {handleAmountMethod === false && (
                  <div className="mb-3">
                    <Input
                      type="text"
                      value={amount}
                      name="amount"
                      placeholder="Enter amount - $"
                      inputClass={amount > remaningBalance ? "is-invalid" : ""}
                      fn={setAmount}
                      showLabel={false}
                      mask={"9999999"}
                    />
                  </div>
                )}
                {Object.keys(errors)
                  ?.map((key) => {
                    return errors[key];
                  })
                  ?.map((data, index) => {
                    return (
                      <>
                        <small className="text-primary" key={index}>
                          {data}*
                        </small>
                        <br />
                      </>
                    );
                  })}
                {amount > remaningBalance + 1 && (
                  <div className="text-danger text-center">
                    The amount you enter is more than the remaining balance.
                  </div>
                )}
                {selectedTaxPro &&
                  !balance?.data?.stripeConnectAccountData?.payouts_enabled && (
                    <div className="text-danger text-center">
                      Right now you are not able to payout ,first complete your
                      verification from settings page
                    </div>
                  )}
                {LoginData?.isTaxPro &&
                  !dashboard?.stripeAccountStatusData?.data
                    ?.payouts_enabled && (
                    <div className="text-danger text-center">
                      Right now you are not able to payout ,first complete your
                      verification from settings page
                    </div>
                  )}
                <Button
                  disabled={
                    remaningBalance <= 0 ||
                    amount <= 0 ||
                    amount > remaningBalance + 1 ||
                    handleAmountMethod === undefined ||
                    !dashboard?.stripeAccountStatusData?.data?.payouts_enabled
                  }
                  mainClass={`btn btn-danger btn-md`}
                  wrapperClass="clearfix d-grid text-center mt-4"
                  buttonText="Request payout"
                />
              </form>
            </div>
          </Modal>
		  <div className="clearfix dashboard-Page">
        <Header name="Payouts" />
        <div className="container page"></div>
        <Link
          to="#"
          id="RequestPayoutPaymentModal"
          className="text-decoration-none text-dark"
          data-bs-toggle="modal"
          data-bs-target="#RequestPayoutPayment"
        ></Link>
        <Footer />
      </div>
        </Fragment>
      }
    >
     
    </ListPageWrapper>
  );
};

export default Payouts;
