import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import UserAvatar from '../components/UserAvatar';
import BookAppointmentForm from '../components/forms/BookAppointmentForm';
import SEO from '../components/SEO';
import { BASEURL } from '../utilites';

const BookAppointment = () => {
	const [leadDetails, setLeadDetails] = useState();
	const [operatorAppointments, setOperatorAppointments] = useState([]);
	const [searchParams] = useSearchParams();
	const [loading,setLoading] = useState(false)

	useEffect(() => {
		// axios.get(`${BASEURL}/api/professionals/${searchParams.get('pro_id')}`).then((response) => {
		// 	setProData(response.data);
		// });
		setLoading(true)
		axios
			.get(
				`${BASEURL}/api/booking/${searchParams.get('lead')}/${searchParams.get(
					'customer'
				)}/${searchParams.get('operator')}`
			)
			.then((response) => {
				setLeadDetails(response.data.data.lead);
				setOperatorAppointments(response.data.data.appointments);
					setLoading(false)	
			});
	}, [searchParams]);

		return (
			<div className='clearfix'>
				<SEO title='Congratulations!' />
				<div className='page bg-primary-200 px-3'>
					 {loading && <div style={{ height: '70vh',display:`${loading ? 'flex' : 'none'}`}} className=' justify-content-center align-items-center '>
							<div className="d-flex justify-content-center" >
  								<div className="spinner-border  text-light" style={{width: '6rem', height: '6rem', borderWidth:'10px'}} role="status">
 									 </div>
								</div>
							</div>}
					<div className='container'>
						 {!loading && <div  style={{ height: '130vh'}} className='clearfix w-85 mx-auto text-center py-5'>
							<UserAvatar
								avatar={leadDetails?.userpro.user.avatar}
								alt={`${leadDetails?.userpro.user.firstname} ${leadDetails?.userpro.user.lastname}`}
								className='img-fluid rounded-circle border border-3 border-primary'
								width='100'
								height='100'
							/>
							<h1 className='mt-2 text-primary'>{`${leadDetails?.userpro.user.firstname} ${leadDetails?.userpro.user.lastname}`}</h1>
							<div className="w-75 mx-auto">
								<p className='text-dark px-5'>
								<span className='text-primary fs-4'>Congratulations!</span> You've been assigned to
									our amazing Tax Professional <span className="fw-bold text-dark">{leadDetails?.userpro.user.firstname}{' '}
									{leadDetails?.userpro.user.lastname}.</span> Please a select time to meet with {leadDetails?.userpro.user.firstname}{' '}
									that works best for you.
								</p>
							</div>
							<div className='w-75 mx-auto mb-5'>
								<BookAppointmentForm appointments={operatorAppointments} lead={leadDetails} />
							</div>
						</div>}
					</div>
				</div>
			</div>
		);
	}

export default BookAppointment;
