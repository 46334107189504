import { useState } from 'react';
import { Document, Page } from 'react-pdf';

export default function RenderMultiPagePDF({ file }) {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		setPageNumber(1);
	}
	function changePage(offset) {
		setPageNumber((prevPageNumber) => prevPageNumber + offset);
	}
	function previousPage() {
		changePage(-1);
	}
	function nextPage() {
		changePage(1);
	}
	return (
		<>
			<Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
				<Page pageNumber={pageNumber} className="react-pdf__Page-multiPagePDF overflow-hidden" />
			</Document>
			<div>
				<p>
					Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
				</p>
				<button
					className="btn btn-primary me-1"
					type="button"
					disabled={pageNumber <= 1}
					onClick={previousPage}
				>
					Previous
				</button>
				<button
					className="btn btn-primary ms-1"
					type="button"
					disabled={pageNumber >= numPages}
					onClick={nextPage}
				>
					Next
				</button>
			</div>
		</>
	);
}
