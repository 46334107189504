/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import DropDown from '../../fields/DropDown';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';
import SingleCheckLabelSelector from '../../fields/SingleCheckLabelSelector';
import { HandleFormData } from '../../../../utilites';

const BusinessInformation = ({ FormHeading, dateHeading, extraDateInput }) => {
	//
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//

	const dispatch = useDispatch();

	const { entityType, addressType } = GLOBAL_VARIABLES;

	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 0 });

	const [{ taxPayerForm,formInformation }] = useSelector((state) => [state.taxForms]);

	const [ein, setEin] = useState();
	const [BusinessLegalName, setBusinessLegalName] = useState();
	const [doingBusinessAs, setDoingBusienssAs] = useState();
	const [phoneNumber, setphoneNumber] = useState();
	const [dateBusinessStarted, setDateBusinessStarted] = useState();
	const [addressTypeLocal, setAddressTypeLocal] = useState();
	const [date, setDate] = useState();

	// For dropdowns, initialize with null or your initial value.
	const [EntityType, setEntityType] = useState(null);

	const [errors, setErrors] = useState({}); // Track errors for form fields

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.BusinessInformation) {
			if (Object.keys(taxPayerForm?.data?.BusinessInformation).length === 0) return;
			const {
				BusinessLegalName,
				ein,
				EntityType,
				doingBusinessAs,
				phoneNumber,
				dateBusinessStarted,
				addressTypeLocal,
				date,
			} = taxPayerForm?.data?.BusinessInformation;

			setBusinessLegalName(BusinessLegalName);
			setEin(ein);
			setDoingBusienssAs(doingBusinessAs);
			setphoneNumber(phoneNumber);
			setDateBusinessStarted(dateBusinessStarted);
			setDate(date);
			if (entityType) setEntityType(EntityType);
			if (addressType) setAddressTypeLocal(addressTypeLocal);
		}
	}, [taxPayerForm?.data?.BusinessInformation]);

	// ---------------------------------------
	// Functions
	// ---------------------------------------
	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		// Add validation rules for each field
		if (!addressTypeLocal && FormHeading === 'Incorporation')
			newErrors.addresType = 'Please Select Incorporation Type';
		// Add more validation rules as needed for other fields

		setErrors(newErrors);

		// Check if there are any errors

		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let errors = check_Validity();
		if (errors) return;

		let FormObj = {
			ein,
			EntityType,
			phoneNumber,
			doingBusinessAs,
			BusinessLegalName,
			dateBusinessStarted,
			addressTypeLocal,
			extraDateInput,
			date,
		};

		if (FormHeading === 'Organization') delete FormObj.addressTypeLocal;
		if (FormHeading === 'Incorporation') delete FormObj.EntityType;
		if (!extraDateInput) delete FormObj.date;
		let formData = taxPayerForm;
		taxPayerForm.data.BusinessInformation = FormObj;
		console.log("FData##", formData)
		dispatch(allActions.taxForms.StoreFormData({ formData }));
		uploadFormData(formData);
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<small className="ml-4 fw-bold">Basic Information</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="BusinessLegalName"
						value={BusinessLegalName}
						placeholder="Business legal name"
						fn={setBusinessLegalName}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="doingBusinessAs"
						value={doingBusinessAs}
						placeholder="Doing business as"
						fn={setDoingBusienssAs}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="ein"
						value={ein}
						placeholder="Ein"
						fn={setEin}
						mask="(99) 9999999"
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="phoneNumber"
						placeholder="Phone number"
						mask="(999) 999-9999"
						value={phoneNumber}
						fn={setphoneNumber}
					/>
				</div>

				{extraDateInput && (
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name={extraDateInput}
							placeholder={extraDateInput}
							value={date}
							fn={setDate}
							mask={'99-99-9999'}
						/>
					</div>
				)}
			</div>
			<small className="ml-4 fw-bold">{FormHeading} Details</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3 mb-3 custom-left-form">
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="dateBusinessStarted"
						placeholder={`Date ${dateHeading}`}
						value={dateBusinessStarted}
						fn={setDateBusinessStarted}
						mask={'99-99-9999'}
					/>
				</div>

				{FormHeading === 'Incorporation' && (
					<div className="col-md-6 mb-3">
						<SingleCheckLabelSelector
							heading={`${FormHeading} type`}
							value={addressTypeLocal}
							setValue={setAddressTypeLocal}
							mapData={addressType}
						/>
					</div>
				)}

				{FormHeading === 'Organization' && (
					<div className="col-md-6 mb-3">
						<DropDown
							options={entityType}
							setValue={(e) => {
								setEntityType(e);
							}}
							value={EntityType}
							label={'Entity type'}
						/>
					</div>
				)}
			</div>

			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default BusinessInformation;
