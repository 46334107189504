function validatePassword(key, pass, rePass, state, setState) {
	if (pass !== '' && rePass !== '') {
		if (pass === rePass && state[key]) {
			setState({ ...state, [key]: false });
		} else if (pass !== rePass && !state[key]) {
			setState({ ...state, [key]: true });
		}
	} else if (state[key]) {
		setState({ ...state, [key]: false });
	}
}

export { validatePassword };
