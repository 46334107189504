import { RECORDINGS_ACTION_TYPE } from "./Type";

const DEFAULT_STATE = {
	getRecordings: { message: "", status: "idle", data: {} },
	saveRecording: { message: "", status: "idle", data: {} },
	playRecording: { message: "", status: "idle", data: {} },
};

const INITIAL_STATE = {
	...DEFAULT_STATE,
};

const recordings = (state = INITIAL_STATE, action) => {
	if (action.type === RECORDINGS_ACTION_TYPE.getRecordings) {
		return {
			...state,
			getRecordings: action.payload,
		};
	}
	if (action.type === RECORDINGS_ACTION_TYPE.saveRecording) {
		return {
			...state,
			saveRecording:action.payload,
		};
	}
	if (action.type === RECORDINGS_ACTION_TYPE.playRecording) {
		return {
			...state,
			playRecording:action.payload,
		};
	}
	// if (action.type === PLAID_ACTION_TYPE.plaidGetUpload) {
	// 	return {
	// 		...state,
	// 		plaidGetUpload: action.payload,
	// 	};
	// }
	// if (action.type === PLAID_ACTION_TYPE.plaidGetUploadIdle) {
	// 	return {
	// 		...state,
	// 		plaidGetUpload: DEFAULT_STATE.plaidGetUpload,
	// 	};
	// }

	return state;
};

export default recordings;
