/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../Store/action";
import Button from "./fields/Button";
import Message from "./fields/Message";
import { PLAID_ACTION_TYPE } from "../../Store/plaid/Type";

export default function VerificationForm({ setActive, note, steps }) {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const dispatch = useDispatch();
	const [{ plaidCreate, plaidGetUpload }] = useSelector((state) => [state.plaid]);
	const [message, setMessage] = useState({ show: false });
	// --------------------------------------------
	// HOOKS (UseEffect used For Different Purposes)
	// --------------------------------------------
	//  *****--HOOKS To Create Plaid Link For Verification--****
	useEffect(() => {
		reset_mesage();
		// if Success

		if (plaidCreate?.status === true) {
			// if Sucessfully Verified already
			if (plaidCreate?.data?.status === "success") {
				setMessage({
					show: true,
					type: "Success",
					text: PLAID_ACTION_TYPE.PlaidPictureUploadingMessage,
				});
				dispatch(
					allActions.plaid.getPlaidUpload({
						VerificationObj: {
							verificationID: plaidCreate?.data?.id,
						},
					})
				);
			}
			// if Not Sucessfully Verified OR New Create
			if (plaidCreate?.data?.status !== "success") {
				setMessage({
					show: true,
					type: "Success",
					text: plaidCreate?.message,
				});
				openMe(plaidCreate?.data?.shareable_url);
				dispatch(allActions.global.Loader(false));
			}
		}
		// if Failure
		if (plaidCreate?.status === false) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: "Error",
				text: plaidCreate?.message,
			});
		}
	}, [plaidCreate, plaidCreate?.status, plaidCreate?.message]);

	//  *****--HOOKS To Check User is Verified or Not--****
	useEffect(() => {
		reset_mesage();
		// if Success
		if (plaidGetUpload?.status === true) {
			dispatch(allActions.global.Loader(false));
			moveToNext();
			reset_All();
		}
		// if Failure
		if (plaidGetUpload?.status === false) {
			setMessage({
				show: true,
				type: "Error",
				text: plaidGetUpload?.message,
			});
			dispatch(allActions.global.Loader(false));
		}
	}, [plaidGetUpload, plaidGetUpload?.status, plaidGetUpload?.message]);

	// Function To Open Web Links
	const openMe = (linkUrl) => {
		if (!linkUrl) return;
		window.open(linkUrl, "_blank");
	};

	// Function To Generate Verification Link
	const handleSubmit = (e) => {
		e.preventDefault();
		reset_All();
		dispatch(allActions.global.Loader(true));
		dispatch(allActions.plaid.createPlaid());
		// Check Validity
	};

	// Function To Move to Next Tab
	const moveToNext = () => {
		dispatch(
			allActions.user.ReGetUserData(() => {
				if (setActive) setActive(6);
			})
		);
	};

	// Function To Move Back Tab
	const GoBack = () => {
		reset_All();
		dispatch(
			allActions.user.ReGetUserData(() => {
				if (setActive) setActive(4);
			})
		);
	};

	// Function To Reset Plaid Store & Loading & Messages
	const reset_All = () => {
		setMessage({ show: false });
		dispatch(allActions.global.Loader(false));
		dispatch(allActions.plaid.dispatchToStore({ type: PLAID_ACTION_TYPE.plaidCreateIdle }));
		dispatch(allActions.plaid.dispatchToStore({ type: PLAID_ACTION_TYPE.plaidGetUploadIdle }));
	};

	const reset_mesage = () => {
		setMessage({ show: false });
	};
	return (
		<div>
			<form className="ContactInfoForm needs-validation" onSubmit={handleSubmit}>
				{message.show ? <Message message={message} /> : <></>}
				<p className="text-dark fw-bold text-center">Verify Your Identity By Clicking Below</p>
				<p className="text-dark text-center text-muted mt-2 small px-4">{note}</p>
				<div className="container-fluid content-row ">
					<div className="d-flex align-items-center justify-content-center row">
						{steps && (
							<Button
								mainClass="btn btn-outline-primary btn-lg w-100"
								buttonText="Previous"
								wrapperClass={"w-25 custon-button  mt-4"}
								type="button"
								callback={() => {
									GoBack();
								}}
							/>
						)}
						<Button
							mainClass={`btn btn-primary custon-button-text ${steps ? "btn-lg" : "btn-md"}  w-100`}
							buttonText="Verify Identity"
							wrapperClass={`${steps ? "w-25" : "w-50"} custon-button  mt-4`}
							type="submit"
						/>
						{steps && (
							<Button
								mainClass="btn btn-outline-primary custon-button-text btn-lg w-100"
								buttonText="Skip"
								wrapperClass={"w-25 custon-button  mt-4"}
								type="button"
								callback={() => {
									moveToNext();
									reset_All();
								}}
							/>
						)}
					</div>
				</div>
			</form>
		</div>
	);
}
