/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Input from '../../fields/Input';
import DropDown from '../../fields/DropDown';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import SingleCheckLabelSelector from '../../fields/SingleCheckLabelSelector';

const AddPartnerInformation = ({ PartnerList, setPartnerList }) => {
	const { entityType, addressType, PartnerInformationOption, PartnerType } = GLOBAL_VARIABLES;

	const [email, setEmail] = useState();
	const [ssnitin, setSsnitin] = useState();
	const [taxReurn, setTaxReturn] = useState();
	const [lastName, setLastName] = useState();
	const [firstName, setFirstName] = useState();
	const [middleName, setMiddleName] = useState();
	const [addresType, setAdresType] = useState();
	const [phoneNumber, setPhoneNumber] = useState();
	const [premiumPayment, setPremiumPayment] = useState();
	const [guaranteePayments, setGuaranteePayments] = useState();
	const [partnerTypeSelected, setpartnerTypeSelected] = useState();
	const [capitalContribution, setCapitalContribution] = useState();
	const [partnerInformationType, setPartnerInformationType] = useState();
	const [ownershipPercentageEnd, setOwnershipPercentageEnd] = useState();
	const [ownershipPercentageStart, setOwnershipPercentageStart] = useState();
	const [distributionContribution, setDistributionContribution] = useState();

	// For dropdowns, initialize with  or your initial value.
	const [selectedEntityType, setEntityType] = useState();

	// For Choice Questions

	const [errors, setErrors] = useState({}); // Track errors for form fields

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	function resetFormStates() {
		setEmail();
		setSsnitin();
		setTaxReturn();
		setLastName();
		setFirstName();
		setMiddleName();
		setAdresType();
		setEntityType({});
		setPhoneNumber();
		setPremiumPayment();
		setGuaranteePayments();
		setpartnerTypeSelected();
		setCapitalContribution();
		setPartnerInformationType();
		setOwnershipPercentageEnd();
		setOwnershipPercentageStart();
		setDistributionContribution();
	}

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		if (!addresType) newErrors.addresType = 'Please Select Address Type';
		if (!partnerInformationType || !partnerTypeSelected)
			newErrors.addresType = 'Please Select Partner Information';

		setErrors(newErrors);
		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmitForm = () => {
		let errors = check_Validity();
		if (errors) return;
		window.scrollTo(0, 0);

		let FormObj = {
			email,
			ssnitin,
			taxReurn,
			lastName,
			firstName,
			middleName,
			addresType,
			phoneNumber,
			premiumPayment,
			guaranteePayments,
			selectedEntityType,
			partnerTypeSelected,
			capitalContribution,
			partnerInformationType,
			ownershipPercentageEnd,
			ownershipPercentageStart,
			distributionContribution,
		};

		let OldPartnerList = PartnerList;
		OldPartnerList.push(FormObj);
		setPartnerList(OldPartnerList);
		console.log('FormObj', FormObj);
		resetFormStates();
	};
	console.log('PartnerList', PartnerList);
	return (
		<div className="my-2">
			<table className="table">
				<thead>
					<tr>
						<th scope="col">Name</th>
						<th scope="col">email</th>
						<th scope="col">SSN</th>
						<th scope="col">Entity Type</th>
					</tr>
				</thead>
				<tbody>
					{PartnerList &&
						PartnerList?.map((data) => {
							console.log(data?.Relationship);
							return (
								<tr>
									<td>{data?.firstName}</td>
									<td>{data?.email}</td>
									<td>{data?.ssnitin}</td>
									<td>{data?.selectedEntityType?.label}</td>
								</tr>
							);
						})}
				</tbody>
			</table>
			<form
				className="col-md-11 bg-light mt-3 shadow-sm mx-4 p-3"
				onSubmit={(e) => {
					e.preventDefault();
					handleSubmitForm();
				}}
			>
				<small className="ml-4 fw-bold">Partner Information</small>
				<hr className="m-0" />
				<div className="mt-3 row justify-content-between px-3  custom-left-form">
					<div className="col-md-6 mb-3">
						<DropDown
							options={entityType}
							setValue={(e) => {
								setEntityType(e);
							}}
							value={selectedEntityType}
							label={'Select Entity Type'}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="firstName"
							value={firstName}
							placeholder="First Name"
							fn={setFirstName}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="middleName"
							value={middleName}
							placeholder="Middle Initial"
							fn={setMiddleName}
							required={false}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="lastName"
							value={lastName}
							placeholder="Last Name"
							fn={setLastName}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="ssnitin"
							placeholder="SSN/ITIN"
							value={ssnitin}
							fn={setSsnitin}
						/>
					</div>
					<div className="mt-3 row justify-content-between col-md-12">
						<div className=" mb-3 px-3">
							<small className="text-dark fw-bold m-0" htmlFor="setpartnerTypeSelected">
								{PartnerType[0].question}
							</small>
							{JSON.parse(JSON.stringify(PartnerType))[0].options?.map((data, i) => {
								return (
									<div
										key={i}
										className="w-100 d-flex flex-row align-items-center justify-content-between mt-2"
									>
										<div className="col-md-12">
											<input
												onChange={() => setpartnerTypeSelected(data)}
												checked={partnerTypeSelected === data}
												className="form-check-input"
												type="radio"
												id={`setpartnerTypeSelected-${i}`}
												name="setpartnerTypeSelected"
											/>
											<label
												htmlFor={`setpartnerTypeSelected-${i}`}
												role="button"
												className="px-3 m-0 small"
											>
												{data}
											</label>
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className="mt-3 row justify-content-between col-md-12">
						<div className=" mb-3 px-3">
							<small className="text-dark fw-bold m-0" htmlFor="partnerInformationType">
								{PartnerType[0].question}
							</small>
							{JSON.parse(JSON.stringify(PartnerInformationOption))[0].options?.map((data, i) => {
								return (
									<div
										key={i}
										className="w-100 d-flex flex-row align-items-center justify-content-between mt-2"
									>
										<div className="col-md-12">
											<input
												onChange={() => setPartnerInformationType(data)}
												checked={partnerInformationType === data}
												className="form-check-input"
												type="radio"
												id={`partnerInformationType-${i}`}
												name="partnerInformationType"
											/>
											<label
												htmlFor={`partnerInformationType-${i}`}
												role="button"
												className="px-3 m-0 small"
											>
												{data}
											</label>
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className="mt-3 row justify-content-between col-md-12">
						<div className=" mb-3 px-3">
							<small className="text-dark fw-bold m-0" htmlFor="taxReturn">
								Select if applicable
							</small>

							<div className="w-100 d-flex flex-row align-items-center justify-content-between mt-2">
								<div className="col-md-12">
									<input
										onChange={() => setTaxReturn(!taxReurn)}
										checked={taxReurn}
										className="form-check-input"
										type="checkbox"
										id={`taxReturn`}
										name="taxReturn"
									/>
									<label htmlFor="taxReturn" role="button" className="px-3 m-0 small">
										This partner will sign the tax return
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<small className="ml-4 fw-bold">Partner Contact</small>
				<hr className="m-0" />
				<div className="mt-3 row justify-content-between  ">
					<SingleCheckLabelSelector
						heading={'Select address type'}
						value={addresType}
						setValue={setAdresType}
						mapData={addressType}
					/>
					<div className="col-md-6 mb-3">
						<Input type="text" name="email" value={email} fn={setEmail} placeholder="Email" />
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="PhoneNumber"
							placeholder="Phone Number"
							value={phoneNumber}
							fn={setPhoneNumber}
							mask="(999) 999-9999"
						/>
					</div>
				</div>
				<small className="ml-4 fw-bold">Partner ownership</small>
				<hr className="m-0" />
				<div className="mt-3 row justify-content-between px-3  custom-left-form">
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="OwnershipPercentageBeginning"
							value={ownershipPercentageStart}
							fn={setOwnershipPercentageStart}
							placeholder="Ownership Percentage (Beginning of year) %"
							mask={'999'}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="OwnershipPercentageEnd"
							value={ownershipPercentageEnd}
							fn={setOwnershipPercentageEnd}
							placeholder="Ownership Percentage (End of year) %"
							mask={'999'}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="capitalContribution"
							value={capitalContribution}
							fn={setCapitalContribution}
							placeholder="Capital contribution"
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="distributionContribution"
							value={distributionContribution}
							fn={setDistributionContribution}
							placeholder="Distribution during the year"
						/>
					</div>
				</div>
				<small className="ml-4 fw-bold">Partner other information</small>
				<hr className="m-0" />
				<div className="mt-3 row justify-content-between px-3  custom-left-form">
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="OwnershipPercentageBeginning"
							value={guaranteePayments}
							fn={setGuaranteePayments}
							placeholder="Guaranteed payments"
							mask={'99999999'}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="premiumPayment"
							value={premiumPayment}
							fn={setPremiumPayment}
							placeholder="Amount paid for health insurance"
							mask={'99999999'}
						/>
					</div>
				</div>
				{Object.keys(errors)
					?.map((key) => {
						return errors[key];
					})
					?.map((data, index) => {
						return (
							<>
								<small className="text-primary" key={index}>
									{data}*
								</small>
								<br />
							</>
						);
					})}
				<div className="container-fluid content-row pb-4">
					<div className="d-flex align-items-center justify-content-center row">
						<Button
							mainClass="btn btn-primary custon-button-text   w-100"
							buttonText="Add Partner"
							wrapperClass={'w-25 custon-button  mt-4'}
							type={'submit'}
						/>
					</div>
				</div>
				{PartnerList.length === 0 && (
					<>
						<small className="ml-4 fw-bold">No signing partner selected</small>
						<hr className="m-0" />
						<small className="ml-4">
							Select the partner who will sign the tax return. Create or open an entry for the
							signing partner above and check the "This partner will sign the tax return box.
						</small>
					</>
				)}
			</form>
		</div>
	);
};

export default AddPartnerInformation;
