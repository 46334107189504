/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Input from './Input';

const MultiLineDoubleFieldInputs = ({
	Options,
	handleChange,
	firstPlaceHolder,
	secondPlaceHolder,
}) => {
	return (
		<div className="w-100 d-flex flex-row flex-wrap justify-content-between align-items-end mb-3 px-4 mt-3">
			{Options?.map((data, i) => {
				return (
					<div
						key={i}
						className="w-100 d-flex align-items-end justify-content-between flex-wrap px-2 my-2"
					>
						<p className=" col-md-4 form-check-label text-start fw-bold">{data.question}</p>
						<div className="d-flex flex-row justify-content-between col-md-8 flex-wrap mt-3">
							<div className="col-md-5">
								<Input
									type="text"
									value={data[Object.keys(data)[1]]}
									name={firstPlaceHolder}
									placeholder={firstPlaceHolder}
									formid={`${firstPlaceHolder}${i}`}
									fn={() => {
										handleChange(`${firstPlaceHolder}${i}`, i, Object.keys(data)[1]);
									}}
									mask={'999999999'}
									label={firstPlaceHolder}
								/>
							</div>
							<div className="col-md-5">
								<Input
									type="text"
									value={data[Object.keys(data)[2]]}
									name={secondPlaceHolder}
									placeholder={secondPlaceHolder}
									formid={`${secondPlaceHolder}${i}`}
									fn={() => {
										handleChange(`${secondPlaceHolder}${i}`, i, Object.keys(data)[2]);
									}}
									mask={'999999999'}
									label={secondPlaceHolder}
								/>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default MultiLineDoubleFieldInputs;
