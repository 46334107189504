import Faq from '../components/home/Faq';
import SEO from '../components/SEO';

const FaqPage = () => {
	return (
		<div className='page bg-light px-3'>
			<SEO title='FAQ' />
			<div className='container'>
				<div className='clearfix pt-4 text-center mb-4'>
					<Faq isFaqPage />
				</div>
			</div>
		</div>
	);
};

export default FaqPage;
