function validate(key, value, regExp, state, setState) {
	if (value !== '') {
		if (regExp.test(value) && state[key]) {
			setState({ ...state, [key]: false });
		} else if (!regExp.test(value) && !state[key]) {
			setState({ ...state, [key]: true });
		}
	} else if (state[key]) {
		setState({ ...state, [key]: false });
	}
}

export { validate };
