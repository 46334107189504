/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import allActions from '../../../Store/action';
// Components
import Header from '../../dashboard/components/Header';
import Footer from '../components/Footer';
import { getJobStatus, getJobStatusClass, moneyFormatter } from '../../../utilites';
import Message from '../../../components/forms/fields/Message';

const ProjectList = () => {
	const dispatch = useDispatch();
	const [message, setMessage] = useState({ show: false });
	const [{ projects }, { payInvoice, payInvoiceMessage }] = useSelector((state) => [
		state.dashboard,
		state.invoice,
	]);

	useEffect(() => {
		dispatch(allActions.dashboard.getProjects());
	}, [dispatch]);

	// ******************************
	// HOOKS FOR PAYING INVOICES
	// ******************************
	useEffect(() => {
		if (payInvoice === true) {
			setMessage({
				show: true,
				type: 'Success',
				text: 'Invoice Paid Successfully!',
			});
		}
	}, [payInvoice, payInvoiceMessage]);



// const totalCostCalulations = (invoices,projectCost) => {
// 	let cost = 0;
// 	if(invoices?.length===0){
// 		cost=projectCost
// 	}else{	
// 	invoices?.forEach((element) => {
// 		if (!element?.name?.includes('subscription')) {
// 			cost += Number(element?.amount);
// 		}
// 	});
// }
// 	return cost;
// };

	return (
		<div className="clearfix">
			<Header name="Projects" />
			{message.show ? <Message message={message} /> : <></>}

			<div className="container page">
				{projects && projects.loaded && projects.data.length > 0 ? (
					<div className="row">
						<div className="col-12">
							{projects.data?.map((project, index) => (
								<div className="clearfix border border-light rounded-3 p-4 mt-4" key={index}>
									<h2 className="text-primary mb-3 fw-bold">
										{project?.service?.name || project.others}
									</h2>
									<p>
										Status:{' '}
										<span className={`ms-3 text-${getJobStatusClass(project.status_id)}`}>
											{getJobStatus(project.status_id)}
										</span>
									</p>
									<p>
										Completed by:{' '}
										<span className="ms-3">
											{new Date(project.completed_at).toLocaleString('en-US', {
												month: 'long',
												day: 'numeric',
												year: 'numeric',
											})}
										</span>
									</p>
									{project.status_id !== 5 && (
										<p>
											Total cost: <span className="ms-3">{moneyFormatter(project.cost)}</span>
										</p>
									)}
									<Link to={`/dashboard/projects/${project.uuid}`} className="btn btn-primary mt-2">
										View job
									</Link>
								</div>
							))}
						</div>
					</div>
				) : (
					<div className="clearfix text-center p-5">
						<p className="text-muted py-5">
							Your Tax Pro has not initiated any project for you yet.
						</p>
					</div>
				)}
			</div>
			<Footer />
		</div>
	);
};

export default ProjectList;
