import { Link } from 'react-router-dom';
import { IsLogin } from '../utilites/functions';

import Logo from '../assets/img/logo.png';

const Navbar = () => {
	return (
		<nav className='navbar navbar-expand-lg bg-white sticky-top'>
			<div className='container'>
				<Link className='navbar-brand' to='/'>
					<img src={Logo} alt='Uncle Kam App' />
				</Link>
				<button
					className='navbar-toggler'
					type='button'
					data-bs-toggle='collapse'
					data-bs-target='#navbarSupportedContent'
					aria-controls='navbarSupportedContent'
					aria-expanded='false'
					aria-label='Toggle navigation'
				>
					<span className='navbar-toggler-icon'></span>
				</button>
				<div className='collapse navbar-collapse' id='navbarSupportedContent'>
					<ul className='navbar-nav me-auto mb-2 mb-lg-0'></ul>
					{!IsLogin() ? (
						<ul className='navbar-nav fw-semibold px-3 px-md-0'>
							<li className='nav-item'>
								<Link className='nav-link one' to='/faq'>
									FAQ
								</Link>
							</li>
							<li className='nav-item'>
								<Link className='nav-link two' to='/login'>
									Login
								</Link>
							</li>
							<li className='nav-item'>
								<Link
									className='nav-link bg-primary text-white text-center text-md-start border border-2 border-primary rounded three'
									to='/register'
								>
									Get started
								</Link>
							</li>
						</ul>
					) : (
						<ul className='navbar-nav fw-semibold px-3 px-md-0'>
							<li className='nav-item'>
								<Link className='nav-link two' to='/dashboard'>
									Dashboard
								</Link>
							</li>
						</ul>
					)}
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
