/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';

import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import DropDown from '../../fields/DropDown';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';

const Retirement = () => {

    const { retirementOptions, DDEntityType}= GLOBAL_VARIABLES

	const dispatch=useDispatch()
	const [{ taxPayerForm, taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 9});

    const [selectedEntityType, setEntityType] = useState();
	const [howProvideReport, setHowProvideReport] = useState();
    const [specificQuestions, setSpecificQuestions] = useState([]);
    const [totalAmountContributed, setTotalAmountContributed]=useState();

	const [errors, setErrors] = useState({}); // Track errors for form fields

	const multipleChoice = [] ;
  
    const handleQuestions = (index, bool) => {
        console.log(index, bool);
        const localSpecificQuestions = specificQuestions.slice();
        localSpecificQuestions[index].bool = bool;
        setSpecificQuestions(localSpecificQuestions);
    };

    useEffect(() => {
		setSpecificQuestions(JSON.parse(JSON.stringify(retirementOptions)));
	}, []);

	useEffect(() => {
    	if (taxPayerForm?.data?.Retirement) {
    		if (Object.keys(taxPayerForm?.data?.Retirement).length === 0) return;
			const {
				selectedEntityType,
				howProvideReport,
				specificQuestions,
				totalAmountContributed
			} = taxPayerForm?.data?.Retirement;
			setEntityType(selectedEntityType)
			setHowProvideReport(howProvideReport)
			setSpecificQuestions(specificQuestions)
			setTotalAmountContributed(totalAmountContributed)
    	}
    }, [taxPayerForm?.data?.Retirement]);
	
	const check_Validity = () => {
		setErrors({});
		const newErrors = {};
	
		// Add more validation rules as needed for other fields
		if (
			specificQuestions
				?.flatMap((data) => {
					return data?.bool;
				})
				.includes(null)
		) {
			newErrors.AnswerOtherQuestions = 'Please Select Required Option';
		}
	
		setErrors(newErrors);
	
		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		let errors = check_Validity();
		if (errors) return;

		let FormObj = {
			selectedEntityType,
				howProvideReport,
				specificQuestions,
				totalAmountContributed
		};
        let formData = taxPayerForm;
        taxPayerForm.data.Retirement = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));
        uploadFormData(formData);
    };
		
	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 9;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			{/* <small className="ml-4 fw-bold">Retirement</small>
			<hr className="m-0" /> */}
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<div className="col-md-12 mb-3">
					<Input
						type="text"
						name="enityName"
						value={totalAmountContributed}
						placeholder="What is the total amount to be contributed to the employees in 2021, not including yourself or your spouse (if your spouse owns a % of the business)?"
						fn={setTotalAmountContributed}
					/>
				</div>
               <div className=" mb-3 px-3">
					{specificQuestions?.map((data, i) => {
						return (
							<div key={i} className="w-100  align-items-center justify-content-between mt-2">
								<small className="col-md-12 form-check-label text-start" htmlFor={`checkbox-${i}`}>
									{data.question}
									{data.bool === null && (
										<span className="text-danger small-text mx-1">*Required</span>
									)}
								</small>
								<div className="col-md-12">
									<input
										onChange={() => handleQuestions(i, true)}
										checked={data?.bool === true}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 176}`}
										name={`checkbox-${i + 176}`}
									/>
									<label htmlFor={`checkbox-${i + 176}`} role="button" className="px-3 m-0 small">
										Yes
									</label>
									<input
										onChange={() => handleQuestions(i, false)}
										checked={data?.bool === false}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 999}`}
										name={`checkbox-${i + 999}`}
									/>
									<label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
										No
									</label>
								</div>
							</div>
						);
					})}
					{JSON.parse(JSON.stringify(multipleChoice))?.map((data, i) => {
						return (
							<div key={i} className="w-100 my-2">
								<small
									className="col-md-6 form-check-label text-start fw-bold mb-1"
									htmlFor={`howProvideReport-${i}`}
								>
									{data.question}
									{!howProvideReport && (
										<span className="text-danger small-text mx-1">*Required</span>
									)}
								</small>
								{data?.options?.map((option, index) => {
									return (
										<div>
											<input
												onChange={() => setHowProvideReport(option)}
												checked={howProvideReport === option}
												className="form-check-input"
												type="radio"
												id={`howProvideReport-${index}`}
												name={`howProvideReport-${index}`}
											/>
											<label className="px-3 small" htmlFor={`howProvideReport-${index}`}>
												{option}
											</label>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
               <div className="col-md-6 mb-3 mt-2">
						<DropDown
							options={DDEntityType}
							setValue={(e) => {
								setEntityType(e);
							}}
							value={selectedEntityType}
							label={'What type of retirement plan?'}
						/>
				</div>
			</div>
			{Object.keys(errors)
    ?.map((key) => {
        return errors[key];
    })
    ?.map((data, index) => {
        return (
            <>
                <small className="text-primary" key={index}>
                    {data}*
                </small>
                <br />
            </>
        );
    })}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={()=>goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default Retirement;
