import React, { useState, useRef } from 'react';
import axios from 'axios';
import { BASEURL, validatePassword } from '../../utilites';
import Input from './fields/Input';
import Button from './fields/Button';
import Checkbox from './fields/Checkbox';

export default function EditUserPasswordForm({ userId, userEmail, setAlert }) {
	const [password, setPassword] = useState('');
	const [repeatPassword, setRepeatPassword] = useState('');
	const [sendMail, setSendMail] = useState([]);

	const [errors, setErrors] = useState({
		password: false,
		repeatPassword: false,
		general: '',
	});
	const button = useRef();

	const handleSubmit = (e) => {
		e.preventDefault();

		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });

		axios
			.put(`${BASEURL}/api/users/update-password`, {
				id: userId,
				email: userEmail,
				password,
				repeatPassword,
				sendMail: sendMail.length > 0 ? true : false,
			})
			.then(() => {
				button.current.disabled = false;
				// Close Modal
				document.querySelector('.passwordChangeModalClose').click();
				setAlert('Password has been updated successfully!');
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};
	return (
		<form className="userform needs-validation" onSubmit={handleSubmit}>
			<div className="row">
				<div className="mb-3">
					<Input
						type="password"
						value={password}
						name="password"
						placeholder="Password"
						fn={setPassword}
						secure={true}
						onLeave={validatePassword('password', password, repeatPassword, errors, setErrors)}
						hasValidationError={errors.password}
						wrapperClass="position-relative"
						showLabel={false}
					/>
				</div>
			</div>
			<div className="row">
				<div className="mb-3">
					<Input
						type="password"
						value={repeatPassword}
						name="repeat-password"
						placeholder="Repeat Password"
						fn={setRepeatPassword}
						secure={true}
						onLeave={validatePassword(
							'repeatPassword',
							password,
							repeatPassword,
							errors,
							setErrors
						)}
						hasValidationError={errors.repeatPassword}
						wrapperClass="position-relative"
						showLabel={false}
					/>
				</div>
			</div>
			<div className="row">
				<div className="mb-3 text-start">
					<Checkbox
						name="send-mail"
						state={sendMail}
						value={'yes'}
						label={'Send a new password to the user via email.'}
						fn={setSendMail}
						id={`send-mail`}
						required={false}
					/>
				</div>
			</div>
			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mb-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 ? ' disabled' : ''
				}`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-3"
				buttonText="Update Membership"
			/>
		</form>
	);
}
