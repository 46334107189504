import Loader from './components/Loader';
import RoutesCollection from './routes';
import { HelmetProvider } from 'react-helmet-async';
import SEO from './components/SEO';

const App = () => {
	return (
		<HelmetProvider>
			<SEO />
			<Loader />
			<RoutesCollection />
		</HelmetProvider>
	);
};

export default App;
