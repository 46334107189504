/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect,useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link,useParams } from 'react-router-dom';
import SweetAlert2 from 'react-sweetalert2';
import { AiOutlineCheck } from 'react-icons/ai';
import { BsThreeDotsVertical,BsThreeDots } from 'react-icons/bs';
import { BASEURL } from '../../../utilites';
import axios from 'axios';
import { FileTypeIcon } from '../../../utilites';
import allActions from '../../../Store/action';
import Modal from '../../../components/Dashboard/Modal';
import Message from '../../../components/forms/fields/Message';
import RequestJobDocumentForm from '../../../components/forms/RequestJobDocumentForm';
import UploadDraftDocumentForm from '../../../components/forms/UploadDraftDocumentForm';
import EditDocumentForm from '../../../components/forms/EditDocumentForm';
import RenderMultiPagePDF from '../components/RenderMultiPagePDF';
import FormPreviewModal from '../../../components/forms/Modal/FormPreviewModal';
import RequestJobTaxForm from '../../../components/forms/RequestJobTaxForm';
import FileIcon  from '../.../../../../assets/img/file.svg'
import DotMoreIcon from '../../../assets/img/dotmore.png'


export default function JobDocuments({
	data,
	reload,
	documentRequestData,
	documentRequestLeadData,
	leadUUID,
	taxProUserId,
}) {
	const documentsData = data?.documents;
	const draftsData = data?.drafts;
	// const [{ jobs }] = useSelector((state) => [state.jobs, state.user]);
	const [message, setMessage] = useState({ show: false });
	const [swalProps, setSwalProps] = useState({});
	const [selectedDocument, setSelectedDocument] = useState(null);
	const [documentToView, setDocumentToView] = useState();
	const [jobs,setJobs] = useState([])
    const {uuid} = useParams()


	const dispatch = useDispatch();

	const loadJobByUUID = useCallback((UUID) => {
		axios
			.get(`${BASEURL}/api/jobs/${UUID}`)
			.then((res) => {
				setJobs(res.data.data);
			})
			.catch((err) => {
				console.log('error>>>>', err);
			});
	}, []);

	useEffect(() => {
		loadJobByUUID(uuid)
	},[uuid])

	useEffect(() => {
		// if Status True
		if (jobs.status === true) {
			changeLoading(false);
		}

		// if Status False
		if (jobs.status === false) {
			changeLoading(false);
			setMessage({
				show: true,
				type: 'Error',
				text: jobs?.message,
			});
		}
		if (jobs.status === 'idle') {
			dispatch(allActions.jobs.getJobsByTaxPro({ uuid: leadUUID }));
		}
	}, [jobs.status, jobs.message, jobs?.data]);

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};
	const deleteDocument = (uuid) => {
		dispatch(allActions.dashboard.deleteDocument(uuid, data?.user?.id)).then(() => {
			// TODO: Improve this
			setTimeout(() => {
				reload();
			}, 500);
		});
	};

	const handleDeleteDocumentClick = (uuid) => {
		setSwalProps((prev) => ({
			...prev,
			[uuid]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				text: 'You will not be able to recover this document!',
				confirmButtonText: 'Yes, delete it!',
				denyButtonText: 'Cancel',
				didClose: () => setSwalProps((prev) => ({ ...prev, [uuid]: { show: false } })),
				onConfirm: () => deleteDocument(uuid),
			},
		}));
	};

	const openJobData = (form,id) => {
		const selectedDocument = jobs?.jobs[0]
		if (selectedDocument) selectedDocument.form_data[id].lead_id = selectedDocument.lead_id;
		dispatch(allActions.dashboard.resetTaxPreparation());
		const formExist=selectedDocument?.form_data?.find((data)=>data?.name===form?.name)
		dispatch(
			allActions.taxForms.dispatchViewFormDetails({
				document: formExist,
				navigate:()=>document.querySelector('.formPreview').click(),
			})
		);
	};



	// GET JOBS
	useEffect(() => {
		console.log("useEffectIdInJobDoc", leadUUID)
		dispatch(allActions.jobs.getJobsByTaxPro({ uuid: leadUUID }));
	}, []);

	useEffect(() => {
		if (jobs.status === true) {
			setSelectedDocument(
				jobs?.data?.find((d) => data.id === d.id)?.form_data
			);
		}
	}, [jobs]);


	return (
		<div className="clearfix">
			{message.show ? <Message message={message} /> : <></>}
			{selectedDocument?.length > 0 && <h5 className="text-black fw-bold">Tax Forms</h5>}
			<div className="row my-3">
				<div className="col-md-6">
					<strong>Description</strong>
				</div>
				<div className="col-md-2">
					<strong> Status</strong>
				</div>
				<div className="col-md-3">
					<strong>Date</strong>
				</div>

			</div>
			{selectedDocument?.map((selected,id) => {
				console.log(selected,"@@121")
				if (Object.keys(selected).includes('formData')) {
					return <div key={selected.name} className="d-flex flex-row align-items-center justify-content-between px-4 py-4 border border-light rounded-3 mb-3 flex-wrap">
						<div className='d-flex align-items-center gap-4 col-md-5 col-sm-12'>
						<div className=" d-flex justify-content-center">
							<div>
								<img src={FileIcon} alt='file' />
							</div>
						</div>

						<div className="d-flex  align-items-center">
							<Link
								className="text-dark text-decoration-none d-flex align-items-center gap-3"
								onClick={() => {
									setMessage({ show: false });
									dispatch(
										allActions.dashboard.getLeadsByUUID({
											UUID: leadUUID,
										})
									);
									openJobData(selected,id);
								}}
							>
								<div className='d-flex align-items-center'>
								<p className='my-0 fw-bold'>{selected.name}</p>
								{/* <p className='my-0 text-muted'>{selected.message}</p> */}
								</div>
							</Link>
							
						</div>
						</div>
						<div className='d-flex align-items-center ' style={{ gap: 120 }}>
								{/* <button className='btn btn-secondary'>Pending</button>  */}
								<button className='btn btn-success'>completed</button>
									<div>{selected?.created_at || '24 jun 2024'}</div>
								</div>
								
						<div className="text-end dropdown-center col-md-2">
							<button
								type="button"
								className={`btn btn-sm text-primary border-0 rounded-circle`}
								data-bs-toggle="dropdown"
								aria-expanded="false"
								style={{
									width: 30,
									height: 30,
								}}
							>
								<img src={DotMoreIcon}  alt='dotmore'/>
								{/* <BsThreeDotsVertical /> */}
							</button>
							<ul className="dropdown-menu">
								<li>
									<Link
										className="dropdown-item"
										onClick={() => {
											setMessage({ show: false });
											dispatch(
												allActions.dashboard.getLeadsByUUID({
													UUID: leadUUID,
												})
											);
											openJobData(selected,id);
										}}
									>
										View
									</Link>
								</li>
							</ul>
						</div>
					</div>
				}
			})}
				
				<button
					className={`btn btn-outline-primary px-2 rounded-2 ${
						data.status_id === 4 && 'disabled'
					}`}
					data-bs-toggle="modal"
					data-bs-target="#requestJobTaxForms"
				>
					Request Additional Tax forms
				</button>
			
			{documentsData.length > 0 &&  <h5 className="text-black py-3 fw-bold">Requested Documents</h5>
}
			{documentsData || documentsData?.length !== 0 ? (
				<>
					{documentsData?.map((document, index) => (
					<div
					key={document.id}
					className="row align-items-center px-3 py-3 border border-light text-black rounded-3 mb-3"
				>
					<div className="col-md-6 d-flex align-items-center" style={{ gap: 10 }}>
						{/* <TbFileDescription size={28} color="grey" extension={document?.extension} /> */}
						<div>
							<p className="mb-0 mt-0 fw-bold">{document?.jobdocument?.name || "Sample Document Name"}</p>
							<p className="mb-0 mt-0">{document?.jobdocument?.description || "Sample Description"}</p>
							{document?.jobdocument.message && (
								<p className="mb-0 mt-0 text-muted">
									{document?.jobdocument?.message || "Sample Message"}
								</p>
							)}
						</div>
					</div>

					<div className="col-md-2 d-flex align-items-center">
						<div
							className={`btn btn-${document?.path ? "success" : "grey-400"} btn-sm text-white`}
						>
							{document?.path ? "Submitted" : "Pending"}
						</div>
					</div>

					<div className="col-md-3 d-flex align-items-center text-black">
						<span className="text-dark text-black ">

							{new Date(document.updated_at || new Date()).toLocaleDateString("en-US", {
								year: "numeric",
								month: "short",
								day: "numeric",
							})}
						</span>
					</div>

					<div className="col-md-1 text-end dropdown-center">
						<button
							type="button"
							className="btn btn-sm text-primary border-0 rounded-circle"
							data-bs-toggle="dropdown"
							aria-expanded="false"
							style={{
								width: 30,
								height: 30,
							}}
						>
							<BsThreeDots />
						</button>
						<ul className="dropdown-menu">
							<li>
								<Link
									to="#"
									className={`dropdown-item${document?.path ? "" : " disabled"}`}
									data-bs-toggle="modal"
									data-bs-target="#viewDocumentModal"
									onClick={() => setDocumentToView(document)}
								>
									View
								</Link>
							</li>
							<li>
								<Link
									to={document?.path ? document.path : "#"}
									className={`dropdown-item${document?.path ? "" : " disabled"}`}
									download={document?.name}
								>
									Download
								</Link>
							</li>
							<li>
								<hr className="dropdown-divider border-light" />
							</li>
							<li>
								<Link
									to="#"
									className="dropdown-item text-danger"
									onClick={() => handleDeleteDocumentClick(document.uuid)}
								>
									Delete
								</Link>
								<SweetAlert2 {...swalProps[document.uuid]} />
							</li>
						</ul>
					</div>
				</div>
					))}
				</>
			) : (
				<div className="card p-5 border-1 border-secondary-100 text-center rounded shadow-hover">
					<h4>The customer has not started the assessment yet.</h4>
				</div>
			)}
			<div className="clearfix">
				<button
					className={`btn btn-outline-primary px-2 rounded-2 ${
						data.status_id === 4 && 'disabled'
					}`}
					data-bs-toggle="modal"
					data-bs-target="#requestJobDocumentsModal"
				>
					Request Additional document
				</button>
			</div>
			<h5 className="fw-bold mt-5">Draft documents</h5>
			{draftsData || draftsData?.length !== 0 ? (
				<>
					{draftsData?.map((document, index) => (
						<div
							className="row align-items-center px-2 py-4 border border-light rounded-3 mb-3"
							key={document.id}
						>
							<div className="col-md-1 d-flex justify-content-center">
								<div
									className={`rounded-circle bg-${
										document?.path ? 'success' : 'grey-200'
									} text-white d-flex justify-content-center align-items-center`}
									style={{
										width: 20,
										height: 20,
									}}
								>
									<AiOutlineCheck />
								</div>
							</div>
							<div className="col-md-7 d-flex align-items-center" style={{ gap: 10 }}>
								<FileTypeIcon extension={document?.extension} /> {document?.name}
							</div>
							<div className="col-3 text-end">
								{document?.path && (
									<span className="text-dark text-opacity-50">
										Added{' '}
										{new Date(document.updated_at).toLocaleDateString('en-US', {
											year: 'numeric',
											month: 'short',
											day: 'numeric',
										})}
									</span>
								)}
							</div>
							<div className="col text-end dropdown-center">
								<button
									type="button"
									className={`btn btn-sm text-primary border-0 rounded-circle`}
									data-bs-toggle="dropdown"
									aria-expanded="false"
									style={{
										width: 30,
										height: 30,
									}}
								>
									<BsThreeDotsVertical />
								</button>
								<ul className="dropdown-menu">
									<li>
										<Link
											to="#"
											className={`dropdown-item${document?.path ? '' : ' disabled'}`}
											data-bs-toggle="modal"
											data-bs-target="#viewDocumentModal"
											onClick={() => setDocumentToView(document)}
										>
											View
										</Link>
									</li>
									<li>
										<Link
											to="#"
											className={`dropdown-item${document?.path ? '' : ' disabled'}`}
											data-bs-toggle="modal"
											data-bs-target="#openEditDocumentModal"
											onClick={() => setDocumentToView(document)}
										>
											Edit Document Name
										</Link>
									</li>
									<li>
										<hr className="dropdown-divider border-light" />
									</li>
									<li>
										<Link
											to="#"
											className="dropdown-item text-danger"
											onClick={() => handleDeleteDocumentClick(document.uuid)}
										>
											Delete
										</Link>
										<SweetAlert2 {...swalProps[document.uuid]} />
									</li>
								</ul>
							</div>
						</div>
					))}
				</>
			) : (
				<div className="card p-5 border-1 border-secondary-100 text-center rounded shadow-hover">
					<h4>You haven't uploaded any draft documents yet.</h4>
				</div>
			)}
			{documentRequestData.isTaxPro && data.status_id !== 3 && (
				<div className="clearfix">
					<button
						className={`btn btn-outline-primary px-2 rounded-2 ${
							data.status_id === 4 && 'disabled'
						}`}
						data-bs-toggle="modal"
						data-bs-target="#uploadDraftDocumentModal"
					>
						Upload draft document
					</button>
				</div>
			)}
			<Modal
				title="Request Documents"
				bodyClass="px-5 py-4 text-center"
				trigger="requestJobDocumentsModal"
				showBtn={false}
				btnClass="requestJobDocumentsModalClose"
				size="lg"
			>
				<RequestJobDocumentForm
					data={documentRequestData}
					reloadData={reload}
					customer_id={data?.user_id}
					leadData={documentRequestLeadData}
					closeClass="requestJobDocumentsModalClose"
				/>
			</Modal>
			<Modal
				title="Request Tax Form"
				bodyClass="px-5 py-4 text-center"
				trigger="requestJobTaxForms"
				showBtn={false}
				btnClass="requestJobTaxFormsModalClose"
				size="xl"
			>
				<RequestJobTaxForm  
					data={selectedDocument} 
					reloadData={reload}
					userData={documentRequestData}
					setSelectedDocument={setSelectedDocument}
					jobs={jobs}
					setJobs={setJobs}
					/>
			</Modal>
			<Modal
				title="Upload draft document"
				bodyClass="px-5 py-4 text-center"
				trigger="uploadDraftDocumentModal"
				btnClass="uploadDraftDocumentModalClose"
				showBtn={false}
			>
				<UploadDraftDocumentForm
					userId={taxProUserId}
					jobId={data.id}
					reloadData={reload}
					targetUserUuid={data.user?.uuid}
				/>
			</Modal>
			<Modal
				title="View Document"
				bodyClass="px-5 py-4 text-center"
				trigger="viewDocumentModal"
				showBtn={false}
				size="lg"
			>
				{documentToView &&
					(documentToView.location.includes('.pdf') ? (
						<>
							<RenderMultiPagePDF file={documentToView.path} />
						</>
					) : (
						<img
							src={documentToView.path}
							className="w-100 img-fluid rounded"
							alt={documentToView.name}
							style={{ objectFit: 'contain' }}
						/>
					))}
			</Modal>
			<Modal
				title="Edit document"
				bodyClass="p-4 text-center text-dark text-opacity-50"
				trigger="openEditDocumentModal"
				showBtn={false}
				btnClass="editDocumentModalClose"
			>
				{documentToView && <EditDocumentForm data={documentToView} reloadDocuments={reload} />}
			</Modal>
			<FormPreviewModal />
		</div>
	);
}
