/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import moment from 'moment';
import { useCallback, useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BsThreeDotsVertical } from 'react-icons/bs';
import DatePicker from 'react-datepicker';
import {
	BASEURL,
	// getLeadStatus,
	// setQueryParams,
	getQueryParam,
	useAutoReplyHook,
	// removeQueryParam,
} from '../../../utilites';
import { GLOBAL_VARIABLES } from '../../../utilites/variables';
import allActions from '../../../Store/action';
import Header from '../components/Header';
import Preloader from '../components/Preloader';
import Select from '../../../components/forms/fields/Select';
import Input from '../../../components/forms/fields/Input';
import TextArea from '../../../components/forms/fields/TextArea';
import Checkbox from '../../../components/forms/fields/Checkbox';
import DropDown from '../../../components/forms/fields/DropDown';
import Message from '../../../components/forms/fields/Message';
import UserAvatar from '../../../components/UserAvatar';

const invoicePeriodOptions = [
	{ value: '3', label: '3' },
	{ value: '6', label: '6' },
	{ value: '12', label: '12' },
];
const partionalPaymentOption = [
	{ value: 20, label: '20%' },
	{ value: 40, label: '40%' },
	{ value: 60, label: '60%' },
	{ value: 80, label: '80%' },
	{ value: 'custom', label: 'Custom' },
];
const invoiceOptions = [
	{ value: 'FullPayment', label: 'Full Payment' },
	{ value: 'PartialPayment', label: 'Partial Payment' },
	// { value: 'RecurringPayment', label: 'Recurring Payment' },
];
const { DocumentOptions } = GLOBAL_VARIABLES;
const CreateContract = () => {
	const [{ LoginData }, { documentNames, taxPreparation, taxPlanning }, { personalConnections }] =
		useSelector((state) => [state.user, state.dashboard, state.firebase]);
	const [lead, setLead] = useState(null);
	const [alertMessage, setAlertMessage] = useState({ show: false });
	// Form
	const [serviceType, setServiceType] = useState(getQueryParam('service') || lead?.services[0]?.id);
	const [otherServiceType, setOtherServiceType] = useState('');
	const [completionDate, setCompletionDate] = useState();
	const [invoiceDeadline, setInvoiceDedline] = useState();
	const [cost, setCost] = useState();
	const [uncleKamFee, setUncleKamFee] = useState();
	const [remaning, setRemanning] = useState();
	const [message, setMessage] = useState();
	const [documents, setDocuments] = useState([]);
	const [otherDocumentName, setOtherDocumentName] = useState('');
	const [selectedFormName, setSelectedFormName] = useState();
	const [invoiceType, setInvoiceType] = useState();
	const [agreement, setAgreement] = useState(false);
	const [subscriptionOption, setSubscriptionOption] = useState();
	const [deduction, setDeduction] = useState();
	const [customPartial, setCustomPartial] = useState();
	const [createContractResponse, setCreateContractResponse] = useState();
	const [isDraft, setIsDraft] = useState(false);

	const [errors, setErrors] = useState({
		serviceType: false,
		completionDate: false,
		cost: false,
		message: false,
		documents: false,
		otherServiceType: false,
		otherDocumentName: false,
		general: '',
	});

	const { uuid } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const button = useRef();
	const handleAutoReply = useAutoReplyHook(dispatch);

	const loadLeadByUUID = useCallback((UUID) => {
		axios
			.get(`${BASEURL}/api/leads/${UUID}`)
			.then((res) => {
				console.log('lead data>>>>', res.data);
				setLead(res.data);
				if (!getQueryParam('service')) setServiceType(res.data?.services[0]?.id);
			})
			.catch((err) => {
				console.log('error>>>>', err);
			});
	}, []);

	useEffect(() => {
		loadLeadByUUID(uuid);
	}, [uuid, loadLeadByUUID]);

	useEffect(() => {
		dispatch(allActions.dashboard.getDocumentNames());
	}, [dispatch]);

	useEffect(() => {
		// if Success
		if (taxPreparation?.state === true) {
			setAlertMessage({ show: false });
			changeLoading(false);
			navigate(`/dashboard/jobs/${createContractResponse?.data?.data?.uuid}`, {
				state: { message: 'Contract Created Successfully!' },
			});
			dispatch(allActions.dashboard.resetTaxPreparation());
		}
		// if Error
		if (taxPreparation?.state === false) {
			// Show Message Alert
			setAlertMessage({
				show: true,
				type: 'Error',
				text: taxPreparation.message,
			});
			changeLoading(false);
			dispatch(allActions.dashboard.resetTaxPreparation());
		}
	}, [taxPreparation.message, taxPreparation?.state]);

	useEffect(() => {
		// if Success
		if (taxPlanning?.state === true) {
			setAlertMessage({ show: false });
			changeLoading(false);
			navigate(`/dashboard/jobs/${createContractResponse?.data?.data?.uuid}`, {
				state: { message: 'Contract Created Successfully!' },
			});
			dispatch(allActions.dashboard.resetTaxPlanning());
		}
		// if Error
		if (taxPlanning?.state === false) {
			// Show Message Alert
			setAlertMessage({
				show: true,
				type: 'Error',
				text: taxPlanning.message,
			});
			changeLoading(false);
			dispatch(allActions.dashboard.resetTaxPlanning());
		}
	}, [taxPlanning.message, taxPlanning?.state]);

	useEffect(() => {
		// Validate other document name
		if (
			documentNames.filter(
				(documentName) =>
					documentName.name.toLowerCase() === 'others' &&
					documents.includes(documentName.id.toString())
			).length > 0 &&
			otherDocumentName === ''
		) {
			!errors.otherDocumentName && setErrors({ ...errors, otherDocumentName: true });
		} else {
			errors.otherDocumentName && setErrors({ ...errors, otherDocumentName: false });
		}
	}, [documents, setErrors, errors, documentNames, otherDocumentName]);

	useEffect(() => {
		// Validate other service type
		if (serviceType === 'others' && otherServiceType === '') {
			!errors.otherServiceType && setErrors({ ...errors, otherServiceType: true });
		} else {
			errors.otherServiceType && setErrors({ ...errors, otherServiceType: false });
		}
	}, [serviceType, setErrors, errors, otherServiceType]);

	const getInvoiceData = () => {
		let obj = { invoice_data: {}, payment_due_date: invoiceDeadline };
		// For Partial Payment
		if (invoiceType?.value === 'PartialPayment') {
			obj.invoice_data.deductionType = deduction?.value === 'custom' ? '' : '%';
			obj.invoice_type = 'partial';
			obj.invoice_data.deduction = deduction?.value === 'custom' ? customPartial : deduction?.value;
			obj.invoice_data.fullAmount = cost;
			obj.invoice_data.remainingAmount =
				deduction?.value === 'custom'
					? cost - customPartial
					: cost - ((deduction?.value / 100) * cost).toFixed(2);
		}
		// For Subscription Payment
		if (invoiceType?.value === 'RecurringPayment') {
			obj.invoice_type = 'subscription';
			obj.invoice_data.deduction = subscriptionOption?.value;
			// obj.invoice_data.fullAmount = cost;
			// obj.invoice_data.remainingAmount = cost;
		}
		if (invoiceType.value === 'FullPayment') {
			obj.invoice_type = 'full';
			// obj.invoice_data.fullAmount = cost;
			// obj.invoice_data.remainingAmount = cost;
		}
		return obj;
	};

	// function to handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();
		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });

		let selectedDocNames = documentNames?.filter((obj) => documents?.includes(obj?.id?.toString()));
		let taxPlanObj = {
			lead_id: lead.id,
			service_id: serviceType,
			method: 'create',
			docName: selectedDocNames?.map((item) => item?.name),
		};
		changeLoading(true);
		dispatch(allActions.dashboard.resetTaxPlanning());
		dispatch(allActions.dashboard.resetTaxPreparation());
		setAlertMessage({ show: false });
		axios
			.post(`${BASEURL}/api/jobs/create`, {
				lead_id: lead.id,
				service_id: serviceType,
				other_service_type: otherServiceType,
				customer_id: lead.user.id,
				pro_id: LoginData?.userpro?.id || lead.userpro?.id,
				additional: message,
				other_document_name: otherDocumentName,
				isDraft,
				completionDate,
				cost,
				documents,
				...getInvoiceData(),
			})
			.then((response) => {
				setCreateContractResponse(response);
				handleAutoMessaging({ response });
				if (
					!(
						taxPlanObj?.service_id === 2 &&
						(LoginData?.isTaxPro || LoginData?.isAdmin || LoginData?.isAccountManager) &&
						taxPlanObj?.service_id === 1
					)
				) {
					button.current.disabled = false;
				}
				// if SERVICE IS TAX PLAN
				if (taxPlanObj?.service_id === '2' || taxPlanObj?.service_id === 2) {
					callTaxPlanning(taxPlanObj);
				}
				// if SERVICE IS TAX PREPARATION
				else if (taxPlanObj?.service_id === '1' || taxPlanObj?.service_id === 1) {
					// Modify Object To Tax Preparation

					delete taxPlanObj.docName;
					taxPlanObj.formName = selectedFormName?.value;
					taxPlanObj.formData = {};
					callTaxPreparation(taxPlanObj);

					console.log('taxPlanObj', taxPlanObj);
				}
				// FOR OTHER SERVICES
				else {
					changeLoading(false);
					// Navigate to lead details page
					navigate(`/dashboard/jobs/${response?.data?.data?.uuid}`, {
						state: { message: 'Contract Created and sent to customer Successfully!' },
					});
				}
			})
			.catch((error) => {
				changeLoading(false);
				setAlertMessage({
					show: true,
					type: 'Error',
					text: error?.response?.data?.error || error?.message,
				});
				button.current.disabled = false;
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};

	const callTaxPlanning = (taxPlanObj) => {
		reset_mesage();
		dispatch(allActions.dashboard.resetTaxPlanning());
		dispatch(allActions.dashboard.taxPlanning({ obj: taxPlanObj }));
	};

	const callTaxPreparation = (taxPreparationObj) => {
		reset_mesage();
		dispatch(allActions.dashboard.resetTaxPreparation());
		dispatch(allActions.dashboard.taxPreparation({ obj: taxPreparationObj }));
	};

	const reset_mesage = () => {
		setAlertMessage({ show: false });
	};

	const handleAutoMessaging = ({ response }) => {
		let message = `<p>Based on our discussions, I've create a contract for you to review and approve.</p>
		<img src="https://cdn-icons-png.flaticon.com/512/2666/2666523.png" class="bg-white p-1 rounded" alt="Girl in a jacket" width="60" height="60">
		<br></br>
		<a href="${window.location.origin}/dashboard/contract/${response?.data?.data?.uuid}" target="_blank" >Contract</a>
		<br></br>
		<p>To upload documents from your mobile phone, please scan the QR code using your phone's camera. The QR code will direct you to the upload page.</p>
		<a href="${window.location.origin}/qr-code/${response?.data?.data?.uuid}" target="_blank" >QR Code</a>
		`;

		handleAutoReply({
			personalConnections,
			LoginData,
			receiver: lead?.user,
			type: 'simple',
			encodedString: isDraft
				? `<p>Please <a href="${window.location.origin}/dashboard/contract/${response?.data?.data?.uuid}" target="_blank" >click here</a> to upload the new documents I have requested.</p>`
				: message,
		});
	};

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

	useEffect(() => {
		if (isDraft === true) {
			// Set default values for draft contract payment info part.
			setCompletionDate(new Date());
			setCost(0);
			setUncleKamFee(0);
			setRemanning(0);
			setInvoiceType({ label: 'Full Payment', value: 'FullPayment' });
			setInvoiceDedline(new Date());
		} else {
			// Clean up the default values for draft contract payment info part.
			setCompletionDate();
			setCost();
			setUncleKamFee();
			setRemanning();
			setInvoiceType();
			setInvoiceDedline();
			// Reset documents state as well
			setDocuments([]);
		}
	}, [isDraft]);

	if (lead !== null) {
		return (
			<div className="clearfix">
				<Header name="Create Job" />
				<div className="container page">
					<form className="clearfix pt-4 mb-4" onSubmit={handleSubmit}>
						{alertMessage.show ? <Message message={alertMessage} /> : <></>}
						<div className="card border-1 border-light rounded p-4 mb-4">
							<div className="card-header d-flex justify-content-between bg-white border-0">
								<h3 className="card-title text-primary fw-bold">Client</h3>
								<div className="card-actions">
									<span className="btn btn-sm text-primary">+</span>
									<span className="btn btn-sm text-primary">
										<BsThreeDotsVertical />
									</span>
								</div>
							</div>
							<div className="card-body">
								<div className="d-flex align-items-center">
									<div>
										<UserAvatar
											avatar={lead.user.avatar}
											alt={`${lead.user.firstname} ${lead.user.lastname}`}
											className="img-fluid object-fit-cover rounded-circle"
											style={{ height: 40 }}
										/>
									</div>
									<p className="text-dark m-0 ms-2">
										{`${lead.user.firstname} ${lead.user.lastname}`}
									</p>
								</div>
							</div>
						</div>
						<div className="card border-1 border-light rounded p-4 mb-4">
							<div className="card-header d-flex justify-content-between bg-white border-0">
								<h3 className="card-title text-primary fw-bold">Terms</h3>
								<div className="card-actions">
									<span className="btn btn-sm text-primary">+</span>
									<span className="btn btn-sm text-primary">
										<BsThreeDotsVertical />
									</span>
								</div>
							</div>
							<div className="card-body">
								<div className="mb-4">
									<div className="text-dark">Service type</div>
									<Select
										value={serviceType}
										name="serviceType"
										placeholder="Service type"
										fn={setServiceType}
									>
										{lead.services?.map((service) => (
											<option key={service.id} value={service.id}>
												{service.name}
											</option>
										))}
										<option value="others">Others</option>
									</Select>
								</div>
								{serviceType === 'others' && (
									<div className="mb-4">
										<Input
											type="text"
											value={otherServiceType}
											name="otherServiceType"
											placeholder="Other service type"
											fn={setOtherServiceType}
											hasValidationError={errors.otherServiceType}
											autoFocus={true}
											required={true}
										/>
									</div>
								)}
								<div className="mb-4">
									<div className="text-dark">Description</div>
									<TextArea
										inputName="form-control"
										defaultValue={message}
										placeholder="Write a description of the job here."
										name="message"
										fn={setMessage}
										row="6"
										required={false}
										showLabel={false}
									/>
								</div>
								<div className="mb-0">
									<div className="form-check">
										<input
											type="checkbox"
											name="isDraft"
											className="form-check-input"
											id="isDraft"
											value="1"
											checked={isDraft}
											onChange={(e) => setIsDraft(e.target.checked)}
										/>
										<label className="form-check-label" htmlFor="isDraft">
											Draft Mode
										</label>
									</div>
								</div>
							</div>
						</div>
						{isDraft ? null : (
							<div className="card border-1 border-light rounded p-4 mb-4">
								<div className="card-header d-flex justify-content-between bg-white border-0">
									<h3 className="card-title text-primary fw-bold">Payment Info</h3>
									<div className="card-actions">
										<span className="btn btn-sm text-primary">+</span>
										<span className="btn btn-sm text-primary">
											<BsThreeDotsVertical />
										</span>
									</div>
								</div>
								<div className="card-body">
									<div className="mb-4">
										<div className="text-dark">Completion date</div>
										<div className="form-floating">
											<DatePicker
												className="form-control"
												wrapperClassName="d-block"
												selected={completionDate}
												onChange={(date) => setCompletionDate(date)}
												dateFormat="MM/dd/yyyy"
												placeholderText="mm/dd/yyyy"
												minDate={new Date()}
												isClearable
											/>
										</div>
									</div>
									<div className="mb-4">
										<div className="text-dark">Total Cost</div>
										<Input
											type="text"
											value={cost}
											name="cost"
											placeholder="00.00"
											fn={(value) => {
												if (/^\d*\.?\d*$/.test(value)) {
													setCost(value);
													setUncleKamFee(
													(
														(parseInt(LoginData?.userpro?.percentage || lead?.userpro?.percentage) /
															100) *
														value
													).toFixed(2)
												);
												setRemanning(
													(
														value -
														(
															(parseInt(
																LoginData?.userpro?.percentage || lead?.userpro?.percentage
															) /
																100) *
															value
														).toFixed(2)
													).toFixed(2)
												);
											}
											}}
											// hasValidationError={errors.reason}
											required={true}
											showLabel={false}
											/>
									</div>
									<div className="mb-4">
										<div className="text-dark">
											{LoginData?.userpro?.percentage || lead?.userpro?.percentage}% Uncle Kam fee
										</div>
										<Input
											type="number"
											value={uncleKamFee}
											name="cost"
											placeholder="00.00"
											fn={setUncleKamFee}
											// hasValidationError={errors.reason}
											required={false}
											showLabel={false}
											isDisabled={true}
										/>
									</div>
									<div className="mb-4">
										<div className="text-dark">You'll receive</div>
										<Input
											type="number"
											value={remaning}
											name="cost"
											placeholder="00.00"
											fn={setRemanning}
											// hasValidationError={errors.reason}
											required={false}
											showLabel={false}
											isDisabled={true}
										/>
									</div>
									<div className="mb-4">
										<div className="text-dark">Select invoice type</div>
										<DropDown
											options={invoiceOptions}
											value={invoiceType}
											setValue={setInvoiceType}
											label={'Select Invoice Type'}
											showLabel={false}
										/>
									</div>
									{}
									{invoiceType?.value === 'RecurringPayment' ? (
										<div className="mb-3 col-md-12">
											<div className="text-dark">Select subscription duration</div>
											<DropDown
												options={invoicePeriodOptions}
												value={subscriptionOption}
												setValue={setSubscriptionOption}
												label={'Subscription Duration'}
												showLabel={false}
											/>
										</div>
									) : (
										''
									)}
									{invoiceType?.value === 'PartialPayment' ? (
										<div className="mb-3 col-md-12">
											<div className="text-dark">Amount</div>
											<DropDown
												options={partionalPaymentOption}
												value={deduction}
												setValue={setDeduction}
												label={'Deposit'}
												showLabel={false}
											/>
										</div>
									) : (
										''
									)}
									{deduction?.value === 'custom' && (
										<div className="mb-4">
											<div className="text-dark">Custom Amount</div>
											<Input
												type="number"
												value={customPartial}
												name="partialValue"
												placeholder="00.00"
												fn={(e) => {
													if (Number(e) <= Number(cost)) {
														setCustomPartial(e);
													}
												}}
												required={false}
												showLabel={false}
											/>
										</div>
									)}
									<div>
										<div className="mb-4">
											<div className="text-dark">Invoice expiry date</div>
											<div className="form-floating">
												<DatePicker
													className="form-control"
													wrapperClassName="d-block"
													selected={invoiceDeadline}
													onChange={(date) => setInvoiceDedline(date)}
													dateFormat="MM/dd/yyyy"
													placeholderText="mm/dd/yyyy"
													minDate={moment().add(1, 'days').toDate()}
													isClearable
													required
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						<div className="card border-1 border-light rounded p-4">
							<div className="card-header d-flex justify-content-between bg-white border-0">
								<h3 className="card-title text-primary fw-bold"> {parseInt(serviceType) === 1?"Tax Organization Form":"Documents request"}</h3>
								<div className="card-actions">
									<span className="btn btn-sm text-primary">+</span>
									<span className="btn btn-sm text-primary">
										<BsThreeDotsVertical />
									</span>
								</div>
							</div>
							<div className="card-body">
								<div>
									{parseInt(serviceType) !== 1 && documentNames.length > 0 && (
										<div className="row text-start">
											{documentNames?.map((documentName) => (
												<div className="col-6 mt-2" key={documentName.id}>
													<Checkbox
														wrapperClass="form-check"
														name="documents"
														state={documents}
														value={documentName.id}
														label={documentName.name}
														fn={setDocuments}
														id={`doc-${documentName.id}`}
														required={false}
													/>
												</div>
											))}
										</div>
									)}
									{parseInt(serviceType) === 1 && (
										<div className="clearfix">
											<div className="text-dark">Form type</div>
											<DropDown
												options={DocumentOptions}
												value={selectedFormName}
												setValue={setSelectedFormName}
												label={'Select Form Type'}
												showLabel={false}
											/>
										</div>
									)}
								</div>
								{documentNames.filter(
									(documentName) =>
										documentName.name.toLowerCase() === 'others' &&
										documents.includes(documentName.id.toString())
								).length > 0 && (
									<div className="row text-start mt-4">
										<Input
											type="text"
											value={otherDocumentName}
											name="documentName"
											placeholder="Document Name"
											label="Type document name here"
											fn={setOtherDocumentName}
											hasValidationError={errors.otherDocumentName}
											autoFocus={true}
										/>
									</div>
								)}
								{isDraft && selectedFormName ? (
									<>
										<div className="mt-4">
											<div className="text-dark">Additional documents</div>
											<div className="row text-start">
												{documentNames?.map((documentName) => (
													<div className="col-6 mt-2" key={documentName.id}>
														<Checkbox
															wrapperClass="form-check"
															name="documents"
															state={documents}
															value={documentName.id}
															label={documentName.name}
															fn={setDocuments}
															id={`doc-${documentName.id}`}
															required={false}
														/>
													</div>
												))}
											</div>
										</div>
									</>
								) : null}
							</div>
						</div>
						<div className="clearfix p-2 mt-4">
							<div className="form-check">
								<input
									type="checkbox"
									name="agreed"
									className="form-check-input"
									id="agreed"
									value="1"
									checked={agreement}
									onChange={(e) => setAgreement(e.target.checked)}
								/>
								<label className="form-check-label" htmlFor="agreed">
									Yes, I understand and agree to the Uncle Kam Terms of Service, including the{' '}
									<Link to="/user-agreement">User Agreement</Link> and{' '}
									<Link to="/privacy-policy">Privacy Policy</Link>.
								</label>
							</div>
						</div>
						<div className="clearfix p-2 mt-4 d-flex justify-content-end">
							<span className="btn btn-outline-primary me-3" onClick={() => navigate(-1)}>
								Cancel
							</span>
							<button
								className={`btn btn-primary${
									Object.values(errors).indexOf(true) > -1 || !agreement ? ' disabled' : ''
								}`}
								type="submit"
								ref={button}
							>
								Submit
							</button>
						</div>
					</form>
				</div>
			</div>
		);
	} else {
		return <Preloader />;
	}
};

export default CreateContract;
