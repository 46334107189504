import { Link } from 'react-router-dom';
import ForgotPasswordForm from '../../components/forms/ForgotPasswordForm';
import SEO from '../../components/SEO';

const ForgotPassword = () => {
	return (
		<div className='page px-3'>
			<SEO title='Forgot password' />
			<div className='container'>
				<div className='clearfix mb-4'>
					<div className='row justify-content-center'>
						<div className='col-md-4'>
							<div className='clearfix bg-white p-5 shadow rounded'>
								<h4 className='ff-gothic text-dark mb-4'>Sign in to access your account</h4>
								<p className='fs-7 text-dark'>
									Enter the email address associated with your account and we’ll send you a link to
									reset your password.
								</p>
								<ForgotPasswordForm />
							</div>
							<div className='text-center mt-4'>
								<p className='text-muted'>
									Don't have an account? <Link to='/register'>Sign up</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
