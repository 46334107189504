import { BsThreeDotsVertical } from 'react-icons/bs';
import moment from 'moment-timezone';
import { convertTimeIntoSselectedTZ } from '../../../utilites';

export default function AppointmentsList({ data, setSelectedAppointment, timezone }) {
	return (
		<ul className="list-group list-group-flush">
			{data && data.length > 0 ? (
				data?.map((item) => (
					<li className="list-group-item" key={item.id}>
						<div className="row align-items-center text-decoration-none text-black">
							<div className="col-3 px-1">
								<div className="bg-primary-200 text-center rounded p-2">
									<p className="text-muted m-0" style={{ fontSize: 10 }}>
										{convertTimeIntoSselectedTZ(
											item?.timezone,
											moment.tz.guess(),
											moment.utc(item.start_date),
											'MMM'
										)}
									</p>
									<p className="fw-bold m-0" style={{ fontSize: 16 }}>
										{convertTimeIntoSselectedTZ(
											item?.timezone,
											moment.tz.guess(),
											moment.utc(item.start_date),
											'D'
										)}
									</p>
								</div>
							</div>
							<div
								className="col px-3"
								data-bs-toggle="offcanvas"
								data-bs-target="#openAppointmentDetailsPanel"
								aria-controls="openAppointmentDetailsPanel"
								onClick={() => setSelectedAppointment(item)}
								style={{ cursor: 'pointer' }}
							>
								<p className="text-black fw-bold m-0">{item.title}</p>
								<p className="text-muted m-0">
									{' '}
									{convertTimeIntoSselectedTZ(
										item?.timezone,
										moment.tz.guess(),
										moment.utc(item.start_date),
										'h:mm A'
									)}
								</p>
							</div>
							<div className="px-0 btn-group" style={{ width: 30 }}>
								<button
									type="button"
									data-bs-toggle="offcanvas"
									data-bs-target="#openAppointmentDetailsPanel"
									aria-controls="openAppointmentDetailsPanel"
									onClick={() => setSelectedAppointment(item)}
									className="btn btn-outline-grey-200 btn-sm rounded-circle"
									//data-bs-toggle='dropdown'
									//aria-expanded='false'
									style={{ width: 30, height: 30 }}
								>
									<BsThreeDotsVertical />
								</button>
								{/*<ul className='dropdown-menu'>
									<li>
										<a className='dropdown-item' href='##'>
											Action
										</a>
									</li>
								</ul>*/}
							</div>
						</div>
					</li>
				))
			) : (
				<p className="text-muted text-center m-0">
					You have no upcoming appointments available to load right now.
				</p>
			)}
		</ul>
	);
}
