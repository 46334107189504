import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Input from './fields/Input';
import Button from './fields/Button';
import Checkbox from './fields/Checkbox';
import { useSelector, useDispatch } from 'react-redux';
import Message from './fields/Message';
import allActions from '../../Store/action';
import { useNavigate } from 'react-router-dom';
import { USER_ACTION_TYPE } from '../../Store/user/Type';
export default function LoginForm() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const button = document.querySelector('form.loginform button.btn');

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [remember, setRemember] = useState([]);
	const [message, setMessage] = useState({ show: false });

	// Store States
	const verify_check = useSelector((state) => state.user.verify);
	// HOOKS
	useEffect(() => {
		if (verify_check?.mode && verify_check?.type !== 'null') {
			setMessage({
				show: verify_check?.mode,
				type: 'Awesome',
				text: verify_check?.text,
				text1: 'Please login to continue',
			});
		}
		if (verify_check?.mode === false && verify_check?.type !== 'null') {
			setMessage({
				show: true,
				type: 'Error',
				text: verify_check?.text,
				text1: '',
			});
		}
	}, [verify_check?.mode, verify_check?.text, verify_check?.type]);

	const reset_mesage = () => {
		setMessage({ show: false });
	};
	const resetForm = () => {
		setEmail('');
		setPassword('');
	};
	const change_spinner = (spin) => {
		if (spin) {
			button.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...`;
		} else {
			button.innerHTML = `Login`;
		}
	};

	const handleSubmit = (e) => {
		// Reset User Action Values
		dispatch({
			type: USER_ACTION_TYPE.reset,
		});
		dispatch(allActions.user.reset_user());
		// Reset User State Values
		reset_mesage();

		e.preventDefault();
		// adding a bootstrap spinner uppon form submission

		let user_obj = {
			email,
			password,
		};
		change_spinner(true);
		dispatch(allActions.user.Login(user_obj, setMessage, resetForm, change_spinner, navigate));
	};

	return (
		<div>
			<form className='loginform needs-validation' onSubmit={handleSubmit}>
				<div className='mb-3'>
					{message.show ? <Message message={message} /> : <></>}
					<Input
						type='email'
						value={email}
						name='email'
						placeholder='Email Address'
						showLabel={false}
						fn={setEmail}
					/>
				</div>
				<div className='mb-3 position-relative'>
					<Input
						type='password'
						value={password}
						name='password'
						placeholder='Password'
						showLabel={false}
						fn={setPassword}
						secure={true}
					/>
				</div>
				<div className='mb-3 text-start'>
					<Checkbox
						type='checkbox'
						state={remember}
						value={'remember'}
						name='remember'
						label='Remember Me'
						inputClass='form-check-input form-custom-checkbox'
						fn={setRemember}
						required={false}
					/>
				</div>
				<Button
					mainClass='btn btn-primary btn-lg px-4'
					wrapperClass='clearfix d-grid text-center mt-3'
					buttonText='Login'
				/>
				<div className='clearfix text-center mt-3'>
					<Link
						onClick={() => {
							reset_mesage();
							dispatch({
								type: USER_ACTION_TYPE.reset,
							});
							dispatch(allActions.user.reset_user());
						}}
						to='/forgot-password'
						className='fs-6 text-decoration-underline'
					>
						Forgot your password?
					</Link>
				</div>
			</form>
		</div>
	);
}
