import { CALL_ACTION_TYPE } from "./Type";

const DEFAULT_STATE = {
	videoCallRoom: { message: "", status: "idle", data: {} },
	getZoomMeeting: { message: "", status: "idle", data: {} },
	updateMeetingRoom: { message: "", status: "idle", data: {} },

	// AUDIO CALL 
	audioCallToken: { message: "", status: "idle", data: {} },

};

const INITIAL_STATE = {
	...DEFAULT_STATE,
};

const call = (state = INITIAL_STATE, action) => {
	if (action.type === CALL_ACTION_TYPE.videoCallRoom) {
		return {
			...state,
			videoCallRoom: action.payload,
		};
	}
	if (action.type === CALL_ACTION_TYPE.videoCallRoomIdle) {
		return {
			...state,
			videoCallRoom: DEFAULT_STATE.videoCallRoom,
		};
	}
	if (action.type === CALL_ACTION_TYPE.getZoomMeeting) {
		return {
			...state,
			getZoomMeeting: action.payload,
		};
	}
	if (action.type === CALL_ACTION_TYPE.getZoomMeetingIdle) {
		return {
			...state,
			getZoomMeeting: DEFAULT_STATE.getZoomMeeting,
		};
	}
	if (action.type === CALL_ACTION_TYPE.updateMeetingRoom) {
		return {
			...state,
			updateMeetingRoom: action.payload,
		};
	}
	if (action.type === CALL_ACTION_TYPE.updateMeetingRoomIdle) {
		return {
			...state,
			updateMeetingRoom: DEFAULT_STATE.updateMeetingRoom,
		};
	}
	if (action.type === CALL_ACTION_TYPE.audioCallToken) {
		return {
			...state,
			audioCallToken: action.payload,
		};
	}
	if (action.type === CALL_ACTION_TYPE.audioCallTokenIdle) {
		return {
			...state,
			audioCallToken: DEFAULT_STATE.audioCallToken,
		};
	}
	return state;
};

export default call;
