/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import allActions from "../../Store/action";
import { useEffect } from "react";
import { CALL_ACTION_TYPE } from "../../Store/call/Type";
import { BASEURL, twilioPhoneNumberFormatter } from "../../utilites";
import UserAvatar from "../UserAvatar";
import { FaPhone } from "react-icons/fa";
import { Device } from "@twilio/voice-sdk";
import DialpadModal from "../forms/Modal/DialpadModal";
import { IoIosLock } from "react-icons/io";


const CallTopBar = ({
	selectedReceiver,
	setMessage,
	chatDisable,
	callData,
	callMeta,
	setCallData,
	setCallMeta,
	CallEnd
}) => {

	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [lastLead, setLastLead] = useState();
	const [audioConnection,setAudionConnection] = useState(null)
	const [{LoginData},{ audioCallToken }] = useSelector((state) => [state.user,state.call]);

	const showError = (message) => {
		setMessage({
			show: true,
			type: "Error",
			text: message,
		});
	};

	const proceedCall = async (token) => {
		try {
			const device = new Device(token, {
				enableImprovedSignalingErrorPrecision: true,
			});

			setCallMeta(device);

			device.on("registered", function () {
				// console.log("Twilio.Device Ready to make and receive calls!");
			});

			device.on("error", function (error) {
				resetMessage();
				showError(error.toString());
			});

			const call = await device.connect({
				params: {
					To: `${twilioPhoneNumberFormatter(selectedReceiver?.phone)}`,
				},
			});
			setAudionConnection(call)
			
			call.on("error", (callError) => {
				resetMessage();
				showError("Something went wrong due to network connectivity!");
			});
			call.on("accept", () => {
				resetMessage();
				setCallData({ status: "Ringing....", user: selectedReceiver });
				dispatch(allActions.recordings.saveRecording(call?.parameters?.CallSid))
			});
			call.on("disconnect", () => { });
			call.on("cancel", () => { });
		} catch (error) { }
	};

	useEffect(() => {
		// if Error While Creating Video Call Room
		if (!audioCallToken?.status && audioCallToken) {
			// Reset Message and Loading
			resetMessage();
			setMessage({
				show: true,
				type: "Error",
				text: audioCallToken?.message ? audioCallToken?.message : {},
			});
		}
		if (audioCallToken?.status === true && audioCallToken) {
			proceedCall(audioCallToken?.data?.token);
		}
	}, [audioCallToken?.status, audioCallToken?.message]);

	// Function To Reset Message
	const reset_Message = () => {
		setMessage({ show: false });
	};

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

	const resetMessage = () => {
		reset_Message();
		changeLoading(false);
	};

	useEffect(() => {
		loadLastLead();
	}, [selectedReceiver]);

	// Show Dialpad Modal

	

	const loadLastLead = () => {
		if (
			selectedReceiver &&
			selectedReceiver?.role?.toLowerCase() === "customer"
		) {
			// Get latest lead of selected receiver
			axios
				.get(`${BASEURL}/api/leads/by-customer`, {
					params: {
						id: selectedReceiver?.id,
					},
				})
				.then((res) => setLastLead(res.data.data));
		} else if (lastLead) {
			setLastLead(null);
		}
	};

	const createAudioCallToken = () => {
		resetMessage();
		changeLoading(true);
		dispatch(
			allActions.call.dispatchToStore({
				type: CALL_ACTION_TYPE.audioCallTokenIdle,
			}),
		);
		dispatch(allActions.call.createAudioCallToken(selectedReceiver?.id));
	};

	const hangUp = async () => {
			await CallEnd();
			dispatch(allActions.global.Loader(true));
			navigate('/dashboard/calls?reload')
			setTimeout(() => {
				dispatch(allActions.firebase.resetPersonalConnection());
				dispatch(allActions.firebase.resetReadPersonalChatsArray());
				dispatch(allActions.call.dispatchToStore({ type: CALL_ACTION_TYPE.videoCallRoomIdle }));
					dispatch(
					allActions.call.dispatchToStore({
						type: CALL_ACTION_TYPE.audioCallTokenIdle,
					}),
				);
				navigate('/dashboard/calls');
			}, 1000);
	}

	return (
		<>
		<div className="clearfix">
			<div className="row video-appointment-height">
				<div className="col-6">
					{selectedReceiver && (
						<>
							<div className="float-start">
								<div
									className="position-relative"
									style={{ width: 60, height: 60 }}
								>
									<UserAvatar
										avatar={selectedReceiver.avatar}
										alt={`${selectedReceiver.firstname} ${selectedReceiver.lastname}`}
										className="rounded-circle w-100 h-100 object-fit-cover"
									/>
								</div>
							</div>
							<div className="d-flex flex-column align-items-start px-2 mt-2">
								<h5 className="mb-0">
									{selectedReceiver?.firstname +
										" " +
										selectedReceiver?.lastname}
								</h5>
								<small className="text-capitalize text-responsive w-200 fold-Text-small opacity-75">
									{callData?.status ? callData.status : 'Ready'}
								</small>
							</div>
						</>
					)}
				</div>
				<div className="col-6 ">
					<div className="float-end icon-div d-flex">
						{LoginData?.userpro?.status_id === 6 ? <button
								className="btn btn-sm btn-outline-primary px-2 float-md-end float-none d-flex align-tems-center gap-1"
								disabled={true}
							>
								Dial  <IoIosLock style={{ fontSize: '16px' }} />
							</button> : callData?.status === "" ? (
							<Link
								to={`#`}
								className={`btn btn-md btn-primary mx-1 px-3 w-100 d-flex align-items-center justify-content-center ${chatDisable && "disabled"}`}
								onClick={() => {
									if (selectedReceiver?.phone) {
										createAudioCallToken();
									} else {
										showError("User done't have added phone number");
										//   phone number missing
									}
								}}
							>
								Dial
								<FaPhone className="icons mx-2 fs-7" />
							</Link>
						) : (
							<Link
								to={`#`}
								className={`btn btn-md btn-danger mx-1 px-2 w-100 d-flex align-items-center justify-content-center ${chatDisable && "disabled"}`}
								onClick={hangUp}
							>
								Hang Up
								<FaPhone className="icons fs-7 mx-2" />
							</Link>
						)}
					</div>
				</div>
			</div>
		</div>
		{callData?.status && <DialpadModal width='300px' hangUp={hangUp} connection={audioConnection} /> }
		
	</>
	);
};

export default CallTopBar;
