import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { BASEURL, useAutoReplyHook } from '../../utilites';
import allActions from '../../Store/action';
import Checkbox from './fields/Checkbox';
import Button from './fields/Button';
import Input from './fields/Input';

export default function RequestJobDocumentForm({
	data,
	setAlert,
	reloadData,
	leadData,
	closeClass,
}) {
	const [{ documentNames }, { personalConnections }, { LoginData }] = useSelector((state) => [
		state.dashboard,
		state.firebase,
		state.user,
	]);
	const [documents, setDocuments] = useState([]);
	const [otherDocumentName, setOtherDocumentName] = useState('');

	const [errors, setErrors] = useState({
		documents: false,
		otherDocumentName: false,
		general: '',
	});
	const dispatch = useDispatch();
	const handleAutoReply = useAutoReplyHook(dispatch);
	const button = useRef();

	useEffect(() => {
		dispatch(allActions.dashboard.getDocumentNames());
	}, [dispatch]);

	useEffect(() => {
		// Validate documents
		if (documents.length === 0) {
			!errors.documents && setErrors({ ...errors, documents: true });
		} else {
			errors.documents && setErrors({ ...errors, documents: false });
		}
		// Validate other document name
		if (
			documentNames.filter(
				(documentName) =>
					documentName.name.toLowerCase() === 'others' &&
					documents.includes(documentName.id.toString())
			).length > 0 &&
			otherDocumentName === ''
		) {
			!errors.otherDocumentName && setErrors({ ...errors, otherDocumentName: true });
		} else {
			errors.otherDocumentName && setErrors({ ...errors, otherDocumentName: false });
		}
	}, [documents, setErrors, errors, documentNames, otherDocumentName]);

	// function to handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();
		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });

		let otherExist=documents.find((document) =>document.name.toLowerCase() === 'others')
		if(otherExist){
			let index=documents.indexOf(otherExist)
			documents[index].name=otherDocumentName
			// documents[index].description=otherDocumentName			
		}

		axios
			.post(`${BASEURL}/api/jobs/request-documents`, {
				customer_id: data.customer_id,
				pro_id: data.pro_id,
				job_id: data.job_id,
				other_document_name: otherDocumentName,
				documents,
			})
			.then(() => {
				button.current.disabled = false;
				// Close Modal
				document
					.querySelector(`.${closeClass ? closeClass : 'requestDocumentsModalClose'}`)
					.click();
				setAlert && setAlert('Documents has been requested successfully!');
				handleAutoMessaging();
			})
			.then(() => {
				reloadData();
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};

	const handleAutoMessaging = () => {
		handleAutoReply({
			personalConnections,
			LoginData,
			receiver: leadData,
			type: 'simple',
			encodedString: `<p>I just sent you a new document request. Please upload through your Projects page. Thanks</p>`,
		});
	};

	console.log("documewts ",documents);
	
	return (
		<form className="needs-validation" onSubmit={handleSubmit}>
			<div className="row text-start mb-3">
				{documentNames.length > 0 &&
					documentNames?.map((documentName) => (
						<div className="col-12" key={documentName.id}>
							<Checkbox
								wrapperClass="mb-2"
								name="documents"
								state={documents}
								value={documentName.id}
								label={documentName.name}
								// checked={documents.find(doc => doc.id === documentName.id)}
								onChnage={(doc) => {									
									let alreadyExist=documents.find((doc)=>doc.id===documentName?.id)
									if(!alreadyExist){
										setDocuments((prev) => [...prev, { id: documentName?.id, name: documentName?.name, description: documentName?.name, message: '' }])
									}
									else{
										let otherDocuments=documents.filter((doc)=>doc.id!==documentName?.id)
										setDocuments(otherDocuments)
									}
								}}
								id={`request-doc-${documentName.id}`}
								required={false}
							/>
						</div>
					))}
			</div>
			{documents.find((document) =>document.name.toLowerCase() === 'others')
			&&
			<div className="row text-start mb-3">
			<Input
				type="text"
				value={otherDocumentName}
				name="documentName"
				placeholder="Document Name"
				fn={setOtherDocumentName}
				hasValidationError={errors.otherDocumentName}
				autoFocus={true}
			/>
		</div>
			}
			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mb-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 ? ' disabled' : ''
				}`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-3"
				buttonText="Send Request"
			/>
		</form>
	);
}
