/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { UsaStates } from 'usa-states';

import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import DropDown from '../../fields/DropDown';
import TextArea from '../../fields/TextArea';
import ReactSelect from 'react-select';
import allActions from '../../../../Store/action';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
const UsaStatesList = new UsaStates().states?.map((e) => {
	return { label: e.name, value: e.abbreviation };
});

const GeneralInformation = () => {
	const dispatch=useDispatch()
	const [{ taxPayerForm,taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 1 });

    const {entityType, OptionsForTaxPlanningIndividual}= GLOBAL_VARIABLES
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	// const { otherQuestions } = GLOBAL_VARIABLES;

	// const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 0 });

	// const [{ taxPayerForm }] = useSelector((state) => [state.taxForms]);
    // form States
    
	const [ein, setEin] = useState();
	const [state, setState] = useState({});
    const [authority, setAuthority]=useState();
    const [entityName, setEntityName]=useState();
    const [fiscalYear, setFiscalYear]=useState();
    const [BusinessCity, setBusinessCity]=useState();
    const [businessStart, setBusinessStart]=useState();
	const [selectedEntityType, setEntityType] = useState();
    const [businessService, setBusinessService]=useState();
    const [BusinessAddress, setBusinessAddress]=useState();
    const [BusinesszipCode, setBusinessZipCode]=useState();
    const [taxPeriodEnding, setTaxPeriodEnding]=useState();
    const [corporateWebsite, setCorporateWebsite]=useState();
    const [changeInOwnership, setChangeInOwnership]=useState(null);
    const [taxPeriodBegining, setTaxPeriodBegining]=useState();
    const [BusinessFaxNumber, setBusinessFaxNumber]=useState();
	const [howProvideReport, setHowProvideReport] = useState();
	const [businessActivities, setBusinessActivities] = useState();
	const [specificQuestions, setSpecificQuestions] = useState([]);
    const [representativePhone, setRepresentativePhone]=useState();
    const [businessSellingPrice, setBusinessSellingPrice]=useState();
    const [ownBusinessPercentage, setOwnBusinessPercentage]=useState();
    const [changeInOwnershipInput, setChangeInOwnershipInput]=useState();
    const [stateIdentificationNumber, setStateIdentificationNumber]=useState();
    const [spouseBusinessPercentage, setSposueOwnBusinessPercentage]=useState();
    const [businessRepresentativeName, setBusinessRepresentativeName]=useState();
    const [businessRepresentativeEmail, setBusinessRepresentativeEmail]=useState();
	
	const [errors, setErrors] = useState({}); // Track errors for form fields
	
	const multipleChoice = [] ;

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------
const handleQuestions = (index, bool) => {
    console.log(index, bool);
    const localSpecificQuestions = specificQuestions.slice();
	localSpecificQuestions[index].bool = bool;
    setSpecificQuestions(localSpecificQuestions);
};

	useEffect(() => {
		setSpecificQuestions(JSON.parse(JSON.stringify(OptionsForTaxPlanningIndividual)));
	}, []);

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.GeneralInformation) {

			if (Object.keys(taxPayerForm?.data?.GeneralInformation).length === 0) return;

			const {
				ein,
			state,
			authority,
			entityName,
			fiscalYear,
			BusinessCity,
			businessStart,
			selectedEntityType,
			businessService,
			BusinessAddress,
			BusinesszipCode,
			taxPeriodEnding,
			corporateWebsite,
			changeInOwnership,
			taxPeriodBegining,
			BusinessFaxNumber,
			howProvideReport,
			businessActivities,
			specificQuestions,
			representativePhone,
			businessSellingPrice,
			ownBusinessPercentage,
			changeInOwnershipInput,
			stateIdentificationNumber,
			spouseBusinessPercentage,
			businessRepresentativeName,
			businessRepresentativeEmail
			} = taxPayerForm?.data?.GeneralInformation;

setEin(ein);
setState(state);
setAuthority(authority);
setEntityName(entityName);
setFiscalYear(fiscalYear);
setBusinessCity(BusinessCity);
setBusinessStart(businessStart);
setEntityType(selectedEntityType);
setBusinessService(businessService);
setBusinessAddress(BusinessAddress);
setBusinessZipCode(BusinesszipCode);
setTaxPeriodEnding(taxPeriodEnding);
setCorporateWebsite(corporateWebsite);
setChangeInOwnership(changeInOwnership);
setTaxPeriodBegining(taxPeriodBegining);
setBusinessFaxNumber(BusinessFaxNumber);
setHowProvideReport(howProvideReport);
setBusinessActivities(businessActivities);
setSpecificQuestions(specificQuestions);
setRepresentativePhone(representativePhone);
setBusinessSellingPrice(businessSellingPrice);
setOwnBusinessPercentage(ownBusinessPercentage);
setChangeInOwnershipInput(changeInOwnershipInput);
setStateIdentificationNumber(stateIdentificationNumber);
setSposueOwnBusinessPercentage(spouseBusinessPercentage);
setBusinessRepresentativeName(businessRepresentativeName);
setBusinessRepresentativeEmail(businessRepresentativeEmail);
		}
	}, [taxPayerForm?.data?.GeneralInformation]);

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		// Add more validation rules as needed for other fields
		if (
			specificQuestions
				?.flatMap((data) => {
					return data?.bool;
				})
				.includes(null)
		|| changeInOwnership===null) {
			newErrors.AnswerOtherQuestions = 'Please Select Required Option';
		}

		setErrors(newErrors);

		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		let errors = check_Validity();
		if (errors) return;

		let FormObj = {
			ein,
			state,
			authority,
			entityName,
			fiscalYear,
			BusinessCity,
			businessStart,
			selectedEntityType,
			businessService,
			BusinessAddress,
			BusinesszipCode,
			taxPeriodEnding,
			corporateWebsite,
			changeInOwnership,
			taxPeriodBegining,
			BusinessFaxNumber,
			howProvideReport,
			businessActivities,
			specificQuestions,
			representativePhone,
			businessSellingPrice,
			ownBusinessPercentage,
			changeInOwnershipInput,
			stateIdentificationNumber,
			spouseBusinessPercentage,
			businessRepresentativeName,
			businessRepresentativeEmail
		};

		let formData = taxPayerForm;
		taxPayerForm.data.GeneralInformation = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
    };

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 1;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<small className="ml-4 fw-bold">General Information</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="enityName"
						value={entityName}
						placeholder="Legal Entity Name"
						fn={setEntityName}
					/>
				</div>
                <div className="col-md-6 mb-3 mt-2">
						<DropDown
							options={entityType}
							setValue={(e) => {
								setEntityType(e);
							}}
							value={selectedEntityType}
							label={'What is Entity Type'}
						/>
				</div>
				<div className="col-md-12 mb-3">
					<Input
						type="text"
						mask={"999999999"}
						name="ownBusinessPercentage"
						value={ownBusinessPercentage}
						placeholder="What percentage of the business do you own? %"
						fn={setOwnBusinessPercentage}
					/>
				</div>
				<div className="col-md-12 mb-3">
					<Input
						type="text"
						mask={"999999999"}
						name="spouseBusinessPercentage"
						value={spouseBusinessPercentage}
						placeholder="What percentage of the business does your spouse own? (%)"
						fn={setSposueOwnBusinessPercentage}
					/>
				</div>
				<div className="col-md-12 mb-3">
					<Input
						type="text"
						name="businessService"
						value={businessService}
						placeholder="What is the business's principal product or service?"
						fn={setBusinessService}
					/>
				</div>
                <div className="col-md-6 mb-3">
                <small className="col-md-6 form-label text-start" htmlFor={`checkbox`}>
                Business state
                    </small>
					<ReactSelect
						id="state"
						className="p-0 basic-single mt-2"
						placeholder="Business State"
						closeMenuOnSelect={true}
						options={UsaStatesList}
						onChange={setState}
						value={state}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="corporateWebsite"
						value={corporateWebsite}
						placeholder="Corporate website"
						fn={setCorporateWebsite}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="businessRepresentativeName"
						value={businessRepresentativeName}
						placeholder="Business representative name"
						fn={setBusinessRepresentativeName}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="email"
						name="businessRepresentativeEmail"
						value={businessRepresentativeEmail}
						placeholder="Business representative email"
						fn={setBusinessRepresentativeEmail}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
					type="text"
					mask={"999999999"}
						name="stateIdentificationNumber"
						value={stateIdentificationNumber}
						placeholder="State identification number"
						fn={setStateIdentificationNumber}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="businessStart"
						value={businessStart}
						placeholder="When did the business start?"
						fn={setBusinessStart}
					/>
				</div>
				<div className="col-md-12 mb-3">
					<Input
						type="text"
						mask={"999999999"}
						name="fiscalYear"
						value={fiscalYear}
						placeholder="Does this business use a fiscal year?"
						fn={setFiscalYear}
					/>
				</div>
				<div className=" mb-3 px-3">
					{specificQuestions?.map((data, i) => {
						return (
							<div key={i} className="w-100  align-items-center justify-content-between mt-2">
								<small className="col-md-12 form-check-label text-start" htmlFor={`checkbox-${i}`}>
									{data.question}
									{data.bool === null && (
										<span className="text-danger small-text mx-1">*Required</span>
									)}
								</small>
								<div className="col-md-12">
									<input
										onChange={() => handleQuestions(i, true)}
										checked={data?.bool === true}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 176}`}
										name={`checkbox-${i + 176}`}
									/>
									<label htmlFor={`checkbox-${i + 176}`} role="button" className="px-3 m-0 small">
										Yes
									</label>
									<input
										onChange={() => handleQuestions(i, false)}
										checked={data?.bool === false}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 999}`}
										name={`checkbox-${i + 999}`}
									/>
									<label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
										No
									</label>
								</div>
							</div>
						);
					})}
					{JSON.parse(JSON.stringify(multipleChoice))?.map((data, i) => {
						return (
							<div key={i} className="w-100 my-2">
								<small
									className="col-md-6 form-check-label text-start fw-bold mb-1"
									htmlFor={`howProvideReport-${i}`}
								>
									{data.question}
									{!howProvideReport && (
										<span className="text-danger small-text mx-1">*Required</span>
									)}
								</small>
								{data?.options?.map((option, index) => {
									return (
										<div>
											<input
												onChange={() => setHowProvideReport(option)}
												checked={howProvideReport === option}
												className="form-check-input"
												type="radio"
												id={`howProvideReport-${index}`}
												name={`howProvideReport-${index}`}
											/>
											<label className="px-3 small" htmlFor={`howProvideReport-${index}`}>
												{option}
											</label>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
				<div
                className="w-100 mt-2"
            >
                <small className="col-md-8 form-check-label text-start" htmlFor={`checkbox`}>
				Do you expect any change in ownership?
				{changeInOwnership === null && (
					<span className="text-danger small-text mx-1">*Required</span>
				)}
                </small>
                <div className="col-md-4">
                    <input
                        onChange={() => setChangeInOwnership(true)}
                        checked={changeInOwnership === true}
                        className="form-check-input"
                        type="radio"
                        id={`checkbox-div5`}
                        name={`checkbox-div5`}
                    />
                    <label htmlFor={`checkbox-div5`} role="button" className="px-3 m-0 small">
                        Yes
                    </label>
                    <input
                        onChange={() => setChangeInOwnership(false)}
                        checked={changeInOwnership === false}
                        className="form-check-input"
                        type="radio"
                        id={`checkbox-div4`}
                        name={`checkbox-div4`}
                    />
                    <label htmlFor={`checkbox-div4`} role="button" className="px-3 m-0 small">
                        No
                    </label>
                </div>
            </div>
            {changeInOwnership &&
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="changeInOwnership"
                        value={changeInOwnershipInput}
                        placeholder="Please describe the change in ownership"
                        fn={setChangeInOwnershipInput}
                    />
                </div>
            }
				<div className="col-md-12 mb-3">
					<Input
						type="text"
						mask={"999999999"}
						name="businessSellingPrice"
						value={businessSellingPrice}
						placeholder="What is the expected selling price of the business?"
						fn={setBusinessSellingPrice}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						mask={"999999999"}
						name="taxPeriodBegining"
						value={taxPeriodBegining}
						placeholder="Tax period beginning"
						fn={setTaxPeriodBegining}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						mask={"999999999"}
						name="taxPeriodEnding"
						value={taxPeriodEnding}
						placeholder="Tax period ending"
						fn={setTaxPeriodEnding}
					/>
				</div>
				<div className="col-md-12 mb-3">
					<Input
						type="text"
						name="authority"
						value={authority}
						placeholder="Authiority"
						fn={setAuthority}
					/>
				</div>
				<div className="col-md-12 mb-3">
					<TextArea
						inputName="form-control"
						defaultValue={businessActivities}
						placeholder="Business Activities"
						name="Businessactivities"
						fn={setBusinessActivities}
						row="6"
						required={false}
					/>
				</div>
			</div>
            <small className="ml-4 fw-bold">Address</small>
			<hr className="m-0" />
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                <div className="col-md-6 mb-3">
					<Input
						type="text"
						name="BusinessAddress"
						value={BusinessAddress}
						placeholder="Business address"
						fn={setBusinessAddress}
					/>
				</div>
                <div className="col-md-6 mb-3">
					<Input
						type="text"
						name="BusinessCity"
						value={BusinessCity}
						placeholder="Business City"
						fn={setBusinessCity}
					/>
				</div>
                <div className="col-md-6 mb-3">
					<Input
						type="text"
						name="BusinesszipCode"
						value={BusinesszipCode}
						placeholder="Business zip code"
						fn={setBusinessZipCode}
					/>
				</div>
                <div className="col-md-6 mb-3">
					<Input
						type="text"
						mask={"999999999"}
						name="BusinessFaxNumber"
						value={BusinessFaxNumber}
						placeholder="Business fax number"
						fn={setBusinessFaxNumber}
					/>
				</div>
                <div className="col-md-12 mb-3">
					<Input
						type="text"
						mask={"999999999"}
						name="representativePhone"
						value={representativePhone}
						placeholder="Business representative telephone number"
						fn={setRepresentativePhone}
					/>
				</div>
                <div className="col-md-12 mb-3">
					<Input
						type="text"
						name="representativePhone"
						value={ein}
						placeholder="What is the employer identification number (EIN), if any?"
						fn={setEin}
						required={false}
					/>
				</div>
			</div>
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={()=>goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default GeneralInformation;