const Offcanvas = ({
	header,
	children,
	enableScroll = true,
	position = 'start',
	bodyClass = '',
	trigger,
	style = '',
}) => {
	return (
		<div
			className={`offcanvas offcanvas-${position}`}
			data-bs-scroll={enableScroll}
			tabIndex='-1'
			id={trigger}
			aria-labelledby={`${trigger}Label`}
			style={style}
		>
			<div className='offcanvas-header border-bottom border-light px-4'>
				<h5 className='offcanvas-title' id={`${trigger}Label`}>
					{header}
				</h5>
				<button
					id={`close-${trigger}`}
					type='button'
					className='btn-close'
					data-bs-dismiss='offcanvas'
					aria-label='Close'
				></button>
			</div>
			<div className={`offcanvas-body ${bodyClass} ${!enableScroll ? 'overflow-hidden' : ''}`}>
				{children}
			</div>
		</div>
	);
};

export default Offcanvas;
