/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '../../fields/Button';

import TextArea from '../../fields/TextArea';
import Input from '../../fields/Input';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';

const Farm = () => {
    

    const dispatch=useDispatch()
	const [{ taxPayerForm, taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 23});

    // form States
	const [farmLocation, setFarmLocation] = useState();
	const [nonProductiveValue, setNonProductiveValue] = useState("");
	const [conservationEasement, setConservationEasement] = useState(null);

    const [errors, setErrors] = useState({}); // Track errors for form fields

    useEffect(() => {
    	if (taxPayerForm?.data?.Farm) {
    		if (Object.keys(taxPayerForm?.data?.Farm).length === 0) return;
			const {
                farmLocation,
                nonProductiveValue,
                conservationEasement
			} = taxPayerForm?.data?.Farm;
            setFarmLocation(farmLocation)
            setNonProductiveValue(nonProductiveValue)
            setConservationEasement(conservationEasement)
    	}
    }, [taxPayerForm?.data?.Farm]);

    const check_Validity = () => {
        setErrors({});
        const newErrors = {};
    
        // Add more validation rules as needed for other fields
        if (
            conservationEasement===null) {
            newErrors.AnswerOtherQuestions = 'Please Select Required Option';
        }
    
        setErrors(newErrors);
    
        let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
        // Check if there are any errors
        return ConditionCheck;
    };

	const handleSubmit = (e) => {
		e.preventDefault();

        let errors = check_Validity();
        if (errors) return;

        let FormObj = {
            farmLocation,
                nonProductiveValue,
                conservationEasement
		};
        let formData = taxPayerForm;
        taxPayerForm.data.Farm = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));
        uploadFormData(formData);
    };

    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 23;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                <div className="col-md-12 mb-3">
                    <small className="ml-4 fw-bold">Farm Location?</small>
                    <TextArea
                        inputName="form-control"
                        defaultValue={farmLocation}
                        placeholder=""
                        name="Farm Location?"
                        fn={setFarmLocation}
                        row="6"
                    />
                </div>
                <div className="col-md-12 mb-3">
					<Input
						type="text"
						name="authority"
						value={nonProductiveValue}
						placeholder="What is the total value of your non productive land owned by this business?"
						fn={setNonProductiveValue}
					/>
				</div>
                <div className="w-100 mt-2">
                <small className="col-md-8 form-check-label text-start" htmlFor={`checkbox`}>
				Does the taxpayer's farm currently have a conservation easement in place?
                {conservationEasement===null && (
                    <span className="text-danger small-text mx-1">*Required</span>
                )}
                </small>
                <div className="col-md-4">
                    <input
                        onChange={() => setConservationEasement(true)}
                        checked={conservationEasement === true}
                        className="form-check-input"
                        type="radio"
                        id={`checkbox-div4`}
                        name={`checkbox-div4`}
                    />
                    <label htmlFor={`checkbox-div4`} role="button" className="px-3 m-0 small">
                        Yes
                    </label>
                    <input
                        onChange={() => setConservationEasement(false)}
                        checked={conservationEasement === false}
                        className="form-check-input"
                        type="radio"
                        id={`checkbox-div5`}
                        name={`checkbox-div5`}
                    />
                    <label htmlFor={`checkbox-div5`} role="button" className="px-3 m-0 small">
                        No
                    </label>
                </div>
            </div>
            </div>
            {Object.keys(errors)
    ?.map((key) => {
        return errors[key];
    })
    ?.map((data, index) => {
        return (
            <>
                <small className="text-primary" key={index}>
                    {data}*
                </small>
                <br />
            </>
        );
    })}
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="button"
                        callback={()=>goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
	);
};

export default Farm;