import React, { useEffect, useState } from 'react';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';
import SingleCheckLabelSelector from '../../fields/SingleCheckLabelSelector';
import MultiLineOneFieldInputs from '../../fields/MultiLineOneFieldInput';
import { HandleFormData } from '../../../../utilites';

const CostOfGoodsSale = ({ mode }) => {
	//
	//  ---------------------------------------
	//  Variables , States & Store States
	//  ---------------------------------------
	//
	const dispatch = useDispatch();

	const { costOfGoodSaleOptions, SimpleChoiceOptions, goodsSalesMethodsOptions } = GLOBAL_VARIABLES;
	const uploadFormData = HandleFormData({ parentIndex: 3, myIndex: 1 });

	const [{ taxPayerFormData, taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);
	
	// For Choice Questions
	const [goodsCategories, setGoodsCategories] = useState();
	const [methodOfCost, setMethodOfCost] = useState();
	const [costOfGoods, setCostGoods] = useState(JSON.parse(JSON.stringify(costOfGoodSaleOptions)));

	const [errors, setErrors] = useState({});
	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.CostOfGoodsForm) {
			if (Object.keys(taxPayerForm?.data?.CostOfGoodsForm).length === 0) return;

			const { costOfGoods, goodsCategories, methodOfCost } = taxPayerForm?.data?.CostOfGoodsForm;
			setMethodOfCost(methodOfCost);
			setGoodsCategories(goodsCategories);
			if (costOfGoods) setCostGoods(costOfGoods);
		}
	}, [taxPayerForm?.data?.CostOfGoodsForm]);

	const handleAmountChange = (id, index) => {
		const myInputAmount = document.getElementById(id);
		let localDeduction = costOfGoods;
		localDeduction[index].amount = myInputAmount.value;
		setCostGoods(JSON.parse(JSON.stringify(localDeduction)));
	};
	// ---------------------------------------
	// Functions
	// ---------------------------------------

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		// Add more validation rules as needed for other fields

		if (!goodsCategories) newErrors.goodsCategories = 'Select Category';
		if (goodsCategories?.label === mode && !methodOfCost)
			newErrors.goodsCategories = 'Select Method of Cost';

		setErrors(newErrors);

		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let errors = check_Validity();
		if (errors) return;

		let FormObj = { goodsCategories, methodOfCost, costOfGoods };

		let formData = taxPayerForm;
		taxPayerForm.data.CostOfGoodsForm = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
	};

	console.log('CostOfGoodsForm', taxPayerForm);

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 3;
		let myIndex = 1;
		dispatch(
			allActions.taxForms.moveToBack({
				parentIndex,
				myIndex,
				taxPayerFormData,
			})
		);
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<SingleCheckLabelSelector
				heading={'Does the business make,buy or sell goods?'}
				value={goodsCategories}
				setValue={setGoodsCategories}
				mapData={SimpleChoiceOptions}
			/>
			{goodsCategories?.label === mode && (
				<>
					<SingleCheckLabelSelector
						heading={goodsSalesMethodsOptions.question}
						value={methodOfCost}
						setValue={setMethodOfCost}
						mapData={goodsSalesMethodsOptions.Options}
					/>

					<MultiLineOneFieldInputs
						Options={costOfGoods}
						handleChange={handleAmountChange}
						placeholder={'Amount'}
					/>
				</>
			)}
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default CostOfGoodsSale;
