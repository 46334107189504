import { ADMIN } from "./type";

const DEFAULT_STATE = {
	keapVerification: { message: "", status: "idle", data: {} },
};

const INITIAL_STATE = {
	...DEFAULT_STATE,
};

const Admin = (state = INITIAL_STATE, action) => {
	if (action.type === ADMIN.keapVerificarionSuccess) {
		return {
			...state,
			keapVerification: action.payload,
		};
	}
	if (action.type === ADMIN.keapVerificarionIDLE) {
		return {
			...state,
			keapVerification: DEFAULT_STATE.keapVerification,
		};
	}
    if (action.type === ADMIN.keapVerificarionError) {
		return {
			...state,
			keapVerification: action.payload,
		};
	}

	return state;
};

export default Admin;
