import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import Input from "../../forms/fields/Input";
import CreditCardInput from "../../forms/fields/Credit-Card-input";

export default function MembershipCardInput({
	cardName,
	setCardName,
	cardNumber,
	mmYy,
	setMmYy,
	cvc,
	zipCode,
	setZipCode,
	defaultSelection,
	setDefaultSelection,
	handleSubmitCard,
	OkButtonText,
	editCard,
	AllowDefaultSelect,
}) {
	const customStyles = {
		style: {
			base: {
				fontSize: "1.2rem",
				lineHeight: "1.5",
			},
		},
	};

	const cardNumberElementOptions = {
		...customStyles,
		placeholder: "Card Number",
	};

	const cardExpiryElementOptions = {
		...customStyles,
		placeholder: "MM / YY",
	};

	const cardCvcElementOptions = {
		...customStyles,
		placeholder: "CVC",
	};

	const paymentElement = document.getElementsByClassName("__PrivateStripeElement");
	if (paymentElement) {
		for (let i = 0; i < paymentElement.length; i++) {
			paymentElement[i].style.height = "50px";
		}
	}

	return (

		<form
			className="w-100 d-flex justify-content-between flex-wrap align-items-center"
			onSubmit={(e) => handleSubmitCard(e)}
		>
			<div className="w-100 d-flex align-items-center justify-content-center">
				{" "}
			</div>
			<div className="w-100 mb-4 row m-0 px-2">
				{cardNumber ? (
					<CreditCardInput
						type="text"
						value={cardNumber}
						name="Card number"
						placeholder="1234 5678 3234 2342"
						paymentcard={true}
						editCard={editCard}
						isDisabled={true}
						showLabel={true}
						fn={() => { }}
					/>
				) : (
					<>
						<label className={`form-label p-0`}>Card number</label>
						<CardNumberElement className="form-control" options={cardNumberElementOptions} />
					</>
				)}
			</div>
			<div className="w-100 mb-4 row m-0 p-0">
				<Input
					type="text"
					value={cardName}
					name="Name on Card"
					placeholder="Name on card"
					fn={setCardName}
					isDisabled={editCard ? true : false}
				/>
			</div>
			<div className="mb-2 w-100 m-0 p-0">
				<div className="row w-100 m-0 p-0">

					<div className="mb-3 col-lg-6 col-sm-12">
						{mmYy ? (
							<Input
								type="text"
								value={mmYy}
								name="MM / YYY"
								placeholder="MM / YY"
								fn={setMmYy}
							/>
						) : (
							<>
								<label className={`form-label`}>Expiration date</label>
								<CardExpiryElement className="form-control" options={cardExpiryElementOptions} />
							</>
						)}
					</div>
					<div className="mb-3 col-lg-6 col-sm-12">
						{cvc ? (
							<Input
								type="text"
								value={cvc}
								name="CVC"
								placeholder="CVC"
								fn={() => { }}
								mask={editCard ? "" : "999"}
								isDisabled={true}
							/>
						) : (
							<>
								<label className={`form-label`}>CVC</label>
								<CardCvcElement className="form-control" options={cardCvcElementOptions} />
							</>
						)}
					</div>
					<div className="mb-3 col-lg-6 col-sm-12">
						<Input
							type="text"
							value={zipCode}
							name="Zipcode"
							placeholder="Zipcode"
							fn={setZipCode}
							mask="99999999"
						/>
					</div>
					<div className="mb-3 col-lg-12 col-sm-12">
						<div className="w-full d-flex align-items-start justify-content-start">
							<input
								className="form-check-input custom-checkbox pl-2 "
								type="checkbox"
								id={`checkbox`}
								disabled={!AllowDefaultSelect}
								checked={defaultSelection}
								onChange={() => setDefaultSelection(!defaultSelection)}
							/>
							<label
								role="button"
								className="form-check-label text-start text-muted mx-2"
								htmlFor={`checkbox`}
							>
								Make this my default payment method
							</label>
						</div>
					</div>
				</div>
			</div>
			<div className={"w-100 px-2"}>
				<button
					id="paymentSubmit"
					className={"btn btn-primary custon-button-text btn-md w-100"}
					type="submit"
				>
					{OkButtonText}
				</button>
			</div>
			{/*<p className="text-muted small-text col-lg-10 mt-2 px-2">Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.</p>*/}
		</form>
	);
}
