/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "../../../fields/Button";
import AddOfficerInformation from "./AddOfficerInformation";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../../Store/action";
import { HandleFormData } from "../../../../../utilites";

const Officer = () => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const dispatch = useDispatch();

	const [{ taxPayerFormData, taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);

	const uploadFormData = HandleFormData({ parentIndex: 1, myIndex: 0 });


	const [OfficerList, setOfficerList] = useState([]);

	// For Choice Questions

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.OfficerForm) {
			if (Object.keys(taxPayerForm?.data?.OfficerForm).length === 0) return;

			const { OfficerList } = taxPayerForm?.data?.OfficerForm;
			setOfficerList(OfficerList);
		}
	}, [taxPayerForm?.data?.OfficerForm]);

	// ---------------------------------------
	// Functions
	// ---------------------------------------
	const handleSubmit = (e) => {
		let FormObj = { OfficerList };

		let formData = taxPayerForm;
		taxPayerForm.data.OfficerForm = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData)
	};

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 1;
		let myIndex = 0;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	return (
		<div
			className="w-100 mt-3"
			onSubmit={(e) => {
				e.preventDefault();
			}}
		>
			<AddOfficerInformation
				OfficerList={OfficerList}
				setOfficerList={setOfficerList}
			/>
			<br />
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={"w-25 custon-button  mt-4"}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={"w-25 custon-button  mt-4"}
						type="button"
						callback={() => handleSubmit()}
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</div>
	);
};

export default Officer;
