/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../dashboard/components/Header';
import Footer from '../../dashboard/components/Footer';
import StepForm from '../../../components/forms/OrganizerForm/stepForm';
import Forms from '../../../components/forms/OrganizerForm/Form';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Message from '../../../components/forms/fields/Message';
import allActions from '../../../Store/action';

const Organizer = ({modal,editable}) => {
	// const {TaxPlanningBusinessOwner}=GLOBAL_VARIABLES
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [message, setMessage] = useState({ show: false });
	const [{ taxPayerFormData, formInformation }, { taxPreparation }] = useSelector((state) => [
		state.taxForms,
		state.dashboard,
	]);

	useEffect(() => {
		if (!formInformation && !modal) navigate('/dashboard');
	}, [formInformation,modal]);

	useEffect(() => {
		// if Success
		if (taxPreparation?.state === true) {
			setMessage({
				show: true,
				type: 'Success',
				text:
					typeof taxPreparation.message === 'string'
						? taxPreparation.message
						: 'Successfully Done!',
			});
			changeLoading(false);
		}
		// if Error
		if (taxPreparation?.state === false) {
			// Show Message Alert
			setMessage({
				show: true,
				type: 'Error',
				text:
					typeof taxPreparation.message === 'string'
						? taxPreparation.message
						: 'Something Went Wrong !',
			});
			changeLoading(false);

			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth', // You can use 'auto' for an instant scroll or 'smooth' for a smooth scroll
			});
		}
		if (taxPreparation?.state === 'idle') {
			setMessage({ show: false });
		}
	}, [taxPreparation.state]);

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

	return (
		<div className='clearfix'>
			{!modal && <Header name='Tax Form' />}
			{!formInformation?.lead_id &&
			<div class="alert alert-secondary" role="alert">
			The Form is Open in Read-Only mode
		  </div>
			}
			<div className='container page'>
				<div className='clearfix pt-4 mb-4'>
					{message.show ? <Message message={message} /> : <></>}
					<div className='row'>
						<div className='col-md-4 mb-2'>
							<div
								className='card sticky-top border-1 border-light rounded py-3 px-4'
								style={{ zIndex: 99 }}
							>
								<StepForm Options={taxPayerFormData} />
							</div>
						</div>
						<div className='col-md-8 mb-2'>
							<div className='card border-1 border-light rounded shadow-hover p-0'>
								<Forms taxPayerFormData={taxPayerFormData} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Organizer;
