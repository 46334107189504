import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Main Routes
import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Thankyou from '../pages/Thankyou';
import BookAppointment from '../pages/BookAppointment';
import Faq from '../pages/Faq';

// Authuntication Routes
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import VerifyEmail from '../components/forms/VerifyEmail';
import ForgotPassword from '../pages/auth/ForgotPassword';
import Existingaccount from '../pages/auth/existing-account';

// Dashboard Routes
import Settings from '../pages/dashboard/Settings';
import Dashboard from '../pages/dashboard/Dashboard';
import Notifications from '../pages/dashboard/Notifications';
// Leads Routes starts
import LeadList from '../pages/dashboard/leads/list';
import LeadDetails from '../pages/dashboard/leads/details';
// Leads Routes ends
import DisputesList from '../pages/dashboard/disputes/list';
import JobList from '../pages/dashboard/jobs/list';
import JobDetails from '../pages/dashboard/jobs/details';
import PayoutList from '../pages/dashboard/payouts/list';
import InvoiceList from '../pages/dashboard/invoices/list';
import AppointmentList from '../pages/dashboard/appointments/list';
import DocumentList from '../pages/dashboard/documents/list';
import UserList from '../pages/dashboard/users/list';
import userDetails from '../pages/dashboard/users/details';
import InvoiceDetail from '../pages/dashboard/invoices/detail';
import MessageList from '../pages/dashboard/messages/list';

import NotFound from '../pages/NotFound';

// Layouts
import MainLayout from '../layouts/MainLayout';
import FrontendLayout from '../layouts/FrontendLayout';
import DashboardLayout from '../layouts/DashboardLayout';
import AuthLayout from '../layouts/AuthLayout';
import React from 'react';
import ProtectedRoute from './ProtectedRoutes/ProtectedRoute';
import ProtectDashboard from './ProtectedRoutes/ProtectDashboard';
import Otp from '../pages/auth/Otp';
import Organizer from '../pages/dashboard/organizer/organizer';
import Questionnaire from '../pages/dashboard/Questionnaire';
import ProjectList from '../pages/dashboard/projects/list';
import ProjectDetails from '../pages/dashboard/projects/details';
import CreateContract from '../pages/dashboard/leads/createContract';
import EditContract from '../pages/dashboard/jobs/editContract';
import Contract from '../pages/dashboard/projects/contract';
import RegisterTaxPro from '../pages/auth/RegisterTaxPro';
import UserAgreement from '../pages/UserAgreement';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import CustomerCallBoard from '../pages/dashboard/leads/components/CustomerCallBoard';
import KeapVerification from '../pages/dashboard/Admin/keapVerification';
import CalendlyVerification from '../pages/dashboard/CalendlyVerification';
import OutlookCalendarVerification from '../pages/dashboard/OutlookCalendarVerification';
import GoogleCalendarVerification from '../pages/dashboard/GoogleCalendarVerification';
import Billing from '../pages/dashboard/billing/list';
import Membership from '../pages/dashboard/billing/membership';
import ActiveUpcomingTasks from '../pages/dashboard/tasks/list';
import AdminBilling from '../pages/dashboard/billing/adminBilling';
import Checkout from '../pages/dashboard/billing/checkout';
import SuccessThanks from '../pages/dashboard/billing/successThanks';
import AddPaymentMethods from '../pages/dashboard/billing/addPaymentMethod';
//
import AppointmentDetails from '../pages/AppointmentDetails';
import AppointmentStatus from '../pages/AppointmentStatus';
import CreateJob from '../pages/dashboard/leads/CreateJob';
import QRCodePage from '../pages/dashboard/documents/QRCodePage';
import DocumentUploadMonile from '../pages/dashboard/documents/DocumentUploadMonile';
import Recordings from '../pages/dashboard/recording/list';
import EditJob from '../pages/dashboard/jobs/editJob';

const RoutesCollection = () => {
	return (
		<Router>
			<Routes>
				<Route path='/' element={<MainLayout />}>
					<Route path='*' element={<FrontendLayout />}>
						<Route index element={<Home />} />
						<Route path='about' element={<About />} />
						<Route path='contact' element={<Contact />} />
						<Route path='thankyou' element={<Thankyou />} />
						<Route path='book-appointment' element={<BookAppointment />} />
						<Route path='faq' element={<Faq />} />
						<Route path='user-agreement' element={<UserAgreement />} />
						<Route path='privacy-policy' element={<PrivacyPolicy />} />				
					</Route>
					<Route
						path='*'
						element={<ProtectedRoute login={false} Component={AuthLayout} path='/dashboard/' />}
					>
						<Route path='login' element={<Login />} />
						<Route path='forgot-password' element={<ForgotPassword />} />
						<Route path='existing-account' element={<Existingaccount />} />
						<Route path='api/user/:status/:id' element={<VerifyEmail />} />
						<Route
							path='registerTaxPro'
							element={
								<ProtectedRoute login={false} Component={RegisterTaxPro} path='/dashboard/' />
							}
						/>
						<Route
							path='appointment-details/:id'
							element={<AppointmentDetails />}
						/>
							
					<Route path='qr-code/:uuid' element={<QRCodePage /> }  />
					<Route path='qr-upload/:uuid' element={<DocumentUploadMonile />} />
					</Route>
					<Route
						path='register'
						element={<ProtectedRoute login={false} Component={Register} path='/dashboard/' />}
					/>

					<Route
						path='/otp'
						element={<ProtectedRoute login={true} Component={Otp} path='/login' />}
					/>
					<Route
						path='*'
						element={<ProtectedRoute login={true} Component={DashboardLayout} path='/login' />}
					>
						<Route path='dashboard' element={<ProtectDashboard Component={Dashboard} />} />
						<Route path='dashboard/leads' element={<ProtectDashboard Component={LeadList} />} />
						<Route
							path='dashboard/leads/:uuid'
							element={<ProtectDashboard Component={LeadDetails} />}
						/>
						<Route
							path='dashboard/createContract/:uuid'
							element={<ProtectDashboard Component={CreateContract} />}
						/>
						<Route
							path='dashboard/jobs/create/:uuid'
							element={<ProtectDashboard Component={CreateJob} />}
						/>
						<Route
							path='dashboard/editContract/:uuid'
							element={<ProtectDashboard Component={EditContract} />}
						/>
						<Route
							path='dashboard/jobs/edit/:uuid'
							element={<ProtectDashboard Component={EditJob} />}
						/>
						<Route path='dashboard/jobs' element={<ProtectDashboard Component={JobList} />} />
						<Route
							path='dashboard/jobs/:uuid'
							element={<ProtectDashboard Component={JobDetails} />}
						/>
						<Route
							path='dashboard/calls'
							element={<ProtectDashboard Component={CustomerCallBoard} />}
						/>
						<Route
							path='dashboard/calls/:uuid/details'
							element={<ProtectDashboard Component={CustomerCallBoard} />}
						/>
						<Route
							path='dashboard/messages'
							element={<ProtectDashboard Component={MessageList} />}
						/>
						<Route
							path='dashboard/messages/:uuid/details'
							element={<ProtectDashboard Component={MessageList} />}
						/>
						<Route
							path='dashboard/invoices'
							element={<ProtectDashboard Component={InvoiceList} />}
						/>
						<Route
							path='dashboard/invoices/detail'
							element={<ProtectDashboard Component={InvoiceDetail} />}
						/>
						<Route
							path='dashboard/invoices/payouts'
							element={<ProtectDashboard Component={PayoutList} />}
						/>
						<Route
							path='dashboard/invoices/disputes'
							element={<ProtectDashboard Component={DisputesList} />}
						/>
						<Route
							path='dashboard/invoices/:id'
							element={<ProtectDashboard Component={InvoiceDetail} />}
						/>
						<Route
							path='dashboard/projects'
							element={<ProtectDashboard Component={ProjectList} />}
						/>
						<Route
							path='dashboard/projects/:uuid'
							element={<ProtectDashboard Component={ProjectDetails} />}
						/>

						<Route
							path='dashboard/contract/:id'
							element={<ProtectDashboard Component={Contract} />}
						/>
						<Route
							path='dashboard/appointments'
							element={<ProtectDashboard Component={AppointmentList} />}
						/>
						<Route
							path="dashboard/appointments/change-status/:statusId/:uuid"
							element={<ProtectDashboard Component={AppointmentStatus} />}
						/>
						<Route
							path='dashboard/billing'
							element={<ProtectDashboard Component={Billing} />}
						/>
						<Route
							path='dashboard/adminbilling'
							element={<ProtectDashboard Component={AdminBilling} />}
						/>
						<Route
							path='dashboard/membership'
							element={<ProtectDashboard Component={Membership} />}
						/>	
						<Route
							path='dashboard/checkout'
							element={<ProtectDashboard Component={Checkout} />}
						/>	
						<Route
							path='dashboard/successThanks'
							element={<ProtectDashboard Component={SuccessThanks} />}
						/>
						<Route
							path='dashboard/AddPaymentMethods'
							element={<ProtectDashboard Component={AddPaymentMethods} />}
						/>	
						<Route
						path='dashboard/tasks'
						element={<ProtectDashboard Component={ActiveUpcomingTasks} />}
						/>
						<Route
						path='dashboard/recordings'
						element={<ProtectDashboard Component={Recordings} />}
						/>
						<Route
							path='dashboard/documents'
							element={<ProtectDashboard Component={DocumentList} />}
						/>
						<Route path='dashboard/settings' element={<ProtectDashboard Component={Settings} />} />
						<Route
							path='dashboard/notifications'
							element={<ProtectDashboard Component={Notifications} />}
						/>
						<Route path='dashboard/onboard' element={<ProtectDashboard />} />
						<Route path='dashboard/users' element={<ProtectDashboard Component={UserList} />} />
						<Route
							path='dashboard/users/:uuid'
							element={<ProtectDashboard Component={userDetails} />}
						/>
						<Route path='dashboard/underReview' element={<ProtectDashboard />} />
						<Route
							path='dashboard/organizer'
							element={<ProtectDashboard Component={Organizer} />}
						/>
						<Route
							path='dashboard/questionnaire'
							element={<ProtectDashboard Component={Questionnaire} />}
						/>
						<Route
							path='dashboard/verify'
							element={<ProtectDashboard Component={KeapVerification} />}
						/>
						<Route
							path='dashboard/calendly/auth'
							element={<ProtectDashboard Component={CalendlyVerification} />}
						/>
						<Route
							path='dashboard/outlook_calendar/auth'
							element={<ProtectDashboard Component={OutlookCalendarVerification} />}
						/>
						<Route
							path='dashboard/google_calendar/auth'
							element={<ProtectDashboard Component={GoogleCalendarVerification} />}
						/>
						<Route path='*' element={<NotFound />} />
					</Route>
				</Route>
			</Routes>
		</Router>
	);
};

export default RoutesCollection;
