import React, { useState, useEffect, useRef, Fragment } from "react";
import axios from "axios";
import { BASEURL,
  getAmericanTimezones,
  timezoneFormatter,
  convertTime
 } from "../../utilites";
import Button from "./fields/Button";
import ReactSelect from "react-select";
import moment from "moment";
import { AiOutlinePlus, AiOutlineMinus, AiOutlineDelete } from "react-icons/ai";
// CSS
import "react-datepicker/dist/react-datepicker.css";
import Message from "./fields/Message";

const customStyles = {
  option: (provided,state) => ({
    ...provided,
    textAlign: "left",
    height:'40px',
    color:state.isSelected ? provided.color : 'gray',
  }),
  singleValue: (provided) => ({
    ...provided,
    textAlign: "left",
  }),
  placeholder: (provided) => ({
    ...provided,
    textAlign: "left",
    fontSize: 14,
  }),
};

const defaultTimeRange = [
  {
    from: "00:00",
    to: "23:30",
  },
];

const defaultTimeRanges = {
  Sunday: defaultTimeRange,
  Monday: defaultTimeRange,
  Tuesday: defaultTimeRange,
  Wednesday: defaultTimeRange,
  Thursday: defaultTimeRange,
  Friday: defaultTimeRange,
  Saturday: defaultTimeRange,
};

const american_timezones = getAmericanTimezones();

export default function UserAvailabilityForm({
  reloadUser,
  setAlert,
  data,
  currentTimezone,
  calendlyData,
  user_allow_appointments,
}) {
  const [timeRanges, setTimeRanges] = useState(defaultTimeRanges);
  const [timezone, setTimezone] = useState(
    currentTimezone
  );
  const [timezoneName, setTimezoneName] = useState(
currentTimezone
  );

const topTimezones = [
  "America/New_York",
  "America/Chicago",
  "America/Denver",
  "America/Los_Angeles",
];
const americanTimeZones = moment.tz.zonesForCountry("US");
  const [allow_appointments, setAllow_appointments] = useState(
    user_allow_appointments
  );
  const [errors, setErrors] = useState({
    timeRanges: false,
    timezone: false,
    general: "",
  });
  const [message, setMessage] = useState({ show: true });

  const button = useRef();
  const syncButton = useRef();

  const convertTimesToSelectedTimezone = (selectedTimezone) => {
    setTimezone(selectedTimezone.value);
    setTimezoneName(selectedTimezone.label);
  };

  // function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    button.current.disabled = true;
    if (errors.general !== "") setErrors({ errors, general: "" });
    axios
      .post(`${BASEURL}/api/auth/profile/set-availability`, {
        time_ranges: timeRanges,
        timezone,
        allow_appointments: allow_appointments,
      })
      .then(() => {
        button.current.disabled = false;
        // Reload user data
        reloadUser();
        // Close Modal
        document.querySelector(".userAvailabilityModalClose").click();
        setAlert("Availability has been set successfully!");
      })
      .catch((error) => {
        button.current.disabled = false;
        console.error(error.message);
        // Set general error
        if (errors.general === "")
          setErrors({
            errors,
            general: error?.response?.data?.message || error.message,
          });
      });
  };

  // Effect hook to update timeRanges initially (based on the data prop)
  useEffect(() => {
    if (data) {
      setTimeRanges({
        Sunday: data["Sunday"],
        Monday: data["Monday"],
        Tuesday: data["Tuesday"],
        Wednesday: data["Wednesday"],
        Thursday: data["Thursday"],
        Friday: data["Friday"],
        Saturday: data["Saturday"],
      });
    }
  }, [data]);


  // Effect hook to validate time ranges on change
  useEffect(() => {
    Object.keys(timeRanges).forEach((day) => {
      const timeRange = timeRanges[day];
      // Validate time ranges (if invalid range (meaning logical issue from > to, etc.), of if invalid values in different time ranges, correct them)
      if (timeRange !== null && timeRange?.length > 0) {
        timeRange?.forEach((tr, index) => {
          if (tr.from > tr.to) {
            // Corrent the time range (from < to)
            setTimeRanges((prev) => ({
              ...prev,
              [day]: prev[day]?.map((tr, i) =>
                i === index ? { from: tr.to, to: tr.from } : tr
              ),
            }));
          }
          if (
            index < timeRange.length - 1 &&
            tr.to > timeRange[index + 1].from
          ) { 
            // Corrent the time range (to < next from)
            setTimeRanges((prev) => ({
              ...prev,
              [day]: prev[day]?.map((tr, i) =>
                i === index
                  ? { from: tr.from, to: timeRange[index + 1].from }
                  : tr
              ),
            }));
          }
        });
      }
    });
  }, [timeRanges]);




  const addTimeRange = (day) => {
    // Add time range to the selected day (calculate the next time range. If the last time is equal to 23:30, ignore the addition of a new time range)
    // If the day has no time range, add the default time range
    if (timeRanges[day] === null || timeRanges[day].length === 0) {
      setTimeRanges((prev) => ({
        ...prev,
        [day]: defaultTimeRange,
      }));
      // Check the checkbox
      document.querySelector(`.AvailabilityFormCheckbox-${day}`).checked = true;
    } else {
      const lastTimeRange = timeRanges[day][timeRanges[day].length - 1];
      if (lastTimeRange.to !== "23:30") {
        setTimeRanges((prev) => ({
          ...prev,
          [day]: [...prev[day], { from: lastTimeRange.to, to: "23:30" }],
        }));
      }
    }
  };

  const syncCalendlyData = () => {
    syncButton.current.classList.add("disabled");
    axios
      .get(`${BASEURL}/api/calendly/get-user-schedules`)
      .then((data) => {
        const response = data.data.data[0];
        const timezone = response.timezone;
        const rules = response.rules;
        if (timezone && american_timezones.find((t) => t.tzCode === timezone)) {
          setTimezone(timezone);
        }
        if (rules) {
          // Rules -> [{ intervals: [], type: "wday", wday: "sunday" }, ..., { intervals: [{from: '00:00', to: '01:00'}, {from: '02:00', to: '03:00'}, {from: '09:00', to: '17:00'},{from: '18:00', to: '19:00'},{from: '20:00', to: '21:00'},{from: '22:00', to: '23:00'}], type: "wday", wday: "sunday" }]
          // Set time ranges for each day based on the rules
          const newTimeRanges = {};
          rules.forEach((rule) => {
            // first letter should be uppercase
            const day = rule.wday.charAt(0).toUpperCase() + rule.wday.slice(1);
            const intervals = rule.intervals;
            if (intervals.length > 0) {
              newTimeRanges[day] = intervals?.map((interval) => ({
                from: interval.from,
                to: interval.to,
              }));
            } else {
              newTimeRanges[day] = null;
            }
          });
          setTimeRanges((prev) => ({
            ...newTimeRanges,
          }));
        }
        syncButton.current.classList.remove("disabled");
      })
      .catch((error) => {
        setMessage({
          show: true,
          type: "Error",
          text: error.response?.data?.error,
        });
        syncButton.current.classList.remove("disabled");
      });
  };

  return (
    <form className="appointmentsform needs-validation" onSubmit={handleSubmit}>
      {/* If calendlydata is present, add a button 'Sync calendly schedules' */}
      {calendlyData && (
        <div className="row">
          <div className="col">
            <span
              className="btn btn-outline-primary w-100 mb-4"
              onClick={syncCalendlyData}
              ref={syncButton}
            >
              Sync Your Calendly Schedules
            </span>
          </div>
        </div>
      )}
      {message.show ? <Message message={message} /> : <></>}
      {Object.keys(timeRanges)?.map((day) => (
        <div className="row justify-content-end mb-3" key={day}>
          <div className="col d-flex justify-content-start align-items-center mb-2">
            <div className="form-check">
              <input
                type="checkbox"
                className={`form-check-input AvailabilityFormCheckbox-${day}`}
                placeholder={day}
                aria-label={day}
                value={day}
                id={day}
                onChange={(e) => {
                  if (
                    e.target.checked &&
                    (timeRanges[day] === null || timeRanges[day]?.length === 0)
                  ) {
                    setTimeRanges((prev) => ({
                      ...prev,
                      [day]: defaultTimeRange,
                    }));
                  } else if (
                    timeRanges[day] !== null &&
                    timeRanges[day]?.length > 0
                  ) {
                    setTimeRanges((prev) => ({
                      ...prev,
                      [day]: null,
                    }));
                  }
                }}
                defaultChecked={
                  timeRanges[day] !== null && timeRanges[day]?.length > 0
                }
              />
              <label htmlFor={day} className="form-check-label">
                {day.substring(0, 3).toUpperCase()}
              </label>
            </div>
          </div>
          {timeRanges[day] !== null && timeRanges[day]?.length > 0 ? (
            timeRanges[day]?.map((timeRange, index) => (
              <Fragment key={index}>
                <div className="col-md-4 mb-2">
                  <input
                    className="form-control"
                    type="time"
                    value={timeRange.from}
										onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) =>
                      // Set time range from (for only the selected object)
                    {
                      setTimeRanges((prev) => ({
                        ...prev,
                        [day]: prev[day]?.map((tr, i) =>
                          i === index ? { ...tr, from: e.target.value } : tr
                        ),
                      }))
                    }
                    }
                  />
                </div>
                <div className="col-1 d-flex justify-content-center align-items-center mb-2">
                  <AiOutlineMinus />
                </div>
                <div className="col-md-3 mb-2">
                  <input
                    className="form-control"
                    type="time"
                    value={timeRange.to}
										onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) =>
                      // Set time range to (for only the selected object)
                      setTimeRanges((prev) => ({
                        ...prev,
                        [day]: prev[day]?.map((tr, i) =>
                          i === index ? { ...tr, to: e.target.value } : tr
                        ),
                      }))
                    }
                  />
                </div>
                <div className="col-md-2 d-flex justify-content-around align-items-center mb-2">
                  <AiOutlineDelete
                    className="text-danger cursor-pointer"
                    onClick={() => {
                      // If the day has no time range, set it to null
                      if (timeRanges[day].length === 1) {
                        setTimeRanges((prev) => ({
                          ...prev,
                          [day]: null,
                        }));
                        // Uncheck the checkbox
                        document.querySelector(
                          `.AvailabilityFormCheckbox-${day}`
                        ).checked = false;
                        return;
                      }
                      setTimeRanges((prev) => ({
                        ...prev,
                        [day]: prev[day].filter((_, i) => i !== index),
                      }));
                    }}
                    size={20}
                  />
                  <AiOutlinePlus
                    className={`text-primary cursor-pointer${
                      timeRange.to === "23:30" ? " pe-none disabled" : ""
                    }${
                      index !== timeRanges[day].length - 1
                        ? " opacity-0 pe-none"
                        : ""
                    }`}
                    onClick={() => addTimeRange(day)}
                    size={20}
                  />
                </div>
              </Fragment>
            ))
          ) : (
            <Fragment>
              <div className="col-md-8 mb-2">Unavailable</div>
              <div className="col-md-2 d-flex justify-content-around align-items-center mb-2">
                <AiOutlineDelete className="opacity-0 pe-none" size={20} />
                <AiOutlinePlus
                  className="text-primary cursor-pointer"
                  size={20}
                  onClick={() => addTimeRange(day)}
                />
              </div>
            </Fragment>
          )}
        </div>
      ))}
      <fieldset className="border-top p-1 mt-3">
        <legend className="fs-6 float-none w-auto px-2 mx-2 text-start">
          <small>Timezone</small>
        </legend>
      </fieldset>
      <div className="clearfix">
      <ReactSelect
                id="users"
                className="p-0 basic-single"
                placeholder="Select User"
                styles={customStyles}
                closeMenuOnSelect={true}
                options={[
                  ...topTimezones?.map((v) => ({
                    value: v,
                    label: timezoneFormatter(v),
                  })),
                  ...americanTimeZones?.map((v) => ({
                    value: v,
                    label: timezoneFormatter(v),
                  })),
                ]}
                onChange={(v) => convertTimesToSelectedTimezone(v)}
                value={{
                  value: timezone,
                  label: `${timezoneFormatter(timezoneName)} (${convertTime(
                    timezone,
                    "h:mm A"
                  )})`,
                }}  
              />
      </div>
      {errors.general && errors.general !== "" && (
        <div className="row">
          <div className="mb-3">
            <div className="alert alert-danger" role="alert">
              {errors.general}
            </div>
          </div>
        </div>
      )}
      <div className="d-flex gap-3 mt-3 px-1">
        <input
          class="form-check-input"
          type="checkbox"
          checked={!allow_appointments}
          value={allow_appointments}
          onClick={(e) => setAllow_appointments((prev) => !prev)}
          id="defaultCheck1"
        />
        <label class="form-check-label text-muted" for="defaultCheck1">
          I do not want other users setting up appointments with me.
        </label>
      </div>
      <Button
        buttonRef={button}
        mainClass={`btn btn-primary btn-lg px-4${
          Object.values(errors).indexOf(true) > -1 ? " disabled" : ""
        }`}
        wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-4"
        buttonText="Save Changes"
      />
    </form>
  );
}
