/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';

const FringeBenefits = () => {
    const {fringeOptions}= GLOBAL_VARIABLES

    const dispatch=useDispatch()
	const [{ taxPayerForm, taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 13 });

    // for Current component States
    const [groupInsurance, setGroupInsurance] = useState();
	const [howProvideReport, setHowProvideReport] = useState();
    const [discountPrograms, setdiscountPrograms] = useState();
    const [DeMinimusBenefits, setDeMinimusBenefits] = useState();
	const [specificQuestions, setSpecificQuestions] = useState([]);
    const [fringeBenifitCosts, setfringeBenifitCosts] = useState();
    const [disabilityInsurance, setDisabilityInsurance] = useState();
    const [QualifiedDependentCare, setQualifiedDependentCare] = useState();
    const [totalAnnualOutOfPocket, setTotalAnnualOutOfPocket] = useState();
    const [workingConditionsBenefits, setWorkingConditionsBenefits] = useState();
    const [qualifiedTransportationCosts, setQualifiedTransportationCosts] = useState();

    const [errors, setErrors] = useState({}); // Track errors for form fields

    const multipleChoice = [] ;
    
    const handleQuestions = (index, bool) => {
        const localSpecificQuestions = specificQuestions.slice();
        localSpecificQuestions[index].bool = bool;
        setSpecificQuestions(localSpecificQuestions);
    };

    useEffect(() => {
		setSpecificQuestions(JSON.parse(JSON.stringify(fringeOptions)));
	}, []);
    
    useEffect(() => {
    	if (taxPayerForm?.data?.FringeBenefits) {
    		if (Object.keys(taxPayerForm?.data?.FringeBenefits).length === 0) return;
			const {
                groupInsurance,
                howProvideReport,
                discountPrograms,
                DeMinimusBenefits,
                specificQuestions,
                fringeBenifitCosts,
                disabilityInsurance,
                QualifiedDependentCare,
                totalAnnualOutOfPocket,
                workingConditionsBenefits,
                qualifiedTransportationCosts
			} = taxPayerForm?.data?.FringeBenefits;
            setGroupInsurance(groupInsurance)
            setHowProvideReport(howProvideReport)
            setdiscountPrograms(discountPrograms)
            setDeMinimusBenefits(DeMinimusBenefits)
            setSpecificQuestions(specificQuestions)
            setfringeBenifitCosts(fringeBenifitCosts)
            setDisabilityInsurance(disabilityInsurance)
            setQualifiedDependentCare(QualifiedDependentCare)
            setTotalAnnualOutOfPocket(totalAnnualOutOfPocket)
            setWorkingConditionsBenefits(workingConditionsBenefits)
            setQualifiedTransportationCosts(qualifiedTransportationCosts)
    	}
    }, [taxPayerForm?.data?.FringeBenefits]);

    const check_Validity = () => {
        setErrors({});
        const newErrors = {};
    
        // Add more validation rules as needed for other fields
        if (
            specificQuestions
                ?.flatMap((data) => {
                    return data?.bool;
                })
                .includes(null)
        ) {
            newErrors.AnswerOtherQuestions = 'Please Select Required Option';
        }
    
        setErrors(newErrors);
    
        let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
        // Check if there are any errors
        return ConditionCheck;
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = check_Validity();
        if (errors) return;

        let FormObj = {
            groupInsurance,
                howProvideReport,
                discountPrograms,
                DeMinimusBenefits,
                specificQuestions,
                fringeBenifitCosts,
                disabilityInsurance,
                QualifiedDependentCare,
                totalAnnualOutOfPocket,
                workingConditionsBenefits,
                qualifiedTransportationCosts
		};
        let formData = taxPayerForm;
        taxPayerForm.data.FringeBenefits = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));
        uploadFormData(formData);
    };
    
    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 13;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            {/* <small className="ml-4 fw-bold">Fringe Benefits</small>
            <hr className="m-0" /> */}
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                <div className="col-md-6 mb-3">
                    <Input
                        type="text"
                        name="disabilityInsurance"
                        value={disabilityInsurance}
                        placeholder="Disability insurance"
                        fn={setDisabilityInsurance}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <Input
                        type="text"
                        name="groupInsurance"
                        value={groupInsurance}
                        placeholder="Group term life insurance"
                        fn={setGroupInsurance}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <Input
                        type="text"
                        name="qualifiedTransportationCosts"
                        value={qualifiedTransportationCosts}
                        placeholder="Qualified transportation costs"
                        fn={setQualifiedTransportationCosts}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <Input
                        type="text"
                        name="QualifiedDependentCare"
                        value={QualifiedDependentCare}
                        placeholder="Qualified dependent care"
                        fn={setQualifiedDependentCare}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <Input
                        type="text"
                        name="workingConditionsBenefits"
                        value={workingConditionsBenefits}
                        placeholder="Working conditions benefits"
                        fn={setWorkingConditionsBenefits}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <Input
                        type="text"
                        name="DeMinimusBenefits"
                        value={DeMinimusBenefits}
                        placeholder="De minimus benefits"
                        fn={setDeMinimusBenefits}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <Input
                        type="text"
                        name="fringeBenifitCosts"
                        value={fringeBenifitCosts}
                        placeholder="Other fringe benefits costs"
                        fn={setfringeBenifitCosts}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <Input
                        type="text"
                        name="discountPrograms"
                        value={discountPrograms}
                        placeholder="Qualified employee discount programs"
                        fn={setdiscountPrograms}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="EIDLAmount"
                        value={totalAnnualOutOfPocket}
                        placeholder="What are the total annual out-of-pocket business expenses not previously listed (such as copying, mailing, office supplies, etc)?"
                        fn={setTotalAnnualOutOfPocket}
                        mask={"999999999"}
                    />
                </div>
            </div>
            <div className=" mb-3 px-3">
					{specificQuestions?.map((data, i) => {
						return (
							<div key={i} className="w-100  align-items-center justify-content-between mt-2">
								<small className="col-md-12 form-check-label text-start" htmlFor={`checkbox-${i}`}>
									{data.question}
									{data.bool === null && (
										<span className="text-danger small-text mx-1">*Required</span>
									)}
								</small>
								<div className="col-md-12">
									<input
										onChange={() => handleQuestions(i, true)}
										checked={data?.bool === true}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 176}`}
										name={`checkbox-${i + 176}`}
									/>
									<label htmlFor={`checkbox-${i + 176}`} role="button" className="px-3 m-0 small">
										Yes
									</label>
									<input
										onChange={() => handleQuestions(i, false)}
										checked={data?.bool === false}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 999}`}
										name={`checkbox-${i + 999}`}
									/>
									<label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
										No
									</label>
								</div>
							</div>
						);
					})}
					{JSON.parse(JSON.stringify(multipleChoice))?.map((data, i) => {
						return (
							<div key={i} className="w-100 my-2">
								<small
									className="col-md-6 form-check-label text-start fw-bold mb-1"
									htmlFor={`howProvideReport-${i}`}
								>
									{data.question}
									{!howProvideReport && (
										<span className="text-danger small-text mx-1">*Required</span>
									)}
								</small>
								{data?.options?.map((option, index) => {
									return (
										<div>
											<input
												onChange={() => setHowProvideReport(option)}
												checked={howProvideReport === option}
												className="form-check-input"
												type="radio"
												id={`howProvideReport-${index}`}
												name={`howProvideReport-${index}`}
											/>
											<label className="px-3 small" htmlFor={`howProvideReport-${index}`}>
												{option}
											</label>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
                {Object.keys(errors)
    ?.map((key) => {
        return errors[key];
    })
    ?.map((data, index) => {
        return (
            <>
                <small className="text-primary" key={index}>
                    {data}*
                </small>
                <br />
            </>
        );
    })}
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button  mt-4'}
                        type="button"
                        callback={()=>goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default FringeBenefits;