/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { UsaStates } from 'usa-states';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';
import SingleCheckLabelSelector from '../../fields/SingleCheckLabelSelector';
import { HandleFormData } from '../../../../utilites';

const UsaStatesList = new UsaStates().states?.map((e) => {
	return { label: e.name, value: e.abbreviation };
});

const Address = () => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const dispatch = useDispatch();
	const { addressType, otherLivings } = GLOBAL_VARIABLES;
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 3 });
	const [{ taxPayerFormData, taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);

	const [city, setCity] = useState('');
	const [street, setStreet] = useState('');
	const [zipCode, setZipCode] = useState('');
	const [addresType, setAdresType] = useState(null);
	const [ApartmentNo, setApartmentNo] = useState('');

	// For dropdowns, initialize with null or your initial value.
	const [state, setState] = useState({});

	// For Choice Questions
	const [otherLiving, setotherLiving] = useState([]);
	const [errors, setErrors] = useState({}); // Track errors for form fields

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	useEffect(() => {
		setotherLiving(JSON.parse(JSON.stringify(otherLivings)));
	}, []);

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.addressForm) {
			if (Object.keys(taxPayerForm?.data?.addressForm).length === 0) return;

			const { city, street, state, ApartmentNo, zipCode, addresType, otherLiving } =
				taxPayerForm?.data?.addressForm;
			setCity(city);
			setStreet(street);
			setState(UsaStatesList.find((s) => s.value === state?.value) || null);
			setApartmentNo(ApartmentNo);
			setZipCode(zipCode);
			setAdresType(addresType);
			setotherLiving(otherLiving);
		}
	}, [taxPayerForm?.data?.addressForm]);

	// ---------------------------------------
	// Functions
	// ---------------------------------------

	const handleQuestions = (index, bool) => {
		console.log(index, bool);
		const localotherLiving = otherLiving.slice();
		localotherLiving[index].bool = bool;
		setotherLiving(localotherLiving);
	};

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		// Add validation rules for each field
		if (!addresType) newErrors.addresType = 'Please Select Address Type';
		// Add more validation rules as needed for other fields
		if (
			otherLiving
				?.flatMap((data) => {
					return data?.bool;
				})
				.includes(null)
		) {
			newErrors.otherLiving = 'Please Answer All The Questions There';
		}

		setErrors(newErrors);

		// Check if there are any errors

		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let errors = check_Validity();
		if (errors) return;

		let FormObj = { city, street, state, ApartmentNo, zipCode, addresType, otherLiving };
		let formData = taxPayerForm;
		taxPayerForm.data.addressForm = FormObj;
		alert(JSON.stringify(formData, null, 2))
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
	};

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 3;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};
	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<small className="ml-4 fw-bold">Current Address</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3  custom-left-form">
				<SingleCheckLabelSelector
					heading={` Type of Address`}
					value={addresType}
					setValue={setAdresType}
					mapData={addressType}
				/>
				<div className="col-md-6 mb-3">
					<Input type="text" name="street" value={street} placeholder="Street" fn={setStreet} />
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="ApartmentNo"
						value={ApartmentNo}
						placeholder="Apartment Number"
						fn={setApartmentNo}
						required={false}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input type="text" name="city" value={city} placeholder="City" fn={setCity} />
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="zipCode"
						value={zipCode}
						placeholder="Zip Code"
						fn={setZipCode}
						mask="99999999"
					/>
				</div>
				<div className="col-md-6 mb-3">
					<ReactSelect
						id="state"
						className="p-0 basic-single"
						placeholder="Select state"
						closeMenuOnSelect={true}
						options={UsaStatesList}
						onChange={setState}
						value={state}
					/>
				</div>
			</div>
			<small className="ml-4 fw-bold">TaxPayer-specific Questions</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<div className=" mb-3 px-3">
					<small className="text-dark fw-bold m-0">Please answer the following</small>
					{otherLiving?.map((data, i) => {
						return (
							<div
								key={i}
								className="w-100 d-flex flex-row align-items-center justify-content-between mt-2"
							>
								<small className="col-md-9 form-check-label text-start" htmlFor={`checkbox-${i}`}>
									{data.question}
								</small>
								<div className="col-md-3">
									<input
										onChange={() => handleQuestions(i, true)}
										checked={data?.bool === true}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 174}`}
										name={`checkbox-${i + 174}`}
									/>
									<label htmlFor={`checkbox-${i + 174}`} role="button" className="px-3 m-0 small">
										Yes
									</label>
									<input
										onChange={() => handleQuestions(i, false)}
										checked={data?.bool === false}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 999}`}
										name={`checkbox-${i + 999}`}
									/>
									<label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
										No
									</label>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default Address;
