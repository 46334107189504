import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import allActions from '../../Store/action';
import { BASEURL } from '../../utilites';
import Button from './fields/Button';
import Select from './fields/Select';

export default function EditUserRoleForm({ userId, userRole, reloadUser, setAlert }) {
	const [{ roles }] = useSelector((state) => [state.dashboard]);

	const [role, setRole] = useState();

	const [errors, setErrors] = useState({
		general: '',
	});
	const button = useRef();
	const dispatch = useDispatch();

	useEffect(() => {
		setRole(userRole);
	}, [userRole]);

	useEffect(() => {
		dispatch(allActions.dashboard.getUserRoles());
	}, [dispatch]);

	const handleSubmit = (e) => {
		e.preventDefault();

		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });

		axios
			.put(`${BASEURL}/api/users/update-role`, {
				id: userId,
				role,
			})
			.then(() => {
				button.current.disabled = false;
				reloadUser();
				// Close Modal
				document.querySelector('.userRoleQuickEditModalClose').click();
				setAlert('User role has been updated successfully!');
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};
	return (
		<form className="userform needs-validation" onSubmit={handleSubmit}>
			{roles && role && (
				<div className="row">
					<div className="mb-3">
						<Select value={role} name="userRole" placeholder="User Role" fn={setRole}>
							{roles?.map((r) => (
								<option key={r.id} value={r.id}>
									{r.display_name}
								</option>
							))}
						</Select>
					</div>
				</div>
			)}
			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mb-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 ? ' disabled' : ''
				}`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-3"
				buttonText="Update User Role"
			/>
		</form>
	);
}
