import Modal from "../../Dashboard/Modal";
import { Link } from "react-router-dom";
export default function ConfirmationModal({ label ,handleSubmit}) {
  return (
    <Modal
      title="Confirmation"
      bodyClass="p-3"
      btnText="Add New Payment"
      trigger="ConfirmationModal"
      showBtn={false}
      size={"md"}
      close={false}
      headcenter={true}
      centerClass={'centerClass'}
    >
      <div
        className="w-100 d-flex justify-content-between flex-wrap align-item-start"
      >
        <label
          className="form-check-label text-center w-100 text-muted "
          htmlFor={`checkbox`}
        >
          {label}
        </label>
        <div className="container-fluid content-row  ">
          <div className="d-flex align-items-center justify-content-center row">
            <div className={"col-sm-4 custon-button  mt-3"}>
              <Link
                to="#"
                className="d-block text-decoration-none text-dark ConfirmationModal"
                data-bs-toggle="modal"
                data-bs-target="#ConfirmationModal"
              >
                <button
                  className={
                    "btn btn-info custon-button-text text-white btn-lg w-100"
                  }
                  type={"button"}
                  onClick={() => {}}
                >
                  Cancel
                </button>
              </Link>
            </div>
            <div className={"col-sm-4 custon-button  mt-3"}>
              <button
                className={"btn btn-primary custon-button-text btn-lg w-100"}
                type="button"
                onClick={()=>handleSubmit()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
