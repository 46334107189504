import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import ReactSelect from 'react-select';
import { Document, Page } from 'react-pdf';

import Input from './fields/Input';
import Button from './fields/Button';
import { BASEURL, useAutoReplyHook } from '../../utilites';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../Store/action';

export default function ChooseDocumentForm({
	userId,
	setAlert,
	reloadDocuments,
	documentId,
	targetUserUuid,
}) {
	const [{ LoginData }, { allExistingDocuments, customFolders }, { personalConnections }] =
		useSelector((state) => [state.user, state.dashboard, state.firebase]);
	const [selectedDocument, setSelectedDocument] = useState();
	const [keyword, setKeyword] = useState('');
	const [folder, setFolder] = useState(null);
	const [errors, setErrors] = useState({
		selectedDocument: false,
		general: '',
	});
	const dispatch = useDispatch();
	const handleAutoReply = useAutoReplyHook(dispatch);
	const button = useRef();

	useEffect(() => {
		dispatch(allActions.dashboard.getAllExistingDocuments(1, keyword, folder?.value));
	}, [dispatch, keyword, folder]);

	useEffect(() => {
		dispatch(allActions.dashboard.getCustomFolders());
	}, [dispatch]);

	// function to handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();

		if (selectedDocument) {
			button.current.disabled = true;
			if (errors.general !== '') setErrors({ errors, general: '' });

			// Create a new FormData instance
			const formData = new FormData();
			// Append all properties to FormData
			formData.append('user_id', userId);
			targetUserUuid && formData.append('target_user_uuid', targetUserUuid);
			formData.append('type', 1);
			if (documentId) formData.append('document_id', documentId);
			formData.append('document_path', selectedDocument.path);
			formData.append('document_location', selectedDocument.location);
			try {
				// Make an Axios request to the backend API endpoint for each file individually
				await axios
					.post(`${BASEURL}/api/documents`, formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then(() => {
						handleAutoMessaging();
						button.current.disabled = false;
						// Close Modal
						document.querySelector('.addDocumentModalClose').click();
						// Reload documents
						reloadDocuments();
						setAlert && setAlert('Document has been added successfully!');
					});
			} catch (error) {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			}
			// Reset the form
			setSelectedDocument(null);
		}
	};
	const handleAutoMessaging = () => {
		handleAutoReply({
			personalConnections,
			LoginData,
			receiver: LoginData?.lead[0]?.userpro?.user,
			type: 'simple',
			encodedString: `<p>I just uploaded one of the documents you requested.</p>`,
		});
	};
	return (
		<form className="documentsform needs-validation position-relative" onSubmit={handleSubmit}>
			<div className="row px-3 justify-content-between mb-3">
				{/** Search field based on the keyboard state and filtration field (dropdown) based on folder state */}
				{customFolders && customFolders.length > 0 && (
					<div className="col-md-4">
						<ReactSelect
							id="folders"
							name="folders"
							className="p-0 basic-single"
							placeholder="Select folder"
							options={customFolders?.map((u) => ({
								value: u.id,
								label: u.name,
							}))}
							onChange={setFolder}
							value={folder}
							closeMenuOnSelect
							isClearable
						/>
					</div>
				)}
				<div className="col-md-5">
					<Input
						type="search"
						value={keyword}
						name="name"
						placeholder="Search"
						fn={setKeyword}
						showLabel={false}
						inputClass="search-input border h-auto min-height-initial"
						required={false}
					/>
				</div>
			</div>
			{allExistingDocuments && allExistingDocuments.length > 0 ? (
				<div className="row px-3">
					{allExistingDocuments?.map((document, index) => (
						<div
							className="col-12 col-md-6 col-lg-3"
							key={index}
							onClick={() => {
								if (selectedDocument?.id === document.id) {
									setSelectedDocument(null);
								} else {
									setSelectedDocument(document);
								}
							}}
						>
							<div
								className={`card overflow-hidden mb-4 position-relative${
									document.id === selectedDocument?.id ? ' border-primary' : ''
								}`}
							>
								{document.location.includes('.pdf') ? (
									<Document file={document.path} className="card-img-top">
										<Page pageNumber={1} height={160} />
									</Document>
								) : (
									<img
										src={document.path}
										className="card-img-top"
										alt={document.name}
										style={{ height: 160, objectFit: 'cover' }}
									/>
								)}
								<input
									className="form-check position-absolute"
									type="checkbox"
									style={{ top: 5, left: 10 }}
									onChange={() => {}}
									checked={document.id === selectedDocument?.id}
								/>
								<span
									className="position-absolute w-100 bottom-0 start-0 px-2 py-1 bg-dark text-white text-truncate"
									style={{ fontSize: 10, maxWidth: '100%' }}
								>
									{document.name}
								</span>
							</div>
						</div>
					))}
				</div>
			) : (
				<div className="row px-3">
					<div className="mb-3">
						<div className="alert alert-warning" role="alert">
							No documents found!
						</div>
					</div>
				</div>
			)}
			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mb-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			{allExistingDocuments && allExistingDocuments.length > 0 ? (
				<Button
					buttonRef={button}
					mainClass={`btn btn-primary px-4${
						Object.values(errors).indexOf(true) > -1 || !selectedDocument ? ' disabled' : ''
					}`}
					wrapperClass="position-absolute bottom-17 start-0"
					buttonText={'Select document'}
				/>
			) : null}
		</form>
	);
}
