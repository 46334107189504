/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
// Components
import Modal from '../../../components/Dashboard/Modal';
import ReviewAndAcceptDraftDocumentsForm from '../../../components/forms/ReviewAndAcceptDraftDocumentsForm';
import ReviewProfessionalForm from '../../../components/forms/ReviewProfessionalForm';
import Message from '../../../components/forms/fields/Message';
import Button from '../../../components/forms/fields/Button';
// Store
import allActions from '../../../Store/action';
import { moneyFormatter } from '../../../utilites';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ReviewDraftDocumentsForm from '../../../components/forms/ReviewDraftDocuments';
import { JOBS_ACTION_TYPE } from '../../../Store/jobs/Type';
import JobRequestForm from '../../../components/forms/JobRequestForm';

export default function JobCard({ data, userpro, user, reload }) {

	const [{ invoices, createInvoice, createInvoiceMessage }, { projects }, { LoginData }] =
		useSelector((state) => [state.invoice, state.dashboard, state.user]);
	const [message, setMessage] = useState({ show: false });
	const [invoiceDeadline, setInvoiceDedline] = useState(moment().add(1, 'days').toDate());
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// INVOICES
	const allInvoices = invoices?.data?.filter((d) => Number(d?.job_id) === Number(data?.id));
	let status = allInvoices?.map((data) => {
		let paid_amount = 0;
		data?.payments?.map((i, v) => (paid_amount = paid_amount + parseFloat(i.amount_paid)));
		if (parseInt(data?.amount) - paid_amount <= 0) {
			return true;
		} else {
			return false;
		}
	});

	let paidAmount = 0;
	let remainingAmount = 0;
	invoices?.data?.map((data) =>
		data?.payments?.map((i, v) => (paidAmount = paidAmount + parseFloat(i.amount_paid)))
	);
	invoices?.data?.map((data) => (remainingAmount = remainingAmount + parseFloat(data.amount)));
	let selectedRemaining = 0;
	data?.invoices?.map((data) => (selectedRemaining = selectedRemaining + parseFloat(data.amount)));
	// Conditional rendering variables
	const isDraft = data.status_id === 5 ? true : false;
	const isContractSigned =
		data.confirmed_at && data.status_id !== 0 && data.status_id !== 5 ? true : false;
	const isPartOfProjectPaid =
		isContractSigned && data?.invoices?.length && data?.payments?.length !== 0 ? true : false;
	const isProjectAlreadyHasInvoice = isContractSigned && data?.invoices?.length > 0 ? true : false;
	const isInvoicePaid = isPartOfProjectPaid && status?.indexOf(false) === -1 ? true : false;
	const areFinalDocumentsUploaded = isInvoicePaid && data.drafts?.length > 0 ? true : false;
	const areFinalDocumentsApproved =
		areFinalDocumentsUploaded && data.status_id === 6 ? true : false;
	const isProjectApproved = areFinalDocumentsApproved && data.status_id === 6 ? true : false;
	const allInvoiceNotPaid = data?.cost - paidAmount !== 0;
	const anyInvoicePaid = paidAmount > 0;

	useEffect(() => {
		dispatch(allActions.dashboard.getProjects());
	}, [dispatch]);

	// ******************************
	// HOOKS FOR GETTING INVOICES
	// ******************************
	useEffect(() => {
		// FALILURE
		if (!invoices?.status && invoices?.message !== 'No customer found') {
			setMessage({
				show: true,
				type: 'Error',
				text: invoices?.message,
			});
			dispatch(allActions.global.Loader(false));
		}
		// SUCCESS
		if (invoices?.status === true) {
			dispatch(allActions.global.Loader(false));
		}
		// IDLE
		if (invoices?.status === 'idle' && projects?.data?.[0]?.leads) {
			dispatch(allActions.invoice.getLeadInvoices(projects?.data?.[0]?.leads?.uuid));
		}
	}, [invoices?.message, invoices?.status]);

	// ******************************
	// HOOKS FOR CREATE INVOICES
	// ******************************

	useEffect(() => {
		if (!createInvoice) {
			document.querySelector('.requestbalancepaymentInfoModalClose').click();
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: createInvoiceMessage,
			});
		}

		if (createInvoice === true) {
			invoiceGenerated();
		}
	}, [createInvoice, createInvoiceMessage]);

	// Call After invoice will generated
	const invoiceGenerated = () => {
		setMessage({
			show: false,
		});
		document.querySelector('.requestbalancepaymentInfoModalClose').click();
		dispatch(allActions.invoice.resetCreateInvoice());
		dispatch(allActions.invoice.resetPayInvoice());
		dispatch(allActions.global.Loader(false));
		if (createInvoiceMessage?.data?.stripe_invoice_id) {
			setTimeout(() => {
				navigate(`/dashboard/invoices/${createInvoiceMessage?.data?.stripe_invoice_id}`, {
					state: { navigate: 'projects', message: 'Invoice Generated Successfully!' },
				});
			}, 500);
		}
	};

	// To Generate Ramming Balance
	const generateRemaningDueInvoice = (e) => {
		e.preventDefault();

		let invoiceObj = {
			user_id: LoginData?.id,
			dueDate: moment(new Date(invoiceDeadline), 'YYYY-MM-DD').format('MM/DD/YYYY'),
			name: data?.service?.name?data?.service?.name:"Others",
			amount: data?.invoice_data?.remainingAmount,
			job_id: data?.id,
			pro_id: LoginData?.lead?.[0]?.pro_id,
		};
		dispatch(allActions.global.Loader(true));
		dispatch(allActions.invoice.createInvoice(invoiceObj, LoginData?.leads?.uuid));
	};

	return (
		<div className="clearfix">
			{message.show ? <Message message={message} /> : <></>}

			<ul className="JobCard list-group">
				<li
					className={`list-group-item border-0${
						isContractSigned ? ' JobCard__item--completed' : ''
					}`}
				>
					<h3 className="fs-6 fw-bold">Get Started</h3>
					{!isContractSigned && !isDraft ? (
						<Button
							callback={() => {
								navigate(`/dashboard/contract/${data.uuid}`, {
									state: { projects },
								});
							}}
							mainClass={`btn btn-primary btn-sm text-white w-100 ${
								data.status_id === 4 ? 'disabled' : 'enabled'
							}`}
							wrapperClass={'col-md-3 custon-button  my-2'}
							buttonText="Sign contract"
						/>
					) : isDraft ? (
						<small>
							This is a draft contract. Please see the documents tab to upload the Requested
							Documents for your Tax Pro to get started.
						</small>
					) : (
						<small
							onClick={() => {
								navigate(`/dashboard/contract/${data.uuid}`, {
									state: { projects },
								});
							}}
						>
							<Link to={`#`}>Contract</Link> was signed on{' '}
							{moment(data.confirmed_at).format('Y-m-d')}.
						</small>
					)}
				</li>
				<li
					className={`list-group-item border-0${
						!isContractSigned ? ' JobCard__item--disabled' : ''
					}${
						anyInvoicePaid &&
						isPartOfProjectPaid &&
						(data.invoice_type === 'full' ? isInvoicePaid : true)
							? ' JobCard__item--completed'
							: ''
					}`}
				>
					<h3 className="fs-6 fw-bold">Pay Deposit</h3>
					{isContractSigned && !anyInvoicePaid ? (
						<>
							{paidAmount > 0 ? (
								<p>{moneyFormatter(paidAmount)}</p>
							) : !isProjectAlreadyHasInvoice ? (
								<Button
									callback={() => {
										navigate(`/dashboard/contract/${data.uuid}`, {
											state: { projects },
										});
									}}
									mainClass="btn btn-primary btn-sm text-white w-100"
									wrapperClass={'col-md-3 custon-button  my-2'}
									buttonText="Click to pay"
								/>
							) : (
								<Button
									callback={() => {
										dispatch(allActions.invoice.resetPayInvoice());
										navigate(
											`/dashboard/invoices/${
												allInvoices?.[status?.indexOf(false)]?.stripe_invoice_id
											}`,
											{ state: { navigate: 'projects' } }
										);
									}}
									mainClass="btn btn-primary btn-sm text-white w-100"
									wrapperClass={'col-md-3 custon-button  my-2'}
									buttonText="Click to pay"
								/>
							)}
						</>
					) : (
						<small>{`${moneyFormatter(paidAmount)} amount was paid`}.</small>
					)}
				</li>
				<li
					className={`list-group-item border-0${
						!anyInvoicePaid || !isPartOfProjectPaid ? ' JobCard__item--disabled' : ''
					}${anyInvoicePaid && isPartOfProjectPaid ? ' JobCard__item--completed' : ''}`}
				>
					<h3 className="fs-6 fw-bold">Start Project</h3>
					{isInvoicePaid && isPartOfProjectPaid ? (
						<small>The project has started</small>
					) : (
						<small>The project has not start yet.</small>
					)}
				</li>
				{data.invoice_type === 'partial' ? (
					<li
						className={`list-group-item border-0${
							!anyInvoicePaid ? ' JobCard__item--disabled' : ''
						}${anyInvoicePaid && !allInvoiceNotPaid ? ' JobCard__item--completed' : ''}`}
					>
						<h3 className="fs-6 fw-bold">Pay Balance</h3>

						{!allInvoiceNotPaid && anyInvoicePaid ? (
							<small>Balance Paid</small>
						) : isInvoicePaid && anyInvoicePaid ? (
							<>
								<button
									className={`btn btn-primary btn-sm px-3 ${
										data.status_id === 4 ? 'disabled' : 'enabled'
									}`}
									data-bs-toggle="modal"
									data-bs-target="#requestbalancepayment"
									onClick={() => {
										setMessage({ show: false });
										dispatch(allActions.invoice.resetCreateInvoice());
										dispatch(allActions.invoice.resetPayInvoice());
									}}
								>
									Pay Balance
								</button>
							</>
						) : !anyInvoicePaid ? (
							<small>Milestone for payment</small>
						) : (
							<Button
								callback={() => {
									dispatch(allActions.invoice.resetPayInvoice());
									navigate(
										`/dashboard/invoices/${
											allInvoices?.[status?.indexOf(false)]?.stripe_invoice_id
										}`,
										{ state: { navigate: 'projects' } }
									);
								}}
								mainClass="btn btn-primary btn-sm text-white w-100"
								wrapperClass={'col-md-3 custon-button  my-2'}
								buttonText="Click to pay"
							/>
						)}
					</li>
				) : null}
				<li
					className={`list-group-item border-0${
						!areFinalDocumentsUploaded && allInvoiceNotPaid ? ' JobCard__item--disabled' : ''
					}${areFinalDocumentsApproved && !allInvoiceNotPaid ? ' JobCard__item--completed' : ''}
					${isDraft ? ' JobCard__item--disabled' : ''}`}
				>
					<h3 className="fs-6 fw-bold">Approve and accept final deliverables</h3>
					{areFinalDocumentsUploaded && isInvoicePaid ? (
						<>
							<p className=" small">
								Please review the document{data?.drafts?.length > 1 ? 's' : ''} below to approve the
								final payment.
							</p>

							<ReviewDraftDocumentsForm drafts={data.drafts} />

							{!areFinalDocumentsApproved ? (
								<Link
									to="#"
									data-bs-toggle="modal"
									className={`btn btn-primary ${data.status_id === 4 ? 'disabled' : 'enabled'}`}
									data-bs-target="#ReviewAndAcceptDraftDocumentsModal"
								>
									Approve Job Completion
								</Link>
							) : (
								<small>Final deliverables approved on {moment(data.approved_at).fromNow()}</small>
							)}

							<Link
								data-bs-toggle="modal"
								data-bs-target="#jobCanelRequestForm1"
								className="btn btn-outline-primary me-3 mx-2"
								onClick={() => {
									setMessage({ show: false });
									dispatch({ type: JOBS_ACTION_TYPE.cancelJobRequestIdle });
								}}
							>
								Request Modification
							</Link>
						</>
					) : !areFinalDocumentsApproved && anyInvoicePaid ? (
						<small>Tax Pro has not uploaded any document yet.</small>
					) : (
						<small>
							When your tax professional complete your job, this is where you will see it.
						</small>
					)}
				</li>
				<li
					className={`list-group-item border-0${
						!areFinalDocumentsApproved ? ' JobCard__item--disabled' : ''
					}${isProjectApproved ? ' JobCard__item--completed' : ''}`}
				>
					<h3 className="fs-6 fw-bold">Review professional</h3>
					{data.ratings && data.ratings.length > 0 ? (
						<Link
							data-bs-toggle="modal"
							className="btn btn-primary btn-sm text-white"
							data-bs-target="#ReviewProfessionalModal"
						>
							View Review
						</Link>
					) : isProjectApproved ? (
						<Link
							data-bs-toggle="modal"
							className="btn btn-primary btn-sm text-white"
							data-bs-target="#ReviewProfessionalModal"
						>
							Submit Review
						</Link>
					) : data.status_id === 4 ? (
						<span className="text-danger">This job has been canceled</span>
					) : (
						<small>Once job is approved, you should be able to review your tax professional.</small>
					)}
				</li>
			</ul>
			<Modal
				title="Approve project completion"
				bodyClass="p-4 text-center text-dark text-opacity-50"
				trigger="ReviewAndAcceptDraftDocumentsModal"
				showBtn={false}
				btnClass="ReviewAndAcceptDraftDocumentsModalClose"
				size="lg"
			>
				<ReviewAndAcceptDraftDocumentsForm
					drafts={data.drafts}
					uuid={data.uuid}
					reload={reload}
					setMessage={setMessage}
				/>
			</Modal>
			<Modal
				title="Request Modification"
				bodyClass="p-4"
				trigger="jobCanelRequestForm"
				showBtn={false}
				btnClass="jobCanelRequestFormClose"
			>
				<div className="clearfix p-2"></div>
			</Modal>
			<Modal
				title="Request approved! Rate your provider."
				bodyClass="p-4 text-center text-dark text-opacity-50"
				trigger="ReviewProfessionalModal"
				showBtn={false}
				btnClass="ReviewProfessionalModalClose"
				size="lg"
			>
				<ReviewProfessionalForm
					proData={userpro}
					customerData={user}
					job_id={data.id}
					reload={reload}
					setMessage={setMessage}
				/>
			</Modal>

			<Modal
				title="Pay Job Balance"
				bodyClass="p-4"
				btnText="Pay Balance"
				trigger="requestbalancepayment"
				showBtn={false}
				btnClass="requestbalancepaymentInfoModalClose"
			>
				<div className="clearfix p-2">
					<form
						className="clearfix w-100"
						onSubmit={(e) => {
							generateRemaningDueInvoice(e);
						}}
					>
						<div className="text-dark w-100 text-align-left my-1">Deadline to Pay</div>
						<div className="mb-2">
							<DatePicker
								className="form-control"
								wrapperClassName="d-block"
								selected={invoiceDeadline || moment().add(1, 'days').toDate()}
								onChange={(date) => setInvoiceDedline(date)}
								dateFormat="MM/dd/yyyy"
								placeholderText="mm/dd/yyyy"
								minDate={new Date()}
								required
								disabled
							/>
						</div>
						<p className="text-dark w-100 text-align-left my-1">
							Balance:{' '}
							<span className="ms-2">{moneyFormatter(data?.cost - selectedRemaining)}</span>
						</p>
						<Button
							mainClass="btn btn-primary text-white w-100"
							wrapperClass={'w-75 custon-button mx-auto mt-4'}
							buttonText={'Pay Balance'}
						></Button>
					</form>
				</div>
			</Modal>

			<Modal
					title="Job Modification Request"
					bodyClass="p-4 text-center text-dark text-opacity-50"
					trigger="jobCanelRequestForm1"
					showBtn={false}
					btnClass="jobCanelRequestModalClose"
				>
					<JobRequestForm
						type={3}
						triggerClass=".jobCanelRequestModalClose"
						uuid={data?.uuid}
						setMessage={setMessage}
						receiver={data?.userpro?.user}
						sender={LoginData}
						selectable={false}
					/>
				</Modal>
		</div>
	);
}
