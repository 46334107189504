import { AiOutlinePlusCircle, AiOutlineCloudUpload, AiOutlineFolderOpen } from 'react-icons/ai';

const Footer = () => {
	const Options = [
		{ label: 'New file', icon: <AiOutlineCloudUpload /> },
		{ label: 'Existing file', icon: <AiOutlineFolderOpen /> },
	];
	return (
		<div className="w-100 d-flex flex-row  justify-content-between align-items-center header-footer">
			<div>
				<p className=" mt-3">Upload Documents</p>
			</div>
			<div class="dropdown">
				<button
					class="btn btn-light border-0 bg-transparent"
					type="button"
					id="dropdownMenuButton1"
					// data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					<AiOutlinePlusCircle size={17} />
				</button>
				<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
					{Options?.map((data) => {
						return (
							<li
								role="button"
								className="d-flex flex-row align-items-center justify-content-start px-2 py-2"
							>
								{data?.icon}
								<small className="mx-2">{data.label}</small>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default Footer;
