import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { useParams } from 'react-router-dom';

const QRCodePage = () => {
    const { uuid } = useParams();
    const uploadPageURL = `${window.location.origin}/qr-upload/${uuid}`;

    return (
        <div className='d-flex flex-column py-5 justify-content-center align-items-center'>
            <h1>Scan this QR code to upload your documents</h1>
            <div className='my-5'>
                <a href={uploadPageURL}>
                    <QRCodeCanvas value={uploadPageURL} size={256} />
                </a>
            </div>
        </div>
    );
};

export default QRCodePage;
