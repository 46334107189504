import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import Input from './fields/Input';
import Button from './fields/Button';
import Message from './fields/Message';
import { useDispatch } from 'react-redux';
import allActions from '../../Store/action';
import { RECAPTCHA_SITE_KEY } from '../../utilites';

export default function ForgotPasswordForm({ buttonName, checkEmail }) {
	let dispatch = useDispatch();
	const reCaptchaRef = useRef();
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState({ show: false });
	const button = document.querySelector('form.forgotpassword button.btn');
	const reset_mesage = () => {
		setMessage({ show: false });
	};
	const resetForm = () => {
		setEmail('');
	};
	const change_spinner = (spin) => {
		if (spin) {
			button.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...`;
		} else {
			button.innerHTML = `Forgot Password`;
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const recaptcha_token = await reCaptchaRef.current.executeAsync();
		let user_obj = {
			recaptcha_token,
			email,
		};
		if (buttonName === 'signup') {
			reset_mesage();
			checkEmail(email, setMessage, resetForm, change_spinner);
		} else {
			// adding a bootstrap spinner uppon form submission
			button.innerHTML = `<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...`;
			// redirect after 3 seconds for now
			change_spinner(true);
			dispatch(allActions.user.ForgotPassword(user_obj, setMessage, resetForm, change_spinner));
			// Reset reCaptcha
			reCaptchaRef.current.reset();
		}
	};

	return (
		<div>
			<form className='forgotpassword needs-validation' onSubmit={handleSubmit}>
				<div className='mb-3'>
					{message.show ? <Message message={message} /> : <></>}
					<Input
						type='email'
						value={email}
						name='email'
						placeholder='Email Address'
						showLabel={false}
						fn={setEmail}
					/>
				</div>
				<ReCAPTCHA
					className='d-none'
					sitekey={RECAPTCHA_SITE_KEY}
					size='invisible'
					ref={reCaptchaRef}
				/>
				<Button
					mainClass='btn btn-primary btn-lg px-4'
					wrapperClass='clearfix d-grid text-center mt-3'
					buttonText={buttonName ? buttonName : 'Forgot Password'}
				/>
				<div className='text-center mt-3'>
					<Link to='/login' className='d-inline-block px-2 text-decoration-underline'>
						Return to sign in
					</Link>
				</div>
			</form>
		</div>
	);
}
