/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';

import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';


const FuturePartnerShareholder = () => {
    const { FuturePartnerShareholderAnswers } = GLOBAL_VARIABLES

    const dispatch=useDispatch()
	const [{ taxPayerForm, taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 21 });

    const [businessIncomeStates, setBusinessIncomeStates]= useState([]);

    const handletwentyTwentyChange = (id, index) => {
        const myInputAmount = document.getElementById(id);
        let localDeduction = businessIncomeStates;
        localDeduction[index].twentyTwentyNine = myInputAmount.value;
        setBusinessIncomeStates(JSON.parse(JSON.stringify(localDeduction)));
    };
    const handletwentyTwentyOneChange = (id, index) => {
        const myInputAmount = document.getElementById(id);
        let localDeduction = businessIncomeStates;
        localDeduction[index].twentyTwenty = myInputAmount.value;
        setBusinessIncomeStates(JSON.parse(JSON.stringify(localDeduction)));
    };
    const handletwentyTwentyTwoChange = (id, index) => {
        const myInputAmount = document.getElementById(id);
        let localDeduction = businessIncomeStates;
        localDeduction[index].twentyTwentyOne = myInputAmount.value;
        setBusinessIncomeStates(JSON.parse(JSON.stringify(localDeduction)));
    };

    useEffect(() => {
        setBusinessIncomeStates(JSON.parse(JSON.stringify(FuturePartnerShareholderAnswers)));
    }, []);

    useEffect(() => {
    	if (taxPayerForm?.data?.FuturePartnerShareholder) {
    		if (Object.keys(taxPayerForm?.data?.FuturePartnerShareholder).length === 0) return;
            const {
                businessIncomeStates
            } = taxPayerForm?.data?.FuturePartnerShareholder;
            setBusinessIncomeStates(businessIncomeStates)
    	}
    }, [taxPayerForm?.data?.FuturePartnerShareholder]);


    const handleSubmit = (e) => {
        e.preventDefault();
        // let FormObj = {
            let FormObj = {
                businessIncomeStates
            };
            let formData = taxPayerForm;
            taxPayerForm.data.FuturePartnerShareholder = FormObj;
            dispatch(allActions.taxForms.StoreFormData({ formData }));
            uploadFormData(formData);
    };

    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 21;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            <small className="ml-4 fw-bold">Business Expenses</small>
            <hr className="m-0" />
            <div className="mt-3 row justify-content-between  custom-left-form">
                <div className="w-100 d-flex flex-row flex-wrap justify-content-between align-items-end mb-3  mt-3">
                    {businessIncomeStates?.map((data, i) => {
                        return (
                            <div
                                onClick={() => { }}
                                key={i}
                                className="col-md-12 px-2 my-2"
                            >
                                <small className="text-start fw-bold col-md-12">{data?.question}</small>
                                <div className="col-md-12 row">
                                    <div className="col-md-4">
                                        <Input
                                            type="text"
                                            value={data?.twentyTwentyNine}
                                            name="Amount"
                                            placeholder="2020"
                                            formid={`twenty${i}`}
                                            fn={() => {
                                                handletwentyTwentyChange(`twenty${i}`, i);
                                            }}
                                            mask={"999999999"}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input
                                            type="text"
                                            name="description"
                                            placeholder='2021'
                                            formid={`twentyone${i}`}
                                            value={data?.twentyTwenty}
                                            fn={() => {
                                                handletwentyTwentyOneChange(`twentyone${i}`, i);
                                            }}
                                            mask={"999999999"}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input
                                            type="text"
                                            name="description"
                                            placeholder='2022'
                                            formid={`twentytwo${i}`}
                                            value={data?.twentyTwentyOne}
                                            fn={() => {
                                                handletwentyTwentyTwoChange(`twentytwo${i}`, i);
                                            }}
                                            mask={"999999999"}

                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text  w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button  mt-4'}
                        type="button"
                        callback={()=>goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text   w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button  mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default FuturePartnerShareholder;
