import { Link } from 'react-router-dom';

const Announcement = ({
	condition = true, // if condition is false, then the announcement will not be shown
	title = 'Announcement', // title of the announcement
	details = 'This is an announcement', // details of the announcement
	buttonLink = '/dashboard/settings', // link of the button
	alertClass = 'clearfix', // alert class
	mainClass = 'alert-success', // main class
	buttonText = 'Click here', // text of the button
	buttonEnabled = true, // if button is enabled or not
	buttonClass = 'btn btn-dark', // button class
	clickHandler, // onClick event handler function
	containerClass = 'clearfix', // container class
}) => {
	return (
		<>
			{condition && (
				<div className={mainClass}>
					<div className={`alert alert-${alertClass} mb-0`}>
						<div className={containerClass}>
							<div className="row align-middle">
								<div className={`col-md-${buttonEnabled ? '10' : '12'}`}>
									<p className="mb-0"><span className="fw-bold mb-0">{title}</span> {details}</p>
								</div>
								{buttonEnabled && (
									<div className="col-md-2 text-end">
										<Link
											to={buttonLink}
											onClick={clickHandler}
											className={buttonClass}
										>
											{buttonText}
										</Link>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Announcement;
