/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { UsaStates } from 'usa-states';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';
import SingleCheckLabelSelector from '../../fields/SingleCheckLabelSelector';
import { HandleFormData } from '../../../../utilites';

const UsaStatesList = new UsaStates().states?.map((e) => {
	return { label: e.name, value: e.abbreviation };
});

const BusinessAddress = () => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const dispatch = useDispatch();
	const { addressType } = GLOBAL_VARIABLES;
	const [{ taxPayerFormData, taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);
	
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 1 });

	const [city, setCity] = useState('');
	const [street, setStreet] = useState('');
	const [zipCode, setZipCode] = useState('');
	const [addresType, setAddressType] = useState(null);
	const [SuiteNumber, setSuitNumber] = useState('');

	// For dropdowns, initialize with null or your initial value.
	const [state, setState] = useState({});

	// For Choice Questions
	const [errors, setErrors] = useState({}); // Track errors for form fields

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.BusinessAddressForm) {
			if (Object.keys(taxPayerForm?.data?.BusinessAddressForm).length === 0) return;

			const { city, street, state, SuiteNumber, zipCode, addresType } =
				taxPayerForm?.data?.BusinessAddressForm;
			setCity(city);
			setStreet(street);
			setState(UsaStatesList.find((s) => s.value === state?.value) || null);
			setSuitNumber(SuiteNumber);
			setZipCode(zipCode);
			setAddressType(addresType);
		}
	}, [taxPayerForm?.data?.BusinessAddressForm]);

	// ---------------------------------------
	// Functions
	// ---------------------------------------
	console.log('addresType', addresType);
	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		// Add validation rules for each field
		if (!addresType) newErrors.addresType = 'Please Select Address Type';
		// Add more validation rules as needed for other fields

		setErrors(newErrors);

		// Check if there are any errors

		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let errors = check_Validity();
		if (errors) return;

		let FormObj = { city, street, state, SuiteNumber, zipCode, addresType };

		let formData = taxPayerForm;
		taxPayerForm.data.BusinessAddressForm = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
	};

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 1;
		dispatch(
			allActions.taxForms.moveToBack({
				parentIndex,
				myIndex,
				taxPayerFormData,
			})
		);
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<small className="ml-4 fw-bold">Business Address</small>

			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<SingleCheckLabelSelector
					heading={` Type of Address`}
					value={addresType}
					setValue={setAddressType}
					mapData={addressType}
				/>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="street"
						value={street}
						placeholder="Street address"
						fn={setStreet}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="SuiteNumber"
						value={SuiteNumber}
						placeholder="Suit Number"
						fn={setSuitNumber}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input type="text" name="city" value={city} placeholder="City" fn={setCity} />
				</div>
				<div className="col-md-6 mb-3">
					<ReactSelect
						id="state"
						className="p-0 basic-single"
						placeholder="Select state"
						closeMenuOnSelect={true}
						options={UsaStatesList}
						onChange={setState}
						value={state}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="zipCode"
						value={zipCode}
						placeholder="Zip Code"
						fn={setZipCode}
						mask="99999999"
					/>
				</div>
			</div>
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default BusinessAddress;
