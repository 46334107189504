import { FIREBASE_ACTION_TYPE } from './Type.js';

const DEFAULT_STATE = {
	myUuid: '',
	LoginDataUuid: '',
	readChats: [],
	writeMessage: '',
	saveDocId: 'idle',
	topicUserList: [],
	readStatus: 'idle',
	writeStatus: 'idle',
	readChatsMessage: '',
	writeReplyStatus: 'idle',
	writeReplyMessage: '',
	readPersonalChats: [],
	readChatsStatus: 'idle',
	topicUserListStatus: 'idle',
	topicUserListMessage: '',
	writePersonalStatus: 'idle',
	writePersonalMessage: '',
	writePersonalReplyStatus: 'idle',
	writePersonalReplyMessage: '',
	readPersonalChatsStatus: 'idle',
	readPersonalChatsMessage: '',
	adminRole: { loaded: 'idle', data: [], message: '' },
	myConnection: { loaded: 'idle', data: [], message: '' },
	personalConnections: { loaded: 'idle', data: [], message: '', notification: 0, myId: '' },
	personalGroupConnections: { loaded: 'idle', data: [], message: '', notification: 0, myId: '' },
	chatStatus: { loaded: 'idle', data: [], message: '' },
	// Notifications
	notificationsCount: 0,
	recentNotifications: { data: [], fetching: false, loaded: false },
	notifications: { data: [], fetching: false, loaded: false },
	//storage
	writeStorageStatus: 'idle',
	storageLinks: [],
};

const INITIAL_STATE = { ...DEFAULT_STATE };

const firebase = (state = INITIAL_STATE, action) => {
	if (action.type === FIREBASE_ACTION_TYPE.WRITE) {
		return {
			...state,
			writeStatus: action.status,
			writeMessage: action.message,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.myUuid) {
		return {
			...state,
			myUuid: action.uuid,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.LoginDataUuid) {
		return {
			...state,
			LoginDataUuid: action.uuid,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.WriteIdle) {
		return {
			...state,
			writeStatus: 'idle',
			writeMessage: '',
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.writeReply) {
		return {
			...state,
			writeReplyStatus: action.status,
			writeReplyMessage: action.message,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.writeReplyIdle) {
		return {
			...state,
			writeReplyStatus: 'idle',
			writeReplyMessage: '',
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.READ) {
		return {
			...state,
			readChats: action.data,
			readChatsStatus: action.status,
			readChatsMessage: action.message,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.readIdle) {
		return {
			...state,
			readChatsStatus: 'idle',
			readChatsMessage: '',
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.readIdleArray) {
		return {
			...state,
			readChats: [],
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.topicUserList) {
		return {
			...state,
			topicUserList: action.data,
			topicUserListStatus: action.status,
			topicUserListMessage: action.message,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.topicUserListIdle) {
		return {
			...state,
			topicUserListStatus: 'idle',
			topicUserListMessage: '',
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.GET_NOTIFICATIONS_COUNT) {
		return {
			...state,
			notificationsCount: action.payload,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.RECENT_NOTIFICATIONS_FETCHING_START) {
		return {
			...state,
			recentNotifications: { ...state.recentNotifications, fetching: true },
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.GET_RECENT_NOTIFICATIONS) {
		return {
			...state,
			recentNotifications: {
				data: action.payload,
				fetching: false,
				loaded: true,
			},
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.NOTIFICATIONS_FETCHING_START) {
		return {
			...state,
			notifications: { ...state.notifications, fetching: true },
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.GET_NOTIFICATIONS) {
		const allNotifications = [...state.notifications.data, ...action.payload];
		const uniqueNotifications = [];

		allNotifications.forEach((notification) => {
			const isUnique = !uniqueNotifications.some((item) => item.id === notification.id);

			if (isUnique) {
				uniqueNotifications.push(notification);
			}
		});

		uniqueNotifications.sort(function (a, b) {
			return b.createdAt - a.createdAt;
		});

		return {
			...state,
			notifications: {
				data: uniqueNotifications,
				fetching: false,
				loaded: true,
			},
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.admin) {
		return {
			...state,
			adminRole: action.payload,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.myConnection) {
		return {
			...state,
			myConnection: action.payload,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.myConnectionIdle) {
		return {
			...state,
			myConnection: action.payload,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.WRITEPERSONAL) {
		return {
			...state,
			writePersonalStatus: action.status,
			writePersonalMessage: action.message,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.WritePersonalIdle) {
		return {
			...state,
			writePersonalStatus: 'idle',
			writePersonalMessage: '',
		};
	}
	if (action.type === FIREBASE_ACTION_TYPE.writeReplyPersonal) {
		return {
			...state,
			writePersonalReplyStatus: action.status,
			writePersonalReplyMessage: action.message,
			writeStorageStatus: action.status ? 'idle' : state.writeStorageStatus,
			storageLinks: action.status ? [] : state.storageLinks,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.writeReplyPersonalIdle) {
		return {
			...state,
			writePersonalReplyStatus: 'idle',
			writePersonalReplyMessage: '',
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.READPERSONAL) {
		return {
			...state,
			// Check if Old Doc id is Saved Don't Update Old Chat with updated One
			readPersonalChats: state.saveDocId === action.id ? action.data : state.readPersonalChats,
			readPersonalChatsStatus: action.status,
			readPersonalChatsMessage: action.message,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.readPersonalIdle) {
		return {
			...state,
			readPersonalChats: [],
			readPersonalChatsStatus: 'idle',
			readPersonalChatsMessage: '',
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.personalConnections) {
		return {
			...state,
			personalConnections:
				state.myUuid === action.payload.myId || state.LoginDataUuid === action.payload.myId
					? action.payload
					: state.personalConnections,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.personalGroupConnections) {
		return {
			...state,
			personalGroupConnections:
				state.myUuid === action.payload.myId || state.LoginDataUuid === action.payload.myId
					? action.payload
					: state.personalGroupConnections,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.personalConnectionsIdle) {
		return {
			...state,
			personalConnections: { loaded: 'idle', data: [], message: '' },
			personalGroupConnections: { loaded: 'idle', data: [], message: '' },
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.saveDocId) {
		return {
			...state,
			saveDocId: action.payload,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.chatStatus) {
		return {
			...state,
			chatStatus: action.payload,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.writeStorageSuccess) {
		console.log('ddd', action.payload);
		return {
			...state,
			writeStorageStatus: action.status,
			storageLinks: state.storageLinks.concat(action.payload),
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.writeStorageFail) {
		return {
			...state,
			writeStorageStatus: action.status,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.storageLinks) {
		return {
			...state,
			storageLinks: action.payload,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.writeStorageIdle) {
		return {
			...state,
			writeStorageStatus: action.status,
		};
	} else if (action.type === FIREBASE_ACTION_TYPE.RESET) {
		return { ...state, ...DEFAULT_STATE };
	}
	return state;
};
export default firebase;
