/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';

import TextArea from '../../fields/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';

const Payroll = () => {
    
    const dispatch=useDispatch()
	const [{ taxPayerForm,taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 11 });

    // for Current component States
    const [writtenPolicy, setWrittenPolicy] = useState();
    const [totalEmpoyees, setTotalEmployees] = useState();
    const [totalPayrollCost, setTotalPayRollCost] = useState();
    const [wagesInQ1Twenty, setWagesInQ1Twenty] = useState();
    const [expectedTotalWages, setExpectedTotalWages] = useState();
    const [wagesInQ2TwentyOne, setWagesInQ2TwentyOne] = useState();
    const [wagesInQ3TwentyOne, setWagesInQ3TwentyOne] = useState();
    const [wagesInQ4TwentyOne, setWagesInQ4TwentyOne] = useState();

    useEffect(() => {
    	if (taxPayerForm?.data?.Payroll) {
    		if (Object.keys(taxPayerForm?.data?.Payroll).length === 0) return;
			const {
                writtenPolicy,
                totalEmpoyees,
                totalPayrollCost,
                wagesInQ1Twenty,
                expectedTotalWages,
                wagesInQ2TwentyOne,
                wagesInQ3TwentyOne,
                wagesInQ4TwentyOne
			} = taxPayerForm?.data?.Payroll;
			setWrittenPolicy(writtenPolicy)
            setTotalEmployees(totalEmpoyees)
            setTotalPayRollCost(totalPayrollCost)
            setWagesInQ1Twenty(wagesInQ1Twenty)
            setExpectedTotalWages(expectedTotalWages)
            setWagesInQ2TwentyOne(wagesInQ2TwentyOne)
            setWagesInQ3TwentyOne(wagesInQ3TwentyOne)
            setWagesInQ4TwentyOne(wagesInQ4TwentyOne)
    	}
    }, [taxPayerForm?.data?.Payroll]);

    const handleSubmit = (e) => {
        e.preventDefault();
        let FormObj = {
            writtenPolicy,
            totalEmpoyees,
            totalPayrollCost,
            wagesInQ1Twenty,
            expectedTotalWages,
            wagesInQ2TwentyOne,
            wagesInQ3TwentyOne,
            wagesInQ4TwentyOne
		};
        let formData = taxPayerForm;
        taxPayerForm.data.Payroll = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));
        uploadFormData(formData);
    };

    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 11;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            {/* <small className="ml-4 fw-bold">Payroll</small>
            <hr className="m-0" /> */}
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="expectedTotalWages"
                        value={expectedTotalWages}
                        placeholder="What are the expected total wages to be paid to your employees in 2021, not including yourself or your spouse (if your spouse owns a percentage of the business)?"
                        fn={setExpectedTotalWages}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <Input
                        type="text"
                        name="wagesInQ1Twenty"
                        value={wagesInQ1Twenty}
                        placeholder="Total wages paid in Q1 2020"
                        fn={setWagesInQ1Twenty}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <Input
                        type="text"
                        name="wagesInQ2TwentyOne"
                        value={wagesInQ2TwentyOne}
                        placeholder="Total wages paid in Q2 2021"
                        fn={setWagesInQ2TwentyOne}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <Input
                        type="text"
                        name="wagesInQ3TwentyOne"
                        value={wagesInQ3TwentyOne}
                        placeholder="Total wages paid in Q3 2021"
                        fn={setWagesInQ3TwentyOne}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <Input
                        type="text"
                        name="wagesInQ4TwentyOne"
                        value={wagesInQ4TwentyOne}
                        placeholder="Total wages expected to be paid in Q4 2021"
                        fn={setWagesInQ4TwentyOne}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="totalPayrollCost"
                        value={totalPayrollCost}
                        placeholder="What is the total payroll costs of all employees projected to make less than $10,000 in 2021?"
                        fn={setTotalPayRollCost}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="totalEmpoyees"
                        value={totalEmpoyees}
                        placeholder="How many total employees are projected to make over $10,000 in 2021?"
                        fn={setTotalEmployees}
                        mask={"999999999"}
                    />
                </div>
                <div className="col-md-12 mb-3">
					<TextArea
						inputName="form-control"
						defaultValue={writtenPolicy}
						placeholder="Did the business provide paid medical and family leave to employees pursuant to a written policy?"
						name="writtenPolicy"
						fn={setWrittenPolicy}
						row="6"
					/>
				</div>
            </div>
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button  mt-4'}
                        type="button"
                        callback={()=>goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default Payroll;