import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getNotificationContentByActionType } from '../../utilites';
import Activity from './Activity';

export default function ActivitiesWrapper() {
	const [{ LoginData }, { notificationsCount, recentNotifications }] = useSelector((state) => [
		state.user,
		state.firebase,
	]);

	return (
		<ul className="list-group list-group-flush p-3 pb-2" style={{ width: 300 }}>
			{!recentNotifications.loaded && recentNotifications.fetching ? (
				<p>Loading...</p>
			) : (
				<>
					{recentNotifications.loaded && recentNotifications.data?.length === 0 ? (
						<Fragment>
							<p className="text-black m-0">
								You have no new notifications available to load right now.
							</p>
							{notificationsCount > 0 && (
								<Link
									className="text-decoration-none text-center text-black fw-bold mt-3"
									to="/dashboard/notifications"
								>
									View all notifications
								</Link>
							)}
						</Fragment>
					) : (
						<>
							<h5 className="text-black">Latest Notifications</h5>
							{recentNotifications.data?.map((notification) => (
								<Activity
									key={notification.id}
									textContent={getNotificationContentByActionType({
										action_type: notification.actionType,
										current_user: LoginData.uuid,
										user_by: notification.userBy,
										user_for: notification.userFor,
									})}
									createdAt={notification.createdAt}
									createdBy={notification.userBy}
									createdFor={notification.userFor}
									isDropDown
								/>
							))}
							{notificationsCount > 5 && (
								<Link
									className="text-decoration-none text-center text-black fw-bold mt-3"
									to="/dashboard/notifications"
								>
									View more notifications
								</Link>
							)}
						</>
					)}
				</>
			)}
		</ul>
	);
}
