import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
// Components
import Input from "../forms/fields/Input";
import Checkbox from "../forms/fields/Checkbox";
import Button from "../forms/fields/Button";
// Utils
import {
  BASEURL,
  RECAPTCHA_SITE_KEY,
  validate,
  nameRexExp,
} from "../../utilites";
import allActions from "../../Store/action";

const annualIncomes = [
  { id: 1, name: "$12,000-$49,999" },
  { id: 2, name: "$50,000-$99,999" },
  { id: 3, name: "$100,000-$249,999" },
  { id: 4, name: "$250,000-$499,999" },
  { id: 5, name: "$500,000+" },
];

const LeadForm = ({ isRegistrationPage,stripe_account_id }) => {
  const [{ services }] = useSelector((state) => [state.dashboard]);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [options, setOptions] = useState([]);
  const [income, setIncome] = useState([]);
  const [agree, setAgree] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    firstname: false,
    lastname: false,
    email: false,
    phone: false,
    zipcode: false,
    options: false,
    general: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const button = useRef();
  const reCaptchaRef = useRef();

  useEffect(() => {
    dispatch(allActions.dashboard.getServices(null, null, null));
  }, [dispatch]);

  // function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    button.current.disabled = true;
    if (errors.general !== "") setErrors({ errors, general: "" });

    const recaptcha_token = await reCaptchaRef.current.executeAsync();
    setLoading(true);

    await axios
      .post(
        `${BASEURL}/api/leads`,
        {
          stripe_account_id,
          recaptcha_token,
          firstname,
          lastname,
          email,
          phone: phone.split(/[ ()-]/).join(""),
          zipcode,
          options,
          income,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        {
          params: {
            utm_source: "direct",
            utm_campaign: "free",
            utml_medium: "direct",
          },
        }
      )
      .then((response) => {
        button.current.innerHTML = `<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...`;
        return {
          lead_id: response.data.data.lead_uuid,
          user_id: response.data.data.user_uuid,
          userpro_id: response.data.data.userpro_uuid,
          allow_appointments: response.data.data.allow_appointments || false,
        };
      })
      .then(({ allow_appointments, lead_id, user_id, userpro_id }) => {
        // Redirect to thank you page
        setTimeout(() => {
          if (allow_appointments) {
            navigate({
              pathname: "/book-appointment",
              search: `?lead=${lead_id}&customer=${user_id}&operator=${userpro_id}`,
            });
          } else {
            navigate({
              pathname: "/thankyou",
              search: `uuid=${lead_id}&appointment=${allow_appointments}`,
            });
          }
          setLoading(false)
        }, 1000);
      })
      .catch((error) => {
        button.current.disabled = false;
        setLoading(false);
        console.error(error.message);
        // Set general error
        if (errors.general === "")
          setErrors({
            ...errors,
            general: error?.response?.data?.message || error.message,
            // email: !error?.response?.data?.isEmailValid || false,
            phone: !error?.response?.data?.isPhoneValid || false,
          });
      });

    // Reset reCaptcha
    reCaptchaRef.current.reset();
  };
  return (
    <section
      className={`clearfix p-4 p-md-5${
        isRegistrationPage
          ? " LeadForm--registration rounded shadow bg-white"
          : " bg-light"
      }`}
    >
      <div className={`${!isRegistrationPage ? "container" : ""}`}>
        <div
          className={`${
            !isRegistrationPage ? "clearfix w-85 mx-auto" : "clearfix"
          }`}
        >
          {isRegistrationPage && (
            <h2 className="text-dark fw-bold mb-4">Create your account</h2>
          )}
          <form onSubmit={handleSubmit} className="needs-validation lead-form">
            <div className="row g-0 g-md-4 mb-2">
              <div
                className={`mb-3${
                  isRegistrationPage ? " col-md-6" : " col-md-3"
                }`}
              >
                <Input
                  type="text"
                  value={firstname}
                  name="firstname"
                  placeholder="First Name"
                  fn={setFirstname}
                  labelClass="text-primary text-uppercase fw-semibold"
                  inputClass="border border-2"
                  onLeave={validate(
                    "firstname",
                    firstname,
                    nameRexExp,
                    errors,
                    setErrors
                  )}
                  hasValidationError={errors.firstname}
                />
              </div>
              <div
                className={`mb-3${
                  isRegistrationPage ? " col-md-6" : " col-md-3"
                }`}
              >
                <Input
                  type="text"
                  value={lastname}
                  name="lastname"
                  placeholder="Last Name"
                  fn={setLastname}
                  labelClass="text-primary text-uppercase fw-semibold"
                  inputClass="border border-2"
                  onLeave={validate(
                    "lastname",
                    lastname,
                    nameRexExp,
                    errors,
                    setErrors
                  )}
                  hasValidationError={errors.lastname}
                />
              </div>
              <div
                className={`mb-3${
                  isRegistrationPage ? " col-md-12" : " col-md-4"
                }`}
              >
                <Input
                  type="email"
                  value={email}
                  name="email"
                  placeholder="Email Address"
                  fn={setEmail}
                  labelClass="text-primary text-uppercase fw-semibold"
                  inputClass="border border-2"
                  // onLeave={validate('email', email, emailRegExp, errors, setErrors)}
                  hasValidationError={errors.email}
                />
              </div>
            </div>
            <div className="row g-0 g-md-4">
              <div
                className={`mb-3${
                  isRegistrationPage ? " col-md-6" : " col-md-3"
                }`}
              >
                <Input
                  type="text"
                  value={phone}
                  name="phone"
                  placeholder="Phone Number"
                  fn={setPhone}
                  labelClass="text-primary text-uppercase fw-semibold"
                  inputClass="border border-2"
                  onLeave={() => {
                    if (
                      !errors.phone &&
                      phone.length > 1 &&
                      phone.length < 14
                    ) {
                      setErrors({ ...errors, phone: true });
                    } else if (errors.phone) {
                      setErrors({ ...errors, phone: false });
                    }
                  }}
                  hasValidationError={errors.phone}
                  mask="(999) 999-9999"
                />
              </div>
              <div
                className={`mb-3${
                  isRegistrationPage ? " col-md-6" : " col-md-3"
                }`}
              >
                <Input
                  type="text"
                  value={zipcode}
                  name="zipcode"
                  placeholder="Zip Code"
                  label="Zip Code"
                  fn={setZipcode}
                  labelClass="text-primary text-uppercase fw-semibold"
                  inputClass="border border-2"
                  onLeave={() => {
                    if (
                      !errors.zipcode &&
                      zipcode.length > 1 &&
                      (zipcode.length < 5 || zipcode.length > 9)
                    ) {
                      setErrors({ ...errors, zipcode: true });
                    } else if (errors.zipcode) {
                      setErrors({ ...errors, zipcode: false });
                    }
                  }}
                  hasValidationError={errors.zipcode}
                  mask="99999999"
                />
              </div>
            </div>
            <hr
              className="text-primary bg-primary mb-4"
              style={{ height: 2 }}
            />
            <div className="row g-4 g-md-5 pt-1">
              {services && (
                <div
                  className={`order-1${!isRegistrationPage ? " col-md-4" : ""}`}
                >
                  <h4 className="text-dark mb-4">What can we help you with?</h4>
                  {services?.map((service) => (
                    <Checkbox
                      key={service.id}
                      wrapperClass="mb-2"
                      labelClass="text-dark fw-semibold"
                      name="interestCheckboxes"
                      state={options}
                      value={service.id}
                      label={service.name}
                      fn={setOptions}
                      id={`checkbox-${service.id}`}
                      required={false}
                    />
                  ))}
                </div>
              )}
              {!isRegistrationPage && (
                <div className="col-md-1 order-3 order-md-2"></div>
              )}
              <div
                className={`order-2 order-md-3${
                  !isRegistrationPage ? " col-md-6" : ""
                }`}
              >
                <h4 className="text-dark mb-3">What is your annual income?</h4>
                <div className="row">
                  <div className="col-md-12">
                    {annualIncomes?.map((ai) => {
                      return (
                        <Checkbox
                          key={ai.id}
                          type="radio"
                          wrapperClass="mb-2"
                          labelClass="text-dark fw-semibold"
                          name="incomeRadios"
                          state={income}
                          value={ai.id}
                          label={ai.name}
                          fn={setIncome}
                          id={`ai-radio-${ai.id}`}
                          required={true}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <ReCAPTCHA
              className="d-none"
              sitekey={RECAPTCHA_SITE_KEY}
              size="invisible"
              ref={reCaptchaRef}
            />
            <div className="clearfix mt-4 mt-md-5 mb-2">
              <div className="form-check">
                <input
                  className="form-check-input form-custom-checkbox"
                  type="checkbox"
                  checked={agree}
                  id="defaultCheck1"
                  onChange={(e) => setAgree(e.target.checked)}
                />
                <label
                  className="form-check-label fw-semibold"
                  htmlFor="defaultCheck1"
                >
                  I agree to the Uncle Kam{" "}
                  <Link to="/user-agreement" className="text-primary">
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy-policy" className="text-primary">
                    Privacy Policy
                  </Link>
                </label>
              </div>
            </div>

            {errors.general && errors.general !== "" && (
              <div className="row">
                <div className="mb-3">
                  <div className="alert alert-danger" role="alert">
                    {errors.general}
                  </div>
                </div>
              </div>
            )}
              <Button
                  buttonRef={button}
                  spinnerClass={
                         Loading ? "spinner-border spinner-border-sm text-white" : ""
                          }
                  wrapperClass="clearfix text-center text-md-start "
                  mainClass={`btn btn-primary fs-5 fw-semibold py-2 mt-4 d-flex justify-content-center align-items-center gap-2 ${
                    !agree || Object.values(errors).indexOf(true) > -1
                          ? " disabled"
                          : ""
                    }  ${!isRegistrationPage ? "px-5" : "w-100 px-3"}`}
                          buttonText={Loading ? "Submitting... " : "Get started today"}
              />
          </form>
        </div>
      </div>
    </section>
  );
};

export default LeadForm;
