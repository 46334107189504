import moment from 'moment';
import UserAvatar from '../UserAvatar';

export default function Activity({
	textContent,
	createdAt,
	createdBy,
	createdFor,
	maxHeight,
	isDropDown,
}) {
	return (
		<li className='list-group-item bg-transparent' style={maxHeight && { height: maxHeight }}>
			<div className='row align-items-center h-100'>
				{createdBy && (
					<div className={`${isDropDown ? 'col-4' : 'col-2'} h-100`}>
						<UserAvatar
							avatar={createdBy.avatar}
							alt={`${createdBy.firstname} ${createdBy.lastname}`}
							className='img-fluid rounded-circle h-100'
						/>
					</div>
				)}
				{!createdBy && createdFor && (
					<div className={`${isDropDown ? 'col-4' : 'col-2'} h-100`}>
						<UserAvatar
							avatar={createdFor.avatar}
							alt={`${createdFor.firstname} ${createdFor.lastname}`}
							className='img-fluid rounded-circle h-100'
						/>
					</div>
				)}
				<div className={`${isDropDown ? 'col-8' : 'col-10'} h-100`}>
					{createdBy && (
						<p className='text-black fw-bold m-0'>{`${createdBy.firstname} ${createdBy.lastname}`}</p>
					)}
					{!createdBy && createdFor && (
						<p className='text-black fw-bold m-0'>{`${createdFor.firstname} ${createdFor.lastname}`}</p>
					)}
					<p className='text-dark text-opacity-75 m-0'>{textContent}</p>
					<span className='text-primary'>{moment(createdAt).fromNow()}</span>
				</div>
			</div>
		</li>
	);
}
