/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';


const Qbid = () => {

	const dispatch=useDispatch()
	const [{ taxPayerForm, taxPayerFormData, formInformation}] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 7 });

    const [UBIAShare, setUBIAShare]=useState();
    const [allocatedShare, setAllocatedShare]=useState();
    const [patronReduction, setPatronReduction]=useState();
    const [carryOverLossses, setCarryOverLosses]=useState();
	const [principalAsset, setPrincipalAsset] = useState(null);

	const [errors, setErrors] = useState({}); // Track errors for form fields

	useEffect(() => {
    	if (taxPayerForm?.data?.Qbid) {
    		if (Object.keys(taxPayerForm?.data?.Qbid).length === 0) return;
			const {
				UBIAShare,
				allocatedShare,
				patronReduction,
				carryOverLossses,
				principalAsset
			} = taxPayerForm?.data?.Qbid;
			setUBIAShare(UBIAShare)
			setAllocatedShare(allocatedShare)
			setPatronReduction(patronReduction)
			setCarryOverLosses(carryOverLossses)
			setPrincipalAsset(principalAsset)
    	}
    }, [taxPayerForm?.data?.Qbid]);
	
	const check_Validity = () => {
		setErrors({});
		const newErrors = {};
	
		// Add more validation rules as needed for other fields
		if (
			principalAsset===null) {
			newErrors.AnswerOtherQuestions = 'Please Select Required Option';
		}
	
		setErrors(newErrors);
	
		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		let errors = check_Validity();
		if (errors) return;
	
		let FormObj = {
			UBIAShare,
			allocatedShare,
			patronReduction,
			carryOverLossses,
			principalAsset
		};
        let formData = taxPayerForm;
        taxPayerForm.data.Qbid = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));
        uploadFormData(formData);
    };

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 7;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			{/* <small className="ml-4 fw-bold">General Information</small>
			<hr className="m-0" /> */}
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<div className="col-md-12 mb-3">
					<Input
						type="text"
						name="enityName"
						value={allocatedShare}
						placeholder="What is your allocated share of W-2 wages from this trade or business? Include wages paid to you or your spouse from this business."
						fn={setAllocatedShare}
					/>
				</div>
				<div className="col-md-12 mb-3">
					<Input
						type="text"
						name="ownBusinessPercentage"
						value={UBIAShare}
						placeholder="What is the allocated share of unadjusted basis immediately (UBIA) after acquisition of all qualified business property from this business? This is generally the purchase price of the asset before any depreciation was taken."
						fn={setUBIAShare}
						mask={"999999999"}
					/>
				</div>
				<div className="col-md-12 mb-3">
					<Input
						type="text"
						name="spouseBusinessPercentage"
						value={patronReduction}
						placeholder="What is the patron reduction from horticultural or agricultural cooperatives, for this business?"
						fn={setPatronReduction}
					/>
				</div>
				<div className="col-md-12 mb-3">
					<Input
						type="text"
						name="changeInOwnership"
						value={carryOverLossses}
						placeholder="What were the prior year carryover losses for this trade or business?"
						fn={setCarryOverLosses}
						mask={'999999999'}
					/>
				</div>
                <div className="mt-3 row justify-content-between px-3 custom-left-form">
                <div className=" mb-3 px-3">
                    <small className="text-dark fw-bold m-0">Please answer the following</small>
                    <div
                        className="w-100 mt-2"
                    >
                        <small className="col-md-12 form-check-label text-start" htmlFor={`checkbox`}>
                        Is this a business where the principal asset is the reputation or skill of one or more of its employees or owners, such as businesses providing services in health, law, performing arts, accounting, actuarial science, and consulting?
                        {principalAsset===null && (
    						<span className="text-danger small-text mx-1">*Required</span>
						)}
						</small>
                        <div className="col-md-12">
                            <input
                                onChange={() => setPrincipalAsset(true)}
                                checked={principalAsset === true}
                                className="form-check-input"
                                type="radio"
                                id={`checkbox-div2`}
                                name={`checkbox-div2`}
                            />
                            <label htmlFor={`checkbox-div2`} role="button" className="px-3 m-0 small">
                                Yes
                            </label>
                            <input
                                onChange={() => setPrincipalAsset(false)}
                                checked={principalAsset === false}
                                className="form-check-input"
                                type="radio"
                                id={`checkbox-div1`}
                                name={`checkbox-div1`}
                            />
                            <label htmlFor={`checkbox-div1`} role="button" className="px-3 m-0 small">
                                No
                            </label>
                        </div>
                    </div>
                </div>
            </div>
			</div>
			{Object.keys(errors)
    ?.map((key) => {
        return errors[key];
    })
    ?.map((data, index) => {
        return (
            <>
                <small className="text-primary" key={index}>
                    {data}*
                </small>
                <br />
            </>
        );
    })}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={()=>goBack()}

					/>
					<Button
						mainClass="btn btn-primary custon-button-text w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default Qbid;