/* eslint-disable no-unused-vars */
import React, { useEffect, useState, Fragment,useCallback } from 'react'
import Modal from '../../../components/Dashboard/Modal'
import SweetAlert2 from 'react-sweetalert2';
import LoginModalForm from '../../../components/forms/LoginModalForm';
import { useSelector, useDispatch } from 'react-redux';
import allActions from '../../../Store/action';
import { FileTypeIcon, BASEURL } from '../../../utilites';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { AiOutlineCheck } from 'react-icons/ai';
import AddDocumentForm from '../../../components/forms/AddDocumentForm';
import ChooseDocumentForm from '../../../components/forms/ChooseDocumentForm';

const DocumentUploadMonile = () => {
    const [{ LoginData }] =
        useSelector((state,) => [state.user, state.dashboard]);
	const [lead, setLead] = useState(null);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const {uuid} = useParams()
	const [job, setJob] = useState(null);
	const [documentToUpload, setDocumentToUpload] = useState(null);
	const [documentToView, setDocumentToView] = useState();
	const [swalProps, setSwalProps] = useState({});



    useEffect(() => {
        if(LoginData === null) {
            document.querySelector('.login-modal').click()
        }else{
            reloadData()
        }
        if ((job?.form_data && job.form_data.length > 0) || (job?.documents && job.documents.length > 0)){
            document.querySelector('.LoginModalClose').click()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[LoginData,job])


    const changeLoading = (status) => {
        dispatch(allActions.global.Loader(status));
      };

    useEffect(() => {
		setJob(lead?.jobs[0]);
	}, [lead]);

    const reloadData = async () => {
        loadJobByUUID(uuid);
		dispatch(allActions.dashboard.getAllExistingDocuments(1));
	};

    // useEffect(() => {
    //     if(job?.form_data.length > 0 && LoginData === null){
    //     }else{
    //     }
    // },[job?.form_data])


	const loadJobByUUID = useCallback((UUID) => {
		axios
			.get(`${BASEURL}/api/jobs/${UUID}`)
			.then((res) => {
				console.log('job data>>>>', res.data);
				setLead(res.data.data);
			})
			.catch((err) => {
				console.log('error>>>>', err);
			});
	}, []);

	useEffect(() => {
		loadJobByUUID(uuid);
	}, [uuid, loadJobByUUID]);

    const OpenMyForm = (document, jobId) => {
		if (!jobId || !document) return;
		document.lead_id = jobId;
		dispatch(allActions.dashboard.resetTaxPreparation());
		dispatch(allActions.taxForms.dispatchViewFormDetails({ document, navigate }));
	};

    const deleteDocument = (uuid) => {
		dispatch(allActions.dashboard.deleteDocument(uuid))
			.then(() => {
				setTimeout(() => reloadData(), 500); // TODO: Fix this
			})
			.catch((error) => console.error(error));
	};

    const handleDeleteDocumentClick = (uuid) => {
		setSwalProps((prev) => ({
			...prev,
			[uuid]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				text: 'You will not be able to recover this document!',
				confirmButtonText: 'Yes, delete it!',
				denyButtonText: 'Cancel',
				didClose: () => setSwalProps((prev) => ({ ...prev, [uuid]: { show: false } })),
				onConfirm: () => deleteDocument(uuid),
			},
		}));
	};

    // useEffect(() => {
    //     if (LoginData === null && isMobileDevice()) {
    //         document.querySelector('.login-modal').click()
    //     } else {
    //         document.querySelector('.LoginModalClose').click()
    //         loadDocuments()
    //     }
    // }, [LoginData])

    // useEffect(() => {
    //     const redirectBasedOnDevice = () => {
    //         if (isMobileDevice()) {
    //             navigate('/qr-upload');
    //         } else {
    //             navigate('/qr-code');
    //             document.querySelector('.LoginModalClose').click()
    //         }
    //     };
    //     redirectBasedOnDevice();
    // }, [navigate]);

    const isMobileDevice = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /android|webOS|iPhone|iPad|iPod|blackberry|iemobile|opera mini/i.test(userAgent);
    };

    return (
        <>
            <Modal title="Login"
                bodyClass="p-3"
                btnText="Log in"
                trigger="LoginModal"
                showBtn={false}
                size={"md"}
                headcenter={true}
                centerClass={'centerClass'}
                backdrop={true}
                btnClass="LoginModalClose"
                close={true}
            >
                <LoginModalForm handleStatusClick={() => { }} />
                <Link
                    to='#'
                    className='d-block text-decoration-none text-dark LoginModal'
                    data-bs-toggle='modal'
                    data-bs-target='#LoginModal'
                >
                </Link>
            </Modal>
            <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#LoginModal" // Ensure this matches the modal ID
                className="opacity-0 login-modal"
            >
            </button>
            <Modal
					title="View Document"
					bodyClass="px-5 py-4 text-center"
					trigger="viewDocumentModal"
					showBtn={false}
					size="lg"
				>
					{documentToView &&
						(documentToView.location.includes('.pdf') ? (
							<>
								{/* <RenderMultiPagePDF file={documentToView.path} /> */}
							</>
						) : (
							<img
								src={documentToView.path}
								className="w-100 img-fluid rounded"
								alt={documentToView.name}
								style={{ objectFit: 'contain' }}
							/>
						))}
				</Modal>


                <Modal
					title="Upload Document"
					bodyClass="px-5 py-4 text-center"
					trigger="uploadDocumentModal"
					showBtn={false}
					btnClass="addDocumentModalClose"
					size="lg"
				>
					{documentToUpload ? (
						<Fragment>
							<nav>
								<div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
									<button
										className="nav-link active"
										id="nav-existing-tab"
										data-bs-toggle="tab"
										data-bs-target="#nav-existing"
										type="button"
										role="tab"
										aria-controls="nav-existing"
										aria-selected="true"
									>
										Choose an existing file
									</button>
									<button
										className="nav-link"
										id="nav-fromComputer-tab"
										data-bs-toggle="tab"
										data-bs-target="#nav-fromComputer"
										type="button"
										role="tab"
										aria-controls="nav-fromComputer"
										aria-selected="false"
									>
										Upload from computer
									</button>
								</div>
							</nav>
							<div className="tab-content mt-3" id="nav-tabContent">
								<div
									className="tab-pane show active"
									id="nav-existing"
									role="tabpanel"
									aria-labelledby="nav-existing-tab"
									tabIndex="0"
								>
									<ChooseDocumentForm
										userId={LoginData.id}
										documentId={documentToUpload.id}
										reloadDocuments={reloadData}
										targetUserUuid={documentToUpload?.userProUuid}
									/>
								</div>
								<div
									className="tab-pane"
									id="nav-fromComputer"
									role="tabpanel"
									aria-labelledby="nav-fromComputer-tab"
									tabIndex="0"
								>
									<AddDocumentForm
									    isModal={true}
										userId={LoginData.id}
										multiple={false}
										documentId={documentToUpload.id}
										reloadDocuments={reloadData}
										showDocumentNameField={false}
										targetUserUuid={documentToUpload?.userProUuid}
									/>
								</div>
							</div>
						</Fragment>
					) : null}
				</Modal>


            {/* /////////////////// */}

                                    <div className="clearfix border border-light rounded-3 p-4 mt-4">
									<h1 className="text-primary">Documents</h1>
									<div className="clearfix mt-3 px-2">
										{job?.service?.id && Number(job?.service?.id) === 1 && (
											<div className="clearfix">
												{job.form_data && job.form_data.length > 0 ? (
													job.form_data?.map((document) => (
														<div
															className="row align-items-center px-2 py-4 border border-light rounded-3 mb-3"
															key={document.id}
														>
															<div className="col-md-1 d-flex justify-content-center">
																<div
																	className={`rounded-circle bg-${
																		Object.keys(document?.formData)?.length > 0
																			? 'success'
																			: 'grey-200'
																	} text-white d-flex justify-content-center align-items-center`}
																	style={{
																		width: 20,
																		height: 20,
																	}}
																>
																	<AiOutlineCheck />
																</div>
															</div>
															<div
																className="col-md-9 d-flex align-items-center"
																style={{ gap: 10 }}
															>
																<FileTypeIcon extension={document?.extension} /> {document?.name}
															</div>
															{Object.keys(document?.formData)?.length === 0 ? (
																<div className="col">
																	<button
																		className={`btn btn-sm btn-outline-primary ${
																			job?.status_id === 4 && 'disabled'
																		}`}
																		onClick={() => {
																			OpenMyForm(document, job?.id);
																		}}
																	>
																		Fill Form
																	</button>
																</div>
															) : (
																<div className="col text-end">
																	<button
																		className="btn btn-sm btn-outline-primary"
																		onClick={() => {
																			OpenMyForm(document, job?.id);
																		}}
																	>
																		View Form
																	</button>
																</div>
															)}
														</div>
													))
												) : (
													<></>
												)}
											</div>
										)}
										<div className="clearfix">
											{job?.documents && job?.documents?.length > 0 ? (
												job?.documents?.map((document) => (
													<div
														className="d-flex flex-column gap-3 align-items-center px-2 py-4 border border-light rounded-3 mb-3"
														key={document.id}
													>
														<div className="col-md-1 d-flex justify-content-center">
															<div
																className={`rounded-circle bg-${
																	document.path ? 'success' : 'grey-200'
																} text-white d-flex justify-content-center align-items-center`}
																style={{ width: 20, height: 20 }}
															>
																<AiOutlineCheck />
															</div>
														</div>
														<div className="col-md-7 d-flex align-items-center text-center" style={{ gap: 10 }}>
															<FileTypeIcon extension={document?.extension} /> {document.name}
														</div>
														{document.path ? (
															<div className="col">
																<p className="text-muted text-end mb-0">
																	Added{' '}
																	{new Date(document.updated_at).toLocaleDateString('en-US', {
																		year: 'numeric',
																		month: 'short',
																		day: 'numeric',
																	})}
																</p>
															</div>
														) : (
															<div className="col text-end">
																<button
																	className="btn btn-sm btn-outline-primary"
																	data-bs-toggle="modal"
																	data-bs-target="#uploadDocumentModal"
																	onClick={() =>
																		setDocumentToUpload({
																			...document,
																			...{
																				userProUuid: job?.leads?.userpro?.user?.uuid,
																			},
																		})
																	}
																>
																	Upload
																</button>
															</div>
														)}
														<div className="col-md-1 text-end text-primary">
															<div className="px-0 btn-group" style={{ width: 30 }}>
																<button
																	type="button"
																	className={`btn btn-sm text-primary border-0 rounded-circle${
																		!document.path ? ' disabled' : ''
																	}`}
																	data-bs-toggle="dropdown"
																	aria-expanded="false"
																	style={{
																		width: 30,
																		height: 30,
																	}}
																>
																	<BsThreeDotsVertical />
																</button>
																<ul className="dropdown-menu">
																	<li>
																		<Link
																			to="#"
																			className={`dropdown-item${
																				document?.path ? '' : ' disabled'
																			}`}
																			data-bs-toggle="modal"
																			data-bs-target="#viewDocumentModal"
																			onClick={() => setDocumentToView(document)}
																		>
																			View
																		</Link>
																		<button
																			className="dropdown-item"
																			onClick={() => handleDeleteDocumentClick(document.uuid)}
																		>
																			Delete
																		</button>
																		<SweetAlert2 {...swalProps[document.uuid]} />
																	</li>
																</ul>
															</div>
														</div>
													</div>
												))
											) : (
												<></>
											)}
										</div>
										{(job?.documents?.length ? job?.documents?.length : job?.form_data?.length) ===
											0 && <p className="text-center mb-0">No documents found</p>}
									</div>
								</div>
          
        </>
    )
}

export default DocumentUploadMonile