/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState, useEffect, useCallback} from 'react'
import FileBlueSvg from '../../assets/img/filebluesvg.svg'
import QuestionaireSvg from '../../assets/img/quetenionairesvg.svg'
import BinSvg from '../../assets/img/binsvg.svg'
import { GLOBAL_VARIABLES } from '../../utilites/variables';
import { useDispatch } from 'react-redux';
import allActions from '../../Store/action';
import axios from 'axios'
import { BASEURL } from '../../utilites';
import { useParams } from 'react-router-dom';



const { DocumentOptions } = GLOBAL_VARIABLES;


const RequestJobTaxForm = ({data,userData,setSelectedDocument,jobs,setJobs}) => {

	const [selectedFormNames, setSelectedFormNames] = useState(data || []);
	const [addMessageOnRequest, setAddMessageOnRequest] = useState('')
	const [selectedForms,setSelectedForms] = useState([])
    const [additionalTaxForms,setEdditionalTaxFroms] = useState([])
    const [alreadySelectedForms,setAlreadySelectedForms] = useState([])
    const {uuid} = useParams()

    const dispatch = useDispatch()

    const changeLoading = (status) => {
        dispatch(allActions.global.Loader(status));
      };

    const loadJobByUUID = useCallback((UUID) => {
		axios
			.get(`${BASEURL}/api/jobs/${UUID}`)
			.then((res) => {
				setJobs(res.data.data);
			})
			.catch((err) => {
				console.log('error>>>>', err);
			});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

        const handleSubmit = (e) => {
        e.preventDefault()
        changeLoading(true)
        axios
			.post(`${BASEURL}/api/jobs/request-taxforms`, {
				customer_id: userData.customer_id,
				pro_id:userData.pro_id,
				job_id: userData.job_id,
				forms:additionalTaxForms,
			})
			.then(() => {
                loadJobByUUID(uuid)
                setEdditionalTaxFroms([])
                setSelectedForms()
                document
					.querySelector(`.${'requestJobTaxFormsModalClose'}`)
					.click();
                    changeLoading(false)
			})
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
		if(jobs?.jobs?.length > 0){
            setSelectedDocument(jobs?.jobs[0].form_data)
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jobs]);

    useEffect(() => {
        setSelectedForms(selectedFormNames?.map(name => name?.name))
     },[selectedFormNames])
     useEffect(() => {
        setSelectedForms(additionalTaxForms?.map(name => name?.name))
     },[additionalTaxForms])

     useEffect(() => {
        setSelectedFormNames(() => data?.map(form => {
            return {name:form.name,value:form.name,label:form.name, message:''}
        }))
        setAlreadySelectedForms(data?.map(data => data.name))
     },[data])

     return (
    <div className='border-dashed rounded mt-3 bg-muted-200  pb-5 bg-color'>
    <div className='mt-5 d-flex flex-column justify-content-center align-items-center'>
        <h3 className='text-center text-grey-400 fw-bold'>Smart Questionnaire</h3>
                 <p className='text-center w-85 mt-2 text-2 px-5 '>Select from among our pre-built Smart Questionnaires. Your Client’s input are saved as they answer each question, allowing them to continue right from where they last left off.</p>
             </div>
             {additionalTaxForms.length > 0 && additionalTaxForms.map((name, id) => {
                 return <div key={name.value} className='mt-5 px-5 mb-3 d-flex align-items-start justify-content-between border-b pb-3' >
                     <div className='d-flex gap-4 cursor-pointer'
                     >
                         <img style={{height:'30px'}} className='mt-1 icon-h' src={QuestionaireSvg} alt='requestfile' onClick={() => {
                         }} />
                         <div role='button'>
                             <div className='d-flex align-itesm-center gap-5'>
                                 <p className='mb-0 fw-bold'>{name.value}</p>
                             </div>
                             <p className='mb-0 mt-1 max-w'>{name.label} </p>
                             <p onClick={() => setAddMessageOnRequest(name.value)} className='mt-1 text-start text-primary cursor-pointer'>Add a Message</p>
                             {(addMessageOnRequest === name.value || name?.message) && <textarea
                                 className='w-100'
                                 value={name?.message}
                                 onChange={(e) => {
                                     const edited = additionalTaxForms?.map(n => {
                                         if (n.value === name.value) {
                                             return {
                                                ...name,
                                                 message: e.target.value
                                             }
                                         }
                                         return n
                                     })
                                     setEdditionalTaxFroms(edited)
                                 }}
                             ></textarea>}
                         </div>
                     </div>
                     <div className='d-flex gap-4'>
                         <img style={{height:'30px'}} className='cursor-pointer icon' onClick={() => {
                             const deletedFormName = additionalTaxForms.filter(formname => formname.value !== name.value)
                             setEdditionalTaxFroms(deletedFormName)
                         }} src={BinSvg} alt='delete' />
                     </div>
                 </div>
             })}
    <div className='row d-flex justify-content-start  card-request-cont'>
        {/* <div className='border-solid d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded'>
            <img style={{height:'40px'}} src={PlusSvg} alt='plus' />
            Create New
        </div> */}
        {DocumentOptions.map((option) => {
            return <div key={option.label} onClick={() => {
                const isForm = additionalTaxForms?.find((name) => name.value === option.value)
                const isFormAlreadySelected = alreadySelectedForms?.find(formname =>  formname === option.value )
                if(isForm){
                    const deletedFormName = additionalTaxForms?.filter((name) => name.label !== option.label ) 
                    setEdditionalTaxFroms(deletedFormName)
                }else if(isFormAlreadySelected) {
                        return
                }else{
                    setSelectedFormNames((prev) => [...prev, {name:option.value,value:option.value,label:option.label, message:''}])
                    setEdditionalTaxFroms((prev) => [...prev, {name:option.value,value:option.value,label:option.label, message:''}])
                }
                setAddMessageOnRequest('')
            }} className={`px-5 text-center border-solid ${alreadySelectedForms?.includes(option.value) || selectedForms?.includes(option.value) ? 'border-primary' : ''} d-flex flex-column justify-content-center align-items-center gap-2 custom-request-card rounded text-sm`}>
                <img style={{ height: '20px' }} src={FileBlueSvg} alt='plus' />
                <small> {option.value}</small>
            </div>
        })}
    </div>
    <button className='btn btn-primary text-lg mt-5 w-50' onClick={handleSubmit}>Send request</button>
</div>
  )
}

export default RequestJobTaxForm