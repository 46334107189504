/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { UsaStates } from 'usa-states';

import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import ReactSelect from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';
const UsaStatesList = new UsaStates().states?.map((e) => {
	return { label: e.name, value: e.abbreviation };
});
const BusinessGeneralFinances = () => {

    const dispatch=useDispatch()
	const [{ taxPayerForm, taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 2 });

    const {businessGeneralFinancesOptions}= GLOBAL_VARIABLES
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	// const { otherQuestions } = GLOBAL_VARIABLES;

	// const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 0 });

	// const [{ taxPayerForm }] = useSelector((state) => [state.taxForms]);
    // form States
    
	const [state, setState] = useState({});
    const [forignTrust, SetForignTrust]= useState(null);
    const [forignTrustInput, SetForignTrustInput]= useState();
	const [howProvideReport, setHowProvideReport] = useState();
	const [specificQuestions, setSpecificQuestions] = useState([]);
    const [nonDisclosureAgreement, SetNonDisclosureAgreement]= useState(null);
    const [businessVirtualCurrency, SetBusinessVirtualCurrency]= useState(null);
    const [compensationRelatedInput, SetCompensationRelatedInput]= useState();
    const [nonDisclosureAgreementInput, SetNonDisclosureAgreementInput]= useState();
    const [compensationRelatedAccruals, SetCompensationRelatedAccruals]= useState(null);
    const [businessVirtualCurrencyInput, SetBusinessVirtualCurrencyInput]= useState();

    const [errors, setErrors] = useState({}); // Track errors for form fields


    const multipleChoice = [] ;
	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

const handleQuestions = (index, bool) => {
    console.log(index, bool);
    const localSpecificQuestions = specificQuestions.slice();
	localSpecificQuestions[index].bool = bool;
    setSpecificQuestions(localSpecificQuestions);
};

	useEffect(() => {
		setSpecificQuestions(JSON.parse(JSON.stringify(businessGeneralFinancesOptions)));
	}, []);

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.BusinessGeneralFinances) {
			if (Object.keys(taxPayerForm?.data?.BusinessGeneralFinances).length === 0) return;
			const {
                state,
                forignTrust,
                forignTrustInput,
                howProvideReport,
                specificQuestions,
                nonDisclosureAgreement,
                businessVirtualCurrency,
                compensationRelatedInput,
                nonDisclosureAgreementInput,
                compensationRelatedAccruals,
                businessVirtualCurrencyInput
			} = taxPayerForm?.data?.BusinessGeneralFinances;
			setState(state);
			SetForignTrust(forignTrust);
			SetForignTrustInput(forignTrustInput);
			setHowProvideReport(howProvideReport);
			setSpecificQuestions(specificQuestions);
			SetNonDisclosureAgreement(nonDisclosureAgreement);
			SetBusinessVirtualCurrency(businessVirtualCurrency);
			SetCompensationRelatedInput(compensationRelatedInput);
			SetNonDisclosureAgreementInput(nonDisclosureAgreementInput);
			SetCompensationRelatedAccruals(compensationRelatedAccruals);
			SetBusinessVirtualCurrencyInput(businessVirtualCurrencyInput);
		}
	}, [taxPayerForm?.data?.BusinessGeneralFinances]);

    const check_Validity = () => {
        setErrors({});
        const newErrors = {};
    
        // Add more validation rules as needed for other fields
        if (
            specificQuestions
                ?.flatMap((data) => {
                    return data?.bool;
                })
                .includes(null)
        ||businessVirtualCurrency===null||forignTrust===null||compensationRelatedAccruals===null ||nonDisclosureAgreement===null) {
            newErrors.AnswerOtherQuestions = 'Please Select Required Option';
        }
    
        setErrors(newErrors);
    
        let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
        // Check if there are any errors
        return ConditionCheck;
    };
    
	const handleSubmit = (e) => {
		e.preventDefault();

        let errors = check_Validity();
        if (errors) return;

        let FormObj = {
            state,
            forignTrust,
            forignTrustInput,
            howProvideReport,
            specificQuestions,
            nonDisclosureAgreement,
            businessVirtualCurrency,
            compensationRelatedInput,
            nonDisclosureAgreementInput,
            compensationRelatedAccruals,
            businessVirtualCurrencyInput
        };
        let formData = taxPayerForm;
		taxPayerForm.data.BusinessGeneralFinances = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
    };

    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 2;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};
    
	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<small className="ml-4 fw-bold">General Information</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
                <div className="col-md-6 mb-3">
                <small className="col-md-6 form-label text-start" htmlFor={`checkbox`}>
                Method used to value closing inventory
                    </small>
					<ReactSelect
						id="state"
						className="p-0 basic-single mt-2"
						placeholder="Business State"
						closeMenuOnSelect={true}
						options={UsaStatesList}
						onChange={setState}
						value={state}
					/>
				</div>
				<div className=" mb-3 px-3">
					{specificQuestions?.map((data, i) => {
						return (
							<div key={i} className="w-100  align-items-center justify-content-between mt-2">
								<small className="col-md-12 form-check-label text-start" htmlFor={`checkbox-${i}`}>
									{data.question}
									{data.bool === null && (
										<span className="text-danger small-text mx-1">*Required</span>
									)}
								</small>
								<div className="col-md-12">
									<input
										onChange={() => handleQuestions(i, true)}
										checked={data?.bool === true}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 176}`}
										name={`checkbox-${i + 176}`}
									/>
									<label htmlFor={`checkbox-${i + 176}`} role="button" className="px-3 m-0 small">
										Yes
									</label>
									<input
										onChange={() => handleQuestions(i, false)}
										checked={data?.bool === false}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 999}`}
										name={`checkbox-${i + 999}`}
									/>
									<label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
										No
									</label>
								</div>
							</div>
						);
					})}
					{JSON.parse(JSON.stringify(multipleChoice))?.map((data, i) => {
						return (
							<div key={i} className="w-100 my-2">
								<small
									className="col-md-6 form-check-label text-start fw-bold mb-1"
									htmlFor={`howProvideReport-${i}`}
								>
									{data.question}
									{!howProvideReport && (
										<span className="text-danger small-text mx-1">*Required</span>
									)}
								</small>
								{data?.options?.map((option, index) => {
									return (
										<div>
											<input
												onChange={() => setHowProvideReport(option)}
												checked={howProvideReport === option}
												className="form-check-input"
												type="radio"
												id={`howProvideReport-${index}`}
												name={`howProvideReport-${index}`}
											/>
											<label className="px-3 small" htmlFor={`howProvideReport-${index}`}>
												{option}
											</label>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
            <div className="mt-1 row justify-content-between px-3 custom-left-form">
                <div className=" mb-3 px-3">
                    {/* <small className="text-dark fw-bold m-0">Please answer the following</small> */}
                    <div
                        className="w-100 mt-2"
                    >
                        <small className="col-md-12 form-check-label text-start" htmlFor={`checkbox`}>
                            Did the business acquire, use, dispose of or hold any virtual currency/cryptocurrency (such as bitcoin)? If yes, provide details.
                            {businessVirtualCurrency===null && (
                                <span className="text-danger small-text mx-1">*Required</span>
                            )}
                        </small>
                        <div className="col-md-12">
                            <input
                                onChange={() => SetBusinessVirtualCurrency(true)}
                                checked={businessVirtualCurrency === true}
                                className="form-check-input"
                                type="radio"
                                id={`checkbox-div123`}
                                name={`checkbox-div123`}
                            />
                            <label htmlFor={`checkbox-div123`} role="button" className="px-3 m-0 small">
                                Yes
                            </label>
                            <input
                                onChange={() => SetBusinessVirtualCurrency(false)}
                                checked={businessVirtualCurrency === false}
                                className="form-check-input"
                                type="radio"
                                id={`checkbox-div223`}
                                name={`checkbox-div223`}
                            />
                            <label htmlFor={`checkbox-div223`} role="button" className="px-3 m-0 small">
                                No
                            </label>
                        </div>
                    </div>
                </div>
                {businessVirtualCurrency &&
                    <div className="col-md-12 mb-3">
                        <Input
                            type="text"
                            name="changeInOwnership"
                            value={businessVirtualCurrencyInput}
                            placeholder="provide details"
                            fn={SetBusinessVirtualCurrencyInput}
                        />
                    </div>
                }
            </div>
            <div
                className="w-100 mt-2"
            >
                <small className="col-md-12 form-check-label text-start" htmlFor={`checkbox`}>
                    Was the business the grantor or transferor to a foreign trust during the year? If yes, provide details.
                    {forignTrust===null && (
                        <span className="text-danger small-text mx-1">*Required</span>
                    )}
                </small>
                <div className="col-md-4">
                    <input
                        onChange={() => SetForignTrust(true)}
                        checked={forignTrust === true}
                        className="form-check-input"
                        type="radio"
                        id={`checkbox-div3`}
                        name={`checkbox-div3`}
                    />
                    <label htmlFor={`checkbox-div3`} role="button" className="px-3 m-0 small">
                        Yes
                    </label>
                    <input
                        onChange={() => SetForignTrust(false)}
                        checked={forignTrust === false}
                        className="form-check-input"
                        type="radio"
                        id={`checkbox-div4`}
                        name={`checkbox-div4`}
                    />
                    <label htmlFor={`checkbox-div4`} role="button" className="px-3 m-0 small">
                        No
                    </label>
                </div>
            </div>
            {forignTrust &&
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="changeInOwnership"
                        value={forignTrustInput}
                        placeholder="provide details"
                        fn={SetForignTrustInput}
                    />
                </div>
            }
            <div
                className="w-100 mt-2"
            >
                <small className="col-md-12 form-check-label text-start" htmlFor={`checkbox`}>
                    Will all compensation-related accruals (including vacation pay) be paid within 2½ months of year end?
                    {compensationRelatedAccruals===null && (
                        <span className="text-danger small-text mx-1">*Required</span>
                    )}
                </small>
                <div className="col-md-4">
                    <input
                        onChange={() => SetCompensationRelatedAccruals(true)}
                        checked={compensationRelatedAccruals === true}
                        className="form-check-input"
                        type="radio"
                        id={`checkbox-div5`}
                        name={`checkbox-div5`}
                    />
                    <label htmlFor={`checkbox-div5`} role="button" className="px-3 m-0 small">
                        Yes
                    </label>
                    <input
                        onChange={() => SetCompensationRelatedAccruals(false)}
                        checked={compensationRelatedAccruals === false}
                        className="form-check-input"
                        type="radio"
                        id={`checkbox-div6`}
                        name={`checkbox-div6`}
                    />
                    <label htmlFor={`checkbox-div6`} role="button" className="px-3 m-0 small">
                        No
                    </label>
                </div>
            </div>
            {compensationRelatedAccruals===false &&
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="changeInOwnership"
                        value={compensationRelatedInput}
                        placeholder="If no, provide details of unpaid amounts."
                        fn={SetCompensationRelatedInput}
                    />
                </div>
            }
            <div
                className="w-100 mt-2"
            >
                <small className="col-md-8 form-check-label text-start" htmlFor={`checkbox`}>
                    Did the business pay or incur any expenses, including settlements, other payouts or attorney fees, related to a sexual abuse or sexual harassment claim if the payments are subject to a nondisclosure agreement?
                    {nonDisclosureAgreement===null && (
                    <span className="text-danger small-text mx-1">*Required</span>
                )}
                </small>
                <div className="col-md-4">
                    <input
                        onChange={() => SetNonDisclosureAgreement(true)}
                        checked={nonDisclosureAgreement === true}
                        className="form-check-input"
                        type="radio"
                        id={`checkbox-div7`}
                        name={`checkbox-div7`}
                    />
                    <label htmlFor={`checkbox-div7`} role="button" className="px-3 m-0 small">
                        Yes
                    </label>
                    <input
                        onChange={() => SetNonDisclosureAgreement(false)}
                        checked={nonDisclosureAgreement === false}
                        className="form-check-input"
                        type="radio"
                        id={`checkbox-div20`}
                        name={`checkbox-div20`}
                    />
                    <label htmlFor={`checkbox-div20`} role="button" className="px-3 m-0 small">
                        No
                    </label>
                </div>
            </div>
            {nonDisclosureAgreement &&
                <div className="col-md-12 mb-3">
                    <Input
                        type="text"
                        name="changeInOwnership"
                        value={nonDisclosureAgreementInput}
                        placeholder="If yes, list amount(s) and indicate the reason for the penalty, fine or other expense and which accounts these expenses were posted."
                        fn={SetNonDisclosureAgreementInput}
                    />
                </div>
            }
            {Object.keys(errors)
    ?.map((key) => {
        return errors[key];
    })
    ?.map((data, index) => {
        return (
            <>
                <small className="text-primary" key={index}>
                    {data}*
                </small>
                <br />
            </>
        );
    })}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button mt-4'}
						type="button"
                        callback={()=>goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
                        disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default BusinessGeneralFinances;
