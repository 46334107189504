import { AiOutlineCheck } from "react-icons/ai";

const SubOption = ({ heading, check, onClick, filled }) => {
	return (
		<div
			onClick={onClick}
			className="w-100 d-flex flex-row align-items-center option-left sub-option-left mb-1"
			role="button"
		>
			{check && <AiOutlineCheck color="green" />}
			{!check && (
				<input
					disabled={true}
					checked={filled}
					className="form-check-input"
					type="radio"
					id="agreeCheckbox"
				/>
			)}
			<small className="left-portion-option mt-1">{heading}</small>
		</div>
	);
};

export default SubOption;
