import { BASEURL } from '../../utilites';
import axios from 'axios';
import { INVOICE_ACTION_TYPE } from './Type';
import { user } from '../user/action';

//  Function To Create Customer for Invoice
const createCustomer = (customerObj, stopUpdation) => {
	// console.log("BEFORE>>>",customerObj);
	if (
		!customerObj?.phone ||
		customerObj?.phone === '' ||
		customerObj?.phone === null ||
		customerObj?.phone === 'null' ||
		customerObj?.phone === undefined ||
		customerObj?.phone === 'undefined'
	) {
		customerObj.phone = '2254525005';
	}

	// console.log("AFTER>>>",customerObj);
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/stripe/createcustomer`, customerObj)
			.then((response) => {
				if (!stopUpdation) {
					dispatch(user.ReGetUserData());
				}

				dispatch({
					type: INVOICE_ACTION_TYPE.createCustomer,
					status: true,
					message: response.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: INVOICE_ACTION_TYPE.createCustomer,
					status: false,
					message:
						error.code === 'ERR_NETWORK'
							? error?.message
							: error?.response?.data?.error?.raw?.message
							? error?.response?.data?.error?.raw?.message
							: error?.response?.data?.error,
				});
			});
	};
};

//  Function To Reset Create Customer
const resetCreateCustomer = () => {
	return async (dispatch) => {
		dispatch({
			type: INVOICE_ACTION_TYPE.createCustomerIdle,
		});
	};
};

//  Function To Create for Invoice
const createInvoice = (invoiceObj, uuid) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/stripe/createinvoice`, invoiceObj)
			.then((response) => {
				// function to get updated invoices if have uuid then get specific for that otherwise for all
				if (uuid) {
					dispatch(getLeadInvoices(uuid));
				} else {
					dispatch(getInvoices());
				}
				dispatch({
					type: INVOICE_ACTION_TYPE.createInvoice,
					status: true,
					message: response.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: INVOICE_ACTION_TYPE.createInvoice,
					status: false,
					message:
						error.code === 'ERR_NETWORK'
							? error?.message
							: error?.response?.data?.error?.raw?.message
							? error?.response?.data?.error?.raw?.message
							: error?.response?.data?.error,
				});
			});
	};
};

//  Function To Reset Create Customer
const resetCreateInvoice = () => {
	return async (dispatch) => {
		dispatch({
			type: INVOICE_ACTION_TYPE.createInvoiceIdle,
		});
	};
};

// Function to Update Invoice
const updateInvoice = (invoiceObj, uuid) => {
	return async (dispatch) => {
		axios
			.patch(`${BASEURL}/api/stripe/updateinvoice`, invoiceObj)
			.then((response) => {
				// function to get updated invoices if have uuid then get specific for that otherwise for all
				if (uuid) {
					dispatch(getLeadInvoices(uuid));
				} else {
					dispatch(getInvoices());
				}
				dispatch({
					type: INVOICE_ACTION_TYPE.createInvoice,
					status: true,
					message: response.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: INVOICE_ACTION_TYPE.createInvoice,
					status: false,
					message:
						error.code === 'ERR_NETWORK'
							? error?.message
							: error?.response?.data?.error?.raw?.message
							? error?.response?.data?.error?.raw?.message
							: error?.response?.data?.error,
				});
			});
	};
};

//  Function To Create for Invoice
const removeInvoice = (invoiceObj, uuid) => {
	return async (dispatch) => {
		axios
			.delete(`${BASEURL}/api/stripe/deleteinvoice`, { data: invoiceObj })
			.then((response) => {
				// function to get updated invoices if have uuid then get specific for that otherwise for all
				if (uuid) {
					dispatch(getLeadInvoices(uuid));
				} else {
					dispatch(getInvoices());
				}
				dispatch({
					type: INVOICE_ACTION_TYPE.createInvoice,
					status: true,
					message: response.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: INVOICE_ACTION_TYPE.createInvoice,
					status: false,
					message:
						error.code === 'ERR_NETWORK'
							? error?.message
							: error?.response?.data?.error?.raw?.message
							? error?.response?.data?.error?.raw?.message
							: error?.response?.data?.error,
				});
			});
	};
};
//  Function To Create for Invoice
const createSubscription = (invoiceObj) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/stripe/createsubscription`, invoiceObj)
			.then((response) => {
				dispatch({
					type: INVOICE_ACTION_TYPE.createSubscription,
					status: true,
					message: response.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: INVOICE_ACTION_TYPE.createSubscription,
					status: false,
					message:
						error.code === 'ERR_NETWORK'
							? error?.message
							: error?.response?.data?.error?.raw?.message
							? error?.response?.data?.error?.raw?.message
							: error?.response?.data?.error,
				});
			});
	};
};

//  Function To Reset Create Customer
const resetcreateSubscription = () => {
	return async (dispatch) => {
		dispatch({
			type: INVOICE_ACTION_TYPE.createSubscriptionIdle,
		});
	};
};

//  Function To Get Invoice
const getInvoices = (keyword, status, dateCreated, page = 1, per_page = 10) => {
	return async (dispatch) => {
		dispatch({ type: INVOICE_ACTION_TYPE.invoicesFetchingStart });
		// Get all invoices
		axios
			.get(`${BASEURL}/api/invoices`, {
				params: { keyword, status, dateCreated, page, per_page },
			})
			.then((response) => {
				dispatch({
					type: INVOICE_ACTION_TYPE.invoices,
					payload: {
						data: response.data.data.invoices,
						count: response.data.data.count,
						status: true,
						loaded: true,
						fetching: false,
						message: '',
					},
				});
			})
			.catch((error) => {
				dispatch({
					type: INVOICE_ACTION_TYPE.invoices,
					payload: {
						data: [],
						status: false,
						message:
							error.code === 'ERR_NETWORK'
								? error?.message
								: error?.response?.data?.error?.raw?.message
								? error?.response?.data?.error?.raw?.message
								: error?.response?.data?.error,
					},
				});
			});
	};
};

//  Function To Get Invoice
const getLeadInvoices = (uuid,job_uuid) => {
	return async (dispatch) => {
		dispatch({ type: INVOICE_ACTION_TYPE.invoicesFetchingStart });
		// Get invoices for specific lead
		axios
		    .get(`${BASEURL}/api/invoices/lead?uuid=${uuid}&job_uuid=${job_uuid}`)
			.then((response) => {
				dispatch({
					type: INVOICE_ACTION_TYPE.invoices,
					payload: { data: response.data.data.invoices, status: true, message: '' },
				});
			})
			.catch((error) => {
				dispatch({
					type: INVOICE_ACTION_TYPE.invoices,
					payload: {
						data: [],
						status: false,
						message:
							error.code === 'ERR_NETWORK'
								? error?.message
								: error?.response?.data?.error?.raw?.message
								? error?.response?.data?.error?.raw?.message
								: error?.response?.data?.error,
					},
				});
			});
	};
};

const getJobInvoices = (uuid) => {
	return async (dispatch) => {
		dispatch({ type: INVOICE_ACTION_TYPE.invoicesFetchingStart });
		// Get invoices for specific lead
		axios
			.get(`${BASEURL}/api/invoices/job?job_uuid=${uuid}`)
			.then((response) => {
				dispatch({
					type: INVOICE_ACTION_TYPE.invoices,
					payload: { data: response.data.data.invoices, status: true, message: '' },
				});
			})
			.catch((error) => {
				dispatch({
					type: INVOICE_ACTION_TYPE.invoices,
					payload: {
						data: [],
						status: false,
						message:
							error.code === 'ERR_NETWORK'
								? error?.message
								: error?.response?.data?.error?.raw?.message
								? error?.response?.data?.error?.raw?.message
								: error?.response?.data?.error,
					},
				});
			});
	};
};

//  Function To Pay Invoice
const payInvoice = (invoiceObj) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/stripe/invoicepay`, invoiceObj)
			.then((response) => {
				dispatch({
					type: INVOICE_ACTION_TYPE.payInvoice,
					status: true,
					message: response.data.data,
				});
				dispatch(getInvoices());
			})
			.catch((error) => {
				console.log(error?.response?.data?.error);
				dispatch({
					type: INVOICE_ACTION_TYPE.payInvoice,
					status: false,
					message:
						error.code === 'ERR_NETWORK'
							? error?.message
							: error?.response?.data?.error?.raw?.message
							? error?.response?.data?.error?.raw?.message
							: error?.response?.data?.error
							? error?.response?.data?.error
							: {},
				});
			});
	};
};

//  Function To Pay Invoice
const downloadInvoice = (invoiceId) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/stripe/invoice-download/${invoiceId}`)
			.then((response) => {
				dispatch({
					type: INVOICE_ACTION_TYPE.downloadInvoice,
					status: true,
					message: response.data.data,
				});
			})
			.catch((error) => {
				console.log(error?.response?.data?.error);
				dispatch({
					type: INVOICE_ACTION_TYPE.downloadInvoice,
					status: false,
					message:
						error.code === 'ERR_NETWORK'
							? error?.message
							: error?.response?.data?.error?.raw?.message
							? error?.response?.data?.error?.raw?.message
							: error?.response?.data?.error
							? error?.response?.data?.error
							: {},
				});
			});
	};
};

//  Function To Reset PayInvoice
const resetDownloadInvoice = () => {
	return async (dispatch) => {
		dispatch({
			type: INVOICE_ACTION_TYPE.downloadInvoiceIdle,
		});
	};
};

//  Function To Reset PayInvoice
const resetPayInvoice = () => {
	return async (dispatch) => {
		console.log('RESET>>>>>>>>');
		dispatch({
			type: INVOICE_ACTION_TYPE.payInvoiceIdle,
		});
	};
};

const resetGetInvoice = () => {
	return async (dispatch) => {
		dispatch({
			type: INVOICE_ACTION_TYPE.invoiceStatusIdle,
		});
	};
};

//  Function To CREATE VIDEO ROOM
const invoicePaid = (payload) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/stripe/transfer-fund`, payload)
			.then((response) => {
				dispatch({type: INVOICE_ACTION_TYPE.invoicePaid,payload: {
					status: true,
					data: response.data,
				}, });
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: INVOICE_ACTION_TYPE.invoicePaid,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};

// FUNCTION TO HANDLE MEETING API ERRORS
const handleMeetingError = (type, error) => {
	if (type === "simple") {
		return error?.code === "ERR_NETWORK"
			? error?.message
			: error?.response?.data?.error?.message
				? "Something Went Wrong"
				: error?.response?.data?.error;
	}
};
export const invoice = {
	createCustomer,
	resetCreateCustomer,
	createInvoice,
	updateInvoice,
	removeInvoice,
	resetCreateInvoice,
	createSubscription,
	resetcreateSubscription,
	getInvoices,
	payInvoice,
	resetPayInvoice,
	resetGetInvoice,
	getLeadInvoices,
	getJobInvoices,
	downloadInvoice,
	resetDownloadInvoice,
	invoicePaid
};
