import React from 'react';
import Keap from './integrations/Keaps';
import Calendly from './integrations/Calendly';
import OutlookCalendar from './integrations/OutlookCalendar';
import GoogleCalendar from "./integrations/GoogleCalendar";


const Integrations = ({ user, keapVerification, calendlyVerification ,outlookCalendarVerification,googleCalendarVerification}) => {
	return (
		<div className='clearfix p-4'>
			<div className='clearfix text-dark text-opacity-50 mb-5'>
				<Keap user={user} keapVerification={keapVerification} />
				<Calendly user={user} calendlyVerification={calendlyVerification} />
				<OutlookCalendar user={user} outlookCalendarVerification={outlookCalendarVerification} />
        <GoogleCalendar user={user} googleCalendarVerification={googleCalendarVerification}/>
			</div>
		</div>
	);
};

export default Integrations;
