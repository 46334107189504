import React, { useState } from 'react';
import Input from '../../fields/Input';
import DropDown from '../../fields/DropDown';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import MultipleCheckLabelSelector from '../../fields/MultipleCheckLabelSelector';

const DependentInformation = ({ dependentList, setDependentList }) => {
	const { prefix, relationShipType, DependentPersonAppliedOptions, durationLiveWith } =
		GLOBAL_VARIABLES;


	const [firstName, setFirstName] = useState('');
	const [middleName, setMiddleName] = useState('');
	const [lastName, setLastName] = useState('');
	const [ssnitin, setSsnitin] = useState('');
	const [dob, setDob] = useState('');
	const [amountPaid, setAmountPaid] = useState('');

	// For dropdowns, initialize with null or your initial value.
	const [myprefix, setMyPrefix] = useState(null);
	const [Relationship, setRelationship] = useState(null);
	const [duration, setDuration] = useState(null);
	// For Choice Questions
	const [appliedOptions, setAppliedOptions] = useState([]);
	const [errors, setErrors] = useState({}); // Track errors for form fields
	
	const handleAppliedOptions = (option) => {
		const localOptions = appliedOptions.slice();
		if (localOptions?.includes(option)) {
			setAppliedOptions(localOptions.filter((data) => data !== option));
		} else {
			localOptions.push(option);
			setAppliedOptions(localOptions);
		}
	};

	function resetFormStates() {
		setFirstName('');
		setMiddleName('');
		setLastName('');
		setSsnitin('');
		setDob('');
		setAmountPaid('');
		setMyPrefix(null);
		setRelationship(null);
		setDuration(null);
		setAppliedOptions([]);
		setErrors({});
	}

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		// Add more validation rules as needed for other fields
		if (appliedOptions?.length === 0) {
			newErrors.AnswerOtherQuestions = 'Please Select Required Option';
		}

		setErrors(newErrors)
		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleForm = () => {
		let errors = check_Validity();
		if (errors) return;
		window.scrollTo(0, 0);

		let FormObj = {
			dob,
			firstName,
			ssnitin,
			lastName,
			myprefix,
			middleName,
			amountPaid,
			Relationship,
			duration,
			appliedOptions,
		};

		let OldDependentList = dependentList;
		OldDependentList.push(FormObj);
		setDependentList(OldDependentList);
		resetFormStates();
	};

	return (
		<div className="my-2">
			<table className="table">
				<thead>
					<tr>
						<th scope="col">Name</th>
						<th scope="col">Dob</th>
						<th scope="col">SSN</th>
						<th scope="col">Relationship</th>
					</tr>
				</thead>
				<tbody>
					{dependentList &&
						dependentList?.map((data) => {
							console.log(data?.Relationship);
							return (
								<tr>
									<td>{data?.firstName}</td>
									<td>{data?.dob}</td>
									<td>{data?.ssnitin}</td>
									<td>{data?.Relationship?.label}</td>
								</tr>
							);
						})}
				</tbody>
			</table>
			<form
				className="col-md-11 bg-light mt-3 shadow-sm mx-4 p-3"
				onSubmitCapture={(e) => {
					e.preventDefault();
					handleForm();
				}}
			>
				<small className="ml-4 fw-bold">Dependent Name</small>
				<hr className="m-0" />
				<div className="mt-3 row justify-content-between px-3 custom-left-form">
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="firstName"
							value={firstName}
							placeholder="First Name"
							fn={setFirstName}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="middleName"
							value={middleName}
							placeholder="Middle Initial"
							fn={setMiddleName}
							required={false}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="lastName"
							value={lastName}
							placeholder="Last Name"
							fn={setLastName}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<DropDown
							options={prefix}
							setValue={(e) => {
								setMyPrefix(e);
							}}
							value={myprefix}
							label={'Select Prefix'}
							required={false}
						/>
					</div>
				</div>
				<small className="ml-4 fw-bold">TaxPayer Other Information</small>
				<hr className="m-0" />
				<div className="mt-3 row justify-content-between px-3 mb-3 custom-left-form">
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="ssnitin"
							placeholder="SSN/ITIN"
							value={ssnitin}
							fn={setSsnitin}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="dob"
							value={dob}
							fn={setDob}
							placeholder="Date Of Birth (MM-DD-YYYY)"
							mask={'99-99-9999'}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<DropDown
							options={relationShipType}
							value={Relationship}
							label={'Relationship'}
							setValue={(e) => setRelationship(e)}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							type="text"
							name="amountPaid"
							placeholder="Amount paid to care provider"
							value={amountPaid}
							fn={setAmountPaid}
							mask={'99999999'}
						/>
					</div>
					<div className="col-md-12 mb-3">
						<DropDown
							options={durationLiveWith}
							value={duration}
							label={'How many year did dependent live with taxpayer?'}
							setValue={(e) => setDuration(e)}
						/>
					</div>
					<MultipleCheckLabelSelector
						col={12}
						mapData={DependentPersonAppliedOptions}
						selectedOptions={appliedOptions}
						handleAppliedOptions={handleAppliedOptions}
						label={'Select all that apply to this person'}
					/>
				</div>
				{Object.keys(errors)
					?.map((key) => {
						return errors[key];
					})
					?.map((data, index) => {
						return (
							<>
								<small className="text-primary" key={index}>
									{data}*
								</small>
								<br />
							</>
						);
					})}
				<div className="container-fluid content-row pb-4">
					<div className="d-flex align-items-center justify-content-center row">
						<Button
							mainClass="btn btn-primary custon-button-text   w-100"
							buttonText="Add Dependent"
							wrapperClass={'w-25 custon-button  mt-4'}
							type="submit"
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default DependentInformation;
