import { moneyFormatter } from '../../../utilites';

export default function JobPayments({ cost,paidAmount,remaining }) {
	return (
		<div className='clearfix'>
			<div className='progress bg-light'>
				<div
					className='progress-bar bg-primary'
					role='progressbar'
					style={{
						width: `${(paidAmount / cost) * 100}%`,
					}}
					aria-valuenow={paidAmount}
					aria-valuemin='0'
					aria-valuemax={cost - 0}
				/>
				<div
					className='progress-bar bg-secondary-700'
					role='progressbar'
					style={{
						width: `${0}%`,
					}}
					aria-valuenow={0}
					aria-valuemin='0'
					aria-valuemax={cost - paidAmount}
				/>
			</div>
			<ul className='list-group mt-3'>
				<li className='list-group-item border-0 px-0 py-1'>
					<span
						className='d-inline-flex bg-primary rounded-circle me-1'
						style={{
							width: 10,
							height: 10,
						}}
					/>
					Deposit
					<span className='float-end'>{moneyFormatter(paidAmount)}</span>
				</li>
				<li className='list-group-item border-0 px-0 py-1'>
					<span
						className='d-inline-flex bg-secondary-700 rounded-circle me-1'
						style={{
							width: 10,
							height: 10,
						}}
					/>
					Balance
					<span className='float-end'>{moneyFormatter(remaining > 0 ? remaining : 0)}</span>
				</li>
				<li className='list-group-item border-0 px-0 py-1'>
					<span
						className='d-inline-flex bg-light rounded-circle me-1'
						style={{
							width: 10,
							height: 10,
						}}
					/>
					Project price
					<span className='float-end'>{moneyFormatter(cost)}</span>
				</li>
			</ul>
		</div>
	);
}
