import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import allActions from '../../Store/action';
import { BASEURL, validate, validatePassword, nameRexExp, emailRegExp } from '../../utilites';
import Input from './fields/Input';
import Checkbox from './fields/Checkbox';
import Button from './fields/Button';
import Select from './fields/Select';

export default function AddUserForm({ reloadUsers, setAlert }) {
	const [{ roles }] = useSelector((state) => [state.dashboard]);
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [repeatPassword, setRepeatPassword] = useState('');
	const [generatePassword, setGeneratePassword] = useState([]);
	const [role, setRole] = useState('');

	const [errors, setErrors] = useState({
		firstname: false,
		lastname: false,
		email: false,
		password: false,
		repeatPassword: false,
		general: '',
	});

	const dispatch = useDispatch();

	const button = useRef();

	useEffect(() => {
		dispatch(allActions.dashboard.getUserRoles());
	}, [dispatch]);

	// function to handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();

		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });

		axios
			.post(`${BASEURL}/api/users/create`, {
				firstname,
				lastname,
				email,
				password,
				generatePassword: generatePassword.length > 0 ? true : false,
				role,
			})
			.then(() => {
				button.current.disabled = false;
				reloadUsers();
				// Close Modal
				document.querySelector('.addUserModalClose').click();
				setAlert('User has been added successfully!');
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};

	return (
		<form className="usersform needs-validation" onSubmit={handleSubmit}>
			{roles && (
				<div className="row">
					<div className="mb-3">
						<Select value={role} name="role" placeholder="User Role" fn={setRole}>
							<option value="">Select Role</option>
							{roles?.map((r) => (
								<option key={r.id} value={r.id}>
									{r.display_name}
								</option>
							))}
						</Select>
					</div>
				</div>
			)}
			<div className="row">
				<div className="mb-3">
					<Input
						type="text"
						value={firstname}
						name="firstname"
						placeholder="First Name"
						fn={setFirstname}
						onLeave={validate('firstname', firstname, nameRexExp, errors, setErrors)}
						hasValidationError={errors.firstname}
						showLabel={false}
					/>
				</div>
			</div>
			<div className="row">
				<div className="mb-3">
					<Input
						type="text"
						value={lastname}
						name="lastname"
						placeholder="Last Name"
						fn={setLastname}
						onLeave={validate('lastname', lastname, nameRexExp, errors, setErrors)}
						hasValidationError={errors.lastname}
						showLabel={false}
					/>
				</div>
			</div>
			<div className="row">
				<div className="mb-3">
					<Input
						type="email"
						value={email}
						name="email"
						placeholder="Email Address"
						fn={setEmail}
						onLeave={validate('email', email, emailRegExp, errors, setErrors)}
						hasValidationError={errors.email}
						showLabel={false}
					/>
				</div>
			</div>
			{generatePassword.length === 0 && (
				<Fragment>
					<div className="row">
						<div className="mb-3">
							<Input
								type="password"
								value={password}
								name="password"
								placeholder="Password"
								wrapperClass="position-relative"
								fn={setPassword}
								secure={true}
								onLeave={validatePassword('password', password, repeatPassword, errors, setErrors)}
								hasValidationError={errors.password}
								showLabel={false}
							/>
						</div>
					</div>
					<div className="row">
						<div className="mb-3">
							<Input
								type="password"
								value={repeatPassword}
								name="repeat-password"
								placeholder="Repeat Password"
								wrapperClass="position-relative"
								fn={setRepeatPassword}
								secure={true}
								onLeave={validatePassword(
									'repeatPassword',
									password,
									repeatPassword,
									errors,
									setErrors
								)}
								hasValidationError={errors.repeatPassword}
								showLabel={false}
							/>
						</div>
					</div>
				</Fragment>
			)}
			<div className="row">
				<div className="mb-3 text-start">
					<Checkbox
						name="generate-password"
						state={generatePassword}
						value={'yes'}
						label={'Auto generate password and send it to the email above.'}
						fn={setGeneratePassword}
						id={`generate-password`}
						required={false}
					/>
				</div>
			</div>
			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mb-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 ? ' disabled' : ''
				}`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-3"
				buttonText="Add User"
			/>
		</form>
	);
}
