export default function Preloader({ wrapperClass = 'vh-100' }) {
	return (
		<div
			className={`d-flex align-items-center justify-content-center w-100${` ${wrapperClass}`}`}
		>
			<div
				className="spinner-border text-primary"
				style={{ width: '5rem', height: '5rem' }}
				role="status"
			>
				<span className="visually-hidden">Loading...</span>
			</div>
		</div>
	);
}
