import { TASKS_ACTION_TYPE } from './Type';

const DEFAULT_STATE = {
	tasks: { data: [], count: 0, fetching: false, loaded: false, error: 'idle', status: 'idle' },
	createTasks: { message: '', status: 'idle', data: {} },
	editTask: { message: '', status: 'idle', data: {} },


};

const INITIAL_STATE = {
	...DEFAULT_STATE,
};

const tasks = (state = INITIAL_STATE, action) => {
	if (action.type === TASKS_ACTION_TYPE.TASKS_FETCHING_START) {
		return {
			...state,
			tasks: { ...state.tasks, fetching: true },
		};
	} else if (action.type === TASKS_ACTION_TYPE.TASKS) {
		return {
			...state,
			tasks: {
				data: action.payload.data,
				count: action.payload.count,
				status: action.payload.status,
				error: action.payload.error,
				fetching: false,
				loaded: true,
			},
		};
	}else if (action.type === TASKS_ACTION_TYPE.CREATE_TASKS) {
		return {
			...state,
			createTasks: action.payload,
		};
	}else if (action.type === TASKS_ACTION_TYPE.EDIT_TASK) {
		return {
			...state,
			editTask: action.payload,
		};
	}
	if (action.type === TASKS_ACTION_TYPE.CREATE_TASKS_IDLE) {
		return {
			...state,
			createTasks: DEFAULT_STATE.createTasks,
		};
	}

	return state;
};

export default tasks;
