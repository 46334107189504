import React from "react";
import ContactInfoForm from "../../forms/ContactInfoForm";

const ContactInfo = ({active,setActive}) => {
  return (
    <div>
      <div className="w-full  border-grey rounded px-2 py-2">
        <ContactInfoForm  active={active} setActive={setActive} />
      </div>
    </div>
  );
};

export default ContactInfo;
