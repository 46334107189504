import React, { useState, useEffect } from "react";
import Button from "./fields/Button";
import { useDispatch, useSelector } from "react-redux";
import Message from "./fields/Message";
import allActions from "../../Store/action";
import OtpInput from "react-otp-input";
export default function OtpForm() {
  const dispatch = useDispatch();
  const [methodPhone, setmethodPhone] = useState(true);
  const [message, setMessage] = useState({ show: false });
  const [{ sendOtp, messageSend, verifyOtp, messageVerify }, user] =
    useSelector((state) => [state.factAuth, state.user]);
  const [otp, setOtp] = useState("");
  const OtpSource = methodPhone
    ? localStorage.getItem("phoneNo")
    : localStorage.getItem("email");
  const OtpConditionCheck =
    OtpSource !== null &&
    OtpSource !== undefined &&
    OtpSource !== "null" &&
    OtpSource.length > 5
      ? OtpSource
      : "";
  const otplength = 6;
  useEffect(() => {
    if (!sendOtp) {
      setMessage({
        show: true,
        type: "Error",
        text: messageSend,
      });
    }
    if (sendOtp === true) {
      setMessage({
        show: true,
        type: "Success",
        text: messageSend,
      });
    }
  }, [sendOtp, messageSend]);

  useEffect(() => {
    if (!verifyOtp) {
      setMessage({
        show: true,
        type: "Error",
        text: messageVerify,
      });
    }
    if (verifyOtp === true) {
      if (user.isRegular) {
        window.open("/dashboard/messages", "_self");
      } else {
        window.open("/dashboard/", "_self");
      }
      allActions.user.ReGetUserData();
    }
  }, [verifyOtp, messageVerify, user]);
  useEffect(() => {
    reset_mesage();
  }, []);
  useEffect(() => {
    if (user.loginStatus === true) {
      dispatch(allActions.user.ReGetUserData());
    }
    dispatch(allActions.factAuth.resetSendOtp());
    dispatch(allActions.factAuth.resetVerifyOtp());
  }, [user, dispatch]);

  // Functions To Handle Method Chnage For OTP
  const handleAgreeMethodChange = () => {
    setmethodPhone(true);
  };
  const handleDeclineMethodChange = () => {
    setmethodPhone(false);
  };

  // Store States
  const reset_mesage = () => {
    setMessage({ show: false });
  };

  // function to handle form form submission to Send OTP
  const handleSubmit = (e) => {
    // Reset User State Values
    reset_mesage();
    e.preventDefault();
    if (otp.length !== otplength) {
      setMessage({
        show: true,
        type: "Error",
        text: `Please Enter ${otplength} length OTP.`,
      });
      return;
    }
    let payload = {
      otp,
    };
    dispatch(allActions.factAuth.resetVerifyOtp());
    dispatch(allActions.factAuth.verifyOtp(payload));
  };
  const onConfirm = () => {
    // Reset User State Values
    dispatch(allActions.factAuth.resetSendOtp());
    reset_mesage();
    setOtp("");
    if (OtpConditionCheck === "") {
      setMessage({
        show: true,
        type: "Error",
        text: "Invalid Input",
      });
      return;
    }
    let payload;
    if (methodPhone) {
      payload = {
        method: "phone",
        phoneNumber: OtpSource,
      };
    } else {
      payload = {
        method: "email",
        email: OtpSource,
      };
    }
    dispatch(allActions.factAuth.sendOtp(payload));
  };

  function maskSensitiveInfo(input) {
    if (input === "") {
      return "";
    }

    if (input.length < 4) {
      var maskedNumber = input.substr(-2).padStart(input.length, "*");
      return maskedNumber;
    }
    if (isNaN(input) && input.includes("@")) {
      // Input is an email address
      var parts = input.split("@");
      var username = parts[0];
      var domain = parts[1];

      var maskedUsername = username.charAt(0) + "*".repeat(username.length - 1);
      var maskedDomain = domain.charAt(0) + "*".repeat(domain.length - 1);

      return maskedUsername + "@" + maskedDomain;
    } else {
      // Input is a number
      var maskedNumber1 = input.substr(-4).padStart(input.length, "*");
      return maskedNumber1;
    }
  }
  return (
    <div>
      <div className="mb-3">
        {message.show ? <Message message={message} /> : <></>}
      </div>
      {sendOtp === true ? (
        <form className="OtpForm needs-validation" onSubmit={handleSubmit}>
          <h4 className="fw-500">Two-Factor Authuntication</h4>
          <p className="text-muted">
            Please Confirm your account by entering the authuntication code send
            to
            <p className="fw-bold text-black">
              {maskSensitiveInfo(OtpConditionCheck)}
            </p>
          </p>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={otplength}
            renderSeparator={<span>-</span>}
            renderInput={(props) => (
              <input
                {...props}
                className="py-2 w-25 m-2 lead color-black no-outline otpinput"
              />
            )}
            inputStyles={{
              borderRadius: 3,
              border: "1px solid purple",
              backgroundColor: "#fff",
            }}
          />
          <p className="react-calendar__tile align-start">
            It May Take Upto 5 Min To Recieve Otp. Please Wait Before To Send
            Otp?{" "}
            <p
              onClick={() => {
                reset_mesage();
                dispatch(allActions.factAuth.resetSendOtp());
              }}
              className="text-decoration-underline fw-bold text-primary resend-link"
            >
              Resend New Code.
            </p>
          </p>
          <Button
            mainClass="btn btn-dark btn-lg px-4 "
            wrapperClass="clearfix d-grid text-center mt-3"
            buttonText="Submit"
          />
        </form>
      ) : (
        <form className="OtpForm needs-validation" onSubmit={handleSubmit}>
          <h4 className="fw-500">Two-Factor Authuntication</h4>
          <p className="text-muted">
            Please Confirm Your Credentials To Send Otp To
            <p className="fw-bold text-black">
              {maskSensitiveInfo(OtpConditionCheck)}
            </p>
          </p>
          <p className="fw-bold text-muted ">You Want To Get Otp On</p>
          <div className="d-flex align-items-center justify-content-center row">
            <div className="col-auto">
              <div className="form-check form-check-inline">
                <label className="form-check-label" htmlFor="agreeCheckbox">
                  <input
                    className="form-check-input custom-checkbox"
                    type="checkbox"
                    id="agreeCheckbox"
                    checked={methodPhone}
                    onChange={handleAgreeMethodChange}
                  />
                  Phone
                </label>
              </div>
            </div>
            <div className="col-auto">
              <div className="form-check form-check-inline">
                <label className="form-check-label" htmlFor="dontAgreeCheckbox">
                  <input
                    className="form-check-input custom-checkbox"
                    type="checkbox"
                    id="dontAgreeCheckbox"
                    checked={!methodPhone}
                    onChange={handleDeclineMethodChange}
                  />
                  Email
                </label>
              </div>
            </div>
          </div>
          <Button
            mainClass="btn btn-dark btn-lg px-4"
            wrapperClass="clearfix d-grid text-center mt-3"
            buttonText="Send Otp"
            type={"button"}
            callback={() => onConfirm("send")}
          />
        </form>
      )}
    </div>
  );
}
