import { convertTimeIntoSselectedTZ, timezoneShortFormatter, getAppointmentStatusClass, getAppointmentStatus } from '../utilites'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { BASEURL } from '../utilites'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import { BsCameraVideo, BsPhone } from 'react-icons/bs'
import SweetAlert2 from 'react-sweetalert2'
import allActions from '../Store/action'
import { ImParagraphLeft } from 'react-icons/im'
import UserAvatar from '../components/UserAvatar'
import { useDispatch, useSelector } from 'react-redux'
import { AddToCalendarButton } from 'add-to-calendar-button-react'
import Modal from '../components/Dashboard/Modal'
import LoginModalForm from '../components/forms/LoginModalForm'
import Message from '../components/forms/fields/Message'

const AppointmentDetails = () => {
	const { id } = useParams()
	const [{ LoginData }, { getZoomMeeting }] =
		useSelector((state) => [state.user, state.call]);
	const [appointment, setAppointment] = useState({})
	const [swalProps, setSwalProps] = useState({});
	const [message, setMessage] = useState({ show: false })
	//const firstUpdate = useRef(true);
	const dispatch = useDispatch();
	const endDate = moment(appointment?.end_date).add(15, 'minute');
	const currentDate = moment();

	useEffect(() => {
		if (getZoomMeeting.data) {
			window.open(getZoomMeeting.data.join_url, '_blank');
		}
	}, [getZoomMeeting.data])

	const getAppointment = async () => {
		changeLoading(true)
		try {
			const resp = await axios.get(`${BASEURL}/api/appointments/unauthorized/${id}`, { id })
			setAppointment(resp.data.data)
			changeLoading(false)
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		getAppointment()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const joinMeeting = (room_id) => {
		dispatch(allActions.call.getZoomMeeting(room_id));
		changeLoading(true)
	};

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};
	const editAppointmentUserStatus = (id, status_id) => {
		changeLoading(true)
		axios
			.put(`${BASEURL}/api/appointments/change-status/${id}/${status_id}`)
			.then(() => {
				setMessage({
					show: true,
					type: "Success",
					text: 'Appointment status succesfully updated'
				  });
			setTimeout(() => {
				setMessage({ show: false })
			}, 2000)
				getAppointment()
			})
	};

	const handleStatusClick = (id) => {
		setSwalProps((prev) => ({
			...prev,
			[id]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				confirmButtonText: 'Confirm',
				denyButtonText: 'Reject',
				didClose: () => setSwalProps((prev) => ({ ...prev, [id]: { show: false } })),
				onResolve: (event) => {
					if (event.isConfirmed) {
						editAppointmentUserStatus(id, 1);  
					} else if (event.isDenied) {
						editAppointmentUserStatus(id, 2); 
					}
				},
			},
		}));
	};
	return (
		<>
			{Object.keys(appointment).length && <div className="clearfix p-4 d-flex justify-content-center flex-column align-items-center">
				<div className='shadow-sm p-5 w-50 bg-white radius-sm rounded'>
					<div className="row">
							 {message.show ? <Message message={message} /> : <></>} 
						<div className="col-9">
							<h5 className="fw-bold">{appointment?.title}</h5>
							<p style={{ fontSize: 14 }}>
								{convertTimeIntoSselectedTZ(
									appointment?.timezone,
									moment.tz.guess(),
									moment.utc(appointment?.start_date),
									'lll'
								)}{' '}
								{timezoneShortFormatter(moment.tz.guess())}
							</p>
						</div>
						<div className="col d-flex justify-content-end" style={{ gap: 5 }}>
							{appointment.creator_user_id ? (
								<button
									className="btn btn-sm btn-outline-primary rounded-circle"
									style={{ width: 30, height: 30 }}
								// onClick={() => handleDeleteAppointmentClick(appointment.id)}
								>
									{/* <RiDeleteBin5Line /> */}
									<SweetAlert2 {...swalProps[`ad-${appointment.id}`]} />
								</button>
							) : null}
							<div className="dropdown-center">
								{/* <button
							className="btn btn-sm btn-outline-primary rounded-circle"
							style={{ width: 30, height: 30 }}
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<BsThreeDotsVertical />
						</button> */}
								<ul className="dropdown-menu border-light">
									{/* <li>
								<Link
									to="#"
									className={`dropdown-item${
									
										appointment.users.some((e) => e.appointmentuser.status_id === 2)
											? ' disabled'
											: ''
									}`}
									data-bs-toggle="modal"
									data-bs-target="#openAppointmentRescheduleModal2"
								>
									Reshcedule
								</Link>
							</li> */}
								</ul>
							</div>
						</div>
					</div>
					<div className="row text-black mb-4">
						<div className="col-12 d-flex align-items-center mb-3" style={{ gap: 10 }}>
							<span>{appointment.room_id ? <BsCameraVideo /> : <BsPhone />}</span>
							<p className="m-0">{appointment?.room_id ? 'Video Appointment' : 'Phone Appointment'}</p>
						</div>
						{appointment.details ? (
							<div className="col-12 d-flex mb-3" style={{ gap: 10 }}>
								<span>
									<ImParagraphLeft />
								</span>
								<p className="m-0">{appointment.details}</p>
							</div>
						) : null}
						{appointment ? (
							<AddToCalendarButton
								name={appointment?.title}
								description={appointment?.details}
								hideBranding={true}
								options={[
									'Google',
									'Yahoo',
									'Apple',
									'Microsoft365',
									'MicrosoftTeams',
									'Outlook.com',
									'iCal',
								]}
								location={appointment.room_id ? 'Zoom Meeting' : 'Phone Call'}
								startDate={convertTimeIntoSselectedTZ(
									appointment?.timezone,
									moment.tz.guess(),
									moment.utc(appointment.start_date),
									'YYYY-MM-DD'
								)}
								endDate={convertTimeIntoSselectedTZ(
									appointment?.timezone,
									moment.tz.guess(),
									moment.utc(appointment.end_date),
									'YYYY-MM-DD'
								)}
								startTime={convertTimeIntoSselectedTZ(
									appointment?.timezone,
									moment.tz.guess(),
									moment.utc(appointment.start_date),
									'HH:mm'
								)}
								endTime={convertTimeIntoSselectedTZ(
									appointment?.timezone,
									moment.tz.guess(),
									moment.utc(appointment.end_date),
									'HH:mm'
								)}
								timeZone={moment.tz.guess() || 'America/Los_Angeles'}
							></AddToCalendarButton>
						) : null}
					</div>
					<div className="clearfix text-black mb-4">
						<p className="m-0">
							{1 + appointment.users.length} guest
							{appointment.users.length > 0 ? 's' : ''}
						</p>
						<ul className="list-group list-group-flush">
							<li className="list-group-item">
								<div className="row align-items-center">
									<div className="col-1">
										<UserAvatar
											avatar={appointment.creator.avatar}
											alt={`${appointment.creator.firstname} ${appointment.creator.lastname}`}
											className="img-fluid object-fit-cover rounded-circle"
										/>
									</div>
									<div className="col">
										<p className="text-black m-0">
											{`${appointment?.creator?.firstname} ${appointment?.creator?.lastname}`}
										</p>
										<p className="text-muted m-0">Organizer</p>
									</div>
									<div className="col d-flex justify-content-end">
										<span className="text-primary">Confirmed</span>
									</div>
								</div>
							</li>
							{appointment.users.length > 0 ? (
								<>
									{appointment.users?.map((user) => (
										<li className="list-group-item" key={user.id}>
											<div className="row align-items-center">
												<div className="col-1">
													<UserAvatar
														avatar={user.avatar}
														alt={`${user.firstname} ${user.lastname}`}
														className="img-fluid object-fit-cover rounded-circle"
													/>
												</div>
												<div className="col">
													<p className="text-black m-0">{`${user.firstname} ${user.lastname}`}</p>
													<p className="text-muted m-0">Guest</p>
												</div>
												<Modal title="Login"
													bodyClass="p-3"
													btnText="Log in"
													trigger="LoginModal"
													showBtn={false}
													size={"md"}
													close={false}
													headcenter={true}
													centerClass={'centerClass'}
													backdrop={true}
												>
													<LoginModalForm  handleStatusClick={() => handleStatusClick(appointment.id)} />
												</Modal>
												<div className="col d-flex justify-content-end">
													{user.appointmentuser.user_id ? (
														<>
															<span
																className={`badge bg-${getAppointmentStatusClass(
																	user.appointmentuser.status_id
																)} cursor-pointer`}
																onClick={() => {
																	if (LoginData !== null && LoginData.id === user.id) {
																		handleStatusClick(user.appointmentuser.appointment_id)
																	}else if(LoginData && LoginData.id !== user.id){
																		return
																	}
																	else {
																		document.querySelector('.LoginModal').click()
																	}
																}}
															>
																{getAppointmentStatus(user.appointmentuser.status_id)}
															</span>
															<Link
																to='#'
																className='d-block text-decoration-none text-dark LoginModal'
																data-bs-toggle='modal'
																data-bs-target='#LoginModal'
															>
															</Link>
															<SweetAlert2 {...swalProps[user.appointmentuser.appointment_id]} />
														</>
													) : (
														<span
															className={`text-${getAppointmentStatusClass(
																user.appointmentuser.status_id
															)}`}
														>
															{getAppointmentStatus(user.appointmentuser.status_id)}
														</span>
													)}
												</div>
											</div>
										</li>
									))}
								</>
							) : null}
						</ul>
						{appointment?.room_id && convertTimeIntoSselectedTZ(
							appointment?.timezone,
							moment.tz.guess(),
							moment.utc(endDate),
						) > currentDate.format('YYYY-MM-DD HH:mm:ss') && (
							<div className="clearfix text-center mt-3">
								<button
									type="button"
									className={`btn btn-primary btn-sm w-50 my-3`}
									onClick={() => joinMeeting(appointment?.room_id)}
								>
									Join Meetings
								</button>
							</div>
						)}
					</div>
					{/* <Modal
				title="Reschedule Appointment"
				bodyClass="p-4 text-center text-dark text-opacity-50"
				trigger="openAppointmentRescheduleModal2"
				showBtn={false}
				btnClass="AppointmentRescheduleModalClose2"
				size="lg"
			> */}
					{/* <RescheduleAppointmentForm
					data={appointment}
					users={usersForAppointment}
					currentUserAppointments={currentUserAppointments}
					reload={() => {
						reload();
						close();
					}}
					setAlert={setAlert}
					btnClass="AppointmentRescheduleModalClose2"
				/> */}
					{/* </Modal> */}

				</div>

			</div>}
		</>
	)
}

export default AppointmentDetails