import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BASEURL } from '../../utilites';
import allActions from '../../Store/action';
import Button from './fields/Button';

export default function ImpersonateUserForm({ userId,userToEdit, cleanup, isLeadsPage }) {
	const [errors, setErrors] = useState({
		general: '',
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const button = useRef();

	const handleSubmit = (e) => {
		e.preventDefault();

		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });

		let role = userToEdit?.roles?.[0];
             console.log("@1111",role?.name,userToEdit?.userpro?.status_id);
		axios
			.post(`${BASEURL}/api/users/impersonate`, {
				userId,
			})
			.then(() => {
				button.current.disabled = false;
				// Close Modal
				document.querySelector('.impersonateUserModalClose').click();
				// Reset Messages Store To Get Data Again
				dispatch(allActions.firebase.resetPersonalConnection());
				dispatch(allActions.firebase.resetReadPersonalChatsArray());
				// Reset global state for dashboard
				dispatch(allActions.dashboard.reset());
				// Reset Firestore state
				dispatch(allActions.firebase.reset());
				// Reset global state for user and re-fetch
				dispatch(allActions.user.removeUserData());
				dispatch(allActions.user.ReGetUserData());
             //    register free membership for taxpro while login 
				if(role?.name === 'taxpro' && userToEdit?.userpro?.status_id===2 && userToEdit?.usermembership?.length===0 ){
					dispatch(allActions.membership.initialRegisterMembership())
				}
				// Redirect to index page
			})
			.then(() => {
				cleanup && cleanup();
				// If impersonating from leads page (by account manager); redirect to the index page of the dashboard
				isLeadsPage && navigate('/dashboard/');
			})
			.catch((error) => {
				if(button.current){
					button.current.disabled = false;
				}
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};

	return (
		<form className='usersform needs-validation' onSubmit={handleSubmit}>
			<div className='row'>
				<div className='mb-3'>
					<p>You're about to Log In As this user. Click the button below to confirm this action.</p>
				</div>
			</div>
			{errors.general && errors.general !== '' && (
				<div className='row'>
					<div className='mb-3'>
						<div className='alert alert-danger' role='alert'>
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 ? ' disabled' : ''
				}`}
				wrapperClass='clearfix w-75 mx-auto d-grid text-center mt-3'
				buttonText='Confirm'
			/>
		</form>
	);
}
