/* eslint-disable react-hooks/exhaustive-deps */

import allActions from '../../../../Store/action.js';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../../../../components/forms/fields/Message.js';
import UsersList from '../../../../components/Messaging/UsersList.js';
import Offcanvas from '../../../../components/Dashboard/Offcanvas.js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SideMenuButton from '../../../../components/Messaging/SideMenuButton.js';
import { FIREBASE_ACTION_TYPE } from '../../../../Store/firebase/Type.js';
import { Scrollbar } from 'react-scrollbars-custom';
import { useLocation } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import Loader from '../../../../components/Loader.js';
import CallTopBar from '../../../../components/Messaging/CallTopBar.js';
import CallCard from '../../../../components/Messaging/CallCard.js';
import Footer from '../../components/Footer.js';

const CustomerCallBoard = ({ uuid, group, editable = true, isIndexPage }) => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const [callData, setCallData] = useState({ status: '' });
	const [callMeta, setCallMeta] = useState('');

	const count = 10;
	const dispatch = useDispatch();
	const messagesEndRef = useRef(null);
	const params = useLocation();
	const scrollContainerRef = useRef();
	const scrollContainerRef1 = useRef();

	let [
		{ user, dashboard },
		{ LoginData, loginStatus },
		{ adminRole, personalConnections, personalGroupConnections },
	] = useSelector((state) => [state, state.user, state.firebase]);
	personalConnections = group ? personalGroupConnections : personalConnections;
	const [search, setSearch] = useState('');
	const [chatUsers, setChatUsers] = useState([]);
	const [openChat, setOpenChat] = useState(false);
	const [message, setMessage] = useState({ show: false });
	const [selectedReceiver, setselectedReceiver] = useState();
	const [initialLoad, setInitialLoad] = useState(count);
	const [load, setLoad] = useState(false);
	const [manageLocal, setmanageLocal] = useState(false);

	const chatDisable =
		LoginData?.isTaxPro &&
		Number(
			dashboard?.leads?.data?.find((data) => data?.user?.id === selectedReceiver?.id)?.status
		) === 3;
	// --------------------------------------------
	// HOOKS (UseEffect used For Different Purposes)
	// --------------------------------------------

	//  *****--HOOKS To Retrive Chat Users(last seen , Online /offline)--****
	useEffect(() => {
		if (chatUsers?.length > 0) {
			dispatch(allActions.firebase.getFirebaseUsers(chatUsers));
		}
	}, [chatUsers]);

	//  *****--HOOKS To Apply Filter on Chats according to Last Chat--****
	useEffect(() => {
		manageFilter(loginStatus, adminRole.loaded, personalConnections);
	}, [loginStatus, adminRole.loaded, personalConnections, openChat, user, chatUsers.length]);

	// GET DATA
	useEffect(() => {
		// FOR TAX PRO
		if (loginStatus && !user?.isRegular && !user?.isAdmin && !user?.isAccountManager) {
			dispatch(allActions.dashboard?.getAllLeads('', ''));
		}

		// FOR ADMIN
		if (loginStatus && !user?.isRegular && !user?.isTaxPro) {
			dispatch(allActions.dashboard?.getAllUsers('', ''));
		}

		// FOR ACCOUNT MANAGER
		if (loginStatus && user?.isAccountManager) {
			dispatch(allActions.dashboard?.getAllLeads('', ''));
		}
		if (loginStatus && user?.isAccountManager) {
			dispatch(allActions.dashboard?.getAllUsers('', ''));
		}
	}, [window.location.href]);

	// *****--Load Customer & Account manager If User is Tax Pro--*****
	useEffect(() => {
		if (
			loginStatus &&
			!user?.isRegular &&
			!user?.isAdmin &&
			!user?.isAccountManager &&
			dashboard?.leads.loaded &&
			adminRole.loaded === true
		) {
			let options = [];
			let filteredData = dashboard?.leads?.data?.map((data) => {
				return {
					role: 'Customer',
					id: data?.user?.id,
					uuid: data?.user?.uuid,
					lastname: data?.user?.lastname,
					firstname: data?.user?.firstname,
					email: data?.user?.email,
					phone: data?.phone,
					callRecord: data?.call_record,
				};
			});
			options = filteredData;
			// check account manager exist or not
			if (user?.LoginData?.userpro?.manager) {
				let manager = user?.LoginData?.userpro?.manager;
				manager.role = 'Account Manager';
				options.push(manager);
			}

			let admin = adminRole?.data?.map((data) => {
				return {
					firstname: data?.firstname,
					lastname: data?.lastname,
					email: data?.email,
					id: data?.roles[0].userrole?.user_id,
					uuid: data?.uuid,
					role: 'Admin',
					callRecord: data?.call_record,
				};
			});
			options.push(admin[0]);
			setChatUsers(options);
			manageFilter(loginStatus, adminRole.loaded, personalConnections);
		}
	}, [
		loginStatus,
		dashboard?.leads.data,
		user?.isRegular,
		user?.isAdmin,
		user.isAccountManager,
		adminRole.loaded,
		window.location.href,
	]);
	// *****--Load Customers & TaxPro If User is Admin--*****
	useEffect(() => {
		if (
			loginStatus &&
			!user?.isRegular &&
			!user?.isTaxPro &&
			dashboard?.allUsers.loaded &&
			!user?.isAccountManager
		) {
			let filteredData = dashboard?.allUsers?.data
				.filter((data) => {
					if (!group) return data.roles[0].name !== 'customer';
					else return data;
				})
				?.map((data) => {
					if (data.roles[0].display_name !== 'Admin') {
						return {
							firstname: data?.firstname,
							lastname: data?.lastname,
							email: data?.email,
							id: data?.id,
							uuid: data?.uuid,
							role: data?.roles[0]?.display_name,
							phone: data?.userpro?.phone,
							callRecord: data?.call_record,
						};
					} else return undefined;
				});
			let others = filteredData.filter((data) => data !== undefined);
			setChatUsers(others);
			manageFilter(loginStatus, adminRole.loaded, personalConnections);
		}
	}, [
		loginStatus,
		dashboard.allUsers.data,
		user?.isTaxPro,
		user?.isRegular,
		user?.isAccountManager,
		window.location.href,
	]);

	// *****--Load TaxPro & Account manager If User is Customer--*****
	useEffect(() => {
		let options = [];
		if (
			loginStatus &&
			!user?.isTaxPro &&
			!user?.isAdmin &&
			!user?.isAccountManager
			// &&
			// adminRole.loaded === true
		) {
			let taxpro = LoginData?.lead[0]?.userpro?.user;
			if (taxpro) {
				taxpro.role = 'Tax Pro';
				options.push(taxpro);
			}
			// check account manager exist or not
			if (LoginData?.lead[0]?.userpro?.manager) {
				let manager = LoginData?.lead[0]?.userpro?.manager;
				manager.role = 'Account Manager';
				options.push(manager);
			}
			// handle admin role there
			// let admin = adminRole?.data?.map((data) => {
			// 	return {
			// 		firstname: data?.firstname,
			// 		lastname: data?.lastname,
			// 		id: data?.roles[0].userrole?.user_id,
			// 		uuid: data?.uuid,
			// 		role: "Admin",
			// 	};
			// });
			// options.push(admin[0]);
			setChatUsers(options);
			manageFilter(loginStatus, adminRole.loaded, personalConnections);
		}
	}, [
		loginStatus,
		user?.isTaxPro,
		user?.isAdmin,
		user?.isAccountManager,
		LoginData,
		window.location.href,
	]);

	// *****--RESET STATE--*****
	useEffect(() => {
		setOpenChat(false);
	}, [window.location.href]);

	// *****--Check Leads Exist or not & Customers as well  if user is account manager--*****
	useEffect(() => {
		// check leads exist or not
		if (
			loginStatus &&
			!user?.isRegular &&
			!user?.isAdmin &&
			!user?.isTaxPro &&
			dashboard?.allUsers.loaded &&
			adminRole.loaded === true
		) {
			let options = [];
			// check users exist or not
			let filteredUsersData = dashboard?.allUsers?.data?.map((data) => {
				return {
					id: data?.id,
					uuid: data?.uuid,
					firstname: data?.firstname,
					lastname: data?.lastname,
					email: data?.email,
					role: data?.roles[0]?.display_name,
					phone: data?.userpro.phone,
					callRecord: data?.call_record,
				};
			});
			options = filteredUsersData;
			let admin = adminRole?.data?.map((data) => {
				return {
					firstname: data?.firstname,
					lastname: data?.lastname,
					email: data?.email,
					id: data?.roles[0].userrole?.user_id,
					uuid: data?.uuid,
					role: 'Admin',
					callRecord: data?.call_record,
				};
			});
			options.push(admin[0]);
			console.log('CHECKK>>>>>>>', options);
			setChatUsers(options);
			manageFilter(loginStatus, adminRole.loaded, personalConnections);
		}
	}, [
		loginStatus,
		dashboard?.allUsers.loaded,
		user?.isRegular,
		user?.isTaxPro,
		user?.isAdmin,
		adminRole.loaded,
		window.location.href,
	]);

	// *****--Handle Store Response Of Loading Of Every HOOKS--*****
	useEffect(() => {
		// check user is login
		if (loginStatus) {
			if (
				manageLocal === true ||
				// For Leads Put Condition
				(!user?.isRegular && !user?.isAdmin && !dashboard?.leads.loaded) ||
				// For Customer Put Condition
				(!user?.isTaxPro && !user?.isAdmin && !LoginData?.lead[0]?.userpro?.user) ||
				// For Getting Admin user
				(!user?.isAdmin ? adminRole.loaded !== 'idle' : false) ||
				// personal firebase connection details
				personalConnections?.loaded === 'idle'
			) {
				dispatch(allActions.global.Loader(true));
			}
			if (
				// For Leads Put Condition
				((!user?.isRegular && !user?.isAdmin && dashboard?.leads.loaded) ||
					// For Customer Put Condition
					(!user?.isTaxPro && !user?.isAdmin && LoginData?.lead[0]?.userpro?.user) ||
					// For admin Put Condition
					(!user?.isTaxPro &&
						!user?.isTaxPro &&
						!user?.isAccountManager &&
						dashboard?.allUsers.loaded)) &&
				manageLocal === false &&
				// For Getting Admin user
				(!user?.isAdmin ? adminRole.loaded !== 'idle' : true) &&
				// personal firebase connection details
				personalConnections?.loaded === true
			) {
				dispatch(allActions.global.Loader(false));
			}
		}
	}, [
		dashboard?.allUsers.loaded,
		dashboard?.leads.loaded,
		user.isRegular,
		user.isAdmin,
		user.isTaxPro,
		user.isAccountManager,
		LoginData?.lead,
		manageLocal,
		loginStatus,
		adminRole?.loaded,
		personalConnections?.loaded,
	]);

	// *****--Function to Manage Filter According to Last Chat--****
	const manageFilter = () => {
		setmanageLocal(true);
		if (adminRole.loaded === 'idle' && loginStatus) {
			dispatch(allActions.firebase.getAdminUser());
		}
		// Read personalConnections if not loaded
		if (personalConnections?.loaded === 'idle' && loginStatus) {
			dispatch(allActions.firebase.readPersonalConnections(LoginData?.uuid));

			if (group && !group?.includes(undefined) && group.find((data) => data !== uuid)) {
				dispatch(
					allActions.firebase.readPersonalGroupConnections(group.find((data) => data !== uuid))
				);
			}
			// Submit User Id
			dispatch({
				type: FIREBASE_ACTION_TYPE.myUuid,
				uuid:
					group && !group?.includes(undefined)
						? group.find((data) => data !== uuid)
						: LoginData?.uuid,
			});
		}
		if (personalConnections?.loaded === true) {
			filteredChatUsers(personalConnections.data);
		}
	};

	//  *****--Function to Retrive User Chats--****
	const loadChats = (data, openChat, loading) => {
		if (LoginData?.uuid === undefined || data?.uuid === undefined || openChat === true) {
			return;
		}

		setselectedReceiver(data);
		setMessage({ show: false });
		CallEnd();
	};
	//  *****--Function to Filter Chat Users--****
	const filteredChatUsers = (connection) => {
		chatUsers?.map((data) => {
			let filtered = connection.find((v) => v?.ids?.includes(data?.uuid));
			if (filtered) {
				data['updatedAt'] = filtered['updatedAt'];
				data[LoginData?.uuid] = filtered[LoginData?.uuid];
				data['docId'] = filtered['docId'];
			}
			return 0;
		});
		const sortedArray = chatUsers
			.slice()
			.sort((a, b) => (a.updatedAt ? b.updatedAt - a.updatedAt : 1) || -1);

		// Check if Array Contain Element then Save
		if (sortedArray.length > 0) {
			setChatUsers(group ? chatUsers.slice() : sortedArray);
		}

		// if from params any data came open that data chat
		if (params?.state?.uuid && !openChat) {
			dispatch(allActions.firebase.resetReadPersonalChatsArray());
			loadChats(
				sortedArray.find((data) => data.uuid === params?.state?.uuid),
				openChat
			);
			return;
		}
		if (uuid && sortedArray.find((data) => data.uuid === uuid) && !openChat) {
			dispatch(allActions.firebase.resetReadPersonalChatsArray());
			loadChats(
				sortedArray.find((data) => data.uuid === uuid),
				openChat
			);
			return;
		}
		// else open first chat
		if (!openChat) {
			loadChats(sortedArray[0], openChat);
		}
	};

	const handleScroll = () => {
		const scrollContainer = scrollContainerRef.current;
		const scrollContainer1 = scrollContainerRef1.current;

		// Check if the user has reached the bottom of the scroll container
		if (
			scrollContainer.scrollTop + scrollContainer.clientHeight >=
			scrollContainer.scrollHeight - 1
		) {
			handleMore();
			console.log('Reached the end of the scroll');
			// Perform any action you want when the scroll reaches the end
		}
		// Check if the user has reached the bottom of the scroll container
		if (
			scrollContainer1.scrollTop + scrollContainer1.clientHeight >=
			scrollContainer1.scrollHeight - 1
		) {
			handleMore();
			console.log('Reached the end of the scroll');
			// Perform any action you want when the scroll reaches the end
		}
	};

	const handleMore = () => {
		if (initialLoad < chatUsers?.length) {
			setLoad(true);
			setTimeout(() => {
				setInitialLoad(initialLoad + count);
				setLoad(false);
			}, 2000);
		} else {
			setInitialLoad(chatUsers?.length);
		}
	};

	const CallEnd = async () => {
		try {
			if (callMeta !== '' && callMeta._boundDestroy()) {
				await callMeta?._boundDestroy();
			}
			setCallData({ status: '' });
			setCallMeta('');
			setTimeout(() => {
				setmanageLocal(false);
			}, 2000);
		} catch (error) {
			setmanageLocal(false);
			console.log('ERROR???', error);
		}
	};

	return (
		<div className="border-secondary-100 p-0 mb-3 rounded main ">
			<div className="row justify-content-center">
				<div className="col-md-9">
					<h3 className="fw-bold text-center mt-5">Call Center</h3>
					<div className="row justify-content-between mt-5">
						{message?.show ? <Message message={message} /> : <></>}
						{!uuid && (
							<div className="col-md-4 chat-Box  chat-list-side">
								<div className="clearfix mb-2">
									<AiOutlineSearch
										className="position-absolute search-icon"
										size={23}
										color="#6A737B"
									/>
									<input
										type="text"
										className="form-control"
										style={{ paddingLeft: 35 }}
										placeholder="Find user..."
										onChange={(e) => setSearch(e.target.value)}
									/>
								</div>
								<Scrollbar
									onScroll={handleScroll}
									width={3}
									ref={scrollContainerRef}
									className="w-100 m-0 p-0 chat-user-scrollbar"
								>
									<UsersList
										search={search}
										chatUsers={
											chatUsers?.length > initialLoad ? chatUsers?.slice(0, initialLoad) : chatUsers
										}
										loadChats={loadChats}
										setOpenChat={setOpenChat}
										getMyStatus={allActions.firebase.getMyStatus}
										selectedReceiver={selectedReceiver}
										showNotification={false}
									/>
									{load && (
										<Loader
											size={{ width: '2rem', height: '2rem' }}
											padding={10}
											local={true}
											loading={load}
											customBg={'rgba(0,0,0,0)'}
											viewClass="bg-transparent"
										/>
									)}
								</Scrollbar>
							</div>
						)}
						{!uuid && (
							<SideMenuButton
								selectedReceiver={selectedReceiver}
								getMyStatus={allActions.firebase.getMyStatus}
							/>
						)}
						{selectedReceiver && (
							<div
								className={`${
									uuid ? 'col-md-12 py-2 rounded' : 'col-md-8 py-2 rounded'
								} chat-Box overflow-hidden d-flex flex-column justify-content-between`}
							>
								<>
									<CallTopBar
										setMessage={setMessage}
										selectedReceiver={selectedReceiver}
										chatDisable={chatDisable}
										callMeta={callMeta}
										setCallData={setCallData}
										callData={callData}
										setCallMeta={setCallMeta}
										CallEnd={CallEnd}
									/>
									<div className=" border-bottom border-light">
										<p className="text-muted small m-0 p-0 pt-4 my-1">Call logs</p>
									</div>
									<Scrollbar width={3} className="w-100 h-100" ref={messagesEndRef}>
										<div>
											<div className="clearfix chatroom p-2 chats-area">
												{selectedReceiver &&
													chatUsers
														?.find((data) => data?.id === selectedReceiver?.id)
														?.callRecord?.map((data, index) => {
															return <CallCard data={data} />;
														})}
											</div>
										</div>
									</Scrollbar>
								</>
							</div>
						)}
					</div>
				</div>
			</div>
			{!uuid && (
				<Offcanvas
					header="Chat Users"
					trigger="messageMobileView"
					style={{ width: 300, zIndex: 1 }}
					bodyClass="chat-offCanvas"
					enableScroll={false}
				>
					<div className="clearfix p-2 mb-2">
						<AiOutlineSearch className="position-absolute search-icon" size={23} color="#6A737B" />
						<input
							type="text"
							className="form-control"
							style={{ paddingLeft: 35 }}
							placeholder="Find user..."
							onChange={(e) => setSearch(e.target.value)}
						/>
					</div>
					<Scrollbar
						onScroll={handleScroll}
						width={3}
						ref={scrollContainerRef1}
						className="w-100"
						style={{ height: '92%' }}
					>
						<UsersList
							search={search}
							chatUsers={
								chatUsers?.length > initialLoad ? chatUsers?.slice(0, initialLoad) : chatUsers
							}
							loadChats={loadChats}
							setOpenChat={setOpenChat}
							getMyStatus={allActions.firebase.getMyStatus}
							selectedReceiver={selectedReceiver}
							showNotification={false}
						/>
						{load && (
							<Loader
								size={{ width: '2rem', height: '2rem' }}
								padding={10}
								local={true}
								loading={load}
								customBg={'rgba(0,0,0,0)'}
								viewClass="bg-transparent"
							/>
						)}
					</Scrollbar>
				</Offcanvas>
			)}
			<Footer/>
		</div>
	);
};

export default CustomerCallBoard;
