import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import allActions from "../../Store/action";
import Message from "../../components/forms/fields/Message";

const GoogleCalendarVerification = () => {
  const dispatch = useDispatch();
  const [message] = useState({ show: false });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [{ googleCalendarVerification }] = useSelector((state) => [
    state.dashboard,
  ]);

  useEffect(() => {
    if (googleCalendarVerification.status === "idle") {
      const code = searchParams.get("code");
      dispatch(allActions.dashboard.googleCalendarVerification(code));
    }
    if (googleCalendarVerification.status === false) {
      navigate("/dashboard/settings", {
        state: {
          identity: "googlecalendarverification",
          googleCalendarVerificationStatus: false,
          googleCalendarVerificationError: googleCalendarVerification.message,
        },
      });
    }
    if (googleCalendarVerification.status === true) {
      navigate("/dashboard/settings", {
        state: {
          identity: "googlecalendarverification",
          googleCalendarVerificationStatus: true,
        },
      });
    }
  }, [dispatch, navigate, googleCalendarVerification, searchParams]);
  return (
    <div className="clearfix px-3">
      {message.show ? <Message message={message} /> : <></>}
    </div>
  );
};

export default GoogleCalendarVerification;
