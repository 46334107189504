import timezones from 'timezones-list';

const getAmericanTimezones = () => {
	const american_timezones = timezones.filter((timezone) => {
		return timezone.label.toLowerCase().includes('america');
	});

	const firstFourTimezoneTzCodes = [
		'America/New_York',
		'America/Chicago',
		'America/Denver',
		'America/Los_Angeles',
	];

	const firstFourTimezoneObject = [];

	firstFourTimezoneTzCodes.forEach((id) => {
		let index = american_timezones.findIndex((obj) => obj.tzCode === id);
		if (index !== -1) {
			firstFourTimezoneObject.push(american_timezones.splice(index, 1)[0]);
		}
	});
	// Insert the specific objects at the beginning of the array
	american_timezones.unshift(...firstFourTimezoneObject);

	return american_timezones;
};

export { getAmericanTimezones };
