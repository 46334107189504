import { AiFillCheckCircle } from "react-icons/ai";

const MainOption = ({ heading, check, onClick, filled }) => {
	return (
		<div
			onClick={onClick}
			className="w-100 d-flex flex-row align-items-center justify-content-start option-left mb-2"
			role="button"
		>
			{check && <AiFillCheckCircle color="green" />}
			{!check && (
				<input
					disabled={true}
					checked={filled}
					className="form-check-input"
					type="radio"
					id="agreeCheckbox"
				/>
			)}
			<p className="left-portion-option mt-3">{heading}</p>
		</div>
	);
};

export default MainOption;
