import React, { useEffect, useState } from 'react';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import TextArea from '../../fields/TextArea';
import allActions from '../../../../Store/action';
import { useDispatch, useSelector } from 'react-redux';
import MultipleCheckLabelSelector from '../../fields/MultipleCheckLabelSelector';
import { HandleFormData } from '../../../../utilites';

const IncomeSource = () => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const dispatch = useDispatch();

	const { IncomeSourcesList } = GLOBAL_VARIABLES;

	const uploadFormData = HandleFormData({ parentIndex: 2, myIndex: 0 });

	const [{ taxPayerFormData, taxPayerForm ,formInformation}] = useSelector((state) => [state.taxForms]);

	// For Text Input Field

	const [comments, setComments] = useState('');

	// For Choice Questions
	const [appliedOptions, setAppliedOptions] = useState([]);

	const [errors, setErrors] = useState({}); // Track errors for form fields

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.incomeSourceForm) {
			if (Object.keys(taxPayerForm?.data?.incomeSourceForm).length === 0) return;

			const { appliedOptions, comments } = taxPayerForm?.data?.incomeSourceForm;
			setAppliedOptions(appliedOptions);
			setComments(comments);
		}
	}, [taxPayerForm?.data?.incomeSourceForm]);

	// ---------------------------------------
	// Functions
	// ---------------------------------------

	const handleAppliedOptions = (option) => {
		const localOptions = appliedOptions.slice();
		if (localOptions?.includes(option)) {
			setAppliedOptions(localOptions.filter((data) => data !== option));
		} else {
			localOptions.push(option);
			setAppliedOptions(localOptions);
		}
	};

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		// Add more validation rules as needed for other fields
		if (appliedOptions?.length === 0) {
			newErrors.AnswerOtherQuestions = 'Please Select Required Option';
		}

		setErrors(newErrors);
		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let errors = check_Validity();
		if (errors) return;

		let FormObj = { appliedOptions, comments };

		let formData = taxPayerForm;
		taxPayerForm.data.incomeSourceForm = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
	};

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 2;
		let myIndex = 0;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	return (
		<form className="col-md-12 py-3" onSubmit={handleSubmit}>
			<small className="ml-4 fw-bold">Types of income received during 2023</small>
			<hr className="m-0" />
			<MultipleCheckLabelSelector
				col={6}
				mapData={IncomeSourcesList}
				selectedOptions={appliedOptions}
				handleAppliedOptions={handleAppliedOptions}
				label={"Select all that apply, even If you didn't receive a form"}
			/>
			<small className="ml-4 fw-bold">Comments</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3 mb-3 custom-left-form">
				<small className="text-dark fw-bold m-0 py-2">
					Enter Any Additional Information / Question here.
				</small>
				<div className="col-md-8 mb-3">
					<TextArea
						inputName="form-control"
						defaultValue={comments}
						placeholder="Comments"
						name="comments"
						fn={setComments}
						row="6"
						required={false}
					/>
				</div>
			</div>
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default IncomeSource;
