import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import ReactSelect from 'react-select';
import Input from './fields/Input';
import Button from './fields/Button';
import Message from './fields/Message';
import { getStatesList } from '../../utilites';
import allActions from '../../Store/action';

const UsaStatesList = getStatesList();

export default function ContactInfoForm({ active, setActive }) {
	const [{ stripeAccountStatusData }, { LoginData }] = useSelector((state) => [
		state.dashboard,
		state.user,
	]);
	let dispatch = useDispatch();
	let previousStripeData =
		stripeAccountStatusData?.data?.individual || stripeAccountStatusData?.data?.company;
	const [firstname, setFirstname] = useState(
		LoginData?.firstname || previousStripeData?.first_name
	);
	const [lastname, setLastname] = useState(LoginData?.lastname || previousStripeData?.last_name);
	const [Phone, setPhone] = useState(LoginData?.userpro?.phone || previousStripeData?.phone);
	const [MMDDYYYY, setMMDDYYYY] = useState(
		moment(
			LoginData?.userpro?.dob ||
				`"${stripeAccountStatusData?.data?.individual?.dob?.year}-${stripeAccountStatusData?.data?.individual?.dob?.month}-${stripeAccountStatusData?.data?.individual?.dob?.day}"`
		).format('MM/DD/YYYY')
	);
	const [message, setMessage] = useState({ show: false });
	const [streetAddress, setstreetAddress] = useState(
		LoginData?.userpro?.street || previousStripeData?.address.line1
	);
	const [city, setCity] = useState(LoginData?.userpro?.city || previousStripeData?.address?.city);
	const [aptsuit, setAptsuit] = useState(
		LoginData?.userpro?.street2 || previousStripeData?.address?.line2
	);
	const [state, setState] = useState(
		LoginData?.userpro?.state
			? UsaStatesList.find((s) => s.value === LoginData.userpro.state)
			: previousStripeData?.address?.state
			? UsaStatesList.find((s) => s.value === previousStripeData?.address?.state)
			: null
	);
	const [zipcode, setZipcode] = useState(
		LoginData?.userpro?.zipcode || previousStripeData?.address?.postal_code
	);

	const reset_mesage = () => {
		setMessage({ show: false });
	};

	// const resetForm = () => {
	//   setFirstname("");
	//   setLastname("");
	//   setPhone("");
	//   setMMDDYYYY("");
	//   setstreetAddress("");
	//   setCity("");
	//   setAptsuit();
	//   setState("");
	//   setZipcode("");
	// };
	// function to handle form form submission
	const handleSubmit = (e) => {
		// Reset User State Values
		reset_mesage();
		e.preventDefault();
		// resetForm();
		// Validation user

		const dateVaidity = isValidDate(MMDDYYYY);
		if (!dateVaidity) {
			return;
		}
		// for old stripe account
		var additional;
		if (stripeAccountStatusData?.data) {
			if (stripeAccountStatusData?.data?.company) {
				additional = {
					business_type: stripeAccountStatusData?.data?.business_type,
					business_structure: stripeAccountStatusData?.data?.company?.structure,
				};
			} else {
				additional = {
					business_type: stripeAccountStatusData?.data?.business_type,
				};
			}
		}
		let userObj = {
			firstName: firstname,
			lastName: lastname,
			phoneNumber: Phone,
			street: streetAddress,
			DOB: moment(MMDDYYYY).format('YYYY-MM-DD'),
			street2: aptsuit,
			city: city,
			state: state?.value || '',
			zipCode: zipcode,
			additional: additional,
		};
		// Call Dispatch Function For Agreement
		dispatch(allActions.dashboard.userPersonalDetails(userObj, getServices, setMessage));
		// Get Services Function
	};
	const getServices = () => {
		dispatch(allActions.dashboard.getServices(moveToNext, setMessage));
	};
	function isValidDate(dateString) {
		// Split the date string into month, day, and year parts
		const [month, day, year] = dateString.split('/');
		// Create a new Date object using the parts
		const date = new Date(year, month - 1, day);
		// Check if the parts are valid and match the original input
		if (
			date.getFullYear() === parseInt(year) &&
			date.getMonth() + 1 === parseInt(month) &&
			date.getDate() === parseInt(day)
		) {
			return true;
		} else {
			setMessage({
				show: true,
				type: 'Error',
				text: 'Entered Date is Invalid',
			});
			return false;
		}
	}
	const moveToNext = () => {
		if (stripeAccountStatusData?.data) {
			setActive(4);
		} else {
			setActive(3);
		}
	};
	const GoBack = () => {
		dispatch(
			allActions.user.ReGetUserData(() => {
				setActive(1);
			})
		);
	};
	return (
		<div>
			<form className='ContactInfoForm needs-validation' onSubmit={handleSubmit}>
				{message.show ? <Message message={message} /> : <></>}
				<div className='row g-3'>
					<div className='col-md-4 mb-3'>
						<Input
							type='text'
							value={firstname}
							name='firstname'
							placeholder='First Name'
							fn={setFirstname}
							className='form-control'
							showLabel={false}
						/>
					</div>
					<div className='col-md-4 mb-3'>
						<Input
							type='text'
							value={lastname}
							name='lastname'
							placeholder='Last Name'
							fn={setLastname}
							className='form-control'
							showLabel={false}
						/>
					</div>
					<div className='col-md-4 mb-3'>
						<Input
							type='text'
							value={Phone}
							name='Phone Number'
							placeholder='Phone Number'
							fn={setPhone}
							mask='(999) 999-9999'
							showLabel={false}
						/>
					</div>
				</div>
				<div className='row g-3'>
					<div className='col-md-6 mb-3'>
						<Input
							isDisabled={true}
							type='email'
							value={LoginData?.email}
							name='email'
							showLabel={false}
							placeholder='Email Address'
							fn={() => {}}
						/>
					</div>
					<div className='col-md-3 mb-3'>
						<Input
							type='text'
							value={MMDDYYYY}
							name='MM/DD/YYYY'
							placeholder='MM/DD/YYYY'
							fn={setMMDDYYYY}
							mask='99/99/9999'
							showLabel={false}
						/>
					</div>
				</div>
				<fieldset className='border-top p-1 mt-3'>
					<legend className='float-none w-auto px-2 mx-2 text-start legned-text'>
						<small>Full Address</small>
					</legend>
				</fieldset>
				<div className='row g-3'>
					<div className='col-md-9 mb-3'>
						<Input
							type='text'
							value={streetAddress}
							name='Street Address'
							placeholder='Street Address'
							fn={setstreetAddress}
							showLabel={false}
						/>
					</div>
					<div className='col-md-3'>
						<Input
							type='text'
							value={aptsuit}
							name='Apt/Suite'
							placeholder='Apt/Suite'
							fn={setAptsuit}
							required={false}
							showLabel={false}
						/>
					</div>
				</div>
				<div className='row g-3'>
					<div class='col-md-5 mb-3'>
						<Input
							type='text'
							value={city}
							name='City'
							showLabel={false}
							placeholder='City'
							fn={setCity}
						/>
					</div>
					<div className='mb-3 col-lg-4 col-sm-12 responsive-field'>
						<ReactSelect
							id='state'
							className='p-0 basic-single'
							placeholder='State'
							closeMenuOnSelect={true}
							options={UsaStatesList}
							onChange={setState}
							value={state}
						/>
					</div>
					<div className='mb-3 col-lg-3 col-sm-12 responsive-field'>
						<Input
							type='text'
							value={zipcode}
							name='Zipcode'
							showLabel={false}
							placeholder='Zipcode'
							fn={setZipcode}
							mask='99999999'
						/>
					</div>
				</div>
				<div className='container-fluid content-row '>
					<div className='d-flex align-items-center justify-content-center row'>
						<Button
							mainClass='btn btn-outline-primary btn-lg w-100'
							buttonText='Previous'
							wrapperClass={'w-25 custon-button  mt-4'}
							type='button'
							callback={() => {
								GoBack();
							}}
						/>
						<Button
							mainClass='btn btn-primary custon-button-text btn-lg  w-100'
							buttonText='Next'
							wrapperClass={'w-25 custon-button  mt-4'}
							type='submit'
						/>
					</div>
				</div>
			</form>
		</div>
	);
}
