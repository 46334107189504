/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Scrollbar } from 'react-scrollbars-custom';
import Calendar from 'react-calendar';
import { BASEURL, useAutoReplyHook, timezoneFormatter, convertTime, convertTimeIntoSselectedTZ } from '../../utilites';
import Button from './fields/Button';
import Checkbox from './fields/Checkbox';
// CSS
import 'react-datepicker/dist/react-datepicker.css';
import allActions from '../../Store/action';
import { useDispatch, useSelector } from 'react-redux';
import Message from './fields/Message';
import { CALL_ACTION_TYPE } from '../../Store/call/Type';
import ReactSelect from 'react-select';
import moment from "moment";
const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// const defaultTimeRange = [
// 	{
// 		from: '08:00',
// 		to: '20:00',
// 	},
// ];
// const defaultTimeRanges = {
// 	Sunday: defaultTimeRange,
// 	Monday: defaultTimeRange,
// 	Tuesday: defaultTimeRange,
// 	Wednesday: defaultTimeRange,
// 	Thursday: defaultTimeRange,
// 	Friday: defaultTimeRange,
// 	Saturday: defaultTimeRange,
// };

const durationOptions = [
	{ id: 30, name: '30 Minutes' },
	{ id: 60, name: '60 Minutes' },
];

export default function RescheduleAppointmentForm({
	data,
	setAlert,
	reload,
	btnClass = 'AppointmentRescheduleModalClose',
}) {
	const [message, setMessage] = useState({ show: false });
	const [{ getZoomMeeting, updateMeetingRoom }, { personalConnections }, { LoginData }] =
		useSelector((state) => [state.call, state.firebase, state.user]);
	const [videoAppointment, setVideoAppointment] = useState();
	const [startDate, setStartDate] = useState(new Date());
	const [duration, setDuration] = useState([durationOptions[0].id.toString()]);
	const [availableTime, setAvailableTime] = useState([]);
	const topTimezones = [
		"America/New_York",
		"America/Chicago",
		"America/Denver",
		"America/Los_Angeles",
	  ];
	  
	  const americanTimeZones = moment.tz.zonesForCountry("US");
	  const [timezone, setTimezone] = useState(
		moment.tz.guess().startsWith("America/")
		  ? moment.tz.guess()
		  : "America/Adak"
	  );
	  const [timezoneName, setTimezoneName] = useState(
		moment.tz.guess().startsWith("America/")
		  ? moment.tz.guess()
		  : "America/Adak"
	  );
	  const [availableTimeTZ, setAvailableTimeTZ] = useState([]);
	  var userAvailTZ = null;

	  switch (LoginData?.roles[0]?.name) {
		case "customer":
		  userAvailTZ = LoginData?.lead?.[0]?.userpro?.user;
		  break;
		case "taxpro":
		  userAvailTZ = LoginData;
		  break;
	
		default:
		  break;
	  }
	const [errors, setErrors] = useState({
		startDate: false,
		duration: false,
		general: '',
	});
	const button = useRef();
	const dispatch = useDispatch();
	const handleAutoReply = useAutoReplyHook(dispatch);

	useEffect(() => {
		// if Error While Creating Video Call Room
		if (!updateMeetingRoom?.status) {
			// Reset Message and Loading
			resetMessage();
			setMessage({
				show: true,
				type: 'Error',
				text: updateMeetingRoom?.message,
			});
		}
		// if Success After Creating Video Call
		if (updateMeetingRoom?.status === true) {
			let newRoomId = videoAppointment && updateMeetingRoom?.data?.meetingID;
			// Reset Message and Loading
			handleSubmit(newRoomId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateMeetingRoom.status, updateMeetingRoom?.message, updateMeetingRoom?.data?.meetingID]);

	useEffect(() => {
		// if Error While Creating Video Call Room
		if (!getZoomMeeting?.status) {
			// Reset Message and Loading
			resetMessage();
			setMessage({
				show: true,
				type: 'Error',
				text: getZoomMeeting?.message,
			});
		}
		if (getZoomMeeting?.status === true) {
			document.querySelector(`.${btnClass}`).click();
			resetMessage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getZoomMeeting.status, getZoomMeeting.message, getZoomMeeting?.data?.roomId]);

	const handleSubmit = (roomId) => {
		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });
		// Get endDate based on the selected duration and startDate
		const durationInMinutes = parseInt(duration[0]);
		const end_date = new Date(startDate);
		
		if(startDate){
			end_date.setMinutes(startDate.getMinutes() + durationInMinutes);
		}
		const appointmentObj = {
			timezone: userAvailTZ?.settings?.timezone,
			roomId,
			startDate: convertTimeIntoSselectedTZ(
			  timezone,
			  userAvailTZ?.settings?.timezone,
			  startDate
			),
			endDate: convertTimeIntoSselectedTZ(
			  timezone,
			  userAvailTZ?.settings?.timezone,
			  end_date
			),
		  };
		axios
			.put(`${BASEURL}/api/appointments/reschedule/${data?.uuid}`, appointmentObj)
			.then(() => {
				button.current.disabled = false;
				handleVideoMeetingReply();
				// Reload appointments
				reload();
				// Close Modal
				document.querySelector(`.${btnClass}`).click();
				setAlert && setAlert('Appointment has been updated successfully!');
				changeLoading(false);
			})
			.catch((error) => {
				if(button.current){
					button.current.disabled = false;
				}
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};
	const isExcludedDate = (date) => {
		const day = dayNames[date.getDay()];
		if (!userAvailTZ?.availability?.time_ranges[day]) return true;
		return false;
	  };
	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};
	const resetMessage = () => {
		dispatch(allActions.call.dispatchToStore({ type: CALL_ACTION_TYPE.getZoomMeetingIdle }));
		dispatch(allActions.call.dispatchToStore({ type: CALL_ACTION_TYPE.updateMeetingRoomIdle }));
		setMessage({ show: false });
		changeLoading(false);
		    // Reset the form
			setTimezone(
			  moment.tz.guess().startsWith("America/")
				? moment.tz.guess()
				: "America/Adak"
			);
			setDuration([durationOptions[0].id.toString()]);
	};

	const handleVideoMeetingReply = () => {
		handleAutoReply({
			personalConnections,
			LoginData,
			receiver: data.users[0],
			type: 'simple',
			encodedString: `<p>Hi, I just rescheduled our appointment to a later date. You should be receiving an email about the new schedule shortly or you can visit the appointments page by clicking on the link below to confirm the new date and time. <a href="${window.location.origin}/dashboard/appointments">View Appointment</a></p>`,
		});
	};

	const handleVideoMeeting = (e) => {
		e.preventDefault();
		changeLoading(true);

		// CHECK APPOINTMENT IS CHANGE OR NOT
		if ((data?.room_id && true === videoAppointment) || !data?.room_id === !videoAppointment) {
			console.log('No change>>>>');
			handleSubmit(data?.room_id);
		} else {
			if (!videoAppointment) {
				// thats one working
				if (!data?.room_id) return;
				console.log({duration: parseInt(duration[0])})
				dispatch(
					allActions.call.updateMeetingRoom({
						meetingObj: {
							meetingID: data?.room_id,
							videoAppointment: false,
							email: LoginData?.email,
							duration: parseInt(duration[0]),
						},
					})
				);
			}
			if (videoAppointment === true) {
				dispatch(
					allActions.call.updateMeetingRoom({
						meetingObj: {
							videoAppointment: true,
							email: LoginData?.email,
							duration: parseInt(duration[0]),
						},
					})
				);
			}
		}
	};
	const haandleChange = (date) => {
		setStartDate(date);
		const currentDate = moment(date);
		const dayOfWeek = currentDate.format("dddd");
		const slotDuration = duration;
		const timeSlots = [];
		const originaltimeSlots = [];
		setAvailableTime();
		userAvailTZ?.availability?.time_ranges[dayOfWeek]?.length > 0 &&
		  userAvailTZ?.availability?.time_ranges[dayOfWeek]?.map((v, i) => {
			originaltimeSlots.push(
			  ...getTimeSlots(
				v.from,
				v.to,
				slotDuration,
				currentDate.format("YYYY-MM-DD")
			  ).originaltimeSlots
			);
			timeSlots.push(
			  ...getTimeSlots(
				v.from,
				v.to,
				slotDuration,
				currentDate.format("YYYY-MM-DD")
			  ).timeSlots
			);
			return v;
		  });
	
		setAvailableTime(originaltimeSlots);
		setAvailableTimeTZ(timeSlots);
	  };
	
	  const haandleDurationChange = (due) => {
		const currentDate = moment(startDate);
		const dayOfWeek = currentDate.format("dddd");
		const slotDuration = due;
		const timeSlots = [];
		const originaltimeSlots = [];
		setAvailableTime();
		console.log("#####",userAvailTZ?.availability);
		userAvailTZ?.availability?.time_ranges[dayOfWeek].length > 0 &&
		  userAvailTZ?.availability?.time_ranges[dayOfWeek]?.map((v, i) => {
			originaltimeSlots.push(
			  ...getTimeSlots(
				v.from,
				v.to,
				slotDuration,
				currentDate.format("YYYY-MM-DD")
			  ).originaltimeSlots
			);
			timeSlots.push(
			  ...getTimeSlots(
				v.from,
				v.to,
				slotDuration,
				currentDate.format("YYYY-MM-DD")
			  ).timeSlots
			);
			return v;
		  });
		setAvailableTime(originaltimeSlots);
		setAvailableTimeTZ(timeSlots);
	  };
	  const getTimeSlots = (startTime, endTime, slotDuration, currentDate) => {
		const timeSlots = [];
		const originaltimeSlots = [];
		const startDateTime = moment(
		  `${currentDate} ${startTime}`,
		  "YYYY-MM-DD HH:mm:ss"
		);
		const endDateTime = moment(
		  `${currentDate} ${endTime}`,
		  "YYYY-MM-DD HH:mm:ss"
		);
		let currentTime = startDateTime;
		while (currentTime.isSameOrBefore(endDateTime)) {
		  const currentTimeWithTZ = moment.tz(
			currentTime.format("YYYY-MM-DDTHH:mm:ss"),
			userAvailTZ?.settings?.timezone
		  );
		  originaltimeSlots.push(
			moment(currentTimeWithTZ).format("YYYY-MM-DD HH:mm:ss")
		  );
		  timeSlots.push(
			moment(currentTimeWithTZ).tz(timezone).format("YYYY-MM-DD HH:mm:ss")
		  );
		  currentTime.add(slotDuration, "minutes");
		}
		originaltimeSlots.pop();
		timeSlots.pop();
		return {
		  originaltimeSlots,
		  timeSlots,
		};
	  };
	
	  const convertTimesToSelectedTimezone = (selectedTimezone) => {
		setTimezone(selectedTimezone.value);
		setTimezoneName(selectedTimezone.label);
		setAvailableTimeTZ(
		  availableTime?.map((time) => {
			const currentTimeWithTZ = moment.tz(
			  moment(time).format("YYYY-MM-DDTHH:mm:ss"),
			  userAvailTZ?.settings?.timezone
			);
			return moment(currentTimeWithTZ)
			  .tz(selectedTimezone.value)
			  .format("YYYY-MM-DD HH:mm:ss");
		  })
		);
	  };
	  const minDate = () => {
		var currentDate = new Date();
		currentDate.setDate(currentDate.getDate() + 1);
		return currentDate;
	  };
	  const titleDisabled = () => {
		return ({ date, view }) => {
		  return isExcludedDate(date);
		};
	  };
	  const customStyles = {
		option: (provided) => ({
		  ...provided,
		  textAlign: "left",
		}),
		singleValue: (provided) => ({
		  ...provided,
		  textAlign: "left",
		}),
		placeholder: (provided) => ({
		  ...provided,
		  textAlign: "left",
		  fontSize: 14,
		}),
	  };
	
	//   const handleNextStep = () => {
	// 	if (Object.values(errors).every((error) => !error)) {
	// 	  setStep((prevStep) => prevStep + 1);
	// 	} else {
	// 	  setErrors({ ...errors, general: "Please fill all the required fields" });
	// 	}
	//   };
	
	//   const handlePreviousStep = () => {
	// 	setStep((prevStep) => prevStep - 1);
	//   };
	
	  const isAllWeekUnavailable = () => {
		let data = LoginData;
		const role = LoginData?.roles[0]?.name;
		let allNull = false;
		if (role === "taxpro") {
		  const objData = data.availability?.time_ranges;
		  allNull = Object.values(objData).every((value) => value === null);
		} else if (role === "customer") {
		  const objData = data?.lead?.[0]?.userpro?.user.availability?.time_ranges;
		  allNull = Object.values(objData).every((value) => value === null);
		}
		if (allNull) {
		  return true;
		}
		return false;
	  };
	return (
		<form className="appointmentsform needs-validation" onSubmit={handleVideoMeeting}>
			<div className="row">{message.show ? <Message message={message} /> : <></>}</div>
			<div className="row">
				<div className="col-md-6">
					<div className="form-floating BookAppointmentForm__calendar">
					<Calendar
                  className="border-0 bg-white"
                  tileClassName=""
                  value={startDate}
                  onChange={haandleChange}
                  tileDisabled={titleDisabled()}
                  minDate={minDate()}
                  // maxDate={maxDate()}
                  next2Label={null}
                  prev2Label={null}
                  formatShortWeekday={(locale, date) =>
                    ["S", "M", "T", "W", "T", "F", "S"][date.getDay()]
                  }
                  showFixedNumberOfWeeks={true}
                />
					</div>
				</div>
				<div className="col-md-6 mt-3 mt-md-0">
              <ReactSelect
                id="users"
                className="p-0 basic-single"
                placeholder="Select User"
                styles={customStyles}
                closeMenuOnSelect={true}
                // options={americanTimeZones}
                options={[
                  ...topTimezones?.map((v) => ({
                    value: v,
                    label: timezoneFormatter(v),
                  })),
                  ...americanTimeZones?.map((v) => ({
                    value: v,
                    label: timezoneFormatter(v),
                  })),
                ]}
                onChange={(v) => convertTimesToSelectedTimezone(v)}
                value={{
                  value: timezone,
                  label: `${timezoneFormatter(timezoneName)} (${convertTime(
                    timezone,
                    "h:mm A"
                  )})`,
                }}
              />
              <ul
                className="w-100 list-group list-group-flush mt-3"
                style={{ height: 216 }}
              >
                {isAllWeekUnavailable() &&
                  LoginData?.roles[0]?.name === "customer" && (
                    <div class="alert alert-muted">
                      Your Tax Professional does not have any Availability right
                      now
                    </div>
                  )}
                {isAllWeekUnavailable() &&
                  LoginData?.roles[0]?.name === "taxpro" && (
                    <div class="alert alert-danger">
                      You do not have any availability
                    </div>
                  )}
                <Scrollbar width={3} className="w-100 h-100">
					{console.log("@888",availableTimeTZ)}
                  {availableTimeTZ?.map((time, index) => (
                    <li
                      key={index}
                      className={`list-group-item mt-2 py-1 cursor-pointer${
                        startDate &&
                        moment(time).hours() === startDate.getHours() &&
                        moment(time).minutes() === startDate.getMinutes()
                          ? " bg-primary text-white"
                          : " bg-light text-black"
                      }`}
                      onClick={() => {
                        const newStartDate = moment(time);
                        newStartDate.hours(moment(time).hours());
                        newStartDate.minutes(moment(time).minutes());
                        setStartDate(newStartDate.toDate());
                      }}
                    >
                      {moment(time).format("h:mm A")}
                    </li>
                  ))}
                </Scrollbar>
              </ul>
            </div>
				<div className="col-md-12 my-3">
					<div className="row justify-content-center">
					{durationOptions?.map((option) => (
                  <div className="col-md-3" key={option.id}>
                    <Checkbox
                      type="radio"
                      wrapperClass="text-start m-0"
                      name="durationRadios"
                      disabled={isAllWeekUnavailable()}
                      state={duration}
                      value={option.id}
                      label={option.name}
                      fn={(e) => {
                        setDuration(e);
                        haandleDurationChange(e);
                      }}
                      id={`ad-radio-${option.id}`}
                      required={true}
                    />
                  </div>
                ))}
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12 mb-3">
					<input
						className="form-check-input me-2"
						id="flexCheckChecked"
						type="checkbox"
						onChange={(e) => setVideoAppointment(e.target.checked)}
						checked={videoAppointment}
					/>
					<label className="form-check-label" htmlFor="flexCheckChecked">
						Video Appointment
					</label>
				</div>
			</div>
			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mt-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary${
					Object.values(errors).indexOf(true) > -1 || !startDate ? ' disabled' : ''
				}`}
				buttonText="Reshcedule Appointment"
			/>
		</form>
	);
}
