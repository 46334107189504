/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';
import { HandleFormData } from '../../../../utilites';

const ClientIntakeTPBO = () => {

	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	// const { otherQuestions } = GLOBAL_VARIABLES;
	
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 0 });
	
	const dispatch=useDispatch()
	const [{ taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);
    // form States
	const [companyName, setCompanyName] = useState();
	const [contactName, setContactName] = useState();
	const [companyWebsite, setCompanyWebsite] = useState();
	const [contactPhoneNumber, setContactPhoneNumber] = useState();
	const [contactEmailAddress, setContactEmailAddress] = useState();

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.ClientIntakeTPBO) {

			if (Object.keys(taxPayerForm?.data?.ClientIntakeTPBO).length === 0) return;

			const {
				companyName,
				contactName,
				companyWebsite,
				contactPhoneNumber,
				contactEmailAddress,
			} = taxPayerForm?.data?.ClientIntakeTPBO;

			setCompanyName(companyName);
			setContactName(contactName);
			setCompanyWebsite(companyWebsite);
			setContactPhoneNumber(contactPhoneNumber);
			setContactEmailAddress(contactEmailAddress);
		}
	}, [taxPayerForm?.data?.ClientIntakeTPBO]);

	const handleSubmit = (e) => {
		e.preventDefault();
		let FormObj = {
			companyName,
			contactName,
			companyWebsite,
			contactPhoneNumber,
			contactEmailAddress
		};

		let formData = taxPayerForm;
		taxPayerForm.data.ClientIntakeTPBO = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
	};

	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<small className="ml-4 fw-bold">Client inTake</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="companyName"
						value={companyName}
						placeholder="Client Company Name"
						fn={setCompanyName}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="middleName"
						value={contactName}
						placeholder="Key Contact Name"
						fn={setContactName}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="text"
						name="lastName"
						value={contactPhoneNumber}
						placeholder="Key Contact Phone Number"
						fn={setContactPhoneNumber}
						mask={"999999999"}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<Input
						type="email"
						name="lastName"
						value={contactEmailAddress}
						placeholder="Key Contact Email Address"
						fn={setContactEmailAddress}
					/>
				</div>
				<div className="col-md-12 mb-3">
					<Input
						type="text"
						name="lastName"
						value={companyWebsite}
						placeholder="Client Company Website"
						fn={setCompanyWebsite}
					/>
				</div>
			</div>
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default ClientIntakeTPBO;
