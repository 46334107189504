/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '../../fields/Button';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';
import { HandleFormData } from '../../../../utilites';

const QuestionnairePart1 = ({
	questions = [],
	formName = 'formName',
	parentIndex = 2,
	myIndex = 0,
}) => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//

	const dispatch = useDispatch();

	const [{ taxPayerFormData, taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex, myIndex });
	const [questionariePart1, setQuestionariePart1] = useState([]);
	const [errors, setErrors] = useState({}); // Track errors for form fields
	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------
	useEffect(() => {
		setQuestionariePart1(JSON.parse(JSON.stringify(questions)));
	}, [questions]);

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.[formName]) {
			if (Object.keys(taxPayerForm?.data?.[formName]).length === 0) return;

			const { questionariePart1 } = taxPayerForm?.data?.[formName];
			setQuestionariePart1(questionariePart1);
		}
	}, [taxPayerForm?.data?.[formName]]);

	// ---------------------------------------
	// Functions
	// ---------------------------------------

	const handleQuestions = (index, bool) => {
		const localEventOptions = questionariePart1.slice();
		localEventOptions[index].bool = bool;
		setQuestionariePart1(localEventOptions);
	};

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};
		// Add more validation rules as needed for other fields
		if (
			questionariePart1
				?.flatMap((data) => {
					return data?.bool;
				})
				.includes(null)
		) {
			newErrors.AnswerOtherQuestions = 'Please Select Required Option';
		}
		setErrors(newErrors);
		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let errors = check_Validity();
		if (errors) return;
		let FormObj = { questionariePart1 };
		let formData = taxPayerForm;
		taxPayerForm.data[formName] = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));
		uploadFormData(formData);
	};

	const goBack = () => {
		// CHANGE FORM TO BACK
		dispatch(
			allActions.taxForms.moveToBack({
				parentIndex,
				myIndex,
				taxPayerFormData,
			})
		);
	};
	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<div className="mt-3 row justify-content-between px-3">
				<p className="fw-bold">
					The following questions apply to the 2322 tax year unless otherwise specified.
				</p>
				<div className=" mb-3 px-3">
					{questionariePart1?.map((data, i) => {
						return (
							<div key={i} className="w-100  align-items-center justify-content-between mt-2">
								<small className="col-md-12 form-check-label text-start" htmlFor={`checkbox-${i}`}>
								{data.question}{data.bool===null&&<span className='text-danger small-text mx-1'>*Required</span>}
								</small>
								<div className="col-md-12">
									<input
										onChange={() => handleQuestions(i, true)}
										checked={data?.bool === true}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 176}`}
										name={`checkbox-${i + 176}`}
									/>
									<label htmlFor={`checkbox-${i + 176}`} role="button" className="px-3 m-0 small">
										Yes
									</label>
									<input
										onChange={() => handleQuestions(i, false)}
										checked={data?.bool === false}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 999}`}
										name={`checkbox-${i + 999}`}
									/>
									<label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
										No
									</label>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default QuestionnairePart1;
