import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import Input from './fields/Input';
import Button from './fields/Button';
import Message from './fields/Message';
import { useDispatch } from 'react-redux';
import allActions from '../../Store/action';
import { RECAPTCHA_SITE_KEY } from '../../utilites';

export default function RegistrationForm() {
	let dispatch = useDispatch();
	const reCaptchaRef = useRef();
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [email, setEmail] = useState('');
	const [username, setusername] = useState('');
	const [password, setPassword] = useState('');
	const [passwordRepeat, setPasswordRepeat] = useState('');
	const [message, setMessage] = useState({ show: false });
	const button = document.querySelector('form.registrationform button.btn');
	const reset_mesage = () => {
		setMessage({ show: false });
	};

	const resetForm = () => {
		setFirstname('');
		setLastname('');
		setEmail('');
		setusername('');
		setPassword('');
		setPasswordRepeat('');
	};

	const change_spinner = (spin) => {
		if (spin) {
			button.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...`;
		} else {
			button.innerHTML = `Sign Up`;
		}
	};

	// function to handle form form submission
	const handleSubmit = async (e) => {
		// Reset User Action Values
		dispatch(allActions.user.reset_user());
		// Reset User State Values
		reset_mesage();
		e.preventDefault();

		const recaptcha_token = await reCaptchaRef.current.executeAsync();

		// Validation of Password
		if (password !== passwordRepeat) {
			setMessage({
				show: true,
				type: 'Error',
				text: 'Password does not match the Repeat Password',
			});
			return;
		}
		// redirect after 3 seconds for now
		// setTimeout(() => {
		//     ;
		// }, 1500);
		let user_obj = {
			recaptcha_token,
			firstname,
			lastname,
			username,
			email,
			password,
		};
		// adding a bootstrap spinner uppon form submission
		change_spinner(true);
		dispatch(await allActions.user.Signup(user_obj, setMessage, resetForm, change_spinner));
		// Reset reCaptcha
		reCaptchaRef.current.reset();
	};

	return (
		<div className={`loginform needs-validation`}>
			<form className='registrationform needs-validation' onSubmit={handleSubmit}>
				{message.show ? <Message message={message} /> : <></>}
				<div className='mb-3 '>
					<Input
						type='text'
						value={firstname}
						name='firstname'
						placeholder='First Name'
						fn={setFirstname}
						showLabel={false}
					/>
				</div>
				<div className='mb-3 '>
					<Input
						type='text'
						value={lastname}
						name='lastname'
						placeholder='Last Name'
						fn={setLastname}
						showLabel={false}
					/>
				</div>
				<div className='mb-3'>
					<Input
						type='text'
						value={username}
						name='username'
						placeholder='Username'
						fn={setusername}
						showLabel={false}
					/>
				</div>
				<div className='mb-3'>
					<Input
						type='email'
						value={email}
						name='email'
						placeholder='Email Address'
						fn={setEmail}
						showLabel={false}
					/>
				</div>
				<div className='mb-3 position-relative'>
					<Input
						type='password'
						value={password}
						name='password'
						placeholder='Password'
						fn={setPassword}
						showLabel={false}
						secure={true}
					/>
				</div>
				<div className='mb-3 position-relative'>
					<Input
						type='password'
						value={passwordRepeat}
						name='passwordRepeat'
						placeholder='Password Confirmation'
						fn={setPasswordRepeat}
						showLabel={false}
						secure={true}
					/>
				</div>
				<ReCAPTCHA
					className='d-none'
					sitekey={RECAPTCHA_SITE_KEY}
					size='invisible'
					ref={reCaptchaRef}
				/>
				<Button
					wrapperClass='clearfix text-center text-md-start'
					mainClass={`btn btn-primary fs-5 fw-semibold py-2 mt-4 w-100 px-3`}
					buttonText='Sign Up'
				/>
				<div className='text-center mt-3'>
					<Link to='/login' className='d-inline-block px-2 border-end text-decoration-none'>
						Login to Account
					</Link>
					<Link to='/forgot-password' className='d-inline-block px-2 text-decoration-none'>
						Forgot Password?
					</Link>
				</div>
			</form>
		</div>
	);
}
