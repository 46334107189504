import { GLOBAL_ACTYPE_TYPE } from "./Type";

const DEFAULT_STATE = {
  Loader: false,
};

const INITIAL_STATE = {
  ...DEFAULT_STATE,
};

const global = (state = INITIAL_STATE, action) => {
  if (action.type === GLOBAL_ACTYPE_TYPE.LOADER) {
    return {
      ...state,
      Loader: action.status,
    };
  }
  return state;
};

export default global;
