import { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { BsPersonPlus } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import SweetAlert2 from "react-sweetalert2";
import axios from "axios";
import DatePicker from "react-datepicker";
import allActions from "../../../Store/action";
import {
  BASEURL,
  setQueryParams,
  getQueryParam,
  removeQueryParam,
  getAppointmentStatus,
  getAppointmentStatusClass,
  timezoneShortFormatter,
  convertTimeIntoSselectedTZ,
} from "../../../utilites";
import ListPageWrapper from "../components/ListPageWrapper";
import Input from "../../../components/forms/fields/Input";
import Modal from "../../../components/Dashboard/Modal";
import AddAppointmentForm from "../../../components/forms/AddAppointmentForm";
import EditAppointmentForm from "../../../components/forms/EditAppointmentForm";
import AddAppointmentUserForm from "../../../components/forms/AddAppointmentUserForm";
import Offcanvas from "../../../components/Dashboard/Offcanvas";
import AppointmentOffcanvas from "../components/AppointmentOffcanvas";
import UserAvatar from "../../../components/UserAvatar";
import RescheduleAppointmentForm from "../../../components/forms/RescheduleAppointmentForm";
import moment from "moment-timezone";
import { IoIosLock } from "react-icons/io";


const appointmentTypes = [
  { id: 0, name: "Onboarding" },
  { id: 1, name: "Walkthrough (Coaching)" },
  { id: 2, name: "Tax Strategy & Financing" },
  { id: 3, name: "Technical Support" },
  { id: 4, name: "Others" },
];

const AppointmentList = () => {
  const [
    { LoginData },
    {
      appointments,
      usersForAppointment,
      currentUserAppointments,
      userAppointments,
    },
  ] = useSelector((state) => [state.user, state.dashboard]);
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [alertMessage, setAlertMessage] = useState("");
  const [swalProps, setSwalProps] = useState({});

  // Filtering
  const [keyword, setKeyword] = useState(getQueryParam("search") || "");
  const [startDate, setStartDate] = useState(
    getQueryParam("starts_after")
      ? new Date(getQueryParam("starts_after"))
      : null
  );
  const [status, setStatus] = useState(getQueryParam("status") || "");
  // Pagination
  const [page, setPage] = useState(getQueryParam("page") || 1);
  const [perPage, setPerPage] = useState(getQueryParam("per_page") || 10);
  const dispatch = useDispatch();

  var userPro = null;
  switch (LoginData?.roles[0]?.name) {
    case "customer":
      userPro = LoginData?.lead?.[0]?.userpro?.user;
      break;
    case "taxpro":
      userPro = LoginData;
      break;

    default:
      break;
  }

  useEffect(() => {
    dispatch(
      allActions.dashboard.getAppointments(
        keyword,
        status,
        startDate,
        page,
        perPage
      )
    );
  }, [dispatch, keyword, status, startDate, page, perPage]);

  useEffect(() => {
    dispatch(allActions.dashboard.getUsersForAppointment());
  }, [dispatch]);

  const loadAppointments = () => {
    dispatch(
      allActions.dashboard.getAppointments(
        keyword,
        status,
        startDate,
        page,
        perPage
      )
    );
    dispatch(allActions.dashboard.getUsersForAppointment());
  };

  useEffect(() => {
    if (LoginData?.isTaxPro || LoginData?.isCustomer)
      dispatch(allActions.dashboard.getUserAppointment(userPro?.id));
  }, [dispatch, LoginData, userPro?.id]);

  const editAppointmentUserStatus = (id, status_id) => {
    axios
      .put(`${BASEURL}/api/appointments/change-status/${id}/${status_id}`)
      .then(() => {
        loadAppointments();
      })
      .then(() => {
        setAlertMessage("Appointment status has been updated successfully!");
      });
  };

  const handleStatusClick = (id) => {
    setSwalProps((prev) => ({
      ...prev,
      [id]: {
        show: true,
        showDenyButton: true,
        title: "Are you sure?",
        confirmButtonText: "Confirm",
        denyButtonText: "Reject",
        didClose: () =>
          setSwalProps((prev) => ({ ...prev, [id]: { show: false } })),
        onResolve: (event) => {
          if (event.isDismissed) return;
          if (event.isConfirmed) {
            editAppointmentUserStatus(id, 1);
          } else {
            editAppointmentUserStatus(id, 2);
          }
        },
      },
    }));
  };

  const deleteAppointmentUser = (id, appointment_id) => {
    axios
      .post(`${BASEURL}/api/appointments/remove-user/${id}/${appointment_id}`)
      .then(() => {
        // Reload appointments
        loadAppointments();
        // Set alert message
        setAlertMessage("User has been removed from appointment successfully!");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeleteUserClick = (id, appointment_id) => {
    setSwalProps((prev) => ({
      ...prev,
      [`a${appointment_id}-au${id}`]: {
        show: true,
        showDenyButton: true,
        title: "Are you sure?",
        confirmButtonText: "Confirm",
        denyButtonText: "Cancel",
        didClose: () =>
          setSwalProps((prev) => ({
            ...prev,
            [`a${appointment_id}-au${id}`]: { show: false },
          })),
        onConfirm: () => deleteAppointmentUser(id, appointment_id),
      },
    }));
  };

  const handleDeleteAppointmentClick = (id) => {
    setSwalProps((prev) => ({
      ...prev,
      [`ad-${id}`]: {
        show: true,
        showDenyButton: true,
        title: "Are you sure?",
        confirmButtonText: "Confirm",
        denyButtonText: "Cancel",
        didClose: () =>
          setSwalProps((prev) => ({
            ...prev,
            [`ad-${id}`]: { show: false },
          })),
        onConfirm: () =>
          dispatch(
            allActions.dashboard.deleteAppointment(
              id,
              null,
              setAlertMessage,
              loadAppointments
            )
          ),
      },
    }));
  };

  const moveTo = (page) => {
    setPage(page);
    // Set query param
    setQueryParams("page", page);
  };

  const changePerPage = (perPage) => {
    setPerPage(perPage);
    // Set query param
    setQueryParams("per_page", perPage);
  };

  const filterByKeyword = (keyword) => {
    setKeyword(keyword);
    // Set query param
    if (!keyword) {
      removeQueryParam("search");
    } else {
      setQueryParams("search", keyword);
    }
  };

  const filterByStatus = (st) => {
    setStatus(st);
    // Set query param
    if (!st) {
      removeQueryParam("status");
    } else {
      setQueryParams("status", st);
    }
  };

  const filterByStartDate = (date) => {
    setStartDate(date);
    // Set query param
    if (!date) {
      removeQueryParam("starts_after");
    } else {
      setQueryParams("starts_after", date);
    }
  };

  return (
    <ListPageWrapper
      heading="Appointments"
      headerControls={
        <Fragment>
          <div className="col-md-10">
            <div className="row g-3">
              <div className="col-md-3">
                <Input
                  type="search"
                  value={keyword}
                  name="keyword"
                  inputClass="search-input border h-auto min-height-initial"
                  required={false}
                  showLabel={false}
                  placeholder="Search by topic"
                  fn={filterByKeyword}
                  extraSetter={() => {
                    if (page !== 1) moveTo(1);
                  }}
                />
              </div>
              <div className="col-md-2">
                <div className="form-floating">
                  <DatePicker
                    className="form-select"
                    wrapperClassName="d-block"
                    selected={startDate}
                    onChange={(date) => {
                      filterByStartDate(date);
                      if (page !== 1) moveTo(1);
                    }}
                    dateFormat="MMMM d, yyyy"
                    placeholderText="Date Created"
                    isClearable
                  />
                </div>
              </div>
              <div className="col-md-2">
                <select
                  className="form-select"
                  id="filter"
                  name="status"
                  onChange={(e) => {
                    filterByStatus(e.target.value);
                    if (page !== 1) moveTo(1);
                  }}
                  value={status}
                >
                  <option value="">Status</option>
                  <option value="pending">Pending</option>
                  <option value="confirmed">Confirmed</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            {LoginData?.userpro?.status_id === 6 ? <button
								className="btn btn-sm btn-outline-primary px-2 float-md-end float-none d-flex align-tems-center gap-1"
								disabled={true}
							>
                Add appointment
                <IoIosLock style={{ fontSize: '16px' }} />
							</button> : 
              (LoginData?.isTaxPro ||
              (LoginData?.isCustomer && LoginData?.userpro?.status_id !== 6 && (LoginData?.lead[0]?.userpro?.user?.settings?.allow_appointments)) ? (
              <Link
                to="#"
                className="w-100 btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#addAppointmentModal"
              >
                Add appointment
              </Link>
            ) : null)}
          </div>
        </Fragment>
      }
      underHeader={
        <Fragment>
          {appointments.statistics &&
          (LoginData.isAdmin ||
            LoginData.isAccountManager ||
            LoginData.isSupport) ? (
            <Fragment>
              <div className="col-md-3">
                <div className="card border-light text-center">
                  <div className="card-body">
                    <h5 className="card-title">Appointments 24 hours</h5>
                    <p className="card-text fw-bold fs-3">
                      {appointments.statistics.last24hours}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card border-light text-center">
                  <div className="card-body">
                    <h5 className="card-title">Appointments last 7 days</h5>
                    <p className="card-text fw-bold fs-3">
                      {appointments.statistics.last7days}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card border-light text-center">
                  <div className="card-body">
                    <h5 className="card-title">Appointments last 30 days</h5>
                    <p className="card-text fw-bold fs-3">
                      {appointments.statistics.last30days}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card border-light text-center">
                  <div className="card-body">
                    <h5 className="card-title">Appointment rates all time</h5>
                    <p className="card-text fw-bold fs-3">
                      {appointments.statistics.alltime}
                    </p>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : null}
        </Fragment>
      }
      alert={{ message: alertMessage, setMessage: setAlertMessage }}
      dataState={{
        loaded: appointments.loaded,
        fetching: appointments.fetching,
      }}
      tableHeader={
        <Fragment>
          <th className="d-none d-md-block" scope="col">
            ID
          </th>
          <th  scope="col">Type</th>
          <th scope="col">Users</th>
          <th scope="col">Name</th>
          <th className="d-none d-md-block" scope="col">
            Topic
          </th>
          <th scope="col">Date</th>
          <th className="text-center" scope="col">
            Status
          </th>
          <th scope="col"></th>
        </Fragment>
      }
      tableBody={
        <Fragment>
          {appointments.data.length === 0 ? (
            <tr className="text-center text-muted">
              <td colSpan={7}>You have not created any appointment yet.</td>
            </tr>
          ) : (
            <Fragment>
              {appointments.data?.map((appointment) => (
                <tr
                  key={appointment.id}
                  className="text-muted align-middle"
                >
                  <th scope="row">{appointment.id}</th>
                  
                  <td  className="text-left">
                    <Link
                      to="#"
                      className="text-dark text-decoration-none"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#openAppointmentDetailsPanel"
                      aria-controls="openAppointmentDetailsPanel"
                      onClick={() => setSelectedAppointment(appointment)}
                    >
                      {
                        appointmentTypes.find(
                          (a) => a.id === appointment.type_id
                        ).name
                      }
                    </Link>
                  </td>
                
                  <td>
                    <Link to="#" className="d-inline-block me-1">
                      <UserAvatar
                        avatar={appointment?.creator?.avatar}
                        alt={`${appointment?.creator?.firstname} ${appointment?.creator?.lastname}`}
                        className="rounded-circle"
                        width="35"
                        height="35"
                        data-tooltip-id={`appointment-${appointment.id}_creator-avatar-tooltip`}
                      />
                      <Tooltip
                        className="bg-black text-white"
                        style={{ zIndex: 1 }}
                        id={`appointment-${appointment?.id}_creator-avatar-tooltip`}
                        place="bottom"
                        content={`${appointment?.creator?.firstname} ${appointment?.creator?.lastname}`}
                      />
                    </Link>
                    {appointment.users?.length > 0 &&
                      appointment.users?.map((user) => (
                        <div
                          className="ParticipantList_avatar-card d-inline-block position-relative"
                          key={user.uuid}
                        >
                          <Link to="#" className="d-inline-block me-1">
                            <UserAvatar
                              avatar={user.avatar}
                              alt={`${user.firstname} ${user.lastname}`}
                              className="rounded-circle"
                              width="35"
                              height="35"
                              data-tooltip-id={`appointment-${appointment.id}_user-${user.uuid}-avatar-tooltip`}
                            />
                            <Tooltip
                              className="bg-black text-white"
                              style={{ zIndex: 1 }}
                              id={`appointment-${appointment.id}_user-${user.uuid}-avatar-tooltip`}
                              place="bottom"
                              content={`${user.firstname} ${user.lastname}`}
                            />
                          </Link>
                          {(LoginData.isAdmin ||
                            appointment.creator_user_id === LoginData.id) && (
                            <Fragment>
                              <button
                                className="btn btn-sm bg-danger position-absolute top-0 end-0 translate-middle p-1 rounded-circle z-index-1"
                                onClick={() =>
                                  handleDeleteUserClick(user.id, appointment.id)
                                }
                                style={{ fontSize: "0.8rem" }}
                              />
                              <SweetAlert2
                                {...swalProps[
                                  `a${appointment.id}-au${user.id}`
                                ]}
                              />
                            </Fragment>
                            )}
                        </div>
                      ))}
                    {LoginData?.userpro?.status_id === 6 ? <div
                      className=" px-2 d-inline-block"
                      disabled={true}
                    >
                      <BsPersonPlus />  <IoIosLock style={{ fontSize: '16px',color:'#a8a8a8'}} />
                    </div> : (LoginData.isAdmin ||
                      appointment.creator_user_id === LoginData.id) &&
                    usersForAppointment &&
                    usersForAppointment.length > 0 && (
                      <Link
                        to="#"
                        className="d-inline-block px-2"
                        data-bs-toggle="modal"
                        data-bs-target="#addUsersToAppointment"
                        onClick={() => setSelectedAppointment(appointment)}
                      >
                        <BsPersonPlus />
                      </Link>
                    )}
                  </td>
                  <td className="d-flex">
                    {LoginData.roles[0].name !== 'taxpro' && <div className="d-flex gap-1">
                      <div>{appointment?.creator?.firstname}</div>
                      <div>{appointment?.creator?.lastname}</div>
                    </div> }
                    {LoginData.roles[0].name === 'taxpro' && <div className="d-flex gap-1">
                    <div>{appointment.users[0]?.firstname}</div>
                    <div>{appointment.users[0]?.lastname}</div>
                      </div>}
                  </td>
                  <td>
                    <Link
                      to="#"
                      className="text-dark text-decoration-none"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#openAppointmentDetailsPanel"
                      aria-controls="openAppointmentDetailsPanel"
                      onClick={() => setSelectedAppointment(appointment)}
                    >
                      {appointment.title}
                    </Link>
                  </td>
                  <td>
                    {convertTimeIntoSselectedTZ(
                      appointment?.timezone,
                      moment.tz.guess(),
                      moment.utc(appointment.start_date),
                      "lll"
                    )}{" "}
                    {timezoneShortFormatter(moment.tz.guess())}
                  </td>
                  <td className="text-center">
                    {LoginData.isAdmin ||
                    appointment.creator_user_id === LoginData.id ? (
                      <Fragment>
                        {appointment.users.every(
                          (user) => user.appointmentuser.status_id === 1
                        ) ? (
                          <span className="badge bg-secondary-600">
                            Confirmed
                          </span>
                        ) : appointment.users.every(
                            (user) => user.appointmentuser.status_id === 2
                          ) ? (
                          <span className="badge bg-danger">Rejected</span>
                        ) : (
                          <span className="badge bg-primary">Pending</span>
                        )}
                      </Fragment>
                    ) : (
                      <>
                        {appointment.users.find(
                          (e) => e.appointmentuser.user_id === LoginData.id
                        ) ? (
                          <span
                            className={`badge bg-${getAppointmentStatusClass(
                              appointment.users.find(
                                (e) =>
                                  e.appointmentuser.user_id === LoginData.id
                              ).appointmentuser.status_id
                            )} cursor-pointer`}
                            onClick={() =>
                              handleStatusClick(
                                appointment.users.find(
                                  (e) =>
                                    e.appointmentuser.user_id === LoginData.id
                                ).appointmentuser.appointment_id
                              )
                            }
                          >
                            {getAppointmentStatus(
                              appointment.users.find(
                                (e) =>
                                  e.appointmentuser.user_id === LoginData.id
                              ).appointmentuser.status_id
                            )}
                          </span>
                        ) : null}
                        <SweetAlert2
                          {...swalProps[
                            appointment.users.find(
                              (e) => e.appointmentuser.user_id === LoginData.id
                            ).appointmentuser.appointment_id
                          ]}
                        />
                      </>
                    )}
                  </td>
                  <td className="text-end">
                    <div className="dropdown-center">
                     {LoginData?.userpro?.status_id === 6 ? <IoIosLock style={{fontWeight:'20px',color:'#a8a8a8'}} /> :  <button
                        type="button"
                        className={`btn btn-sm btn-outline-primary rounded-circle${
                          (LoginData.isTaxPro &&
                            appointment?.creator?.roles.filter(
                              (r) => r.name.toLowerCase() === "customer"
                            ).length > 0) ||
                          appointment.creator_user_id === LoginData.id
                            ? ""
                            : " disabled"
                        }`}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <BsThreeDotsVertical className="" />
                      </button>}
                      <ul className="dropdown-menu border-light">
                        {appointment.creator_user_id === LoginData.id ? (
                          <Fragment>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#openAppointmentQuickEditModal"
                                onClick={() =>
                                  setSelectedAppointment(appointment)
                                }
                              >
                                Edit
                              </Link>
                            </li>
                            <li>
                              <hr className="dropdown-divider border-light" />
                            </li>
                            <li>
                              <Link
                                to="#"
                                className={`dropdown-item text-${
                                  appointment.creator_user_id === LoginData.id
                                    ? "danger"
                                    : "grey disabled"
                                }`}
                                onClick={() => {
                                  handleDeleteAppointmentClick(appointment.id);
                                }}
                              >
                                Delete
                                <SweetAlert2
                                  {...swalProps[`ad-${appointment.id}`]}
                                />
                              </Link>
                            </li>
                          </Fragment>
                        ) : (
                          <li>
                            <Link
                              to="#"
                              className={`dropdown-item${
                                appointment.users.some(
                                  (e) => e.appointmentuser.status_id === 2
                                )
                                  ? " disabled"
                                  : ""
                              }`}
                              data-bs-toggle="modal"
                              data-bs-target="#openAppointmentRescheduleModal"
                              onClick={() =>
                                setSelectedAppointment(appointment)
                              }
                            >
                              Reschedule
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            </Fragment>
          )}
        </Fragment>
      }
      paginationProps={{
        pageCount: Math.ceil(appointments.count / perPage),
        currentPage: parseInt(page),
        setCurrentPage: moveTo,
        perPage: perPage,
        setPerPage: (pc) => {
          changePerPage(pc);
          // Move to first page
          if (page !== 1) moveTo(1);
        },
      }}
      modals={
        <Fragment>
          <Modal
            title="Appointment Maker"
            bodyClass="p-4 text-center text-dark text-opacity-50"
            trigger="addAppointmentModal"
            showBtn={false}
            btnClass="createAppointmentModalClose"
            size="lg"
          >
            <AddAppointmentForm
              appointmentTypes={appointmentTypes}
              users={usersForAppointment}
              taxProPrevAppointments={userAppointments}
              setAlert={setAlertMessage}
              reload={loadAppointments}
              allAppointments={appointments}
            />
          </Modal>
          {usersForAppointment && usersForAppointment.length > 0 && (
            <Modal
              title="Add Users to Appointment"
              bodyClass="p-4 text-center text-dark text-opacity-50"
              trigger="addUsersToAppointment"
              showBtn={false}
              btnClass="addUsersToAppointmentModalClose"
              size="md"
            >
              {selectedAppointment && (
                <AddAppointmentUserForm
                  data={selectedAppointment}
                  users={usersForAppointment}
                  currentUserAppointments={currentUserAppointments}
                  setAlert={setAlertMessage}
                  reload={loadAppointments}
                />
              )}
            </Modal>
          )}
          <Modal
            title="Quick Edit"
            bodyClass="p-4 text-center text-dark text-opacity-50"
            trigger="openAppointmentQuickEditModal"
            showBtn={false}
            btnClass="appointmentQuickEditModalClose"
            size="lg"
          >
            {selectedAppointment && (
              <EditAppointmentForm
                appointmentTypes={appointmentTypes}
                data={selectedAppointment}
                users={usersForAppointment}
                currentUserAppointments={currentUserAppointments}
                reload={loadAppointments}
                setAlert={setAlertMessage}
              />
            )}
          </Modal>
          <Modal
            title="Reschedule Appointment"
            bodyClass="p-4 text-center text-dark text-opacity-50"
            trigger="openAppointmentRescheduleModal"
            showBtn={false}
            btnClass="AppointmentRescheduleModalClose"
            size="lg"
          >
            {selectedAppointment && (
              <RescheduleAppointmentForm
                data={selectedAppointment}
                users={usersForAppointment}
                currentUserAppointments={currentUserAppointments}
                reload={loadAppointments}
                setAlert={setAlertMessage}
              />
            )}
          </Modal>
          <Offcanvas
            header="Appointment Details"
            position="end"
            trigger="openAppointmentDetailsPanel"
            style={{ width: 500 }}
          >
            {selectedAppointment && (
              <AppointmentOffcanvas
                data={selectedAppointment}
                close={() => {
                  document
                    .getElementById("close-openAppointmentDetailsPanel")
                    .click();
                }}
                setAlert={setAlertMessage}
                reload={loadAppointments}
              />
            )}
          </Offcanvas>
        </Fragment>
      }
    />
  );
};

export default AppointmentList;
