import Modal from "../../Dashboard/Modal";
import { Link } from "react-router-dom";
import Input from "../fields/Input";
import Message from "../fields/Message";
export default function BankInputModal({
	accountNo,
	setAccountNo,
	routingNo,
	setRoutingNo,
	accountHolderName,
	setAccountHolderName,
	paymentMessage,
	defaultSelection,
	setDefaultSelection,
	editCard,
	AllowDefaultSelect,
	handleSubmitCard,
	resetWarnings,
	OkButtonText,
}) {
	return (
		<Modal
			title={editCard ? "Edit Bank Card" : "Add New Bank"}
			bodyClass="p-4"
			btnText={editCard ? "Edit Bank Card" : "Add New Bank"}
			trigger="BankPaymentInputForm"
			showBtn={false}
			size={"lg"}
			close={false}
		>
			<form
				className="w-100 d-flex justify-content-between flex-wrap align-item-start"
				onSubmit={(e) => handleSubmitCard(e)}
			>
				<div className="w-100 d-flex align-item-center justify-content-center">
					{" "}
					{paymentMessage.show ? <Message message={paymentMessage} /> : <></>}
				</div>
				<div className={`mb-2 row w-100`}>
					<div className="w-100 mb-3 col-lg-12">
						<Input
							type="text"
							value={accountHolderName}
							name="Account Holder Name"
							placeholder="Account Holder Name"
							fn={setAccountHolderName}
						/>
					</div>

					<div className="mb-3 col-lg-6 ">
						<Input
							type="text"
							value={accountNo}
							name="Account No"
							placeholder="Account No"
							fn={setAccountNo}
							isDisabled={editCard ? true : false}
						/>
					</div>
					{!editCard && (
						<div className="mb-3 col-lg-6 ">
							<Input
								type="text"
								value={routingNo}
								name="Routing No"
								placeholder="Routing No"
								fn={setRoutingNo}
								mask={"999999999"}
								isDisabled={editCard ? true : false}
							/>
						</div>
					)}
				</div>
				<div className="w-full row  d-flex align-items-start justify-content-start">
					<div className="w-full d-flex align-items-start justfy-content-start">
						<input
							className="form-check-input custom-checkbox pl-2"
							type="checkbox"
							id={`checkbox`}
							disabled={!AllowDefaultSelect}
							checked={defaultSelection}
							onChange={() => setDefaultSelection(!defaultSelection)}
						/>
						<label className="form-check-label text-start text-muted" htmlFor={`checkbox`}>
							Make this my default payment method
						</label>
					</div>
				</div>
				<div className="container-fluid content-row  ">
					<div className="d-flex align-items-center justify-content-center row">
						<div className={"col-sm-4 custon-button  mt-4"}>
							<Link
								to="#"
								className="d-block text-decoration-none text-dark BankPaymentInputForm"
								data-bs-toggle="modal"
								data-bs-target="#BankPaymentInputForm"
							>
								<button
									className={"btn btn-info custon-button-text text-white btn-lg w-100"}
									type={"button"}
									onClick={() => {
										resetWarnings();
									}}
								>
									Cancel
								</button>
							</Link>
						</div>
						<div className={"col-sm-4 custon-button  mt-4"}>
							<button
								id="paymentSubmit"
								className={"btn btn-primary custon-button-text btn-lg w-100"}
								type="submit"
							>
								{OkButtonText}
							</button>
						</div>
					</div>
				</div>
			</form>
		</Modal>
	);
}
