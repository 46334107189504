/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Button from './fields/Button';
import Message from './fields/Message';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../Store/action';
import { Link } from 'react-router-dom';
import PaymentModal from './Modal/CardInputModal';
import ConfirmationModal from './Modal/ConfirmationPopup';

import { MdDelete } from 'react-icons/md';
import { BsCheckCircleFill } from 'react-icons/bs';
import { PLAID_ACTION_TYPE } from '../../Store/plaid/Type';
import BankInputModal from './Modal/BankInputModal';
import { MdOutlineRadioButtonUnchecked } from 'react-icons/md';
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
} from '@stripe/react-stripe-js';

const defaultMessage = 'Something went wrong while adding the card';

export default function PaymentForm({ setActive, invoice, membership, membershipCheckout,editCardNested , deletedCardNested,defaultSelect,children }) {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const stripe = useStripe();
	const elements = useElements();
	let dispatch = useDispatch();
	const [
		{ stripeAccountStatusData },
		{
			paymentCardAdd,
			paymentCardAddMessage,
			paymentModificationStatus,
			paymentModificationStatusMessage,
		},
		{ LoginData },
		{ createCustomer, createCustomerMessage },
	] = useSelector((state) => [state.dashboard, state.payment, state.user, state.invoice]);
	// Payment Card Modal States
	const [cvc, setCvc] = useState();
	const [mmYy, setMmYy] = useState();
	const [zipCode, setZipCode] = useState('');
	const [cardName, setCardName] = useState('');
	const [cardNumber, setCardNumber] = useState();
	const [cardObj, setCardObj] = useState();
	const [defaultSelection, setDefaultSelection] = useState();
	const [accountNo, setAccountNo] = useState('');
	const [routingNo, setRoutingNo] = useState('');
	const [accountHolderName, setAccountHolderName] = useState('');
	const [type, setType] = useState();
	const [editCard, setEditCard] = useState(undefined);
	const [paymentMessage, setPaymentMessage] = useState({ show: false });
	const [message, setMessage] = useState({ show: false });
	const [methodSelection, setMethodSelection] = useState([]);
	const [selectedCard, setSelectedCard] = useState();
	// Hide modal on Card Selection
	const [HideModalOnCardSelection, setHideModalOnCardSelection] = useState(true);

	// --------------------------------------------
	// HOOKS (UseEffect used For Different Purposes)
	// --------------------------------------------
	// *****--Hooks To Handle Create Customer--****
	useEffect(() => {
		setDefaultSelection(defaultSelect?true:LoginData?.userpaymentmethod.length === 0 ? true : false);
		if(membership) return setType('card')
		let checkBank = LoginData?.userpaymentmethod?.find(
			(data) => data.payment_method_type === 'bank_account'
		);
		let checkCard = LoginData?.userpaymentmethod?.find(
			(data) => data.payment_method_type === 'card'
		);
		if (checkBank && checkCard) {
			setType('card');
			setMethodSelection(['debitCard']);
			return;
		}
		if (checkBank) {
			setMethodSelection(['bank']);
			setType('bank_account');
			return;
		} else {
			setType('card');
			setMethodSelection(['debitCard']);
			return;
		}
	}, [LoginData?.userpaymentmethod.length,membership]);

	useEffect(() => {
		if (!createCustomer) {
			dispatch(allActions.global.Loader(false));
			setPaymentMessage({
				show: true,
				type: 'Error',
				text: createCustomerMessage,
			});
			handleButton(false);
		}
		if (createCustomer === true) {
			dispatch(allActions.payment.AddInvoiceCard(cardObj));
		}
	}, [createCustomer, createCustomerMessage]);

	// *****--Hooks To Handle Add Card--****
	useEffect(() => {
		setMessage({ show: false });

		// if Failure
		if (!paymentCardAdd) {
			dispatch(allActions.global.Loader(false));
			setPaymentMessage({
				show: true,
				type: 'Error',
				text: paymentCardAddMessage,
			});
			handleButton(false);
		}
		if (paymentCardAdd === true) {
			dispatch(allActions.global.Loader(false));
			if (type === 'card' && HideModalOnCardSelection !== false) {
				document.querySelector('.paymentmodal').click();
			}
			if (type === 'bank_account') document.querySelector('.BankPaymentInputForm').click();
			setMessage({
				show: true,
				type: 'Success',
				text: editCard ? 'Payment Update Successfully' : 'Payment Card Added Successfully',
			});
			reset_CardForm();
			handleButton(false);
		}
	}, [paymentCardAdd, paymentCardAddMessage, editCard,HideModalOnCardSelection]);

	// *****--Hooks To Handle Modify Bank Cards / Details--****
	useEffect(() => {
		setMessage({ show: false });
		if (!paymentModificationStatus) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: paymentModificationStatusMessage,
			});
		}
		if (paymentModificationStatus === true) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Success',
				text: 'Payment Card Removed Successfully',
			});
		}
	}, [paymentModificationStatus, paymentModificationStatusMessage]);



	// *****--Hooks To Call Function from outside--****
	useEffect(() => {
		if (editCardNested) {
			setEditCard(editCardNested);
			resetWarnings();
			set_EditCard(editCardNested);
		}
	}, [editCardNested]);

	useEffect(() => {
		if (deletedCardNested) {
			removeCard(deletedCardNested)
		}
	}, [deletedCardNested]);


	// *****--Functions To Reset Message / Alert--****

	const reset_message = () => {
		dispatch(allActions.payment.resetModifyPayment());
		setMessage({ show: false });
	};

	const reset_payment_message = () => {
		dispatch(allActions.payment.resetAddPayment());
		setPaymentMessage({ show: false });
	};

	// *****--Function To Reset Payment Forms--****

	const resetWarnings = () => {
		reset_message();
		reset_payment_message();
		dispatch(allActions.invoice.resetCreateCustomer());
	};

	const reset_CardForm = () => {
		setCvc('');
		setMmYy('');
		setZipCode('');
		setCardName('');
		setRoutingNo('');
		setAccountNo('');
		setCardNumber('');
		setAccountHolderName('');
		setDefaultSelection(defaultSelect?true:LoginData?.userpaymentmethod.length === 0 ? true : false);
		if (elements.getElement(CardNumberElement))
			elements.getElement(CardNumberElement).update({ value: '', disabled: false });
		if (elements.getElement(CardCvcElement))
			elements.getElement(CardCvcElement).update({ value: '', disabled: false });
		if (elements.getElement(CardExpiryElement))
			elements.getElement(CardExpiryElement).update({ value: '', disabled: false });
	};
	const handleButton = (status) => {
		if (status) {
			const btn = document.getElementById('paymentSubmit');
			btn.setAttribute('disabled', status);
		} else {
			setTimeout(() => {
				const btn = document.getElementById('paymentSubmit');
				btn.removeAttribute('disabled');
			}, 1000);
		}
	};
	// *****--Function To Show Values For Edit Forms --****
	const set_EditCard = (data) => {
		setHideModalOnCardSelection(true)
		// If Debit Card
		if (type === 'card') {
			// Disable Stripe Fields

			setCvc(`***`);
			setZipCode(data?.payment_method_data?.address_zip);
			setCardName(data?.payment_method_data?.name);
			setCardNumber(`**** **** **** **** ${data?.payment_method_data?.last4}`);
			setMmYy(
				`${handleEmptyValue(
					data?.payment_method_data?.exp_month
				)}/${data?.payment_method_data?.exp_year.toString().slice(2, 4)}`
			);
			document.querySelector('.paymentmodal').click();
		}
		if (type === 'bank_account') {
			setAccountHolderName(data?.payment_method_data?.account_holder_name);
			setAccountNo(`**** **** **** **** ${data?.payment_method_data?.last4}`);
			setRoutingNo(data?.payment_method_data?.routing_number);
			document.querySelector('.BankPaymentInputForm').click();
		}
		setDefaultSelection(data?.is_default);
	};

	// *****--Function To Show Values like 09,08 --****
	const handleEmptyValue = (val) => {
		if (val === undefined || val === null || val === '') {
			return '';
		}
		if (val < 10) {
			return '0' + val;
		} else {
			return val;
		}
	};

	// *****--Function To Move To Next Slide in Case of Tax Pro Registration --****

	const moveToNext = () => {
		setMessage({ show: false });
		if (LoginData?.userpaymentmethod && LoginData?.userpaymentmethod.length > 0) {
			if (
				!stripeAccountStatusData?.data?.requirements?.currently_due ||
				stripeAccountStatusData?.data.requirements.currently_due.includes(
					'individual.verification.document'
				)
			) {
				setActive && setActive(5);
			} else {
				setActive && setActive(6);
			}
			reset_All();
			resetWarnings();
		} else {
			setMessage({
				show: true,
				type: 'Error',
				text: 'You need to add at lesast one payment source in order to select "Next"',
			});
		}
	};

	// *****--Function To Go Back To Last Slide --****

	const GoBack = () => {
		resetWarnings();
		dispatch(
			allActions.user.ReGetUserData(() => {
				if (stripeAccountStatusData?.data) {
					setActive && setActive(2);
				} else {
					setActive && setActive(3);
				}
			})
		);
	};

	// *****--Function To Reset Plaid Store & Loading & Messages--****
	const reset_All = () => {
		dispatch(allActions.plaid.dispatchToStore({ type: PLAID_ACTION_TYPE.plaidCreateIdle }));
		dispatch(allActions.plaid.dispatchToStore({ type: PLAID_ACTION_TYPE.plaidGetUploadIdle }));
	};

	// *****--Function To Handle Submit New Card--****

	const handleSubmitCard = async (e) => {
		console.log('@000', stripe);
		e.preventDefault();
		if (!stripe || !elements) {
			return;
		}
		handleButton(true);
		// Reset User State Values
		resetWarnings();
		let cardObj = {
			type,
			card: {
				def: defaultSelection,
			},
			bank_account: {
				account_holder_name: accountHolderName,
				routing_number: routingNo,
				account_number: accountNo,
				def: defaultSelection,
			},
		};
		if (type === 'card') delete cardObj?.bank_account;
		if (type === 'bank_account') delete cardObj?.card;

		dispatch(allActions.global.Loader(true));
		// For CUSTOMER
		// Add Case For Invoice Payment
		if (invoice !== undefined || membership) {
			if (type === 'card') {
				const { paymentMethod, error } = await stripe.createPaymentMethod(
					{
						type: 'card',
						card: elements.getElement(CardNumberElement),
						billing_details: {
							name: cardName,
							address: {
								postal_code: zipCode,
							},
						},
					},
					{
						stripeAccount: 'acct_1OwUX0QhwNsicKyd',
					}
				);
				if (error) {
					setPaymentMessage({
						show: true,
						type: 'Error',
						text: error?.message || defaultMessage,
					});
					handleButton(false);
					dispatch(allActions.global.Loader(false));
					return;
				}
				cardObj.card.cardData = paymentMethod;
			}
			let invoiceCardObj = {
				...cardObj.card,
			};
			if (LoginData?.customers?.length === 0) {
				let customerObj = {
					user_id: LoginData?.id,
					email: LoginData?.email,
					phone: LoginData?.lead?.[0]?.phone,
					name: LoginData?.firstname + ' ' + LoginData?.lastname,
				};
				setCardObj(invoiceCardObj);
				dispatch(allActions.invoice.createCustomer(customerObj));
			} else {
				dispatch(allActions.payment.AddInvoiceCard(invoiceCardObj));
			}
			return;
		}
		// FOR TAXPRO
		if (type === 'card') {
			const cardNumberElement = elements.getElement(CardNumberElement);
			const { token, error } = await stripe.createToken(cardNumberElement, {
				currency: 'usd',
				name: cardName,
				address_zip: zipCode,
			});
			if (error) {
				setPaymentMessage({
					show: true,
					type: 'Error',
					text: error?.message || defaultMessage,
				});
				handleButton(false);
				dispatch(allActions.global.Loader(false));
				return;
			}
			cardObj.card.cardToken = token.id;
		}
		dispatch(allActions.payment.paymentCards(cardObj));
	};
	// *****--Function To Handle Updation of Card--****
	const handleSubmitUpdateCard = (e) => {
		let finalObj = { payment_method_id: editCard?.payment_method_id, type };
		e.preventDefault();
		handleButton(true);
		// Reset User State Values
		resetWarnings();
		const [mm, yyyy] = mmYy?.split('/');
		let cardObj = {
			exp_month: mm,
			exp_year: yyyy,
			def: defaultSelection,
			address_zip: zipCode,
		};
		if (cardObj.def === editCard.is_default) {
			delete cardObj.def;
		}
		let bank_account = {
			account_holder_name: accountHolderName,
			def: defaultSelection,
		};
		if (type === 'card') finalObj.card = cardObj;
		if (type === 'bank_account') finalObj.bank_account = bank_account;
		dispatch(allActions.global.Loader(true));
		// Add Case For Invoice Payment
		if (invoice !== undefined) {
			// delete cardObj?.payment_method_id;
			let invoiceCardObj = {
				...cardObj,
				// customerId: invoice?.customers?.customer_stripe_id,
				paymentId: editCard?.payment_method_id,
				name: editCard?.payment_method_data?.name,
			};
			dispatch(allActions.payment.updateInvoiceCard(invoiceCardObj));
			return;
		}
		dispatch(allActions.payment.paymentCardUpdate(finalObj));
	};

	const handleSetDefault = (data) => {
		setHideModalOnCardSelection(false);
		// setEditCard([]);
		console.log('DATA>>>>', data);

		let finalObj = { payment_method_id: data?.payment_method_id, type: data?.payment_method_type };
		// Reset User State Values
		resetWarnings();

		const monthsYears = `${handleEmptyValue(
			data?.payment_method_data?.exp_month
		)}/${data?.payment_method_data?.exp_year.toString().slice(2, 4)}`;
		const [mm, yyyy] = monthsYears?.split('/');
		let cardObj = {
			exp_month: mm,
			exp_year: yyyy,
			def: true,
			address_zip: data?.payment_method_data?.address_zip,
		};
		if (cardObj.def === data.is_default) {
			delete cardObj.def;
		}
		let bank_account = {
			account_holder_name: data?.payment_method_data?.account_holder_name,
			def: true,
		};
		if (type === 'card') finalObj.card = cardObj;
		if (type === 'bank_account') finalObj.bank_account = bank_account;
		dispatch(allActions.global.Loader(true));
		// Add Case For Invoice Payment
		if (invoice !== undefined || membershipCheckout) {
			// delete cardObj?.payment_method_id;
			let invoiceCardObj = {
				...cardObj,
				// customerId: invoice?.customers?.customer_stripe_id,
				paymentId: data?.payment_method_id,
				name: data?.payment_method_data?.name,
			};
			dispatch(allActions.payment.updateInvoiceCard(invoiceCardObj));
			return;
		}
		dispatch(allActions.payment.paymentCardUpdate(finalObj));
	};
	// Function To Remove Card
	let removeCard = (selectedCard) => {
		const { payment_method_id } = selectedCard;
		reset_message();
		dispatch(allActions.global.Loader(true));
		if (invoice !== undefined) {
			let cardObj = {
				paymentId: selectedCard?.payment_method_id,
			};
			dispatch(allActions.payment.removeInvoiceCard(cardObj));
			if(!deletedCardNested) document.querySelector('.ConfirmationModal').click();
			return;
		}
		dispatch(allActions.payment.removeCard({ payment_method_id }));
		if(!deletedCardNested) document.querySelector('.ConfirmationModal').click();
	};
	return (
		<div>
			<div className="ContactInfoForm needs-validation">
				{message.show ? <Message message={message} /> : <></>}
				{!membership ? (
					<>
						<div className="clearfix w-100 mb-1 w-100">
							{!invoice ? (
								<div className="d-flex align-items-center justify-content-center">
									{membership}
									{!membershipCheckout && (
										<>
											<div className="col-auto">
												<div className="form-check form-check-inline">
													<label className="form-check-label-outer" htmlFor="agreeCheckbox">
														<input
															className="form-check-input custom-checkbox"
															type="radio"
															id="agreeCheckbox"
															checked={methodSelection.includes('debitCard')}
															onChange={() => {
																setMethodSelection(['debitCard']);
																resetWarnings();
																setType('card');
															}}
														/>
														by <font className="form-check-label-inner">Debit Card</font>
													</label>
												</div>
											</div>
											<div className="col-auto">
												<div className="form-check form-check-inline">
													<label className="form-check-label-outer" htmlFor="dontAgreeCheckbox">
														<input
															className="form-check-input custom-checkbox"
															type="radio"
															id="dontAgreeCheckbox"
															checked={methodSelection.includes('bank')}
															onChange={() => {
																setMethodSelection(['bank']);
																resetWarnings();
																setType('bank_account');
															}}
														/>
														by <font className="form-check-label-inner">Bank</font>
													</label>
												</div>
											</div>
										</>
									)}
								</div>
							) : (
								''
							)}
							{methodSelection.includes('debitCard') ? (
								<>
									<div className="clearfix w-100 p-0">
										{LoginData?.userpaymentmethod &&
											LoginData?.userpaymentmethod
												.filter((data) => data?.payment_method_type === type)
												?.map((data, index) => {
													console.log('@DATA>', data);
													return (
														<>
															{((LoginData?.isTaxPro && membershipCheckout
																? data?.payment_method_id?.startsWith('pm')
																: LoginData?.isTaxPro &&
																  data?.payment_method_id?.startsWith('card')) ||
																LoginData?.isCustomer) && (
																<>
																	<div
																		key={index}
																		// role='button'
																		// onClick={()=>handleSetDefault(data)}
																		className="clearfix mt-4 payment-card position-relative w-100 ms-0"
																	>
																		<div
																			className="position-absolute top-0 start-0 p-3"
																			onClick={() => handleSetDefault(data)}
																			role="button"
																		>
																			{data.is_default && (
																				<BsCheckCircleFill
																					onClick={() => {setEditCard(data)}}
																					size={20}
																					color="#000"
																				/>
																			)}
																			{!data.is_default && (
																				<MdOutlineRadioButtonUnchecked
																					onClick={() => {setEditCard(data)}}
																					size={20}
																					color="#000"
																				/>
																			)}
																		</div>
																		<p className="clearfix p-3 mt-3 border-top border-light fw-bolder text-center">
																			{data?.payment_method_data?.brand} Card Ending In ***
																			{membershipCheckout ?data?.payment_method_data?.last4:
																			<Link
																				to="#"
																				className="text-decoration-underline cursor-pointer text-dark"
																				onClick={() => {
																					// Set That Card is in Editing option
																				
																							setEditCard(data);
																							resetWarnings();
																							set_EditCard(data);
																						
																					
																				}}
																			>
																				{data?.payment_method_data?.last4}
																			</Link>
															}
																		</p>
																		<div className="position-absolute top-0 end-0 p-3">
																			<Link
																				to="#"
																				className="d-block text-decoration-none text-dark"
																				data-bs-toggle="modal"
																				data-bs-target="#ConfirmationModal"
																			>
																				{!membershipCheckout &&
																				
																				<MdDelete
																					onClick={() => setSelectedCard(data)}
																					size={23}
																					color="#000"
																				/>
																				}
																			</Link>
																		</div>
																	</div>
																</>
															)}
														</>
													);
												})}
									</div>
									<div className={`clearfix text-center g-2 mb-2 ${invoice ? `d-none` : ''}`}>
										<div className="d-block">
											<Link
												to="#"
												className="d-block text-decoration-none text-dark paymentformmodal"
												data-bs-toggle="modal"
												data-bs-target="#paymentForm"
												onClick={() => {
													// Set That Card is not in Editing option
													setEditCard(undefined);
													resetWarnings();
													reset_CardForm();
													setHideModalOnCardSelection(true)
												}}
											>
												<small className="d-block text-dark mt-2 fw-bolder">Add New Card</small>
											</Link>
										</div>
									</div>
									
								</>
							) : (
								<>
									{' '}
									<div className={`clearfix text-center g-2 mb-2 ${invoice ? `d-none` : ''}`}>
										<div className="clearfix w-100 p-0">
											{LoginData?.userpaymentmethod &&
												LoginData?.userpaymentmethod
													.filter((data) => data?.payment_method_type === type)
													?.map((data, index) => {
														return (
															<div
																key={index}
																className="clearfix mt-4 payment-card form-floating w-100 ms-0"
															>
																<div className="position-absolute top-0 start-0 p-3">
																	{data.is_default && (
																		<BsCheckCircleFill onClick={() => {}} size={20} color="#000" />
																	)}
																</div>
																<p className="clearfix p-3 mt-3 fw-bolder text-center">
																	{data?.payment_method_data?.brand} Account Number End With ***
																	<font
																		className="text-decoration-underline curs-pointer"
																		onClick={() => {
																			// Set That Card is in Editing option
																			setEditCard(data);
																			resetWarnings();
																			set_EditCard(data);
																		}}
																	>
																		{data?.payment_method_data?.last4}
																	</font>
																</p>
																<div className="position-absolute top-0 end-0 p-3">
																	<Link
																		to="#"
																		className="d-block text-decoration-none text-dark"
																		data-bs-toggle="modal"
																		data-bs-target="#ConfirmationModal"
																	>
																		<MdDelete
																			onClick={() => setSelectedCard(data)}
																			size={23}
																			color="#000"
																		/>
																	</Link>
																</div>
															</div>
														);
													})}
										</div>
										<div className="d-block">
											<Link
												to="#"
												className="d-block text-decoration-none text-dark paymentformmodal"
												data-bs-toggle="modal"
												data-bs-target="#BankPaymentInputForm"
												onClick={() => {
													// Set That Card is not in Editing option
													setEditCard(undefined);
													resetWarnings();
													reset_CardForm();
												}}
											>
												<small className="d-block text-dark mt-2 fw-bolder">Add New Bank</small>
											</Link>
										</div>
									</div>
								</>
							)}
							{setActive && (
								<div className="clearfix mb-2 mt-3 text-start text-center">
									If you would like to add a payment method at a later time, you may select "Skip"
									to proceed to the next step.
								</div>
							)}
						</div>

						{setActive && (
							<div className="container-fluid content-row  ">
								<div className="d-flex align-items-center justify-content-center row">
									<Button
										mainClass="btn btn-outline-primary btn-lg w-100"
										buttonText="Previous"
										wrapperClass={'w-25 custon-button  mt-4'}
										type="button"
										callback={() => {
											GoBack();
										}}
									/>
									<Button
										mainClass="btn btn-primary custon-button-text btn-lg  w-100"
										buttonText="Next"
										wrapperClass={'w-25 custon-button  mt-4'}
										type="button"
										callback={() => {
											moveToNext();
										}}
									/>
									<Button
										mainClass="btn btn-outline-primary custon-button-text btn-lg w-100"
										buttonText="Skip"
										wrapperClass={'w-25 custon-button  mt-4'}
										type="button"
										callback={() => {
											if (
												!stripeAccountStatusData?.data?.requirements?.currently_due ||
												stripeAccountStatusData?.data?.requirements.currently_due?.includes(
													'individual.verification.document'
												)
											) {
												setActive && setActive(5);
											} else {
												setActive && setActive(6);
											}
											resetWarnings();
											reset_All();
										}}
									/>
								</div>
							</div>
						)}
					</>
				) : (
					<div className={`clearfix text-center g-2 mb-2 d-none`}>
						<div className="d-block">
							<Link
								to="#"
								className="d-block text-decoration-none text-dark paymentformmodal"
								data-bs-toggle="modal"
								data-bs-target="#paymentForm"
								onClick={() => {
									// Set That Card is not in Editing option
									setEditCard(undefined);
									resetWarnings();
									reset_CardForm();
								}}
							>
								<small className="d-block text-dark mt-2 fw-bolder">Add New Card</small>
							</Link>
						</div>
					</div>
				)}
			</div>
			{children && children}
			<PaymentModal
				cvc={cvc}
				setCvc={setCvc}
				mmYy={mmYy}
				setMmYy={setMmYy}
				zipCode={zipCode}
				setZipCode={setZipCode}
				cardName={cardName}
				setCardName={setCardName}
				cardNumber={cardNumber}
				setCardNumber={setCardNumber}
				paymentMessage={paymentMessage}
				defaultSelection={defaultSelection}
				setDefaultSelection={setDefaultSelection}
				AllowDefaultSelect={LoginData?.userpaymentmethod.length === 0 ? false : true}
				resetWarnings={resetWarnings}
				editCard={editCard}
				handleSubmitCard={editCard ? handleSubmitUpdateCard : handleSubmitCard}
				OkButtonText={editCard ? 'Update Card' : 'Add Card'}
			/>

			<BankInputModal
				accountNo={accountNo}
				setAccountNo={setAccountNo}
				routingNo={routingNo}
				setRoutingNo={setRoutingNo}
				accountHolderName={accountHolderName}
				setAccountHolderName={setAccountHolderName}
				paymentMessage={paymentMessage}
				defaultSelection={defaultSelection}
				setDefaultSelection={setDefaultSelection}
				AllowDefaultSelect={LoginData?.userpaymentmethod.length === 0 ? false : true}
				resetWarnings={resetWarnings}
				editCard={editCard}
				handleSubmitCard={editCard ? handleSubmitUpdateCard : handleSubmitCard}
				OkButtonText={'Add Bank'}
			/>

			<ConfirmationModal
				label="Are You Sure Want To Remove That Card?"
				handleSubmit={() => removeCard(selectedCard)}
			/>
		</div>
	);
}
