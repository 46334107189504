import { TASK_BASEURL } from '../../utilites';
import axios from 'axios';
import { TASKS_ACTION_TYPE } from './Type';


const getTasks = (name, createdDate, page = 1, per_page = 10) => {
	// keyword, createdDate, page, perPage
	return async (dispatch) => {
		dispatch({
			type: TASKS_ACTION_TYPE.TASKS_FETCHING_START,
		});
		// Fetch data
		axios
		.get(`${TASK_BASEURL}/api/task/getAllTask`, {
				params: { name, createdDate, page, per_page,env:process.env.REACT_APP_ENV },
			})
			.then((response) => {
				const { data, count } = response.data.data;
				dispatch({
					type: TASKS_ACTION_TYPE.TASKS,
					payload: { data, count, status: true, error: '' },
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				dispatch({
					type: TASKS_ACTION_TYPE.TASKS,
					payload: { data: [], count: 0, status: false, error: handleTasksError('simple', error) },
				});
				console.error(error);
			});
	};
};


//  Function To POST Membership
const createTasks = (createTaskObj) => {
	return async (dispatch) => {
		axios
			.post(`${TASK_BASEURL}/api/task/create`,createTaskObj)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: TASKS_ACTION_TYPE.CREATE_TASKS,
					payload: {
						message: 'success',
						status: response?.data?.success,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				console.log(error);
				// HANDLE ERROR MESSAGE
				dispatch({
					type: TASKS_ACTION_TYPE.CREATE_TASKS,
					payload: {
						message: handleTasksError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};


const editTask = (editTaskObj) => {
	return async (dispatch) => {
		axios
			.patch(`${TASK_BASEURL}/api/task/edit/${editTaskObj.id}`,editTaskObj)
			.then((response) => {
				document.querySelector('.editTaskFormBtn').click()
				dispatch({
					type: TASKS_ACTION_TYPE.EDIT_TASK,
					payload: {
						message: 'success',
						status: response?.data?.success,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: TASKS_ACTION_TYPE.EDIT_TASK,
					payload: {
						message: handleTasksError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};


const handleTasksError = (type, error) => {
	if (type === 'simple') {
		return error.code === 'ERR_NETWORK'
			? error?.message
			: error?.response?.data?.error
				? error?.response?.data?.error
				: 'Internel Server Error!';
	}
};

export const tasks = {
	getTasks,
	createTasks,
	editTask
};
