import { useEffect, useState } from 'react';
import {
	ComposedChart,
	Line,
	Area,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
} from 'recharts';

// * Earnings are divided by number below to make them fit on the chart
const earningsDivider = 1000;

export default function DataChart({ chartData }) {
	const [data, setData] = useState();

	useEffect(() => {
		const dataArray = [];
		const { leadsCountData, leadsCountDataCompleted, earningsData } = chartData;

		for (const property in leadsCountData) {
			const name = property;
			const leads = leadsCountData[property];
			const completed = leadsCountDataCompleted[property];
			const earnings = earningsData[property] / earningsDivider;

			dataArray.push({ name, jobs: leads, completed, earnings });
		}

		setData(dataArray);
	}, [chartData]);

	return (
		<ResponsiveContainer width='100%' height='100%'>
			<ComposedChart width={500} height={400} data={data}>
				<XAxis
					dataKey='name'
					label={{ position: 'insideBottomRight', offset: 0 }}
					// scale='band'
					tick={{ fontSize: 10 }}
					axisLine={false}
					tickLine={false}
				/>
				<YAxis
					label={{ angle: -90, position: 'insideLeft' }}
					tick={{ fontSize: 10 }}
					axisLine={false}
					tickLine={false}
				/>
				<Tooltip
					formatter={(value, name) => {
						if (name === 'earnings') {
							return `$${(value * earningsDivider).toLocaleString('en-US')}`;
						}
						return value;
					}}
				/>
				<Area type='monotone' dataKey='earnings' fill='#dee2e6' stroke='#adb5bd' />
				<Bar dataKey='jobs' fill='var(--bs-secondary-700)' radius={0} />
				<Line
					type='monotone'
					dataKey='completed'
					stroke='#4b57e7'
					strokeDasharray='10 10'
					dot={false}
				/>
			</ComposedChart>
		</ResponsiveContainer>
	);
}
