import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch } from 'react-redux';
import { BASEURL } from '../../utilites';
import allActions from '../../Store/action';
import Button from './fields/Button';

export default function ProfileImageForm({ setAlert, userAvatar, userId, isAdmin, userFullName }) {
	// Custom image upload
	const [image, setImage] = useState();
	// Add avatars by admin
	const [newAvatars, setNewAvatars] = useState();
	// Selected avatar
	const [avatar, setAvatar] = useState();
	// Progress
	const [uploading, setUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	// Arert
	// const [swalProps, setSwalProps] = useState({});
	const [errors, setErrors] = useState({
		image: false,
		newAvatars: false,
		avatar: false,
		general: '',
	});
	const dispatch = useDispatch();
	const button = useRef();
	useEffect(() => {
		dispatch(allActions.dashboard.getAvatars());
	}, [dispatch]);
	// function to handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();

		if (image) {
			setUploading(true);

			button.current.disabled = true;
			if (errors.general !== '') setErrors({ errors, general: '' });

			// Create a new FormData instance
			const formData = new FormData();
			// Append properties to FormData
			formData.append('documents', image);
			formData.append('user_id', userId);
			formData.append('type', 7);
			if (userFullName) formData.append('document_name', `${userFullName}'s profile picture`);

			try {
				// Make an Axios request to the backend API endpoint for each file individually
				await axios
					.post(`${BASEURL}/api/documents`, formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
						onUploadProgress: (progressEvent) => {
							// Calculate the upload progress percentage
							const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);

							// Update the uploadProgress state for the current file
							setUploadProgress(progress);
						},
					})
					.then(() => {
						button.current.disabled = false;
						// Close Modal
						document.querySelector('.profilePictureModalClose').click();
						// Re-get user data
						dispatch(allActions.user.ReGetUserData());
						setAlert('Profile picture has been updated successfully!');
					});
			} catch (error) {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({
						errors,
						general:
							error?.response?.data?.message || error?.response?.data?.error || error.message,
					});
			}
			// Reset the form
			setUploading(false);
			setUploadProgress(0);
			setImage();
		} else if (avatar) {
			button.current.disabled = true;
			if (errors.general !== '') setErrors({ errors, general: '' });

			axios
				.post(`${BASEURL}/api/users/update-avatar`, {
					user_id: userId,
					avatar,
				})
				.then(() => {
					button.current.disabled = false;
					// Close Modal
					document.querySelector('.profilePictureModalClose').click();
					// Re-get user data
					dispatch(allActions.user.ReGetUserData());
					setAlert('User avatar has been updated successfully!');
				})
				.catch((error) => {
					button.current.disabled = false;
					console.error(error.message);
					// Set general error
					if (errors.general === '')
						setErrors({ errors, general: error?.response?.data?.message || error.message });
				});
			// Reset the form
			setAvatar();
		} else if (isAdmin && newAvatars) {
			setUploading(true);
			button.current.disabled = true;
			if (errors.general !== '') setErrors({ errors, general: '' });
			// Create a new FormData instance
			const formData = new FormData();
			// Append all properties to FormData
			for (let i = 0; i < newAvatars.length; i++) {
				formData.append('documents', newAvatars[i]);
			}
			formData.append('user_id', userId);
			formData.append('type', 6);
			try {
				// Make an Axios request to the backend API endpoint for each file individually
				await axios
					.post(`${BASEURL}/api/documents`, formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
						onUploadProgress: (progressEvent) => {
							// Calculate the upload progress percentage
							const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);

							// Update the uploadProgress state for the current file
							setUploadProgress(progress);
						},
					})
					.then(() => {
						button.current.disabled = false;
						// Reload avatars
						dispatch(allActions.dashboard.getAvatars());
						setAlert(`Avatar${newAvatars.length > 1 ? 's' : ''} has been added successfully!`);
					});
			} catch (error) {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			}
			// Reset the form
			setUploading(false);
			setUploadProgress(0);
			setNewAvatars();
		}
	};

	// const deleteAvatar = (uuid) => {
	// 	dispatch(allActions.dashboard.deleteDocument(uuid)).then(() =>
	// 		setAlert('Avatar has been deleted successfully!')
	// 	);
	// };

	// const handleDeleteAvatarClick = (uuid) => {
	// 	setSwalProps((prev) => ({
	// 		...prev,
	// 		[uuid]: {
	// 			show: true,
	// 			showDenyButton: true,
	// 			title: 'Are you sure?',
	// 			text: 'You will not be able to recover this avatar!',
	// 			confirmButtonText: 'Yes, delete it!',
	// 			denyButtonText: 'Cancel',
	// 			didClose: () => setSwalProps((prev) => ({ ...prev, [uuid]: { show: false } })),
	// 			onConfirm: () => deleteAvatar(uuid),
	// 		},
	// 	}));
	// };

	return (
		<form className="imageform needs-validation" onSubmit={handleSubmit}>
			{!uploading && !newAvatars && !avatar && (
				<div className="row">
					<div>
						<FileUploader
							classes="form-control"
							multiple={false}
							values={image}
							handleChange={(file) => setImage(file)}
							name="image"
							types={['PNG', 'JPEG', 'JPG']}
							onTypeError={() => {
								setErrors({ ...errors, image: true });
							}}
							/>
					</div>
				</div>
			)}
			<p className='text-start mt-0 pt-0 text-sm text-alert mt-2'>Only photos under 9MB are allowed</p>
			{/* {avatars && avatars.length > 0 && !image && !newAvatars && !uploading ? (
				<Fragment>MB
					<div className="mb-3 hr-divider">
						<div className="hr-line"></div>
						<div className="hr-text">Or choose an avatar below</div>
						<div className="hr-line"></div>
					</div>

					<div className="row mb-3 AvatarList">
						{avatars?.map((a) => (
							<div
								className="col-md-3 mb-3 position-relative rounded-circle AvatarList__avatar-card"
								key={a.id}
							>
								<img
									className={`img-fluid rounded-circle h-100 object-fit-cover${
										avatar === a.id ? ' border border-primary opacity-100' : ' opacity-75'
									}${userAvatar?.id === a.id ? ' pe-none border border-success opacity-100' : ''}`}
									onClick={() => {
										if (a.id === avatar) {
											setAvatar();
										} else {
											setAvatar(a.id);
										}
									}}
									src={a.path}
									alt={a.name}
									style={{ cursor: 'pointer' }}
								/>
								{isAdmin && (
									<Fragment>
										<button
											className="btn btn-close btn-sm bg-danger top-0 end-0 position-absolute p-1 rounded-circle d-none z-index-2"
											onClick={() => handleDeleteAvatarClick(a.uuid)}
										/>
										<SweetAlert2 {...swalProps[a.uuid]} />
									</Fragment>
								)}
							</div>
						))}
					</div>
				</Fragment>
			) : null} */}

			{/* {isAdmin && !image && !avatar && !uploading && (
				<Fragment>
					<div className="mb-3 hr-divider">
						<div className="hr-line"></div>
						<div className="hr-text">Add a new avatar option</div>
						<div className="hr-line"></div>
					</div>

					<div className="row">
						<div className="col-md-12 mb-3">
							<FileUploader
								classes="form-control"
								multiple={true}
								values={newAvatars}
								handleChange={(files) => setNewAvatars(files)}
								name="newAvatars"
								types={['PNG', 'JPEG', 'JPG']}
								onTypeError={() => {
									setErrors({ ...errors, newAvatars: true });
								}}
							/>
						</div>
					</div>
				</Fragment>
			)} */}

			{uploading ? (
				<div className="progress">
					<div
						className="progress-bar progress-bar-striped progress-bar-animated"
						role="progressbar"
						style={{ width: `${uploadProgress}%` }}
						aria-valuenow={uploadProgress}
						aria-valuemin="0"
						aria-valuemax="100"
					>
						{uploadProgress}%
					</div>
				</div>
			) : null}

			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mb-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 || !(image || newAvatars || avatar)
						? ' disabled'
						: ''
				}`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-3"
				buttonText="Update"
			/>
		</form>
	);
}
