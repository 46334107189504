export const INVOICE_ACTION_TYPE = {
	invoicesFetchingStart: 'invoicesFetchingStart',
	invoices: 'invoices',
	invoiceStatusIdle: 'invoiceStatusIdle',
	createCustomer: 'createCustomer',
	createCustomerIdle: 'createCustomerIdle',
	createInvoice: 'createInvoice',
	createInvoiceIdle: 'createInvoiceIdle',
	createSubscription: 'createSubscription',
	createSubscriptionIdle: 'createSubscriptionIdle',
	payInvoice: 'payInvoice',
	payInvoiceIdle: 'payInvoiceIdle',
	downloadInvoice:'downloadInvoice',
	downloadInvoiceIdle:'downloadInvoiceIdle',
	invoicePaid:'invoicePaid',
	invoicePaidIdle:'invoicePaidIdle'
};
