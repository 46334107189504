/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState,Fragment } from 'react';
import Button from './fields/Button';
import Message from './fields/Message';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../Store/action';
import { AiOutlinePlus, AiOutlineMinus, AiOutlineDelete } from 'react-icons/ai';
import moment from 'moment';
// CSS
import 'react-datepicker/dist/react-datepicker.css';
import { DASHBOARD_ACTION_TYPE } from '../../Store/dashboard/Type';
import ReactSelect from 'react-select';
import {
	getAmericanTimezones,
	timezoneFormatter,
	convertTime
   } from '../../utilites'

const american_timezones = getAmericanTimezones();
const americanTimeZones = moment.tz.zonesForCountry("US");


const defaultTimeRange = [
	{
		from: '00:00',
		to: '23:30',
	},
];

const defaultTimeRanges = {
	Sunday: defaultTimeRange,
	Monday: defaultTimeRange,
	Tuesday: defaultTimeRange,
	Wednesday: defaultTimeRange,
	Thursday: defaultTimeRange,
	Friday: defaultTimeRange,
	Saturday: defaultTimeRange,
};

const topTimezones = [
	"America/New_York",
	"America/Chicago",
	"America/Denver",
	"America/Los_Angeles",
  ];


const customStyles = {
	option: (provided,state) => ({
	  ...provided,
	  textAlign: "left",
	  height:'40px',
	  color:state.isSelected ? provided.color : 'gray',
	}),
	singleValue: (provided) => ({
	  ...provided,
	  textAlign: "left",
	}),
	placeholder: (provided) => ({
	  ...provided,
	  textAlign: "left",
	  fontSize: 14,
	}),
  };

export default function AvailabilityForm({ active, setActive }) {
	// --------------------------------------------
	// React States , Variable , Build in Redux
	// --------------------------------------------
	// ********--------Redux Build in Functions-----*******
	const dispatch = useDispatch();

	// ********--------React States-----*******
	const [timezone, setTimezone] = useState('');
	const [message, setMessage] = useState({ show: false });
	const [
		{ LoginData },
		{
			onBoardUpdateStatus,
			onBoardUpdateStatusMessage,
			availability,
			profile,
			stripeAccountStatusData,
		},
	] = useSelector((state) => [state.user, state.dashboard]);
	const [timeRanges, setTimeRanges] = useState(defaultTimeRanges);
	const [allow_appointments, setAllow_appointments] = useState(
		true
	  );
	  const [timezoneName, setTimezoneName] = useState(
		topTimezones[3]
		  );
	  
  const convertTimesToSelectedTimezone = (selectedTimezone) => {
    setTimezone(selectedTimezone.value);
    setTimezoneName(selectedTimezone.label);
  };
	// --------------------------------------------
	// HOOKS TO HANDLE RESPONSES OF API's
	// --------------------------------------------
	useEffect(() => {
		let data = LoginData?.availability?.time_ranges;

		if (LoginData?.settings?.timezone) {
			setTimezone(LoginData?.settings?.timezone);
		} else {
			setTimezone(american_timezones[3].tzCode); // Default to America/Los_Angeles
		}
		if (data)
			setTimeRanges(
				data
					? {
							Sunday: data['Sunday'],
							Monday: data['Monday'],
							Tuesday: data['Tuesday'],
							Wednesday: data['Wednesday'],
							Thursday: data['Thursday'],
							Friday: data['Friday'],
							Saturday: data['Saturday'],
					  }
					: defaultTimeRanges
			);
	}, [LoginData]);
	// Effect hook to validate time ranges on change
	useEffect(() => {
		Object.keys(timeRanges).forEach((day) => {
			const timeRange = timeRanges[day];
			// Validate time ranges (if invalid range (meaning logical issue from > to, etc.), of if invalid values in different time ranges, correct them)
			if (timeRange !== null && timeRange.length > 0) {
				timeRange.forEach((tr, index) => {
					if (tr.from > tr.to) {
						// Corrent the time range (from < to)
						setTimeRanges((prev) => ({
							...prev,
							[day]: prev[day]?.map((tr, i) => (i === index ? { from: tr.to, to: tr.from } : tr)),
						}));
					}
					if (index > 0 && tr.from < timeRange[index - 1].to) {
						// Corrent the time range (from > previous to)
						setTimeRanges((prev) => ({
							...prev,
							[day]: prev[day]?.map((tr, i) =>
								i === index ? { from: timeRange[index - 1].to, to: tr.to } : tr
							),
						}));
					}
					if (index < timeRange.length - 1 && tr.to > timeRange[index + 1].from) {
						// Corrent the time range (to < next from)
						setTimeRanges((prev) => ({
							...prev,
							[day]: prev[day]?.map((tr, i) =>
								i === index ? { from: tr.from, to: timeRange[index + 1].from } : tr
							),
						}));
					}
				});
			}
		});
	}, [timeRanges]);

	useEffect(() => {
		if (!onBoardUpdateStatus) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: onBoardUpdateStatusMessage,
			});
		}
		if (onBoardUpdateStatus === true) {
			dispatch(allActions.global.Loader(false));
		}
	}, [onBoardUpdateStatus, onBoardUpdateStatusMessage]);

	useEffect(() => {
		// if Profile Update Successfully !

		if (profile?.state === true) {
			dispatch(allActions.dashboard.setAvailability({ timeRanges }));
		}
		// if Profile GOT ERROR !
		if (profile?.state === false) {
			if (typeof profile?.message === 'string')
				setMessage({
					show: true,
					type: 'Error',
					text: profile?.message,
				});
			dispatch(allActions.global.Loader(false));
		}
	}, [profile?.state]);

	useEffect(() => {
		// if Profile Update Successfully !
		if (availability?.state === true) {
			// Reset Account Errror Came in Stripe Status
			dispatch(allActions.dashboard.resetAccountStatus());
			//  Function To Reset OnBoard Status Final Submit
			dispatch(allActions.dashboard.onBoardUpdateStatusReset());
			dispatch(allActions.dashboard.onBoardUpdateStatus());
		}
		// if availability GOT ERROR !
		if (availability?.state === false) {
			if (typeof availability?.message === 'string')
				setMessage({
					show: true,
					type: 'Error',
					text: availability?.message,
				});
			dispatch(allActions.global.Loader(false));
		}
	}, [availability?.state]);

	// --------------------------------------------------
	// Local Functions to handle local States , Call Apis
	// --------------------------------------------------

	// function to handle form submission
	const handleSubmit = (e) => {
		reset_States();
		e.preventDefault();
		dispatch(allActions.global.Loader(true));

		const profileObj = {
			settings: {
				theme: 'light',
				timezone: timezone || american_timezones[0].tzCode,
				allow_appointments: allow_appointments,
			},
			mode: 'onBoarding',
		};

		dispatch(allActions.dashboard.updateProfile({ uuid: LoginData?.uuid, profileObj }));
	};
	const GoBack = () => {
		if (
			!stripeAccountStatusData?.data?.requirements?.currently_due ||
			stripeAccountStatusData?.data.requirements.currently_due.includes(
				'individual.verification.document'
			)
		) {
			setActive && setActive(5);
		} else {
			setActive && setActive(4);
		}
		//  Function To Reset OnBoard Status Final Submit
		dispatch(allActions.dashboard.onBoardUpdateStatusReset());
		// Reset Account Errror Came in Stripe Status
		dispatch(allActions.dashboard.resetAccountStatus());
	};

	const reset_States = () => {
		setMessage({ show: false });
		dispatch({
			type: DASHBOARD_ACTION_TYPE.PROFILEIDLE,
		});
		dispatch({
			type: DASHBOARD_ACTION_TYPE.AVAILABILITYIDLE,
		});
	};
	const addTimeRange = (day) => {
		// Add time range to the selected day (calculate the next time range. If the last time is equal to 23:30, ignore the addition of a new time range)
		// If the day has no time range, add the default time range
		if (timeRanges[day] === null || timeRanges[day].length === 0) {
			setTimeRanges((prev) => ({
				...prev,
				[day]: defaultTimeRange,
			}));
			// Check the checkbox
			document.querySelector(`.AvailabilityFormCheckbox-${day}`).checked = true;
		} else {
			const lastTimeRange = timeRanges[day][timeRanges[day].length - 1];
			if (lastTimeRange.to !== '23:30') {
				setTimeRanges((prev) => ({
					...prev,
					[day]: [...prev[day], { from: lastTimeRange.to, to: '23:30' }],
				}));
			}
		}
	};
	return (
		<div className="BusinessInfoForm needs-validation">
			{message.show ? <Message message={message} /> : <></>}

			<form className=" needs-validation" onSubmit={handleSubmit}>
				<div className="w-100 d-flex justify-content-center flex-column align-items-center">
					<p className="text-dark fw-bold w-75">
						Please set your availability below in case customers want to schedule a video
						appointment with you.
					</p>
					{/* <div className="mb-3 col-sm-6">
						<Select value={timezone} name="timezone" placeholder="Timezone" fn={setTimezone}>
							{american_timezones?.map((timezone) => (
								<option key={timezone.tzCode} value={timezone.tzCode}>
									{timezone.name}
								</option>
							))}
						</Select>
					</div> */}
						<ReactSelect
							id="users"
							className="p-0 w-100 my-3"
							placeholder="Select User"
							styles={customStyles}
							closeMenuOnSelect={true}
							options={[
								...topTimezones?.map((v) => ({
									value: v,
									label: timezoneFormatter(v),
								})),
								...americanTimeZones?.map((v) => ({
									value: v,
									label: timezoneFormatter(v),
								})),
							]}
							onChange={(v) => convertTimesToSelectedTimezone(v)}
							value={{
								value: timezone,
								label: `${timezoneFormatter(timezoneName)} (${convertTime(
									timezone,
									"h:mm A"
								)})`,
							}}
						/>
					<div className="row">
						{Object.keys(timeRanges)?.map((day) => (
							<div className="row justify-content-end" key={day}>
								<div className="col d-flex justify-content-start align-items-center mb-2 gap-3">
									<div className="form-check d-flex justify-content-start align-items-center mb-2 gap-2">
										<input
											type="checkbox"
											className={`AvailabilityFormCheckbox-${day}`}
											placeholder={day}
											aria-label={day}
											value={day}
											id={day}
											onChange={(e) => {
												if (
													e.target.checked &&
													(timeRanges[day] === null || timeRanges[day]?.length === 0)
												) {
													setTimeRanges((prev) => ({
														...prev,
														[day]: defaultTimeRange,
													}));
												} else if (timeRanges[day] !== null && timeRanges[day]?.length > 0) {
													setTimeRanges((prev) => ({
														...prev,
														[day]: null,
													}));
												}
											}}
											defaultChecked={timeRanges[day] !== null && timeRanges[day]?.length > 0}
										/>
										<label htmlFor={day} className="form-check-label">
											{day.substring(0, 3).toUpperCase()}
										</label>
									</div>
								</div>
								{timeRanges[day] !== null && timeRanges[day]?.length > 0 ? (
									timeRanges[day]?.map((timeRange, index) => (
										<Fragment key={index}>
											<div className="col-md-4 mb-2">
												<input
													className="form-control"
													type="time"
													onKeyDown={(e) => e.preventDefault()}
													value={timeRange.from}
													onChange={(e) =>
														// Set time range from (for only the selected object)
														setTimeRanges((prev) => ({
															...prev,
															[day]: prev[day]?.map((tr, i) =>
																i === index ? { ...tr, from: e.target.value } : tr
															),
														}))
													}
												/>
											</div>
											<div className="col-1 d-flex justify-content-center align-items-center mb-2">
												<AiOutlineMinus />
											</div>
											<div className="col-md-3 mb-2">
												<input
													className="form-control"
													type="time"
													value={timeRange.to}
													onKeyDown={(e) => e.preventDefault()}
													onChange={(e) =>
														// Set time range to (for only the selected object)
														setTimeRanges((prev) => ({
															...prev,
															[day]: prev[day]?.map((tr, i) =>
																i === index ? { ...tr, to: e.target.value } : tr
															),
														}))
													}
												/>
											</div>
											<div className="col-md-2 d-flex justify-content-around align-items-center mb-2">
												<AiOutlineDelete
													className="text-danger cursor-pointer"
													onClick={() => {
														// If the day has no time range, set it to null
														if (timeRanges[day].length === 1) {
															setTimeRanges((prev) => ({
																...prev,
																[day]: null,
															}));
															// Uncheck the checkbox
															document.querySelector(
																`.AvailabilityFormCheckbox-${day}`
															).checked = false;
															return;
														}
														setTimeRanges((prev) => ({
															...prev,
															[day]: prev[day].filter((_, i) => i !== index),
														}));
													}}
													size={20}
												/>
												<AiOutlinePlus
													className={`text-primary cursor-pointer${
														timeRange.to === '23:30' ? ' pe-none disabled' : ''
													}${index !== timeRanges[day].length - 1 ? ' opacity-0 pe-none' : ''}`}
													onClick={() => addTimeRange(day)}
													size={20}
												/>
											</div>
										</Fragment>
									))
								) : (
									<Fragment>
										<div className="col-md-8 mb-2">Unavailable</div>
										<div className="col-md-2 d-flex justify-content-around align-items-center mb-2">
											<AiOutlineDelete className="opacity-0 pe-none" size={20} />
											<AiOutlinePlus
												className="text-primary cursor-pointer"
												size={20}
												onClick={() => addTimeRange(day)}
											/>
										</div>
									</Fragment>
								)}
							</div>
						))}
					</div>
				</div>
				<div className="d-flex gap-3 mt-3 px-1">
					<input
						class="form-check-input"
						type="checkbox"
						checked={!allow_appointments}
						value={allow_appointments}
						onClick={(e) => setAllow_appointments((prev) => !prev)}
						id="defaultCheck1"
					/>
					<label class="form-check-label text-muted" for="defaultCheck1">
						I do not want other users setting up appointments with me.
					</label>
				</div>
				<div className="container-fluid content-row ">
					<div className="d-flex align-items-center justify-content-center row">
						<Button
							mainClass="btn btn-outline-primary btn-lg w-100"
							buttonText="Previous"
							wrapperClass={'w-25 custon-button  mt-4'}
							type="button"
							callback={() => {
								GoBack();
							}}
						/>
						<Button
							mainClass="btn btn-primary custon-button-text btn-lg  w-100"
							buttonText="Next"
							wrapperClass={`w-25 custon-button  mt-4 `}
							type="submit"
						/>
					</div>
				</div>
			</form>
		</div>
	);
}
