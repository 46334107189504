/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import allActions from '../../../Store/action';
import Message from '../../../components/forms/fields/Message';

const KeapVerification = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const [message] = useState({ show: false });
	const navigate = useNavigate();
	const [{ keapVerification }] = useSelector((state) => [state.Admin]);

	useEffect(() => {
		console.log('@6666', keapVerification);
		if (keapVerification.status === 'idle') {
			console.log('@0000', location.search.slice(6, 14));
			let code = location.search.slice(6, 14);
			dispatch(allActions.Admin.keapVerification(code));
		}
		if (keapVerification.status === false) {
			navigate('/dashboard/settings', {
				state: {
					identity: 'keapVerification',
					keapVerificationStatus: false,
					keapVerificationError: keapVerification.message,
				},
			});
		}
		if (keapVerification.status === true) {
			navigate('/dashboard/settings', {
				state: {
					identity: 'keapVerification',
					keapVerificationStatus: true,
				},
			});
		}
	}, [keapVerification]);
	// --------------------------------------------
	// HOOKS (UseEffect used For Different Purposes)
	// --------------------------------------------
	//  *****--HOOKS To Create Plaid Link For Verification--****
	return (
		<div className="clearfix px-3">{message.show ? <Message message={message} /> : <></>}</div>
	);
};

export default KeapVerification;
