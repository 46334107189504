/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '../../fields/Button';

import TextArea from '../../fields/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { HandleFormData } from '../../../../utilites';
import allActions from '../../../../Store/action';

const SCorp = () => {
	
    const dispatch=useDispatch()
	const [{ taxPayerForm , taxPayerFormData, formInformation}] = useSelector((state) => [state.taxForms]);
	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 18 });

	const [amountPosted, setAmountPosted] = useState([]);
	const [fringeBenefitsPaid, setFringeBenefitsPaid] = useState();
	
    useEffect(() => {
    	if (taxPayerForm?.data?.SCorp) {
    		if (Object.keys(taxPayerForm?.data?.SCorp).length === 0) return;
			const {
                amountPosted,
            fringeBenefitsPaid
			} = taxPayerForm?.data?.SCorp;
            setAmountPosted(amountPosted)
            setFringeBenefitsPaid(fringeBenefitsPaid)
    	}
    }, [taxPayerForm?.data?.SCorp]);

	const handleSubmit = (e) => {
		e.preventDefault();
        let FormObj = {
            amountPosted,
            fringeBenefitsPaid
		};
        let formData = taxPayerForm;
        taxPayerForm.data.SCorp = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));
        uploadFormData(formData);
    };

    const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 18;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};

	return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            {/* <hr className="m-0" /> */}
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                <div className="col-md-12 mb-3">
                    <small className="ml-4 fw-bold">Have fringe benefits paid on behalf of more than two-percent shareholders (including, but not limited to medical, life insurance, disability, housing, etc.) been included in the shareholder’s compensation and subjected to payroll taxes, if applicable?</small>
                    <TextArea
                        inputName="form-control"
                        defaultValue={fringeBenefitsPaid}
                        placeholder=""
                        name="Businessactivities"
                        fn={setFringeBenefitsPaid}
                        row="6"
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <small className="ml-4 fw-bold">Indicate to which accounts these amounts have been posted. These rules also apply to relatives of more than two-percent shareholders.</small>
                    <TextArea
                        inputName="form-control"
                        defaultValue={amountPosted}
                        placeholder=""
                        name="Businessactivities"
                        fn={setAmountPosted}
                        row="6"
                    />
                </div>
            </div>
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="button"
                        callback={()=>goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
	);
};

export default SCorp;