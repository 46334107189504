import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BASEURL } from '../../../../utilites';
import keapImage from '../../../../assets/img/keap-logo.png';

const Keap = ({ user, keapVerification }) => {
	const [keap, setKeap] = useState();

	useEffect(() => {
		axios.get(`${BASEURL}/api/keap/auth-url`).then((res) => setKeap(res.data.data));
	}, []);

	return (
		<>
			{user.isAdmin && (
				<div className='clearfix pb-3 border-1 border-light border-bottom'>
					<h4 className='text-dark'>Keap Integration</h4>
					<p>
						<img src={keapImage} alt='Keap' className='w-25' />
					</p>
					<p>
						<strong>Keap</strong> is a CRM and marketing automation platform that helps you grow
						your business, improve customer service, and increase sales.
					</p>
					<p className='text-dark'>
						<strong>Status: </strong>
						{keapVerification || user.isKeapAuth
							? 'Integrated'
							: 'No Integration'}
					</p>
					<p>
						<Link to={keap} className='btn btn-outline-primary' target='_blank'>
						{keapVerification || user.isKeapAuth
							? 'Re-Authenticate Application'
							: 'Authenticate Application'}
						</Link>
					</p>
				</div>
			)}
		</>
	);
};

export default Keap;
