import React, { useEffect, useState } from 'react';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';
import { HandleFormData } from '../../../../utilites';

const FilliingStatus = () => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	const dispatch = useDispatch();
	const { StatusOption } = GLOBAL_VARIABLES;

	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 1 });

	const [{ taxPayerFormData, taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);

	// For Choice Questions
	const [StatusType, setStatusType] = useState(null);

	const [errors, setErrors] = useState({}); // Track errors for form fields

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.fillingStatus) {
			if (Object.keys(taxPayerForm?.data?.fillingStatus).length === 0) return;

			const { StatusType } = taxPayerForm?.data?.fillingStatus;
			setStatusType(StatusType);
		}
	}, [taxPayerForm?.data?.fillingStatus]);

	// ---------------------------------------
	// Functions
	// ---------------------------------------
	const check_Validity = () => {
		setErrors({});

		const newErrors = {};

		// Add validation rules for each field
		if (!StatusType) newErrors.identificationType = 'Please Select Status Type';
		// Add more validation rules as needed for other fields

		setErrors(newErrors);

		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let errors = check_Validity();
		if (errors) return;

		let FormObj = {
			StatusType,
		};

		let formData = taxPayerForm;
		taxPayerForm.data.fillingStatus = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
	};

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 1;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};
	return (
		<form className="w-100 mt-3" onSubmit={handleSubmit}>
			<div className="mt-3 row justify-content-between px-3 mb-3 custom-left-form">
				<div className=" mb-3 px-3">
					<small className="text-dark fw-bold m-0" htmlFor={`StatusType`}>
						Filling Status
					</small>
					{StatusOption?.map((data, i) => {
						return (
							<div key={i} className="w-full d-flex align-items-start justfy-content-start mt-2">
								<input
									checked={StatusType?.question === data?.question}
									className="form-check-input"
									type="radio"
									id={`StatusType${i}`}
									name={`StatusType`}
									onChange={() => setStatusType(data)}
								/>
								<label
									htmlFor={`StatusType${i}`}
									role="button"
									className="form-check-label text-start px-3 small"
								>
									{data.question}
								</label>
							</div>
						);
					})}
				</div>
			</div>
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default FilliingStatus;
