import { BASEURL } from "../../utilites";
import axios from "axios";
import { PAYMENT_ACTION_TYPE } from "./Type";
import { user } from "../user/action";

//  Function To Add Card Stripe
const paymentCards = (cardObj) => {
  return async (dispatch) => {
    axios
      .post(`${BASEURL}/api/stripe/addpaymentMethod`, cardObj)
      .then((response) => {
        dispatch(user.ReGetUserData());
        dispatch({
          type: PAYMENT_ACTION_TYPE.paymentCardAdd,
          status: true,
          message: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_ACTION_TYPE.paymentCardAdd,
          status: false,
          message:
            error.code === "ERR_NETWORK"
              ? error?.message
              : error?.response?.data?.error?.raw?.message
              ? error?.response?.data?.error?.raw?.message
              : error?.response?.data?.error,
        });
      });
  };
};
//  Function To Edit Card
const paymentCardUpdate = (cardObj) => {
  return async (dispatch) => {
    axios
      .patch(`${BASEURL}/api/stripe/updatepaymentMethod`, cardObj)
      .then((response) => {
        dispatch(user.ReGetUserData());
        dispatch({
          type: PAYMENT_ACTION_TYPE.paymentCardAdd,
          status: true,
          message: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_ACTION_TYPE.paymentCardAdd,
          status: false,
          message:
            error.code === "ERR_NETWORK"
              ? error?.message
              : error?.response?.data?.error?.raw?.message
              ? error?.response?.data?.error?.raw?.message
              : error?.response?.data?.error,
        });
      });
  };
};
//  Function To Remove Card Stripe
const removeCard = (cardIdObj) => {
  return async (dispatch) => {
    axios
      .delete(`${BASEURL}/api/stripe/deletepaymentMethod`, {
        data: cardIdObj,
      })
      .then((response) => {
        dispatch(user.ReGetUserData());
        dispatch({
          type: PAYMENT_ACTION_TYPE.paymentModificationStatus,
          status: true,
          message: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_ACTION_TYPE.paymentModificationStatus,
          status: false,
          message:
            error.code === "ERR_NETWORK"
              ? error?.message
              : error?.response?.data?.error?.raw?.message
              ? error?.response?.data?.error?.raw?.message
              : error?.response?.data?.error,
        });
      });
  };
};

// Function To Add invoice payment Card
const AddInvoiceCard = (cardObj) => {
  return async (dispatch) => {
    axios
      .post(`${BASEURL}/api/stripe/attachpaymentmethod`, cardObj)
      .then((response) => {
        dispatch(user.ReGetUserData());
        dispatch({
          type: PAYMENT_ACTION_TYPE.paymentCardAdd,
          status: true,
          message: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_ACTION_TYPE.paymentCardAdd,
          status: false,
          message:
            error.code === "ERR_NETWORK"
              ? error?.message
              : error?.response?.data?.error?.raw?.message
              ? error?.response?.data?.error?.raw?.message
              : error?.response?.data?.error,
        });
      });
  };
};
// Function To Edit Invoice Card
const updateInvoiceCard = (cardObj) => {
  return async (dispatch) => {
    axios
      .patch(`${BASEURL}/api/stripe/updateattachpaymentmethod`, cardObj)
      .then((response) => {
        dispatch(user.ReGetUserData());
        dispatch({
          type: PAYMENT_ACTION_TYPE.paymentCardAdd,
          status: true,
          message: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_ACTION_TYPE.paymentCardAdd,
          status: false,
          message:
            error.code === "ERR_NETWORK"
              ? error?.message
              : error?.response?.data?.error?.raw?.message
              ? error?.response?.data?.error?.raw?.message
              : error?.response?.data?.error,
        });
      });
  };
};
// Function To Remove Invoice Card
const removeInvoiceCard = (cardIdObj) => {
  return async (dispatch) => {
    axios
      .delete(`${BASEURL}/api/stripe/deleteattachpaymentmethod`, {
        data: cardIdObj,
      })
      .then((response) => {
        dispatch(user.ReGetUserData());
        dispatch({
          type: PAYMENT_ACTION_TYPE.paymentModificationStatus,
          status: true,
          message: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_ACTION_TYPE.paymentModificationStatus,
          status: false,
          message:
            error.code === "ERR_NETWORK"
              ? error?.message
              : error?.response?.data?.error?.raw?.message
              ? error?.response?.data?.error?.raw?.message
              : error?.response?.data?.error,
        });
      });
  };
};
//  Function To Reset Stripe Account Status Response
const resetAddPayment = () => {
  return async (dispatch) => {
    dispatch({
      type: PAYMENT_ACTION_TYPE.paymentCardAddIdle,
    });
  };
};

//  Function To Reset Stripe Account Status Response
const resetModifyPayment = () => {
  return async (dispatch) => {
    dispatch({
      type: PAYMENT_ACTION_TYPE.paymentModificationStatusIdle,
    });
  };
};

export const payment = {
  paymentCards,
  resetAddPayment,
  removeCard,
  resetModifyPayment,
  paymentCardUpdate,
  AddInvoiceCard,
  updateInvoiceCard,
  removeInvoiceCard,
};
