import React from "react";
// Message Cards import

const CallCard = ({ data }) => {
	// Desired output date format
	const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
	// Set the desired date components manually
	const desiredDate = new Date(data?.Date_Time);
	// Format the date according to the desired output format
	const formattedDate = desiredDate.toLocaleDateString('en-US', options);
	const convertSecondsToTime = seconds => new Date(seconds * 1000).toISOString().substr(11, 8);

	return (
		<div className="d-flex align-items-center justify-content-between border-bottom border-light">
			<p className="text-muted m-0 p-0  py-3">{formattedDate}</p>
			<p className="text-muted m-0 p-0  py-3">{data?.duration && convertSecondsToTime(data?.duration)} </p>
		</div>
	);
};

export default CallCard;
