/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import TextArea from '../../fields/TextArea';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import DependentInformation from './DependentInformationForm';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';
import { HandleFormData } from '../../../../utilites';

const Dependent = () => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const dispatch = useDispatch();
	const { DependentsOptions } = GLOBAL_VARIABLES;

	const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 2 });

	const [{ taxPayerFormData, taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);

	const [dependentList, setDependentList] = useState([]);
	// For Text Input Field
	const [comments, setComments] = useState('');

	// For Choice Questions
	const [DependentOptions, setDependentOptions] = useState([]);

	const [errors, setErrors] = useState({}); // Track errors for form fields

	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------
	useEffect(() => {
		setDependentOptions(JSON.parse(JSON.stringify(DependentsOptions)));
	}, []);
	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.dependentForm) {
			if (Object.keys(taxPayerForm?.data?.dependentForm).length === 0) return;

			const { dependentList, DependentOptions, comments } = taxPayerForm?.data?.dependentForm;
			setDependentList(dependentList);
			setDependentOptions(DependentOptions);
			setComments(comments);
		}
	}, [taxPayerForm?.data?.dependentForm]);

	const handleQuestions = (index, bool) => {
		const localDependentOptions = DependentOptions.slice();
		localDependentOptions[index].bool = bool;
		setDependentOptions(localDependentOptions);
	};

	// ---------------------------------------
	// Functions
	// ---------------------------------------

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};

		// Add more validation rules as needed for other fields
		if (
			DependentOptions?.flatMap((data) => {
				return data?.bool;
			}).includes(null)
		) {
			newErrors.AnswerOtherQuestions = 'Please Select Required Option';
		}

		setErrors(newErrors);

		// Check if there are any errors

		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = () => {
		let errors = check_Validity();
		if (errors) return;

		let FormObj = { dependentList, DependentOptions, comments };

		let formData = taxPayerForm;
		taxPayerForm.data.dependentForm = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
	};

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 0;
		let myIndex = 2;
		dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
	};
	return (
		<form className="w-100 mt-3">
			<div className="mt-3 row justify-content-between px-3 custom-left-form">
				<div className=" mb-3 px-3">
					{DependentOptions?.map((data, i) => {
						return (
							<div
								key={i}
								className="w-100 d-flex flex-row align-items-center justify-content-between mt-2"
							>
								<small className="col-md-6 form-check-label text-start" htmlFor={`checkbox-${i}`}>
								{data.question}{data.bool===null&&<span className='text-danger small-text mx-1'>*Required</span>}
								</small>
								<div className="col-md-6">
									<input
										onChange={() => handleQuestions(i, true)}
										checked={data?.bool === true}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 176}`}
										name={`checkbox-${i}`}
									/>
									<label htmlFor={`checkbox-${i + 176}`} role="button" className="px-3 m-0 small">
										Yes
									</label>
									<input
										onChange={() => handleQuestions(i, false)}
										checked={data?.bool === false}
										className="form-check-input"
										type="radio"
										id={`checkbox-${i + 999}`}
										name={`checkbox-${i + 999}`}
									/>
									<label htmlFor={`checkbox-${i + 999}`} role="button" className="px-3 m-0 small">
										No
									</label>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			{DependentOptions?.[0]?.bool && (
				<>
					<DependentInformation dependentList={dependentList} setDependentList={setDependentList} />
				</>
			)}
			<small className="ml-4 fw-bold">Comments</small>
			<hr className="m-0" />
			<div className="mt-3 row justify-content-between px-3 mb-3 custom-left-form">
				<small className="text-dark fw-bold m-0 py-2">
					Enter Any Additional Information / Question here.
				</small>
				<div className="col-md-8 mb-3">
					<TextArea
						inputName="form-control"
						defaultValue={comments}
						placeholder="Comments"
						name="comments"
						fn={setComments}
						row="6"
						required={false}
					/>
				</div>
			</div>
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => handleSubmit()}
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default Dependent;
