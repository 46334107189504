import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { BASEURL } from '../../utilites';
import Button from './fields/Button';
import Input from './fields/Input';

export default function EditRoundRobinForm({ proId, roundRobin, reloadUser, setAlert }) {
	const [circleCount, setCircleCount] = useState();

	const [errors, setErrors] = useState({
		circleCount,
		general: '',
	});
	const button = useRef();

	useEffect(() => {
		setCircleCount(roundRobin);
	}, [roundRobin]);
	const handleSubmit = (e) => {
		e.preventDefault();
		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });
		axios
			.put(`${BASEURL}/api/professionals/update-round-robin`, {
				proId,
				circleCount,
			})
			.then(() => {
				button.current.disabled = false;
				reloadUser();
				// Close Modal
				document.querySelector('.roundRobinQuickEditModalClose').click();
				setAlert('Round Robin circle has been updated successfully!');
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};

	return (
		<form className='userform needs-validation' onSubmit={handleSubmit}>
			{circleCount && (
				<div className='row'>
					<div className='mb-3'>
						<Input
							type='number'
							value={circleCount}
							name='roundRobin'
							placeholder='Jobs per circle'
							fn={setCircleCount}
							hasValidationError={errors.circleCount}
							min={1}
						/>
					</div>
				</div>
			)}
			{errors.general && errors.general !== '' && (
				<div className='row'>
					<div className='mb-3'>
						<div className='alert alert-danger' role='alert'>
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 ? ' disabled' : ''
				}`}
				wrapperClass='clearfix w-75 mx-auto d-grid text-center mt-3'
				buttonText='Confirm'
			/>
		</form>
	);
}
