import React from "react";
import Button from "./fields/Button";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../Store/action";

export default function OtpInputForm({
  phoneNo,
  otp,
  setOtp,
  otplength,
  setOtpForm,
  LoginData,
  setTwoAuthEnabled,
  note,
  reset_mesage,
  setMessage,
}) {
  // States & Variables
  const dispatch = useDispatch();
  const [{ OldAuthSendOtp, EnableAuthSendOtp }] = useSelector((state) => [
    state.factAuth,
  ]);

  // Functions For Masking Email & Number
  function maskSensitiveInfo(input) {
    if (input === "") {
      return "";
    }

    if (input.length < 6) {
      var maskedNumber = input.substr(-2).padStart(input.length, "*");
      return maskedNumber;
    }

    maskedNumber = input.substr(-4).padStart(input.length, "*");
    return maskedNumber;
  }
  // Function to Handle Verify OTP
  const handleVerifyOtp = () => {
    if (otp.length !== otplength) {
      setMessage({
        show: true,
        type: "Error",
        text: `Please Enter ${otplength} length OTP.`,
      });
      return;
    }
    reset_mesage();
    if (LoginData.authorization === "0") {
      dispatch(allActions.factAuth.resetEnableVerifyOtp());
      dispatch(allActions.factAuth.enableTwoFactAuthVerifyOtp({ otp }));
    } else {
      if (OldAuthSendOtp === true && EnableAuthSendOtp === true) {
        dispatch(allActions.factAuth.resetEnableVerifyOtp());
        dispatch(allActions.factAuth.updateNumberVerifyOtp({ otp }));
        return;
      }
      dispatch(allActions.factAuth.resetOldVerifyOtp());
      dispatch(allActions.factAuth.otpVerifyOld({ otp }));
    }
  };
  // Function To Resend Code
  const resendCode = () => {
    reset_mesage();
    // Check if user is Enable 2 Auth First Time
    if (LoginData.authorization === "0") {
      dispatch(allActions.factAuth.resetEnableSendOtp());
      dispatch(allActions.factAuth.resetEnableVerifyOtp());

      dispatch(
        allActions.factAuth.enableTwoFactAuthSendOtp({
          phoneNumber: phoneNo,
        })
      );
    } else {
      if (OldAuthSendOtp === true && EnableAuthSendOtp === true) {
        dispatch(allActions.factAuth.resetEnableSendOtp());
        dispatch(allActions.factAuth.resetEnableVerifyOtp());
        dispatch(
          allActions.factAuth.enableTwoFactAuthSendOtp({
            phoneNumber: phoneNo,
            method: "phone",
          })
        );
        return;
      }
      dispatch(allActions.factAuth.resetOldSendOtp());
      dispatch(allActions.factAuth.resetOldVerifyOtp());
      dispatch(
        allActions.factAuth.OtpSendOld({
          phoneNumber: phoneNo,
          method: "phone",
        })
      );
    }
  };

  // Function To Go Back To Last Opened Form
  const gotoBack = () => {
    reset_mesage();
    setOtpForm(false);
    // Check if user is Enable 2 Auth First Time
    if (LoginData.authorization === "0") {
      dispatch(allActions.factAuth.resetEnableSendOtp());
      dispatch(allActions.factAuth.resetEnableVerifyOtp());
    }
     else {
      if (OldAuthSendOtp === true && EnableAuthSendOtp === true) {
        dispatch(allActions.factAuth.resetEnableSendOtp());
        dispatch(allActions.factAuth.resetEnableVerifyOtp());
        setTwoAuthEnabled(false);
      } else {
        dispatch(allActions.factAuth.resetOldSendOtp());
        dispatch(allActions.factAuth.resetOldVerifyOtp());
        setTwoAuthEnabled(true);
      }
    }
  };
  return (
    <div>
      <p className="text-center small-text text-muted">
        {note}
        <p className="text-center small-text fw-bold text-black">
          {maskSensitiveInfo(phoneNo)}
        </p>
      </p>
      <OtpInput
        value={otp}
        onChange={setOtp}
        numInputs={otplength}
        renderSeparator={<span>-</span>}
        renderInput={(props) => (
          <input
            {...props}
            className="py-2 w-25 m-2 lead color-black no-outline otpinput"
          />
        )}
        inputStyles={{
          borderRadius: 3,
          border: "1px solid purple",
          backgroundColor: "#fff",
        }}
      />
      <p className="small-text align-start mt-4">
        It May Take Upto 5 Min To Recieve Otp. Please Wait Before To Send Otp?
        <p
          onClick={() => {
            resendCode();
          }}
          className="text-decoration-underline fw-bold text-primary resend-link text-center small-text">
          Resend New Code
        </p>
      </p>
      <div className="d-flex align-items-center justify-content-center row">
        <Button
          mainClass="btn btn-info custon-button-text btn-lg w-100 text-dark"
          buttonText="Previous"
          wrapperClass={"w-50 custon-button  mt-4"}
          type="button"
          callback={() => gotoBack()}
        />
        <Button
          mainClass="btn btn-dark custon-button-text btn-lg  w-100"
          buttonText="Next"
          wrapperClass={"w-50 custon-button  mt-4"}
          type="button"
          callback={() => handleVerifyOtp()}
        />
      </div>
    </div>
  );
}
