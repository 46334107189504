/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineDownload } from 'react-icons/ai';
import Header from '../../dashboard/components/Header';
import PaymentForm from '../../../components/forms/PaymentForm';
import Message from '../../../components/forms/fields/Message';
import allActions from '../../../Store/action';
import { useAutoReplyHook } from '../../../utilites';
import { isNumberRepeated } from '../../../utilites/functions';

const InvoiceDetail = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useLocation();
	const handleAutoReply = useAutoReplyHook(dispatch);
	// Invoice Id Get From URL
	const url = window.location.href;
	const urlParts = url.split('/');
	const invoiceID = urlParts[urlParts.length - 1];
	const [message, setMessage] = useState({ show: false });
	const [agreement, setAgreement] = useState();
	const [parentParams, setParentParams] = useState();

	useEffect(() => {
		if (params?.state?.navigate === 'projects') {
			dispatch(allActions.invoice.resetCreateInvoice());
			setParentParams(params?.state?.navigate);
		}
		if (params?.state?.message) {
			setMessage({
				show: true,
				type: 'Success',
				text: params?.state?.message,
			});
		}
	}, [params?.state]);
	console.log('STAT>>>', params.state, message);
	// Store States
	const [
		{ invoices, payInvoice, payInvoiceMessage, downloadInvoice, downloadInvoiceMessage },
		{ personalConnections },
		{ LoginData },
	] = useSelector((state) => [state.invoice, state.firebase, state.user]);
	// Filter invoice from there
	const filteredInvoice = invoices?.status
		? invoices?.data.find((data) => data.stripe_invoice_id === invoiceID)
		: {};
	let paidAmount = 0;
	filteredInvoice?.payments?.map((i, v) => (paidAmount = paidAmount + parseFloat(i.amount_paid)));
	//  Get Date From Filtered Invoice
	const options = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	};
	const date = new Date(filteredInvoice?.due_date); // Replace this with your date
	const formattedDate = filteredInvoice?.due_date
		? new Intl.DateTimeFormat('en-US', options).format(date)
		: '00-00-0000';
	// Get Agreement Status
	const job_idRepeating = isNumberRepeated(
		invoices?.data?.map((data) => data.job_id).filter((data) => data),
		filteredInvoice?.job_id
	);
	// Get Paid STATUS
	const filterStatus = invoices?.data.slice()?.map((data) => {
		let paidAmount = 0;
		data?.payments?.map((i, v) => (paidAmount = paidAmount + parseFloat(i.amount_paid)));
		return {
			job_id: data?.job_id,
			paid: parseInt(data?.amount) - paidAmount <= 0,
		};
	});
	const checkOtherWithSameIdPaid =
		filterStatus.filter((data) => data.job_id === filteredInvoice?.job_id && data?.paid)?.length >
		0;

	// console.log("filterStatus", filterStatus);
	useEffect(() => {
		if (invoices.status === 'idle') {
			dispatch(allActions.invoice.getInvoices());
		}
	}, []);

	// Set Agreement Checkbox
	useEffect(() => {
		if (checkOtherWithSameIdPaid && job_idRepeating)
			setAgreement(checkOtherWithSameIdPaid && job_idRepeating);
	}, [job_idRepeating, checkOtherWithSameIdPaid]);

	useEffect(() => {
		if (!invoices?.status) {
			setMessage({
				show: true,
				type: 'Error',
				text: invoices?.message,
			});
			dispatch(allActions.global.Loader(false));
		}
	}, [invoices]);

	// ******************************
	// HOOKS FOR DOWNLOAD INVOICE
	// ******************************

	useEffect(() => {
		if (!downloadInvoice) {
			setMessage({
				show: true,
				type: 'Error',
				text: downloadInvoiceMessage,
			});
			dispatch(allActions.global.Loader(false));
		}
		if (downloadInvoice === true) {
			dispatch(allActions.global.Loader(false));
			window.open(downloadInvoiceMessage?.invoice_pdf);
			dispatch(allActions.invoice.resetDownloadInvoice());
		}
	}, [downloadInvoice, downloadInvoiceMessage]);

	// *********************************************
	// Handle Loading For Fetching Data on Load
	// *********************************************

	useEffect(() => {
		if (invoices?.status === 'idle') {
			dispatch(allActions.global.Loader(true));
		}
		if (invoices?.status === true) {
			dispatch(allActions.global.Loader(false));
		}
	}, [invoices, dispatch]);

	useEffect(() => {
		if (!payInvoice) {
			dispatch(allActions.global.Loader(false));
			if (
				typeof payInvoiceMessage === 'string' &&
				payInvoiceMessage?.indexOf(' PaymentIntent was confirmed with the `off_session` option') > 0
			) {
				setMessage({
					show: true,
					type: 'Error',
					text: 'Select a Default Payment Method Before',
				});
			} else {
				setMessage({
					show: true,
					type: 'Error',
					text: payInvoiceMessage,
				});
			}
		}
		if (payInvoice === true) {
			if (parentParams) {
				navigate(`/dashboard/${parentParams}`);
			} else {
				navigate(-1);
			}
			dispatch(allActions.global.Loader(false));
			handleAutoMessaging();
			setMessage({
				show: true,
				type: 'Success',
				text: 'Invoice Paid Successfully!',
			});
		}
	}, [payInvoice, payInvoiceMessage]);
	const reset_message = () => {
		setMessage({ show: false });
		dispatch(allActions.invoice.resetPayInvoice());
	};
	const handlePayInvoice = () => {
		const invoiceObj = { invoiceId: filteredInvoice?.stripe_invoice_id };
		reset_message();
		dispatch(allActions.global.Loader(true));
		dispatch(allActions.invoice.payInvoice(invoiceObj));
	};

	const handleAutoMessaging = () => {
		handleAutoReply({
			personalConnections,
			LoginData,
			receiver: filteredInvoice?.userpro?.user,
			type: 'simple',
			encodedString: `<p>Hi, I just paid the invoice you sent me.</p>`,
		});
	};
	if (invoices?.status === true && filteredInvoice === undefined) {
		return (
			<div className="clearfix px-3">
				<Header name="Invoice" />
				<div className="container page">
					<div className="clearfix pt-4 mb-4">
						<div className="row justify-content-center">
							<div className="col-md-9">
								<div className="clearfix text-center">
									<br />
									<div className="clearfix text-center">
										<h1>Invoice Details</h1>
									</div>
									<div className="clearfix text-center mt-5">
										<small>You don't have any invoice here with these details</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	return invoices?.status === true ? (
		<div className="clearfix px-3">
			<Header name="Invoice" />
			<div className="container page">
				<div className="clearfix pt-4 mb-4">
					<div className="row justify-content-center">
						<div className="col-md-9">
							<div className="clearfix text-center">
								<h1>Invoice Details</h1>
							</div>
							<div className="row justify-content-center">
								<div className="col-md-8">
									<div className="card border-1 border-secondary-100 rounded shadow-hover mt-4">
										<div className="card-body p-4">
											{message.show ? <Message message={message} /> : <></>}
											<div className="clearfix">
												<small className="text-dark text-opacity-75">Invoice Amount:</small>
												<h1>${filteredInvoice?.amount}</h1>
												<small className="text-dark text-opacity-75">Due {formattedDate}</small>
											</div>
											{parseInt(filteredInvoice?.amount) - paidAmount <= 0 && (
												<div
													className="clearfix border-top py-1 mt-3"
													onClick={() => {
														setMessage({ show: false });
														dispatch(allActions.global.Loader(true));
														dispatch(allActions.invoice.resetDownloadInvoice());
														dispatch(
															allActions.invoice.downloadInvoice(filteredInvoice?.stripe_invoice_id)
														);
													}}
												>
													<Link className="btn btn-link text-decoration-none text-dark" to="#">
														<AiOutlineDownload /> Download Invoice
													</Link>
												</div>
											)}
											<div className="clearfix border-top py-3">
												<p className="mb-0">
													To:
													<span className="ms-4 fw-bold">
														{filteredInvoice?.customers?.user?.firstname +
															' ' +
															filteredInvoice?.customers?.user?.lastname}
													</span>
												</p>
												<p className="mb-0">
													From:
													<span className="ms-3 fw-bold">
														{
															filteredInvoice?.name==='subscription'?
															'Uncle Kam':
														filteredInvoice?.userpro?.user?.firstname +
															' ' +
															filteredInvoice?.userpro?.user?.lastname
														}
													</span>
												</p>
												<p className="mb-0">
													Memo: <span className="ms-3 fw-bold">Thanks for your business!</span>
												</p>
											</div>
											<div className="clearfix mt-2">
												<p className="fw-bold mb-0 border-bottom py-2">
													{filteredInvoice?.name}
													<span className="float-end">
														$
														{/* {filteredInvoice?.payments[0] ? filteredInvoice?.payments[0]?.amount_paid : "0.00"} */}
														{filteredInvoice?.amount}
													</span>
												</p>
												<p className="fw-bold mb-0 py-2">
													Total due{' '}
													<span className="float-end">${filteredInvoice?.amount - paidAmount}</span>
												</p>
											</div>
											{LoginData?.id === filteredInvoice?.customers?.user_id ? (
												<>
													<PaymentForm invoice={filteredInvoice} />
													<div className="clearfix text-center">
														{LoginData?.userpaymentmethod?.length === 0 && (
															<small className="d-block mt-5">
																You do not have any payment method available.{' '}
															</small>
														)}
														<Link
															className="d-block"
															to="#"
															onClick={() => {
																document.querySelector('.paymentformmodal').click();
															}}
														>
															Add New Payment Method
														</Link>
													</div>
													{!(parseInt(filteredInvoice?.amount) - paidAmount <= 0) && (
														<div className="clearfix p-2 mt-4">
															<div className="form-check">
																<input
																	type="checkbox"
																	name="agreed"
																	class="form-check-input"
																	id="agreed"
																	value="1"
																	checked={agreement}
																	onChange={(e) => setAgreement(e.target.checked)}
																/>
																<label className="form-check-label" htmlFor="agreed">
																	By clicking the button below, I agree to the{' '}
																	<Link to="/user-agreement">Terms of Service</Link> and{' '}
																	<Link to="/privacy-policy">Privacy Policy</Link>.
																</label>
															</div>
														</div>
													)}
													<div className="clearfix d-grid mt-4">
														<Link
															className={`btn btn-dark ${
																LoginData?.userpaymentmethod?.length === 0 ||
																parseInt(filteredInvoice?.amount) - paidAmount <= 0 ||
																!agreement
																	? 'disabled'
																	: ''
															}`}
															to="#"
															onClick={() => {
																handlePayInvoice();
															}}
														>
															{parseInt(filteredInvoice?.amount) - paidAmount <= 0
																? 'Invoice Paid'
																: ' Pay Invoice'}
														</Link>
													</div>
												</>
											) : (
												<></>
											)}
											<div className="clearfix d-grid mt-5 text-center">
												<small>
													Question? Contact us at{' '}
													<Link to="#">support@taxreductioncompany.com</Link>
												</small>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	) : (
		''
	);
};

export default InvoiceDetail;
