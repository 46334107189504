export default function Select({
	name,
	placeholder,
	required = true,
	label = placeholder,
	showLabel = false,
	fn,
	children,
	value,
}) {
	return (
		<div className="clearfix">
			{showLabel && (
				<label htmlFor={name} className="form-label">
					{label}
					{required ? '*' : ''}
				</label>
			)}
			<select
				name={name}
				className="form-control"
				required={required}
				onChange={(e) => {
					fn(e.target.value);
				}}
				value={value}
			>
				{children}
			</select>
		</div>
	);
}
