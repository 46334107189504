export default function TextArea({
	defaultValue,
	name,
	placeholder,
	required = true,
	label = placeholder,
	inputClass = 'form-control',
	wrapperClass = 'clearfix',
	fn,
	onLeave,
	hasValidationError,
	isDisabled = false,
	showLabel = true,
	formid = name,
	row = '3',
}) {
	return (
		<div className={wrapperClass}>
			{showLabel && (
				<label htmlFor={name} className="form-label">
					{label + (required ? '*' : '')}
				</label>
			)}
			<textarea
				className={`${inputClass}${hasValidationError ? ' is-invalid' : ''}`}
				placeholder={placeholder}
				required={required}
				disabled={isDisabled}
				value={defaultValue}
				defaultValue={defaultValue}
				onChange={(e) => {
					fn(e.target.value ? e.target.value : '');
				}}
				onBlur={onLeave}
				style={{ height: row + 'rem' }}
				id={formid}
			></textarea>
		</div>
	);
}
