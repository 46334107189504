import HeroUnderLineStroke from '../../assets/img/hero-underline-stroke.png';
import HeroCircleStroke from '../../assets/img/hero-circle-stroke.png';
import HeroMain from '../../assets/img/hero-main.png';

const Header = () => {
    return (
        <header className="header px-3">
            <div className="container">
                <div className="clearfix text-dark text-center mt-4 mb-5 mb-md-0">
                    <h1 className="display-1 text-uppercase fw-bold lh-sm">
                        <span className="d-inline d-md-block">
                            <span className="hero-underline-stroke">Saving More<img src={HeroUnderLineStroke} alt="Uncle Kem" /></span> on Taxes</span> Has Never Been <span className="text-primary hero-circle-stroke">Easier.<img src={HeroCircleStroke} alt="Uncle Kem" />
                        </span>
                    </h1>
                    <div className="clearfix position-relative top-n20">
                        <img src={HeroMain} className="img-fluid" alt="Uncle Kem" />
                    </div>
                    <h1 className="display-3 position-relative w-75 mx-auto fw-bold lh-1 top-n70">
                        <span className="d-inline d-md-block"><span className="text-primary">Uncle Kam</span> is here to</span> help you reach new heights.
                    </h1>
                </div>
            </div> 
        </header>
    )
};

export default Header;