import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import Input from "./fields/Input";
import Button from "./fields/Button";

import { BASEURL } from "../../utilites";

export default function UpdatePassword({ data = {}, oldPassword = true }) {
	const [password, setPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [passwordRepeat, setPasswordRepeat] = useState("");
	const [alert, setAlert] = useState({
		password: false,
		newPassword: false,
		passwordRepeat: false,
		class: "danger",
		general: "",
	});
	const button = useRef();
	// set the form data
	useEffect(() => {
		if (data) {
		}
	}, [data]);
	const endpoint = `${BASEURL}/api/auth/profile/reset-password/${data.uuid}`;
	// function to handle form form submission
	const handleFormSubmission = (e) => {
		e.preventDefault();
		// disable the button
		button.current.disabled = true;
		button.current.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...`;
		// send the form data to the server
		axios
			.post(endpoint, {
				password,
				newPassword,
				passwordRepeat,
			})
			.then((response) => {
				if (response.data.success && response.data.message !== "") {
					setAlert({
						class: "success",
						general: response.data.message,
					});
				}
				// reload the page after 3 seconds for now
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			})
			.catch((error) => {
				console.error(error);
				button.current.disabled = false;
				button.current.innerHTML = "Update Password";
				setAlert({
					class: "danger",
					general: error?.response?.data?.error,
				});
			});
	};
	return (
		<div>
			<form className="passwordReset needs-validation" onSubmit={handleFormSubmission}>
				{alert.general && alert.general !== "" && (
					<div
						className={`alert alert-${alert.class} alert-dismissible fade show mb-4 text-center`}
						role="alert"
					>
						{alert.general}
					</div>
				)}
				{oldPassword && (
					<>
						<div className="mb-3">
							<Input
								type="password"
								value={password}
								name="password"
								placeholder="Password"
								wrapperClass="position-relative"
								fn={setPassword}
								showLabel={false}
							/>
						</div>
						<fieldset className="border-top mb-3 text-start mt-3">
							<legend className="w-auto float-none px-2 ms-3 fs-6">New Password</legend>
						</fieldset>
					</>
				)}
				<div className="mb-3">
					<Input
						type="password"
						value={newPassword}
						name="newPassword"
						placeholder="New Password"
						fn={setNewPassword}
						showLabel={false}
					/>
				</div>
				<div className="mb-3">
					<Input
						type="password"
						value={passwordRepeat}
						name="passwordRepeat"
						placeholder="Password Confirmation"
						fn={setPasswordRepeat}
						showLabel={false}
					/>
				</div>
				{oldPassword && <p className="text-center">Are you sure you want to change password?</p>}
				<Button
					buttonRef={button}
					mainClass="btn btn-danger btn-lg"
					wrapperClass="clearfix d-grid text-center mt-4"
					buttonText="Update Password"
				/>
			</form>
		</div>
	);
}
