/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Modal from "../../Dashboard/Modal";
import { Link } from "react-router-dom";
import allActions from "../../../Store/action";
import { INVOICE_ACTION_TYPE } from "../../../Store/invoice/Type";
import { useDispatch, useSelector } from "react-redux";
import Message from "../fields/Message";

export default function InvoicePaid({ selectedInvoice, setSelectedInvoice, setListMessage, message, setMessage }) {
	// ---------
	// States
	// ---------
	const dispatch = useDispatch()
	// const [agree, setAgree] = useState(null);
	const [{ invoicePaid }] = useSelector((state) => [state.invoice]);

	useEffect(() => {
		if (invoicePaid?.status===false) {
			setMessage({
				show: true,
				type: 'Error',
				text: invoicePaid.message,
			});
			dispatch(allActions.global.Loader(false));
		}

		if (invoicePaid?.status === true) {
			setListMessage({
				show: true,
				type: 'Success',
				text: invoicePaid.message,
			});
			// setAgree()
			setSelectedInvoice('')
			dispatch(allActions.invoice.getInvoices())
			dispatch(allActions.global.Loader(false));
			document.getElementById('closeInvoicePaidButton').click()
		}
	}, [invoicePaid, invoicePaid?.message, invoicePaid?.status]);

	// ---------
	// Functions
	// ---------
	// const handleAgreeChange = () => setAgree(!agree);

	const handleSubmit = (e) => {
	try {
		let { pro_id, stripe_invoice_id } = selectedInvoice;
		const payload = {
			"pro_id": pro_id,
			"stripe_invoice_id": stripe_invoice_id,
			// "mark_paid": agree
		}
		e?.preventDefault()
		// setMessag
		setMessage({ show: false })
		setListMessage({ show: false })
		dispatch(allActions.global.Loader(true));
		dispatch({ type: INVOICE_ACTION_TYPE.invoicePaidIdle });
		dispatch(allActions.invoice.invoicePaid(payload));
	} catch (error) {}
	}

	return (
		<Modal title={"Transfer Fund"} bodyClass="p-4" btnText={"Paid"} trigger="invoicePaid" showBtn={false} size={"md"} close={false}>
			{message?.show ? <Message message={message} /> : <></>}
			{/* <div className="row align-items-center mx-1">
				<div className="col-auto">
					<div className="form-check form-check-inline">
						<label className="form-check-label" htmlFor="agreeCheckbox">
							<input className="form-check-input custom-checkbox" type="checkbox" id="agreeCheckbox" checked={agree} onChange={handleAgreeChange} />
							Mark Invoice as completed
						</label>
					</div>
				</div>
			</div> */}
			<div className="d-flex align-items-center justify-content-between row px-4">
				<Link data-bs-toggle="modal" data-bs-target="#invoicePaid" className="col-md-5 btn btn-outline-primary btn-md mt-2" id="closeInvoicePaidButton">
					Not yet
				</Link>
				<Link className="col-md-5 btn btn-primary btn-md mt-2" onClick={(e) => handleSubmit(e)}>
					Transfer Fund
				</Link>
			</div>
		</Modal>
	);
}
