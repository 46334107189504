import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BASEURL } from '../../utilites';
import Button from './fields/Button';
import Select from './fields/Select';

export default function EditTaxproStatusForm({ proId, currentStatus, reload, setAlert }) {
	const [status, setStatus] = useState();
	const [errors, setErrors] = useState({
		status: false,
		general: '',
	});
	const button = useRef();

	useEffect(() => {
		setStatus(currentStatus?.toString());
	}, [currentStatus]);

	const handleSubmit = (e) => {
		e.preventDefault();

		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });

		axios
			.put(`${BASEURL}/api/professionals/update-status`, {
				proId,
				status,
			})
			.then(() => {
				button.current.disabled = false;
				reload && reload();
				// Close Modal
				document.querySelector('.userTaxStatusEditModalClose').click();
				setAlert("Tax professional's status has been updated successfully!");
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({
						errors,
						general:
							error?.response?.data?.error ||
							error?.response?.data?.message ||
							error?.message ||
							'Internel Server Error!',
					});
			});
	};

	return (
		<form className='userform needs-validation' onSubmit={handleSubmit}>
			<div className='row'>
				<div className='col-12 mb-3'>
					<Select
						value={status}
						name='status'
						placeholder='Business Status'
						showLabel={true}
						fn={setStatus}
					>
						<option value='0'>Pending</option>
						<option value='1'>Under Review</option>
						<option value='2'>Activated</option>
						<option value='3'>Paused</option>
						<option value='4'>Suspended</option>
						<option value='5'>Denied</option>
					</Select>
				</div>
			</div>
			{errors.general && errors.general !== '' && (
				<div className='row'>
					<div className='mb-3'>
						<div className='alert alert-danger' role='alert'>
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 || !status || currentStatus === parseInt(status)
						? ' disabled'
						: ''
				}`}
				wrapperClass='clearfix w-75 mx-auto d-grid text-center mt-1'
				buttonText='Update status'
				callback={() => {
					setErrors({ errors, general: '' });
				}}
			/>
		</form>
	);
}
