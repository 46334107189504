import React, { useState, useRef } from 'react';
import axios from 'axios';
import ReactSelect from 'react-select';
import { BASEURL } from '../../utilites';
import Button from './fields/Button';

export default function AddAppointmentUserForm({ data, users, setAlert, reload }) {
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [errors, setErrors] = useState({
		selectedUsers: false,
		general: '',
	});
	const button = useRef();

	const handleSubmit = (e) => {
		e.preventDefault();

		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });

		axios
			.post(`${BASEURL}/api/appointments/add-users`, {
				appointment_id: data.id,
				selectedUsers,
			})
			.then(() => {
				button.current.disabled = false;
				// Reload appointments
				reload();
				// Close Modal
				document.querySelector('.addUsersToAppointmentModalClose').click();
				setAlert('Users has been added successfully!');
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};

	const handleUserSelect = (selectedOptions) => {
		const selectedIds = selectedOptions?.map((option) => option.value);
		setSelectedUsers(selectedIds);
	};

	return (
		<form className="appointmentsform needs-validation" onSubmit={handleSubmit}>
			<div className="row">
				<div className="col-md-12">
					<div className="mb-3">
						<ReactSelect
							id="users"
							className="p-0"
							placeholder="Select Users"
							closeMenuOnSelect={false}
							isMulti
							options={users?.map((u) => ({
								value: u.id,
								label: `${u.firstname} ${u.lastname}`,
								isDisabled: data.users.find((usr) => usr.appointmentuser.user_id === u.id)
									? true
									: false,
							}))}
							onChange={handleUserSelect}
							value={selectedUsers?.map((id) => ({
								value: id,
								label:
									users.find((user) => user.id === id)?.firstname +
									' ' +
									users.find((user) => user.id === id)?.lastname,
							}))}
						/>
						<label htmlFor="users" className="form-label">
							Users
						</label>
					</div>
				</div>
			</div>
			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mb-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 ? ' disabled' : ''
				}`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-3"
				buttonText="Add users to appointment"
			/>
		</form>
	);
}
