import React from 'react';
import { FaPhone } from "react-icons/fa";

const DialpadModal = ({hangUp,connection}) => {

  const getNum = (e) => {
    const digit = e.target.innerText;
    if (connection) {
      connection.sendDigits(digit); 
    }
  }

  return (
      <section className="center-column">
      <div id="call-controls" className="hide">
        <div className="pad-container">
          <form name="input">
            <div className="pad-row">
              <div className="digit" id="one" onClick={(e) => getNum(e)}>1</div>
              <div className="digit" id="two" onClick={(e) => getNum(e)}>2</div>
              <div className="digit" id="three" onClick={(e) => getNum(e)}>3</div>
            </div>
            <div className="pad-row">
              <div className="digit" id="four" onClick={(e) => getNum(e)}>4</div>
              <div className="digit" id="five" onClick={(e) => getNum(e)}>5</div>
              <div className="digit" onClick={(e) => getNum(e)}>6</div>
            </div>
            <div className="pad-row">
              <div className="digit" onClick={(e) => getNum(e)}>7 </div>
              <div className="digit" onClick={(e) => getNum(e)}>8</div>
              <div className="digit" onClick={(e) => getNum(e)}>9 </div>
            </div>
            <div className="pad-row">
              <div className="digit" onClick={(e) => getNum(e)}>*</div>
              <div className="digit" onClick={(e) => getNum(e)}>0</div>
              <div className="digit" onClick={(e) => getNum(e)}>#</div>
            </div>
            <div className="botrow">
              <div id="hangup" type="submit" onClick={hangUp}>
								<FaPhone className="icons fs-7 mx-2" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default DialpadModal;