import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from './fields/Button';

import allActions from '../../Store/action';
import Message from './fields/Message';
import { TASKS_ACTION_TYPE } from '../../Store/tasks/Type';
import moment from 'moment';
import DropDown from './fields/DropDown';

export default function AddTasksForm({ setOuterMessage }) {
	const button = useRef();
	const dispatch = useDispatch();
	const options = [{ value: 'appointments', label: 'Appointments' }]

	const [message, setMessage] = useState({ show: false })
	const [{ appointments }, { createTasks }] = useSelector((state) => [state.dashboard, state.tasks]);
	const [selectedAppointment, setSelectedAppointment] = useState()
	const data = selectedAppointment?.value;
	const appointmentList = appointments?.data.map((data) => {
		return ({
			value: data,
			label: data.title
		})
	})
	// HOOKS
	useEffect(() => {
		if (createTasks?.status === true) {
			setMessage({ show: false })
			setSelectedAppointment()
			setOuterMessage({ show: false });
			if (document.querySelector('.addTasksFormBtn')) document.querySelector('.addTasksFormBtn').click()
			setOuterMessage({
				show: true,
				type: 'Success',
				text: "Task Created Successfully!",
			});
			changeLoading(false)
		}
		if (createTasks?.status === false) {
			setMessage({ show: false })
			setMessage({
				show: true,
				type: 'Error',
				text: createTasks?.message,
			});
			changeLoading(false)
		}

		if (createTasks?.status === 'idle') {
			setMessage({ show: false });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createTasks?.status]);

	useEffect(() => {
		dispatch(allActions.dashboard.getAppointments());
		dispatch(allActions.dashboard.getUsersForAppointment());
	}, [dispatch])

	// function to handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();


		if (!selectedAppointment) { return setMessage({ show: true, type: 'Error', text: 'Select Appointment first!' }) }

		const createTaskObj = {
			"bulkpayload": [
				{
					"type": 1,
					"timezone": data?.timezone,
					"status_Id": 0,
					"model_id": data?.id,
					trigger_at: moment.utc(data?.start_date).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
					"env": process.env.REACT_APP_ENV
				},
				{
					"type": 1,
					"timezone": data?.timezone,
					"status_Id": 0,
					"model_id": data?.id,
					trigger_at: moment.utc(data?.start_date).subtract(60, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
					"env": process.env.REACT_APP_ENV
				},
				{
					"type": 1,
					"timezone": data?.timezone,
					"status_Id": 0,
					"model_id": data?.id,
					trigger_at: moment.utc(data?.start_date).subtract(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
					"env": process.env.REACT_APP_ENV
				},
				{
					"type": 1,
					"timezone": data?.timezone,
					"status_Id": 0,
					"model_id": data?.id,
					trigger_at: moment.utc(data?.start_date).subtract(5, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
					"env": process.env.REACT_APP_ENV
				}
			]
		}
		changeLoading(true)
		setMessage({ show: false })
		dispatch({ type: TASKS_ACTION_TYPE.CREATE_TASKS_IDLE });
		dispatch(allActions.tasks.createTasks(createTaskObj));
	};

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

	return (
		<form className="disputessform needs-validation" onSubmit={handleSubmit}>
			{message.show ? <Message message={message} /> : <></>}
			<div className='my-4'>
				<DropDown
					options={options}
					value={options?.[0]}
					setValue={() => { }}
					label='Select Option'
					showLabel={false}
					isDisabled={false}
				/>
			</div>
			<div className='my-4'>
				<DropDown
					options={appointmentList}
					value={selectedAppointment}
					setValue={setSelectedAppointment}
					label='Select Appointment'
					showLabel={false}
					isDisabled={false}
				/>
			</div>
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-3"
				buttonText="Create task"
			/>
		</form>
	);
}
