import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BsThreeDotsVertical } from 'react-icons/bs';
// import SweetAlert2 from 'react-sweetalert2';
import {
	getDisputeStatus,
	setQueryParams,
	getQueryParam,
	removeQueryParam,
	getDisputeStatusClass,
} from '../../../utilites';
import allActions from '../../../Store/action';
import ListPageWrapper from '../components/ListPageWrapper';
// import Modal from '../../../components/Dashboard/Modal';
import Input from '../../../components/forms/fields/Input';
import UserAvatar from '../../../components/UserAvatar';

const DisputesList = () => {
	const [{ LoginData }, { disputes }] = useSelector((state) => [state.user, state.dashboard]);
	const dispatch = useDispatch();
	const [alertMessage, setAlertMessage] = useState('');
	// Pagination
	const [perPage, setPerPage] = useState(getQueryParam('per_page') || 10);
	const [page, setPage] = useState(getQueryParam('page') || 1);
	// Search
	const [keyword, setKeyword] = useState(getQueryParam('search') || '');
	const [status, setStatus] = useState(getQueryParam('status') || '');

	useEffect(() => {
		if (LoginData) {
			dispatch(allActions.dashboard.getDisputes(keyword, status, page, perPage));
		}
	}, [dispatch, LoginData, keyword, status, page, perPage]);

	// const loadDisputes = () => {
	// 	dispatch(allActions.dashboard.getDisputes(keyword, status, page, perPage));
	// };

	// const deleteDispute = (id) => {
	// 	dispatch(allActions.dashboard.deleteDispute(id, setAlertMessage, loadDisputes));
	// };

	// const handleDeleteClick = (id) => {
	// 	setSwalProps((prev) => ({
	// 		...prev,
	// 		[id]: {
	// 			show: true,
	// 			showDenyButton: true,
	// 			title: 'Are you sure?',
	// 			text: 'You will not be able to recover this dispute!',
	// 			confirmButtonText: 'Confirm',
	// 			denyButtonText: 'Cancel',
	// 			didClose: () => setSwalProps((prev) => ({ ...prev, [id]: { show: false } })),
	// 			onConfirm: () => deleteDispute(id),
	// 		},
	// 	}));
	// };

	const moveTo = (page) => {
		setPage(page);
		// Set query param
		setQueryParams('page', page);
	};
	const changePerPage = (perPage) => {
		setPerPage(perPage);
		// Set query param
		setQueryParams('per_page', perPage);
	};
	const filterByKeyword = (keyword) => {
		setKeyword(keyword);
		// Set query param
		if (!keyword) {
			removeQueryParam('search');
		} else {
			setQueryParams('search', keyword);
		}
	};
	const filterByStatus = (status) => {
		setStatus(status);
		// Set query param
		if (!status) {
			removeQueryParam('status');
		} else {
			setQueryParams('status', status);
		}
	};
	return (
		<ListPageWrapper
			heading="Disputes"
			underHeader={
				<Fragment>
					{disputes.statistics && (LoginData.isAdmin || LoginData.isAccountManager) ? (
						<Fragment>
							<div className="col-md-6">
								<div className="card border-light">
									<div className="card-body">
										<h5 className="card-title">Active</h5>
										<p className="card-text fw-bold fs-3">{disputes.statistics.active}</p>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="card border-light">
									<div className="card-body">
										<h5 className="card-title">Resolved</h5>
										<p className="card-text fw-bold fs-3">{disputes.statistics.resolved}</p>
									</div>
								</div>
							</div>
						</Fragment>
					) : null}
					<div className="row g-3">
						<div className="col-md-3">
							<Input
								type="search"
								value={keyword}
								name="name"
								placeholder="Search"
								fn={filterByKeyword}
								showLabel={false}
								inputClass="search-input border h-auto min-height-initial"
								extraSetter={() => {
									if (page !== 1) moveTo(1);
								}}
								required={false}
							/>
						</div>
						<div className="col-md-2">
							<select
								className="form-select"
								id="filter"
								name="status"
								onChange={(e) => {
									filterByStatus(e.target.value);
									if (page !== 1) moveTo(1);
								}}
								value={status}
							>
								<option value="">Status</option>
								<option value="0">Active</option>
								<option value="1">Resolved</option>
							</select>
						</div>
						<div className={`col-md-7`}>
							<Link
								to="#"
								className="btn btn-outline-primary px-4 float-md-end float-none"
								// data-bs-toggle='modal'
								// data-bs-target='#testModal'
							>
								Button
							</Link>
						</div>
					</div>
				</Fragment>
			}
			alert={{ message: alertMessage, setMessage: setAlertMessage }}
			dataState={{
				loaded: disputes.loaded,
				fetching: disputes.fetching,
			}}
			tableHeader={
				<Fragment>
					<th scope="col">ID</th>
					<th scope="col">Operator</th>
					<th scope="col">Customer</th>
					<th scope="col">Dispute start date</th>
					<th scope="col">Days open</th>
					<th scope="col">Status</th>
					<th scope="col"></th>
				</Fragment>
			}
			tableBody={
				<Fragment>
					{disputes.data.length === 0 ? (
						<tr>
							<td className="text-center opacity-50" colSpan={6}>
								There are no disputes available at this time
							</td>
						</tr>
					) : (
						disputes.data?.map((dispute) => {
							return (
								<tr className="align-middle" key={dispute.id}>
									<th scope="row">{dispute.id}</th>
									<td className="text-md-start text-center">
										<Link
											to={`/dashboard/users/${dispute.job.leads.userpro.user.uuid}`}
											className="text-decoration-none"
										>
											<UserAvatar
												avatar={dispute.job.leads.userpro.user.avatar}
												alt={`${dispute.job.leads.userpro.user.firstname} ${dispute.job.leads.userpro.user.lastname}`}
												className="rounded-circle"
												style={{ width: 35, height: 35 }}
											/>
											<span className="text-dark ps-1">
												{`${dispute.job.leads.userpro.user.firstname} ${dispute.job.leads.userpro.user.lastname}`}
											</span>
										</Link>
									</td>
									<td className="text-md-start text-center">
										<Link
											to={`/dashboard/users/${dispute.job.leads.user.uuid}`}
											className="text-decoration-none"
										>
											<UserAvatar
												avatar={dispute.job.leads.user.avatar}
												alt={`${dispute.job.leads.user.firstname} ${dispute.job.leads.user.lastname}`}
												className="rounded-circle"
												style={{ width: 35, height: 35 }}
											/>
											<span className="text-dark ps-1">
												{`${dispute.job.leads.user.firstname} ${dispute.job.leads.user.lastname}`}
											</span>
										</Link>
									</td>
									<td>
										{new Date(dispute.created_at).toLocaleDateString('en-US', {
											year: 'numeric',
											month: 'short',
											day: 'numeric',
										})}
									</td>
									<td>
										{Math.floor(
											(new Date() - new Date(dispute.created_at)) / (1000 * 60 * 60 * 24)
										)}
									</td>
									<td>
										<span className={`text-${getDisputeStatusClass(dispute.status_id)}`}>
											{getDisputeStatus(dispute.status_id)}
										</span>
									</td>
									<td className="text-end">
										<div className="dropdown-center">
											<button
												type="button"
												className="btn btn-sm btn-outline-primary rounded-circle disabled"
												data-bs-toggle="dropdown"
												aria-expanded="false"
											>
												<BsThreeDotsVertical className="" />
											</button>
											<ul className="dropdown-menu border-light">
												<li>
													<Link
														className="dropdown-item"
														to={`/dashboard/invoices/disputes/${dispute.uuid}`}
													>
														Details
													</Link>
												</li>
												<li>
													<hr className="dropdown-divider border-light" />
												</li>
												<li>
													<Fragment>
														<Link
															to="#"
															className="dropdown-item text-danger"
															// onClick={() => handleDeleteClick(dispute.id)}
														>
															Delete
														</Link>
														{/* <SweetAlert2 {...swalProps[dispute.id]} /> */}
													</Fragment>
												</li>
											</ul>
										</div>
									</td>
								</tr>
							);
						})
					)}
				</Fragment>
			}
			paginationProps={{
				pageCount: Math.ceil(disputes?.count / perPage),
				currentPage: parseInt(page),
				setCurrentPage: moveTo,
				perPage: perPage,
				setPerPage: (pc) => {
					changePerPage(pc);
					// Move to first page
					if (page !== 1) moveTo(1);
				},
			}}
			// modals={
			// 	<Fragment>
			// 	</Fragment>
			// }
		/>
	);
};

export default DisputesList;
