import React, { useEffect, useState } from 'react';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';
import SingleCheckLabelSelector from '../../fields/SingleCheckLabelSelector';
import OtherDataAdditionForm from './OtherDataAdditionForm';
import Table from '../../Table/fixedAssetsTable';
import { HandleFormData } from '../../../../utilites';

const FixedAssets = () => {
	//
	//  ---------------------------------------
	//  Variables , States & Store States
	//  ---------------------------------------
	//
	const dispatch = useDispatch();
	const { SimpleChoiceOptions } = GLOBAL_VARIABLES;
	const uploadFormData = HandleFormData({ parentIndex: 5, myIndex: 0 });
	const [{ taxPayerFormData, taxPayerForm, formInformation }] = useSelector((state) => [state.taxForms]);


	// For Choice Questions
	const [FixedAssets, setFixedAssets] = useState();
	const [nonInventoryAssets, setNonInventoryAssets] = useState();
	const [assetsTypes, setAssetsTypes] = useState([]);
	const [errors, setErrors] = useState({});
	// ---------------------------------------
	// HOOKS TO STORE DATA
	// ---------------------------------------

	// SAVE VALUES TO STATES
	useEffect(() => {
		if (taxPayerForm?.data?.FixedAssetsForm) {
			if (Object.keys(taxPayerForm?.data?.FixedAssetsForm).length === 0) return;
			const { assetsTypes, FixedAssets, nonInventoryAssets } = taxPayerForm?.data?.FixedAssetsForm;
			setNonInventoryAssets(nonInventoryAssets);
			setFixedAssets(FixedAssets);
			setAssetsTypes(assetsTypes);
		}
	}, [taxPayerForm?.data?.FixedAssetsForm]);

	// ---------------------------------------
	// Functions
	// ---------------------------------------

	const check_Validity = () => {
		setErrors({});
		const newErrors = {};
		// Add more validation rules as needed for other fields
		if (!FixedAssets) newErrors.FixedAssets = 'Select Category';
		if (FixedAssets?.label === 'yes' && !nonInventoryAssets)
			newErrors.FixedAssets = 'Select Method of Assets';
		setErrors(newErrors);
		let ConditionCheck = Object.keys(newErrors).length === 0 ? false : true;
		// Check if there are any errors
		return ConditionCheck;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let errors = check_Validity();
		if (errors) return;
		let FormObj = { FixedAssets, nonInventoryAssets, assetsTypes };
		let formData = taxPayerForm;
		taxPayerForm.data.FixedAssetsForm = FormObj;
		dispatch(allActions.taxForms.StoreFormData({ formData }));

		uploadFormData(formData);
	};

	const goBack = () => {
		// CHANGE FORM TO BACK
		let parentIndex = 5;
		let myIndex = 0;
		dispatch(
			allActions.taxForms.moveToBack({
				parentIndex,
				myIndex,
				taxPayerFormData,
			})
		);
	};
	return (
		<form className="w-100 mt-3 " onSubmit={handleSubmit}>
			<SingleCheckLabelSelector
				heading={' Did the business purchase any non-Inventory assets that cost more than $2,500? '}
				value={FixedAssets}
				setValue={setFixedAssets}
				mapData={SimpleChoiceOptions}
			/>

			{FixedAssets?.label === 'yes' && (
				<>
					<SingleCheckLabelSelector
						heading={'Did the business sell or otherwise dispose of any non-Inventory assets? '}
						value={nonInventoryAssets}
						setValue={setNonInventoryAssets}
						mapData={SimpleChoiceOptions}
					/>

					{assetsTypes?.length > 0 && (
						<div className="bg-transparent card p-2">
							<Table
								Header={[
									'Description of property',
									'Date acquired',
									'Cost',
									'Assets - (Sold / Deposit)',
								]}
								List={assetsTypes}
							/>
						</div>
					)}

					<OtherDataAdditionForm
						oldValues={assetsTypes}
						setState={setAssetsTypes}
						heading="Fixed assets details"
						BtnText="Done"
						Datakeys={[
							'question',
							'DateAcquired',
							'cost',
							{ type: 'CheckBox', mode: 'single', key: 'applied' },
						]}
						DataLabels={[
							'Description of property',
							'Date acquired',
							'Cost',
							{
								Heading: 'Select if Applicable',
								label: 'The Asset was sold or deposit during the year',
							},
						]}
					/>
				</>
			)}
			{Object.keys(errors)
				?.map((key) => {
					return errors[key];
				})
				?.map((data, index) => {
					return (
						<>
							<small className="text-primary" key={index}>
								{data}*
							</small>
							<br />
						</>
					);
				})}
			<div className="container-fluid content-row pb-4">
				<div className="d-flex align-items-center justify-content-center row">
					<Button
						mainClass="btn btn-info custon-button-text  w-100 text-dark"
						buttonText="Previous"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="button"
						callback={() => goBack()}
					/>
					<Button
						mainClass="btn btn-primary custon-button-text   w-100"
						buttonText="Next"
						wrapperClass={'w-25 custon-button  mt-4'}
						type="submit"
						disabled={!formInformation?.lead_id}
					/>
				</div>
			</div>
		</form>
	);
};

export default FixedAssets;
