import Select from "react-select";
import React from "react";
const DropDown = ({
	options,
	value,
	setValue,
	label,
	isDisabled,
	resetValues,
	showLabel = true,
	required = true,
}) => {
	// const [open, setOpen] = React.useState("");

	const customStyles = {
		option: (provided) => ({
			...provided,
			textAlign: "left",
		}),
		singleValue: (provided) => ({
			...provided,
			textAlign: "left",
		}),
		placeholder: (provided) => ({
			...provided,
			textAlign: "left",
			fontSize: 14,
		}),
		control: (base, state) => ({
			...base,
			border: "1px solid #e9ecf0",
			boxShadow: "none",
			"&:hover": {
				border: "1px solid #e9ecf0",
			},
		}),
	};
	return (
		<div className={"form-floating"}>
			{showLabel && (
				<div className="text-dark">
					<span className="dropdown-label"> {label}</span>
				</div>
			)}
			<Select
				className="basic-single"
				classNamePrefix="select"
				placeholder={label}
				value={value ? value : ""}
				isDisabled={isDisabled}
				isLoading={false}
				isClearable={false}
				isRtl={false}
				isSearchable={false}
				name="color"
				options={options}
				styles={customStyles}
				onChange={(e) => {
					setValue(e);
					if (resetValues) {
						resetValues();
					}
				}}
				required={required}
			/>
		</div>
	);
};

export default DropDown;
