/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import allActions from '../../Store/action';
import { JOBS_ACTION_TYPE } from '../../Store/jobs/Type';
import Button from './fields/Button';
import DropDown from './fields/DropDown';
import Message from './fields/Message';
import TextArea from './fields/TextArea';
import { Link } from 'react-router-dom';
import { useAutoReplyHook } from '../../utilites';



const JobRequestForm = ({ uuid, setMessage, triggerClass, receiver, type = 2, selectable = true,
	types = [
		{ label: 'Modify Contract Request', value: '0' },
		{ label: 'Modify Document Request', value: '1' },
		{ label: 'Cancel Job Request', value: '2' },
		{ label: 'Modify Job', value: '4' },
		] }) => {
	const dispatch = useDispatch();
	const handleAutoReply = useAutoReplyHook(dispatch);

	const [{ LoginData }, { cancelJobRequest }, { personalConnections }] = useSelector((state) => [
		state.user,
		state.jobs,
		state.firebase,
	]);
	const [modalMessage, setModalMessage] = useState({ show: false });
	const [typeId, setTypeId] = useState(types[type]);
	const [content, setContent] = useState('');
	const [agree, setAgree] = useState(false);
	useEffect(() => {
		if (cancelJobRequest?.status === 'idle') {
			setModalMessage({ show: false });
		}
		if (!cancelJobRequest?.status) {
			handleFormStatusChange('Error', cancelJobRequest.message);
		}
		if (cancelJobRequest?.status === true) {
			handleFormStatusChange('Success', cancelJobRequest.message);
			if (triggerClass && document.querySelector(triggerClass)) {
				document.querySelector(triggerClass).click();
			}
		}
		if (cancelJobRequest?.status === true && receiver && type !== 2) {
			handleAutoReply({
				personalConnections,
				LoginData,
				receiver: receiver,
				type: 'simple',
				encodedString: `<p>${types[type].label}</p><p>${content}</p><a href="${window.location.origin}/dashboard/contract/${uuid}" target="_blank" >Contract</a>`,
			});
		}
	}, [cancelJobRequest?.message, cancelJobRequest.status]);

	const handleFormStatusChange = (type, text) => {
		changeLoading(false);
		if (type === 'Error') {
			setModalMessage({ show: true, type, text });
		} else {
			setTypeId(types[type]);
			setContent('');
			setAgree(false);
			setMessage({ show: true, type, text });
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		const requestData = {
			type_id: typeId.value,
			description: content,
		};
		reset_values();
		changeLoading(true);
		dispatch({ type: JOBS_ACTION_TYPE.cancelJobRequestIdle });
		// console.log("UUID&&&", uuid)
		dispatch(allActions.jobs.cancelJobRequest({ uuid, obj: requestData }));
	};

	const reset_values = () => {
		setModalMessage({ show: false });
		setMessage({ show: false });
	};

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

	return (
		<form className='usersform needs-validation' onSubmit={handleSubmit}>
			{modalMessage?.show ? <Message message={modalMessage} /> : null}
			<div className='row'>
				<div className='mb-3'>
					<DropDown
						options={types}
						value={typeId}
						setValue={setTypeId}
						label={'Select Organization*'}
						showLabel={false}
						isDisabled={!selectable}
					/>
				</div>
				<div className='mb-3'>
					<TextArea
						inputName='form-control'
						defaultValue={content}
						placeholder='Please type in a full description of your request.'
						name='content'
						fn={setContent}
						row='8'
						required={true}
						showLabel={false}
					/>
				</div>
			</div>
			<div className='w-100 d-flex align-items-center justify-content-center'>
				<div className='clearfix  col-md-11 '>
					<div className='form-check'>
						<input
							type='checkbox'
							name='agreed'
							className='form-check-input'
							id='agreed'
							value='1'
							checked={agree}
							onChange={(e) => setAgree(e.target.checked)}
						/>
						<label className='form-check-label' htmlFor='agreed'>
							Yes, I understand and agree to the Uncle Kam Terms of Service, including the{' '}
							<Link to='/user-agreement'>User Agreement</Link> and
							<br /> <Link to='/privacy-policy'>Privacy Policy</Link>.
						</label>
					</div>
				</div>
			</div>
			<Button
				mainClass='btn btn-danger btn-md px-4'
				wrapperClass='clearfix w-50 mx-auto d-grid text-center mt-3'
				buttonText='Submit Request'
				disabled={!agree}
			/>
		</form>
	);
};

export default JobRequestForm;
