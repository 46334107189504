import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { setQueryParams, getQueryParam, removeQueryParam } from '../../../utilites';
import allActions from '../../../Store/action';
import ListPageWrapper from '../components/ListPageWrapper';
import Input from '../../../components/forms/fields/Input';
import Message from '../../../components/forms/fields/Message';
import { Link } from 'react-router-dom';
import Modal from '../../../components/Dashboard/Modal';
import AddTasksForm from '../../../components/forms/AddTasksForm';
import { TASKS_ACTION_TYPE } from '../../../Store/tasks/Type';
import moment from 'moment';
import EditSvg from '../../../assets/img/editrequestsvg.svg'
import EditTaskForm from '../../../components/forms/EditTaskForm';
import { IoIosLock } from "react-icons/io";


const ActiveUpcomingTasks = () => {
	const dispatch = useDispatch();
	const [{ loginStatus, LoginData }, { tasks }] = useSelector((state) => [state.user, state.tasks]);
	const tasksBasedOnMode = tasks?.data
	const [message, setMessage] = useState({ show: false });
	const [alertMessage, setAlertMessage] = useState('');
	const [keyword, setKeyword] = useState(getQueryParam('search') || '');
	const [selectedTask,setSelectedTask] = useState(null)
	const [createdDate, setCreatedDate] = useState(
		getQueryParam('created_after') ? new Date(getQueryParam('created_after')) : null
	);
	// Pagination
	const [perPage, setPerPage] = useState(getQueryParam('per_page') || 10);
	const [page, setPage] = useState(getQueryParam('page') || 1);

	useEffect(() => {
		if (LoginData.isAdmin || LoginData.isAccountManager) {
			dispatch(allActions.dashboard.getTaxProfessionals());
		}
	}, [dispatch, LoginData.isAdmin, LoginData.isAccountManager]);

	useEffect(() => {
		if ((LoginData?.isSupport || LoginData?.isAdmin) && loginStatus) {
			dispatch(allActions.tasks.getTasks(keyword, createdDate, page, perPage));
		} else {
			setMessage({
				show: true,
				type: 'Error',
				text: 'You are not authurize to use this page',
			});
		}
	}, [
		loginStatus,
		dispatch,
		keyword,
		createdDate,
		page,
		perPage,
		LoginData?.isSupport,
		LoginData?.isAdmin,
	]);

	useEffect(() => {
		if (tasks?.status === false) {
			setMessage({
				show: true,
				type: 'Error',
				text: tasks.error,
			});
		}
	}, [tasks.error, tasks?.status]);

	const moveTo = (page) => {
		setPage(page);
		// Set query param
		setQueryParams('page', page);
	};

	const changePerPage = (perPage) => {
		setPerPage(perPage);
		// Set query param
		setQueryParams('per_page', perPage);
	};

	const filterByCreationDate = (date) => {
		setCreatedDate(date);
		// Set query param
		if (!date) {
			removeQueryParam('created_after');
		} else {
			setQueryParams('created_after', date);
		}
	};

	const filterByKeyword = (keyword) => {
		setKeyword(keyword);
		// Set query param
		if (!keyword) {
			removeQueryParam('search');
		} else {
			setQueryParams('search', keyword);
		}
	};

	return (
		<ListPageWrapper
			heading="Active / Upcoming Tasks"
			headerControls={
				<Fragment>
					<div className="col-md-12">
						{message.show ? <Message message={message} /> : <></>}
						<div className="row g-3 justify-content-between">
							<div className="row g-3 col-md-8">
								<div className="col-md-4">
									<Input
										type="search"
										value={keyword}
										name="name"
										placeholder="Search"
										fn={filterByKeyword}
										showLabel={false}
										inputClass="search-input border h-auto min-height-initial"
										extraSetter={() => {
											if (page !== 1) moveTo(1);
										}}
										required={false}
									/>
								</div>
								<div className="col-md-5">
									<div className="form-floating">
										<DatePicker
											className="form-select"
											wrapperClassName="d-block"
											selected={createdDate}
											onChange={(date) => {
												filterByCreationDate(date);
												if (page !== 1) moveTo(1);
											}}
											maxDate={new Date()}
											dateFormat="MMMM d, yyyy"
											placeholderText="Date created"
											isClearable
										/>
									</div>
								</div>
							</div>
							<div className={`col-md-3`}>
							{LoginData?.userpro?.status_id === 6 ? <button
								className="btn btn-sm btn-outline-primary px-2 float-md-end float-none d-flex align-tems-center gap-1"
								disabled={true}
							>
								Add Task  <IoIosLock style={{ fontSize: '16px' }} />
							</button> : <Link
									to="#"
									className="btn btn-outline-primary px-4 float-md-end float-none"
									data-bs-toggle="modal"
									data-bs-target="#addTasksForm"
									onClick={() => {
										setMessage({ show: false });
										dispatch({ type: TASKS_ACTION_TYPE.CREATE_TASKS_IDLE });
									}}
								>
									Add Task
								</Link>}
								
							</div>
						</div>
					</div>
				</Fragment>
			}
			alert={{ message: alertMessage, setMessage: setAlertMessage }}
			dataState={{
				loaded: tasks.loaded,
				fetching: tasks.fetching,
			}}
			tableHeader={
				<Fragment>
					<th scope="col">ID</th>
					<th scope="col">Trigger At</th>
					<th scope="col">From Today</th>
					<th scope="col">Timezone</th>
					<th scope="col">Model ID</th>
					<th scope="col">status</th>
					<th scope="col">Action</th>
				</Fragment>
			}
			tableBody={
				<Fragment>
					{tasksBasedOnMode?.length === 0 ? (
						<tr>
							<td className="text-center opacity-50" colSpan={7}>
								There are no tasks available at this time
							</td>
						</tr>
					) : (
						tasksBasedOnMode?.map((task) => {
							return (
								<tr className="align-middle" key={task.id}>
									<th scope="row">{task.id}</th>
									<td>
										{new Date(task.trigger_at).toLocaleDateString('en-US', {
											year: 'numeric',
											month: 'short',
											day: 'numeric',
										})}</td>
									<td>{moment(task.trigger_at, 'YYYY-MM-DD HH:mm:ss').fromNow()}</td>
									<td>{task.timezone}</td>
									<td>{task.model_id}</td>
									<td>{task.status_Id === 0 ? 'pending' : 'active'}</td>
									<td>
										<Link
											to="#"
											data-bs-toggle="modal"
											data-bs-target="#editTaskForm"
											onClick={() => {
												setSelectedTask(task)
											}}
										>
											<img style={{ height: '20px' }} src={EditSvg} alt='edit' />
										</Link>
									</td>
								</tr>
							);
						})
					)}
				</Fragment>
			}
			paginationProps={{
				pageCount: Math.ceil(tasks.count / perPage),
				currentPage: parseInt(page),
				setCurrentPage: moveTo,
				perPage: perPage,
				setPerPage: (pc) => {
					changePerPage(pc);
					// Move to first page
					if (page !== 1) moveTo(1);
				},
			}}
			modals={
				<Fragment>
					<Modal
						title="Create a new tasks"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="addTasksForm"
						showBtn={false}
						btnClass="addTasksFormBtn"
					>
						<AddTasksForm setOuterMessage={setMessage} />
					</Modal>
					<Modal
						title="Edit task"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="editTaskForm"
						showBtn={false}
						btnClass="editTaskFormBtn"
					>
						<EditTaskForm task={selectedTask} setOuterMessage={setMessage} />
					</Modal>
				</Fragment>
			}
		/>
	);
};

export default ActiveUpcomingTasks;
