import React, { useEffect, useState } from 'react';
import Input from '../../fields/Input';
import Button from '../../fields/Button';
import { GLOBAL_VARIABLES } from '../../../../utilites/variables';
import { HandleFormData } from '../../../../utilites';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../Store/action';

const SaleofAssetsTPI = () => {

    const { SalesOfAssetsTPIQuestions, SalesOfAssetsTPIStyles } = GLOBAL_VARIABLES;

    const uploadFormData = HandleFormData({ parentIndex: 0, myIndex: 9 });
    const dispatch = useDispatch()
    const [{ taxPayerForm, taxPayerFormData, formInformation }] = useSelector((state) => [state.taxForms]);

    const [specificQuestions, setSpecificQuestions] = useState([]);
    const [specificQuestionsStyles, setSpecificQuestionsStyles] = useState([]);

    useEffect(() => {
        setSpecificQuestions(JSON.parse(JSON.stringify(SalesOfAssetsTPIQuestions)));
        setSpecificQuestionsStyles(JSON.parse(JSON.stringify(SalesOfAssetsTPIStyles)));
    }, [SalesOfAssetsTPIQuestions, SalesOfAssetsTPIStyles]);

    const handleInputChange = (index, value, data, valIdx) => {
        const filedKey = Object.keys(data).find(key => key !== 'placeholder' && key !== 'styleProp');
        const updatedQuestions = [...specificQuestions];
        updatedQuestions[index].values[valIdx][filedKey] = value;
        setSpecificQuestions(updatedQuestions);
    };

    useEffect(() => {
        if (taxPayerForm?.data?.SaleofAssetsTPI) {
            if (Object.keys(taxPayerForm?.data?.SaleofAssetsTPI).length === 0) return;
            const {
                specificQuestions,
            } = taxPayerForm?.data?.SaleofAssetsTPI;
            setSpecificQuestions(specificQuestions);
        }
    }, [taxPayerForm?.data?.SaleofAssetsTPI]);


    const handleSubmit = (e) => {
        e.preventDefault();

        let FormObj = {
            specificQuestions,
        };

        let formData = taxPayerForm;
        taxPayerForm.data.SaleofAssetsTPI = FormObj;
        dispatch(allActions.taxForms.StoreFormData({ formData }));

        uploadFormData(formData);
    };

    const goBack = () => {
        // CHANGE FORM TO BACK
        let parentIndex = 0;
        let myIndex = 9;
        dispatch(allActions.taxForms.moveToBack({ parentIndex, myIndex, taxPayerFormData }));
    };

    return (
        <form className="w-100 mt-3" onSubmit={handleSubmit}>
            <div className="mt-3 row justify-content-between px-3 custom-left-form">
                {specificQuestions.map((data, i) => {
                    return (
                        <>
                            {(i === 0) && <small className="ml-4 fw-bold">
                                {data.question}
                            </small>}

                            {(i >= 0 ) && (
                                data?.values?.map((data, index) => (
                                        <div key={index} className={`my-1 ${specificQuestionsStyles?.[i]?.valData[index]?.styleProp}`}>
                                            <Input
                                                mask={specificQuestionsStyles?.[i]?.valData[index]?.mask}
                                                type="text"
                                                value={specificQuestions[i].values[index][Object.keys(data).find(key => key !== 'placeholder' && key !== 'styleProp')]}
                                                placeholder={specificQuestionsStyles?.[i]?.valData[index]?.placeholder}
                                                fn={(value) => handleInputChange(i, value, data, index)}
                                            />
                                        </div>
                                )))}
                        </>
                    )
                })}
            </div>
            <div className="container-fluid content-row pb-4">
                <div className="d-flex align-items-center justify-content-center row">
                    <Button
                        mainClass="btn btn-info custon-button-text w-100 text-dark"
                        buttonText="Previous"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="button"
                        callback={() => goBack()}
                    />
                    <Button
                        mainClass="btn btn-primary custon-button-text w-100"
                        buttonText="Next"
                        wrapperClass={'w-25 custon-button mt-4'}
                        type="submit"
                        disabled={!formInformation?.lead_id}
                    />
                </div>
            </div>
        </form>
    );
};

export default SaleofAssetsTPI;