import { useSelector } from "react-redux";

export default function Loader({
	local = false,
	loading = false,
	customBg = "rgb(246, 246, 246,1)",
	viewClass = "",
	padding = 50,
	size = { width: "3rem", height: "3rem" },
}) {
	const [{ Loader }] = useSelector((state) => [state.global]);
	return (
		<>
			{(Loader || loading) && (
				<div
					className={
						local
							? `d-flex flex-row justify-content-center align-items-center custom-loader ${
									viewClass && viewClass
							  }`
							: "w-100 p-3 h-100 position-fixed d-flex flex-row justify-content-center align-items-center custom-loader"
					}
				>
					<div
						className="d-flex rounded-4"
						style={{
							backgroundColor: customBg,
							padding: padding,
						}}
					>
						<div className="spinner-border text-primary" style={size} role="status"></div>
					</div>
				</div>
			)}
		</>
	);
}
