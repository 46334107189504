import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import ReactSelect from 'react-select';
import { BASEURL, validate, nameRexExp, emailRegExp, getStatesList } from '../../utilites';
import Input from './fields/Input';
import Button from './fields/Button';
import Select from './fields/Select';

const UsaStatesList = getStatesList();

export default function EditUserForm({ userData, reloadUser, setAlert }) {
	const [userId, setUserId] = useState('');
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [email, setEmail] = useState('');
	const [status, setStatus] = useState('');
	// For Customers
	const [lastLead, setLastLead] = useState();
	// Additional fields for Customers
	const [phone, setPhone] = useState('');
	const [street, setStreet] = useState('');
	const [street2, setStreet2] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState(null);
	const [zipcode, setZipcode] = useState('');

	const [errors, setErrors] = useState({
		firstname: false,
		lastname: false,
		email: false,
		phone: false,
		street: false,
		street2: false,
		city: false,
		state: false,
		zipcode: false,
		general: '',
	});

	const button = useRef();

	useEffect(() => {
		if (userData) {
			setUserId(userData.id);
			setFirstname(userData.firstname);
			setLastname(userData.lastname);
			setEmail(userData.email);
			setStatus(userData.isVerify.toString());
		}
		// Check if the user is customer
		if (userData.roles.filter((r) => r.name.toLowerCase() === 'customer').length > 0) {
			loadLastLead();
		}
		return () => {
			setUserId('');
			setFirstname('');
			setLastname('');
			setEmail('');
			setStatus('');
			setPhone('');
			setStreet('');
			setStreet2('');
			setCity('');
			setState('');
			setZipcode('');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData]);

	const loadLastLead = () => {
		axios.get(`${BASEURL}/api/leads/by-customer?id=${userData.id}`).then((res) => {
			const leadData = res.data.data;
			// Set fields
			if (leadData) {
				setPhone(leadData?.phone);
				setStreet(leadData.street || '');
				setStreet2(leadData.street2 || '');
				setCity(leadData.city || '');
				setState(leadData.state ? UsaStatesList.find((s) => s.value === leadData.state) : null);
				setZipcode(leadData.zipcode || '');
				// Set last lead
				setLastLead(leadData);
			}
		});
	};

	// function to handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();

		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });

		axios
			.put(`${BASEURL}/api/users/update`, {
				id: userId,
				firstname,
				lastname,
				email,
				status,
				// For Customers
				phone: phone.split(/[ ()-]/).join(''),
				street,
				street2,
				city,
				state: state?.value || '',
				zipcode,
			})
			.then(() => {
				button.current.disabled = false;
				reloadUser(userData.uuid);
				// Close Modal
				document.querySelector('.userDetailModalClose').click();
				setAlert('User information has been updated successfully!');
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};

	return (
		<form className='userform needs-validation' onSubmit={handleSubmit}>
			<div className='mb-3'>
				<Input
					type='text'
					value={firstname}
					name='firstname'
					placeholder='First Name'
					fn={setFirstname}
					onLeave={validate('firstname', firstname, nameRexExp, errors, setErrors)}
					hasValidationError={errors.firstname}
					showLabel={false}
				/>
			</div>
			<div className='mb-3'>
				<Input
					type='text'
					value={lastname}
					name='lastname'
					placeholder='Last Name'
					fn={setLastname}
					onLeave={validate('lastname', lastname, nameRexExp, errors, setErrors)}
					hasValidationError={errors.lastname}
					showLabel={false}
				/>
			</div>
			<div className='mb-3'>
				<Input
					type='email'
					value={email}
					name='email'
					placeholder='Email Address'
					fn={setEmail}
					onLeave={validate('email', email, emailRegExp, errors, setErrors)}
					hasValidationError={errors.email}
					showLabel={false}
				/>
			</div>
			<div className='mb-3'>
				<Select
					value={status}
					name='status'
					placeholder='User Status'
					showLabel={false}
					fn={setStatus}
				>
					<option value='0'>Unactive</option>
					<option value='1'>Active</option>
				</Select>
			</div>

			{lastLead && (
				<>
					<div className='mb-3'>
						<Input
							type='text'
							value={phone}
							name='phone'
							placeholder='Phone'
							fn={setPhone}
							showLabel={false}
							mask='(999) 999-9999'
							required={false}
						/>
					</div>

					<fieldset className='border-top mb-3 text-start mt-3'>
						<legend className='w-auto float-none px-2 ms-3 fs-6'>Business Address</legend>
					</fieldset>

					<div className='row g-3 mb-3'>
						<div className='col-md-9'>
							<Input
								type='text'
								value={street}
								name='street'
								placeholder='Street'
								fn={setStreet}
								showLabel={false}
								required={false}
							/>
						</div>
						<div className='col'>
							<Input
								type='text'
								value={street2}
								name='street2'
								placeholder='Street 2'
								fn={setStreet2}
								showLabel={false}
								required={false}
							/>
						</div>
					</div>
					<div className='row g-3 mb-3'>
						<div className='col-md-4'>
							<Input
								type='text'
								value={city}
								name='city'
								placeholder='City'
								fn={setCity}
								showLabel={false}
								required={false}
							/>
						</div>
						<div className='col-md-4'>
							<ReactSelect
								id='state'
								className='p-0 basic-single'
								placeholder='State'
								closeMenuOnSelect={true}
								options={UsaStatesList}
								onChange={setState}
								value={state}
								required={false}
							/>
						</div>
						<div className='col-md-4'>
							<Input
								type='text'
								value={zipcode}
								name='zipcode'
								placeholder='Zipcode'
								fn={setZipcode}
								showLabel={false}
								required={false}
							/>
						</div>
					</div>
				</>
			)}
			{errors.general && errors.general !== '' && (
				<div className='row'>
					<div className='mb-3'>
						<div className='alert alert-danger' role='alert'>
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-outline-primary btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 ? ' disabled' : ''
				}`}
				wrapperClass='clearfix d-grid text-center mt-3'
				buttonText='Update User'
			/>
		</form>
	);
}
