import { INVOICE_ACTION_TYPE } from './Type';

const DEFAULT_STATE = {
	invoices: { data: [], count: 0, status: 'idle', message: '', fetching: false, loaded: false },
	invoicePaid:  { message: "", status: "idle", data: {} },
	createCustomer: 'idle',
	createCustomerMessage: '',
	createInvoice: 'idle',
	createInvoiceMessage: '',
	createSubscription: 'idle',
	createSubscriptionMessage: '',
	payInvoice: 'idle',
	payInvoiceMessage: '',
	downloadInvoice: 'idle',
	downloadInvoiceMessage: '',
};

const INITIAL_STATE = {
	...DEFAULT_STATE,
};

const invoice = (state = INITIAL_STATE, action) => {
	if (action.type === INVOICE_ACTION_TYPE.invoicesFetchingStart) {
		return {
			...state,
			invoices: { ...state.invoices, fetching: true },
		};
	} else if (action.type === INVOICE_ACTION_TYPE.invoices) {
		return {
			...state,
			invoices: action.payload,
		};
	} else if (action.type === INVOICE_ACTION_TYPE.invoiceStatusIdle) {
		return {
			...state,
			invoices: { data: [], status: 'idle', message: '' },
		};
	} else if (action.type === INVOICE_ACTION_TYPE.createCustomer) {
		return {
			...state,
			createCustomer: action.status,
			createCustomerMessage: action.message,
		};
	} else if (action.type === INVOICE_ACTION_TYPE.createCustomerIdle) {
		return {
			...state,
			createCustomer: 'idle',
			createCustomerMessage: '',
		};
	} else if (action.type === INVOICE_ACTION_TYPE.createInvoice) {
		return {
			...state,
			createInvoice: action.status,
			createInvoiceMessage: action.message,
		};
	} else if (action.type === INVOICE_ACTION_TYPE.createInvoiceIdle) {
		return {
			...state,
			createInvoice: 'idle',
			createInvoiceMessage: '',
		};
	} else if (action.type === INVOICE_ACTION_TYPE.createSubscription) {
		return {
			...state,
			createSubscription: action.status,
			createSubscriptionMessage: action.message,
		};
	} else if (action.type === INVOICE_ACTION_TYPE.createSubscriptionIdle) {
		return {
			...state,
			createSubscription: 'idle',
			createSubscriptionMessage: '',
		};
	} else if (action.type === INVOICE_ACTION_TYPE.payInvoice) {
		return {
			...state,
			payInvoice: action.status,
			payInvoiceMessage: action.message,
		};
	} else if (action.type === INVOICE_ACTION_TYPE.payInvoiceIdle) {
		console.warn('>>>>>>>.');
		return {
			...state,
			payInvoice: 'idle',
			payInvoiceMessage: '',
		};
	} else if (action.type === INVOICE_ACTION_TYPE.downloadInvoice) {
		return {
			...state,
			downloadInvoice: action.status,
			downloadInvoiceMessage: action.message,
		};
	} else if (action.type === INVOICE_ACTION_TYPE.downloadInvoiceIdle) {
		return {
			...state,
			downloadInvoice: 'idle',
			downloadInvoiceMessage: '',
		};
	}
	else if (action.type ===	INVOICE_ACTION_TYPE.invoicePaid) {
		return {
			...state,
			invoicePaid: action.payload,
		};
	}
	else if (action.type === INVOICE_ACTION_TYPE.invoicePaidIdle) {
		return {
			...state,
			invoicePaid: DEFAULT_STATE.invoicePaid,
		};
	}
	return state;
};

export default invoice;
