/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import allActions from "../Store/action";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const HandleFormData = ({ parentIndex, myIndex, handle = false, redirect }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [handling, setHandling] = useState(handle);

	const [{ formInformation, taxPayerFormData }, { LoginData }, { taxPreparation }] = useSelector(
		(state) => [state.taxForms, state.user, state.dashboard]
	);

	console.log("redirect@", redirect);

	useEffect(() => {
		// if Success
		if (taxPreparation?.state === true && handling) {
			dispatch(allActions.dashboard.getProjects());

			// if its ending screen navigate to that
			if (redirect) {
				markMeComplete();
			} else {
				dispatch(allActions.taxForms.moveToNext({ parentIndex, myIndex, taxPayerFormData }));
			}
			dispatch(allActions.dashboard.resetTaxPreparation());
		}
	}, [taxPreparation?.state]);

	const markMeComplete = () => {
		window.scroll({
			top: 0,
			left: 0,
			behavior: "smooth", // You can use 'auto' for an instant scroll or 'smooth' for a smooth scroll
		});
		navigate(redirect);
		dispatch(allActions.taxForms.markMeCompleted({ parentIndex, myIndex, taxPayerFormData }));
	};

	const uploadFormData = (formData) => {
		let obj = {
			service_id: 1,
			method: "upload",
			formName: formInformation?.name,
			formData: formData?.data,
		};
		if (LoginData?.isTaxPro || LoginData?.isAdmin || LoginData?.isAccountManager)
			obj.lead_id = formInformation?.lead_id;

		setHandling(true);
		changeLoading(true);
		dispatch(allActions.dashboard.resetTaxPreparation());
		dispatch(allActions.dashboard.taxPreparation({ obj }));
	};

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

	return uploadFormData;
};
